<div class="emoji-picker-popin-backdrop" (click)="onClickOutside.emit()"></div>
<div
  class="emoji-picker-popin-container"
  [style.top.px]="topPosition"
  [style.right.px]="rightPosition"
>
  <div class="emoji-picker-popin">
    @let filterFn = emojisToShowFilter();
    <emoji-mart
      (emojiSelect)="onSelected($event)"
      [showPreview]="false"
      [isNative]="false"
      [enableSearch]="!whitelist?.length"
      [darkMode]="false"
      [autoFocus]="true"
      [showSingleCategory]="true"
      set="twitter"
      title="Pick your emoji"
      [emoji]="emoji"
      [sheetSize]="64"
      [imageUrlFn]="imageUrlFn"
      [emojisToShowFilter]="filterFn"
      [exclude]="whitelist?.length ? ['recent'] : []"
    ></emoji-mart>
    <button
      *ngIf="canBeNull"
      class="no-emoji-button"
      nz-button
      nzType="default"
      nzSize="large"
      (click)="onReset()"
    >
      <i nz-icon nzType="delete"></i>
      <span>No emoji</span>
    </button>
  </div>
</div>
