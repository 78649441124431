import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { AnalyticsQuery } from "models/analytics.filters.type";
import { Org } from "models/org.model";
import { RegistryEntry } from "models/registry.model";
import { getFormattedUserGroupNameOrID } from "models/user-group.types";
import { UserDao } from "models/user.dao";
import { NzSelectOptionInterface } from "ng-zorro-antd/select";
import { AnalyticsFilterService } from "services/analytics-filters.service";
import { EntitlementService } from "services/entitlement.service";
import { TrackersService } from "services/trackers.service";
import { deepCopy } from "utils/object";

@Component({
  selector: "filters-save-segment",
  templateUrl: "./save-segment.component.html",
  styleUrls: ["./save-segment.component.scss"],
})
export class FiltersSaveSegmentComponent implements OnInit, OnDestroy {
  private filtersObs$: any = null;
  public lastFilters: AnalyticsQuery;
  public defaultFilters: AnalyticsQuery;

  public isModalVisible = false;
  public loading = false;
  public error: string = null;

  public userGroupOptions: NzSelectOptionInterface[] = [];
  public selectedUserGroups: { type: string; name: string }[] = [];

  public isSegmentsValid = false;

  @Input() public org: Org;
  @Input() public registryEntriesGroup: RegistryEntry[];

  @ViewChild("segmentNameElement") segmentNameElement: ElementRef;

  public getFormattedUserGroupNameOrID = getFormattedUserGroupNameOrID;

  constructor(
    private analyticsFilterService: AnalyticsFilterService,
    private userDao: UserDao,
    private trackersService: TrackersService,
    private entitlementService: EntitlementService,
  ) {}

  ngOnInit() {
    this.filtersObs$ = this.analyticsFilterService
      .subscribe()
      .subscribe((filters: AnalyticsQuery) => {
        this.lastFilters = deepCopy(filters);
      });
  }

  ngOnDestroy() {
    if (this.filtersObs$) {
      this.filtersObs$.unsubscribe();
    }
  }

  isValid() {
    this.isSegmentsValid =
      this.selectedUserGroups.length &&
      this.selectedUserGroups.every(this.isSegmentNameValid);
    return this.isSegmentsValid;
  }

  isSegmentNameValid({ name }) {
    return name && name.length < 255 && name.match(/^[0-9a-zA-Z_ -]+$/);
  }

  showModal(): void {
    this.error = null;
    this.selectedUserGroups = [];
    this.loading = false;
    this.isModalVisible = true;

    this.userGroupOptions = this.registryEntriesGroup
      .map((group) => ({
        value: { type: group.parent?.slug, name: group.slug },
        label: getFormattedUserGroupNameOrID(group),
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    setTimeout(() => {
      (this.segmentNameElement as any)?.focus();
    }, 500);
  }

  handleOk(): void {
    if (this.lastFilters.type === "respondent") {
      this.loading = true;
      this.userDao
        .saveSegment(this.org.id, this.selectedUserGroups, this.lastFilters)
        .then(() => this.closeModal())
        .catch(() => (this.error = "Something went wrong. Please try again."))
        .finally(() => (this.loading = false));

      this.trackersService
        .newEventTrackingBuilder("Segment created")
        .withOrg(this.org)
        .withAnalyticsFilters(this.lastFilters.filters, "respondent")
        .withProps({ segments: this.selectedUserGroups })
        .build();
    }
  }

  closeModal() {
    this.isModalVisible = false;
  }

  handleCancel(): void {
    this.closeModal();
  }

  keyDown($event: KeyboardEvent) {
    if ($event.keyCode === 13 && this.isValid()) {
      this.handleOk();
    }
  }

  onModelChange($event: ({ type: string; name: string } | string)[]) {
    // if ($event.length === 0) {
    //   return;
    // }

    this.selectedUserGroups = $event.map(
      (item): { type: string; name: string } => {
        if (typeof item === "string") {
          return { type: "default", name: item.trim() };
        }
        return item;
      },
    );

    this.isValid();
  }
}
