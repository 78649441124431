<button
  *ngIf="'isNotDeviceTracking' | hasFeatureFlag"
  nz-button
  nzType="default"
  nzSize="large"
  (click)="showModal()"
>
  <i nz-icon nzType="save" nzTheme="outline"></i>
  Save segment
</button>

<nz-modal
  nzTitle="Save this segment"
  [(nzVisible)]="isModalVisible"
  [nzOkLoading]="loading"
  [nzOkDisabled]="selectedUserGroups.length && !isSegmentNameValid"
  nzOkType="primary"
  nzOkText="Save segment"
  (nzOnOk)="handleOk()"
  (nzOnCancel)="handleCancel()"
>
  <ng-container *nzModalContent>
    <tag-input
      [tokenSeparators]="[',', ';']"
      placeHolder="Select existing segment or create new one"
      size="large"
      (ngModelChange)="onModelChange($event)"
      [options]="userGroupOptions"
    ></tag-input>

    <p style="margin-top: 12px">
      Save this segment and use it to share in-app surveys and to analyse your
      user's behaviour.
    </p>

    <p class="error" *ngIf="error">{{ error }}</p>
    <p class="error" *ngIf="selectedUserGroups.length && !isSegmentNameValid">
      Segments names must only contains alpha-numerical characters, underscores
      and hyphen.
    </p>
  </ng-container>
</nz-modal>
