let environment = {
  production: true,
  ENV: "prod",
  TEST: false,
};

// eslint-disable-next-line @typescript-eslint/no-var-requires
const dotEnv = require("../.env.prod.json");
environment = Object.assign(environment, dotEnv);

// @TODO: https://github.com/danduh/ng-process-env

// if ('dotEnv' in window)
//   environment = Object.assign(environment, window['dotEnv']);

const ENV = environment;

export { environment, ENV };
