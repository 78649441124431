import { HttpErrorResponse } from "@angular/common/http";
import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { UIService } from "services/ui.service";

export function uiGuard(): CanActivateFn {
  return async () => {
    const uiService = inject(UIService);
    const router = inject(Router);

    const url = router.getCurrentNavigation().finalUrl.toString();
    return uiService
      .fetchEverything(url)
      .then(() => true)
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        throw err;
      });
  };
}
