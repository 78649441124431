import { Component, Input, OnInit } from "@angular/core";

import { NzSelectOptionInterface } from "ng-zorro-antd/select";

import { AnalyticsDao } from "models/analytics.dao";
import { AnalyticsFilterService } from "services/analytics-filters.service";
import { TrackersService } from "services/trackers.service";
import {
  AnalyticsFilter,
  AnalyticsFilterForUser,
  AnalyticsFilterUser,
  AnalyticsFilterUserEvent,
} from "models/analytics.filters.type";
import {
  CriteriaFilterStatsSurveyComponent,
  valueComponent,
} from "./filter-criteria.component";
import { Org } from "models/org.model";
import { Survey } from "models/survey.model";
import { UIService } from "services/ui.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { ChannelDao } from "models/channel.dao";
import { RegistryEntry } from "models/registry.model";
import { FilterGroup } from "./filters/filter-criteria-filters.component";
import { addDays } from "date-fns";

@Component({
  selector: "survey-stats-filter-criteria-user",
  templateUrl: "./filter-criteria.component.html",
  styleUrls: ["./filter-criteria.component.scss"],
})
export class CriteriaFilterStatsUserSurveyComponent
  extends CriteriaFilterStatsSurveyComponent
  implements OnInit
{
  @Input() public survey: Survey = null;
  @Input() public org: Org = null;
  @Input() public registryEntriesIdentityProperty: RegistryEntry[] = [];
  @Input() public registryEntriesGroup: RegistryEntry[] = [];
  @Input() public registryEntriesEvent: RegistryEntry[] = [];

  public filterValueOptionsNPS: NzSelectOptionInterface[] = null;
  public filterValueOptionsCES: NzSelectOptionInterface[] = null;
  public filterValueOptionsCSat: NzSelectOptionInterface[] = null;

  public title = "Filter users";

  constructor(
    protected analyticsFilterService: AnalyticsFilterService,
    protected trackersService: TrackersService,
    protected channelDao: ChannelDao,
    protected analyticsDao: AnalyticsDao,
    protected uiService: UIService,
    public featureFlaggingService: FeatureFlaggingService,
  ) {
    super(analyticsFilterService, channelDao, trackersService, uiService);
  }

  ngOnInit() {
    // Dirty hack but good enough for now
    if (window.location.hash === "#filter") {
      this.switchPanelVisibility(true);
    }
  }

  getOrg() {
    return this.org;
  }

  getSurvey() {
    return this.survey;
  }

  private getUserPropertiesFields() {
    return this.registryEntriesIdentityProperty
      .filter((prop) => !prop.hidden)
      .sort((a, b) => {
        if (a.favorite !== b.favorite) {
          return Number(b.favorite) - Number(a.favorite);
        }
        return a.slug.localeCompare(b.slug);
      })
      .map(({ id: key, slug: label, favorite }) => ({
        value: {
          type: "respondent.property",
          key,
        },
        label: (favorite ? "⭐️ " : "") + label,
        groupLabel: FilterGroup.UserProperty,
      }));
  }

  private getUserEventsFields() {
    return this.registryEntriesEvent
      .filter((prop) => !prop.hidden)
      .sort((a, b) => {
        if (a.favorite !== b.favorite) {
          return Number(b.favorite) - Number(a.favorite);
        }
        return a.slug.localeCompare(b.slug);
      })
      .map(({ id: key, slug: label, favorite }) => ({
        value: {
          type: "respondent.event",
          key,
        },
        label: (favorite ? "⭐️ " : "") + label,
        groupLabel: FilterGroup.UserEvent,
      }));
  }

  protected async getAvailableFields() {
    this.keysOptions = [
      {
        value: { type: "respondent", key: "created_at" },
        label: "First seen",
        groupLabel: FilterGroup.User,
      },
      {
        value: { type: "respondent", key: "last_activity_at" },
        label: "Last activity",
        groupLabel: FilterGroup.User,
      },
      {
        value: { type: "respondent", key: "aliases" },
        label: "Identifier",
        groupLabel: FilterGroup.User,
      },
      {
        value: { type: "respondent", key: "segment" },
        label: "Segment",
        groupLabel: FilterGroup.User,
      },
      ...this.getUserPropertiesFields(),
      ...this.getUserEventsFields(),
    ].filter(Boolean);
  }

  protected getDefaultFilter(): AnalyticsFilter {
    return {
      type: "respondent",
      key: "created_at",
      operator: "before",
      value: addDays(new Date(), 1),
    };
  }

  /**
   * Filters CRUD / validation
   */

  public getOperatorOptions(
    filter: AnalyticsFilterForUser,
  ): NzSelectOptionInterface[] {
    switch (filter.type) {
      case "respondent":
        switch (filter.key) {
          case "created_at":
            return this.operators.respondent.created_at;
          case "last_activity_at":
            return this.operators.respondent.last_activity_at;
          case "aliases":
            return this.operators.respondent.aliases;
          case "segment":
            return this.operators.respondent.segment;
          default:
        }
        break;

      case "respondent.property":
        const property = this.registryEntriesIdentityProperty.find(
          (p) => p.id === filter.key,
        );
        if (property?.type === "string")
          return this.operators.respondent.property.string;
        else if (property?.type === "number")
          return this.operators.respondent.property.numeric;
        else if (property?.type === "bool")
          return this.operators.respondent.property.boolean;
        else if (property?.type === "time")
          return this.operators.respondent.property.time;
        break;
      case "respondent.event":
        return this.operators.respondent.event;

      default:
    }

    throw Error("unexpected filter type for respondent: " + filter.type);
  }

  public getValueComponent(filter: AnalyticsFilterForUser): valueComponent {
    if (filter.operator === "not_null") return "none";

    switch (filter.type) {
      case "respondent":
        if (filter.key === "created_at")
          return this.valueComponents.respondent.created_at;
        else if (filter.key === "last_activity_at")
          return this.valueComponents.respondent.last_activity_at;
        else if (filter.key === "aliases")
          return this.valueComponents.respondent.aliases(
            filter as AnalyticsFilterUser,
          );
        else if (filter.key === "segment")
          return this.valueComponents.respondent.segment;
        break;
      case "respondent.event":
        return this.valueComponents.respondent.event(
          filter as AnalyticsFilterUserEvent,
        );
      case "respondent.property":
        const property = this.registryEntriesIdentityProperty.find(
          (p) => p.id === filter.key,
        );

        if (property?.type === "string")
          return this.valueComponents.respondent.property.string;
        else if (property?.type === "number")
          return this.valueComponents.respondent.property.numeric;
        else if (property?.type === "bool")
          return this.valueComponents.respondent.property.boolean;
        else if (property?.type === "time")
          return this.valueComponents.respondent.property.time;
        break;
    }

    throw Error(
      "unexpected filter operator " +
        filter.operator +
        " for filter of type " +
        filter.type,
    );
  }
}
