<div *ngIf="rule">
  <nz-select
    *ngIf="['equal', 'not equal'].includes(rule.operator)"
    nzMode="multiple"
    [(ngModel)]="rule.value.v_s_arr"
    nzPlaceHolder="Select screens"
    [nzDropdownMatchSelectWidth]="false"
    nzDropdownClassName="filters-select-value"
    [ngClass]="{ invalid: !rule.value.v_s_arr?.length }"
    [nzDisabled]="!('survey_edition' | hasEntitlement)"
  >
    <nz-option-group [nzLabel]="'Android'" *ngIf="androidScreens.length > 0">
      <nz-option
        *ngFor="let clientScreen of androidScreens"
        [nzLabel]="clientScreen.name"
        [nzValue]="clientScreen.name"
      ></nz-option>
    </nz-option-group>
    <nz-option-group [nzLabel]="'iOS'" *ngIf="iosScreens.length > 0">
      <nz-option
        *ngFor="let clientScreen of iosScreens"
        [nzLabel]="clientScreen.name"
        [nzValue]="clientScreen.name"
      ></nz-option>
    </nz-option-group>
  </nz-select>

  <div *ngIf="['contains', 'not contains'].includes(rule.operator)">
    <div
      *ngFor="let line of rule.value.v_s_arr; let i = index; trackBy: trackByFn"
      class="or-nor-container"
    >
      <rule-or-neither [rule]="rule" [index]="i"></rule-or-neither>

      <input
        type="text"
        nz-input
        [placeholder]="rule.getValuePlaceholder()"
        [ngModel]="line"
        (ngModelChange)="onOrRuleChange(rule, $event, i, false)"
        [ngClass]="{ invalid: !checkValidValue(rule, i) }"
        [disabled]="!('survey_edition' | hasEntitlement)"
      />
    </div>
  </div>
</div>
