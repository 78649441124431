import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
} from "@angular/core";

@Component({
  selector: "utils-badge",
  templateUrl: "./badge.component.html",
  styleUrls: ["./badge.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent implements OnInit {
  @Input() public text: string = null;
  @Input() public top = "-9px";
  @Input() public backgroundColor = "var(--screeb-color-purple-500)";
  @Input() public foregroundColor = "var(--screeb-color-white)";

  constructor(private elRef: ElementRef) {}

  ngOnInit(): void {
    this.elRef.nativeElement.style.top = this.top;
    this.elRef.nativeElement.style.background = this.backgroundColor;
    this.elRef.nativeElement.style.color = this.foregroundColor;
  }
}
