import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  Output,
} from "@angular/core";
import { EMPTY } from "rxjs";

// Comment this line if you're using @types/dom-mediacapture-record
declare const MediaRecorder: any;

/**
 * Wraps access to [HTMLVideoElement](https://developer.mozilla.org/en-US/docs/Web/API/HTMLVideoElement) :
 *
 * ```html
 *   <video></video>
 * ```
 *
 * in your component.ts
 *
 * ```ts
 *   ViewChild(HTMLVideoDirective)
 *   public htmlVideo: HTMLVideoDirective;
 *
 *   this.htmlVideo.element; // access to the element
 * ```
 */
@Directive({
  selector: "video",
})
export class HTMLVideoDirective {
  public element: HTMLVideoElement;

  constructor(elRef: ElementRef) {
    this.element = elRef.nativeElement;
  }
}

const codecs = [
  // Changelog:
  // Marty added "video/webm;codecs=vp8" on 2023-07-27 to fix FF
  // Samuel added ",opus" on 2023-08-30 to fix its own FF -> https://github.com/mozilla/hubs/issues/2024
  // Samuel added custom codecs to support firefox and safari on 2023-08-31

  "video/webm; codecs=vp8,opus", // firefox + chrome
  "video/webm; codecs=vp9,opus", // chrome
  "video/mp4", // safari, edge
];

/**
 * Wraps [MediaDevices](https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia)
 * playing content in <video></video>:
 *
 * ```html
 *   <video mediaStream></video>
 * ```
 *
 * usign autoplay
 *
 * ```html
 *   <video autoplay mediaStream></video>
 * ```
 *
 * in your component.ts
 *
 * ```ts
 *   ViewChild(MediaStreamDirective)
 *   public mediaStream: MediaStreamDirective;
 *
 *   this.mediaStream.play(); // play video from camera
 * ```
 */
@Directive({
  selector: "video[mediaStream]",
})
export class MediaStreamDirective
  extends HTMLVideoDirective
  implements AfterViewInit, OnDestroy
{
  /**
   * config is using [MediaStreamConstraints](https://developer.mozilla.org/en-US/docs/Web/API/MediaStreamConstraints)
   */
  @Input("mediaStream")
  public config!: MediaStreamConstraints;

  /**
   * DOMException - MediaStream has no perm to start
   * ReferenceError - MediaRecorder is not available on browser
   */
  @Output()
  public initError: EventEmitter<DOMException | ReferenceError> =
    new EventEmitter();

  @Output()
  public videoRecorded: EventEmitter<Blob> = new EventEmitter();

  @Output()
  public streamReady: EventEmitter<void> = new EventEmitter();

  private readonly mediaDevices: MediaDevices = navigator.mediaDevices;
  private readonly document: Document = document;
  private mediaRecorder: typeof MediaRecorder;
  private isRecording: boolean = false;
  private isLoaded: boolean = false;
  private isLoading: boolean = false;
  private recoordedBlob: Blob[] = [];

  constructor(
    elRef: ElementRef,
    private ngZone: NgZone,
  ) {
    super(elRef);
  }

  ngAfterViewInit(): void {
    if (this.element.autoplay) {
      this.play();
    }
  }

  ngOnDestroy(): void {
    this.stop();
  }

  public play(): void {
    if (this.isLoaded) {
      this.element.play();
      return;
    }

    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    this.mediaDevices
      .getUserMedia({
        ...{ video: true, audio: false }, // Default config
        ...this.config,
      })
      .then((stream) => {
        this.element.srcObject = stream;
        this.element.load();

        this.element.onloadedmetadata = () => {
          this.element.play();
          this.streamReady.emit();
        };

        try {
          for (let i = 0; i < codecs.length; i++) {
            console.info(
              `Is codec "${codecs[i]}" supported on this browser ?  ->`,
              MediaRecorder.isTypeSupported(codecs[i]),
            );

            if (MediaRecorder.isTypeSupported(codecs[i])) {
              this.mediaRecorder = new MediaRecorder(stream, {
                mimeType: codecs[i],
              });
              break;
            }
          }

          if (!this.mediaRecorder) {
            throw Error(
              "Could not record video, since none of the common codecs are supported by this browser",
            );
          }
        } catch (e) {
          this.initError.emit(e);
          return;
        }

        this.mediaRecorder.ondataavailable = () => {};
        this.mediaRecorder.start();

        setTimeout(() => {
          this.mediaRecorder.stop();
          this.streamReady.emit();

          this.isLoaded = true;
          this.isLoading = false;
        }, 2000);
      })
      .catch((err) => {
        this.initError.emit(err);
        return EMPTY;
      });
  }

  public pause(): void {
    this.element.pause();
  }

  public stop() {
    return new Promise<void>((resolve) => {
      const stream = this.element.srcObject as MediaStream;

      if (!stream) {
        return;
      }

      stream?.getTracks().forEach((track) => {
        track.stop();
      });

      this.element.srcObject = null;
      this.isLoaded = false;
      resolve();
    });
  }

  /**
   * Take pictures from the displaying video
   * @param config mixing of [toDataURL()](https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toDataURL)
   * and [drawImage()](https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/drawImage)
   */
  public takeScreenshot(config?: {
    width?: number;
    height?: number;
    type?: string;
    encoderOptions?: number;
  }): string {
    const canvas: HTMLCanvasElement = this.document.createElement("canvas");
    canvas.width = config?.width || this.element.offsetWidth;
    canvas.height = config?.height || this.element.offsetHeight;
    canvas
      .getContext("2d")
      ?.drawImage(this.element, 0, 0, canvas.width, canvas.height);
    return canvas.toDataURL(config?.type, config?.encoderOptions);
  }

  /**
   * This method is using a native APIs:
   * (MediaRecorder)[https://developer.mozilla.org/en-US/docs/Web/API/MediaRecorder]
   */
  public recordStart() {
    if (this.isRecording || !this.mediaRecorder) {
      return;
    }

    this.recoordedBlob = [];

    this.mediaRecorder.ondataavailable = (event: any) => {
      const blob = event.data;
      if (blob?.size > 0) {
        this.recoordedBlob.push(blob);
      }

      this.mediaRecorder.onstop = () => {
        const blob = new Blob(this.recoordedBlob, {
          type: this.mediaRecorder.mimeType,
        });

        this.ngZone.run(() => {
          this.videoRecorded.emit(blob);
        });
      };
    };

    this.mediaRecorder.start(1000);
    this.isRecording = true;
  }

  public recordStop(): void {
    if (!this.isRecording || this.mediaRecorder.state === "inactive") {
      return;
    }
    this.mediaRecorder.stop(); // Fires ondataavailable's event
    this.isRecording = false;
  }
}
