import { Serializable } from "models/serializable.model";
import { getProfilePicture, Account } from "models/account.model";

class Release extends Serializable {
  constructor(
    public id?: string,
    public org_id?: string,
    public author_id?: string,
    public name?: string,
    public description?: string,
    public version?: string,
    public tags?: string[],
    public released_at?: Date,
    public created_at?: Date,
    public updated_at?: Date,
  ) {
    super();
  }

  public copy(): Release {
    return new Release().fromJson(JSON.parse(JSON.stringify(this)));
  }
}

function getReleasedBy(accounts: Account[], author_id?: string) {
  if (!author_id) {
    return "API";
  }

  const account = accounts.find((u) => u.id === author_id);

  if (!account) {
    return "Unknown";
  }

  return account.fullname;
}

function getAuthorProfilePicture(accounts: Account[], accountId: string) {
  const account = accounts.find((u) => u.id === accountId);

  if (account) {
    return getProfilePicture(account);
  }
  return null;
}

export { Release, getReleasedBy, getAuthorProfilePicture };
