import { Serializable } from "models/serializable.model";
import {
  WorkspaceAccountPermission,
  WorkspaceAccountRole,
} from "./account-permissions";
import { SurveyLanguages, TranslationLanguage } from "./survey.dao.types";
import { TagSettings } from "./tag-settings.types";

export type WorkspaceRegion = "eu" | "us";

class Org extends Serializable {
  constructor(
    public id?: string,
    public super_org_id?: string,
    public tenant_region?: WorkspaceRegion,
    public name?: string,
    public industry?: string,
    public tags?: string[],
    public flags?: OrgFlags,
    public entitlements?: OrgEntitlements,
    public data_governance?: OrgDataGovernance,
    public stats?: OrgStats,
    public logo?: string,
    public last_seen_at?: Date,
    public created_at?: Date,
    public updated_at?: Date,
    // public deleted_at?: Date,
    public settings?: TagSettings,
    public api_token?: string,
    public survey_languages?: SurveyLanguages[],
    public translation_language?: TranslationLanguage,
    public translation_enabled?: boolean,
    public computed_translations?: TranslationLanguage[],
    public role?: WorkspaceAccountRole,
    public permissions?: WorkspaceAccountPermission[],
    public answer_tags?: OrgAnswerTags[],
    public smart_answer_tags?: string[],
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);

    this.tenant_region = json["tenant_region"] as WorkspaceRegion;

    if (json["flags"]) this.flags = new OrgFlags().fromJson(json["flags"]);
    if (json["entitlements"])
      this.entitlements = new OrgEntitlements().fromJson(json["entitlements"]);
    if (json["data_governance"])
      this.data_governance = new OrgDataGovernance().fromJson(
        json["data_governance"],
      );
    if (json["stats"]) this.stats = new OrgStats().fromJson(json["stats"]);

    return this;
  }

  public get usedMTUPercent(): number | null {
    if (
      this.stats?.current_month_respondents === null ||
      this.stats?.current_month_respondents === undefined ||
      this.entitlements?.max_mtu === null ||
      this.entitlements?.max_mtu === undefined
    )
      return null;
    if (this.entitlements.max_mtu === -1) return 0;
    if (this.entitlements.max_mtu === 0) return 100;
    return Math.ceil(
      Math.min(
        (this.stats.current_month_respondents * 100) /
          this.entitlements.max_mtu,
        100,
      ),
    );
  }

  public get usedMTU(): number | null {
    if (
      this.stats?.current_month_respondents === null ||
      this.stats?.current_month_respondents === undefined ||
      this.entitlements?.max_mtu === null ||
      this.entitlements?.max_mtu === undefined
    )
      return null;
    if (this.entitlements.max_mtu === -1) return 0;
    return this.stats.current_month_respondents;
  }

  public get maxMTU(): number | null {
    if (
      this.stats?.current_month_respondents === null ||
      this.stats?.current_month_respondents === undefined ||
      this.entitlements?.max_mtu === null ||
      this.entitlements?.max_mtu === undefined
    )
      return null;
    if (this.entitlements.max_mtu === -1) return 0;
    return this.entitlements.max_mtu;
  }
}

class OrgFlags extends Serializable {
  constructor(
    public debug_log_level?: string, // optional
    public sentry_log_enabled?: boolean, // optional
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);
    return this;
  }
}

type OrgFlagsHomeGridRange = "day" | "week" | "month" | "quarter" | "year";

export const GridRangeNames = {
  day: "Day",
  week: "Week",
  month: "Month",
  quarter: "Quarter",
  year: "Year",
};

type OrgFlagsHomeGridCellType =
  | "nps"
  | "ces"
  | "csat"
  | "mau"
  | "activity"
  | "emotions"
  | "device_type"
  | "identification"
  | "response_total"
  | "response_rate"
  | "response_completion_rate"
  | "response_started"
  | "last_surveys"
  | "help"
  | "changelog"
  | "entitlement_mtu"
  | "quote_of_the_day";

class OrgFlagsHomeGridCell extends Serializable {
  constructor(
    public width?: "small" | "medium" | "full",
    public height?: string,
    public type?: OrgFlagsHomeGridCellType,
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);
    this.width = json["width"];
    this.height = json["height"];
    this.type = json["type"] as OrgFlagsHomeGridCellType;
    return this;
  }
}

class OrgReport extends Serializable {
  constructor(
    public id?: string,
    public org_id?: string,
    public report_type?: string,
    public is_public?: boolean,
    public widgets?: OrgFlagsHomeGridCell[],
    public range?: OrgFlagsHomeGridRange,
    public start_date?: Date,
    public end_date?: Date,
    public created_by?: Date,
    public updated_by?: Date,
    public created_at?: Date,
    public updated_at?: Date,
  ) {
    super();
  }

  public fromJson(json: object): this {
    super.fromJson(json);
    this.id = json["id"];
    this.org_id = json["org_id"];
    this.report_type = json["report_type"];
    this.is_public = json["is_public"];
    this.widgets = json["widgets"];
    this.range = json["range"];
    this.start_date = json["start_date"];
    this.end_date = json["end_date"];
    this.created_by = json["created_by"];
    this.updated_by = json["updated_by"];
    this.created_at = json["created_at"];
    this.updated_at = json["updated_at"];
    return this;
  }
}

class OrgEntitlements extends Serializable {
  constructor(
    public max_mtu?: number, // -1 if unlimited

    public device_tracking_forced?: boolean,
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);
    return this;
  }
}

class OrgDataGovernance extends Serializable {
  constructor(
    public retention_policy_tracking_days_user_override?: number,
    public retention_policy_responses_days_user_override?: number,
    public retention_policy_sessions_days_user_override?: number,
    public retention_policy_tracking_days?: number,
    public retention_policy_responses_days?: number,
    public retention_policy_sessions_days?: number,
    public registry_limit_context_property?: number,
    public registry_limit_identity_property?: number,
    public registry_limit_group_type?: number,
    public registry_limit_group_type_property?: number,
    public registry_limit_group?: number,
    public registry_limit_track?: number,
    public registry_limit_track_property?: number,
    public registry_limit_screen?: number,
    public registry_limit_screen_property?: number,
    public tracking_capability_cohort_enabled?: boolean,
    public tracking_capability_page_enabled?: boolean,
    public tracking_capability_screen_enabled?: boolean,
    public tracking_capability_device_enabled?: boolean,
    public tracking_capability_ip_enabled?: boolean,
    public tracking_capability_geoip_enabled?: boolean,
    public tracking_capability_click_enabled?: boolean,
    public tracking_capability_tap_enabled?: boolean,
    public tracking_capability_copy_enabled?: boolean,
    public tracking_capability_paste_enabled?: boolean,
    public tracking_capability_highlight_enabled?: boolean,
    public tracking_capability_zoom_enabled?: boolean,
    public tracking_capability_rage_click_enabled?: boolean,
    public tracking_capability_dead_click_enabled?: boolean,
    public tracking_capability_thrashing_click_enabled?: boolean,
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);
    return this;
  }
}

class OrgStats extends Serializable {
  constructor(
    // forever stats
    public total_respondents?: number, // Overall respondents (count)
    public total_events_track?: number, // Overall stored track events (count)
    public total_events_screen?: number, // Overall stored screen events (count)
    public total_identity_properties?: number, // Overall stored identity properties updates for every respondents (count)
    public total_group_assignations?: number, // Overall stored group assignations for every respondents (count)
    public total_survey_question_video_uploaded?: number, // Overall uploaded survey question video (count)
    public total_survey_question_video_size_uploaded?: number, // Overall uploaded survey question video size (in bytes)
    public total_response_answer_media_uploaded?: number, // Overall uploaded response answer media (count)
    public total_response_answer_media_size_uploaded?: number, // Overall uploaded response answer media size (in bytes)
    public total_survey_responses?: number, // Total survey responses, whatever the completion
    public total_survey_responses_not_started?: number, // Total survey responses, when completion is not_started
    public total_survey_responses_partially_completed?: number, // Total survey responses, when completion is partially_completed
    public total_survey_responses_fully_completed?: number, // Total survey responses, when completion is fully_completed
    public total_survey_responses_started?: number, // Total survey responses, when completion is partially_completed or fully_completed
    public total_survey_inapp_responses?: number, // Total survey inapp responses, whatever the completion
    public total_survey_inapp_responses_not_started?: number, // Total survey inapp responses, when completion is not_started
    public total_survey_inapp_responses_partially_completed?: number, // Total survey inapp responses, when completion is partially_completed
    public total_survey_inapp_responses_fully_completed?: number, // Total survey inapp responses, when completion is fully_completed
    public total_survey_inapp_responses_started?: number, // Total survey inapp responses, when completion is partially_completed or fully_completed
    public total_survey_inpage_responses?: number, // Total survey inpage responses, whatever the completion
    public total_survey_inpage_responses_not_started?: number, // Total survey inpage responses, when completion is not_started
    public total_survey_inpage_responses_partially_completed?: number, // Total survey inpage responses, when completion is partially_completed
    public total_survey_inpage_responses_fully_completed?: number, // Total survey inpage responses, when completion is fully_completed
    public total_survey_inpage_responses_started?: number, // Total survey inpage responses, when completion is partially_completed or fully_completed
    public total_survey_btn_responses?: number, // Total survey btn responses, whatever the completion
    public total_survey_btn_responses_not_started?: number, // Total survey btn responses, when completion is not_started
    public total_survey_btn_responses_partially_completed?: number, // Total survey btn responses, when completion is partially_completed
    public total_survey_btn_responses_fully_completed?: number, // Total survey btn responses, when completion is fully_completed
    public total_survey_btn_responses_started?: number, // Total survey btn responses, when completion is partially_completed or fully_completed
    public total_survey_email_responses?: number, // Total survey email responses, whatever the completion
    public total_survey_email_responses_not_started?: number, // Total survey email responses, when completion is not_started
    public total_survey_email_responses_partially_completed?: number, // Total survey email responses, when completion is partially_completed
    public total_survey_email_responses_fully_completed?: number, // Total survey email responses, when completion is fully_completed
    public total_survey_email_responses_started?: number, // Total survey email responses, when completion is partially_completed or fully_completed
    public total_survey_link_responses?: number, // Total survey link responses, whatever the completion
    public total_survey_link_responses_not_started?: number, // Total survey link responses, when completion is not_started
    public total_survey_link_responses_partially_completed?: number, // Total survey link responses, when completion is partially_completed
    public total_survey_link_responses_fully_completed?: number, // Total survey link responses, when completion is fully_completed
    public total_survey_link_responses_started?: number, // Total survey link responses, when completion is partially_completed or fully_completed
    public total_message_responses?: number, // Total message responses, whatever the completion
    public total_message_responses_not_started?: number, // Total message responses, when completion is not_started
    public total_message_responses_partially_completed?: number, // Total message responses, when completion is partially_completed
    public total_message_responses_fully_completed?: number, // Total message responses, when completion is fully_completed
    public total_message_responses_started?: number, // Total message responses, when completion is partially_completed or fully_completed
    // 30 rolling days stats
    public rolling_month_respondents?: number, // number of respondents in the last 30 days (count)
    public rolling_month_events_track?: number, // number of stored track events in the last 30 days (count)
    public rolling_month_events_screen?: number, // number of stored screen events in the last 30 days (count)
    public rolling_month_identity_properties?: number, // number of stored identity properties updates for every respondents in the last 30 days (count)
    public rolling_month_group_assignations?: number, // number of stored group assignations for every respondents in the last 30 days (count)
    public rolling_month_survey_question_video_uploaded?: number, // number of uploaded survey question video in the last 30 days (count)
    public rolling_month_survey_question_video_size_uploaded?: number, // number of uploaded survey question video size in the last 30 days (in bytes)
    public rolling_month_survey_response_media_uploaded?: number, // number of uploaded response answer media in the last 30 days (count)
    public rolling_month_survey_response_media_size_uploaded?: number, // number of uploaded response answer media size in the last 30 days (in bytes)
    public rolling_month_survey_responses?: number, // Monthly survey responses, whatever the completion
    public rolling_month_survey_responses_not_started?: number, // Monthly survey responses, when completion is not_started
    public rolling_month_survey_responses_partially_completed?: number, // Monthly survey responses, when completion is partially_completed
    public rolling_month_survey_responses_fully_completed?: number, // Monthly survey responses, when completion is fully_completed
    public rolling_month_survey_responses_started?: number, // Monthly survey responses, when completion is partially_completed or fully_completed
    public rolling_month_survey_inapp_responses?: number, // rolling_month survey inapp responses, whatever the completion
    public rolling_month_survey_inapp_responses_not_started?: number, // rolling_month survey inapp responses, when completion is not_started
    public rolling_month_survey_inapp_responses_partially_completed?: number, // rolling_month survey inapp responses, when completion is partially_completed
    public rolling_month_survey_inapp_responses_fully_completed?: number, // rolling_month survey inapp responses, when completion is fully_completed
    public rolling_month_survey_inapp_responses_started?: number, // rolling_month survey inapp responses, when completion is partially_completed or fully_completed
    public rolling_month_survey_inpage_responses?: number, // rolling_month survey inpage responses, whatever the completion
    public rolling_month_survey_inpage_responses_not_started?: number, // rolling_month survey inpage responses, when completion is not_started
    public rolling_month_survey_inpage_responses_partially_completed?: number, // rolling_month survey inpage responses, when completion is partially_completed
    public rolling_month_survey_inpage_responses_fully_completed?: number, // rolling_month survey inpage responses, when completion is fully_completed
    public rolling_month_survey_inpage_responses_started?: number, // rolling_month survey inpage responses, when completion is partially_completed or fully_completed
    public rolling_month_survey_btn_responses?: number, // rolling_month survey btn responses, whatever the completion
    public rolling_month_survey_btn_responses_not_started?: number, // rolling_month survey btn responses, when completion is not_started
    public rolling_month_survey_btn_responses_partially_completed?: number, // rolling_month survey btn responses, when completion is partially_completed
    public rolling_month_survey_btn_responses_fully_completed?: number, // rolling_month survey btn responses, when completion is fully_completed
    public rolling_month_survey_btn_responses_started?: number, // rolling_month survey btn responses, when completion is partially_completed or fully_completed
    public rolling_month_survey_email_responses?: number, // rolling_month survey email responses, whatever the completion
    public rolling_month_survey_email_responses_not_started?: number, // rolling_month survey email responses, when completion is not_started
    public rolling_month_survey_email_responses_partially_completed?: number, // rolling_month survey email responses, when completion is partially_completed
    public rolling_month_survey_email_responses_fully_completed?: number, // rolling_month survey email responses, when completion is fully_completed
    public rolling_month_survey_email_responses_started?: number, // rolling_month survey email responses, when completion is partially_completed or fully_completed
    public rolling_month_survey_link_responses?: number, // rolling_month survey link responses, whatever the completion
    public rolling_month_survey_link_responses_not_started?: number, // rolling_month survey link responses, when completion is not_started
    public rolling_month_survey_link_responses_partially_completed?: number, // rolling_month survey link responses, when completion is partially_completed
    public rolling_month_survey_link_responses_fully_completed?: number, // rolling_month survey link responses, when completion is fully_completed
    public rolling_month_survey_link_responses_started?: number, // rolling_month survey link responses, when completion is partially_completed or fully_completed
    public rolling_month_message_responses?: number, // Monthly message responses, whatever the completion
    public rolling_month_message_responses_not_started?: number, // Monthly message responses, when completion is not_started
    public rolling_month_message_responses_partially_completed?: number, // Monthly message responses, when completion is partially_completed
    public rolling_month_message_responses_fully_completed?: number, // Monthly message responses, when completion is fully_completed
    public rolling_month_message_responses_started?: number, // Monthly message responses, when completion is partially_completed or fully_completed
    // 7 rolling days stats
    public rolling_week_respondents?: number, // number of respondents in the last 7 days (count)
    public rolling_week_events_track?: number, // number of stored track events in the last 7 days (count)
    public rolling_week_events_screen?: number, // number of stored screen events in the last 7 days (count)
    public rolling_week_identity_properties?: number, // number of stored identity properties updates for every respondents in the last 7 days (count)
    public rolling_week_group_assignations?: number, // number of stored group assignations for every respondents in the last 7 days (count)
    public rolling_week_survey_responses?: number, // weekly survey responses, whatever the completion
    public rolling_week_survey_responses_not_started?: number, // weekly survey responses, when completion is not_started
    public rolling_week_survey_responses_partially_completed?: number, // weekly survey responses, when completion is partially_completed
    public rolling_week_survey_responses_fully_completed?: number, // weekly survey responses, when completion is fully_completed
    public rolling_week_survey_responses_started?: number, // weekly survey responses, when completion is partially_completed or fully_completed
    public rolling_week_survey_inapp_responses?: number, // rolling_week survey inapp responses, whatever the completion
    public rolling_week_survey_inapp_responses_not_started?: number, // rolling_week survey inapp responses, when completion is not_started
    public rolling_week_survey_inapp_responses_partially_completed?: number, // rolling_week survey inapp responses, when completion is partially_completed
    public rolling_week_survey_inapp_responses_fully_completed?: number, // rolling_week survey inapp responses, when completion is fully_completed
    public rolling_week_survey_inapp_responses_started?: number, // rolling_week survey inapp responses, when completion is partially_completed or fully_completed
    public rolling_week_survey_inpage_responses?: number, // rolling_week survey inpage responses, whatever the completion
    public rolling_week_survey_inpage_responses_not_started?: number, // rolling_week survey inpage responses, when completion is not_started
    public rolling_week_survey_inpage_responses_partially_completed?: number, // rolling_week survey inpage responses, when completion is partially_completed
    public rolling_week_survey_inpage_responses_fully_completed?: number, // rolling_week survey inpage responses, when completion is fully_completed
    public rolling_week_survey_inpage_responses_started?: number, // rolling_week survey inpage responses, when completion is partially_completed or fully_completed
    public rolling_week_survey_btn_responses?: number, // rolling_week survey btn responses, whatever the completion
    public rolling_week_survey_btn_responses_not_started?: number, // rolling_week survey btn responses, when completion is not_started
    public rolling_week_survey_btn_responses_partially_completed?: number, // rolling_week survey btn responses, when completion is partially_completed
    public rolling_week_survey_btn_responses_fully_completed?: number, // rolling_week survey btn responses, when completion is fully_completed
    public rolling_week_survey_btn_responses_started?: number, // rolling_week survey btn responses, when completion is partially_completed or fully_completed
    public rolling_week_survey_email_responses?: number, // rolling_week survey email responses, whatever the completion
    public rolling_week_survey_email_responses_not_started?: number, // rolling_week survey email responses, when completion is not_started
    public rolling_week_survey_email_responses_partially_completed?: number, // rolling_week survey email responses, when completion is partially_completed
    public rolling_week_survey_email_responses_fully_completed?: number, // rolling_week survey email responses, when completion is fully_completed
    public rolling_week_survey_email_responses_started?: number, // rolling_week survey email responses, when completion is partially_completed or fully_completed
    public rolling_week_survey_link_responses?: number, // rolling_week survey link responses, whatever the completion
    public rolling_week_survey_link_responses_not_started?: number, // rolling_week survey link responses, when completion is not_started
    public rolling_week_survey_link_responses_partially_completed?: number, // rolling_week survey link responses, when completion is partially_completed
    public rolling_week_survey_link_responses_fully_completed?: number, // rolling_week survey link responses, when completion is fully_completed
    public rolling_week_survey_link_responses_started?: number, // rolling_week survey link responses, when completion is partially_completed or fully_completed
    public rolling_week_message_responses?: number, // weekly message responses, whatever the completion
    public rolling_week_message_responses_not_started?: number, // weekly message responses, when completion is not_started
    public rolling_week_message_responses_partially_completed?: number, // weekly message responses, when completion is partially_completed
    public rolling_week_message_responses_fully_completed?: number, // weekly message responses, when completion is fully_completed
    public rolling_week_message_responses_started?: number, // weekly message responses, when completion is partially_completed or fully_completed
    // 1 rolling day stats
    public rolling_day_respondents?: number, // number of respondents in the last 1 day (count)
    public rolling_day_events_track?: number, // number of stored track events in the last 1 day (count)
    public rolling_day_events_screen?: number, // number of stored screen events in the last 1 day (count)
    public rolling_day_identity_properties?: number, // number of stored identity properties updates for every respondents in the last 1 day (count)
    public rolling_day_group_assignations?: number, // number of stored group assignations for every respondents in the last 1 day (count)
    public rolling_day_survey_responses?: number, // daily survey responses, whatever the completion
    public rolling_day_survey_responses_not_started?: number, // daily survey responses, when completion is not_started
    public rolling_day_survey_responses_partially_completed?: number, // daily survey responses, when completion is partially_completed
    public rolling_day_survey_responses_fully_completed?: number, // daily survey responses, when completion is fully_completed
    public rolling_day_survey_responses_started?: number, // daily survey responses, when completion is partially_completed or fully_completed
    public rolling_day_survey_inapp_responses?: number, // rolling_day survey inapp responses, whatever the completion
    public rolling_day_survey_inapp_responses_not_started?: number, // rolling_day survey inapp responses, when completion is not_started
    public rolling_day_survey_inapp_responses_partially_completed?: number, // rolling_day survey inapp responses, when completion is partially_completed
    public rolling_day_survey_inapp_responses_fully_completed?: number, // rolling_day survey inapp responses, when completion is fully_completed
    public rolling_day_survey_inapp_responses_started?: number, // rolling_day survey inapp responses, when completion is partially_completed or fully_completed
    public rolling_day_survey_inpage_responses?: number, // rolling_day survey inpage responses, whatever the completion
    public rolling_day_survey_inpage_responses_not_started?: number, // rolling_day survey inpage responses, when completion is not_started
    public rolling_day_survey_inpage_responses_partially_completed?: number, // rolling_day survey inpage responses, when completion is partially_completed
    public rolling_day_survey_inpage_responses_fully_completed?: number, // rolling_day survey inpage responses, when completion is fully_completed
    public rolling_day_survey_inpage_responses_started?: number, // rolling_day survey inpage responses, when completion is partially_completed or fully_completed
    public rolling_day_survey_btn_responses?: number, // rolling_day survey btn responses, whatever the completion
    public rolling_day_survey_btn_responses_not_started?: number, // rolling_day survey btn responses, when completion is not_started
    public rolling_day_survey_btn_responses_partially_completed?: number, // rolling_day survey btn responses, when completion is partially_completed
    public rolling_day_survey_btn_responses_fully_completed?: number, // rolling_day survey btn responses, when completion is fully_completed
    public rolling_day_survey_btn_responses_started?: number, // rolling_day survey btn responses, when completion is partially_completed or fully_completed
    public rolling_day_survey_email_responses?: number, // rolling_day survey email responses, whatever the completion
    public rolling_day_survey_email_responses_not_started?: number, // rolling_day survey email responses, when completion is not_started
    public rolling_day_survey_email_responses_partially_completed?: number, // rolling_day survey email responses, when completion is partially_completed
    public rolling_day_survey_email_responses_fully_completed?: number, // rolling_day survey email responses, when completion is fully_completed
    public rolling_day_survey_email_responses_started?: number, // rolling_day survey email responses, when completion is partially_completed or fully_completed
    public rolling_day_survey_link_responses?: number, // rolling_day survey link responses, whatever the completion
    public rolling_day_survey_link_responses_not_started?: number, // rolling_day survey link responses, when completion is not_started
    public rolling_day_survey_link_responses_partially_completed?: number, // rolling_day survey link responses, when completion is partially_completed
    public rolling_day_survey_link_responses_fully_completed?: number, // rolling_day survey link responses, when completion is fully_completed
    public rolling_day_survey_link_responses_started?: number, // rolling_day survey link responses, when completion is partially_completed or fully_completed
    public rolling_day_message_responses?: number, // daily message responses, whatever the completion
    public rolling_day_message_responses_not_started?: number, // daily message responses, when completion is not_started
    public rolling_day_message_responses_partially_completed?: number, // daily message responses, when completion is partially_completed
    public rolling_day_message_responses_fully_completed?: number, // daily message responses, when completion is fully_completed
    public rolling_day_message_responses_started?: number, // daily message responses, when completion is partially_completed or fully_completed
    // Stats since 1st day of the month
    public current_month_respondents?: number, // number of respondents since 1st day of the month (count)
    public current_month_events_track?: number, // number of stored track events since 1st day of the month (count)
    public current_month_events_screen?: number, // number of stored screen events since 1st day of the month (count)
    public current_month_identity_properties?: number, // number of stored identity properties updates for every respondents since 1st day of the month (count)
    public current_month_group_assignations?: number, // number of stored group assignations for every respondents since 1st day of the month (count)
    public current_month_survey_question_video_uploaded?: number, // number of uploaded survey question video since 1st day of the month (count)
    public current_month_survey_question_video_size_uploaded?: number, // number of uploaded survey question video size since 1st day of the month (in bytes)
    public current_month_response_answer_media_uploaded?: number, // number of uploaded response answer media since 1st day of the month (count)
    public current_month_response_answer_media_size_uploaded?: number, // number of uploaded response answer media size since 1st day of the month (in bytes)
    public current_month_survey_responses?: number, // Monthly survey responses, whatever the completion
    public current_month_survey_responses_not_started?: number, // Monthly survey responses, when completion is not_started
    public current_month_survey_responses_partially_completed?: number, // Monthly survey responses, when completion is partially_completed
    public current_month_survey_responses_fully_completed?: number, // Monthly survey responses, when completion is fully_completed
    public current_month_survey_responses_started?: number, // Monthly survey responses, when completion is partially_completed or fully_completed
    public current_month_survey_inapp_responses?: number, // current_month survey inapp responses, whatever the completion
    public current_month_survey_inapp_responses_not_started?: number, // current_month survey inapp responses, when completion is not_started
    public current_month_survey_inapp_responses_partially_completed?: number, // current_month survey inapp responses, when completion is partially_completed
    public current_month_survey_inapp_responses_fully_completed?: number, // current_month survey inapp responses, when completion is fully_completed
    public current_month_survey_inapp_responses_started?: number, // current_month survey inapp responses, when completion is partially_completed or fully_completed
    public current_month_survey_inpage_responses?: number, // current_month survey inpage responses, whatever the completion
    public current_month_survey_inpage_responses_not_started?: number, // current_month survey inpage responses, when completion is not_started
    public current_month_survey_inpage_responses_partially_completed?: number, // current_month survey inpage responses, when completion is partially_completed
    public current_month_survey_inpage_responses_fully_completed?: number, // current_month survey inpage responses, when completion is fully_completed
    public current_month_survey_inpage_responses_started?: number, // current_month survey inpage responses, when completion is partially_completed or fully_completed
    public current_month_survey_btn_responses?: number, // current_month survey btn responses, whatever the completion
    public current_month_survey_btn_responses_not_started?: number, // current_month survey btn responses, when completion is not_started
    public current_month_survey_btn_responses_partially_completed?: number, // current_month survey btn responses, when completion is partially_completed
    public current_month_survey_btn_responses_fully_completed?: number, // current_month survey btn responses, when completion is fully_completed
    public current_month_survey_btn_responses_started?: number, // current_month survey btn responses, when completion is partially_completed or fully_completed
    public current_month_survey_email_responses?: number, // current_month survey email responses, whatever the completion
    public current_month_survey_email_responses_not_started?: number, // current_month survey email responses, when completion is not_started
    public current_month_survey_email_responses_partially_completed?: number, // current_month survey email responses, when completion is partially_completed
    public current_month_survey_email_responses_fully_completed?: number, // current_month survey email responses, when completion is fully_completed
    public current_month_survey_email_responses_started?: number, // current_month survey email responses, when completion is partially_completed or fully_completed
    public current_month_survey_link_responses?: number, // current_month survey link responses, whatever the completion
    public current_month_survey_link_responses_not_started?: number, // current_month survey link responses, when completion is not_started
    public current_month_survey_link_responses_partially_completed?: number, // current_month survey link responses, when completion is partially_completed
    public current_month_survey_link_responses_fully_completed?: number, // current_month survey link responses, when completion is fully_completed
    public current_month_survey_link_responses_started?: number, // current_month survey link responses, when completion is partially_completed or fully_completed
    public current_month_message_responses?: number, // Monthly message responses, whatever the completion
    public current_month_message_responses_not_started?: number, // Monthly message responses, when completion is not_started
    public current_month_message_responses_partially_completed?: number, // Monthly message responses, when completion is partially_completed
    public current_month_message_responses_fully_completed?: number, // Monthly message responses, when completion is fully_completed
    public current_month_message_responses_started?: number, // Monthly message responses, when completion is partially_completed or fully_completed

    public total_nps_score?: number, // Overall NPS score (count)
    public total_average_nps_promoters?: number, // Overall average of NPS scores between 9 and 10 (percent)
    public total_average_nps_passives?: number, // Overall average of NPS scores between 7 and 8 (percent)
    public total_average_nps_detractors?: number, // Overall average of NPS scores between 0 and 6 (percent)
    public total_nps_score_repartition?: Record<number, number>, // Overall NPS score repartition per score (count)

    public total_ces_score?: number, // Overall CES score (count)
    public total_average_ces_high_effort?: number, // Overall average of CES scores between 7 and 10 (percent)
    public total_average_ces_medium_effort?: number, // Overall average of CES scores between 4 and 6 (percent)
    public total_average_ces_low_effort?: number, // Overall average of CES scores between 0 and 3 (percent)
    public total_ces_score_repartition?: Record<number, number>, // Overall CES score repartition per score (count)

    public total_csat_score?: number, // Overall CSAT score (count)
    public total_average_csat_low_score?: number, // Overall average of CSAT scores between 0 and 2 (percent)
    public total_csat_score_repartition?: Record<number, number>, // Overall CSAT score repartition per score (count)

    public total_response_not_started?: number, // Overall response not started (count)
    public total_response_partially_completed?: number, // Overall response partially completed (count)
    public total_response_fully_completed?: number, // Overall response fully completed (count)

    public total_survey_response_started_rate?: number, // Overall response started rate (partially + fully completed) (percent)
    public total_survey_response_not_started_rate?: number, // Overall response not started rate (percent)
    public total_survey_response_partially_completed_rate?: number, // Overall response partially completed rate (percent)
    public total_survey_response_fully_completed_rate?: number, // Overall response fully completed rate (percent)

    public total_sessions?: number, // Overall sessions (count)
    public rolling_month_sessions?: number, // Monthly sessions (count)
    public rolling_week_sessions?: number, // Weekly sessions (count)
    public rolling_day_sessions?: number, // Daily sessions (count)
    public current_month_sessions?: number, // Monthly sessions (count)
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);
    return this;
  }
}

class OrgAnswerTags extends Serializable {
  constructor(
    public tags?: string[],
    public keywords?: string[],
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);
    return this;
  }
}

export {
  Org,
  OrgAnswerTags,
  OrgDataGovernance,
  OrgEntitlements,
  OrgFlags,
  OrgFlagsHomeGridCell,
  OrgFlagsHomeGridCellType,
  OrgFlagsHomeGridRange,
  OrgStats,
  OrgReport,
};
