import { Injectable } from "@angular/core";

import { ScreebApiHelper } from "helpers/screeb-api.helper";
import { Release } from "models/release.model";

@Injectable()
export class ReleaseDao {
  constructor(private screebApiHelper: ScreebApiHelper) {}

  public getAllByOrgId(orgId: string): Promise<Release[]> {
    return this.screebApiHelper
      .get<Release[]>(`/org/${orgId}/release`)
      .toPromise()
      .then((data: object[]) => {
        return data.map((c: object) => new Release().fromJson(c));
      });
  }
  public getPaginatedByOrgId(
    orgId: string,
    from: Date,
    size: number,
  ): Promise<Release[]> {
    return this.screebApiHelper
      .get<Release[]>(
        `/org/${orgId}/release?from=${from.getTime()}&size=${size}`,
      )
      .toPromise()
      .then((data: object[]) => {
        return data.map((c: object) => new Release().fromJson(c));
      });
  }
  public getById(orgId: string, id: string): Promise<Release> {
    return this.screebApiHelper
      .get<Release[]>(`/org/${orgId}/release/${id}`)
      .toPromise()
      .then((data: object) => {
        return new Release().fromJson(data);
      });
  }

  public deleteById(orgId: string, id: string): Promise<void> {
    return this.screebApiHelper
      .delete<void>(`/org/${orgId}/release/${id}`)
      .toPromise();
  }

  public create(
    orgId: string,
    name: string,
    description: string,
    version: string,
    tags: string[],
    releasedAt: Date,
  ): Promise<Release> {
    const body = {
      released_at: releasedAt,
      name,
      description,
      version,
      tags,
    };
    return this.screebApiHelper
      .post<Release>(`/org/${orgId}/release`, body)
      .toPromise()
      .then((data: object) => {
        return new Release().fromJson(data);
      });
  }

  public update(
    orgId: string,
    releaseId: string,
    name: string,
    description: string,
    version: string,
    tags: string[],
    releasedAt: Date,
  ): Promise<Release> {
    const body = {
      released_at: releasedAt,
      name,
      description,
      version,
      tags,
    };
    return this.screebApiHelper
      .put<Release>(`/org/${orgId}/release/${releaseId}`, body)
      .toPromise()
      .then((data: object) => {
        return new Release().fromJson(data);
      });
  }
}
