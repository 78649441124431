import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "onboarding-mobile-preview",
  template: `
    <embed
      style="width: 100%; height: 100%"
      #phonePreview
      src="/assets/preview/preview-onboarding-mobile.svg"
    />
  `,
})
export class OnboardingMobilePreviewComponent
  implements AfterViewInit, OnChanges
{
  @ViewChild("phonePreview") embed: ElementRef<HTMLEmbedElement>;

  @Input()
  public authorName = "";
  @Input()
  public authorJob = "";

  @Input()
  public authorProfilePicture = "";

  private loaded = false;
  constructor() {}

  ngAfterViewInit() {
    this.embed.nativeElement.onload = () => {
      this.setPreviewData();
      this.loaded = true;
    };
  }

  ngOnChanges({ authorName, authorJob, authorProfilePicture }: SimpleChanges) {
    if (!this.loaded) return;

    if (
      (!!authorName && authorName.previousValue !== authorName.currentValue) ||
      (!!authorJob && authorJob.previousValue !== authorJob.currentValue) ||
      (!!authorProfilePicture &&
        authorProfilePicture.previousValue !==
          authorProfilePicture.currentValue)
    ) {
      this.setPreviewData();
    }
  }

  private setPreviewData() {
    const svg = this.embed.nativeElement.getSVGDocument();
    const authorName = svg.querySelector(".author-name");
    const authorJob = svg.querySelector(".author-job");
    const authorPicture = svg.querySelector(
      ".author-profile-picture",
    ) as HTMLImageElement;

    authorName.textContent = this.authorName;
    authorPicture.src = this.authorProfilePicture;
    authorJob.textContent = this.authorJob;
  }
}
