import { GraphNode } from "components/builder/flow";
import { decode } from "html-entities";
import {
  CTAType,
  SurveyLanguages,
  getI18nTextLabelTranslation,
  getI18nVideoLabelTranslation,
  hasI18nTextLabelTranslation,
} from "models/survey.dao.types";
import {
  htmlSanitizer,
  stripHtml,
} from "../../Cards/sanitized-message/sanitizer";
import {
  BigCardContainer,
  CardContainerBase,
  CardContainerType,
  SmallCardContainer,
} from "../Models";
import { ButtonValidator } from "./ButtonValidator";
import { CalendarValidator } from "./CalendarValidator";
import { LinkValidator } from "./LinkValidator";

export type CardValidatorResult = {
  errors: string[][];
  warnings: string[][];
};

export type CardValidator = (
  node: GraphNode,
  currentLanguage: SurveyLanguages,
  defaultLanguage: SurveyLanguages,
) => CardValidatorResult; // Array of errors because some components need to have some granularity for UI purpose

const NoneValidator: CardValidator = () => ({
  errors: [],
  warnings: [],
});

const ComposedValidator =
  (...validators: CardValidator[]): CardValidator =>
  (
    node: GraphNode,
    currentLanguage: SurveyLanguages,
    defaultLanguage: SurveyLanguages,
  ) => {
    const errors: string[][] = [];
    const warnings: string[][] = [];
    for (const validator of validators) {
      const { errors: errs, warnings: warns } = validator(
        node,
        currentLanguage,
        defaultLanguage,
      );

      errors.push(...errs);
      warnings.push(...warns);
    }
    return {
      errors,
      warnings,
    };
  };

export function getLength(text: string) {
  return stripHtml(
    htmlSanitizer(text, {
      styling: true,
      CR: false,
      nativeCR: true,
      links: true,
    }),
  ).length;
}

/**
 * Big card validators
 */

const TextValidator: CardValidator = (
  node: GraphNode,
  currentLanguage: SurveyLanguages,
  defaultLanguage: SurveyLanguages,
) => {
  if (node.node.question.type !== "survey")
    throw new Error("unexpected question type");

  const message = node.node.question.messages[0];

  if (message.type !== "text") throw new Error("unexpected message type");

  const textErrors: string[] = [];
  const warningErrors: string[] = [];

  if (!hasI18nTextLabelTranslation(message.text, currentLanguage)) {
    warningErrors.push("Missing translation");
  }

  // @TODO: the translation should be null when no value, instead of ''
  const defaultText = decode(
    getI18nTextLabelTranslation(message.text, currentLanguage, currentLanguage),
  );
  const text = defaultText?.trim() || "";
  const textLength = getLength(text);

  if (textLength < 1 && currentLanguage === defaultLanguage) {
    textErrors.push("Default translation is missing.");
  }
  if (textLength > 250) {
    textErrors.push("Max 250 characters allowed.");
  }

  return {
    errors: [textErrors],
    warnings: [warningErrors],
  };
};
const VideoValidator: CardValidator = (
  node: GraphNode,
  currentLanguage: SurveyLanguages,
  defaultLanguage: SurveyLanguages,
) => {
  if (node.node.question.type !== "survey")
    throw new Error("unexpected question type");

  const message = node.node.question.messages[0];

  if (message.type !== "video") throw new Error("unexpected message type");

  const videoErrors: string[] = [];
  const overlayErrors: string[] = [];

  const defaultFileData = getI18nVideoLabelTranslation(
    message.video,
    currentLanguage,
    currentLanguage,
  );

  if (!defaultFileData) {
    if (currentLanguage === defaultLanguage)
      videoErrors.push("Default Video is required");

    return { errors: [videoErrors, []], warnings: [] };
  } else if (!defaultFileData.url) {
    if (currentLanguage === defaultLanguage)
      videoErrors.push("Default Video is required");
  }

  const overlay = defaultFileData.overlay?.trim() || "";

  if (getLength(overlay) > 100) {
    overlayErrors.push("Overlay: Max 100 characters allowed.");
  }

  return {
    errors: [videoErrors, overlayErrors],
    warnings: [],
  };
};
export class BigCardValidatorFactory {
  static getValidator(card: BigCardContainer): CardValidator {
    if (card.node.node.question.type !== "survey") return NoneValidator;

    const message = card.node.node.question.messages[0];

    if (!message) {
      if (card.node.questionType === "appstore_rating") return NoneValidator;

      throw new Error("No message found for card");
    }

    const messageType = message.type;

    switch (messageType) {
      case "text":
        return TextValidator;
      case "video":
        return VideoValidator;
      default:
        throw new Error(`Unknown card node type: ${messageType}`);
    }
  }

  static getValidatorFromNode(node: GraphNode) {
    if (node.node.question.type !== "survey")
      throw new Error("unexpected question type");

    const message = node.node.question.messages[0];
    const messageType = message.type;

    switch (messageType) {
      case "text":
        return TextValidator;
      case "video":
        return VideoValidator;
      default:
        throw new Error(`Unknown card node type: ${messageType}`);
    }
  }
}

/**
 * Question validators
 */

const ScoreValidator = NoneValidator;

export class QuestionValidatorFactory {
  private static getValidatorInternal(cta: CTAType): CardValidator {
    switch (cta) {
      case "scoring":
      case "nps":
      case "csat":
      case "ces":
        return ScoreValidator;
      case "multiple_choice":
      case "pmf":
        return ButtonValidator;
      case "input":
        return NoneValidator;
      case "appstore_rating":
        return NoneValidator;
      case "link":
        return LinkValidator;
      case "calendar":
        return CalendarValidator;
      case "range":
        return NoneValidator;
      case "none":
        return NoneValidator;
    }
  }

  static getValidator(card: SmallCardContainer): CardValidator {
    const cta = card.node.questionType;
    return this.getValidatorInternal(cta);
  }

  static getValidatorFromBigCard(card: BigCardContainer): CardValidator {
    const cta = card.node.questionType;

    switch (cta) {
      case "calendar":
        return CalendarValidator;

      default:
        return NoneValidator;
    }
  }

  static getValidatorFromNode(node: GraphNode) {
    if (node.node.question.type !== "survey")
      throw new Error("unexpected question type");

    const cta = node.questionType;

    return this.getValidatorInternal(cta);
  }
}

export class CardValidatorFactory {
  static getValidator(card: CardContainerBase): CardValidator {
    switch (card.component) {
      case CardContainerType.BigCard:
        const titleValidator = BigCardValidatorFactory.getValidator(
          card as BigCardContainer,
        );

        // Some questions types doesn't have options, and so question validation is located in the big card
        const questionValidator =
          QuestionValidatorFactory.getValidatorFromBigCard(
            card as BigCardContainer,
          );

        return ComposedValidator(titleValidator, questionValidator);
      case CardContainerType.SmallCard:
        return QuestionValidatorFactory.getValidator(
          card as SmallCardContainer,
        );
      case CardContainerType.InvisibleCard:
        return NoneValidator;
      default:
        throw new Error(`Unknown card type: ${card.component}`);
    }
  }
}
