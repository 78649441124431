import { Injectable } from "@angular/core";

import { ScreebApiHelper } from "helpers/screeb-api.helper";
import { TargetingRule } from "./targeting-rule.model";
import { SurveyDistribution } from "./survey-distribution.model";

@Injectable()
export class SurveyDistributionDao {
  constructor(private screebApiHelper: ScreebApiHelper) {}

  public getById(
    orgId: string,
    surveyId: string,
    distributionId: string,
  ): Promise<SurveyDistribution> {
    return this.screebApiHelper
      .get<SurveyDistribution>(
        `/org/${orgId}/survey/${surveyId}/distribution/${distributionId}`,
      )
      .toPromise()
      .then((data: object) => {
        return new SurveyDistribution().fromJson(data);
      });
  }

  public getAllBySurveyId(
    orgId: string,
    surveyId: string,
  ): Promise<SurveyDistribution[]> {
    return this.screebApiHelper
      .get<SurveyDistribution[]>(
        `/org/${orgId}/survey/${surveyId}/distribution`,
      )
      .toPromise()
      .then((data: object[]) => {
        return data.map((c: object) => new SurveyDistribution().fromJson(c));
      });
  }

  public _update(
    orgId: string,
    surveyId: string,
    distributionId: string,
    enabled: boolean | null,
    targeting_rules: TargetingRule[] | null,
    selector_css: string | null,
  ) {
    let selectors_css_a: string[];
    if (selector_css) {
      selectors_css_a = [];
      selectors_css_a.push(selector_css);
    }

    return this.screebApiHelper
      .patch<SurveyDistribution>(
        `/org/${orgId}/survey/${surveyId}/distribution/${distributionId}`,
        { enabled, targeting_rules, selectors_css: selectors_css_a },
      )
      .toPromise()
      .then((data: SurveyDistribution) => {
        return new SurveyDistribution().fromJson(data);
      });
  }

  public updateStatus(
    orgId: string,
    surveyId: string,
    distributionId: string,
    enabled: boolean,
    selector_css: string,
  ) {
    return this._update(
      orgId,
      surveyId,
      distributionId,
      enabled,
      null,
      selector_css,
    );
  }

  public updateTargetingRules(
    orgId: string,
    surveyId: string,
    distributionId: string,
    targeting_rules: TargetingRule[],
    selector_css: string | undefined,
  ) {
    return this._update(
      orgId,
      surveyId,
      distributionId,
      null,
      targeting_rules,
      selector_css,
    );
  }
}
