import { Component, Input } from "@angular/core";
import {
  RegistryEntrySource,
  RegistryEntrySourceFormatted,
  sortedRegistryEntrySources,
} from "models/registry.model";

@Component({
  selector: "tag-registry-entry-source",
  templateUrl: "./tag-registry-entry-source.component.html",
  styleUrls: ["./tag-registry-entry-source.component.scss"],
})
export class TagRegistryEntrySourceComponent {
  @Input() public size: "small" | "medium" | "large";
  @Input() public sources: RegistryEntrySource[];

  public sortedRegistryEntrySources = sortedRegistryEntrySources;
  public registryEntrySourceFormatted = RegistryEntrySourceFormatted;

  constructor() {}
}
