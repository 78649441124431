import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { ChartDataset } from "chart.js";

import { AnalyticsFilterService } from "services/analytics-filters.service";
import { AnalyticsResponse } from "models/analytics.model";
import { AnalyticsDao } from "models/analytics.dao";
import { TrackersService } from "services/trackers.service";
import { RoutingService } from "services/routing.service";
import {
  BarClickEvent,
  RepartitionIndicatorValues,
} from "../components/repartition-indicator/repartition-indicator.component";
import { IndustriesScores } from "resolvers/asset-industries-scores";
import { TrendIndicatorLegend } from "../components/trend-indicator/trend-indicator.config";
import { IndicatorStatsSurveyPageComponent } from "../indicator.component";
import {
  bucketToDatedAggregation,
  getDateAnswerValueBuckets,
  getIndustryAverageScore,
} from "../indicator.utils";
import {
  bucketToNpsAggregation,
  computeNpsPeriod,
  computeNpsRepartition,
  computeNpsVariationBewteenPeriod,
  NpsAggregation,
  npsEmojis,
  npsPerDateToChartDataset,
} from "./nps.aggregation";
import { Release } from "models/release.model";
import { Account } from "models/account.model";
import { UIService } from "services/ui.service";

@Component({
  selector: "page-survey-stats-indicators-nps",
  templateUrl: "./nps.component.html",
  styleUrls: ["./nps.component.scss"],
})
export class NPSIndicatorStatsSurveyPageComponent
  extends IndicatorStatsSurveyPageComponent
  implements OnInit, OnDestroy
{
  public title = "Analyze - Net Promoter Score";
  public name = "Analyze net promoter score";

  public npsPerDatePreviousPeriod: NpsAggregation[] = null;
  public npsPerDateCurrentPeriod: NpsAggregation[] = null;
  public npsPreviousPeriod: NpsAggregation = null;
  public npsCurrentPeriod: NpsAggregation = null;
  public npsVariation: NpsAggregation = null;
  public npsRepartition: RepartitionIndicatorValues = [];

  public industryAverageNps = 50;

  public trendChartDataset: ChartDataset[] = [];

  public releases: Release[] = [];
  public orgAccounts: Account[] = [];
  public trendChartLegend: TrendIndicatorLegend = [
    {
      label: "NPS",
      value: "NPS",
      checked: true,
      color: "#5E21F1",
      hoverColor: "#5E21F1",
      format: "number",
    },
    {
      label: "Detractors",
      value: "Detractors",
      checked: true,
      color: "#FFD6DD",
      hoverColor: "#F11672",
    },
    {
      label: "Passives",
      value: "Passives",
      checked: true,
      color: "#FFF2D6",
      hoverColor: "#FFC046",
    },
    {
      label: "Promoters",
      value: "Promoters",
      checked: true,
      color: "#ABF3E0",
      hoverColor: "#1ED5A4",
    },
  ];

  constructor(
    router: Router,
    route: ActivatedRoute,
    routingService: RoutingService,
    analyticsFilterService: AnalyticsFilterService,
    analyticsDao: AnalyticsDao,
    public uiService: UIService,
    trackersService: TrackersService,
  ) {
    super(
      router,
      route,
      routingService,
      analyticsDao,
      analyticsFilterService,
      trackersService,
      uiService,
    );
  }

  ngOnInit() {
    this.obs = this.route.data.subscribe((data) => {
      this.releases = data.releases;
      this.orgAccounts = data.orgAccounts;

      this.industryAverageNps = getIndustryAverageScore(
        data.industriesScores as IndustriesScores,
        "nps",
        data.org.industry,
      );
    });

    this.init();
    this.resetFilters();
  }

  ngOnDestroy() {
    this.destroy();
  }

  async getResponseCallback(
    previousPeriod: AnalyticsResponse,
    currentPeriod: AnalyticsResponse,
  ) {
    this.npsPerDatePreviousPeriod = bucketToDatedAggregation(
      previousPeriod,
      (buckets) =>
        bucketToNpsAggregation(buckets, getDateAnswerValueBuckets, [
          this.survey.scenario,
        ]),
    );
    this.npsPerDateCurrentPeriod = bucketToDatedAggregation(
      currentPeriod,
      (buckets) =>
        bucketToNpsAggregation(buckets, getDateAnswerValueBuckets, [
          this.survey.scenario,
        ]),
    );

    this.trendChartDataset = npsPerDateToChartDataset(
      this.npsPerDateCurrentPeriod,
      this.trendChartLegend,
    );

    this.npsPreviousPeriod = computeNpsPeriod(this.npsPerDatePreviousPeriod);
    this.npsCurrentPeriod = computeNpsPeriod(this.npsPerDateCurrentPeriod);

    this.npsVariation = computeNpsVariationBewteenPeriod(
      this.npsPreviousPeriod,
      this.npsCurrentPeriod,
    );

    this.npsRepartition = computeNpsRepartition(
      currentPeriod,
      this.survey.scenario,
    );
  }

  repartitionChartBarClick($event: BarClickEvent) {
    this.setFiltersToSpecificScores(
      [npsEmojis.findIndex((emoji) => emoji === $event.value.label)],
      "nps",
    );
  }
}
