<div
  *ngIf="this.releases.length === 0 || isFakeDataDisplayed"
  class="blurred-backdrop"
></div>
<div
  [class]="{
    'no-overflow': showOnboarding,
  }"
>
  <section class="content">
    <free-trial-banner />
    <div class="header-group">
      <h1>Releases</h1>
      <div class="header-actions">
        <button
          *ngIf="'release.create' | hasPermission"
          title="Create Releases via API"
          nz-button
          nzSize="large"
          (click)="setAPIModalOpened(true)"
        >
          <span>Create Releases via API</span>
        </button>
        <nz-select
          nzMode="multiple"
          nzPlaceHolder="All tags"
          name="tags"
          [nzShowArrow]="true"
          nzSuffixIcon="caret-down"
          [(ngModel)]="selectedTagFilters"
          (ngModelChange)="refreshFilteredReleases()"
        >
          <nz-option
            *ngFor="let tag of tagList"
            [nzLabel]="tag"
            [nzValue]="tag"
          ></nz-option>
        </nz-select>
        <button
          *ngIf="'release.create' | hasPermission"
          title="Add a new Release"
          nz-button
          nzType="primary"
          nzSize="large"
          (click)="setModalOpened(true)"
        >
          <i nz-icon nzType="plus"></i>
          <span>Add a new Release</span>
        </button>
      </div>
    </div>
  </section>

  <section class="stats">
    <trend-chart
      [ready]="ready"
      [title]="'Total Number of Releases'"
      [data]="releases"
      [dataKey]="'released_at'"
      [format]="'day'"
      [isDataLineCumulating]="true"
      [footer]="chartFooter"
    ></trend-chart>
    <trend-chart
      [ready]="ready"
      [title]="'Average Number of Releases per Month'"
      [data]="releases"
      [dataKey]="'released_at'"
      [format]="'month'"
      [isTotalAverage]="true"
      [footer]="chartFooter"
    ></trend-chart>
    <trend-chart
      [ready]="ready"
      [title]="'Average Number of Releases per Week'"
      [data]="releases"
      [dataKey]="'released_at'"
      [format]="'week'"
      [isTotalAverage]="true"
      [footer]="chartFooter"
    ></trend-chart>
  </section>

  <section class="releases-container">
    <div class="timeline-container">
      <h2>Timeline of Releases</h2>

      <div
        class="releases-timelines"
        *ngFor="let releaseGroup of filteredReleases"
      >
        <h3 class="timeline-title">
          {{ getFormattedReleasePeriod(releaseGroup) }}
        </h3>
        <nz-timeline class="release-timeline" *ngIf="ready">
          <nz-timeline-item
            [nzDot]="dotTemplate"
            *ngFor="let release of releaseGroup.releases"
          >
            <div class="timeline-info-container">
              <div class="release-item-header">
                <h3 [id]="release.id">{{ release.name }}</h3>
                <div class="release-avatar-wrapper">
                  <org-account-avatar
                    *ngIf="!!release.author_id"
                    class="profile-picture"
                    [account]="getAuthorProfilePicture(release.author_id)"
                    [noTooltip]="true"
                    [size]="'small'"
                  />
                  <nz-tag
                    nzColor="var(--screeb-color-info-outline)"
                    [class]="!!release.author_id ? 'with-profile-picture' : ''"
                  >
                    Created {{ !!release.author_id ? "by" : "via" }}
                    {{ getReleasedBy(release.author_id) }}
                  </nz-tag>
                </div>
              </div>
              <div class="release-item-content">
                <p>
                  <sanitized-message-component
                    [content]="release.description"
                    [options]="{
                      links: true,
                      styling: true,
                      emojiTranscoder: true,
                      CR: true,
                    }"
                  ></sanitized-message-component>
                </p>
              </div>
              <div class="release-tags">
                <nz-tag nzColor="#E4F8F2">
                  {{ release.version }}
                </nz-tag>

                <nz-tag nzColor="#F7F7F8">
                  {{ getFormattedReleaseDate(release.released_at) }}
                </nz-tag>
                <nz-tag nzColor="#FFF6EA" *ngFor="let tag of release.tags">
                  {{ tag }}
                </nz-tag>
              </div>
            </div>
            <div class="timeline-actions-container">
              <button
                *ngIf="'release.update' | hasPermission"
                class="release-edit-btn"
                title="Edit Release"
                nz-button
                nzType="primary"
                nzSize="large"
                (click)="setModalOpened(true, release)"
              >
                <screeb-icon size="homepage" icon="pen"></screeb-icon>
              </button>
              <button
                *ngIf="'release.delete' | hasPermission"
                class="release-delete-btn"
                title="Delete Release"
                nz-button
                nzType="primary"
                nzSize="large"
                (click)="showDeleteConfirm($event, release.id)"
              >
                <screeb-icon size="md" icon="delete"></screeb-icon>
              </button>
            </div>
          </nz-timeline-item>
        </nz-timeline>
        <ng-template #dotTemplate>
          <div
            class="ant-timeline-item-head ant-timeline-item-head-blue release-item-header-dot"
          ></div>
        </ng-template>
      </div>
    </div>
    <div id="timeline-footer"></div>
  </section>
  <release-create-modal
    [isOpen]="modalOpened"
    (modalOpenChange)="setModalOpened($event, null)"
    (releaseSubmitted)="onReleaseSubmitted($event)"
    [org]="org"
    [tagOptions]="tagList"
    [release]="selectedRelease"
  />
  <release-api-modal
    [isOpen]="apiModalOpened"
    [org]="org"
    (modalOpenChange)="setAPIModalOpened($event)"
  />
  <release-onboarding-modal
    *ngIf="showOnboarding"
    (onboardingClose)="onOnboardingClose()"
  />
</div>

<ng-template #chartFooter>
  <span class="chart-footer-content">Since Jan 1st</span>
</ng-template>
