import { Component, Input, OnInit } from "@angular/core";
import { GraphNode } from "components/builder/flow";
import {
  routingGetNextNodeId,
  routingGetSkipToNodeId,
  routingRemoveCase,
  routingSetCase,
} from "components/builder/routing";

import { CTAAction, Routing, UUID } from "models/survey.dao.types";
import { BuilderStore } from "stores/builder.store";

@Component({
  selector: "lateral-panel-step-picker",
  templateUrl: "./step-picker.component.html",
  styleUrls: ["./step-picker.component.scss"],
})
export class LateralPanelStepPickerComponent implements OnInit {
  @Input() routing: Routing = null;
  @Input() chainableNodeIds: UUID[] = [];
  @Input() disabled = false;

  // there are 2 modes: case edition or default effect edition
  // a null value in actionId means we are using edition the default behaviour
  @Input() actions: CTAAction[] = [];
  @Input() isSkipAction: boolean = false;

  public chainableNodes: GraphNode[] = [];

  constructor(private builderStore: BuilderStore) {}

  ngOnInit() {
    this.chainableNodes = this.builderStore.nodes.filter((node: GraphNode) =>
      this.chainableNodeIds.includes(node.id),
    );
  }

  public getNodeId(): UUID | null {
    // when action is null, default effect will be returned
    if (this.isSkipAction)
      return routingGetSkipToNodeId(this.routing, this.actions[0]?.id);
    return routingGetNextNodeId(this.routing, this.actions[0]?.id);
  }

  // we never change message_id on actions => we create new actions instead
  // @TODO: we should remove those action on canceled edition
  public selectStep(nodeId: UUID | null) {
    const effectType = this.isSkipAction ? "skip_to_node" : "next_node";
    if (!nodeId) {
      this.actions.forEach((action) => {
        routingRemoveCase(this.routing, action.id, effectType);
      });

      if (this.actions.length === 0) {
        routingRemoveCase(this.routing, null, effectType);
      }
    }
    // we will use a 'end' routing effect for this action, instead of using the default routing effect
    else {
      this.actions.forEach((action) => {
        routingSetCase(this.routing, action.id, nodeId, effectType);
      });

      if (this.actions.length === 0) {
        routingSetCase(this.routing, null, nodeId, effectType);
      }
    }
  }
}
