import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";

import { ApplicationLoadService } from "services/app.service";
import { SurveyDistributionDao } from "models/survey-distribution.dao";
import { SurveyDistribution } from "models/survey-distribution.model";

@Injectable()
export class SurveyDistributionResolver implements Resolve<any> {
  constructor(
    private applicationLoadService: ApplicationLoadService,
    private surveyDistributionDao: SurveyDistributionDao,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Promise<SurveyDistribution> {
    return this.applicationLoadService
      .loader()
      .then(() => {
        return this.surveyDistributionDao.getById(
          route.params["org_id"],
          route.params["survey_id"],
          route.params["distribution_id"],
        );
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        throw err;
      });
  }
}
