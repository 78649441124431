<div
  class="translation-switch"
  nz-tooltip
  nzTooltipPlacement="right"
  [nzTooltipTitle]="
    billingPopupVisible
      ? !('response_translation' | hasEntitlement)
        ? confirmTemplate
        : settingTemplate
      : null
  "
>
  <span>Activate Automatic Responses Translation</span>
  <nz-switch
    ngDefaultControl
    [ngModelOptions]="{ standalone: true }"
    [ngModel]="translationEnabled"
    (ngModelChange)="onTranslationActivationChange($event)"
    [nzDisabled]="!translationAvailable"
  ></nz-switch>

  <ng-template #settingTemplate>
    <span>This option is disabled for this workspace.</span>
    &nbsp;
    <a
      (click)="navigateToSettings()"
      target="_blank"
      rel="noreferrer"
      style="color: var(--screeb-color-primary-outline)"
    >
      Go to Settings
    </a>
  </ng-template>

  <ng-template #confirmTemplate>
    <span>This option is only available with the Translation Addon.</span>
    &nbsp;
    <a
      (click)="navigateToBilling()"
      target="_blank"
      rel="noreferrer"
      style="color: var(--screeb-color-primary-outline)"
    >
      Get it Now
    </a>
  </ng-template>
</div>
