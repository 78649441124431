<div class="menu-left">
  <div
    class="survey-title"
    *ngIf="currentStepIdx === -1 || stepRoutes[currentStepIdx] !== 'edit'"
    nz-tooltip
    [nzTooltipTitle]="uiService.currentSurvey.title"
  >
    <span>
      <span
        class="edit"
        [routerLink]="[
          '/org',
          uiService.currentOrg.id,
          'survey',
          uiService.currentSurvey.id,
          'edit',
        ]"
      >
        <screeb-icon icon="edit" size="sm"></screeb-icon>
      </span>
      <span>{{ uiService.currentSurvey.title }}</span>
    </span>
  </div>
</div>
<steps-component
  [steps]="steps"
  [currentStep]="currentStepIdx"
  (stepClicked)="onStepClicked($event)"
/>
<div class="menu-right" id="bot-builder-actions-location">
  <!-- this is a fucking anti-pattern, but osef ;) -->
  <ng-container *ngIf="headerService.surveyPageActionsRef">
    <ng-container
      [ngTemplateOutlet]="headerService.surveyPageActionsRef"
    ></ng-container>
  </ng-container>
</div>
