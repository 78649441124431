import { Injectable } from "@angular/core";
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
} from "@angular/router";
import { Account, isOnboardingStatus } from "models/account.model";
import { ApplicationLoadService } from "services/app.service";
import { AuthService, SessionService } from "services/auth.service";

@Injectable()
export class OnBoardingGuard implements CanActivate, CanActivateChild {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private applicationLoadService: ApplicationLoadService,
    private sessionService: SessionService,
    private authService: AuthService,
  ) {}

  private getAccount(): Promise<Account> {
    // may be null when because this.uiService.orgs is loaded asynchronously
    const localAccount = this.sessionService.session;
    // if onboarding is already done, we don't need to reload cache
    if (
      localAccount?.flags?.onboarding_status === "done" ||
      localAccount?.flags?.onboarding_status === "product-tour"
    )
      return Promise.resolve(localAccount);

    // force reload cache when onboarding is not done
    return this.authService.getProfile();
  }

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return this.applicationLoadService.loader().then(async () => {
      await new Promise((resolve) => {
        this.route.data.subscribe(resolve).unsubscribe();
      });

      try {
        // uses cache
        const orgId = route.params.org_id;
        const account = await this.getAccount();

        if (
          account.flags?.onboarding_status === "done" ||
          account.flags?.onboarding_status === "product-tour"
        ) {
          return true;
        }

        // onBoarding already done so redirect to create page
        const queryParams = {
          destination: [route.url],
          destinationQueryParams: route.queryParams,
        };

        const onboardingStep =
          account.flags.onboarding_status?.split("-")[0] || "qualification";

        if (!isOnboardingStatus(onboardingStep)) {
          this.router.navigate(["org", orgId, "onboarding", "qualification"], {
            queryParams,
          });
        } else {
          this.router.navigate(["org", orgId, "onboarding", onboardingStep], {
            queryParams,
          });
        }
        return false;
      } catch (err) {
        console.error(err.error);
        throw err;
      }
    });
  }

  canActivateChild(route: ActivatedRouteSnapshot): Promise<boolean> {
    return this.canActivate(route);
  }
}
