<div class="graph" style="background-color: {{ bgColor }};">
  <div
    class="graph-container"
    [ngClass]="{
      'not-available': score === null && !isLoading,
    }"
  >
    <div class="graph-header" style="color: {{ color }}">
      <h3>{{ title }}</h3>
      <survey-stats-indicators-single-indicator
        [value]="score ?? 0"
        [diffPercent]="score !== null ? diff : undefined"
        [loading]="isLoading"
        theme="dark"
        [format]="format"
        [color]="color"
        size="small"
      ></survey-stats-indicators-single-indicator>
    </div>
    <!-- var color don't work because its in a canvas -->
    <mini-trend-indicator
      [datasets]="datasets"
      [loading]="isLoading"
      [color]="color"
    ></mini-trend-indicator>
    <div
      class="wrapper"
      *ngIf="
        (userId &&
          indicatorValue?.length &&
          (datasets?.[0]?.data?.length ?? 0) > 1) ||
        (score !== null && formattedDiffIndustryAverage)
      "
    >
      <div
        *ngIf="score !== null && formattedDiffIndustryAverage"
        class="row-2 industry-average"
        style="color: {{ color }}"
      >
        <ng-container *ngIf="!userId">
          <span class="industry-average-diff">
            {{ formattedDiffIndustryAverage }}
          </span>
          <span>compared to your industry</span>
        </ng-container>
        <ng-container *ngIf="userId && formattedLastDate?.length">
          {{ formattedLastDate }}
        </ng-container>
      </div>
      <div
        *ngIf="
          userId &&
          indicatorValue?.length &&
          (datasets?.[0]?.data?.length ?? 0) > 1
        "
        class="is-flex"
      ></div>
      <div
        *ngIf="
          userId &&
          indicatorValue?.length &&
          (datasets?.[0]?.data?.length ?? 0) > 1
        "
        class="indicator indicator-{{ indicatorValueColor }}"
      >
        {{ indicatorValue }}
      </div>
    </div>
    <div
      class="not-available-empty-screen"
      style="color: {{ color }}"
      *ngIf="score === null && !isLoading"
    >
      <ng-container *ngIf="type === 'nps'">
        <div>
          Start Tracking your NPS Now and Measure your Users Satisfaction in
          Real Time
        </div>
        <a
          routerLink="/org/{{ uiService.currentOrgId }}/survey/create"
          [queryParams]="{
            duplicateById: '16897bd6-ed96-4ff1-a702-d222e9d3dc76',
          }"
        >
          Set up NPS&nbsp;
          <span nz-icon nzType="arrow-right" nzTheme="outline"></span>
        </a>
      </ng-container>
      <ng-container *ngIf="type === 'mau'">
        <div>Install Screeb to Analyse your Product Usage in Real Time</div>

        <a routerLink="/org/{{ uiService.currentOrgId }}/settings/install">
          Install Screeb&nbsp;
          <span nz-icon nzType="arrow-right" nzTheme="outline"></span>
        </a>
      </ng-container>
      <ng-container
        *ngIf="
          type === 'ces' ||
          type === 'csat' ||
          type === 'completionRate' ||
          type === 'responseNumber' ||
          type === 'responseRate' ||
          type === 'displayNumber'
        "
      >
        <div>Install Screeb to Analyse your Product Usage in Real Time</div>

        <a routerLink="/org/{{ uiService.currentOrgId }}/settings/install">
          Install Screeb&nbsp;
          <span nz-icon nzType="arrow-right" nzTheme="outline"></span>
        </a>
      </ng-container>
    </div>
  </div>
</div>
