import { Component, Input, OnInit } from "@angular/core";
import { NotificationHelper } from "helpers/notification.helper";
import { ChannelType } from "models/channel.model";
import { ClipboardService } from "ngx-clipboard";

@Component({
  selector: "survey-share-targeting-push-notification",
  templateUrl: "./push-notification.component.html",
  styleUrls: ["./push-notification.component.scss"],
})
export class PushNotificationTargetingShareSurveyComponent implements OnInit {
  @Input() public surveyId: string = null;
  @Input() public channelType: ChannelType = null;

  public code: string;

  constructor(
    private clipboardService: ClipboardService,
    private notificationHelper: NotificationHelper,
  ) {}

  ngOnInit(): void {
    this.code = this.getCode(true);
  }

  public getDocURL() {
    switch (this.channelType) {
      case "android":
        return "https://developers.screeb.app/sdk-android/push-notifications";
      case "ios":
        return "https://developers.screeb.app/sdk-ios/push-notifications";
      default:
        throw new Error("unexpected channel type");
    }
  }

  public getCode(multiple: boolean) {
    switch (this.channelType) {
      case "android":
        return this.getCodeAndroid(multiple);
      case "ios":
        return this.getCodeIOS(multiple);
      default:
        throw new Error("unexpected channel type");
    }
  }

  private getCodeAndroid(_: boolean) {
    return `"screeb" : "${this.surveyId}"`;
  }

  private getCodeIOS(_: boolean) {
    return `"screeb" : "${this.surveyId}"`;
  }

  recurrencyChange(multiple: boolean) {
    this.code = this.getCode(multiple);
  }

  clipboardCopy(code: string) {
    this.clipboardService.copy(code);
    this.notificationHelper.trigger(
      "Copied to your clipboard!",
      null,
      "success",
    );
  }
}
