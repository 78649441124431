/* eslint-disable @angular-eslint/no-output-on-prefix */
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {
  CTAType,
  I18nLabel,
  I18nTextLabel,
  I18nVideoLabel,
  ScenarioQuestion,
  ScenarioQuestionType,
  ScenarioQuestionTypeMessage,
  getI18nTextLabelTranslation,
  getI18nVideoLabelTranslation,
} from "models/survey.dao.types";
import { BuilderStore } from "stores/builder.store";
import { OnClickEvent } from "../ActionDot/ActionDot.component";

@Component({
  selector: "big-card",
  templateUrl: "./BigCard.component.html",
  styleUrls: ["./BigCard.component.scss"],
})
export class BigCardComponent implements AfterViewInit, OnChanges {
  @ViewChild("videoThumbnail", { static: false })
  videoThumbnail: ElementRef<HTMLVideoElement>;
  @Input() type: CTAType | ScenarioQuestionTypeMessage["type"];
  @Input() letter: string;
  @Input() multiple: boolean;
  @Input() labelType: ScenarioQuestionType;
  @Input() label: I18nLabel;
  @Input() subLabel?: I18nLabel;
  @Input() pathCount?: number;
  @Input() skipPathCount?: number;
  @Input() haveNext?: boolean;
  @Input() haveSkip?: boolean;
  @Input() haveError?: boolean;
  @Input() question?: ScenarioQuestion;
  @Input() isHovered?: boolean = false;

  @Output() onClick = new EventEmitter<Event>();
  @Output() onSizeUpdate = new EventEmitter<any>();
  @Output() onActionDotClick = new EventEmitter<ElementRef>();

  public selector?: string | null;
  public url?: string | null;

  constructor(
    private el: ElementRef,
    public builderStore: BuilderStore,
  ) {}

  ngAfterViewInit() {
    this.refreshBoundingClientRect();
    this.refreshUrlAndSelector();
  }

  ngOnChanges({ labelType }: SimpleChanges) {
    if (
      labelType &&
      labelType.currentValue !== labelType.previousValue &&
      labelType.currentValue === "video"
    ) {
      // Wait for the video Element to be loaded in page
      setTimeout(() => {
        if (!this.videoThumbnail?.nativeElement) return;

        // Force reload video source
        this.videoThumbnail?.nativeElement.load();
      }, 100);
    }

    this.refreshUrlAndSelector();
  }

  private refreshUrlAndSelector() {
    if (this.question?.type === "beacon" || this.question?.type === "popover") {
      this.selector = this.question?.selector;
      this.url = this.question?.url;
    }
  }

  public get isDefaultLanguage() {
    return (
      this.builderStore.currentLanguage ===
      this.builderStore.survey.scenario.default_language
    );
  }

  public get hasActionDot() {
    if (this.multiple) {
      return true;
    }

    switch (this.type) {
      case "input":
      case "range":
      case "none":
      case "calendar":
        return true;

      case "banner":
      case "beacon":
      case "popover":
      case "modal":
      case "sidebar":
        return this.question?.call_to_action?.type === "none";
    }

    return false;
  }

  private refreshBoundingClientRect() {
    const element = this.el.nativeElement.querySelector(".BigCard");
    if (element) {
      const clientRect = element.getBoundingClientRect();
      this.onSizeUpdate.emit({ element, clientRect });
    }
  }

  actionDotClick({ event, origin }: OnClickEvent) {
    event.stopPropagation();
    this.onActionDotClick.emit(origin);
  }

  hasMissingTranslation() {
    if (this.type === "appstore_rating") {
      return false;
    }

    return !this.label[this.builderStore.currentLanguage];
  }

  public getTranslatedTextLabel(): string {
    return (
      getI18nTextLabelTranslation(
        this.label as I18nTextLabel,
        this.builderStore.currentLanguage,
        this.builderStore.survey.scenario.default_language,
      ) || ""
    );
  }

  public getTranslatedVideoLabel(): string {
    return (
      getI18nVideoLabelTranslation(
        this.label as I18nVideoLabel,
        this.builderStore.currentLanguage,
        this.builderStore.survey.scenario.default_language,
      ).url ?? ""
    );
  }

  public getTranslatedVideoThumbnailLabel(): string {
    const translations = getI18nVideoLabelTranslation(
      this.label as I18nVideoLabel,
      this.builderStore.currentLanguage,
      this.builderStore.survey.scenario.default_language,
    );

    const thumbnails = translations?.thumbnail_urls ?? translations?.url;

    if (typeof thumbnails === "string") {
      return thumbnails;
    }

    if (!thumbnails) {
      return undefined;
    }

    // forced to get values by keys because object is not directly accessible (return undefined)
    const k = Object.keys(thumbnails);

    for (let i = 0; i < k.length; i++) {
      const key = k[i];
      if (thumbnails[key] !== null) {
        return thumbnails[key];
      }
    }

    return undefined;
  }
}
