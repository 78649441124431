export class ColorHelper {
  color: string;
  alphaValue: number;

  constructor(color) {
    this.color = color;
    this.alphaValue = 1;
  }

  static color(color: string) {
    return new ColorHelper(color);
  }

  alpha(alpha) {
    this.alphaValue = alpha;
    return this;
  }

  rgbString() {
    const { r, g, b } = ColorHelper.hexToRgb(this.color);
    const alpha = this.alphaValue;

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  static hexToRgb(hex) {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return { r, g, b };
  }
}
