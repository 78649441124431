import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Org } from "models/org.model";
import { OrgDao } from "models/org.dao";

@Component({
  selector: "release-api-modal",
  templateUrl: "./api-modal.component.html",
  styleUrls: ["./api-modal.component.scss"],
})
export class ReleaseAPIModalComponent {
  @Input() isOpen: boolean = false;
  @Input() org: Org = null;
  @Output() modalOpenChange: EventEmitter<boolean> = new EventEmitter();

  constructor(public orgDao: OrgDao) {}

  handleCancel(): void {
    this.isOpen = false;
    this.modalOpenChange.emit(this.isOpen);
  }

  public resetToken() {
    this.orgDao
      .newApiToken(this.org.id)
      .then((data) => (this.org.api_token = data["api_token"]));
  }
}
