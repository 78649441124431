import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";

const defaultRangeOptions = {
  week: "Sync Last Week",
  month: "Sync Last Month",
  year: "Sync Last Year",
  all: "Sync All",
};

export type RangeSelect = {
  from: Date;
  to: Date;
};

@Component({
  selector: "range-button",
  template: `
    <nz-button-group class="sync-actions-group">
      <button
        class="sync"
        nz-button
        nzType="primary"
        [disabled]="disabled"
        [nzLoading]="loading"
        (click)="$event.preventDefault(); onClick(defaultOption)"
      >
        {{ options[defaultOption] }}
      </button>
      <button
        class="sync"
        nzType="primary"
        nz-button
        nz-dropdown
        [disabled]="disabled || loading"
        [nzDropdownMenu]="rangeMenu"
        nzPlacement="bottomRight"
      >
        <span nz-icon nzType="down"></span>
      </button>
    </nz-button-group>

    <nz-dropdown-menu #rangeMenu="nzDropdownMenu">
      <ul nz-menu>
        <ng-container *ngFor="let option of options | keyvalue">
          <li
            *ngIf="option.key !== defaultOption"
            nz-menu-item
            (click)="$event.preventDefault(); onClick(option.key)"
          >
            {{ option.value }}
          </li>
        </ng-container>
      </ul>
    </nz-dropdown-menu>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        display: inline-flex;
      }
    `,
  ],
})
export class RangeButtonComponent {
  // map of option<key, label>
  @Input() public options: object = defaultRangeOptions;
  @Input() public defaultOption: string = "all";
  @Input() public disabled: boolean;
  @Input() public loading: boolean;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() public click: EventEmitter<RangeSelect | null> =
    new EventEmitter<RangeSelect | null>();

  public onClick(option: string): void {
    this.click.emit(this.getDefaultDateFromRange(option));
  }

  private getDefaultDateFromRange(range: string): RangeSelect {
    switch (range) {
      case "week":
        return {
          from: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
          to: new Date(),
        };
      case "month":
        return {
          from: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000),
          to: new Date(),
        };
      case "year":
        return {
          from: new Date(Date.now() - 365 * 24 * 60 * 60 * 1000),
          to: new Date(),
        };
      case "all":
        return {
          from: new Date(2020, 1, 2),
          to: new Date(),
        };
      default:
        return null;
    }
  }
}
