<div class="plan-wrapper">
  <div class="plan-info">
    <h3>
      <screeb-icon size="sm" icon="survey" />
      Plan
    </h3>

    <div class="current-plan">
      <div class="plan-name">
        <span class="star">✨</span>
        {{ planTypeInfos[currentPlanType].name }}

        <span *ngIf="currentPlanType !== 'free'" class="cycle">
          <screeb-icon size="md" icon="hourglass"></screeb-icon>
          <ng-container *ngIf="'isTrialing' | hasEntitlement">
            Free Trial
          </ng-container>
          <ng-container *ngIf="!('isTrialing' | hasEntitlement)">
            {{ cycleDurationToLabel[orgBilling.billing_cycle_duration] }}
          </ng-container>
        </span>
      </div>
      <div
        *ngIf="
          !('isTrialing' | hasEntitlement) &&
          (currentPlanType === 'free' ||
            (orgBillingSubscription.amount ?? 0) > 0)
        "
      >
        <span class="big">
          {{ orgBillingSubscription.amount ?? 0 | price }}
        </span>
        /
        <span class="light">
          {{ orgBilling.billing_cycle_duration ?? "month" }}
        </span>
        <span class="coupon" *ngIf="!!orgBillingSubscription.discount">
          @let strCoupon = couponToString(orgBillingSubscription.discount);
          ({{ strCoupon }})
        </span>
      </div>
      <div class="cycle-end">
        @let memberSince =
          getFormattedDate(orgBilling.created_at.getTime() / 1000);
        Since
        <span>{{ memberSince }}</span>
      </div>
      <div class="free-trial" *ngIf="'isTrialing' | hasEntitlement">
        <span class="trial-status">
          You have
          {{ remainingTrialDays | pluralSingular: "day" : "days" }} left of your
          {{ getFormattedFreeTrialDuration() }} free trial.
        </span>
      </div>
      <div
        class="cycle-end"
        *ngIf="
          currentPlanType !== 'free' &&
          !('isTrialing' | hasEntitlement) &&
          orgBillingSubscription?.current_period_end &&
          !orgBillingSubscription?.cancel_at
        "
      >
        Renew on
        <span>
          @let formatedDate =
            getFormattedDate(orgBillingSubscription.current_period_end);
          {{ formatedDate }}
        </span>
      </div>
      <div
        class="cycle-end"
        *ngIf="
          currentPlanType !== 'free' &&
          !('isTrialing' | hasEntitlement) &&
          !!orgBillingSubscription?.cancel_at
        "
      >
        {{
          orgBillingSubscription.cancel_at > now.getTime()
            ? "Cancelled"
            : "Cancelling"
        }}
        on
        <span>
          @let formatedDate =
            getFormattedDate(orgBillingSubscription.cancel_at);
          {{ formatedDate }}
        </span>
      </div>
      <div class="plan-actions">
        <button
          *ngIf="
            currentPlanType !== 'free' &&
            !('isTrialing' | hasEntitlement) &&
            !orgBillingSubscription?.cancel_at
          "
          class="cancel-btn"
          nz-button
          (click)="onCancel.emit()"
          nzType="primary"
        >
          Cancel subscription
        </button>
        <button nz-button (click)="goToTab.emit('plans')" nzType="primary">
          Explore plans
        </button>
      </div>
    </div>
    <div class="billing-detail"></div>
  </div>
  <div class="billing-detail">
    <h3>
      <screeb-icon size="sm" icon="top-mrr" />
      Billing details
    </h3>
    <section class="billing-infos">
      <ul>
        <li *ngIf="!!orgBilling.name">
          <label>{{ orgBilling.is_company ? "Company Name" : "Name" }}:</label>
          <p>{{ orgBilling.name }}</p>
        </li>
        <li *ngIf="!!orgBilling.email">
          <label>Email:</label>
          <p>{{ orgBilling.email }}</p>
        </li>
        <li
          *ngIf="
            !!orgBilling.address_line_1 ||
            !!orgBilling.address_line_2 ||
            !!orgBilling.postal_code ||
            !!orgBilling.city ||
            !!orgBilling.country
          "
        >
          <label>Address:</label>
          <p>
            @let fullAddress = getFullAddress(orgBilling);
            {{ fullAddress }}
          </p>
        </li>
        <li *ngIf="!!orgBilling.vat">
          <label>VAT:</label>
          <p>{{ orgBilling.vat }}</p>
        </li>
      </ul>

      <div class="actions">
        <button
          *ngIf="orgBillingInvoices?.length > 0"
          nz-button
          nzType="default"
          (click)="openPanel.emit('invoices')"
        >
          View invoices
        </button>
        <button
          nz-button
          nzType="default"
          (click)="openPanel.emit('payment_methods_list')"
        >
          Edit payment methods
        </button>
        <button nz-button nzType="primary" (click)="openPanel.emit('address')">
          Edit
        </button>
      </div>
    </section>
  </div>
</div>
<hr *ngIf="currentPlanType !== 'free'" />

<div *ngIf="currentPlanType !== 'free'" class="top-wrapper">
  <div class="usage">
    <div>
      <h3>
        <ng-template #variablesHelpTemplate>
          <p style="max-width: 500px; word-break: break-word">
            Montly Active Users or Monthly Tracked Users are the number of
            unique users who triggered at least one event in your app in the
            last month. To estimate your number of MAUs, add your Monthly Active
            Users to the number of anonymous visitors you get every month.
            <br />
            For SaaS or mobile apps, MAUs are usually the number of users who
            logged in during the month. If most of your users are not logged in,
            then MAU is similar to Monthly Unique Sessions.
          </p>
        </ng-template>
        <screeb-icon size="sm" icon="user" />
        Montly Active Users
        <span
          nz-popover
          [nzPopoverContent]="variablesHelpTemplate"
          nzPopoverPlacement="rightTop"
        >
          <i nz-icon nzType="info-circle" nzTheme="outline"></i>
        </span>
      </h3>
      <p class="used-mau">
        You have used
        <ng-container
          *ngIf="
            uiService.currentSuperOrg.entitlements.mtu_scheme === 'limited'
          "
        >
          <span
            [style]="{
              color:
                uiService.currentSuperOrg.usedMTUPercent <= 80
                  ? 'var(--screeb-color-primary)'
                  : 'var(--screeb-color-error)',
            }"
          >
            {{ uiService.currentSuperOrg.usedMTUPercent }}%
          </span>
          of your monthly active user quota this month.
        </ng-container>
        <ng-container
          *ngIf="
            uiService.currentSuperOrg.entitlements.mtu_scheme !== 'limited'
          "
        >
          <span
            [style]="{
              color: 'var(--screeb-color-primary)',
            }"
          >
            {{ uiService.currentSuperOrg.usedMTU | format: "number" }}
          </span>
          of monthly active user this month.
        </ng-container>
      </p>
      <nz-progress
        class="progress-bar"
        [nzPercent]="uiService.currentSuperOrg.usedMTUPercent"
        [nzShowInfo]="false"
        nzStrokeWidth="16"
        [nzStrokeColor]="
          uiService.currentSuperOrg.usedMTUPercent <= 80
            ? 'var(--screeb-color-primary)'
            : 'var(--screeb-color-error)'
        "
        [nzStatus]="
          uiService.currentSuperOrg.usedMTUPercent > 80 ? 'exception' : 'normal'
        "
      ></nz-progress>
      <div class="mau-under">
        <p class="ratio-mau">
          <b>{{ uiService.currentSuperOrg.usedMTU | format: "number" }}</b>
          <span>
            &thinsp;/&thinsp;{{
              uiService.currentSuperOrg.entitlements.mtu_scheme === "limited"
                ? (uiService.currentSuperOrg.maxMTU | format: "number")
                : "∞"
            }}
          </span>
          <span class="light">mau</span>
        </p>
        <p>Since 1st of the Month</p>
      </div>
    </div>
    <hr />
    <div>
      <h3>
        <screeb-icon size="sm" icon="search" />
        Workspaces
      </h3>
      <p class="used-mau">Remaining workspaces</p>
      <nz-progress
        class="progress-bar"
        [nzPercent]="
          uiService.currentSuperOrg.entitlements.max_workspaces !== -1
            ? (workspaces.length /
                uiService.currentSuperOrg.entitlements.max_workspaces) *
              100
            : 0
        "
        [nzShowInfo]="false"
        nzStrokeWidth="16"
        [nzStrokeColor]="
          uiService.currentSuperOrg.entitlements.max_workspaces === -1
            ? 'var(--screeb-color-disabled)'
            : workspaces.length <
                uiService.currentSuperOrg.entitlements.max_workspaces
              ? 'var(--screeb-color-primary)'
              : 'var(--screeb-color-error)'
        "
        [nzStatus]="
          uiService.currentSuperOrg.entitlements.max_workspaces >=
          workspaces.length
            ? 'exception'
            : 'normal'
        "
      ></nz-progress>
      <div class="mau-under">
        <p class="ratio-mau">
          <b>{{ workspaces.length | format: "number" }}</b>
          <span>
            &thinsp;/&thinsp;{{
              uiService.currentSuperOrg.entitlements.max_workspaces !== -1
                ? (uiService.currentSuperOrg.entitlements.max_workspaces
                  | format: "number")
                : "∞"
            }}
          </span>
          <span class="light">workspaces</span>
        </p>
      </div>
    </div>
    <ng-container
      *ngIf="
        ('isSessionRecordingEnabled' | hasFeatureFlag) &&
        ('session_recording' | hasEntitlement)
      "
    >
      <hr />
      <div>
        <h3>
          <screeb-icon size="md" icon="session-start" />
          Sessions
        </h3>
        <p class="used-mau">
          You have used
          <ng-container
            *ngIf="uiService.currentSuperOrg.entitlements.max_sessions !== -1"
          >
            <span
              [style]="{
                color:
                  uiService.currentSuperOrg.usedSessionsPercent <= 80
                    ? 'var(--screeb-color-primary)'
                    : 'var(--screeb-color-error)',
              }"
            >
              {{ uiService.currentSuperOrg.usedSessionsPercent }}%
            </span>
            of your monthly session quota this month.
          </ng-container>
        </p>
        <nz-progress
          class="progress-bar"
          [nzPercent]="uiService.currentSuperOrg.usedSessionsPercent"
          [nzShowInfo]="false"
          nzStrokeWidth="16"
          [nzStrokeColor]="
            uiService.currentSuperOrg.usedSessionsPercent <= 80
              ? 'var(--screeb-color-primary)'
              : 'var(--screeb-color-error)'
          "
          [nzStatus]="
            uiService.currentSuperOrg.usedSessionsPercent > 80
              ? 'exception'
              : 'normal'
          "
        ></nz-progress>
        <div class="mau-under">
          <p class="ratio-mau">
            <b>
              {{ uiService.currentSuperOrg.usedSessions | format: "number" }}
            </b>
            <span>
              &thinsp;/&thinsp;{{
                uiService.currentSuperOrg.maxSessions !== -1
                  ? (uiService.currentSuperOrg.maxSessions | format: "number")
                  : "∞"
              }}
            </span>
            <span class="light">sessions</span>
          </p>
          <p>Since 1st of the Month</p>
        </div>
      </div>
    </ng-container>
  </div>

  <div
    *ngIf="currentPlanType === 'scale' || currentPlanType === 'advanced'"
    class="your-addons"
  >
    <ng-component
      *ngTemplateOutlet="addonSection; context: { format: 'half' }"
    ></ng-component>
  </div>

  <div *ngIf="currentPlanType === 'custom'" class="services">
    <h3>
      <screeb-icon size="sm" icon="question-mark" />
      Your services
    </h3>
    <hubspot class="service">
      <div class="fake-avatar">
        <img src="/assets/people/salome-cousseau.jpeg" alt="Salomé Cousseau" />
        <div class="activity-dot"></div>
      </div>
      <div class="content">
        Book a Meeting with Salomé, your dedicated Customer Success Manager
      </div>
    </hubspot>
    <hubspot class="service" href="https://meetings.hubspot.com/simon-robic">
      <div class="fake-avatar">
        <img src="/assets/people/simon-robic-2.jpeg" alt="Simon Robic" />
        <div class="activity-dot"></div>
      </div>
      <div class="content">
        Book a Meeting with Simon, our Chief Product Officer, to talk about our
        product and our roadmap
      </div>
    </hubspot>
    <hubspot class="service">
      <div class="icon">👋</div>
      <div class="content">Request a training for your team members</div>
    </hubspot>
    <intercom class="service">
      <div class="icon">💬</div>
      <div class="content">
        Send a livechat message to our support and get a response in under 5
        minutes
      </div>
    </intercom>
  </div>
</div>

<div *ngIf="currentPlanType === 'custom'" class="your-addons">
  <hr />
  <ng-component
    *ngTemplateOutlet="addonSection; context: { format: 'full' }"
  ></ng-component>
</div>

<ng-template #addonSection let-format="format">
  <h3>
    <screeb-icon size="sm" icon="check" />
    Your Add Ons
  </h3>

  <div class="content">
    <ng-container
      *ngTemplateOutlet="
        addonSummary;
        context: {
          display: orgBilling.expected_metadata.advanced_survey_customization,
          data: {
            format: format,
            title: 'Advanced Survey Customization',
            icon: 'customize',
          },
        }
      "
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="
        addonSummary;
        context: {
          display: orgBilling.expected_metadata.white_label,
          data: {
            format: format,
            title: 'Hide \'Powered by Screeb\'',
            icon: 'talk-to-a-human',
          },
        }
      "
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="
        addonSummary;
        context: {
          display: orgBilling.expected_metadata.response_translation,
          data: {
            format: format,
            title: 'Response Translation',
            icon: 'survey',
          },
        }
      "
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="
        addonSummary;
        context: {
          display: orgBilling.expected_metadata.audio_video_surveys,
          data: {
            format: format,
            title: 'Survey Audio / Video',
            size: 'md',
            icon: 'microphone',
          },
        }
      "
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="
        addonSummary;
        context: {
          display: orgBilling.expected_metadata.content_analysis,
          data: {
            format: format,
            title: 'AI Content Analysis',
            icon: 'live',
          },
        }
      "
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="
        addonSummary;
        context: {
          display: orgBilling.expected_metadata.survey_inapp_mobile,
          data: {
            format: format,
            title: 'In-App Survey on Mobile devices',
            size: 'md',
            icon: 'mobile',
          },
        }
      "
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="
        addonSummary;
        context: {
          display: orgBilling.expected_metadata.message_inapp,
          data: {
            format: format,
            title: 'In-App Messages',
            size: 'md',
            icon: 'create-survey',
          },
        }
      "
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="
        addonSummary;
        context: {
          display: orgBilling.expected_metadata.iam_saml,
          data: {
            format: format,
            title: 'SAML SSO',
            icon: 'dev-documentation',
          },
        }
      "
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="
        addonSummary;
        context: {
          display: orgBilling.expected_metadata.iam_mfa,
          data: {
            format: format,
            title: 'Two Factor Authentication',
            icon: 'widget-settings',
          },
        }
      "
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="
        addonSummary;
        context: {
          display: orgBilling.expected_metadata.session_recording,
          data: {
            format: format,
            title: 'Session recording',
            icon: 'microphone',
          },
        }
      "
    ></ng-container>
  </div>

  <div class="section-break"></div>

  <h3>
    <screeb-icon size="sm" icon="check" />
    Data retention
  </h3>

  <div class="content">
    <ng-container
      *ngTemplateOutlet="
        retentionSummary;
        context: {
          data: {
            format: format,
            title: 'Tracking: ' + getRetentionDuration('tracking'),
            icon: 'mobile',
            size: 'md',
          },
        }
      "
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="
        retentionSummary;
        context: {
          data: {
            format: format,
            title: 'Sessions: ' + getRetentionDuration('sessions'),
            icon: 'microphone',
            size: 'md',
          },
        }
      "
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="
        retentionSummary;
        context: {
          data: {
            format: format,
            title: 'Responses: ' + getRetentionDuration('responses'),
            icon: 'survey',
          },
        }
      "
    ></ng-container>
  </div>
</ng-template>

<ng-template #addonSummary let-data="data" let-display="display">
  <div class="current-plan-summary {{ data.format }}" *ngIf="display">
    <div class="icon">
      <screeb-icon [size]="!!data.size ? data.size : 'sm'" [icon]="data.icon" />
    </div>
    <div class="content">
      <div class="title">
        <p class="key">
          {{ data.title }}
          <i
            *ngIf="!!data.tooltip"
            nz-icon
            nzType="question-circle"
            nzTheme="outline"
            nz-tooltip
            [nzTooltipTitle]="data.tooltip"
          ></i>
        </p>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #retentionSummary let-data="data">
  <div class="current-plan-retention {{ data.format }}">
    <div class="icon">
      <screeb-icon [size]="!!data.size ? data.size : 'sm'" [icon]="data.icon" />
    </div>
    <div class="content">
      <div class="title">
        <p class="key">
          {{ data.title }}
        </p>
      </div>
    </div>
  </div>
</ng-template>

<hr *ngIf="currentPlanType !== 'free'" />
<section class="content" nz-row [nzGutter]="[24, 24]">
  <section
    class="addons"
    *ngIf="currentSubscriptionOptions.planType !== 'free'"
  >
    <h3>🎟️&nbsp;&nbsp;Add Ons recommended for your usage</h3>
    <div class="addons">
      <billing-addon-upgrade
        *ngIf="!currentSubscriptionOptions.addons.whiteLabel"
        addonKey="white-label"
        [planType]="currentSubscriptionOptions.planType"
        [planMTU]="orgBilling.expected_metadata.max_mtu"
        (click)="activateAddon('white-label')"
      />
      <billing-addon-upgrade
        *ngIf="
          isAddonAvailableForPlan('survey-inapp-mobile') &&
          !currentSubscriptionOptions.addons.surveyInAppMobile
        "
        addonKey="survey-inapp-mobile"
        [planType]="currentSubscriptionOptions.planType"
        [planMTU]="orgBilling.expected_metadata.max_mtu"
        (click)="activateAddon('survey-inapp-mobile')"
      />
      <billing-addon-upgrade
        *ngIf="
          isAddonAvailableForPlan('audio-video-survey') &&
          !currentSubscriptionOptions.addons.audioVideoSurvey
        "
        addonKey="audio-video-survey"
        [planType]="currentSubscriptionOptions.planType"
        [planMTU]="orgBilling.expected_metadata.max_mtu"
        (click)="activateAddon('audio-video-survey')"
      />
      <billing-addon-upgrade
        *ngIf="
          isAddonAvailableForPlan('response-translation') &&
          !currentSubscriptionOptions.addons.responseTranslation
        "
        addonKey="response-translation"
        [planType]="currentSubscriptionOptions.planType"
        [planMTU]="orgBilling.expected_metadata.max_mtu"
        (click)="activateAddon('response-translation')"
      />
      <billing-addon-upgrade
        *ngIf="
          isAddonAvailableForPlan('graphical-survey-customization') &&
          !currentSubscriptionOptions.addons.graphicalSurveyCustomization
        "
        addonKey="graphical-survey-customization"
        [planType]="currentSubscriptionOptions.planType"
        [planMTU]="orgBilling.expected_metadata.max_mtu"
        (click)="activateAddon('graphical-survey-customization')"
      />
      <!-- <billing-addon-upgrade
        *ngIf="
          isAddonAvailableForPlan('ai-content-analysis') &&
          !currentSubscriptionOptions.addons.aiContentAnalysis
        "
        addonKey="ai-content-analysis"
        [planType]="currentSubscriptionOptions.planType"
        [planMTU]="orgBilling.expected_metadata.max_mtu"
        (click)="activateAddon('ai-content-analysis')"
      /> -->
      <!-- <billing-addon-upgrade
        *ngIf="
          isAddonAvailableForPlan('message-inapp') &&
          !currentSubscriptionOptions.addons.messageInApp
        "
        addonKey="message-inapp"
        [planType]="currentSubscriptionOptions.planType"
        [planMTU]="orgBilling.expected_metadata.max_mtu"
        (click)="activateAddon('message-inapp')"
      /> -->
      <!-- <billing-addon-upgrade
        *ngIf="
          isAddonAvailableForPlan('iam-mfa') &&
          !currentSubscriptionOptions.addons.iamMFA
        "
        addonKey="iam-mfa"
        [planType]="currentSubscriptionOptions.planType"
        [planMTU]="orgBilling.expected_metadata.max_mtu"
        (click)="activateAddon('iam-mfa')"
      /> -->
      <billing-addon-upgrade
        *ngIf="orgBilling.expected_metadata.max_workspaces !== -1"
        title="Add"
        addonKey="additional-workspaces"
        [planType]="currentSubscriptionOptions.planType"
        [planMTU]="orgBilling.expected_metadata.max_mtu"
        (click)="activateAddon('additional-workspaces')"
      />
      <billing-addon-upgrade
        title="Add"
        addonKey="additional-mtu"
        [planType]="currentSubscriptionOptions.planType"
        (click)="activateAdditionalMTU()"
      />
      <billing-addon-upgrade
        title="Customize"
        name="Increase data retention"
        img="workspace.png"
        (click)="increaseRetention()"
      />
    </div>
  </section>
</section>
