import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ChartDataset } from "chart.js";

import { PageComponentInterface } from "components/PageComponentInterface";
import {
  TREND_GRAPH_MAX_BUCKETS,
  TrendIndicatorLegend,
} from "components/surveys/pages/stats/indicators/components/trend-indicator/trend-indicator.config";
import { getDateAnswerValueBuckets } from "components/surveys/pages/stats/indicators/indicator.utils";
import {
  bucketToNpsAggregation,
  NpsAggregation,
} from "components/surveys/pages/stats/indicators/nps/nps.aggregation";
import { UserNormalizedEvent } from "components/user/common/user-events/user-events.component";
import { differenceInDays } from "date-fns";
import { AnalyticsDao } from "models/analytics.dao";
import {
  AnalyticsFilterUser,
  AnalyticsQueryResponse,
  AnalyticsQueryUsers,
} from "models/analytics.filters.type";
import {
  AnalyticsResponseItemResponseEmotions,
  AnalyticsResponseItemUser,
  getFormattedUserNameOrID,
} from "models/analytics.model";

import { Org } from "models/org.model";
import { RegistryEntry, sortRegistryEntries } from "models/registry.model";
import { getFormattedUserGroupNameOrID } from "models/user-group.types";
import { UserProperty } from "models/user-properties.model";
import {
  aggregateUserProperties,
  getUserIcon,
  UserNormalizedProperty,
} from "models/user.model";

import { RadarIndicatorLegend } from "components/surveys/pages/stats/indicators/components/radar-indicator/radar-indicator.component";
import { Account } from "models/account.model";
import { Release } from "models/release.model";
import { UUID } from "models/survey.dao.types";
import { Survey } from "models/survey.model";
import { IndustriesScores } from "resolvers/asset-industries-scores";
import { AnalyticsFilterService } from "services/analytics-filters.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { PermissionsService } from "services/permissions.service";
import { RoutingService } from "services/routing.service";
import { UIService } from "services/ui.service";
import { arrayToMap } from "utils/array";
import { deepCopy } from "utils/object";

const USER_PAGE_SIZE = 100;

@Component({
  selector: "segment-details-page",
  templateUrl: "./segment-details.component.html",
  styleUrls: ["./segment-details.component.scss"],
})
export class SegmentDetailsPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Segment";
  public name = "Segment";

  private obs: any = null;

  public org: Org;
  public registryEntriesGroupType: RegistryEntry[];
  public registryEntriesGroup: RegistryEntry[];
  public registryEntriesIdentityProperty: RegistryEntry[]; // exclude type=object
  public registryEntriesIdentityPropertyById: Map<string, RegistryEntry>;
  public registryEntriesEvent: RegistryEntry[];
  public surveys: Survey[] = [];
  public users: AnalyticsResponseItemUser[] = [];

  public currentUserGroup: RegistryEntry = null;

  public groupName: string = null;

  public releases: Release[] = [];
  public orgAccounts: Account[] = [];

  public error: Error;
  public initialFetch = true;
  public loadings = {
    getUserCount: true,
    getNps: true,
    getAnwersCount: true,
    getMostTrackedEvents: true,
    getActivityTrendEvents: true,
    getEventCorrelation: true,
    getMostTrackedProperties: true,
    getSpecificUsersMostActive: true,
    getSpecificUsersLeastActive: true,
    getSpecificUsersTopMrr: true,
  };

  protected filtersObs$: any = null;
  public lastFilters: AnalyticsQueryUsers;
  private industriesScores: IndustriesScores;

  public getFormattedUserGroupNameOrID = getFormattedUserGroupNameOrID;
  public getFormattedUserNameOrID = getFormattedUserNameOrID;
  public getUserIcon = getUserIcon;

  public totalNumberOfUserInSegment = 0;
  public numberOfSurveyResponsesForThisSegment = 0;
  public npsAggregation: NpsAggregation = null;

  public mostTriggeredEvents: UserNormalizedEvent[] = [];
  public mostTriggeredEventsAveragePerUser: UserNormalizedEvent[] = [];

  public mostTrackedProperties: UserNormalizedProperty[] = [];

  protected TREND_GRAPH_MAX_BUCKETS = TREND_GRAPH_MAX_BUCKETS;

  public trendChartDataset: ChartDataset[] = [];
  public trendChartLegend: TrendIndicatorLegend = [
    {
      label: "Activity",
      value: "Activity",
      checked: true,
      color: "#1ED5A4",
      hoverColor: "#1ED5A4",
      format: "number",
    },
  ];

  public fetchingMoreUsers = true;
  public currentUserOffset = 0;

  public selectedCorrelationEvent: string = null;
  public selectedCorrelationEventValue = 0;

  public mostActiveUsers: AnalyticsResponseItemUser[] = [];
  public leastActiveUsers: AnalyticsResponseItemUser[] = [];
  public topMRRUsers: AnalyticsResponseItemUser[] = [];
  public topNPSUsers: AnalyticsResponseItemUser[] = [];

  public loadingEmotions = false;
  private orgEmotions: AnalyticsResponseItemResponseEmotions = null;
  private segmemtEmotions: AnalyticsResponseItemResponseEmotions = null;
  public emotionsChartDatasets: ChartDataset[] = [];
  public emotionsChartLegend: RadarIndicatorLegend = [
    {
      label: "Segment",
      value: "Segment",
      checked: true,
      disabled: false,
      color: "#0094FF",
      hoverColor: "#0094FF",
    },
    {
      label: "Organization",
      value: "Organization",
      checked: true,
      disabled: false,
      color: "#1ED5A4",
      hoverColor: "#1ED5A4",
    },
    {
      label: "Industry",
      value: "Industry",
      checked: true,
      disabled: false,
      color: "#0054B6",
      hoverColor: "#0054B6",
      format: "number",
    },
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private analyticsDao: AnalyticsDao,
    public featureFlaggingService: FeatureFlaggingService,
    public analyticsFilterService: AnalyticsFilterService,
    public permissionsService: PermissionsService,
    public uiService: UIService,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );
    this.obs = this.route.data.subscribe((data) => {
      this.org = data.org;
      this.industriesScores = data.industriesScores;
      this.registryEntriesGroup = data.registryEntriesGroup.groups;
      this.registryEntriesGroupType = data.registryEntriesGroup.group_types;
      this.registryEntriesIdentityProperty =
        data.registryEntriesIdentityProperty;
      this.registryEntriesIdentityPropertyById = arrayToMap(
        this.registryEntriesIdentityProperty,
        "id",
      );
      this.registryEntriesIdentityProperty =
        this.registryEntriesIdentityProperty.filter(
          (entry: RegistryEntry) => entry.type !== "object",
        );
      this.registryEntriesEvent = data.registryEntriesEvent
        .filter((entry: RegistryEntry) => !entry.hidden)
        .sort(sortRegistryEntries);
      this.surveys = data.surveys;

      this.releases = data.releases;
      this.orgAccounts = data.orgAccounts;

      this.selectedCorrelationEvent =
        this.registryEntriesEvent?.[0]?.id ?? null;

      this.currentUserGroup = this.registryEntriesGroup.find(
        (userGroup) =>
          userGroup.id === this.route.snapshot.params["segment_id"],
      );

      if (!this.currentUserGroup) {
        this.router.navigate(["/404"]);
      }

      this.groupName = getFormattedUserGroupNameOrID(this.currentUserGroup);

      this.resetFilters(new Date(this.org.created_at));
      this.setExportFilters();
    });
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }

    if (this.filtersObs$) {
      this.filtersObs$.unsubscribe();
    }
  }

  private setExportFilters() {
    const filter: AnalyticsFilterUser = {
      key: "segment",
      operator: "in",
      type: "respondent",
      values: [this.currentUserGroup.id],
    };

    this.analyticsFilterService.addFilter(filter);
  }

  // Get average org emotions
  private async getOrgEmotions() {
    return this.analyticsDao
      .search({
        org_id: this.org.id as UUID,
        survey_ids: ["*"],
        type: "response",
        filters: [
          {
            type: "response",
            key: "respondent_segments",
            operator: "in",
            values: [this.currentUserGroup.id],
          },
        ],
        range: {
          ...this.lastFilters.range,
          field: "created_at",
        },
        filters_bool: "AND",
        size: 0,
        offset: 0,
        aggregation: [{ by: "by_emotions" }],
      })
      .then((response) => {
        const { emotions } = response.aggregations;
        this.orgEmotions = {
          sadness: Math.round((emotions.sadness?.avg?.value ?? 0) * 5),
          joy: Math.round((emotions.joy?.avg?.value ?? 0) * 5),
          anger: Math.round((emotions.anger?.avg?.value ?? 0) * 5),
          fear: Math.round((emotions.fear?.avg?.value ?? 0) * 5),

          sentiment: emotions.sentiment?.avg?.value ?? 0,
        };
      });
  }

  private async getSegmentEmotions() {
    return this.analyticsDao
      .search({
        org_id: this.org.id as UUID,
        survey_ids: ["*"],
        type: "response",
        filters: [
          {
            type: "response",
            key: "respondent_segments",
            operator: "in",
            values: [this.currentUserGroup.id],
          },
        ],
        range: {
          ...this.lastFilters.range,
          field: "created_at",
        },
        filters_bool: "AND",
        size: 0,
        offset: 0,
        aggregation: [{ by: "by_emotions" }],
      })
      .then((response) => {
        const { emotions } = response.aggregations;
        this.segmemtEmotions = {
          sadness: Math.round((emotions.sadness?.avg?.value ?? 0) * 5),
          joy: Math.round((emotions.joy?.avg?.value ?? 0) * 5),
          anger: Math.round((emotions.anger?.avg?.value ?? 0) * 5),
          fear: Math.round((emotions.fear?.avg?.value ?? 0) * 5),

          sentiment: emotions.sentiment?.avg?.value ?? 0,
        };
      });
  }

  private updateEmotionsDatasets(scores: IndustriesScores) {
    const datasets = [];
    if (this.segmemtEmotions) {
      datasets.push({
        label: "Segment",
        data: [
          // Let's have a minimum of 0.25 to fix not chart when we only have one positive emotion
          Math.max(this.segmemtEmotions?.anger, 0.25),
          Math.max(this.segmemtEmotions?.joy, 0.25),
          Math.max(this.segmemtEmotions?.fear, 0.25),
          Math.max(this.segmemtEmotions?.sadness, 0.25),
        ],
        fill: true,
        borderJoinStyle: "round",
        backgroundColor: "rgba(94, 33, 241, 0.2)",
        pointRadius: 0,
      });
    } else {
      this.emotionsChartLegend.find(
        (legend) => legend.value === "Segment",
      ).disabled = true;
    }

    if (this.orgEmotions) {
      datasets.push({
        label: "Organization",
        data: [
          // Let's have a minimum of 0.25 to fix not chart when we only have one positive emotion
          Math.max(this.orgEmotions?.anger, 0.25),
          Math.max(this.orgEmotions?.joy, 0.25),
          Math.max(this.orgEmotions?.fear, 0.25),
          Math.max(this.orgEmotions?.sadness, 0.25),
        ],
        fill: true,
        borderJoinStyle: "round",
        backgroundColor: "rgba(30, 213, 164, .5)",
        pointRadius: 0,
      });
    } else {
      this.emotionsChartLegend.find(
        (legend) => legend.value === "Organization",
      ).disabled = true;
    }

    if (this.org.industry && scores[this.org.industry]) {
      datasets.push({
        label: "Industry",
        data: scores[this.org.industry].scores.emotions.map((e) => e * 5),
        borderDash: [2, 2],
        fill: false,
        borderJoinStyle: "round",
        borderColor: "rgb(94, 33, 241)",
        borderWidth: 1.5,
        pointRadius: 0,
      });
    } else {
      this.emotionsChartLegend.find(
        (legend) => legend.value === "Industry",
      ).disabled = true;
    }
    this.emotionsChartDatasets = datasets;
  }

  resetFilters(defaultStartDate?: Date) {
    this.analyticsFilterService.reset(
      "respondent",
      this.org.id,
      [],
      new Date(this.org.created_at),
      defaultStartDate,
    );
    this.filtersObs$ = this.analyticsFilterService
      .subscribe()
      .subscribe((filters: AnalyticsQueryUsers) => {
        this.lastFilters = deepCopy(filters);
        this.getSegmentsStats();
      });
  }

  private async getSegmentsStats() {
    this.error = null;

    // Get emotions based on filters
    this.loadingEmotions = true;
    Promise.all([this.getSegmentEmotions(), this.getOrgEmotions()])
      .then(() => {
        this.updateEmotionsDatasets(this.industriesScores as IndustriesScores);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        this.loadingEmotions = false;
      });

    try {
      //await Promise.all([
      // header
      await this.getUserCount();
      await this.getNps();
      await this.getAnwersCount();
      // events
      await this.getMostTrackedEvents();
      await this.getActivityTrendEvents();
      if (this.initialFetch) {
        await this.getEventCorrelation(this.selectedCorrelationEvent);
      }
      // users
      if (this.initialFetch) {
        await this.getSpecificUsersMostActive();
        await this.getSpecificUsersLeastActive();
        await this.getMostTrackedProperties();
        await this.getSpecificUsersTopMrr();
      }
    } catch (err) {
      this.error = err;
      console.error(err);
    }

    this.initialFetch = false;
  }

  getBaseQuery(withSegment = false, withEvent = false, eventId?: string) {
    const query = deepCopy(this.lastFilters) as AnalyticsQueryUsers;

    query.identified_only = false;
    query.size = 0;

    query.filters.push({
      type: "respondent",
      key: "segment",
      operator: withSegment ? "in" : "not_in",
      values: [this.currentUserGroup.id],
    });

    if (eventId) {
      if (withEvent) {
        query.filters.push({
          type: "respondent.event",
          key: eventId,
          operator: "gt",
          value: 0,
        });
      } else {
        query.filters.push({
          type: "respondent.event",
          key: eventId,
          operator: "null",
        });
      }
    }

    return query;
  }

  protected getNbrDateBucket(): number {
    const nbrBuckets =
      differenceInDays(
        this.lastFilters.range.end,
        this.lastFilters.range.start,
      ) + 1;

    if (nbrBuckets > this.TREND_GRAPH_MAX_BUCKETS) {
      return this.TREND_GRAPH_MAX_BUCKETS;
    }

    return nbrBuckets;
  }

  private getEvent(nameId: string): RegistryEntry | undefined {
    return this.registryEntriesEvent.find(({ id }) => nameId === id);
  }

  private async getMostTrackedEvents() {
    this.loadings.getMostTrackedEvents = true;

    const query = this.getBaseQuery(true);

    query.aggregation = [{ by: "by_event.most_triggered" }];

    const response = await this.analyticsDao.search(query);

    this.mostTriggeredEvents = (
      response.aggregations?.event?.event_count?.buckets ?? []
    )
      .map(({ key, event_count: { value } }): UserNormalizedEvent => {
        const event = this.getEvent(key);

        if (!event) {
          return;
        }

        return {
          id: event.id,
          name_id: key,
          org_id: event.tenant_id,
          type: event.subject,
          name: event.title,
          sources: event.sources,
          count: value,
        };
      })
      .filter(Boolean);

    this.mostTriggeredEventsAveragePerUser =
      response.aggregations.event.event_average.buckets
        .map(({ key, event_average: { value } }): UserNormalizedEvent => {
          const event = this.getEvent(key);

          if (!event) {
            return;
          }

          return {
            id: event.id,
            name_id: key,
            org_id: event.tenant_id,
            type: event.subject,
            name: event.title,
            sources: event.sources,
            count: value,
          };
        })
        .filter(Boolean);

    this.loadings.getMostTrackedEvents = false;
  }

  private async getActivityTrendEvents() {
    this.loadings.getActivityTrendEvents = true;

    const query = this.getBaseQuery(true);

    query.aggregation = [
      {
        by: "by_event.date",
        params: {
          date_histogram_min_interval: "day",
          date_histogram_buckets: this.getNbrDateBucket(),
          // we have to change the sign of timezone, because Javascript sucks
          // this is pure shit
          // https://stackoverflow.com/questions/55564508/pie-chart-js-display-a-no-data-held-message
          date_histogram_timezone_offset: -new Date().getTimezoneOffset(),
        },
      },
    ];

    const response = await this.analyticsDao.search(query);

    const style: ChartDataset = {
      yAxisID: "main",
      fill: "transparent",
      data: [],
    };

    this.trendChartDataset = [
      {
        ...style,
        data: (response.aggregations?.event?.date?.buckets ?? []).map(
          ({ key_as_string, doc_count }) => ({
            x: +new Date(key_as_string),
            y: doc_count,
          }),
        ),
        label: "Activity",
        borderColor: "#1ED5A4",
        pointBackgroundColor: "#1ED5A4",
        pointHoverBorderColor: "#1ED5A4",
        pointHoverBackgroundColor: "#1ED5A4",
      },
    ];

    this.loadings.getActivityTrendEvents = false;
  }

  private async getUserCount() {
    this.loadings.getUserCount = true;

    const query = this.getBaseQuery(true);

    query.size = USER_PAGE_SIZE;
    query.offset = this.currentUserOffset;
    query.identified_only = false;

    const response = await this.analyticsDao.search(query);

    if (this.fetchingMoreUsers && response.hits.respondents) {
      this.users = this.users.concat(response.hits.respondents);
    }

    this.totalNumberOfUserInSegment = response.hits.total;

    this.fetchingMoreUsers = false;
    this.loadings.getUserCount = false;
  }

  public async loadMoreUsers() {
    this.currentUserOffset += USER_PAGE_SIZE;
    this.fetchingMoreUsers = true;
    await this.getUserCount();
  }

  private async getNps() {
    this.loadings.getNps = true;

    const query: AnalyticsQueryResponse = {
      org_id: UUID(this.org.id),
      survey_ids: ["*"],
      filters_bool: "AND",
      type: "response",
      filters: [
        {
          type: "response",
          key: "respondent_segments",
          operator: "in",
          values: [this.currentUserGroup.id],
        },
      ],
      range: {
        ...this.lastFilters.range,
        field: "created_at",
      },
      having_answer_only: true,
      aggregation: [
        {
          by: "by_answer.value",
          params: {
            cta_type: "nps",
          },
        },
      ],
      size: 0,
    };

    const response = await this.analyticsDao.search(query);

    this.npsAggregation = bucketToNpsAggregation(
      response.aggregations ?? {},
      getDateAnswerValueBuckets,
      this.surveys.map(({ scenario }) => scenario),
    );

    this.loadings.getNps = false;
  }

  private async getAnwersCount() {
    this.loadings.getAnwersCount = true;

    const query: AnalyticsQueryResponse = {
      org_id: UUID(this.org.id),
      survey_ids: this.surveys.map(({ id }) => UUID(id)),
      filters_bool: "AND",
      type: "response",
      filters: [
        {
          type: "response",
          key: "respondent_segments",
          operator: "in",
          values: [this.currentUserGroup.id],
        },
      ],
      range: {
        ...this.lastFilters.range,
        field: "created_at",
      },
      having_answer_only: true,
      aggregation: [],
      size: 0,
    };

    const response = await this.analyticsDao.search(query);

    this.numberOfSurveyResponsesForThisSegment = response.hits.total;

    this.loadings.getAnwersCount = false;
  }

  private async getEventCorrelationPart(
    withSegment: boolean,
    withEvent: boolean,
    eventId: string,
  ) {
    const query = this.getBaseQuery(withSegment, withEvent, eventId);

    return this.analyticsDao.search(query);
  }

  private async getEventCorrelation(eventId: string) {
    this.loadings.getEventCorrelation = true;

    if (!eventId) {
      this.selectedCorrelationEventValue = 0;
      return;
    }
    await new Promise((r) => setTimeout(r, 1000));

    const responses = await Promise.all([
      this.getEventCorrelationPart(false, false, eventId),
      this.getEventCorrelationPart(true, false, eventId),
      this.getEventCorrelationPart(false, true, eventId),
      this.getEventCorrelationPart(true, true, eventId),
    ]);

    const [n00, n10, n01, n11] = responses.map(
      (response) => response.hits.total,
    );
    const correlation =
      (n11 * n00 - n10 * n01) /
      Math.sqrt((n11 + n10) * (n01 + n00) * (n10 + n00) * (n11 + n01));

    this.selectedCorrelationEventValue = correlation || 0;

    this.loadings.getEventCorrelation = false;
  }

  private async getMostTrackedProperties() {
    this.loadings.getMostTrackedProperties = true;

    const query = this.getBaseQuery(true);

    query.aggregation = [{ by: "by_property.most_tracked" }];

    const response = await this.analyticsDao.search(query);

    const propsTypes = {
      v_s: "value_string",
      v_d: "value_numeric",
      v_b: "value_bool",
      v_t: "value_date",
    };

    const properties = Object.entries(propsTypes)
      .flatMap(([typeKey, typeValue]) =>
        response.aggregations.properties[typeKey].buckets.flatMap(
          ({ key, doc_count: count }) => ({
            id: key[0],
            key_id: key[0],
            [typeValue]: key[1],
            count,
          }),
        ),
      )
      .sort((a, b) => b.count - a.count)
      .filter(({ count, value_string }) => count > 1 && value_string !== "")
      .map((property) => new UserProperty().fromJson(property))
      .slice(0, 10);

    this.mostTrackedProperties = aggregateUserProperties(
      this.registryEntriesIdentityPropertyById,
      properties,
    );

    this.loadings.getMostTrackedProperties = false;
  }

  public async getSpecificUsersMostActive() {
    this.loadings.getSpecificUsersMostActive = true;

    const query = this.getBaseQuery(true);
    query.size = 1;
    query.sort = { field: "respondent.events_count", order: "desc" };

    const response = await this.analyticsDao.search(query);
    this.mostActiveUsers = response.hits.respondents;

    this.loadings.getSpecificUsersMostActive = false;
  }

  public async getSpecificUsersLeastActive() {
    this.loadings.getSpecificUsersLeastActive = true;

    const query = this.getBaseQuery(true);
    query.size = 1;
    query.sort = { field: "respondent.events_count", order: "asc" };

    const response = await this.analyticsDao.search(query);
    this.leastActiveUsers = response.hits.respondents;

    this.loadings.getSpecificUsersLeastActive = false;
  }

  public async getSpecificUsersTopMrr() {
    this.loadings.getSpecificUsersTopMrr = true;

    const query = this.getBaseQuery(true);

    query.aggregation = [{ by: "by_power_users.top_mrr" }];

    const response = await this.analyticsDao.search(query);

    this.topMRRUsers =
      response.aggregations?.top_mrr_user?.buckets[0]?.docs.hits.hits.map(
        ({ _source }) => AnalyticsResponseItemUser.fromJson(_source),
      ) ?? [];

    this.topMRRUsers = this.topMRRUsers.filter(({ properties }) =>
      Boolean(properties.find(({ key_name }) => key_name === "mrr")?.v_d),
    );

    this.loadings.getSpecificUsersTopMrr = false;
  }

  selectedCorrelationEventChange(eventId: string) {
    this.getEventCorrelation(eventId);
  }
}

// https://help.screeb.app/en/articles/6364668-default-user-properties-and-how-to-use-them
