import { Component, Input, OnChanges } from "@angular/core";

import {
  surveyCappingTimePeriodTypesAndLabels,
  SurveyCappingType,
} from "models/survey-targeting-rule-capping.model";
import { TargetingRule, TargetingRuleType } from "models/targeting-rule.model";

@Component({
  selector: "survey-capping-summary",
  templateUrl: "./survey-capping-summary.component.html",
  styleUrls: ["./survey-capping-summary.component.scss"],
})
export class SettingsSurveyCappingSummaryComponent implements OnChanges {
  @Input() public rules: TargetingRule[] = null;
  @Input() public surveyCappingType: SurveyCappingType = null;

  public surveyCappingDisplay?: TargetingRule;
  public surveyCappingResponse?: TargetingRule;

  public surveyCappingTimePeriodTypesAndLabels =
    surveyCappingTimePeriodTypesAndLabels;

  constructor() {}

  ngOnChanges(): void {
    switch (this.surveyCappingType) {
      case "per-org":
        this.surveyCappingDisplay = this.findRuleByType("capping_org_display");
        this.surveyCappingResponse = this.findRuleByType(
          "capping_org_response",
        );
        break;
      case "per-survey":
        this.surveyCappingDisplay = this.findRuleByType(
          "capping_survey_display",
        );
        this.surveyCappingResponse = this.findRuleByType(
          "capping_survey_response",
        );
        break;
      case "per-respondent":
        this.surveyCappingDisplay = this.findRuleByType(
          "capping_respondent_display",
        );
        this.surveyCappingResponse = this.findRuleByType(
          "capping_respondent_response",
        );
        break;
    }
  }

  private findRuleByType(type: TargetingRuleType): TargetingRule {
    const rule = this.rules.find((r: TargetingRule) => r.type === type);
    return rule ? rule : null;
  }

  public levelToText(): string {
    switch (this.surveyCappingType) {
      case "per-org":
        return "This account";
      case "per-survey":
        return "Survey";
      case "per-respondent":
        return "Users";
    }
  }
}
