<div class="indicator-with-border full-height">
  <div class="indicator-header">
    <h4>Activity</h4>
    <a
      routerLink="/org/{{ user.org_id }}/people/respondent/{{ user.id }}"
      [queryParams]="{ tab: 'properties' }"
    >
      See properties
    </a>
  </div>
  <div class="loader" *ngIf="loadingResponsesES && initialFetchResponsesES">
    <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
  </div>
  <ng-container *ngIf="!initialFetchResponsesES || !loadingResponsesES">
    <ul class="stats-list">
      <ng-container *ngFor="let alias of user.aliases">
        <li class="stat-item" *ngIf="alias !== user.id">
          <div class="stat-item-header">
            <square-icon icon="star"></square-icon>
            <div class="stat-item-data">
              <div class="stat-item-title">Identity</div>
              <div class="stat-item-value" [title]="alias">
                {{ alias }}
              </div>
            </div>
          </div>
        </li>
      </ng-container>
      <li class="stat-item">
        <div class="stat-item-header">
          <square-icon icon="hourglass"></square-icon>
          <div class="stat-item-data">
            <div class="stat-item-title">First seen</div>
            <div
              class="stat-item-value"
              [title]="user.created_at | toLocaleString"
            >
              {{
                user.created_at | dfnsFormatDistanceToNow: { addSuffix: true }
              }}
            </div>
          </div>
        </div>
      </li>
      <li class="stat-item">
        <div class="stat-item-header">
          <square-icon icon="bye"></square-icon>
          <div class="stat-item-data">
            <div class="stat-item-title">Last seen</div>
            <div
              class="stat-item-value"
              [title]="user.last_activity_at | toLocaleString"
            >
              {{
                user.last_activity_at
                  | dfnsFormatDistanceToNow: { addSuffix: true }
              }}
            </div>
          </div>
        </div>
      </li>
      <li class="stat-item">
        <div class="stat-item-header">
          <square-icon icon="response"></square-icon>
          <div class="stat-item-data">
            <div class="stat-item-title">Number of responses</div>
            <div class="stat-item-value">
              {{ responsesCount }}
            </div>
          </div>
        </div>
      </li>
      <li class="stat-item">
        <div class="stat-item-header">
          <square-icon icon="survey"></square-icon>
          <div class="stat-item-data">
            <div class="stat-item-title">Last response</div>
            <div
              class="stat-item-value"
              *ngIf="!lastResponseDate || +lastResponseDate === 0"
            >
              N/A
            </div>
            <div
              class="stat-item-value"
              [title]="lastResponseDate | toLocaleString"
              *ngIf="lastResponseDate && +lastResponseDate !== 0"
            >
              {{
                lastResponseDate | dfnsFormatDistanceToNow: { addSuffix: true }
              }}
            </div>
          </div>
        </div>
      </li>
    </ul>
  </ng-container>
</div>
