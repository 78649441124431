<div *ngIf="rule">
  <div
    *ngFor="let line of rule.value.v_s_arr; let i = index; trackBy: trackByFn"
    class="or-nor-container"
  >
    <rule-or-neither [rule]="rule" [index]="i"></rule-or-neither>

    <input
      type="text"
      nz-input
      placeholder="{{ rule.getValuePlaceholder() }}"
      [ngModel]="line"
      (ngModelChange)="onOrRuleChange(rule, $event, i, false)"
      [ngClass]="{ invalid: !checkValidUrlRule(rule, i) }"
      [disabled]="!('survey_edition' | hasEntitlement)"
    />
  </div>
</div>
