<free-trial-banner />
<h1>Integrations</h1>

<upgrade-modal
  *ngIf="isUpgradeModalVisible"
  position="fixed"
  [centeredModal]="true"
  [withOverlay]="true"
  [orgId]="org.id"
  class="upgrade-modal"
  (closeClick)="isUpgradeModalVisible = false"
></upgrade-modal>

<header>
  <p>
    Connect Screeb to your daily tools and unleash the power of your users
    feedback!
  </p>

  <div class="search-bar">
    <nz-input-group
      nzSize="large"
      [nzPrefix]="prefixIcon"
      [nzSuffix]="inputClearButton"
    >
      <input
        type="text"
        nz-input
        placeholder="Search an integration"
        [(ngModel)]="keywords"
        (ngModelChange)="onSearchChange()"
      />
    </nz-input-group>
    <ng-template #prefixIcon><i nz-icon nzType="search"></i></ng-template>
    <ng-template #inputClearButton>
      <i
        nz-icon
        class="ant-input-clear-icon"
        nzTheme="fill"
        nzType="close-circle"
        *ngIf="keywords"
        (click)="keywords = ''; onSearchChange()"
      ></i>
    </ng-template>
  </div>
</header>

<section>
  <ul class="connectors">
    <li class="connector" *ngFor="let integration of visibleIntegrations">
      <ng-template #content>
        <ul class="categories">
          <li class="category" *ngFor="let category of integration.categories">
            <nz-tag [nzColor]="integrationCategorySpec[category].color">
              {{ integrationCategorySpec[category].name }}
            </nz-tag>
          </li>
        </ul>
        <p>
          {{ integration.shortDescription }}
        </p>
      </ng-template>

      <ng-container *ngIf="isIntegrationInstalled(integration.type)">
        <card
          [id]="'integration-card-' + integration.type.toString()"
          [titleTpl]="title"
          [contentTpl]="content"
          [headerCss]="{
            'background-image': getCssBackground(integration.imageSrc),
            'background-origin': 'content-box',
            padding: '2rem',
          }"
          [actionLeft]="actionLeft"
          cardPath="/org/{{ org.id }}/integrations/{{
            integration.type.toString()
          }}"
        ></card>
        <ng-template #title>
          {{ integration.name }}

          <nz-tag
            [nzColor]="'#ffc53d'"
            class="auth-error"
            *ngIf="isIntegrationAuthBroken(integration.type)"
          >
            <span nz-icon nzType="exclamation-circle"></span>
            <span>Please reconnect</span>
          </nz-tag>
        </ng-template>

        <ng-template #actionLeft>
          <a
            class="edit"
            nz-button
            nzType="link"
            (click)="$event.stopPropagation()"
            routerLink="/org/{{ org.id }}/integrations/{{
              integration.type.toString()
            }}"
          >
            Edit
          </a>
          <button
            class="remove"
            nz-button
            nzType="link"
            (click)="showRemoveConfirm($event, integration.type)"
          >
            Remove
          </button>
        </ng-template>
      </ng-container>

      <ng-container *ngIf="!isIntegrationInstalled(integration.type)">
        <card
          [id]="'integration-card-' + integration.type.toString()"
          [title]="integration.name"
          [contentTpl]="content"
          [headerCss]="{
            'background-image': getCssBackground(integration.imageSrc),
            'background-origin': 'content-box',
            padding: '2rem',
          }"
          [actionLeft]="actionLeft"
          [quotaOk]="
            entitlementService.isIntegrationAvailable(integration.type)
          "
          (cardClick)="
            onInstall($event, integration.type, integration.createOnInstall)
          "
        ></card>
        <ng-template #actionLeft>
          <a
            *ngIf="'integration.create' | hasPermission"
            class="install"
            nz-button
            nzType="link"
            (click)="
              onInstall($event, integration.type, integration.createOnInstall)
            "
          >
            Install
          </a>
        </ng-template>
      </ng-container>
    </li>
  </ul>
</section>
