import { HttpErrorResponse } from "@angular/common/http";
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { autoTips } from "helpers/form-errors.helper";
import { NotificationHelper } from "helpers/notification.helper";
import { Channel } from "models/channel.model";
import { Org } from "models/org.model";
import { ClipboardService } from "ngx-clipboard";
import { ScreebApiHelper, SessionService } from "services/auth.service";
import { EntitlementService } from "services/entitlement.service";
import { TrackingEventName } from "services/trackers.events";
import { TrackersService } from "services/trackers.service";

import { ActivatedRoute, Router } from "@angular/router";
import { AccountDao } from "models/account.dao";
import { NzModalRef, NzModalService } from "ng-zorro-antd/modal";
import { AnalyticsFilterService } from "services/analytics-filters.service";
import { ConfigService } from "services/config.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import {
  getGTMCodeBlocks,
  getScreebCodeBlocks,
  getSegmentActionCodeBlocks,
  GuideType,
} from "./onboarding-installation.data";

@Component({
  selector: "onboarding-installation-guide",
  templateUrl: "./onboarding-installation-guide.component.html",
  styleUrls: [
    "./onboarding-installation-guide.component.scss",
    "../../layout/onboarding-layout.component.scss",
  ],
})
export class OnBoardingInstallationGuideComponent implements OnInit, OnChanges {
  public codeBlocks = [];

  @Input() public channels: Channel[] = [];
  @Input() public org: Org = null;
  @Input() public guideType: GuideType = "screeb";
  @Input() public verifyInstall: boolean = false;
  @Input() public isOnboarding: boolean = true;
  @Input() public segmentToken: string = null;

  public validateForm: FormGroup = null;
  public autoTips = autoTips;

  public emailSent = false;
  public emailLoading = false;
  public emailErrors: any = null;

  public verifyInstallationLoading = false;

  public installationStatus: "not-checked" | "failed" | "succeeded" =
    "not-checked";

  public selectedTab = 0;
  public selectedOption = 0;
  public selectedStep = 0;

  constructor(
    private clipboardService: ClipboardService,
    private notificationHelper: NotificationHelper,
    private route: ActivatedRoute,
    private router: Router,
    private trackersService: TrackersService,
    private analyticsFilterService: AnalyticsFilterService,
    private accountDao: AccountDao,
    private modal: NzModalService,
    private formBuilder: FormBuilder,
    private screebApiHelper: ScreebApiHelper,
    public entitlementService: EntitlementService,
    public sessionService: SessionService,
    public featureFlaggingService: FeatureFlaggingService,
    private configService: ConfigService,
  ) {}

  ngOnInit(): void {
    this.validateForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
    });

    const from = this.route.snapshot.queryParamMap.get("from");

    if (from === "segment") {
      this.selectedTab = 0;
      this.selectedOption = 4;
      this.selectedStep = 1;
    }
  }

  ngOnChanges({ guideType, org }: SimpleChanges) {
    if (
      (!!guideType && guideType.currentValue !== guideType.previousValue) ||
      org.currentValue !== org.previousValue
    ) {
      this.refreshCodeBlocks();
    }
  }

  public setSelectedOption(optionIdx: number) {
    this.selectedOption = optionIdx;
    this.selectedStep = 0;
  }

  public getOptionsSteps(tab: object) {
    if (tab["option"]) return tab["option"].steps;
    else if (tab["options"]) {
      const option = tab["options"][this.selectedOption] || tab["options"][0];

      return option.steps;
    }

    return [];
  }
  private async refreshCodeBlocks() {
    // const androidSDKVersion = await this.getAndroidSDKVersion();
    this.selectedTab = 0;
    this.selectedOption = 0;

    switch (this.guideType) {
      case "screeb":
        this.codeBlocks = getScreebCodeBlocks(
          this.featureFlaggingService,
          this.configService,
          this.channels,
          !this.isOnboarding,
        );
        return;
      case "segment":
        this.codeBlocks = getSegmentActionCodeBlocks(this.channels);
        return;
      case "gtm":
        this.codeBlocks = getGTMCodeBlocks(
          this.configService.config,
          this.channels,
        );
        return;
    }
  }

  clipboardCopy(code: string, trackerEvent?: TrackingEventName) {
    this.clipboardService.copy(code);
    this.notificationHelper.trigger(
      "Copied to your clipboard!",
      null,
      "success",
    );

    if (trackerEvent) {
      this.trackersService
        .newEventTrackingBuilder(trackerEvent)
        .withOrg(this.org)
        .withAccount(this.sessionService.session)
        .build();
    }
  }

  async askAColleage(email: string) {
    return await this.screebApiHelper
      .post<object>(`/org/${this.org.id}/ask_a_colleague`, {
        email,
      })
      .toPromise();
  }

  public getCompanyDomain(email: string | null) {
    return email?.split("@")[1] ?? "company.com";
  }

  sendEmail() {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (!this.validateForm.valid) return;

    const data = this.validateForm.value;

    this.emailSent = false;
    this.emailLoading = true;
    this.emailErrors = null;

    this.askAColleage(data.email)
      .then(() => (this.emailSent = true))
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        this.emailErrors = err.error;
      })
      .finally(() => {
        this.emailLoading = false;
      });
  }

  async verifyInstallation() {
    if (this.installationStatus === "succeeded") {
      return this.onDone();
    }

    if (this.verifyInstallationLoading) {
      return;
    }

    this.verifyInstallationLoading = true;
    this.installationStatus = "not-checked";

    this.trackersService
      .newEventTrackingBuilder("Screeb Onboarding Installation Verify")
      .withOrg(this.org)
      .withAccount(this.sessionService.session)
      .build();

    this.analyticsFilterService
      .workspaceHasUsers(this.org.id)
      .then((hasUsers) => {
        if (hasUsers) {
          this.installationStatus = "succeeded";
          this.trackersService
            .newEventTrackingBuilder(
              "Screeb Onboarding Installation Verify Succeeded",
            )
            .withOrg(this.org)
            .withAccount(this.sessionService.session)
            .build();
        } else {
          this.installationStatus = "failed";
          this.trackersService
            .newEventTrackingBuilder(
              "Screeb Onboarding Installation Verify Failed",
            )
            .withOrg(this.org)
            .withAccount(this.sessionService.session)
            .build();
        }
      })
      .catch((err) => {
        this.installationStatus = "failed";
        console.error(err);
      })
      .finally(() => (this.verifyInstallationLoading = false));
  }

  async onNext() {
    this.selectedTab += 1;
  }

  onDone() {
    this.trackersService
      .newEventTrackingBuilder("Screeb Onboarding Installation Done")
      .withOrg(this.org)
      .withAccount(this.sessionService.session)
      .build();

    return this.goToNextStep();
  }

  public onSkip(): void {
    const modal: NzModalRef = this.modal.create({
      nzTitle: "Are you sure?",
      nzContent:
        "You won't be able to start using Screeb in your app until you install our tag or SDK.",
      nzStyle: {
        display: "flex",
        "align-items": "center",
        "justify-content": "center",
      },
      nzFooter: [
        {
          label: "Continue without installing",
          type: "default",
          danger: true,
          onClick: () => modal.triggerOk(),
        },
        {
          label: "Install now",
          type: "primary",
          onClick: () => modal.triggerCancel(),
        },
      ],
      nzOnOk: () => {
        this.trackersService
          .newEventTrackingBuilder("Screeb Onboarding Installation Skipped")
          .withOrg(this.org)
          .withAccount(this.sessionService.session)
          .build();

        return this.goToNextStep();
      },
    });
  }

  goToNextStep() {
    const isInvited = this.sessionService.isOnboardingInvited();

    if (isInvited) {
      this.accountDao.updateOnboardingStatus("product-tour").then(() => {
        this.router.navigate(["org", this.org.id]);
      });
    } else {
      this.router.navigate(["org", this.org.id, "onboarding", "book-demo"]);
    }
  }

  // private async getAndroidSDKVersion() {
  //   return this.http
  //     .get(
  //       'https://search.maven.org/solrsearch/select?q=g:"app.screeb.sdk"&rows=20&wt=json'
  //     )
  //     .toPromise()
  //     .then((obj) => {
  //       return obj["response"]?.["docs"]?.[0]?.["latestVersion"];
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //       return "1.x.x";
  //     });
  // }

  // private async getIOSSDKVersion() {
  //   return this.http
  //     .get(
  //       "https://wbhhamhynm-dsn.algolia.net/1/indexes/cocoapods/query?x-algolia-agent=Algolia%20for%20JavaScript%20(3.33.0)%3B%20Browser&x-algolia-application-id=WBHHAMHYNM&x-algolia-api-key=4f7544ca8701f9bf2a4e55daff1b09e9"
  //     )
  //     .toPromise()
  //     .then((obj) => {
  //       // return obj["response"]?.["docs"]?.[0]?.["latestVersion"];
  //       return "42";
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //       return "1.x.x";
  //     });
  // }
}
