<label class="lateral-panel-subtitle">Options</label>

<nz-table
  [nzData]="cta.scores"
  [nzFrontPagination]="false"
  [nzShowPagination]="false"
>
  <tbody cdkDropList (cdkDropListDropped)="drop($event)">
    <tr *ngFor="let button of cta.scores; let ctaIndex = index" cdkDrag>
      <td *cdkDragPlaceholder class="drag-placeholder"></td>
      <td>
        <div class="panel-button-line">
          <span
            *ngIf="this.node.node.question.call_to_action.type === 'scoring'"
            class="holder-icon"
            nz-icon
            nzType="holder"
            nzTheme="outline"
            cdkDragHandle
          ></span>
          <button
            #btnElement
            title="Open emoji picker"
            class="emoji-button"
            [disabled]="node.isPreset && !node.isCSAT"
            (click)="
              (!node.isPreset || node.isCSAT) &&
                openEmojiPicker(button, btnElement)
            "
          >
            <sanitized-message-component
              [content]="button.payload.emoji"
              [options]="{
                styling: true,
                emojiTranscoder: true,
              }"
            ></sanitized-message-component>
          </button>
          <lateral-panel-step-picker
            [routing]="node.node.routing"
            [chainableNodeIds]="node.chainableNodeIds"
            [actions]="[button]"
          ></lateral-panel-step-picker>

          <button
            title="Remove option"
            *ngIf="!node.isPreset && cta.scores.length > 1"
            class="remove-button"
            (click)="removeOption(ctaIndex)"
          >
            <i nz-icon nzType="close-circle"></i>
          </button>
        </div>
      </td>
    </tr>
  </tbody>
</nz-table>

<button
  *ngIf="!node.isPreset && cta.scores.length < 11"
  nz-button
  nzType="default"
  nzSize="large"
  (click)="addOption()"
>
  + Add an option
</button>

<ng-container>
  <label class="lateral-panel-subtitle">Rating Scale</label>
  <div class="input-wrapper">
    <div>
      <p class="lateral-panel-tip">
        <span>
          For min value
          <screeb-icon
            size="sm"
            icon="info-circle"
            nz-popover
            nzType="primary"
            nzPopoverPlacement="bottomLeft"
            [nzPopoverContent]="contentTemplate"
          ></screeb-icon>
          <ng-template #contentTemplate>
            The rating scale is the text that will be displayed on the slider
            for the minimum value, leave empty if you don't want to display
            anything.
          </ng-template>
        </span>
      </p>
      <input
        type="text"
        class="panel-input"
        placeholder="Min rating scale"
        [ngModel]="getLabelLegendMin()"
        (ngModelChange)="setLabelLegendMin($event); validateData()"
      />
    </div>
    <div>
      <p class="lateral-panel-tip">
        <span>
          For max value
          <screeb-icon
            size="sm"
            icon="info-circle"
            nz-popover
            nzType="primary"
            nzPopoverPlacement="bottomLeft"
            [nzPopoverContent]="contentTemplate"
          ></screeb-icon>
          <ng-template #contentTemplate>
            The legend is the text that will be displayed on the slider for the
            maximum value, leave empty if you don't want to display anything.
          </ng-template>
        </span>
      </p>
      <input
        type="text"
        class="panel-input"
        placeholder="Max rating scale"
        [ngModel]="getLabelLegendMax()"
        (ngModelChange)="setLabelLegendMax($event); validateData()"
      />
    </div>
  </div>
</ng-container>

<ng-container>
  <label class="lateral-panel-subtitle skip-to-step-title">Skip to step</label>
  <p class="lateral-panel-tip">Allowing users to skip to a next step</p>

  <lateral-panel-step-picker
    [routing]="node.node.routing"
    [chainableNodeIds]="node.chainableNodeIds"
    [isSkipAction]="true"
    [actions]="[skipAction]"
  ></lateral-panel-step-picker>
</ng-container>

<ng-container>
  <label class="lateral-panel-subtitle">Responsive</label>
  <p class="lateral-panel-tip">Display options in reverse order on mobile</p>
  <nz-switch
    ngDefaultControl
    [ngModelOptions]="{ standalone: true }"
    [ngModel]="cta.responsive"
    (ngModelChange)="setIsResponsive($event)"
  ></nz-switch>
</ng-container>
