import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";

import { ApplicationLoadService } from "services/app.service";
import { SurveyScenarioNodeDao } from "models/survey-scenario-node.dao";
import { ScenarioNode } from "models/survey.dao.types";

@Injectable()
export class SurveyScenarioNodeResolver implements Resolve<any> {
  constructor(
    private applicationLoadService: ApplicationLoadService,
    private surveyScenarioNodeDao: SurveyScenarioNodeDao,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Promise<ScenarioNode[]> {
    return this.applicationLoadService
      .loader()
      .then(() => {
        return this.surveyScenarioNodeDao.getBySurveyIDAndCTAType(
          route.params.org_id,
          route.data.allSurveys ? "all" : route.params.survey_id,
          route.data.node_type,
        );
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        throw err;
      });
  }
}
