import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SessionService } from "services/auth.service";

@Component({
  selector: "onboarding-header",
  template: `
    <div class="onboarding-container">
      <div class="onboarding-header" *ngIf="currStep !== -1">
        <steps-component
          [steps]="steps"
          [currentStep]="currStep"
          [validatePastSteps]="true"
        />
      </div>
      <div class="onboarding-content">
        <router-outlet></router-outlet>
      </div>
    </div>
  `,
  styleUrls: ["onboarding-header.scss"],
})
export class OnboardingHeaderComponent implements OnInit {
  public steps: string[] = [];
  public stepKeys: string[][] = [
    ["account_created"],
    ["qualification", "qualification-invited"],
    ["goal", "goal-invited"],
    ["setup", "setup-invited", "installation", "discovery"],
  ];
  public currStep = 1;

  constructor(
    public sessionService: SessionService,
    public router: Router,
  ) {}

  ngOnInit() {
    const firstname = this.sessionService.session.firstname;
    const profileStep =
      firstname.length > 0 ? `${firstname}’s Profile` : "Profile";

    this.steps = [
      "Account Created",
      profileStep,
      "Your Goal",
      "Start Using Screeb",
    ];

    this.router.events.subscribe(() => {
      const parsedUrl = this.router.url.split("/");
      const route = parsedUrl[parsedUrl.length - 1];
      const cleanedRoute = route.split("?")[0];
      const idx = this.stepKeys.findIndex((step) =>
        step.includes(cleanedRoute),
      );

      this.currStep = idx;
    });
  }
}
