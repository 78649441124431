import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { HttpErrorResponse } from "@angular/common/http";
import { ChartDataset } from "chart.js";
import { PageComponentInterface } from "components/PageComponentInterface";
import { TreemapData } from "components/utils/treemap/treemap.component";
import { Account } from "models/account.model";
import { AnalyticsDao } from "models/analytics.dao";
import { AnalyticsQueryResponse } from "models/analytics.filters.type";
import {
  AnalyticsResponse,
  AnalyticsResponseItemResponse,
  AnalyticsResponseItemResponseEmotions,
} from "models/analytics.model";
import { Integration } from "models/integrations.model";
import { Org } from "models/org.model";
import { RegistryEntry } from "models/registry.model";
import { Release } from "models/release.model";
import { Survey } from "models/survey.model";
import { getUserIcon } from "models/user.model";
import { LanguageWithEmoji } from "resolvers/asset-languages-countries";
import { AnalyticsFilterService } from "services/analytics-filters.service";
import { EntitlementService } from "services/entitlement.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { RoutingService } from "services/routing.service";
import { TrackersService } from "services/trackers.service";
import { UIService } from "services/ui.service";
import { Debounce } from "utils/debounce";
import { deepCopy } from "utils/object";
import { QuestionDetails } from "../all-responses/questions-table/questions-table.component";
import { TrendIndicatorLegend } from "../indicators/components/trend-indicator/trend-indicator.config";
import { IndicatorStatsSurveyPageComponent } from "../indicators/indicator.component";
import {
  OverallEmotionPerformancesAggregation as ContentAnalysisAggregation,
  getAggregations,
  getResponseCallback,
} from "./content-analysis.aggregation";

// const SIGNIFICANT_TEXT_COUNT = 10;

const estimatedLoadingTimeLadder = [
  { treshold: 1000, message: "a few seconds" },
  { treshold: 10000, message: "15 seconds" },
  { treshold: 20000, message: "20 seconds" },
  { treshold: 30000, message: "30 seconds" },
  { treshold: 40000, message: "40 seconds" },
  { treshold: 50000, message: "50 seconds" },
  { treshold: 60000, message: "a few minutes" },
];

type ContentAnalysisKeyword = {
  word: string;
  score: number;
};

type ContentAnalysis = {
  summary: string;
  opportunities: string;
  trends: string;
  strengths: string;
  weakness: string;
  nextSteps: { title: string; detail: string }[];
  qa: { question: string; answer: string }[];
  feedbacks: string[];
  keywords: ContentAnalysisKeyword[];

  answers: AnalyticsResponseItemResponse[];
};

@Component({
  selector: "page-survey-stats-content-analysis",
  templateUrl: "./content-analysis.component.html",
  styleUrls: ["./content-analysis.component.scss"],
})
export class ContentAnalysisStatsSurveyPageComponent
  extends IndicatorStatsSurveyPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Analyze - Content Analysis";
  public name = "Content Analysis";

  public maxAnswersCount: number = 5000;

  public obs: any = null;

  public org: Org = null;
  public survey: Survey = null;
  public integrations: Integration[] = [];
  public languages: LanguageWithEmoji[] = [];
  public registryEntriesGroup: RegistryEntry[] = [];
  public questionDetails: QuestionDetails[] = [];
  public registryEntriesIdentityProperty: RegistryEntry[] = [];
  public registryEntriesEvent: RegistryEntry[] = [];

  public totalResponsesCount: number = 0;
  public filteredResponseCount: number = 0;
  public answersCount: number = 0;
  public loadgingTimeMessage: string = null;

  public filtersObs$: any = null;
  public lastFilters: AnalyticsQueryResponse;

  public hideGraphs = true;

  public getUserIcon = getUserIcon;

  public overallPerformancesPerDateCurrentPeriod: ContentAnalysisAggregation[] =
    null;
  public overallPerformancesCurrentPeriod: ContentAnalysisAggregation = null;
  private surveyEmotions: AnalyticsResponseItemResponseEmotions = null;

  public releases: Release[] = [];
  public orgAccounts: Account[] = [];

  public emotionTrendChartDataset: ChartDataset[] = [];
  public sentimentTrendChartDataset: ChartDataset[] = [];
  public emotionTrendChartLegend: TrendIndicatorLegend = [
    {
      label: "Joy",
      value: "Joy",
      checked: true,
      color: "#1ED5A4",
      hoverColor: "#1ED5A4",
    },
    {
      label: "Fear",
      value: "Fear",
      checked: true,
      color: "#FFB546",
      hoverColor: "#FFB546",
    },
    {
      label: "Sadness",
      value: "Sadness",
      checked: true,
      color: "#502C9E",
      hoverColor: "#502C9E",
    },
    {
      label: "Anger",
      value: "Anger",
      checked: true,
      color: "#F11672",
      hoverColor: "#F11672",
    },
  ];

  public sentimentTrendChartLegend: TrendIndicatorLegend = [
    {
      label: "Positive",
      value: "Positive",
      checked: true,
      color: "#1ED5A4",
      hoverColor: "#1ED5A4",
    },
    {
      label: "Neutral",
      value: "Neutral",
      checked: true,
      color: "#502C9E",
      hoverColor: "#502C9E",
    },
    {
      label: "Negative",
      value: "Negative",
      checked: true,
      color: "#F11672",
      hoverColor: "#F11672",
    },
  ];

  public valueFormatter = (value: number) => `${value}`;

  public contentLoading = true;
  public contentError: Error = null;
  public contentAnalysis: ContentAnalysis = null;
  public keywords: TreemapData = [];

  public emotionsLoading = true;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public routingService: RoutingService,
    public analyticsFilterService: AnalyticsFilterService,
    public featureFlaggingService: FeatureFlaggingService,
    public trackersService: TrackersService,
    public analyticsDao: AnalyticsDao,
    public uiService: UIService,
    public entitlementService: EntitlementService,
  ) {
    super(
      router,
      route,
      routingService,
      analyticsDao,
      analyticsFilterService,
      trackersService,
      uiService,
    );
  }

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.obs = this.route.data.subscribe((data) => {
      this.org = data.org;
      this.survey = data.survey;

      this.releases = data.releases;
      this.orgAccounts = data.orgAccounts;

      this.integrations = data.integrations;
      this.registryEntriesGroup = data.registryEntriesGroup.groups;
      this.languages = data.languages_and_countries.surveyLanguagesWithEmojis;
      this.registryEntriesIdentityProperty =
        data.registryEntriesIdentityProperty.filter(
          (entry: RegistryEntry) => entry.type !== "object",
        );
      this.registryEntriesEvent = data.registryEntriesEvent;

      if (!this.entitlementService.isAvailable("content_analysis")) {
        return;
      }

      this.resetFilters();
    });

    if (!this.entitlementService.isAvailable("content_analysis")) {
      document.getElementById("affix-anchor-target").classList.add("locked");
    }
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }
    if (this.filtersObs$) {
      this.filtersObs$.unsubscribe();
    }
  }

  isBasedOnNodes() {
    return false;
  }

  @Debounce(500)
  public onContentAnalysisRequest() {
    this.contentError = null;
    this.keywords = [];

    if (this.filteredResponseCount === 0) {
      this.contentLoading = false;
      return;
    }

    this.contentLoading = true;

    const query = deepCopy(this.lastFilters) as AnalyticsQueryResponse;

    query.size = 0;
    query.aggregation = [
      {
        by: "ai.content-analysis",
      },
    ];

    return this.analyticsDao
      .search(query)
      .then((res: AnalyticsResponse) => {
        this.contentAnalysis = res.aggregations
          ?.contentAnalysis as ContentAnalysis;

        this.keywords = this.contentAnalysis.keywords.map((keyword) => {
          return {
            label: keyword.word,
            score: keyword.score,
          };
        }) as TreemapData;

        this.contentAnalysis.answers = this.contentAnalysis.answers.map(
          (response) => AnalyticsResponseItemResponse.fromJson(response),
        );

        this.contentLoading = false;
      })
      .catch((err: HttpErrorResponse) => {
        //this.summary = null;
        this.contentLoading = false;
        this.contentError = err;
        console.error(err);

        // this.notificationHelper.trigger(
        //   "Error",
        //   "Failed to compute summary. Please try again.",
        //   "error",
        // );
      });
  }

  private async getLoadingTimeMessage() {
    const cursor = estimatedLoadingTimeLadder.find(
      (ladder) => this.answersCount < ladder.treshold,
    );

    this.loadgingTimeMessage = cursor?.message || "a few seconds";
  }

  // private async getMostSignificantWords() {
  //   const query = deepCopy(this.lastFilters) as AnalyticsQueryResponse;
  //   query.aggregation = [
  //     {
  //       by: "by_significant_text.raw",
  //       params: {
  //         significant_text_count: SIGNIFICANT_TEXT_COUNT,
  //       },
  //     },
  //   ];
  //   query.size = 0;

  //   try {
  //     // When we search word frequency into elasticsearch, using the "sampler" aggregator, we may get
  //     // an inaccurate doc_count (number of occurrences in the doc sample).
  //     // Instead, we shound use bg_count, an extrapolated doc count.
  //     let mostSignificantWords = AnalyticsResponse.fromJson(
  //       fakeData.mostSignificantWords
  //     );

  //     if (this.entitlementService.isAvailable("content_analysis")) {
  //       mostSignificantWords = await this.analyticsDao.search(query);

  //       if (!this.entitlementService.isAvailable("content_analysis")) {
  //         return;
  //       }
  //     }

  //     const aggregations = mostSignificantWords.aggregations;
  //     const buckets = Object.values(aggregations).reduce(
  //       (buckets: object[], agg: any): object[] => {
  //         if (!!agg.buckets) {
  //           buckets = buckets.concat(agg.buckets);
  //         }
  //         return buckets;
  //       },
  //       []
  //     );

  //     // order buckets by most significant count
  //     buckets?.sort((a: any, b: any): number => {
  //       return b.doc_count - a.doc_count;
  //     });

  //     // keep only 10 most significant words
  //     buckets.splice(SIGNIFICANT_TEXT_COUNT);

  //     this.treemapData =
  //       buckets?.map((bucket) => {
  //         return {
  //           name: bucket.key,
  //           value: bucket.doc_count,
  //         };
  //       }) || []; // prevents null value
  //   } catch (err) {
  //     this.error = err;
  //     console.error(err);
  //   }
  // }

  // public onTreeMapClick(event: { name: string; value: number }) {
  //   this.analyticsFilterService.switchFilter({
  //     type: "response",
  //     key: "raw",
  //     operator: "contains",
  //     value: event.slug,
  //   });

  //   this.trackersService
  //     .newEventTrackingBuilder("Switched filter")
  //     .withOrg(this.org)
  //     .withSurvey(this.survey)
  //     .withProps({
  //       filter_type: "repsonse",
  //       filter_key: "raw",
  //       filter_operator: "contains",
  //     })
  //     .build();
  // }

  public getQuery() {
    const query = deepCopy(this.lastFilters);

    query.sort = { field: "response.longest_answers", order: "desc" };
    query.size = 5;

    return query;
  }

  public getAnswers(response: AnalyticsResponseItemResponse) {
    return response.answers.sort(
      (a, b): number => b.toText().length - a.toText().length,
    );
  }

  /* public requestFeedback() {
    this.trackersService.screebSurveyStart(
      "cac7f55e-0441-42b6-96b7-26f4e99d6a4f",
    );
  } */

  async getResponseCallback(
    previousPeriod: AnalyticsResponse,
    currentPeriod: AnalyticsResponse,
    allTimePeriod: AnalyticsResponse,
  ) {
    // Get emotions based on filters
    this.emotionsLoading = false;

    const {
      overallPerformancesPerDateCurrentPeriod,
      emotionTrendChartDataset,
      sentimentTrendChartDataset,
      overallPerformancesCurrentPeriod,
    } = await getResponseCallback(previousPeriod, currentPeriod, allTimePeriod);

    this.totalResponsesCount = allTimePeriod?.hits.total;
    this.filteredResponseCount = currentPeriod?.hits.total;

    this.overallPerformancesPerDateCurrentPeriod =
      overallPerformancesPerDateCurrentPeriod;
    this.emotionTrendChartDataset = emotionTrendChartDataset;
    this.sentimentTrendChartDataset = sentimentTrendChartDataset;

    //await this.getAnswersCount();
    await this.getLoadingTimeMessage();
    this.onContentAnalysisRequest();

    this.overallPerformancesCurrentPeriod = overallPerformancesCurrentPeriod;
  }

  protected buildCurrentPeriodQuery(): AnalyticsQueryResponse {
    return getAggregations(deepCopy(this.lastFilters), this.getNbrDateBucket());
  }
}
