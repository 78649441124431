import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {
  DefaultMessageName,
  DefaultSurveyName,
  Survey,
} from "models/survey.model";
import { UIService } from "services/ui.service";
import { BuilderStore } from "stores/builder.store";

@Component({
  selector: "flow-tag",
  templateUrl: "./FlowTag.component.html",
  styleUrls: ["./FlowTag.component.scss"],
})
export class FlowTagComponent implements OnInit, OnChanges {
  constructor(
    public uiService: UIService,
    public builderStore: BuilderStore,
  ) {}

  @ViewChild("nameInput") nameInputElement: ElementRef;

  @Input() isLoading: boolean = false;
  @Input() survey: Survey | null = null;
  @Input() surveyTags: string[] = [];
  @Input() messageURL: string = "";
  @Input() editOnInit: boolean = false;

  @Output() public messageURLChange: EventEmitter<void> = new EventEmitter();
  @Output() public surveyChange: EventEmitter<Survey> = new EventEmitter();

  public isEditMode: boolean = false;
  public isSaving: boolean = false;
  public titleBackup: string = "";
  public tagsBackup: string[] = [];

  public haveTagsDiff: boolean = false;

  public nameError: boolean = false;
  public tagsError: boolean = false;

  public defaultSurveyName = DefaultSurveyName;
  public defaultMessageName = DefaultMessageName;

  ngOnInit(): void {
    this.titleBackup = this.survey?.title;
    this.tagsBackup = this.survey?.tags ?? [];

    if (this.editOnInit && !this.builderStore.readOnly) {
      this.isEditMode = true;

      setTimeout(() => {
        this.nameInputElement.nativeElement.focus();
      }, 0);
    }
  }

  ngOnChanges({ isLoading }: SimpleChanges): void {
    if (!isLoading?.currentValue && isLoading?.previousValue) {
      this.isSaving = false;
      this.titleBackup = this.survey?.title;
      this.isEditMode = false;
      this.editOnInit = false;
      this.tagsBackup = this.survey?.tags ?? [];
      this.haveTagsDiff = false;
    }
  }

  public onEdit() {
    this.isEditMode = true;
  }

  public onSave() {
    this.nameError = false;
    this.tagsError = false;
    const isValidSurveyName = this.isValidSurveyName(this.survey.title);
    const isValidSurveyTags = this.isValidSurveyTags();
    if (!isValidSurveyName) {
      this.nameError = true;
    }

    if (!isValidSurveyTags) {
      this.tagsError = true;
    }

    if (!isValidSurveyName || !isValidSurveyTags) {
      return;
    }
    this.isSaving = true;
    this.surveyChange.emit(this.survey);
  }

  public onEditClose() {
    this.isEditMode = false;
    this.editOnInit = false;
    this.survey.title = this.titleBackup;
  }

  public onTagsChange(tags: string[]) {
    this.survey.tags = tags;
    this.haveTagsDiff =
      JSON.stringify(this.tagsBackup) !== JSON.stringify(tags);
  }

  public onTagsDiscard() {
    this.survey.tags = this.tagsBackup;
    this.haveTagsDiff = false;
  }

  public isValidSurveyTags() {
    return (
      this.survey.tags.length <= 10 &&
      this.survey.tags.every((tag) => tag.length >= 1 && tag.length <= 255)
    );
  }

  public isValidSurveyName(title: string) {
    return title.length >= 3 && title.length <= 255;
  }

  public get label(): string {
    if (this.survey?.title?.length) {
      return this.survey.title;
    }
    return this.survey?.type === "message" ? "Message starts" : "Survey starts";
  }
}
