import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router } from "@angular/router";
import { NotificationHelper } from "helpers/notification.helper";
import {
  ChannelClientScreen,
  ChannelClientVersion,
  ChannelClientVersionMap,
} from "models/channel-screen.model";
import { ChannelDao } from "models/channel.dao";
import { Channel } from "models/channel.model";
import { ApplicationLoadService } from "services/app.service";

@Injectable()
export class ChannelClientScreensResolver implements Resolve<any> {
  constructor(
    private router: Router,
    private applicationLoadService: ApplicationLoadService,
    private channelDao: ChannelDao,
    private notificationHelper: NotificationHelper,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Promise<ChannelClientScreen[]> {
    return this.applicationLoadService
      .loader()
      .then(() => {
        return this.channelDao.getChannelClientScreensByWorkspaceId(
          route.params["org_id"],
        );
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        throw err;
      });
  }
}

@Injectable()
export class ChannelClientVersionsResolver implements Resolve<any> {
  constructor(
    private router: Router,
    private applicationLoadService: ApplicationLoadService,
    private channelDao: ChannelDao,
    private notificationHelper: NotificationHelper,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Promise<ChannelClientVersion[]> {
    return this.applicationLoadService
      .loader()
      .then(() => {
        return this.channelDao.getChannelClientScreenVersionsByWorkspaceId(
          route.params["org_id"],
        );
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        throw err;
      });
  }
}

@Injectable()
export class ChannelsClientVersionsResolver implements Resolve<any> {
  constructor(
    private router: Router,
    private applicationLoadService: ApplicationLoadService,
    private channelDao: ChannelDao,
    private notificationHelper: NotificationHelper,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Promise<ChannelClientVersionMap[]> {
    /* 2 temps:
    1 => recupérer tous les channels de l'orga et les filtrer par type mobile
    2 => récupérer et concatener tout les app versions
     */
    return this.applicationLoadService
      .loader()
      .then(() => {
        return this.channelDao
          .getAllByOrgId(route.params["org_id"])
          .then(async (channels) => {
            const mobileChannels = channels.filter((c: Channel) =>
              ["android", "ios"].includes(c.type),
            );

            const versions = mobileChannels.map((channel) => ({
              channelId: channel.id,
              channelType: channel.type,
              versions:
                this.channelDao.getChannelClientScreenVersionsByWorkspaceId(
                  route.params["org_id"],
                ),
            }));

            const settledVersions: ChannelClientVersionMap[] = [];
            for (const version of versions) {
              settledVersions.push({
                channelId: version.channelId,
                channelType: version.channelType,
                versions: (await version.versions).filter((v) => {
                  return (
                    (version.channelType === "android" &&
                      v.source === "sdk-android") ||
                    (version.channelType === "ios" && v.source === "sdk-ios")
                  );
                }),
              });
            }

            return settledVersions;
          });
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        throw err;
      });
  }
}
