import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";

import { ApplicationLoadService } from "services/app.service";
import { UserRecordDao } from "models/user-record.dao";
import { UserRecord } from "models/user-record.model";

@Injectable()
export class UserRecordResolver implements Resolve<any> {
  constructor(
    private applicationLoadService: ApplicationLoadService,
    private userRecordDao: UserRecordDao,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Promise<UserRecord> {
    return this.applicationLoadService
      .loader()
      .then(() => {
        return this.userRecordDao.getById(
          route.params["org_id"],
          route.params["session_id"],
        );
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        throw err;
      });
  }
}
