import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NotificationHelper } from "helpers/notification.helper";
import { SurveyDao } from "models/survey.dao";
import {
  MessageVideo,
  ScenarioQuestionTypeSurvey,
  SurveyScenario,
} from "models/survey.dao.types";
import { LanguageWithEmoji } from "resolvers/asset-languages-countries";
import { UIService } from "services/ui.service";
import { BuilderStore } from "stores/builder.store";
import { deepCopy } from "utils/object";
import { CheckOption } from "./scenario-translate.types";

@Component({
  selector: "scenario-translate-button",
  templateUrl: "./scenario-translate-button.component.html",
  styleUrls: ["./scenario-translate-button.component.scss"],
})
export class ScenarioTranslateButtonComponent implements OnInit {
  constructor(
    public builderStore: BuilderStore,
    public surveyDao: SurveyDao,
    public uiService: UIService,
    private notificationHelper: NotificationHelper,
  ) {}

  @Input() isLoading = false;
  @Input() isOpen = false;

  @Output() public scenarioTranslated: EventEmitter<void> = new EventEmitter();
  @Output() public isOpenChange: EventEmitter<boolean> = new EventEmitter();

  public translationLoading = false;

  public defaultLanguage: LanguageWithEmoji;
  public allTranslationsChecked = true;
  public allTranslationsIndeterminate = false;
  public translationsOptions: CheckOption[] = [];
  public overrideValidQuestions = false;

  public validForm = false;

  ngOnInit(): void {
    this.translationsOptions = this.builderStore.availableLanguages
      .filter(
        (lang) =>
          lang.value !== this.builderStore.survey.scenario.default_language,
      )
      .map((lang) => ({
        ...lang,
        checked: true,
      }));

    this.defaultLanguage = this.builderStore.availableLanguages.find(
      (lang) =>
        lang.value === this.builderStore.survey.scenario.default_language,
    );

    this.updateValidForm();
  }

  public updateOpen(open: boolean): void {
    this.isOpen = open;
    this.isOpenChange.emit(this.isOpen);
  }

  private updateValidForm(): void {
    const checkedTranslations = this.translationsOptions.filter(
      (item) => item.checked,
    );

    this.validForm = checkedTranslations.length > 0;
  }

  updateAllChecked(): void {
    this.allTranslationsIndeterminate = false;
    if (this.allTranslationsChecked) {
      this.translationsOptions = this.translationsOptions.map((item) => ({
        ...item,
        checked: true,
      }));
    } else {
      this.translationsOptions = this.translationsOptions.map((item) => ({
        ...item,
        checked: false,
      }));
    }

    this.updateValidForm();
  }

  updateChecked(value: string[]): void {
    this.translationsOptions.forEach((item) => {
      if (value.includes(item.value)) {
        item.checked = true;
      } else {
        item.checked = false;
      }
    });

    if (this.translationsOptions.every((item) => !item.checked)) {
      this.allTranslationsChecked = false;
      this.allTranslationsIndeterminate = false;
    } else if (this.translationsOptions.every((item) => item.checked)) {
      this.allTranslationsChecked = true;
      this.allTranslationsIndeterminate = false;
    } else {
      this.allTranslationsIndeterminate = true;
    }

    this.updateValidForm();
  }

  public translateScenario() {
    const languages = this.translationsOptions
      .filter((item) => item.checked)
      .map((item) => item.value);

    const settings = {
      languages,
      overrideValidQuestions: this.overrideValidQuestions,
    };

    this.translationLoading = true;

    // save scenario states with every urls and translations
    const savedScenario = deepCopy(this.builderStore.survey.scenario);

    this.builderStore.pruneNotLinkedNodes();
    this.builderStore.pruneUnselectedNodesMode();
    this.builderStore.pruneVideoNodeUrls();
    this.builderStore.pruneNonDefaultEmptyQuestionDescription();

    this.surveyDao
      .translateScenario(
        this.builderStore.org.id,
        this.builderStore.survey.id,
        this.builderStore.survey.scenario,
        settings.languages,
        settings.overrideValidQuestions,
      )
      .then((data) => {
        this.assignTranslatedScenario(savedScenario, data);
        this.scenarioTranslated.emit();
        this.notificationHelper.trigger(
          "Scenario translated successfully",
          null,
          "success",
        );

        this.updateOpen(false);
      })
      .catch((err) => {
        console.error(err);
        this.notificationHelper.trigger(
          "An error occurred while translating the scenario",
          null,
          "error",
        );
      })
      .finally(() => {
        this.translationLoading = false;
      });
  }

  private assignTranslatedScenario(
    savedScenario: SurveyScenario,
    translatedScenario: SurveyScenario,
  ) {
    // iterate over all nodes of translated scenario, and assign the urls of video messages from saved scenario
    translatedScenario.nodes.forEach((node) => {
      const savedNode = savedScenario.nodes.find((n) => n.id === node.id);
      if (!savedNode) return;

      const savedMessages = (savedNode.question as ScenarioQuestionTypeSurvey)
        .messages;
      const translatedMessages = (node.question as ScenarioQuestionTypeSurvey)
        .messages;

      translatedMessages.forEach((message) => {
        if (message.type !== "video") return;

        const savedMessage = savedMessages.find(
          (m) => m.id === message.id && m.type === "video",
        );
        if (!savedMessage) return;

        // for each language, assign the url of the video message from saved scenario
        Object.keys(message.video).forEach((lang) => {
          message.video[lang].url = (savedMessage as MessageVideo).video[
            lang
          ]?.url;
        });
      });
    });

    // restore scenario states
    this.builderStore.setSurveyScenario(translatedScenario);
  }
}
