import { Injectable } from "@angular/core";

import { AnalyticsQuery } from "./analytics.filters.type";
import { AnalyticsResponse } from "./analytics.model";
import { ResponseDao } from "./response.dao";
import { UserDao } from "./user.dao";
import { UserRecordDao } from "./user-record.dao";

@Injectable()
export class AnalyticsDao {
  constructor(
    private responseDao: ResponseDao,
    private userDao: UserDao,
    private userRecordDao: UserRecordDao,
  ) {}

  public search(
    query: AnalyticsQuery,
    overview: boolean = false,
  ): Promise<AnalyticsResponse> {
    switch (query.type) {
      case "response":
        return this.responseDao.searchResponses(query, overview);
      case "respondent":
        return this.userDao.searchUsers(query, overview);
      case "session":
        return this.userRecordDao.search(query, overview);
    }
  }

  public export(query: AnalyticsQuery): string {
    switch (query.type) {
      case "response":
        return this.responseDao.exportResponses(query);
      case "respondent":
        return this.userDao.exportUsers(query);
      case "session":
        return "TODO";
    }
  }
}
