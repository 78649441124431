<button
  title="{{ surveyType === 'survey' ? 'Add a question' : 'Add a message' }}"
  class="AddButton"
  (click)="onClick.emit($event)"
  [ngClass]="{ disabled }"
  [disabled]="disabled"
>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7 14C7.55228 14 8 13.5523 8 13L8 8L13 8C13.5523 8 14 7.55229 14 7C14 6.44772 13.5523 6 13 6L8 6L8 0.999999C8 0.447715 7.55229 -5.63677e-07 7 -6.11959e-07C6.44772 -6.60242e-07 6 0.447715 6 0.999999L6 6L1 6C0.447716 6 6.60242e-07 6.44771 6.1196e-07 7C5.63677e-07 7.55228 0.447716 8 1 8L6 8L6 13C6 13.5523 6.44772 14 7 14Z"
      fill="white"
    />
  </svg>
</button>
