<ng-container *ngIf="integration">
  <ng-container *ngIf="!integration.auth_ok && mode === '3LO'">
    <p>
      No {{ integrationSpec.name }} account connected.
      <br />
      Please click the button below to connect one.
    </p>

    <a
      *ngIf="!!authorizeURL"
      class="connect"
      [href]="authorizeURL"
      nz-button
      nzType="default"
      nzSize="large"
    >
      <img
        src="/assets/icons/integrations/{{ integrationSlug }}-icon.svg"
        style="height: 20px; width: 20px; margin-right: 12px"
        alt="Connect {{ integrationSpec.name }} account"
      />
      <span>Connect {{ integrationSpec.name }} account</span>
    </a>
  </ng-container>

  <ng-container *ngIf="!integration.auth_ok && mode === '2LO'">
    <p>
      No {{ integrationSpec.name }} credentials verified.
      <br />
      Please click the button below to verify your credentials.
    </p>

    <button
      class="connect"
      nz-button
      nzType="default"
      nzSize="large"
      (click)="checkCredentials()"
    >
      <img
        src="/assets/icons/integrations/{{ iconName }}-icon.svg"
        style="height: 20px; width: 20px; margin-right: 12px"
        alt="Verify {{ integrationSpec.name }} credentials"
      />
      <span>Verify {{ integrationSpec.name }} credentials</span>
    </button>
  </ng-container>

  <ng-container *ngIf="integration.auth_ok">
    <p class="integrationOk">
      <i nz-icon nzType="check-circle" nzTheme="outline"></i>
      <span *ngIf="mode === '3LO'">
        All good! A {{ integrationSpec.name }} account is connected.
        <br />
        {{ confirmMessage }}
      </span>
      <span *ngIf="mode === '2LO'">
        All good! Your {{ integrationSpec.name }} credentials are verified.
        <br />
        {{ confirmMessage }}
      </span>
    </p>

    <ul class="connected-cta">
      <li *ngIf="!!authorizeURL">
        <a [href]="authorizeURL" title="Reconnect" nz-button nzType="default">
          Reconnect
        </a>
      </li>
      <li>
        <button
          title="Disconnect"
          nz-button
          nzType="default"
          nzDanger
          (click)="disconnect()"
        >
          Revoke access
        </button>
      </li>
    </ul>
  </ng-container>
</ng-container>
