import { Component } from "@angular/core";

@Component({
  selector: "test-mode-banner",
  template: `
    <div class="test-mode-banner">
      <span class="test-mode-title">
        <screeb-icon size="sm" icon="warning"></screeb-icon>
        Test mode activated
      </span>
      <span class="test-mode-desc">
        Eligible users will see it over and over until you desactivate the test
        mode or the survey.
        <span>
          <screeb-icon size="sm" icon="info-circle"></screeb-icon>
          <i>
            Add an additional trigger to limit displays only to specific users.
          </i>
        </span>
      </span>
    </div>
  `,
  styles: [
    `
      .test-mode-banner {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 0.25rem;
        background: var(--screeb-color-warning-outline);
        border: 2px solid var(--screeb-color-warning);
        border-radius: 16px;
        width: 100%;
        min-height: 72px;
        padding: 16px 32px;
        animation: shadeAppear 200ms;
        margin-bottom: 1rem;
      }

      .test-mode-title,
      .test-mode-desc {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 150%;
        color: #303140;
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }

      .test-mode-title {
        color: var(--screeb-color-warning-active);

        screeb-icon {
          width: 24px !important;
          height: 24px !important;

          ::ng-deep svg {
            width: 24px !important;
            height: 24px !important;
          }
        }
      }

      .test-mode-desc {
        font-size: 16px;
        font-weight: 400;
        color: var(-screeb-color-body-text-secondary);
        flex-direction: column;
        align-items: flex-start;
        gap: 0.2rem;
        justify-content: center;

        screeb-icon {
          position: relative;
          top: 2px;
          margin-right: 4px;
        }
      }

      @keyframes shadeAppear {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }
    `,
  ],
})
export class TestModeBannerComponent {
  constructor() {}
}
