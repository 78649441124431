<nz-select
  nzShowSearch
  nzHasFeedback
  nzPlaceHolder="Select user event"
  nzMode="multiple"
  [nzDropdownMatchSelectWidth]="false"
  [nzDisabled]="disabled"
  [ngClass]="{ 'selector-user-event': true, invalid: invalid }"
  [ngModel]="eventNameIds"
  (ngModelChange)="eventNameIdsChange.emit($event)"
>
  <nz-option-group
    *ngFor="let source of sortedRegistryEntrySources"
    [nzLabel]="registryEntrySourceFormatted[source].title"
  >
    <nz-option
      *ngFor="let registryEntry of getEventNamesBySource(source)"
      [nzValue]="registryEntry.id"
      [nzLabel]="(registryEntry.favorite ? '⭐️ ' : '') + registryEntry.slug"
    ></nz-option>
    <nz-option
      *ngIf="
        getEventNamesBySource(source).length === 0 &&
        registryEntrySourceFormatted[source].targetingCategoryVisibleOnEmpty
      "
      [nzValue]="'None'"
      [nzLabel]="'None'"
      nzDisabled
    ></nz-option>
  </nz-option-group>
</nz-select>
