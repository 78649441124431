/* eslint-disable @angular-eslint/no-output-on-prefix */
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import {
  availableCTATypes,
  availableQuestionTypeMessage,
  CTAType,
  surveyNodeTypesSettings,
  ScenarioQuestionTypeMessage,
  messageNodeTypesSettings,
  MessageScenarioNodeTypeSettings,
  SurveyScenarioNodeTypeSettings,
} from "models/survey.dao.types";
import { SurveyType } from "models/survey.model";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { BuilderStore } from "stores/builder.store";

interface CTATypeItem {
  type: CTAType | ScenarioQuestionTypeMessage["type"];
  disabled: boolean;
  new: boolean;
  beta: boolean;
  backgroundColor: string;
  textColor: string;
}

@Component({
  selector: "card-menu-popin",
  templateUrl: "./CardMenuPopin.component.html",
  styleUrls: ["./CardMenuPopin.component.scss"],
})
export class CardMenuPopinComponent implements OnInit {
  @Input() origin: ElementRef;
  @Input() isNodeLeaf = false;
  @Input() isNodeRoot = false;
  @Input() surveyType: SurveyType = "survey";

  @Output() onClickOutside = new EventEmitter<null>();
  @Output() onNewNode = new EventEmitter<CTAType>();
  @Output() onGenerateSurvey = new EventEmitter<null>();

  public ctaTypes: CTATypeItem[] = [];
  public nodeTypesSettings: {
    [key: string]:
      | SurveyScenarioNodeTypeSettings
      | MessageScenarioNodeTypeSettings;
  } = surveyNodeTypesSettings;
  public focusedType: CTATypeItem = null;

  constructor(
    public builderStore: BuilderStore,
    public featureFlaggingService: FeatureFlaggingService,
  ) {}

  ngOnInit(): void {
    if (this.surveyType === "message") {
      this.nodeTypesSettings = messageNodeTypesSettings;
    }

    this.ctaTypes = (
      this.surveyType === "survey"
        ? availableCTATypes
        : availableQuestionTypeMessage
    ).map((ctaType) => {
      return {
        type: ctaType,
        disabled: this.isDisabled(ctaType),
        new: this.nodeTypesSettings[ctaType]?.new ?? false,
        beta: this.nodeTypesSettings[ctaType]?.beta ?? false,
        backgroundColor: this.getBackgroundColor(ctaType),
        textColor: this.getTextColor(ctaType),
      };
    });
    this.focusedType = this.ctaTypes[0];
  }

  public onFocusType(ctaTypeItem: CTATypeItem): void {
    this.focusedType = ctaTypeItem;
  }

  private isDisabled(ctaType: CTAType): boolean {
    if (!this.isNodeLeaf) {
      return ["appstore_rating"].includes(ctaType);
    }
    return false;
  }

  private getBackgroundColor(ctaType: CTAType): string {
    const color = this.nodeTypesSettings[ctaType].colors[0];
    if (color.indexOf("--") === 0) {
      return getComputedStyle(document.body).getPropertyValue(color);
    }
    return color;
  }

  private getTextColor(ctaType: CTAType): string {
    const color = this.nodeTypesSettings[ctaType].colors[1];
    if (color.indexOf("--") === 0) {
      return getComputedStyle(document.body).getPropertyValue(color);
    }
    return color;
  }
}
