import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { ChartData } from "chart.js";
import { AnalyticsDao } from "models/analytics.dao";
import { AnalyticsQueryUsers } from "models/analytics.filters.type";
import { Org } from "models/org.model";
import { UUID } from "models/survey.dao.types";
import { UIService } from "services/ui.service";

@Component({
  selector: "widget-identification",
  templateUrl: "./identification.component.html",
  styleUrls: ["./identification.component.scss"],
})
export class WidgetIdentificationComponent implements OnInit, OnChanges {
  @Input() org: Org;
  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() orgHasRespondents: boolean;

  public isLoading: boolean = true;
  public respondentIdentificationData: ChartData<"doughnut"> = { datasets: [] };

  constructor(
    public uiService: UIService,
    private analyticsDao: AnalyticsDao,
  ) {}

  ngOnInit() {
    this.orgHasRespondents = false;
    this.respondentIdentificationData =
      this.getRespondentIdentificationRepartitionData();

    this.reload();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.startDate?.currentValue !== changes.startDate?.previousValue ||
      changes.endDate?.currentValue !== changes.endDate?.previousValue
    ) {
      this.reload();
    }
  }

  async reload() {
    this.isLoading = true;

    try {
      await this.getRespondentIdentificationRepartition();
    } catch (error) {
      console.log("error", error);
    } finally {
      this.isLoading = false;
    }
  }

  async getRespondentIdentificationRepartition() {
    if (!this.orgHasRespondents) {
      this.isLoading = false;
      return;
    }

    this.isLoading = true;

    const totalUsers = await this.getMonthlyActiveRespondents();

    const query: AnalyticsQueryUsers = {
      type: "respondent",
      org_id: UUID(this.org.id),
      survey_ids: ["*"],
      // survey_ids: this.surveys.map(({ id }) => UUID(id)),
      identified_only: true,
      filters_bool: "AND",
      filters: [],
      range: {
        start: this.startDate,
        end: this.endDate,
        field: "last_activity_at",
      },
      size: 0,
    };
    const response = await this.analyticsDao.search(query);

    this.respondentIdentificationData =
      this.getRespondentIdentificationRepartitionData(
        totalUsers,
        response.hits.total,
      );

    this.isLoading = false;
  }

  private getRespondentIdentificationRepartitionData(
    totalUsers?: number,
    identifiedRespondentsTotal?: number,
  ) {
    const style = window.getComputedStyle(document.body);
    const identified = identifiedRespondentsTotal ?? 0;
    const anonymous = totalUsers - identified;
    const isZero = identified === 0 && anonymous === 0;
    return {
      labels: ["Logged in", "Anonymous"],
      datasets: [
        {
          data: isZero ? [identified, anonymous, 100] : [identified, anonymous],
          backgroundColor: isZero
            ? [
                style.getPropertyValue("--screeb-color-green-500"),
                style.getPropertyValue("--screeb-color-blue-500"),
                style.getPropertyValue("--screeb-color-grey-200"),
              ]
            : [
                style.getPropertyValue("--screeb-color-green-500"),
                style.getPropertyValue("--screeb-color-blue-500"),
              ],
          spacing: 0,
          borderColor: "transparent",
          hoverBorderColor: "transparent",
        },
      ],
    };
  }

  private async getMonthlyActiveRespondents() {
    return (
      await this.analyticsDao.search(
        this.getRespondentsCountQuery(
          this.startDate,
          "last_activity_at",
          this.endDate,
        ),
      )
    )?.hits.total;
  }

  private getRespondentsCountQuery(
    start: Date,
    field: "last_activity_at" | "created_at",
    end: Date,
  ): AnalyticsQueryUsers {
    return {
      type: "respondent",
      org_id: UUID(this.org.id),
      survey_ids: ["*"],
      identified_only: false,
      filters_bool: "AND",
      filters: [],
      range: {
        start,
        end,
        field,
      },
      size: 0,
    };
  }
}
