import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-maintenance",
  styles: [
    `
      :host {
        margin: 0;
        padding: 0;
        background: #5e21f1;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      section {
        padding: 20px;
        width: 100%;
        max-width: 600px;
      }
      h1 {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 40px;
        color: #ffffff;
      }
      p,
      li {
        margin-top: 8px;
        font-family: "Rubik";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
      }
      svg {
        width: 100px;
        height: 100px;
        margin-bottom: 18px;
      }
      a,
      a:hover,
      a:active,
      a:visited {
        color: #ffffff;
        text-decoration: underline;
      }
      #eta {
        display: none;
      }
      bold {
        font-weight: bold;
      }
    `,
  ],
  template: `
    <section>
      <svg
        width="383"
        height="366"
        viewBox="0 0 383 366"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_201_23)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M321.7 197.1C324.6 194.1 327.2 191.1 329.7 187.9C360.7 201 382.5 230.9 382.4 266.5V364.7L294.5 365.3H216.7C168.6 365.3 130.5 325.3 130.5 277.1C130.5 261.5 134.5 245.9 141.5 232H240.4C271.2 232 300.1 219.5 321.7 197.1ZM210.2 292.8C219.1 292.8 226.1 285.6 226.1 276.9C226.1 268.4 219 261.2 210.2 261.2C201.5 261.2 194.5 268.4 194.5 276.9C194.5 285.6 201.5 292.8 210.2 292.8ZM255.5 292.8C264.4 292.8 271.4 285.6 271.4 276.9C271.4 268.4 264.4 261.2 255.5 261.2C246.8 261.2 239.8 268.4 239.8 276.9C239.8 285.6 246.8 292.8 255.5 292.8ZM300.9 292.8C309.8 292.8 316.8 285.6 316.8 276.9C316.8 268.4 309.7 261.2 300.9 261.2C292.2 261.2 285.2 268.4 285.2 276.9C285.2 285.6 292.2 292.8 300.9 292.8Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M236.5 213C292 213 335.9 166.6 335.9 111.1C335.9 55.6 292 0 236.4 0H102.7C47.2 0 0 42.7 0 98.3V211.8L102.7 213H236.5ZM108.8 147.6C108.8 151.7 110.9 155.4 113.8 157.5C127.2 167.1 143.1 171.9 158.6 171.7C184.4 171.7 202.5 158.4 202.5 134.6V134.3C202.5 113.4 188.9 104.7 164.6 98.4C143.9 93.1 138.8 90.6 138.8 82.7V82.4C138.8 76.6 144.1 72 154.2 72C162.4 72 170.6 74.9 179.3 80C181.4 81.2 183.4 81.9 186 81.9C192.8 81.9 198.3 76.6 198.3 69.8C198.3 64.7 195.4 61.1 192.5 59.4C181.8 52.6 169.1 48.8 154.6 48.8C130.1 48.8 112.7 63.2 112.7 84.9V85.2C112.7 109 128.3 115.6 152.4 121.8C172.4 126.9 176.5 130.3 176.5 137V137.4C176.5 144.4 170 148.7 159.2 148.7C147.6 148.7 137.7 144.6 128.6 137.9C126.9 136.7 124.5 135.5 121.1 135.5C114.3 135.5 108.8 140.8 108.8 147.6Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_201_23">
            <rect width="382.5" height="365.4" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <h1>Sorry, we're down for maintenance.</h1>
      <p id="no-eta">We'll be back as soon as possible.</p>
      <p id="eta"></p>
      <ul>
        <li>
          You can check our
          <a rel="noopener" target="_blank" href="https://status.screeb.app">
            status page
          </a>
        </li>
        <li>
          or
          <a
            href="javascript:void"
            onclick="window['Intercom']('showNewMessage'); return false;"
          >
            send us a message on our custom support
          </a>
        </li>
      </ul>
    </section>
  `,
})
class MaintenanceComponent implements OnInit {
  ngOnInit(): void {
    const intercomTagAppId = "f9ft79uz";

    (function () {
      const w = window;
      const ic = w["Intercom"];
      if (typeof ic === "function") {
        ic("reattach_activator");
        ic("update", w["intercomSettings"]);
      } else {
        const d = document;
        const i = function () {
          i["c"](arguments); // eslint-disable-line
        };
        i["q"] = [];
        i["c"] = function (args) {
          i["q"].push(args);
        };
        w["Intercom"] = i;
        const l = function () {
          const s = d.createElement("script");
          s.type = "text/javascript";
          s.async = true;
          s.src = "https://widget.intercom.io/widget/" + intercomTagAppId;
          const x = d.getElementsByTagName("script")[0];
          x.parentNode.insertBefore(s, x);
        };
        l();
      }
    })();

    window["Intercom"]("boot", {
      app_id: intercomTagAppId,
    });
  }
}

export { MaintenanceComponent };
