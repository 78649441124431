<ul nz-menu>
  <li id="menu-settings-organization" nz-menu-group nzTitle="Organization">
    <ul>
      <li id="menu-settings-account" nz-menu-item nzMatchRouter>
        <a
          routerLink="/settings/account"
          [queryParams]="{ workspace: uiService.currentOrg?.id }"
          routerLinkActive="active"
        >
          <screeb-icon size="sm" icon="user" />
          Account settings
        </a>
      </li>
      <li id="menu-settings-team-members" nz-menu-item nzMatchRouter>
        <a
          routerLink="/overview/{{ uiService.currentSuperOrgId }}/users"
          [queryParams]="{ workspace_id: uiService.currentOrgId }"
          routerLinkActive="active"
        >
          <screeb-icon size="md" icon="community" />
          Team members
        </a>
      </li>
      <li
        id="menu-settings-billing"
        *ngIf="'billing.list' | hasSuperPermission"
        nz-menu-item
        nzMatchRouter
      >
        <a
          routerLink="/org/{{ uiService.currentOrgId }}/settings/billing"
          routerLinkActive="active"
        >
          <screeb-icon size="sm" icon="top-mrr" />
          Billing
        </a>
      </li>
    </ul>
  </li>
  <li id="menu-settings-workspace" nz-menu-group nzTitle="Workspace">
    <ul>
      <li id="menu-settings-install-screeb" nz-menu-item nzMatchRouter>
        <a
          routerLink="/org/{{ uiService.currentOrgId }}/settings/install"
          routerLinkActive="active"
        >
          <screeb-icon size="sm" icon="dev-documentation" />
          Install Screeb
        </a>
      </li>
      <li
        id="menu-settings-default-settings-design"
        *ngIf="'org.update' | hasSuperPermission"
        nz-menu-item
        nzMatchRouter
      >
        <a
          routerLink="/org/{{ uiService.currentOrgId }}/settings/behaviour"
          routerLinkActive="active"
        >
          <screeb-icon size="sm" icon="widget-settings" />
          Default settings
        </a>
      </li>
      <li
        id="menu-settings-language-preferences"
        *ngIf="
          ('survey_edition' | hasEntitlement) &&
          ('org.update' | hasSuperPermission)
        "
        nz-menu-item
        nzMatchRouter
      >
        <a
          routerLink="/org/{{ uiService.currentOrgId }}/settings/language"
          routerLinkActive="active"
        >
          <screeb-icon size="sm" icon="survey" />
          Language & Translation
        </a>
      </li>
      <li
        id="menu-settings-automatic-tagging"
        *ngIf="'tagging.list' | hasPermission"
        nz-menu-item
        nzMatchRouter
      >
        <a
          routerLink="/org/{{ uiService.currentOrgId }}/settings/tagging"
          routerLinkActive="active"
        >
          <screeb-icon size="sm" icon="save" />
          Tagging
        </a>
      </li>
      <li
        id="menu-settings-survey-capping"
        *ngIf="'targeting-rule.create' | hasPermission"
        nz-menu-item
        nzMatchRouter
      >
        <a
          routerLink="/org/{{ uiService.currentOrgId }}/settings/survey-capping"
          routerLinkActive="active"
        >
          <screeb-icon size="sm" icon="filter" />
          Survey capping
        </a>
      </li>
      <li
        id="menu-settings-session-replay"
        nz-menu-item
        nzMatchRouter
        *ngIf="
          ('org.update' | hasSuperPermission) &&
          ('isSessionRecordingEnabled' | hasFeatureFlag) &&
          ('session_recording' | hasEntitlement)
        "
      >
        <a
          routerLink="/org/{{ uiService.currentOrgId }}/settings/session-replay"
          routerLinkActive="active"
        >
          <screeb-icon size="md" icon="session-start" />
          Session Replay
        </a>
      </li>
      <li
        id="menu-settings-data-governance"
        nz-menu-item
        nzMatchRouter
        *ngIf="
          ('org.update' | hasSuperPermission) &&
          ('isDataGovernancePageEnabled' | hasFeatureFlag)
        "
      >
        <a
          routerLink="/org/{{
            uiService.currentOrgId
          }}/settings/data-governance"
          routerLinkActive="active"
        >
          <screeb-icon size="sm" icon="customize" />
          Data governance
        </a>
      </li>
    </ul>
  </li>
  <li
    id="menu-settings-design"
    nz-menu-group
    nzTitle="Design settings"
    *ngIf="
      ('survey_edition' | hasEntitlement) && ('org.update' | hasSuperPermission)
    "
  >
    <ul>
      <ng-container>
        <ng-container
          *ngFor="let distributionInteraction of distributionInteractions"
        >
          <li
            id="menu-settings-default-settings-design-{{
              distributionInteraction
            }}"
            nz-menu-item
            nzMatchRouter
          >
            <a
              routerLink="/org/{{
                uiService.currentOrgId
              }}/settings/customize/survey/{{ distributionInteraction }}"
              routerLinkActive="active"
            >
              <screeb-icon
                *ngIf="distributionInteraction === 'in_app'"
                size="md"
                icon="desktop"
              ></screeb-icon>
              <screeb-icon
                *ngIf="distributionInteraction === 'in_page'"
                size="md"
                icon="overview"
              ></screeb-icon>
              <screeb-icon
                *ngIf="distributionInteraction === 'email'"
                size="md"
                icon="email"
              ></screeb-icon>
              <screeb-icon
                *ngIf="distributionInteraction === 'feedback_button'"
                size="md"
                icon="bye"
              ></screeb-icon>
              <screeb-icon
                *ngIf="distributionInteraction === 'link'"
                size="md"
                icon="link"
              ></screeb-icon>
              {{ distributionInteractionsFormatted[distributionInteraction] }}
            </a>
          </li>
        </ng-container>
      </ng-container>
      <li
        id="menu-settings-default-settings-design-message"
        nz-menu-item
        nzMatchRouter
      >
        <a
          routerLink="/org/{{
            uiService.currentOrgId
          }}/settings/customize/message"
          routerLinkActive="active"
        >
          <screeb-icon size="sm" icon="comment"></screeb-icon>
          Messages
        </a>
      </li>
    </ul>
  </li>
  <li id="menu-settings-sdk" nz-menu-group nzTitle="SDKs">
    <ul>
      <li
        id="menu-settings-user-identity-properties"
        nz-menu-item
        nzMatchRouter
      >
        <a
          routerLink="/org/{{
            uiService.currentOrgId
          }}/settings/user-identity-properties"
          routerLinkActive="active"
        >
          <screeb-icon size="sm" icon="active-user" />
          User properties
        </a>
      </li>
      <li id="menu-settings-user-events" nz-menu-item nzMatchRouter>
        <a
          routerLink="/org/{{ uiService.currentOrgId }}/settings/user-events"
          routerLinkActive="active"
        >
          <screeb-icon size="md" icon="events" />
          User events
        </a>
      </li>
      <li
        *ngIf="'survey_inapp_mobile' | hasEntitlement"
        id="menu-settings-mobile-app-data"
        nz-menu-item
        nzMatchRouter
      >
        <a
          routerLink="/org/{{ uiService.currentOrgId }}/settings/mobile-screens"
          routerLinkActive="active"
        >
          <screeb-icon size="md" icon="mobile" />
          Mobile Client Screens
        </a>
      </li>
      <li
        *ngIf="'survey_inapp_mobile' | hasEntitlement"
        id="menu-settings-sdk-changelogs"
        nz-menu-item
        nzMatchRouter
      >
        <a
          routerLink="/org/{{ uiService.currentOrgId }}/settings/sdk-changelogs"
          routerLinkActive="active"
        >
          <screeb-icon size="sm" icon="notification" />
          SDK changelogs
        </a>
      </li>
    </ul>
  </li>
</ul>
