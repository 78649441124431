/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/no-output-rename */

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { integrationUpdate } from "components/integration/settings/integration-settings.component";
import { NotificationHelper } from "helpers/notification.helper";
import { hookIntegrationsSpec } from "models/hook.model";
import { IntegrationDao } from "models/integration.dao";

import {
  Integration,
  IntegrationSettings,
  IntegrationSettingsWebhook,
  IntegrationSettingsWebhookItem,
} from "models/integrations.model";
import { Org } from "models/org.model";
import { Survey } from "models/survey.model";
import { TrackingEventName } from "services/trackers.events";
import { TrackersService } from "services/trackers.service";

@Component({
  selector: "integration-settings-webhook",
  templateUrl: "./webhook-settings.component.html",
  styleUrls: ["./webhook-settings.component.scss"],
})
export class IntegrationSettingsWebhookComponent implements OnInit {
  @Input() public org: Org = null;
  @Input() public survey: Survey = null;
  @Input() public integration: Integration = null;
  @Input() public createIfEmpty: boolean = true;
  @Input() public hideListOnEmpty: boolean = false;
  @Input() public scope: "org" | "survey" = "org";

  @Output("onSettingsChange") public settings =
    new EventEmitter<integrationUpdate>();

  private currentlyExpandedItem: number = -1;
  private newItemId: string = null;

  constructor(
    private trackersService: TrackersService,
    private notificationHelper: NotificationHelper,
    private integrationDao: IntegrationDao,
  ) {}

  ngOnInit() {
    if (
      this.createIfEmpty &&
      this.integration.settings?.webhook?.items?.length === 0
    )
      this.onItemAdd();
  }

  public pruneNewItems() {
    this.integration.settings.webhook.items =
      this.integration.settings.webhook.items.filter(
        (item) => item.id !== this.newItemId,
      );
  }

  public onItemAdd() {
    if (!this.integration.settings)
      this.integration.settings = new IntegrationSettings();
    if (!this.integration.settings.webhook)
      this.integration.settings.webhook = IntegrationSettingsWebhook.New();

    this.pruneNewItems();

    const newItem = IntegrationSettingsWebhookItem.New(
      hookIntegrationsSpec.webhook.currentVersion,
    );
    this.integration.settings.webhook.items.push(newItem);
    this.integration.settings.webhook.items = [].concat(
      ...this.integration.settings.webhook.items,
    ); // triggers @Input() refresh

    this.expandItem(this.integration.settings.webhook.items.length - 1);

    this.newItemId = newItem.id; // mark item as new, in order to remove it if we cancel edition
  }

  public onItemSaved(index: number, item: IntegrationSettingsWebhookItem) {
    const createMode =
      this.integration.settings.webhook.items[index].id === this.newItemId;
    if (createMode) {
      this.newItemId = null;
    }

    // We don't override 'enabled' param here
    this.integration.settings.webhook.items[index].id = item.id;
    this.integration.settings.webhook.items[index].name = item.name;
    this.integration.settings.webhook.items[index].path = item.path;
    this.integration.settings.webhook.items[index].version = item.version;
    this.integration.settings.webhook.items[index].hooks = item.hooks;

    this.propagateItemChange();
    this.noExpand();

    this.trackHookChange(
      createMode
        ? "Integration webhook hook added"
        : "Integration webhook hook updated",
      createMode
        ? "Integration webhook survey hook added"
        : "Integration webhook survey hook updated",
      item,
    );
  }

  public onItemStatusChange(index: number) {
    this.trackHookChange(
      this.integration.settings.webhook.items[index].enabled
        ? "Integration webhook hook disabled"
        : "Integration webhook hook enabled",
      this.integration.settings.webhook.items[index].enabled
        ? "Integration webhook survey hook disabled"
        : "Integration webhook survey hook enabled",
      this.integration.settings.webhook.items[index],
    );

    this.integration.settings.webhook.items[index].enabled =
      !this.integration.settings.webhook.items[index].enabled;
    this.propagateItemChange();
  }

  public onItemCanceled() {
    this.noExpand();
  }

  public onItemRemoved(index: number) {
    this.noExpand();

    this.integration.settings.webhook.items.splice(index, 1);
    this.integration.settings.webhook.items = [].concat(
      ...this.integration.settings.webhook.items,
    ); // triggers @Input() refresh

    this.propagateItemChange();

    this.trackHookChange(
      "Integration webhook hook removed",
      "Integration webhook survey hook removed",
      null,
    );
  }

  private propagateItemChange() {
    this.pruneNewItems();
    this.settings.emit({ settings: this.integration.settings });
  }

  /**
   * Collapsible
   */
  public isItemExpanded(i: number): boolean {
    return this.currentlyExpandedItem === i;
  }

  public expandItem(i: number) {
    if (this.currentlyExpandedItem !== -1) this.pruneNewItems();

    this.currentlyExpandedItem = this.isItemExpanded(i) ? -1 : i;
  }

  public noExpand() {
    this.currentlyExpandedItem = -1;
    this.pruneNewItems();
  }

  /**
   * Token
   */
  public onCopy() {
    this.track("Integration token copied");
  }

  public onReset() {
    this.integrationDao
      .resetIntegrationToken(this.org.id, this.integration.type)
      .then((r: Integration) => {
        this.track("Integration token reset");
        this.integration.token = r.token;
        this.notificationHelper.trigger("Token Reset!", null, "success");
      })
      .catch((err) => {
        console.error(err);
        this.notificationHelper.trigger(
          "Error While resetting Token",
          null,
          "error",
        );
      });
  }

  /**
   * Tracking
   */
  private trackHookChange(
    eventNameOrg: TrackingEventName,
    eventNameSurvey: TrackingEventName,
    item?: IntegrationSettingsWebhookItem,
  ) {
    const eventName = this.scope === "org" ? eventNameOrg : eventNameSurvey;

    this.track(eventName, {
      hook_item_id: item?.id,
      hook_item_version: item?.version,
      hook_item_enabled: item?.enabled,
      hook_item_name: item?.name,
      hook_item_path: item?.path,
      hook_item_hooks: item?.hooks,
    });
  }

  private track(eventName: TrackingEventName, props?: object) {
    this.trackersService
      .newEventTrackingBuilder(eventName)
      .withOrg(this.org)
      .withIntegration(this.integration) // if `integration` is null, no property will be added
      .withSurvey(this.survey) // if `survey` is null, no property will be added
      .withProps(props ?? {})
      .build();
  }
}
