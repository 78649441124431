<div class="account-mfa">
  <div class="mfa-title-container">
    <!-- <span class="mfa-name">{{ accountMFAToLabel[mfa.mfa] }}</span> -->
  </div>

  <span *ngIf="!isEnabled" class="mfa-status">Disabled</span>
  <span *ngIf="isEnabled" class="mfa-status">Enabled</span>

  <div class="mfa-actions-container">
    <button
      *ngIf="!isEnabled"
      class="link-btn"
      nz-button
      [disabled]="error"
      [nzLoading]="loading"
      nzType="primary"
      nzSize="large"
      (click)="onEnable()"
    >
      Enable
    </button>

    <button
      *ngIf="isEnabled"
      class="unlink-btn"
      nz-button
      nzDanger
      [disabled]="enforced || error"
      [nzLoading]="loading"
      nz-popover
      [nzPopoverTrigger]="enforced ? 'hover' : null"
      nzPopoverTitle="Enforced MFA"
      [nzPopoverContent]="EnforcedMFATemplate"
      nzPopoverPlacement="leftTop"
      nzType="default"
      nzSize="large"
      (click)="onDisable()"
    >
      Disable
    </button>
  </div>

  <ng-template #EnforcedMFATemplate>
    <p>2FA has been enforced for everyone in Organization.</p>
  </ng-template>
</div>
