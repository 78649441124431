/* eslint-disable @angular-eslint/component-class-suffix */
import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from "@angular/core";

@Component({
  selector: "big-checkbox",
  template: `
    <div
      class="big-checkbox"
      [ngClass]="{ 'has-content': !!content }"
      [ngClass]="{ checked: checked, disabled: this.disabled }"
    >
      <label
        nz-checkbox
        [ngClass]="{
          round: roundCheckbox,
          small: checkboxSize === 'small',
          big: checkboxSize === 'big',
        }"
        [disabled]="disabled"
        [ngModel]="checked"
        (ngModelChange)="checkedChange.emit($event); checked = $event"
      >
        <span>{{ label }}</span>
        <img *ngIf="iconUrl" class="label-icon" [src]="iconUrl" alt="" />
        <screeb-icon
          *ngIf="iconName"
          class="label-icon"
          [icon]="iconName"
          [size]="iconSize"
        ></screeb-icon>
      </label>
      <div
        *ngIf="(collapseWhenNotChecked || checked) && !!content"
        class="content"
      >
        <ng-container [ngTemplateOutlet]="content"></ng-container>
      </div>
    </div>
  `,
  styleUrls: ["./big-checkbox.component.scss"],
})
export class BigCheckbox {
  @Input() public label: string;
  @Input() public content?: string | TemplateRef<void> | null;
  @Input() public checked = false;
  @Input() public disabled = false;
  @Input() public iconUrl: string = null;
  @Input() public iconName: string = null;
  @Input() public iconSize: string = "sm";
  @Input() public roundCheckbox: boolean = false;
  @Input() public checkboxSize: "small" | "big" = "small";
  @Input() public collapseWhenNotChecked: boolean = true;

  @Output() public checkedChange: EventEmitter<boolean> = new EventEmitter();
}
