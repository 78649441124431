<section>
  <div *ngIf="integration">
    <form nz-form [nzAutoTips]="autoTips" [formGroup]="validateForm">
      <h2>Host</h2>
      <p>
        For
        <b>Segment Business plans</b>
        with access to Regional Segment, you can use the host configuration
        parameter to send data to the desired region, or to the default region.
      </p>
      <nz-form-item>
        <nz-form-control>
          <nz-select formControlName="server" id="server">
            <nz-option
              nzValue="https://api.segment.io/v1"
              nzLabel="Oregon (Default)"
            ></nz-option>
            <nz-option
              nzValue="https://events.eu1.segmentapis.com"
              nzLabel="Dublin"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <h2>Write key</h2>
      <nz-form-item>
        <nz-form-control>
          <nz-input-group>
            <input
              type="text"
              nz-input
              nzHasFeedback
              formControlName="token"
              placeholder="Write key"
            />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
</section>
