<div
  class="survey-status-container survey-status-container--{{
    haveOneWithTestMode && ['running', 'programmed'].includes(commonStatus)
      ? 'warning'
      : {
          paused: 'disabled',
          programmed: 'info',
          running: 'enabled',
          stopped: 'disabled',
        }[commonStatus]
  }} {{ !('survey_edition' | hasEntitlement) ? 'locked' : '' }}"
  [nz-tooltip]="haveOneWithTestMode ? testModeTooltip : null"
  *ngIf="distributions.length > 0"
  (click)="$event.stopPropagation(); $event.preventDefault(); handleOpen()"
>
  <div class="status-icon">
    <screeb-icon
      *ngIf="commonStatus === 'paused'"
      size="sm"
      icon="pause"
    ></screeb-icon>
    <screeb-icon
      *ngIf="commonStatus === 'programmed'"
      size="md"
      icon="hourglass"
    ></screeb-icon>
    <screeb-icon
      *ngIf="commonStatus === 'running'"
      size="sm"
      icon="play"
    ></screeb-icon>
    <screeb-icon
      *ngIf="commonStatus === 'stopped'"
      size="sm"
      icon="stopped"
    ></screeb-icon>
  </div>
  <div class="channel-list">
    <span>{{ commonStatusLabel }}</span>
  </div>
  <div class="channel-pictos" *ngIf="runningDistributions.length">
    <ng-container *ngFor="let distribution of runningDistributions">
      <screeb-icon
        *ngIf="distribution.interaction === 'in_app'"
        size="md"
        icon="desktop"
      ></screeb-icon>
      <screeb-icon
        *ngIf="distribution.interaction === 'in_page'"
        size="md"
        icon="overview"
      ></screeb-icon>
      <screeb-icon
        *ngIf="distribution.interaction === 'email'"
        size="md"
        icon="email"
      ></screeb-icon>
      <screeb-icon
        *ngIf="distribution.interaction === 'feedback_button'"
        size="md"
        icon="bye"
      ></screeb-icon>
      <screeb-icon
        *ngIf="distribution.interaction === 'link'"
        size="md"
        icon="link"
      ></screeb-icon>
      <screeb-icon
        *ngIf="!distribution.interaction && distribution.type === 'android'"
        size="sm"
        icon="android"
      ></screeb-icon>
      <screeb-icon
        *ngIf="!distribution.interaction && distribution.type === 'ios'"
        size="sm"
        icon="apple"
      ></screeb-icon>
      <screeb-icon
        *ngIf="!distribution.interaction && distribution.type === 'hosted-page'"
        size="sm"
        icon="link"
      ></screeb-icon>
      <screeb-icon
        *ngIf="!distribution.interaction && distribution.type === 'widget'"
        size="md"
        icon="desktop"
      ></screeb-icon>
    </ng-container>
  </div>
</div>

<ng-template #testModeTooltip>
  <div class="test-mode-tooltip">
    <span>⚠️ Test mode activated on one or more channels</span>
  </div>
</ng-template>

<nz-modal
  [(nzVisible)]="isModalVisible"
  (nzOnCancel)="handleClose()"
  (nzOnOk)="handleClose()"
  [nzFooter]="null"
>
  <ng-container *nzModalContent>
    <h3>{{ title }}</h3>
    <p>Manage the status of your channels</p>
    <div class="distributions-status">
      <ng-container
        *ngFor="
          let distribution of distributions;
          let i = index;
          trackByProperty: 'id'
        "
      >
        <ng-container>
          <p
            class="distribution-legacy"
            *ngIf="
              i > 0 &&
              !distributions[i].interaction &&
              distributions[i - 1].interaction
            "
          >
            <screeb-icon size="md" icon="hourglass"></screeb-icon>
            Legacy channels
            <screeb-icon
              size="sm"
              icon="info-circle"
              nz-popover
              nzType="primary"
              nzPopoverPlacement="bottomLeft"
              [nzPopoverContent]="contentTemplate"
            ></screeb-icon>
            <ng-template #contentTemplate>
              These methods are being deprecated.
              <br />
              Please transition to our new "In-App" channel that merge Web &
              Mobile for better performance and engagement.
              <br />
              Or the new "Link" channel to replace the legacy Hosted Page.
            </ng-template>
          </p>
          <div class="distribution-status">
            <screeb-icon
              *ngIf="distribution.interaction === 'in_app'"
              size="md"
              icon="desktop"
            ></screeb-icon>
            <screeb-icon
              *ngIf="distribution.interaction === 'in_page'"
              size="md"
              icon="overview"
            ></screeb-icon>
            <screeb-icon
              *ngIf="distribution.interaction === 'email'"
              size="md"
              icon="email"
            ></screeb-icon>
            <screeb-icon
              *ngIf="distribution.interaction === 'feedback_button'"
              size="md"
              icon="bye"
            ></screeb-icon>
            <screeb-icon
              *ngIf="distribution.interaction === 'link'"
              size="md"
              icon="link"
            ></screeb-icon>
            <screeb-icon
              *ngIf="
                !distribution.interaction && distribution.type === 'android'
              "
              size="sm"
              icon="android"
            ></screeb-icon>
            <screeb-icon
              *ngIf="!distribution.interaction && distribution.type === 'ios'"
              size="sm"
              icon="apple"
            ></screeb-icon>
            <screeb-icon
              *ngIf="
                !distribution.interaction && distribution.type === 'hosted-page'
              "
              size="sm"
              icon="link"
            ></screeb-icon>
            <screeb-icon
              *ngIf="
                !distribution.interaction && distribution.type === 'widget'
              "
              size="md"
              icon="desktop"
            ></screeb-icon>
            <span class="distribution-status__title">
              {{ distribution.title }}
            </span>

            <span class="spacer"></span>
            <span
              *ngIf="distribution.isTestMode"
              class="distribution-status__badge distribution-status__badge--partial"
            >
              Test mode
            </span>
            <span
              *ngIf="!isDistribAvailableInEntitlements(distribution)"
              (click)="goToBilling()"
              class="distribution-status__badge distribution-status__badge--disabled"
            >
              Upgrade
            </span>
            <span
              *ngIf="isDistribAvailableInEntitlements(distribution)"
              class="distribution-status__badge distribution-status__badge--{{
                {
                  running: 'enabled',
                  paused: 'disabled',
                  programmed: 'info',
                  stopped: 'disabled',
                }[distribution.status]
              }}"
              (click)="
                onSurveyStatusChange(
                  $event,
                  distribution,
                  !(distribution.status === 'running')
                )
              "
            >
              <i
                *ngIf="distributionStatusLoading[distribution.id]"
                nz-icon
                nzType="loading"
              ></i>
              <screeb-icon
                *ngIf="
                  !distributionStatusLoading[distribution.id] &&
                  distribution.status === 'paused'
                "
                size="sm"
                icon="pause"
              ></screeb-icon>
              <screeb-icon
                *ngIf="
                  !distributionStatusLoading[distribution.id] &&
                  distribution.status === 'programmed'
                "
                size="md"
                icon="hourglass"
              ></screeb-icon>
              <screeb-icon
                *ngIf="
                  !distributionStatusLoading[distribution.id] &&
                  distribution.status === 'running'
                "
                size="sm"
                icon="play"
              ></screeb-icon>
              <screeb-icon
                *ngIf="
                  !distributionStatusLoading[distribution.id] &&
                  distribution.status === 'stopped'
                "
                size="sm"
                icon="stopped"
              ></screeb-icon>
              {{ distribution.statusLabel }}
            </span>
            <a
              *ngIf="'survey.update' | hasPermission"
              [routerLink]="[
                '/org',
                uiService.currentOrgId,
                uiService.surveyTypePath,
                distribution.survey_id,
                'share',
                distribution.id,
                'diffusion',
              ]"
              title="Edit"
              nz-button
              class="button-edit"
              nzSize="small"
              nzType="primary"
              nzShape="round"
              (click)="$event.preventDefault(); $event.stopPropagation()"
            >
              <img alt="Edit" nz-icon src="/assets/icons/homepage/pen.svg" />
            </a>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</nz-modal>
