import { Component } from "@angular/core";
import { Router } from "@angular/router";

import { AuthService, SessionService } from "services/auth.service";
import { NotificationHelper } from "helpers/notification.helper";
import { UIService } from "services/ui.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { PermissionsService } from "services/permissions.service";
import { EntitlementService } from "services/entitlement.service";
import {
  DistributionInteractions,
  DistributionInteractionsFormatted,
} from "models/survey-distribution.model";

@Component({
  selector: "layout-back-sidebar-settings",
  templateUrl: "./sidebar-settings.component.html",
  styleUrls: ["./sidebar-settings.component.scss"],
})
export class LayoutBackSidebarSettingsComponent {
  constructor(
    public router: Router,
    public authService: AuthService,
    public sessionService: SessionService,
    public notificationHelper: NotificationHelper,
    public uiService: UIService,
    public featureFlaggingService: FeatureFlaggingService,
    public entitlementService: EntitlementService,
    public permissionsService: PermissionsService,
  ) {}

  public distributionInteractions = DistributionInteractions;
  public distributionInteractionsFormatted = DistributionInteractionsFormatted;
}
