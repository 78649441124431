<div nz-row class="page-container">
  <div class="description">
    Discover in 30 seconds how Screeb can help
    <p>
      <b>
        {{
          toPlural(accountRoleToLabel[sessionService.session.flags.job_title])
        }}
      </b>
    </p>
    to
    <p>
      <b>{{ accountGoalToLabel[guide].toLowerCase() }}</b>
    </p>
  </div>
  <div class="interactive-guide">
    <div class="loading-container" *ngIf="!interactiveGuide || !guideStarted">
      <span
        nz-icon
        nzType="loading"
        class="circle-loader"
        nzTheme="outline"
      ></span>
    </div>
    <iframe
      [src]="guideUrl"
      loading="lazy"
      webkitallowfullscreen
      mozallowfullscreen
      allowfullscreen
      [style]="{
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        'color-scheme': 'light',
        border: 'none',
        borderRadius: 'inherit',
        visibility: !!interactiveGuide && guideStarted ? '' : 'hidden',
        transition: 'visibility ease-in-out 500ms',
      }"
      title="{{ interactiveGuide.title }}"
    ></iframe>
  </div>
  <button
    class="cta-btn"
    title="Let's start"
    nz-button
    nzType="primary"
    nzSize="large"
    (click)="onDone()"
  >
    <screeb-icon size="sm" icon="launch" />
    <span>Let's start</span>
  </button>
</div>
