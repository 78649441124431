<div>
  If contains any of the following keywords
  <nz-select
    class="my-keywords"
    nzPlaceHolder="keywords"
    nzSize="large"
    nzMode="tags"
    [nzDropdownMatchSelectWidth]="false"
    [nzDisabled]="!('org.update' | hasSuperPermission)"
    [(ngModel)]="groupTag.keywords"
  ></nz-select>
  add all the following tags
  <nz-select
    class="my-tags"
    nzPlaceHolder="tags"
    nzSize="large"
    nzMode="tags"
    [nzDropdownMatchSelectWidth]="false"
    [nzDisabled]="!('tagging.update' | hasPermission)"
    [(ngModel)]="groupTag.tags"
  >
    <nz-option
      *ngFor="let tag of allOrgTags"
      [nzLabel]="tag"
      [nzValue]="tag"
    ></nz-option>
  </nz-select>

  <button
    nz-button
    nz-tooltip="Remove"
    nzType="text"
    nzShape="circle"
    [ngClass]="{ invisible: ('tagging.update' | hasPermission) }"
    (click)="onEventRemove()"
  >
    <i nz-icon nzType="close"></i>
  </button>
</div>
