<div class="selector-css" *ngIf="interaction === 'in_page'">
  <css-selector
    class="row"
    [channelID]="distribution?.channel_id"
    [selectorCSS]="selectorCSS"
    (selectorCSSChange)="selectorCSSChange.emit($event)"
  />
</div>

<nz-table
  #ruleTable
  [nzData]="rules"
  [nzShowPagination]="false"
  [nzPageSize]="1000"
  nzNoResult="No rules defined"
  *ngIf="ready"
>
  <thead>
    <tr>
      <th>Trigger</th>
      <th *ngIf="canDisplayOperatorColumn">Operator</th>
      <th>Value</th>
      <th [nzWidth]="'140px'" nzRight>
        <button
          *ngIf="'survey_edition' | hasEntitlement"
          class="ant-btn-secondary"
          nz-button
          nzType="default"
          (click)="onAddRule()"
        >
          Add a trigger
        </button>
      </th>
    </tr>
  </thead>

  <tbody>
    <ng-container
      *ngFor="let rule of ruleTable.data; let i = index"
      class="type-{{ rule.type }}"
    >
      <tr
        *ngIf="displayRule(rule)"
        [ngClass]="{
          'rule-disabled':
            !!rule.sequence_id || !('survey_edition' | hasEntitlement),
        }"
      >
        <td class="column-type">
          <div class="column-type-inner">
            <i
              *ngIf="rule.sequence_id"
              nz-icon
              nzType="question-circle"
              nzTheme="outline"
              [nzTooltipTitle]="titleTemplate"
              nzTooltipPlacement="right"
              nz-tooltip
            ></i>
            <ng-template #titleTemplate let-thing>
              These conditions are predefined based on your funnel settings.
              <br />
              You can add more if you want to further refine the audience that
              will see this survey.
            </ng-template>
            <div *ngIf="notRemovableRulesTypes.includes(rule.type)">
              {{ targetingRulesTypesAndLabels[rule.type].title }}
            </div>

            <div
              class="full-width"
              *ngIf="!notRemovableRulesTypes.includes(rule.type)"
            >
              <nz-select
                class="rule-type-selector"
                [disabled]="
                  !!rule.sequence_id || !('survey_edition' | hasEntitlement)
                "
                nzShowSearch
                [ngModel]="rule.type"
                (ngModelChange)="onRuleTypeChange(rule, $event)"
                [nzOptions]="ruleTypeSelectorsOptions[i]"
                [nzDropdownMatchSelectWidth]="false"
                nzPlaceHolder="Select rule type"
              ></nz-select>
            </div>

            <nz-tag
              *ngIf="targetingRulesTypesAndLabels[rule.type]?.mobileSdkOnly"
              class="rule-sdk"
            >
              <i
                nz-icon
                nzType="question-circle"
                nzTheme="outline"
                nzTooltipTitle="This rule will only be effective on the mobile SDK."
                nzTooltipPlacement="right"
                nz-tooltip
              ></i>
              Mobile SDK
            </nz-tag>
            <nz-tag
              *ngIf="targetingRulesTypesAndLabels[rule.type]?.webSdkOnly"
              class="rule-sdk"
            >
              <i
                nz-icon
                nzType="question-circle"
                nzTheme="outline"
                nzTooltipTitle="This rule will only be effective on the web SDK."
                nzTooltipPlacement="right"
                nz-tooltip
              ></i>
              Web SDK
            </nz-tag>
          </div>
        </td>

        <td class="column-operator" *ngIf="canDisplayOperatorColumn">
          <div
            *ngIf="
              [
                'visitor_property',
                'visitor_event_count',
                'visitor_event_time',
                'device_visitor_property',
                'device_visitor_event_count',
                'device_visitor_event_time',
              ].includes(rule.type)
            "
            class="column-operator-content"
          >
            <div
              *ngIf="
                rule.type === 'visitor_property' &&
                registryEntriesIdentityPropertyScreeb.length > 0
              "
              class="column-operator-visitor-rules"
            >
              <selector-user-properties
                [disabled]="
                  !!rule.sequence_id || !('survey_edition' | hasEntitlement)
                "
                [registryEntriesIdentityProperty]="
                  registryEntriesIdentityPropertyScreeb
                "
                [registryEntriesIdentityPropertyPaths]="
                  registryEntriesIdentityPropertyPathsById
                "
                [propertyKeyId]="rule.value.key_id"
                (propertyKeyIdChange)="
                  rule.serializeUserPropertyRuleKey(
                    registryEntriesIdentityPropertyById.get($event)
                  )
                "
                ngDefaultControl
                [invalid]="
                  rule.getRegistryEntrySlug(
                    registryEntriesIdentityPropertyById.get(rule.value.key_id)
                  ) === ''
                "
              ></selector-user-properties>
              <rule-operator
                *ngIf="
                  !!rule.value.key_id && visibleRuleOperator.includes(rule.type)
                "
                [disabled]="
                  !!rule.sequence_id || !('survey_edition' | hasEntitlement)
                "
                [rule]="rule"
                [registryEntriesIdentityPropertyById]="
                  registryEntriesIdentityPropertyById
                "
                (ruleChange)="rules[i] = $event"
              ></rule-operator>
            </div>
            <div
              *ngIf="
                rule.type === 'device_visitor_property' &&
                registryEntriesIdentityPropertyDevice.length > 0
              "
              class="column-operator-visitor-rules"
            >
              <selector-user-properties
                [disabled]="
                  !!rule.sequence_id || !('survey_edition' | hasEntitlement)
                "
                [registryEntriesIdentityProperty]="
                  registryEntriesIdentityPropertyDevice
                "
                [registryEntriesIdentityPropertyPaths]="
                  registryEntriesIdentityPropertyPathsById
                "
                [propertyKeyId]="rule.value.key_id"
                (propertyKeyIdChange)="
                  rule.serializeUserPropertyRuleKey(
                    registryEntriesIdentityPropertyById.get($event)
                  )
                "
                ngDefaultControl
                [invalid]="
                  rule.getRegistryEntrySlug(
                    registryEntriesIdentityPropertyById.get(rule.value.key_id)
                  ) === ''
                "
              ></selector-user-properties>
              <rule-operator
                *ngIf="
                  !!rule.value.key_id && visibleRuleOperator.includes(rule.type)
                "
                [disabled]="
                  !!rule.sequence_id || !('survey_edition' | hasEntitlement)
                "
                [rule]="rule"
                [registryEntriesIdentityPropertyById]="
                  registryEntriesIdentityPropertyById
                "
                (ruleChange)="rules[i] = $event"
              ></rule-operator>
            </div>

            <div
              *ngIf="
                rule.type === 'visitor_event_time' &&
                registryEntriesEventScreeb.length > 0
              "
              class="column-operator-visitor-rules"
            >
              <selector-user-events
                [disabled]="
                  !!rule.sequence_id || !('survey_edition' | hasEntitlement)
                "
                [registryEntriesEvent]="registryEntriesEventScreeb"
                [eventNameIds]="rule.value.name_ids"
                (eventNameIdsChange)="rule.setNameIds($event)"
                ngDefaultControl
                [invalid]="!areEventNamesValid(rule.value.name_ids)"
              ></selector-user-events>
              <nz-select
                [disabled]="
                  !!rule.sequence_id || !('survey_edition' | hasEntitlement)
                "
                *ngIf="!!rule.value.name_ids"
                class="rule-event-first-last"
                [(ngModel)]="rule.value.v_s"
              >
                <nz-option nzValue="latest" nzLabel="Last trigger"></nz-option>
                <nz-option nzValue="oldest" nzLabel="First trigger"></nz-option>
              </nz-select>
              <rule-operator
                [disabled]="
                  !!rule.sequence_id || !('survey_edition' | hasEntitlement)
                "
                *ngIf="
                  !!rule.value.name_ids &&
                  visibleRuleOperator.includes(rule.type)
                "
                [rule]="rule"
                (ruleChange)="rules[i] = $event"
              ></rule-operator>
            </div>

            <div
              *ngIf="
                rule.type === 'device_visitor_event_time' &&
                registryEntriesEventDevice.length > 0
              "
              class="column-operator-visitor-rules"
            >
              <selector-user-events
                [disabled]="
                  !!rule.sequence_id || !('survey_edition' | hasEntitlement)
                "
                [registryEntriesEvent]="registryEntriesEventDevice"
                [eventNameIds]="rule.value.name_ids"
                (eventNameIdsChange)="rule.setNameIds($event)"
                ngDefaultControl
                [invalid]="!areEventNamesValid(rule.value.name_ids)"
              ></selector-user-events>
              <nz-select
                [disabled]="
                  !!rule.sequence_id || !('survey_edition' | hasEntitlement)
                "
                *ngIf="!!rule.value.name_ids"
                class="rule-event-first-last"
                [(ngModel)]="rule.value.v_s"
              >
                <nz-option nzValue="latest" nzLabel="Last trigger"></nz-option>
                <nz-option nzValue="oldest" nzLabel="First trigger"></nz-option>
              </nz-select>
              <rule-operator
                [disabled]="
                  !!rule.sequence_id || !('survey_edition' | hasEntitlement)
                "
                *ngIf="
                  !!rule.value.name_ids &&
                  visibleRuleOperator.includes(rule.type)
                "
                [rule]="rule"
                (ruleChange)="rules[i] = $event"
              ></rule-operator>
            </div>

            <div
              *ngIf="
                rule.type === 'visitor_event_count' &&
                registryEntriesEventScreeb.length > 0
              "
              class="column-operator-visitor-rules"
            >
              <selector-user-events
                [disabled]="
                  !!rule.sequence_id || !('survey_edition' | hasEntitlement)
                "
                [registryEntriesEvent]="registryEntriesEventScreeb"
                [eventNameIds]="rule.value.name_ids"
                (eventNameIdsChange)="rule.setNameIds($event)"
                ngDefaultControl
                [invalid]="!areEventNamesValid(rule.value.name_ids)"
              ></selector-user-events>
              <rule-operator
                [disabled]="
                  !!rule.sequence_id || !('survey_edition' | hasEntitlement)
                "
                *ngIf="
                  !!rule.value.name_ids &&
                  visibleRuleOperator.includes(rule.type)
                "
                [rule]="rule"
                (ruleChange)="rules[i] = $event"
              ></rule-operator>
            </div>

            <div
              *ngIf="
                rule.type === 'device_visitor_event_count' &&
                registryEntriesEventDevice.length > 0
              "
              class="column-operator-visitor-rules"
            >
              <selector-user-events
                [disabled]="
                  !!rule.sequence_id || !('survey_edition' | hasEntitlement)
                "
                [registryEntriesEvent]="registryEntriesEventDevice"
                [eventNameIds]="rule.value.name_ids"
                (eventNameIdsChange)="rule.setNameIds($event)"
                ngDefaultControl
                [invalid]="!areEventNamesValid(rule.value.name_ids)"
              ></selector-user-events>
              <rule-operator
                [disabled]="
                  !!rule.sequence_id || !('survey_edition' | hasEntitlement)
                "
                *ngIf="
                  !!rule.value.name_ids &&
                  visibleRuleOperator.includes(rule.type)
                "
                [rule]="rule"
                (ruleChange)="rules[i] = $event"
              ></rule-operator>
            </div>
          </div>

          <ng-container
            *ngIf="
              ![
                'visitor_property',
                'visitor_event_count',
                'visitor_event_time',
                'device_visitor_property',
                'device_visitor_event_count',
                'device_visitor_event_time',
              ].includes(rule.type)
            "
          >
            <rule-operator
              [disabled]="
                !!rule.sequence_id || !('survey_edition' | hasEntitlement)
              "
              *ngIf="visibleRuleOperator.includes(rule.type)"
              [rule]="rule"
              (ruleChange)="rules[i] = $event"
            ></rule-operator>
          </ng-container>
        </td>

        <td class="column-value">
          <ng-container [ngSwitch]="rule.type">
            <div class="full-width" *ngSwitchCase="'language'">
              <rule-language
                [rule]="rule"
                [languages]="languages"
              ></rule-language>
            </div>

            <div class="full-width" *ngSwitchCase="'country'">
              <rule-country
                [rule]="rule"
                [countries]="countries"
              ></rule-country>
            </div>

            <div class="full-width" *ngSwitchCase="'device'">
              <rule-device [rule]="rule"></rule-device>
            </div>

            <div class="full-width" *ngSwitchCase="'sdk_platform'">
              <rule-sdk-platform [rule]="rule"></rule-sdk-platform>
            </div>

            <div class="full-width" *ngSwitchCase="'url'">
              <rule-url [rule]="rule"></rule-url>
            </div>

            <div class="full-width" *ngSwitchCase="'delay'">
              <rule-delay [rule]="rule"></rule-delay>
            </div>

            <div class="full-width" *ngSwitchCase="'session_duration'">
              <rule-session-duration [rule]="rule"></rule-session-duration>
            </div>

            <div class="full-width" *ngSwitchCase="'scroll'">
              <rule-scroll [rule]="rule"></rule-scroll>
            </div>

            <div class="full-width" *ngSwitchCase="'scroll_reach_element'">
              <rule-scroll-reach-element
                [rule]="rule"
              ></rule-scroll-reach-element>
            </div>

            <div class="full-width" *ngSwitchCase="'dom_element'">
              <rule-dom-element [rule]="rule"></rule-dom-element>
            </div>

            <div class="full-width" *ngSwitchCase="'recurrence'">
              <rule-recurrence
                [ruleRecurrence]="getRuleRecurrence()"
                [ruleIfAnswered]="getRuleRecurrenceNotAnswered()"
              ></rule-recurrence>
            </div>

            <div class="full-width" *ngSwitchCase="'start_date'">
              <rule-date [rule]="rule"></rule-date>
            </div>
            <div class="full-width" *ngSwitchCase="'end_date'">
              <rule-date [rule]="rule"></rule-date>
            </div>

            <!-- <div *ngSwitchDefault>
              <input type="text" nz-input placeholder="{{ rule.getValuePlaceholder() }}" [(ngModel)]="rule.value">
            </div> -->

            <div class="full-width" *ngSwitchCase="'visitor_event_count'">
              <rule-user-event-count
                [rule]="rule"
                [registryEntriesEvent]="registryEntriesEventScreeb"
              ></rule-user-event-count>
            </div>

            <div class="full-width" *ngSwitchCase="'visitor_event_time'">
              <rule-user-event-time
                [rule]="rule"
                [registryEntriesEvent]="registryEntriesEventScreeb"
              ></rule-user-event-time>
            </div>

            <div class="full-width" *ngSwitchCase="'visitor_event_sequence'">
              <rule-user-event-sequence
                [rule]="rule"
                [registryEntriesEvent]="registryEntriesEventScreeb"
              ></rule-user-event-sequence>
            </div>

            <div class="full-width" *ngSwitchCase="'screen'">
              <rule-screen
                [rule]="rule"
                [channelClientScreens]="channelClientScreens"
              ></rule-screen>
            </div>

            <div class="full-width" *ngSwitchCase="'app_version'">
              <rule-app-version
                [rule]="rule"
                [channelClientVersions]="channelClientVersions"
              ></rule-app-version>
            </div>

            <div class="full-width" *ngSwitchCase="'visitor_property'">
              <rule-user-property
                [rule]="rule"
                [registryEntriesIdentityProperty]="
                  registryEntriesIdentityPropertyScreeb
                "
                [registryEntriesIdentityPropertyById]="
                  registryEntriesIdentityPropertyById
                "
              ></rule-user-property>
            </div>

            <div class="full-width" *ngSwitchCase="'visitor_group'">
              <rule-user-segment
                [rule]="rule"
                [registryEntriesGroup]="registryEntriesGroup"
              ></rule-user-segment>
            </div>

            <div class="full-width" *ngSwitchCase="'visitor_answered_survey'">
              <rule-user-answered-survey
                [rule]="rule"
                [surveys]="surveys"
              ></rule-user-answered-survey>
            </div>

            <div class="full-width" *ngSwitchCase="'visitor_alias'">
              <rule-user-alias [org]="org" [rule]="rule"></rule-user-alias>
            </div>

            <div class="full-width" *ngSwitchCase="'device_visitor_property'">
              <rule-user-property
                [rule]="rule"
                [registryEntriesIdentityProperty]="
                  registryEntriesIdentityPropertyDevice
                "
                [registryEntriesIdentityPropertyById]="
                  registryEntriesIdentityPropertyById
                "
              ></rule-user-property>
            </div>

            <div
              class="full-width"
              *ngSwitchCase="'device_visitor_event_count'"
            >
              <rule-user-event-count
                [rule]="rule"
                [registryEntriesEvent]="registryEntriesEventDevice"
              ></rule-user-event-count>
            </div>

            <div class="full-width" *ngSwitchCase="'device_visitor_event_time'">
              <rule-user-event-time
                [rule]="rule"
                [registryEntriesEvent]="registryEntriesEventDevice"
              ></rule-user-event-time>
            </div>

            <div
              class="full-width"
              *ngSwitchCase="'device_visitor_event_sequence'"
            >
              <rule-user-event-sequence
                [rule]="rule"
                [registryEntriesEvent]="registryEntriesEventDevice"
              ></rule-user-event-sequence>
            </div>
          </ng-container>
        </td>

        <td zAlign="right" nzRight class="column-actions">
          <a
            *ngIf="
              !notRemovableRulesTypes.includes(rule.type) &&
                !rule.sequence_id &&
                'survey_edition' | hasEntitlement
            "
            (click)="removeRule(i)"
          >
            <i nz-icon nzType="close"></i>
          </a>
        </td>
      </tr>
    </ng-container>
  </tbody>
</nz-table>

<div class="clearfix"></div>
