<div
  class="Checkpoint IsStarting is-{{ builderStore.survey?.type ?? 'survey' }}"
>
  <div class="FlowTagContainer is-{{ builderStore.survey?.type ?? 'survey' }}">
    <flow-tag
      [survey]="builderStore.survey"
      [surveyTags]="surveyTags"
      [messageURL]="messageURL"
      [isLoading]="isLoading"
      [editOnInit]="openOnInit"
      (messageURLChange)="messageURLChange.emit()"
      (surveyChange)="surveyChange.emit($event)"
    ></flow-tag>
    <div
      class="scenario-history"
      *ngIf="
        !builderStore.readOnly &&
        uiService.currentSurvey?.scenario?.version !== 0
      "
    >
      <scenario-history
        [survey]="builderStore.survey"
        (scenarioHistoryChange)="scenarioHistoryChange.emit($event)"
      ></scenario-history>
    </div>
    <div class="lang-select" *ngIf="!builderStore.readOnly">
      <language-select
        nzSize="large"
        name="defaultLanguage"
        ngDefaultControl
        [defaultLanguageWarning]="defaultLanguageWarning"
        [ngModel]="builderStore.currentLanguage"
        (ngModelChange)="
          builderStore.currentLanguage = $event;
          currentLanguageChange.emit($event)
        "
        [languages]="builderStore.availableLanguages"
        [defaultLanguage]="builderStore.survey.scenario.default_language"
        [canSetAsDefault]="true"
        [showInfo]="true"
        (defaultLanguageChange)="defaultLanguageChange.emit($event)"
      ></language-select>
    </div>

    <scenario-translate-button
      *ngIf="builderStore.survey?.type === 'survey' && !builderStore.readOnly"
      [isLoading]="isLoading"
      [(isOpen)]="translationPanelOpened"
      (scenarioTranslated)="scenarioTranslated.emit()"
    ></scenario-translate-button>

    <div
      *ngIf="defaultLanguageWarning && !currentLanguageWarning"
      class="default-lang-warning"
    >
      Missing translation in the default language
      <i nz-icon nzType="warning"></i>
    </div>
  </div>
</div>
