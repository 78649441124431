import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { emojiTranscoder } from "components/builder/components/Cards/sanitized-message/emojis";
import { AnalyticsResponseItemUser } from "models/analytics.model";
import { Org } from "models/org.model";
import { RegistryEntry } from "models/registry.model";
import { UserRecord, UserRecordSnapshot } from "models/user-record.model";
import rrwebPlayer from "rrweb-player";

@Component({
  selector: "user-record-preview",
  templateUrl: "./user-record-preview.component.html",
  styleUrls: ["./user-record-preview.component.scss"],
})
export class UserRecordPreviewComponent
  implements AfterViewInit, OnChanges, OnDestroy
{
  @Input() public loading: boolean = false;
  @Input() public session: UserRecord | null;
  @Input() public org: Org;
  @Input() public user: AnalyticsResponseItemUser | null;
  @Input() public isUserLoading: boolean;
  @Input() public registryEntriesGroup: RegistryEntry[] = [];

  @ViewChild("rrwebPlayerWrapper", { static: false, read: ElementRef })
  rrwebPlayerWrapper;

  public player: rrwebPlayer = null;
  public initialized = false;

  public emojiTranscoder = emojiTranscoder;

  private rObs: ResizeObserver;

  constructor() {}

  ngAfterViewInit() {
    this.init();
  }

  ngOnChanges({ session }: SimpleChanges) {
    if (!this.rrwebPlayerWrapper?.nativeElement) {
      return;
    }

    if (session && session.currentValue !== session.previousValue) {
      this.init();
    }
  }

  ngOnDestroy() {
    this.rObs?.disconnect();
  }

  public init(): void {
    if (!this.session) {
      return;
    }

    this.initialized = false;
    const snapshots =
      this.session.snapshots?.map((s: UserRecordSnapshot) => {
        const data = s.unpackData();
        return {
          type: s.type,
          source: s.source,
          data: data,
          timestamp: new Date(s.timestamp).getTime(),
          delay: s.delay,
        };
      }) ?? [];

    snapshots.sort((a, b) => Number(a.timestamp) - Number(b.timestamp));

    const wrapper = this.rrwebPlayerWrapper.nativeElement as HTMLElement;
    const maxSize = Math.max(wrapper.clientWidth, wrapper.clientHeight);

    this.player = new rrwebPlayer({
      target: this.rrwebPlayerWrapper.nativeElement,
      props: {
        events: snapshots,
        width: maxSize,
        height: maxSize,
        showController: false,
        autoPlay: false,
        useVirtualDom: true,
        mouseTail: {
          strokeStyle: "#5e21f1",
        },
      },
    });

    this.rObs = new ResizeObserver(() => this.onResize());
    this.rObs.observe(wrapper);

    // Let player initialize
    setTimeout(() => {
      this.initialized = true;
    }, 250);
  }

  // Ok this is a bit hacky but it works
  // as only triggerResize is not working
  public onResize() {
    setTimeout(() => {
      const replayer = this.player?.getReplayer();
      const root = replayer?.config.root as HTMLElement;
      if (!root) {
        return;
      }
      const wrapper = this.rrwebPlayerWrapper.nativeElement as HTMLElement;
      const maxSize = Math.max(wrapper.clientWidth, wrapper.clientHeight);
      root.style.width = maxSize + "px";
      root.style.height = maxSize + "px";
      root.parentElement.style.width = root.style.width;
      // @ts-ignore - not in the type
      this.player.$$set({
        width: maxSize,
        height: maxSize,
      });
      this.player.triggerResize();
    }, 0);
  }

  public getComment(session: UserRecord, id: string) {
    return session.comments.find((h) => h.id === id);
  }
}
