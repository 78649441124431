import { Component } from "@angular/core";

@Component({
  selector: "page-not-found",
  templateUrl: "./error-page.component.html",
  styleUrls: ["./error-page.component.scss"],
})
class NotFoundPageComponent {
  public subtitle: string = "404 - Page not found";
  public text: string =
    "The page you are looking for might have been removed had its name changed or is temporarily unavailable.";

  constructor() {}
}

export { NotFoundPageComponent };
