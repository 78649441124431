@let exportUrl =
  getCsvExportURL(forceMode === "filters", lastFilters, defaultFilters);
<a
  *ngIf="forceMode !== null"
  nz-button
  nzType="default"
  nzSize="large"
  download="export.csv"
  [href]="exportUrl"
  (click)="onExportClick()"
>
  <i nz-icon nzType="export" nzTheme="outline"></i>
  Export
</a>
<button
  *ngIf="forceMode === null"
  nz-button
  nzType="default"
  nzSize="large"
  nzTrigger="click"
  nz-dropdown
  [nzDropdownMenu]="dropdown"
  nzPlacement="bottomRight"
>
  <i nz-icon nzType="export" nzTheme="outline"></i>
  Export
</button>
<nz-dropdown-menu
  nzOverlayClassName="export-button-more"
  #dropdown="nzDropdownMenu"
>
  <ul class="export-button-more" nz-menu>
    <li nz-menu-item>
      @let exportAllUrl = getCsvExportURL(false, lastFilters, defaultFilters);
      <a
        [href]="exportAllUrl"
        download="export.csv"
        (click)="onExportAllClick()"
      >
        <screeb-icon size="sm" icon="export" />
        Export all
      </a>
    </li>
    <li nz-menu-item>
      @let exportWithFiltersUrl =
        getCsvExportURL(true, lastFilters, defaultFilters);
      <a
        [href]="exportWithFiltersUrl"
        download="export.csv"
        (click)="onExportWithFiltersClick()"
      >
        <screeb-icon size="sm" icon="filter" />
        Export with filters
      </a>
    </li>
  </ul>
</nz-dropdown-menu>
