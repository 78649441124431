import { Component, Input } from "@angular/core";
import { SequenceFunnelStepDropCondition } from "models/sequence.types";

@Component({
  selector: "funnel-repartition",
  template: `
    <div class="funnel-repartition-main">
      <div
        class="funnel-repartition-main-progress"
        [style]="{ width: getCurrentPercent() + '%' }"
      ></div>
      <div class="funnel-event-name">{{ eventName }}</div>
      <div class="funnel-event-count">
        {{ currentValue | format: "number" }}
        <span *ngIf="currentValue !== total">
          &nbsp;|&nbsp;
          {{ getCurrentPercent() | format: "percent" }} of total
        </span>
      </div>
      <drop-if-select
        *ngIf="dropCondition?.type === 'delay'"
        [readOnly]="true"
        [(value)]="dropCondition.delay"
      ></drop-if-select>
    </div>
    <div class="funnel-repartition-indicators" *ngIf="nextValue !== undefined">
      <svg
        preserveAspectRatio="none"
        width="100"
        height="80"
        viewBox="0 0 100 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path [attr.d]="getRepartitionPath()" fill="#D6F9F0" />
      </svg>

      <div
        *ngIf="!isNaN(getNextPercent())"
        class="funnel-repartition-indicator funnel-success"
      >
        {{ getNextPercent() | format: "percent" }}
      </div>
      <div
        *ngIf="!isNaN(getNextPercent())"
        class="funnel-repartition-indicator funnel-drop"
      >
        {{ 100 - getNextPercent() | format: "percent" }}
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        width: 100%;
        height: 256px;
        display: flex;
        flex-direction: column;
        background: url("/assets/background/background-hashed-red.svg");
      }

      :host .funnel-repartition-main {
        display: flex;
        padding: 0 40px;
        flex-direction: column;
        justify-content: center;
        position: relative;
        width: 100%;
        flex: 1;
      }

      :host .funnel-repartition-main-progress {
        position: absolute;
        left: 0;
        top: 0;
        background: var(--screeb-color-success-outline);
        height: 100%;
      }

      :host .funnel-event-name {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 150%;
      }

      :host .funnel-event-count {
        margin-top: 4px;
        font-family: "Rubik";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
      }

      :host drop-if-select {
        margin-top: 8px;
      }

      :host .funnel-event-name,
      :host .funnel-event-count,
      :host drop-if-select {
        position: relative;
        z-index: 1;
        color: var(--screeb-color-black);
      }

      :host .funnel-repartition-indicators {
        position: relative;
        display: flex;
        width: 100%;
        height: 80px;
        align-items: center;
        justify-content: space-between;
        padding: 0 40px;
      }

      :host .funnel-repartition-indicators svg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }

      :host .funnel-repartition-indicator {
        z-index: 1;
        position: relative;
        display: flex;
        background: var(--screeb-color-background);
        border-radius: 8px;
        font-family: "Rubik";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 150%;
        display: flex;
        align-items: center;
        padding: 1px 8px 0px 8px;
      }

      :host .funnel-success {
        color: var(--screeb-color-success-active);
      }

      :host .funnel-drop {
        color: var(--screeb-color-error);
      }
    `,
  ],
})
export class FunnelRepartitionComponent {
  @Input() public eventName = "";
  @Input() public total = 0;
  @Input() public currentValue = 0;
  @Input() public nextValue: number;
  @Input() public dropCondition: SequenceFunnelStepDropCondition = null;

  public isNaN = isNaN;

  getCurrentPercent() {
    return (this.currentValue * 100) / this.total;
  }

  getNextPercent() {
    return (this.nextValue * 100) / this.currentValue;
  }

  getTotalPercent() {
    return (this.nextValue * 100) / this.total;
  }

  getRepartitionPath() {
    return `M0 0H${this.getCurrentPercent()}L${this.getTotalPercent()} 80H0V0Z`;
  }
}
