import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";

import { ApplicationLoadService } from "services/app.service";
import { TagEditorToken } from "models/tag-editor-token.types";
import { TagEditorTokenDao } from "models/tag-editor-token.dao";

@Injectable()
export class TagEditorTokenResolver implements Resolve<TagEditorToken> {
  constructor(
    private applicationLoadService: ApplicationLoadService,
    private tagEditorTokenDao: TagEditorTokenDao,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Promise<TagEditorToken> {
    return this.applicationLoadService
      .loader()
      .then(() =>
        this.tagEditorTokenDao.getWorkspaceIdAndChannelIdAndSurveyId(
          route.params["org_id"],
          route.params["survey_id"],
          route.params["channel_id"],
        ),
      )
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        throw err;
      });
  }
}
