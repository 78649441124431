<div class="lateral-panel" cdkScrollable>
  <div class="lateral-panel-inner" cdkScrollable>
    <!-- header -->
    <header class="lateral-header">
      <div class="left">
        <button (click)="closePanel.emit($event)" class="close">
          <i nz-icon nzType="close" nzTheme="outline"></i>
        </button>
        <h2 class="title">Create a funnel</h2>
      </div>
      <div class="right"></div>
    </header>

    <div class="lateral-body">
      <div class="lateral-body-content">
        <h3>Name of your funnel</h3>
        <input
          nz-input
          type="text"
          nzSize="large"
          placeholder="Ex: Onboarding Flow"
          [(ngModel)]="funnelTitle"
        />

        <div class="error" *ngIf="!isTitleValid()">
          You must choose a title for your funnel.
        </div>

        <h3>Choose events for each step of your funnel</h3>
        <div class="sub-label">
          Don't worry, you'll be able to reorder them later!
        </div>

        <checkbox-select
          [items]="availableEvents"
          [(selectedItems)]="selectedEvents"
          placeholder="Search events by name"
        ></checkbox-select>

        <div class="events-tags-container">
          <div class="events-tags-label">
            <span *ngIf="!selectedEvents.length">No event selected</span>
            <span *ngIf="selectedEvents.length">
              {{ selectedEvents.length }} event(s) selected
            </span>
          </div>
          <tags [maxTagsCount]="5" [tags]="getSelectedEventsLabels()"></tags>
        </div>

        <div class="error" *ngIf="!isSelectedEventsValid()">
          You must choose at least two events.
        </div>

        <div class="error" *ngIf="submitError">
          Something went wrong. Please retry.
        </div>

        <!-- bottom buttons -->
        <footer class="panel-button-container">
          <div class="left group"></div>
          <div class="right group">
            <button
              nz-button
              nzType="default"
              nzSize="large"
              (click)="closePanel.emit($event)"
            >
              Cancel
            </button>
            <button
              nz-button
              nzType="primary"
              nzSize="large"
              [disabled]="!isValid()"
              [nzLoading]="submitLoading"
              (click)="submit()"
            >
              Next
            </button>
          </div>
        </footer>
      </div>
    </div>
  </div>
</div>

<!-- transparent overlay -->
<div class="lateral-panel-backdrop" (click)="closePanel.emit($event)"></div>
