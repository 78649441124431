import { Component, Input } from "@angular/core";

@Component({
  selector: "tags",
  templateUrl: "./tags.component.html",
  styleUrls: ["./tags.component.scss"],
})
export class TagsComponent {
  @Input() public tags: string[] = [];
  @Input() public maxTagsCount = 3;
  @Input() public color: string;
  @Input() public textColor: string;
  @Input() public size: "small" | "default" = "default";
}
