import { Component, Input } from "@angular/core";
import { ShareReportType } from "models/survey.model";

@Component({
  selector: "share-reporting",
  templateUrl: "./share-reporting.component.html",
  styleUrls: ["./share-reporting.component.scss"],
})
export class ShareReportingComponent {
  @Input() reportType: ShareReportType = null;

  public lateralPanelOpened = false;

  constructor() {}

  openPanel() {
    this.lateralPanelOpened = true;
  }

  closePanel() {
    this.lateralPanelOpened = false;
  }
}
