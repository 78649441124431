import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";

import { NzSelectOptionInterface } from "ng-zorro-antd/select";

import {
  ChannelClientScreen,
  ChannelClientVersion,
} from "models/channel-screen.model";
import { Org } from "models/org.model";
import { RegistryEntry, getRegistryEntriesPath } from "models/registry.model";
import {
  TargetingRule,
  TargetingRuleInfo,
  TargetingRuleOperator,
  TargetingRuleType,
  TargetingRuleValue,
  cappingTargetingRuleTypes,
  deviceTrackingTargetingRuleTypes,
  orgLevelTargetingRuleTypesExceptCappingRules,
  TargetingRuleTypes,
  targetingRulesTypesAndLabels,
  targetingRulesTypesToOperator,
  userPropertiesTypesToOperator,
} from "models/targeting-rule.model";
import { Survey } from "models/survey.model";
import { EntitlementService } from "services/entitlement.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { arrayToMap } from "utils/array";
import {
  DistributionInteraction,
  SurveyDistribution,
} from "models/survey-distribution.model";

@Component({
  selector: "survey-share-targeting-advanced",
  templateUrl: "./advanced.component.html",
  styleUrls: ["./advanced.component.scss"],
})
export class AdvancedTargetingShareSurveyComponent
  implements OnInit, OnChanges
{
  @Input() public isOrgTargeting?: boolean = false;
  @Input() public isRecordTargeting?: boolean = false;
  @Input() public org: Org = null;
  @Input() public distribution: SurveyDistribution = null;
  @Input() public surveyId?: string = null;
  @Input() public rules: TargetingRule[] = [];
  @Input() public registryEntriesIdentityProperty: RegistryEntry[] = [];
  @Input() public registryEntriesEvent: RegistryEntry[] = [];
  @Input() public registryEntriesGroup: RegistryEntry[] = [];
  @Input() public surveys: Survey[] = [];
  @Input() public channelClientScreens: ChannelClientScreen[] = [];
  @Input() public channelClientVersions: ChannelClientVersion[] = [];
  @Input() public languages: string[][] = [];
  @Input() public countries: string[][] = [];
  @Input() public interaction: DistributionInteraction;
  @Input() public selectorCSS: string = null;
  @Output() public selectorCSSChange = new EventEmitter<string>();

  @Output() public rulesChange = new EventEmitter<TargetingRule[]>();

  public targetingRulesTypesToOperator = targetingRulesTypesToOperator;
  public userPropertiesTypesToOperator = userPropertiesTypesToOperator;
  public targetingRulesTypesAndLabels = targetingRulesTypesAndLabels;

  public mainLanguages: string[][] = [];
  public mainCountries: string[][] = [];

  // removable rule ?
  public notRemovableRulesTypes: TargetingRuleType[];
  public ruleTypeSelectorsOptions: NzSelectOptionInterface[][];
  // can i see the operator ?
  public visibleRuleOperator: TargetingRuleType[];

  public ready = false;
  public canDisplayOperatorColumn = true;

  public registryEntriesIdentityPropertyPathsById: Map<string, string[]> =
    new Map();
  public registryEntriesIdentityPropertyById: Map<string, RegistryEntry> =
    new Map(); // no property of type object
  public registryEntriesIdentityPropertyScreeb: RegistryEntry[] = []; // no property of type object
  public registryEntriesIdentityPropertyDevice: RegistryEntry[] = []; // no property of type object

  public registryEntriesEventById: Map<string, RegistryEntry> = new Map();
  public registryEntriesEventScreeb: RegistryEntry[] = [];
  public registryEntriesEventDevice: RegistryEntry[] = [];

  constructor(
    public featureFlaggingService: FeatureFlaggingService,
    public entitlementService: EntitlementService,
  ) {}

  ngOnInit() {
    this.setup();
    this.ready = true;
  }

  ngOnChanges({ distribution, interaction }: SimpleChanges) {
    if (
      distribution?.currentValue?.id !== distribution?.previousValue?.id ||
      interaction?.currentValue !== interaction?.previousValue
    ) {
      this.setup();
    }
  }

  private setup() {
    this.notRemovableRulesTypes = Object.values(targetingRulesTypesAndLabels)
      .filter((info) => !info.removable)
      .map((info) => info.type);

    this.visibleRuleOperator = Object.values(targetingRulesTypesAndLabels)
      .filter((info) => info.visibleOperator)
      .map((info) => info.type);

    this.registryEntriesIdentityPropertyPathsById = getRegistryEntriesPath(
      this.registryEntriesIdentityProperty,
    ); // done before removing properties of type "object"

    const registryEntriesIdentityProperty =
      this.registryEntriesIdentityProperty;
    this.registryEntriesIdentityPropertyById = arrayToMap(
      registryEntriesIdentityProperty,
      "id",
    );
    this.registryEntriesIdentityPropertyScreeb =
      registryEntriesIdentityProperty.filter(
        ({ location }) => location === "screeb",
      );
    this.registryEntriesIdentityPropertyDevice =
      registryEntriesIdentityProperty.filter(
        ({ location }) => location === "device",
      );

    this.registryEntriesEventById = arrayToMap(this.registryEntriesEvent, "id");
    this.registryEntriesEventScreeb = this.registryEntriesEvent.filter(
      ({ location }) => location === "screeb",
    );
    this.registryEntriesEventDevice = this.registryEntriesEvent.filter(
      ({ location }) => location === "device",
    );
    this.recurrentRuleTypesAreInterconnected();
    this.refreshRuleTypeSelectors();
    this.checkCanDisplayOperatorColumn();
  }

  public recurrentRuleTypesAreInterconnected() {
    const recurrenceRule = this.getRuleRecurrence();
    const recurrenceNotAnsweredRule = this.getRuleRecurrenceNotAnswered();

    if (!recurrenceRule && !recurrenceNotAnsweredRule) {
      return;
    }

    // Kind of recursive stuff, but it is safe.
    // Also, the multiple rule will be changed to TRUE in the recursive call.
    if (!recurrenceRule) {
      this.onAddRule("recurrence");
    } else if (!recurrenceNotAnsweredRule) {
      this.onAddRule("recurrence_not_answered");
    }
  }

  // duplicated code
  public onAddRule(
    type?: TargetingRuleType,
    operator?: TargetingRuleOperator,
    value?: TargetingRuleValue,
  ) {
    const r = new TargetingRule();
    r.survey_distribution_id = this.isOrgTargeting
      ? null
      : this.distribution?.id; // can be null
    r.org_id = this.isOrgTargeting ? this.org.id : null; // can be null
    r.type = type;
    r.operator = operator;
    r.value = value ?? r.getDefaultValue();

    this.rules = [r, ...this.rules];
    this.rulesChange.emit(this.rules);

    this.recurrentRuleTypesAreInterconnected();
    this.refreshRuleTypeSelectors();
    this.checkCanDisplayOperatorColumn();
  }

  public onRuleTypeChange(rule: TargetingRule, type: TargetingRuleType) {
    // if old type was recurrence and there is no recurrence_not_answered rule, force set multiple rule to false
    // Or if old type was recurrence_not_answered and there is no recurrence rule, force set multiple rule to false
    if (rule.type === "recurrence" || rule.type === "recurrence_not_answered") {
      this.forceSetMultiple(false);
    }

    rule.type = type;
    rule.operator = rule.getAvailableOperators()?.[0]?.type;
    rule.value = rule.getDefaultValue();

    // if new type is recurrence or recurrence_not_answered, force set multiple rule to true
    if (rule.type === "recurrence" || rule.type === "recurrence_not_answered") {
      this.forceSetMultiple(true);
    }

    this.recurrentRuleTypesAreInterconnected();
    this.refreshRuleTypeSelectors();
    this.checkCanDisplayOperatorColumn();

    this.rulesChange.emit(this.rules);
  }

  public removeRule(index: number) {
    const deletedRule = this.rules.splice(index, 1);

    deletedRule.forEach((rule) => {
      // if old type was recurrence and there is no recurrence_not_answered rule, force set multiple rule to false
      if (rule.type === "recurrence") {
        this.forceSetMultiple(false);
        this.rules = this.rules.filter(
          (r) => r.type !== "recurrence_not_answered",
        );
      }
    });

    this.rules = Array.from(this.rules);
    this.rulesChange.emit(this.rules);

    this.refreshRuleTypeSelectors();
    this.checkCanDisplayOperatorColumn();
  }

  public removeSpecificRule(removedRule: TargetingRule) {
    this.removeRule(this.rules.indexOf(removedRule));
  }

  /**
   * Table view
   */
  public checkCanDisplayOperatorColumn() {
    this.canDisplayOperatorColumn = !!this.rules.find((r: TargetingRule) => {
      if (r.type === "visitor_property") {
        if (this.registryEntriesIdentityPropertyScreeb.length === 0) {
          return false;
        }
      } else if (r.type === "visitor_event_time") {
        if (this.registryEntriesEventScreeb.length === 0) {
          return false;
        }
      } else if (r.type === "visitor_event_count") {
        if (this.registryEntriesEventScreeb.length === 0) {
          return false;
        }
      } else if (r.type === "device_visitor_property") {
        if (this.registryEntriesIdentityPropertyDevice.length === 0) {
          return false;
        }
      } else if (r.type === "device_visitor_event_time") {
        if (this.registryEntriesEventDevice.length === 0) {
          return false;
        }
      } else if (r.type === "device_visitor_event_count") {
        if (this.registryEntriesEventDevice.length === 0) {
          return false;
        }
      }

      return this.visibleRuleOperator.includes(r.type);
    });
  }

  private refreshRuleTypeSelectors() {
    this.ruleTypeSelectorsOptions = this.rules.map((rule) =>
      this.getSelectableRulesTypes(rule),
    );
  }

  private getSelectableRulesTypes(
    currentRule: TargetingRule,
  ): NzSelectOptionInterface[] {
    return Object.values(targetingRulesTypesAndLabels)
      .filter((info: TargetingRuleInfo) => {
        if (info.type === "recurrence_not_answered") {
          return false;
        }
        if (this.isOrgTargeting && !this.isRecordTargeting) {
          if (
            !orgLevelTargetingRuleTypesExceptCappingRules.includes(info.type)
          ) {
            return false;
          }
        }

        if (this.isRecordTargeting) {
          if (
            cappingTargetingRuleTypes.includes(info.type) ||
            TargetingRuleTypes.includes(info.type)
          ) {
            return false;
          }
        }

        if (
          !this.isOrgTargeting &&
          ((this.distribution.interaction &&
            !info.allowedInteraction.includes(this.distribution.interaction)) ||
            (this.distribution.type &&
              !info.allowedChannel.includes(this.distribution.type)))
        ) {
          return false;
        }

        if (
          deviceTrackingTargetingRuleTypes.includes(info.type) &&
          !this.entitlementService.isAvailable("device_tracking")
        ) {
          return false;
        }

        if (
          info.type === "intent_leave_page" &&
          !this.featureFlaggingService.displayRuleTypeLeaveIntent()
        ) {
          return false;
        }

        if (this.notRemovableRulesTypes.includes(info.type)) {
          return false;
        }

        // check if i can have this rule type more than once
        if (info.atMostOnce === false) {
          return true;
        }

        // when i can select this rule only once, but the current rule is of this same type
        if (currentRule.type === info.type) {
          return true;
        }

        // when i can select this rule only once, check if this rule type already exists in rules list
        const ruleSameType = this.rules.find(
          (r: TargetingRule) => r.type === info.type,
        );
        return !ruleSameType;
      })
      .map((info: TargetingRuleInfo) => {
        return {
          label: info.title,
          value: info.type,
          groupLabel: info.group,
        };
      });
  }

  // @TODO: remove when we want to see the multiple back
  public displayRule(rule: TargetingRule): boolean {
    if (rule.type === "intent_leave_page") {
      return this.featureFlaggingService.displayRuleTypeLeaveIntent();
    } else if (
      [
        "capping_survey_display",
        "capping_survey_response",
        "multiple",
        "recurrence_not_answered",
      ].includes(rule.type)
    ) {
      return false;
    }
    return true;
  }

  public forceSetMultiple(value: boolean): boolean {
    // First, checking if multiple rule exists
    const multipleRule = this.rules.find(
      (existingRule) => existingRule.type === "multiple",
    );

    // If multiple rule doesn't exists, create it
    if (!multipleRule) {
      this.onAddRule("multiple", null);
    }

    // If multiple rule exists, set it to true
    multipleRule.value.v_b = value;

    return true;
  }

  public areEventNamesValid(name_ids: string[]) {
    return (
      name_ids &&
      name_ids.length &&
      name_ids.every((name_id) =>
        Boolean(this.registryEntriesEventById.get(name_id)?.slug),
      )
    );
  }

  public getRuleRecurrence(): TargetingRule {
    return this.rules.find((rule) => rule.type === "recurrence");
  }

  public getRuleRecurrenceNotAnswered(): TargetingRule {
    return this.rules.find((rule) => rule.type === "recurrence_not_answered");
  }
}
