import { RegistryEntry } from "models/registry.model";

export type UserGroup = {
  id: string;
  org_id: string;
  type_id: string;

  name: string;
  // strategy: UserGroupStrategy;
  raw_properties: object;
  properties: object;
  // sources: RegistryEntrySource[];

  created_at: string;
  updated_at: string;
};

export function getFormattedUserGroupNameOrID(
  registryEntry: RegistryEntry,
): string {
  const name = registryEntry.title;
  const propertyName = registryEntry.properties?.["name"];

  if (propertyName) {
    return String(propertyName);
  }

  return String(name);
}
