<div class="indicator-with-border">
  <div class="indicator-header">
    <h4>Users</h4>
    <button
      [routerLink]="'/org/' + org?.id + '/people/respondent'"
      nz-button
      nzSize="medium"
      [nzType]="'primary'"
    >
      View all users&nbsp;
      <span nz-icon nzType="arrow-right" nzTheme="outline"></span>
    </button>
  </div>
  <div
    class="user"
    [ngClass]="{
      'not-available': !orgHasRespondents && !isLoading,
    }"
  >
    <div
      class="stat-item"
      [ngClass]="{
        'not-available': !orgHasRespondents && !isLoading,
      }"
    >
      <survey-stats-indicators-single-indicator
        title="Current Active Users"
        boldTitle="true"
        size="medium"
        [value]="currentActiveRespondents"
        [loading]="isLoading"
        [thousandsSuffixes]="true"
        [maximumFractionDigits]="1"
        [link]="'/org/' + org?.id + '/people/respondent'"
        titleLink="View"
        boldLink="true"
      ></survey-stats-indicators-single-indicator>
    </div>
    <div
      class="stat-item"
      [ngClass]="{
        'not-available': !orgHasRespondents && !isLoading,
      }"
    >
      <survey-stats-indicators-single-indicator
        title="New Users This Month"
        boldTitle="true"
        size="medium"
        [value]="newRespondents"
        [diffPercent]="newRespondentsDiff"
        [loading]="isLoading"
        [thousandsSuffixes]="true"
        [maximumFractionDigits]="1"
        [link]="'/org/' + org?.id + '/survey/create'"
        titleLink="Ask a question"
        boldLink="true"
      ></survey-stats-indicators-single-indicator>
    </div>
    <div
      class="stat-item"
      [ngClass]="{
        'not-available': !orgHasRespondents && !isLoading,
      }"
    >
      <survey-stats-indicators-single-indicator
        title="Daily Active Users"
        boldTitle="true"
        size="medium"
        [value]="dailyActiveRespondents"
        [diffPercent]="dailyActiveRespondentsDiff"
        [loading]="isLoading"
        [thousandsSuffixes]="true"
        [maximumFractionDigits]="1"
      ></survey-stats-indicators-single-indicator>
    </div>
    <div
      class="stat-item"
      [ngClass]="{
        'not-available': !orgHasRespondents && !isLoading,
      }"
    >
      <survey-stats-indicators-single-indicator
        title="Weekly Active Users"
        boldTitle="true"
        size="medium"
        [value]="weeklyActiveRespondents"
        [diffPercent]="weeklyActiveRespondentsDiff"
        [loading]="isLoading"
        [thousandsSuffixes]="true"
        [maximumFractionDigits]="1"
      ></survey-stats-indicators-single-indicator>
    </div>
  </div>
</div>
