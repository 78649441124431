<ng-container *ngIf="hook">
  <form
    nz-form
    [nzAutoTips]="autoTips"
    [formGroup]="validateForm"
    (ngSubmit)="save()"
  >
    <nz-form-item>
      <nz-form-label>Name</nz-form-label>
      <nz-form-control>
        <input
          type="text"
          nz-input
          nzHasFeedback
          formControlName="name"
          placeholder="Internal ETL"
        />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label>URL</nz-form-label>
      <nz-form-control [nzErrorTip]="errorTpl">
        <nz-input-group nzAddOnBefore="POST">
          <input
            type="url"
            nz-input
            nzHasFeedback
            formControlName="path"
            placeholder="https://acme.org/webhook/a/b/c"
          />
          <ng-template #errorTpl>
            <span class="field-error">Malformed URL</span>
          </ng-template>
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label>Trigger webhook</nz-form-label>
      <nz-form-control formGroupName="hooks">
        <ng-container *ngFor="let hookSpec of hooksSpec; let i = index">
          <label
            nz-checkbox
            [nzValue]="hookSpec.label"
            [formControlName]="hookSpec.type"
          >
            {{ hookSpec.label }}
          </label>
          <br />
        </ng-container>
      </nz-form-control>
    </nz-form-item>

    <div class="actions">
      <a
        nz-button
        nzType="primary"
        [disabled]="!validateForm.valid"
        nz-dropdown
        [nzDropdownMenu]="testButton"
        nzTrigger="click"
      >
        Send Test Data
      </a>
      <a
        nz-button
        nzType="primary"
        [disabled]="!validateForm.valid"
        [nzLoading]="replaying"
        nz-dropdown
        [nzDropdownMenu]="replayButton"
        nzTrigger="click"
      >
        Re-Send All Historical Data
      </a>

      <span class="push"></span>
      <button
        nz-button
        nzType="primary"
        (click)="save()"
        [disabled]="!validateForm.valid"
      >
        {{ !!survey ? "Ok" : "Save" }}
      </button>
      <button nz-button nzType="default" (click)="cancel()">Cancel</button>
      <button nz-button nzType="dashed" nzDanger (click)="remove()">
        Remove
      </button>
    </div>
  </form>
</ng-container>

<nz-dropdown-menu #testButton="nzDropdownMenu">
  <ul nz-menu>
    <ng-container *ngFor="let hookSpec of hooksSpec; let i = index">
      <li nz-menu-item (click)="sendHookSample(hookSpec.type)">
        {{ hookSpec.sampleLabel }}
      </li>
    </ng-container>
  </ul>
</nz-dropdown-menu>

<nz-dropdown-menu #replayButton="nzDropdownMenu">
  <ul nz-menu>
    <ng-container *ngFor="let hookSpec of hooksSpec; let i = index">
      <li nz-menu-item (click)="triggerHookReplay(hookSpec.type)">
        {{ hookSpec.sampleLabel }}
      </li>
    </ng-container>
  </ul>
</nz-dropdown-menu>
