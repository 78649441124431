<div class="indicator-with-border">
  <div class="indicator-header">
    <h4>Responses Emotions Analysis</h4>
  </div>
  <survey-stats-indicators-radar-indicator
    [labels]="[
      '😡 Anger',
      '&nbsp;😄 Joy&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;',
      '😱 Fear',
      '😢 Sadness',
    ]"
    [legend]="emotionsChartLegend"
    [datasets]="emotionsChartDatasets"
    [loading]="isLoading"
  />
</div>
