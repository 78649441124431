import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import { NzSelectOptionInterface } from "ng-zorro-antd/select";

import { NotificationHelper } from "helpers/notification.helper";
import { Org } from "models/org.model";
import { Integration } from "models/integrations.model";
import { IntegrationDao } from "models/integration.dao";
import { EntitlementService } from "services/entitlement.service";

@Component({
  selector: "share-lateral-panel-slack",
  templateUrl: "./share-via-slack.component.html",
  styleUrls: ["./share-via-slack.component.scss"],
})
export class SlackShareLateralPanelComponent implements OnInit, OnDestroy {
  @Input() org: Org;
  @Input() integrations: Integration[];
  @Input() slackChannel: string;
  @Input() enabled: boolean;

  @Output() slackChannelChange = new EventEmitter<string>();
  @Output() enabledChange = new EventEmitter<boolean>();
  @Output() validChange = new EventEmitter<boolean>();

  public quotaOk: boolean;

  public channels: NzSelectOptionInterface[] = [];

  public integrationSlack?: Integration;
  public integrationSlackStatus?:
    | "not_installed"
    | "not_authed"
    | "loading"
    | "ok"
    | "error";

  private obs: any;

  constructor(
    private route: ActivatedRoute,
    private integrationDao: IntegrationDao,
    private entitlementService: EntitlementService,
    private notificationHelper: NotificationHelper,
  ) {}

  ngOnInit(): void {
    this.quotaOk = this.entitlementService.isIntegrationAvailable("slack");

    this.fetchChannels().then(() => this.isSlackValid());
  }

  ngOnDestroy(): void {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  private async fetchChannels() {
    this.integrationSlack = this.integrations.find(
      ({ type }) => type === "slack",
    );

    if (!this.integrationSlack) {
      this.integrationSlackStatus = "not_installed";
      return;
    }

    if (!this.integrationSlack.auth_ok) {
      this.integrationSlackStatus = "not_authed";
      return;
    }

    this.integrationSlackStatus = "loading";

    this.channels = await this.integrationDao
      .slackListChannels(this.org.id)
      .then(({ channels }) => {
        this.integrationSlackStatus = "ok";

        return channels.map(({ id, name }) => ({
          value: id,
          label: `#${name}`,
        }));
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err);
        this.integrationSlackStatus = "error";
        return [];
      });
  }

  public isSlackValid() {
    let valid = true;

    if (this.enabled) {
      valid =
        Boolean(this.slackChannel) &&
        Boolean(this.channels.find(({ value }) => value === this.slackChannel));
    }

    this.validChange.emit(valid);
    return valid;
  }

  public onEnabledChange(enabled: boolean) {
    this.enabledChange.emit(enabled);
    this.isSlackValid();
  }
}
