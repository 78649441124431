import { default as insane } from "insane";
import { emojiTranscoder } from "./emojis";

/***************************************************
 *          DUPLICATE FROM TAG SOURCE CODE
 ***************************************************/

export const htmlToMarkdown = (html: string, style = true, links = true) => {
  let markdown = html;

  if (style) {
    markdown = markdown
      .replace(/<i>([^<]*)<\/i>/gim, "*$1*")
      .replace(/<b>([^<]*)<\/b>/gim, "**$1**")
      .replace(/<u>([^<]*)<\/u>/gim, "**$1**")
      .replace(/<s>([^<]*)<\/s>/gim, "~~$1~~");
  }

  if (links) {
    markdown = markdown.replace(
      /<a.*?href=["']([^"']*)["'][^>]*>([^<]*)<\/a>/gim,
      "[$2]($1)",
    );
  } else {
    markdown = markdown.replace(
      /<a.*?href=["']([^"']*)["'][^>]*>([^<]*)<\/a>/gim,
      "$2",
    );
  }

  return markdown;
};

function sanitizerFilters(token) {
  if (token.tag === "a") {
    const href = token.attrs["href"];

    if (href === null || href === undefined || href === "") return false;

    if (href[0] === "#") return false;

    // fails relative URL or invalid URL
    try {
      new URL(href);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
    } catch (err) {
      return false;
    }

    // it is supported by library already,
    // but library does not break the <a> tag in that case :-(
    if (href.indexOf("javascript:") === 0) return false;
    if (href.indexOf("magnet:") === 0) return false;

    // bullshit that enforces opening in new window 🤮
    // @TODO: we should make a contrib to official library, in order to support side effect with a clean hook
    token.attrs["target"] = "_blank";
  }
  return true;
}

export class SanitizeOptions {
  links?: boolean = false;
  styling?: boolean = false;
  emojiTranscoder?: boolean = false;
  useDarkAndLightMode?: boolean = false;
  primaryTextColor?: string = "var(--screeb-color-white)";
  CR?: boolean = false;
  nativeCR?: boolean = false;
}

// text formating order does matter
// @TODO: it does not makes sense to apply this filters to buttons, scores and responses
// @TODO: responses should be flatten, even if we have tags in the button
export function htmlSanitizer(
  input: string,
  options: SanitizeOptions = {},
): string {
  const allowedTags: string[] = ["p"];

  if (options.links) allowedTags.push("a");
  if (options.styling) {
    allowedTags.push(
      "u",
      "s",
      "b",
      "i",
      "strong",
      "em",
      "del",
      "blockquote",
      "code",
      "pre",
      "ul",
      "li",
      "ol",
      "input",
    );
  }

  if (options.CR) allowedTags.push("br");

  const allowedAttributes = { "*": ["title", "accesskey"] };

  if (options.styling) {
    allowedAttributes["input"] = ["type", "disabled", "checked"];
    allowedAttributes["li"] = ["class"];
    allowedAttributes["ul"] = ["class"];
  }
  if (options.links) {
    allowedAttributes["a"] = [
      "href",
      "name",
      "target",
      "referrerpolicy",
      "aria-label",
    ];
  } else {
    input = input.replace(
      /<a.*?href=["']([^"']*)["'][^>]*>([^<]*)<\/a>/gim,
      "<u>$2</u>",
    );
  }

  let sanitized = insane(input, {
    allowedTags: allowedTags,
    allowedAttributes: allowedAttributes,
    allowedSchemes: ["http", "https", "mailto"],
    filter: sanitizerFilters,
    transformText: (text) => {
      // if (options.CR) text = text.replace(/\n/g, "<br>");
      if (!options.nativeCR && !options.CR) text = text.replace(/\n/g, " ");
      return text;
    },
  });

  // emoji transcoding is executed after sanitizer, because images may be removed by `insane`
  if (options.emojiTranscoder)
    sanitized = emojiTranscoder(
      sanitized,
      options.useDarkAndLightMode,
      options.primaryTextColor,
    );

  return sanitized;
}

export const stripHtml = (html: string): string =>
  (html ?? "").replace(/(<([^>]+)>)/gi, "");
