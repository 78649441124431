<div class="lateral-panel" cdkScrollable>
  <div class="lateral-panel-inner" cdkScrollable>
    <!-- header -->
    <header class="lateral-header">
      <div class="left">
        <button (click)="onClose.emit($event)" class="close">
          <i nz-icon nzType="close" nzTheme="outline"></i>
        </button>
        <h2 class="title">Share</h2>
      </div>
      <div class="right"></div>
    </header>

    <div class="lateral-body">
      <div class="lateral-body-content">
        <h3>Which data do you want to share?</h3>

        <checkbox-select
          [items]="allKPIs"
          [selectedItems]="selectedKpis"
          (selectedItemsChange)="this.onKpisChange($event)"
          placeholder="Search KPI(s)"
          [unique]="this.reportType === 'all-responses'"
        ></checkbox-select>

        <div class="error" *ngIf="!isKpisValid()">
          Please select 1 to 10 KPI(s).
        </div>

        <ng-container *ngIf="this.shouldShowFilters">
          <h3>How do you want to filter?</h3>
          <div class="survey-filter-container">
            <survey-filter-criteria-filters
              [shouldTakeLastFilters]="false"
              [visibleDropdown]="true"
              title="Filter"
              [loadingDropdown]="loadingDropdown"
              [showUpdateButton]="false"
              [(filtersOperator)]="filtersOperator"
              [(filters)]="filters"
              [(lastFilters)]="lastFilters"
              [(keysOptions)]="keysOptions"
              [availableLanguages]="availableLanguages"
              [availableTags]="availableTags"
              [getOperatorOptions]="getOperatorOptions"
              [getValueComponent]="getValueComponent"
              [getValuePresetOptions]="getValuePresetOptions"
              [nodesByKey]="nodesByKey"
              [survey]="survey"
              [getAvailableFields]="getAvailableFields"
              (filterKeyChange)="onFilterKeyChange($event.f, $event.event)"
              (operatorChange)="onOperatorChange($event)"
              (filterAdded)="onFilterAdded($event)"
              (filtersAreValidChange)="filtersAreValid = $event"
              [registryEntriesGroup]="registryEntriesGroup"
              [registryEntriesIdentityProperty]="
                registryEntriesIdentityProperty
              "
              [registryEntriesEvent]="registryEntriesEvent"
              [matchingOperatorOptions]="matchingOperatorOptions"
            ></survey-filter-criteria-filters>
          </div>
        </ng-container>

        <h3>How do you want to share?</h3>

        <share-lateral-panel-slack
          *ngIf="isSlackIntegrationAvailableHere()"
          class="destination"
          [org]="org"
          [integrations]="integrations"
          [(slackChannel)]="slackChannel"
          [(enabled)]="slackEnabled"
          (validChange)="isSlackValid = $event"
        ></share-lateral-panel-slack>

        <share-lateral-panel-notion
          *ngIf="isNotionIntegrationAvailableHere()"
          class="destination"
          [org]="org"
          [survey]="survey"
          [filters]="filters"
          [filtersOperator]="filtersOperator"
          [withFilters]="shouldShowFilters"
          [integrations]="integrations"
          [(notionDatabaseId)]="notionDatabaseId"
          [(notionVersion)]="notionVersion"
          [(enabled)]="notionEnabled"
          (validChange)="isNotionValid = $event"
          (triggerSave)="share(false)"
        ></share-lateral-panel-notion>

        <share-lateral-panel-atlassian
          *ngIf="isAtlassianIntegrationAvailableHere()"
          class="destination"
          [org]="org"
          [survey]="survey"
          [integrations]="integrations"
          [(atlassianProjectId)]="atlassianProjectId"
          [(enabled)]="atlassianEnabled"
          (validChange)="isAtlassianValid = $event"
        ></share-lateral-panel-atlassian>

        <share-lateral-panel-email
          *ngIf="isEmailIntegrationAvailableHere()"
          class="destination"
          [(emails)]="emailList"
          [(enabled)]="emailEnabled"
          (validChange)="isEmailValid = $event"
        ></share-lateral-panel-email>

        <ng-container *ngIf="hasRepeatInterval()">
          <h3>Do you want to repeat in the future?</h3>
          <nz-select
            nzSize="large"
            class="repeat-select"
            [(ngModel)]="repeatInterval"
            [nzOptions]="repeatOptions"
          ></nz-select>
          <div class="repeat-interval-tips">{{ getShareTipsLabel() }}</div>
        </ng-container>
      </div>

      <div class="repeat-interval-tips" *ngIf="hasAllResponsesRawContent()">
        Every future responses will be shared automatically via the selected
        channel.
      </div>

      <div class="error" *ngIf="shareError">
        Something went wrong. Please retry.
      </div>

      <!-- bottom buttons -->
      <footer class="panel-button-container">
        <div class="left group">
          <button
            nz-button
            nzType="default"
            nzSize="large"
            nzDanger
            (click)="remove()"
          >
            Delete
          </button>
        </div>
        <div class="right group">
          <button
            nz-button
            nzType="default"
            nzSize="large"
            (click)="onClose.emit($event)"
          >
            Cancel
          </button>
          <button
            nz-button
            nzType="primary"
            nzSize="large"
            [nzLoading]="shareLoading"
            [disabled]="shareIsDisabled()"
            (click)="share()"
          >
            {{ getShareButtonLabel() }}
          </button>
        </div>
      </footer>
    </div>
  </div>
</div>

<!-- transparent overlay -->
<div class="lateral-panel-backdrop" (click)="onClose.emit($event)"></div>
