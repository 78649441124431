<div *ngIf="loaded">
  <div *ngIf="distributions.length && !uiService.isMessagePage">
    <h2>
      <screeb-icon size="md" icon="button"></screeb-icon>
      Feedback channels
    </h2>
    <p>
      Choose the best way to invite your users to participate in your
      {{ uiService.isMessagePage ? "messages" : "surveys" }} and share their
      feedback.
    </p>

    <div class="distributions" *ngIf="distributions.length">
      <ng-container *ngFor="let interaction of distributionInteractions">
        <ng-container>
          <div
            class="distribution"
            nz-tooltip
            *ngFor="let distribution of [getByInteraction(interaction)]"
            [routerLink]="[
              '/org',
              orgId,
              uiService.surveyTypePath,
              survey.id,
              'share',
              distribution?.id,
            ]"
          >
            <div class="distribution__tip" *ngIf="interaction === 'in_app'">
              Best response rate
            </div>
            <div class="distribution__content">
              <p class="distribution__title">
                {{ getTitle(interaction) }}
              </p>
              <p class="distribution__description">
                {{ distributionInteractionsDescription[interaction] }}
              </p>
            </div>
            <div class="distribution__img">
              <img
                [src]="getImage(interaction, true)"
                class="distribution__img--enabled"
                [alt]="getTitle(interaction)"
              />
              <img
                [src]="getImage(interaction, false)"
                class="distribution__img--disabled"
                [alt]="getTitle(interaction)"
              />
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <ng-container *ngIf="legacyDistributions?.length">
      <h3>
        <screeb-icon size="md" icon="hourglass"></screeb-icon>
        Legacy channels
      </h3>
      <p>
        These methods are being deprecated.
        <br />
        Please transition to our new "In-App" channel that merge Web & Mobile
        for better performance and engagement.
        <br />
        Or the new "Link" channel to replace the legacy Hosted Page.
      </p>
      <div class="distributions-legacy">
        <div
          class="distribution-legacy"
          *ngFor="
            let distribution of legacyDistributions;
            trackByProperty: 'id'
          "
          [routerLink]="[
            '/org',
            orgId,
            uiService.surveyTypePath,
            survey.id,
            'share',
            distribution.id,
          ]"
        >
          <span class="distribution-legacy__left">
            <screeb-icon
              *ngIf="distribution.type === 'android'"
              size="sm"
              icon="android"
            ></screeb-icon>
            <screeb-icon
              *ngIf="distribution.type === 'ios'"
              size="sm"
              icon="apple"
            ></screeb-icon>
            <screeb-icon
              *ngIf="distribution.type === 'hosted-page'"
              size="sm"
              icon="link"
            ></screeb-icon>
            <screeb-icon
              *ngIf="distribution.type === 'widget'"
              size="md"
              icon="desktop"
            ></screeb-icon>
            <span>{{ distribution.title }}</span>
          </span>
        </div>
      </div>
    </ng-container>
  </div>
  <div
    class="share-redirect-container"
    *ngIf="!distributions.length || uiService.isMessagePage"
  >
    <h1>
      Where do you want to
      {{ uiService.isMessagePage ? "see this message" : "share this survey" }}?
    </h1>

    <div class="share-cards">
      <div class="share-card" [routerLink]="getLink('widget')">
        <div class="share-card-illustration" [style.background]="'#EBF4FF'">
          <img
            alt="Web"
            width="280"
            src="/assets/illustrations/channels/web.png"
            class="padding"
          />
        </div>
        <div class="share-card-details">
          <div class="share-card-details-title">Web App</div>
          <div class="share-card-details-description">
            For SaaS and websites
          </div>
          <div class="spacer"></div>
          <a nz-button nzType="primary" [routerLink]="getLink('widget')">
            Choose
          </a>
        </div>
      </div>
      <div
        class="share-card"
        [routerLink]="
          uiService.isMessagePage &&
          !('isContextualMessagesMobileEnabled' | hasFeatureFlag)
            ? null
            : getLink('ios')
        "
      >
        <div class="share-card-illustration" [style.background]="'#F5F1FF'">
          <img
            alt="iOS"
            width="280"
            src="/assets/illustrations/channels/ios.png"
            class="padding"
          />
        </div>
        <div class="share-card-details">
          <div class="share-card-details-title">iOS App</div>
          <div class="share-card-details-description">For iPhones and iPad</div>
          <div class="spacer"></div>
          <a
            nz-button
            nzType="primary"
            [routerLink]="getLink('ios')"
            [disabled]="
              uiService.isMessagePage &&
              !('isContextualMessagesMobileEnabled' | hasFeatureFlag)
            "
          >
            {{
              uiService.isMessagePage &&
              !("isContextualMessagesMobileEnabled" | hasFeatureFlag)
                ? "Coming soon"
                : "Choose"
            }}
          </a>
        </div>
      </div>
      <div
        class="share-card"
        [routerLink]="uiService.isMessagePage ? null : getLink('android')"
      >
        <div class="share-card-illustration" [style.background]="'#E4F8F2'">
          <img
            width="280"
            alt="Android"
            src="/assets/illustrations/channels/android.png"
          />
        </div>
        <div class="share-card-details">
          <div class="share-card-details-title">Android App</div>
          <div class="share-card-details-description">
            For smartphones and tablets
          </div>
          <div class="spacer"></div>
          <a
            nz-button
            nzType="primary"
            [routerLink]="getLink('android')"
            [disabled]="uiService.isMessagePage"
          >
            {{ uiService.isMessagePage ? "Coming soon" : "Choose" }}
          </a>
        </div>
      </div>
    </div>

    <a
      *ngIf="!uiService.isMessagePage"
      nz-button
      nzType="dashed"
      class="hosted-page"
      [routerLink]="getLink('hosted-page')"
    >
      <div class="hosted-page-title">
        <i nz-icon nzType="link"></i>
        Direct link
      </div>
      <div class="hosted-page-description">
        To share via email or social media
      </div>
    </a>
  </div>
</div>
