import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";

import { ApplicationLoadService } from "services/app.service";
import {
  OrgBilling,
  OrgBillingInvoice,
  OrgBillingPaymentMethods,
  OrgBillingSubscription,
} from "models/org_billing.model";
import { SuperOrgDao } from "models/super-org.dao";

@Injectable()
export class OrgBillingResolver implements Resolve<any> {
  constructor(
    private applicationLoadService: ApplicationLoadService,
    private superOrgDao: SuperOrgDao,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Promise<OrgBilling> {
    return this.applicationLoadService
      .loader()
      .then(() => {
        if (route.params["super_org_id"]) {
          return this.superOrgDao.getBillingBySuperOrgId(
            route.params["super_org_id"],
          );
        } else if (route.parent.data?.org?.super_org_id) {
          return this.superOrgDao.getBillingBySuperOrgId(
            route.parent.data.org.super_org_id,
          );
        } else {
          throw new Error("No super org id found");
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        throw err;
      });
  }
}

@Injectable()
export class OrgBillingSubscriptionResolver implements Resolve<any> {
  constructor(
    private applicationLoadService: ApplicationLoadService,
    private superOrgDao: SuperOrgDao,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Promise<OrgBillingSubscription> {
    return this.applicationLoadService
      .loader()
      .then(() => {
        if (route.params["super_org_id"]) {
          return this.superOrgDao.getBillingSubscriptionBySuperOrgId(
            route.params["super_org_id"],
          );
        } else if (route.parent.data?.org?.super_org_id) {
          return this.superOrgDao.getBillingSubscriptionBySuperOrgId(
            route.parent.data.org.super_org_id,
          );
        } else {
          throw new Error("No super org id found");
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        throw err;
      });
  }
}

@Injectable()
export class OrgBillingPaymentMethodsResolver implements Resolve<any> {
  constructor(
    private applicationLoadService: ApplicationLoadService,
    private superOrgDao: SuperOrgDao,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Promise<OrgBillingPaymentMethods> {
    return this.applicationLoadService
      .loader()
      .then(() => {
        if (route.params["super_org_id"]) {
          return this.superOrgDao.getPaymentMethodBySuperOrgId(
            route.params["super_org_id"],
          );
        } else if (route.parent.data?.org?.super_org_id) {
          return this.superOrgDao.getPaymentMethodBySuperOrgId(
            route.parent.data.org.super_org_id,
          );
        } else {
          throw new Error("No super org id found");
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        throw err;
      });
  }
}

@Injectable()
export class OrgBillingInvoicesResolver implements Resolve<any> {
  constructor(
    private applicationLoadService: ApplicationLoadService,
    private superOrgDao: SuperOrgDao,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Promise<OrgBillingInvoice[]> {
    return this.applicationLoadService
      .loader()
      .then(() => {
        if (route.params["super_org_id"]) {
          return this.superOrgDao.getInvoicesBySuperOrgId(
            route.params["super_org_id"],
          );
        } else if (route.parent.data?.org?.super_org_id) {
          return this.superOrgDao.getInvoicesBySuperOrgId(
            route.parent.data.org.super_org_id,
          );
        } else {
          throw new Error("No super org id found");
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        throw err;
      });
  }
}
