<div
  class="capping-config-container"
  *ngIf="targetingRuleDisplay && targetingRuleResponse"
>
  <div class="capping-config">
    <span>Time Period</span>
    <nz-select
      [ngModel]="timePeriod"
      (ngModelChange)="onTimePeriodChange($event)"
      [nzDropdownMatchSelectWidth]="false"
    >
      <nz-option
        *ngFor="let opt of surveyCappingTypeToTimePeriod[surveyCappingType]"
        [nzValue]="opt.type"
        [nzLabel]="opt.title"
      ></nz-option>
    </nz-select>
  </div>
  <div class="capping-config">
    <span>Maximum Survey Displays</span>
    <div class="capping-config-value">
      <input
        type="number"
        min="1"
        max="99999999"
        step="1"
        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
        nz-input
        [ngModel]="targetingRuleDisplay.value.v_n"
        (ngModelChange)="
          onCappingCountValueChange(targetingRuleDisplay, $event, 1, 99999999)
        "
      />
      <i
        nz-icon
        nzType="question-circle"
        nzTheme="outline"
        nzTooltipTitle="Leave empty if you don't want to use this condition"
        nzTooltipPlacement="rightBottom"
        nz-tooltip
      ></i>
    </div>
  </div>
  <div class="capping-config">
    <span>Maximum Survey Responses</span>
    <div class="capping-config-value">
      <input
        type="number"
        min="1"
        max="99999999"
        step="1"
        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
        nz-input
        [ngModel]="targetingRuleResponse.value.v_n"
        (ngModelChange)="
          onCappingCountValueChange(targetingRuleResponse, $event, 1, 99999999)
        "
      />
      <i
        nz-icon
        nzType="question-circle"
        nzTheme="outline"
        nzTooltipTitle="Leave empty if you don't want to use this condition"
        nzTooltipPlacement="rightBottom"
        nz-tooltip
      ></i>
    </div>
  </div>
  <div
    *ngIf="
      surveyCappingType === 'per-respondent' && targetingRuleTimeBetweenDisplay
    "
    class="capping-config"
  >
    <span>Minimum Time Between Surveys Displays</span>
    <div class="capping-config-value">
      <nz-input-group [nzSuffix]="'minutes'">
        <input
          type="number"
          min="1"
          max="99999999"
          step="1"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          nz-input
          [ngModel]="timeBetweenSurveyDisplays"
          (ngModelChange)="
            onCappingTimeBetweenDisplayValueChange($event, 1, 99999999)
          "
        />
      </nz-input-group>
      <i
        nz-icon
        nzType="question-circle"
        nzTheme="outline"
        nzTooltipTitle="Leave empty if you don't want to use this condition"
        nzTooltipPlacement="rightBottom"
        nz-tooltip
      ></i>
    </div>
  </div>
</div>

<survey-capping-summary
  [rules]="rules"
  [surveyCappingType]="surveyCappingType"
></survey-capping-summary>
