import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Org } from "models/org.model";
import { NotificationHelper } from "helpers/notification.helper";

@Component({
  selector: "surveys-import-modal",
  templateUrl: "./surveys-import.component.html",
  styleUrls: ["./surveys-import.component.scss"],
})
export class SurveysImportModalComponent {
  isConfirmLoading = false;

  @Input() isOpen: boolean = false;
  @Output() modalOpenChange: EventEmitter<boolean> = new EventEmitter();
  @Input() org: Org = null;

  constructor(private notificationHelper: NotificationHelper) {}

  onSubmit(): void {}

  handleCancel(): void {
    this.isOpen = false;
    this.modalOpenChange.emit(this.isOpen);
    this.isConfirmLoading = false;
  }
}
