<nz-modal
  *ngIf="!inBuilder"
  [(nzVisible)]="isOpen"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="null"
  [nzClosable]="false"
  (nzOnCancel)="handleCancel()"
  nzWidth="976px"
  nzClassName="email-preview-modal"
>
  <ng-template #modalTitle>
    <div class="modal-header">
      <h1>Email Preview</h1>
      <p>Template format may change depending the end user email portal.</p>
    </div>
  </ng-template>

  <ng-template #modalContent>
    <div class="modal-content">
      <iframe #preview class="email-preview"></iframe>
    </div>
    <div class="modal-footer"></div>
  </ng-template>
</nz-modal>

<div *ngIf="inBuilder" class="in-builder">
  <iframe #preview class="email-preview"></iframe>
</div>
