<svg
  class="chart"
  xmlns="http://www.w3.org/2000/svg"
  [attr.width]="chartWidth"
  [attr.height]="chartHeight"
  [attr.viewBox]="'0 0 ' + chartWidth + ' ' + chartHeight"
>
  <g>
    <line
      *ngFor="let value of values; let index = index; trackBy: trackElement"
      class="bar"
      [attr.x1]="getBarXPosition(index)"
      [attr.y1]="getBarBottomYPosition()"
      [attr.x2]="getBarXPosition(index)"
      [attr.y2]="getBarTopYPosition(index)"
      [attr.stroke]="value.color"
      [attr.stroke-width]="12"
      stroke-linecap="round"
      stroke-dasharray="140"
      stroke-dashoffset="0"
      [attr.opacity]="getBarOpacity(index)"
      (mouseenter)="barMouseEnter(index, $event)"
      (mouseleave)="barMouseLeave()"
      (click)="barClick.emit({ index: index, value: value })"
    ></line>
  </g>
  <g>
    <line
      x1="0"
      [attr.y1]="chartHeight - legendHeight - legendMargin + 0.5"
      [attr.x2]="chartWidth"
      [attr.y2]="chartHeight - legendHeight - legendMargin + 0.5"
      stroke="#CECED7"
      [attr.stroke-width]="1"
      stroke-linecap="round"
    ></line>
    <image
      *ngFor="let value of values; let index = index; trackBy: trackElement"
      [attr.x]="getBarXPosition(index) - 12"
      class="label"
      [attr.y]="chartHeight - 23"
      width="24"
      height="23"
      [attr.href]="getLabelURL(index)"
      (mouseenter)="barMouseEnter(index, $event)"
      (mouseleave)="barMouseLeave()"
      (click)="barClick.emit({ index: index, value: value })"
    />
  </g>
</svg>
<div
  class="tooltip"
  [attr.style]="
    'left: ' +
    tooltipXPosition +
    'px;' +
    'top: ' +
    tooltipYPosition +
    'px; display: ' +
    (tooltipXPosition === -1 ? 'none' : 'flex')
  "
>
  <div class="tooltipLabel" [innerHTML]="tooltipLabel"></div>
  <div class="tooltipValue">
    {{ tooltipValue }}
  </div>
  <div class="tooltipPercent">({{ tooltipPercent }}%)</div>
</div>
