@let dateStr = currentChangelog?.date.toDateString();
<nz-card class="changelog-container" [nz-tooltip]="dateStr">
  <div class="changelog-header">
    <div class="type">
      <nz-tag>{{ currentChangelog?.type }}</nz-tag>
    </div>
    <div class="title">
      <h3>{{ currentChangelog?.title }}</h3>
    </div>
    <div class="right actions-container">
      <button (click)="prevChangelog()">
        <screeb-icon size="sm" icon="left-arrow" />
      </button>
      <button (click)="nextChangelog()">
        <screeb-icon size="sm" icon="right-arrow" />
      </button>
    </div>
  </div>
  <a
    class="changelog-body"
    [href]="currentChangelog?.url"
    rel="noopener"
    target="_blank"
  >
    <img [src]="currentChangelog.thumbnail" alt="thumbnail" />
  </a>
</nz-card>
