import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
} from "@angular/router";
import { ApplicationLoadService } from "services/app.service";
import { SurveyDao } from "models/survey.dao";
import { PermissionsService } from "services/permissions.service";

@Injectable()
export class DoOrgHasSurveysGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private applicationLoadService: ApplicationLoadService,
    private surveyDao: SurveyDao,
    private permissionsService: PermissionsService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return this.applicationLoadService.loader().then(async () => {
      const orgId = route.params["org_id"];
      // We can't rely on uiService here, as it's too soon
      const isMessagePage = route.routeConfig.path.indexOf("message") === 0;
      const surveys = await this.surveyDao.getAllByOrgId(
        isMessagePage ? "message" : "survey",
        orgId,
        false,
      );
      if (
        surveys.length === 0 &&
        this.permissionsService.isAllowed("survey.create")
      ) {
        await this.router.navigate(
          ["org", orgId, isMessagePage ? "message" : "survey", "create"],
          {},
        );
        return true;
      }
      return true;
    });
  }

  canActivateChild(route: ActivatedRouteSnapshot): Promise<boolean> {
    return this.canActivate(route);
  }
}
