<big-checkbox
  class="big-checkbox"
  label="Via Jira Software"
  [content]="atlassianBigCheckbox"
  [(checked)]="enabled"
  (checkedChange)="onEnabledChange($event)"
  [disabled]="!quotaOk"
  iconUrl="/assets/icons/integrations/atlassian-icon.svg"
  name="atlassianEnabled"
  ngDefaultControl
  collapseWhenNotChecked
>
  <ng-template #atlassianBigCheckbox>
    <div
      class="atlassian-tip"
      *ngIf="integrationAtlassianStatus === 'not_installed'"
    >
      <span>
        No Atlassian integration installed!
        <br />
        Go to
      </span>
      <a routerLink="/org/{{ org.id }}/integrations">integrations</a>
      <span>to install one.</span>
    </div>

    <div
      class="atlassian-tip"
      *ngIf="
        integrationAtlassianStatus === 'error' ||
        integrationAtlassianStatus === 'not_authed'
      "
    >
      <p class="error">Something went wrong with Atlassian integration!</p>
      <p>
        Go to your
        <a routerLink="/org/{{ org.id }}/integrations/atlassian">
          Atlassian integration
        </a>
        to check your connection.
      </p>
    </div>

    <div
      *ngIf="
        integrationAtlassianStatus === 'loading' ||
        integrationAtlassianStatus === 'ok'
      "
    >
      <!-- fetching db -->
      <div class="loader" *ngIf="fetchingProject">
        <nz-spin nzSimple [nzSize]="'medium'"></nz-spin>
      </div>

      <!-- failed to fetch selected db -->
      <div *ngIf="atlassianProjectId && projectError && !fetchingProject">
        <span class="error">Something went wrong with Atlassian project!</span>
        <div class="right">
          <button
            nz-button
            nzType="default"
            nzSize="small"
            nzDanger
            (click)="remove()"
          >
            <i nz-icon nzType="delete"></i>
            Remove
          </button>
        </div>
      </div>

      <!-- db selected -->
      <div
        *ngIf="atlassianProjectId && !projectError && !fetchingProject"
        class="atlassian-version"
      >
        <label>
          <span>Share to Atlassian project &nbsp;</span>
          <!-- <nz-tag nzColor="#00C898">
            <span class="tag">v{{ atlassianVersion }}</span>
          </nz-tag> -->
        </label>
        <nz-input-group
          class="atlassian-project"
          nzSearch
          [nzAddOnAfter]="suffixIconButton"
        >
          <a [href]="project?.id" target="_blank">
            <input
              type="text"
              nz-input
              [value]="project?.name"
              [disabled]="true"
            />
          </a>
        </nz-input-group>
        <ng-template #suffixIconButton>
          <button
            nz-button
            nzType="primary"
            nzDanger
            nzSearch
            (click)="remove()"
          >
            <i nz-icon nzType="delete"></i>
          </button>
        </ng-template>
        <div
          class="right"
          *ngIf="'isAtlassianReplayButtonVisible' | hasFeatureFlag"
        >
          <button
            class="sync"
            nz-button
            nzType="primary"
            [nzLoading]="replaying"
            (click)="triggerReplay()"
          >
            Sync
          </button>
        </div>
      </div>

      <!-- select a page for creating db -->
      <div *ngIf="!atlassianProjectId">
        <nz-select
          nzPlaceHolder="Select a project"
          [nzLoading]="integrationAtlassianStatus === 'loading'"
          [nzOptions]="projectOptions"
          [(ngModel)]="projectId"
          nzSize="large"
          class="atlassian-pages-select"
        ></nz-select>
        <div class="right">
          <button
            class="create-btn"
            nz-button
            nzType="primary"
            [nzLoading]="creating"
            [disabled]="!projectId"
            (click)="syncProject()"
          >
            Synchronize
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</big-checkbox>
