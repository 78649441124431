/* eslint-disable @angular-eslint/no-output-on-prefix */
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from "@angular/core";
import {
  AddonBillingSettings,
  BillingSettings,
  applyCouponToAmount,
  notFreeSubscriptionOptions,
  subscriptionOptions,
  subscriptionOptionsAddon,
  subscriptionSummary,
} from "models/org_billing.model";
import { SettingsService } from "services/settings.service";
import { assert } from "utils/assertion";
import { formatNumber } from "utils/number";
import {
  YearlyDiscount,
  couponToString,
  subscriptionOptionsToAddonArray,
} from "../../products.data";
import { addonInfos } from "../billing-plan/billing-plan.data";

type summaryItems = {
  class: string;
  label: string;
  amount: string;
  tag?: string;
};

// 🤮
const FN = (nbr: number) => formatNumber(nbr, "number");

@Component({
  selector: "billing-subscription-invoice-summary",
  template: `
    <div class="summary-container">
      <h4>Summary</h4>
      <nz-list nzSize="small" class="summary">
        <nz-list-item *ngFor="let item of items">
          <span class="left {{ item.class }}">
            <nz-tag *ngIf="item.tag" [nzColor]="'blue'">{{ item.tag }}</nz-tag>
            {{ item.left }}
          </span>
          <span class="right {{ item.class }}">{{ item.right }}</span>
        </nz-list-item>
      </nz-list>
    </div>
  `,
  styleUrls: ["invoice-summary.component.scss"],
})
export class SettingsBillingSubscriptionInvoiceComponent implements OnChanges {
  @Input() subscriptionOptions: subscriptionOptions;

  @Output() onPriceUpdate = new EventEmitter<subscriptionSummary>();
  @Output() onPriceError = new EventEmitter<any>();

  public items: summaryItems[] = [];

  constructor(public settingsService: SettingsService) {}

  ngOnChanges(): void {
    this.items = this.getSummaryItems();
  }

  public getSummaryItems(): summaryItems[] {
    if (this.subscriptionOptions.planType === "free") {
      return [];
    }

    const summary = this.subscriptionOptionsToSubscriptionSummary(
      this.subscriptionOptions,
      this.settingsService.billing,
    );
    if (summary === null) {
      this.onPriceError.emit(null);
      return;
    }
    this.onPriceUpdate.emit(summary);

    const items = [];

    // plan
    items.push({
      class: "",
      left: `${FN(summary.plan.plan.mtu)} MAU`, // -1 is arbitrary
      right: `€${FN(summary.plan.plan.monthly_billing_mrr ?? 0)}`, // monthly_billing_mrr is not defined for legacy plans
    });

    // addons
    items.push(
      ...summary.addons.map((addon) => ({
        tag: addon.included ? "included" : undefined,
        class: "",
        left: `Add On "${addon.addon.name}"`,
        right:
          (addon.quantity > 1 ? `${addon.quantity}x ` : "") +
          `€${FN(addon?.monthly_billing_mrr ?? 0)}`, // monthly_billing_mrr is not defined for legacy addons
      })),
    );

    if (this.subscriptionOptions.billingCycle === "month") {
      items.push(...this.getSummaryItemsMonthly(summary));
    } else {
      items.push(...this.getSummaryItemsYearly(summary));
    }

    return items;
  }

  private getSummaryItemsMonthly(summary: subscriptionSummary): summaryItems[] {
    const items = [];

    // subtotal
    items.push({
      class: "margin-top " + (summary.coupon ? "" : "bold"),
      left: `Total` + (summary.coupon ? "" : " (excl vat)"),
      right: `€${FN(summary.subtotal.monthly_billing_mrr)}`,
    });

    if (!summary.coupon) {
      return items;
    }

    // coupon
    items.push({
      class: "",
      left: `Coupon (${couponToString(summary.coupon.coupon)})`,
      right: `🎁 It's on us: €-${FN(summary.coupon.monthly_billing_mrr)}`,
    });

    // grand total
    items.push({
      class: `bold margin-top`,
      left: `Total (excl vat)`,
      right: `€${FN(summary.total.monthly_billing_mrr)}`,
    });

    return items;
  }

  private getSummaryItemsYearly(summary: subscriptionSummary): summaryItems[] {
    const items = [];

    // subtotal
    items.push({
      class: `margin-top`,
      left: `Total`,
      right: `€${FN(summary.subtotal.monthly_billing_mrr)}/month`,
    });

    // annual discount
    items.push({
      class: "",
      left: `Annual Subscription: -${YearlyDiscount * 100}%`,
      right: `🥳 You save €${FN(
        summary.subtotal.monthly_billing_mrr -
          summary.subtotal.yearly_billing_mrr,
      )}`,
    });

    if (summary.coupon) {
      // coupon
      items.push({
        class: "",
        left: `Discount (${couponToString(summary.coupon.coupon)})`,
        right: `🎁 It's on us: €${FN(summary.coupon.yearly_billing_arr)}/year`,
      });
    }

    // grand total
    items.push({
      class: `bold margin-top`,
      left: `Total (excl vat)`,
      right: `€${FN(summary.total.yearly_billing_mrr)}/month`,
    });
    items.push({
      class: ``,
      left: ``,
      right: `€${FN(summary.total.yearly_billing_arr)}/year`,
    });

    return items;
  }

  // This function build invoice summary. It takes into account the coupon.
  subscriptionOptionsToSubscriptionSummary(
    subscriptionOptions: notFreeSubscriptionOptions,
    billingSettings: BillingSettings,
  ): subscriptionSummary | null {
    assert(
      ["scale", "advanced"].includes(subscriptionOptions.planType),
      "Invalid plan type",
    );

    const products =
      subscriptionOptions.planType === "scale"
        ? billingSettings.plans.scale.products
        : billingSettings.plans.advanced.products;

    const plan = products.find((p) => p.mtu === subscriptionOptions.mtu);
    if (!plan) {
      return null;
    }

    const coupon = subscriptionOptions.coupon;

    const planCost = {
      plan: plan,
      monthly_billing_mrr: plan?.monthly_billing_mrr ?? 0, // monthly_billing_mrr is not defined for legacy products
      yearly_billing_mrr: plan?.yearly_billing_mrr ?? 0, // monthly_billing_mrr is not defined for legacy products
      yearly_billing_arr: plan?.yearly_billing_arr ?? 0, // monthly_billing_mrr is not defined for legacy products
    };

    const addonKeyToSubscriptionOptionAddon = (
      addonKey: string,
      qty: number,
      free: boolean = false,
      included: boolean = false,
    ) => {
      const addon: AddonBillingSettings =
        billingSettings.plans[subscriptionOptions.planType]?.addons?.[addonKey];
      if (!included && !addon) {
        throw Error(
          `Addon "${addonKey}" not found in plan "${subscriptionOptions.planType}" (included: ${included}, free: ${free})`,
        );
      }

      const pricing =
        free || included
          ? {
              monthly_billing_mrr: 0,
              yearly_billing_mrr: 0,
              yearly_billing_arr: 0,
            }
          : {
              monthly_billing_mrr:
                addon.per_mtu[plan?.mtu ?? -1]?.monthly_billing_mrr ??
                addon.monthly_billing_mrr,
              yearly_billing_mrr:
                addon.per_mtu[plan?.mtu ?? -1]?.yearly_billing_mrr ??
                addon.yearly_billing_mrr,
              yearly_billing_arr:
                addon.per_mtu[plan?.mtu ?? -1]?.yearly_billing_arr ??
                addon.yearly_billing_arr,
            };

      const quantity = qty ?? 1;

      const defaultAddonIfNull: AddonBillingSettings = {
        key: addonKey,
        name: addonInfos[addonKey].name,

        old_products: [],
        product: "cannot-be-purchased",

        per_mtu: {},
        monthly_billing_mrr: 0,
        monthly_billing_arr: 0,
        yearly_billing_mrr: 0,
        yearly_billing_arr: 0,
      };

      return {
        addon: addon ?? defaultAddonIfNull,
        quantity: quantity,
        ...pricing,
        included,
      };
    };

    const includedAddonsCost = Object.entries(addonInfos)
      .filter(([, info]) =>
        info.includedInPlans.includes(subscriptionOptions.planType),
      )
      .map(([key]) => addonKeyToSubscriptionOptionAddon(key, 1, true, true))
      .filter((addon) => !!addon);

    const otherAddonsCost = subscriptionOptionsToAddonArray(
      subscriptionOptions,
    ).map((subscriptionOptionsAddon: subscriptionOptionsAddon) =>
      addonKeyToSubscriptionOptionAddon(
        subscriptionOptionsAddon.addon_key,
        subscriptionOptionsAddon.quantity,
      ),
    );

    const addonsCost = includedAddonsCost.concat(otherAddonsCost);

    const subtotal = {
      monthly_billing_mrr:
        planCost.monthly_billing_mrr +
        addonsCost.reduce(
          (acc, addon) => acc + addon.monthly_billing_mrr * addon.quantity,
          0,
        ),
      yearly_billing_mrr:
        planCost.yearly_billing_mrr +
        addonsCost.reduce(
          (acc, addon) => acc + addon.yearly_billing_mrr * addon.quantity,
          0,
        ),
      yearly_billing_arr:
        planCost.yearly_billing_arr +
        addonsCost.reduce(
          (acc, addon) => acc + addon.yearly_billing_arr * addon.quantity,
          0,
        ),
    };

    let couponDiscount = null;
    if (coupon && coupon.active) {
      couponDiscount = {
        coupon: coupon,
        monthly_billing_mrr: applyCouponToAmount(
          coupon,
          subtotal.monthly_billing_mrr,
        ),
        yearly_billing_mrr:
          applyCouponToAmount(coupon, subtotal.yearly_billing_arr) / 12,
        yearly_billing_arr: applyCouponToAmount(
          coupon,
          subtotal.yearly_billing_arr,
        ),
      };
    }

    const total = {
      monthly_billing_mrr:
        subtotal.monthly_billing_mrr -
        (couponDiscount?.monthly_billing_mrr ?? 0),
      yearly_billing_mrr:
        subtotal.yearly_billing_mrr - (couponDiscount?.yearly_billing_mrr ?? 0),
      yearly_billing_arr:
        subtotal.yearly_billing_arr - (couponDiscount?.yearly_billing_arr ?? 0),
    };

    return {
      plan: planCost,
      addons: addonsCost,
      subtotal: subtotal,
      coupon: couponDiscount,
      total: total,
    };
  }
}
