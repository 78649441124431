<div *ngIf="rule">
  <div class="rule-type-recurrence">
    <screeb-date-picker
      *ngIf="'survey_edition' | hasEntitlement"
      [(value)]="rule.value.v_t"
      nzPlaceHolder="Set date"
      [showTime]="true"
    ></screeb-date-picker>
    <input
      *ngIf="!('survey_edition' | hasEntitlement)"
      nz-input
      type="text"
      value="{{ rule.value.v_t | date: 'yyyy-MM-dd HH:mm' }}"
      disabled
    />
  </div>
</div>
