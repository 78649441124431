<ul nz-menu>
  <li nz-menu-item nzMatchRouter>
    <a
      routerLink="/org/{{ uiService.currentOrgId }}/{{
        uiService.surveyTypePath
      }}/create"
      [queryParams]="{ category: 'all' }"
      routerLinkActive="active"
    >
      All templates
    </a>
  </li>

  <li class="separator"></li>

  <li nz-menu-item nzMatchRouter *ngFor="let category of categories">
    <a
      routerLink="/org/{{ uiService.currentOrgId }}/{{
        uiService.surveyTypePath
      }}/create"
      [queryParams]="{ category: category }"
      routerLinkActive="active"
    >
      {{ templateCategorySpec[category].name }}
    </a>
  </li>
</ul>
