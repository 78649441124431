<div *ngIf="rule">
  <nz-select
    class="rule-type-alias-values"
    [ngClass]="{ invalid: rule.value.v_s_arr.length === 0 }"
    nzPlaceHolder="Search a user"
    nzMode="tags"
    nzShowSearch
    nzServerSearch
    [nzDisabled]="!('survey_edition' | hasEntitlement)"
    [nzDropdownMatchSelectWidth]="false"
    [nzNotFoundContent]="'User not found'"
    [nzAutoClearSearchValue]="true"
    [(ngModel)]="rule.value.v_s_arr"
    (nzOnSearch)="onSearch($event)"
  >
    <ng-container *ngFor="let r of options">
      <nz-option
        *ngIf="!isLoading"
        [nzValue]="r.id"
        [nzLabel]="getFormattedUserNameOrID(r)"
        nzCustomContent
      >
        {{ getFormattedUserNameOrID(r) }}
        <nz-tag *ngIf="r.is_anonymous">Anonymous</nz-tag>
        <span *ngIf="getFormattedUserNameOrID(r) !== r.id" class="user-id">
          ({{ r.id }})
        </span>
      </nz-option>
    </ng-container>
    <ng-container *ngFor="let r of initial">
      <nz-option
        *ngIf="!isLoading"
        [nzHide]="false"
        [nzValue]="r.id"
        [nzLabel]="getFormattedUserNameOrID(r)"
        nzCustomContent
      >
        {{ getFormattedUserNameOrID(r) }}
        <nz-tag *ngIf="r.is_anonymous">Anonymous</nz-tag>
        <span *ngIf="getFormattedUserNameOrID(r) !== r.id" class="user-id">
          ({{ r.id }})
        </span>
      </nz-option>
    </ng-container>
    <nz-option *ngIf="isLoading" nzDisabled nzCustomContent>
      <i nz-icon nzType="loading" class="loading-icon"></i>
      Loading Data...
    </nz-option>
  </nz-select>
</div>
