import { Component, Input } from "@angular/core";

import { OrgBillingInvoice } from "models/org_billing.model";
import { SuperOrgDao } from "models/super-org.dao";
import { SuperOrg } from "models/super-org.model";

@Component({
  selector: "billing-lateral-panel-invoices",
  templateUrl: "./invoices.component.html",
  styleUrls: ["./invoices.component.scss"],
})
export class SettingsBillingLateralPanelInvoicesComponent {
  @Input() superOrg: SuperOrg = null;
  @Input() orgBillingInvoices: OrgBillingInvoice[] = null;

  constructor(private superOrgDao: SuperOrgDao) {}

  public getInvoiceURL(invoice: OrgBillingInvoice): string {
    return this.superOrgDao.getInvoiceURL(this.superOrg.id, invoice.id);
  }
}
