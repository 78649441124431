import { AccountGoal } from "models/account.model";

export type GoalType = {
  title: string;
  illustration: string;
  description: string;
};

export const goals: { [key in AccountGoal]: GoalType } = {
  "collect-product-feedback": {
    title: "Collect Product feedback",
    illustration: "illustrations/attribution.svg",
    description: "collect product feedback in your product.",
  },
  "boost-user-satisfaction": {
    title: "Boost User Satisfaction",
    illustration: "illustrations/gauge-your-product-ease-of-use.svg",
    description: "boost users satisfaction in your product.",
  },
  "improve-funnels-conversion-rate": {
    title: "Improve Funnels Conversion Rate",
    illustration: "empty-screen/empty-screen-funnels.png",
    description: "improve funnels conversion rate in your product.",
  },
  "increase-user-retention": {
    title: "Increase User Retention",
    illustration: "indicators/CES-indicator-high-effort.png",
    description: "increase user retention in your product.",
  },
  "increase-product-adoption": {
    title: "Increase my product Adoption",
    illustration: "illustrations/user-experience.svg",
    description: "increase your product adoption.",
  },
  "discover-new-things-about-users": {
    title: "Discover new Things About my Users",
    illustration: "illustrations/gauge-future-intent.svg",
    description: "discover new things about your users.",
  },
};

export const goalKeys = Object.keys(goals);
