<header>
  <div class="header-title">
    <h1>Bulk assign segment by user property</h1>
  </div>
</header>

<div class="content">
  <div>
    Select the property to match:
    <selector-user-properties
      [registryEntriesIdentityProperty]="registryEntriesIdentityPropertyScreeb"
      [registryEntriesIdentityPropertyPaths]="
        registryEntriesIdentityPropertyPathsById
      "
      [(propertyKeyId)]="selectedPropertyKeyId"
    ></selector-user-properties>
  </div>

  <div>
    <textarea
      rows="10"
      nz-input
      [(ngModel)]="values"
      placeholder="steve@jobs.com&#10;elon@musk.com&#10;jeff@bezos.com"
    ></textarea>
  </div>

  <div>
    <button
      nz-button
      nzType="primary"
      nzSize="large"
      [nzLoading]="loading"
      (click)="import()"
    >
      Assign
    </button>
  </div>
</div>
