import { Pipe, PipeTransform } from "@angular/core";
import { ResponseCompletion } from "models/response.model";

@Pipe({ name: "responseCompletionColor" })
export class ResponseCompletionColorPipe implements PipeTransform {
  constructor() {}

  transform(responseCompletion: ResponseCompletion) {
    const style = getComputedStyle(document.body);
    switch (responseCompletion) {
      case "inserted":
        return "#5E21F1";
      case "not_started":
        return "#5E21F1";
      case "partially_completed":
        return style.getPropertyValue("--screeb-color-blue-700");
      case "fully_completed":
        return style.getPropertyValue("--screeb-color-blue-500");
      default:
        undefined;
    }
  }
}
