<section class="rudderstack-token-display">
  <ng-container *ngIf="contactUs">
    <p>Contact us to connect integration to your workspace.</p>
    <intercom-button
      title="Contact Us"
      borderColor="var(--screeb-color-primary)"
      backgroundColor="var(--screeb-color-primary)"
      textColor="var(--screeb-color-body-text-invert)"
    ></intercom-button>
  </ng-container>
  <div *ngIf="!contactUs && integration">
    <h2>Webhook URL</h2>
    <text-snippet-copy
      [copyText]="'https://webhook.screeb.app/webhook/rudderstack'"
    ></text-snippet-copy>

    <h2>Method: POST</h2>

    <h2>Header</h2>
    <div nz-row [nzGutter]="16">
      <div nz-col [nzSpan]="8">
        <text-snippet-copy [copyText]="'Authorization'"></text-snippet-copy>
      </div>
      <div nz-col [nzSpan]="16">
        <text-snippet-copy
          [reset]="true"
          [copyText]="'Bearer ' + integration.token"
          (onReset)="onReset()"
          (onCopy)="onCopy()"
        ></text-snippet-copy>
      </div>
    </div>

    <form
      nz-form
      [nzAutoTips]="autoTips"
      [formGroup]="validateForm"
      class="rudderstack-form"
    >
      <span *ngIf="isOverrided" class="enabled-workspace">
        <screeb-icon size="sm" icon="info-circle" />
        This setting is overridden by the workspace setting "Ignore anonymous
        users".
      </span>
      <nz-form-item>
        <nz-form-control>
          <nz-switch
            nzHasFeedback
            formControlName="ignore_anonymous"
            [nzDisabled]="isOverrided"
          ></nz-switch>
          <label for="ignore_anonymous">
            Would you like to ignore events from anonymous users?
          </label>
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
</section>
