<!-- <upgrade-modal
  position="absolute"
  [withOverlay]="true"
  [centeredModal]="true"
  [canCancel]="false"
  class="upgrade-modal"
  *ngIf="!('analytics_segment' | hasEntitlement)"
  [orgId]="org.id"
></upgrade-modal> -->
<free-trial-banner />
<div>
  <header>
    <h1>Segments</h1>

    <div class="header-right">
      <button
        *ngIf="'segment.update' | hasPermission"
        nz-button
        nzSize="large"
        [nzType]="'primary'"
        (click)="createSegment()"
      >
        <i nz-icon nzType="plus"></i>
        Create a segment
      </button>
    </div>
  </header>

  <section class="content d100">
    <div class="filters-bar">
      <div class="sources-filter">
        <nz-select
          nzShowSearch
          nzAllowClear
          nzPlaceHolder="All sources"
          nzSize="large"
          nzMode="multiple"
          [nzDropdownMatchSelectWidth]="false"
          [(ngModel)]="selectedSegmentSources"
          [nzShowArrow]="true"
          nzSuffixIcon="caret-down"
          (ngModelChange)="this.dofilterUsersGroups(keyword, $event)"
        >
          <ng-container *ngFor="let segmentSource of segmentsSources">
            <nz-option
              [nzLabel]="segmentSource.nzLabel"
              [nzValue]="segmentSource.nzValue"
            ></nz-option>
          </ng-container>
        </nz-select>
      </div>
      <div class="search-bar">
        <form
          nz-form
          (ngSubmit)="this.dofilterUsersGroups(keyword, selectedSegmentSources)"
        >
          <nz-input-group
            nzSize="large"
            [nzPrefix]="prefixIcon"
            [nzSuffix]="inputClearButton"
          >
            <input
              type="text"
              nz-input
              placeholder="Search a segment"
              [(ngModel)]="keyword"
              [ngModelOptions]="{ standalone: true }"
              (ngModelChange)="
                this.dofilterUsersGroups($event, selectedSegmentSources)
              "
            />
          </nz-input-group>
          <ng-template #prefixIcon>
            <i nz-icon nzType="search"></i>
          </ng-template>
          <ng-template #inputClearButton>
            <i
              nz-icon
              class="ant-input-clear-icon"
              nzTheme="fill"
              nzType="close-circle"
              *ngIf="keyword"
              (click)="
                keyword = '';
                this.dofilterUsersGroups('', selectedSegmentSources)
              "
            ></i>
          </ng-template>
        </form>
      </div>
    </div>

    <ng-template #noSegments>
      <nz-empty [nzNotFoundContent]="contentTpl">
        <ng-template #contentTpl>
          <div>You don't have any segment yet!</div>
          <br />
          <a routerLink="/org/{{ org.id }}/people/respondent" fragment="filter">
            <button
              nz-button
              [nzType]="'primary'"
              *ngIf="'segment.update' | hasPermission"
            >
              <i nz-icon nzType="plus"></i>
              Create your first segment
            </button>
          </a>
        </ng-template>
      </nz-empty>
    </ng-template>

    <div class="segment-table-group">
      <nz-table
        class="segments-table"
        #table
        [nzData]="filteredUserGroups"
        [nzPageSize]="pageSize"
        [nzPageIndex]="pageIndex"
        [nzTotal]="maxOffset"
        [nzShowSizeChanger]="true"
        [nzFrontPagination]="true"
        [nzHideOnSinglePage]="true"
        [nzPageSizeOptions]="[10, 25, 100]"
        [nzNoResult]="noSegments"
      >
        <thead>
          <tr>
            <th
              *ngFor="let column of listOfColumns"
              [(nzSortOrder)]="column.sortOrder"
              [nzSortFn]="column.sortFn"
              [nzSortDirections]="column.sortDirections"
              [nzFilterMultiple]="column.filterMultiple"
              [nzColumnKey]="column.sortKey"
              [nzWidth]="column.width + 'px'"
              [nzAlign]="column.align"
            >
              {{ column.name }}
            </th>
            <th [style]="{ width: '130px' }"></th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let segment of table.data">
            <a
              class="hack"
              [routerLink]="
                ('isNotDeviceTracking' | hasFeatureFlag)
                  ? '/org/' + org.id + '/people/segment/' + segment.id
                  : null
              "
            >
              <tr>
                <td nzAlign="left" class="segment-icon">
                  <div class="bold-text">
                    {{ segment.favorite ? "⭐️" : "" }}
                    {{ getFormattedUserGroupNameOrID(segment) }}
                  </div>
                  <nz-tag
                    nzSize="small"
                    *ngFor="let source of segment.sources ?? []"
                    [nzColor]="registryEntrySourceFormatted[source].color"
                  >
                    <span class="tag">
                      {{ registryEntrySourceFormatted[source].title }}
                    </span>
                  </nz-tag>
                </td>
                <td
                  nzAlign="center"
                  [title]="segment.last_at?.toLocaleString('en')"
                >
                  {{
                    segment.last_at
                      | dfnsFormatDistanceToNow: { addSuffix: true }
                  }}
                </td>

                <td
                  nzAlign="right"
                  class="actions"
                  (click)="$event.preventDefault(); $event.stopPropagation()"
                >
                  <a
                    *ngIf="'survey.create' | hasPermission"
                    id="button-segment-ask-a-question"
                    routerLink="/org/{{ org.id }}/survey/create"
                    [queryParams]="{ segmentId: segment.id }"
                    title="Ask a question to this segment"
                    nz-button
                    class="button-create"
                    nzSize="large"
                    nzType="default"
                    nzShape="round"
                  >
                    <img
                      alt="Ask a question"
                      class="icon"
                      src="/assets/icons/common/ask-a-question-purple.svg"
                    />
                  </a>
                  <a
                    id="button-segment-stats"
                    *ngIf="'isNotDeviceTracking' | hasFeatureFlag"
                    routerLink="/org/{{ org.id }}/people/segment/{{
                      segment.id
                    }}"
                    title="User"
                    nz-button
                    class="button-create"
                    nzSize="large"
                    nzType="default"
                    nzShape="round"
                  >
                    <img
                      alt="Stats"
                      nz-icon
                      src="/assets/icons/homepage/graph.svg"
                    />
                  </a>
                  <button
                    *ngIf="
                      ('registry.update' | hasPermission) ||
                      (segment.removable && ('registry.delete' | hasPermission))
                    "
                    title="More"
                    nz-button
                    class="more"
                    nzSize="large"
                    nzType="text"
                    nzShape="round"
                  >
                    <i
                      nz-icon
                      [nzType]="'more'"
                      class="segment-actions"
                      [ngClass]="{
                        opened: currentSegmentActionDropDown === segment.id,
                      }"
                      nz-dropdown
                      nzTrigger="click"
                      nzPlacement="bottomRight"
                      [nzDropdownMenu]="segmentActionsDropdown"
                      (nzVisibleChange)="
                        setCurrentSegmentActionDropDown(segment.id, $event)
                      "
                    ></i>
                  </button>

                  <nz-dropdown-menu #segmentActionsDropdown="nzDropdownMenu">
                    <ul nz-menu class="sidebar-segment-dropdown-actions-list">
                      <style>
                        /* because overlay is displayed out of component */
                        .sidebar-segment-dropdown-actions-list li.disabled {
                          background: transparent;
                          cursor: default;
                        }

                        .sidebar-segment-dropdown-actions-list a {
                          padding: 0;
                          padding-left: 10px;
                          text-align: left;
                        }

                        .sidebar-segment-dropdown-actions-list button {
                          margin-top: -5px;
                          margin-bottom: -5px;
                          padding: 0;
                        }

                        .sidebar-segment-dropdown-actions-list button i.green {
                          color: green;
                        }

                        .sidebar-segment-dropdown-actions-list button i.yellow {
                          color: #ffc046;
                        }
                      </style>
                      <li
                        nz-menu-item
                        *ngIf="'registry.update' | hasPermission"
                        (click)="onFavorite(segment, !segment.favorite)"
                      >
                        <button
                          nz-button
                          nz-tooltip="Favorite"
                          nzType="text"
                          nzShape="circle"
                        >
                          {{ segment.favorite ? "Unfavorite" : "Favorite" }}
                        </button>
                      </li>
                      <li
                        nz-menu-item
                        *ngIf="
                          segment.removable &&
                          ('registry.delete' | hasPermission)
                        "
                        (click)="onDelete(segment)"
                      >
                        <button
                          nz-button
                          nzType="text"
                          [nzLoading]="
                            getSegmentActionLoaderStatus(segment.id, 'deleting')
                          "
                        >
                          Delete
                        </button>
                      </li>
                    </ul>
                  </nz-dropdown-menu>
                </td>
              </tr>
            </a>
          </ng-container>
        </tbody>
      </nz-table>
    </div>
  </section>
</div>
