<div
  [ngClass]="{
    'link-sharing-preview': interaction === 'link',
    'email-preview': interaction === 'email',
  }"
>
  <settings-header
    title="Default designs"
    class="{{ surveyType === 'message' ? 'full-width' : '' }} {{
      interaction === 'link' ? 'link-sharing-preview-header' : ''
    }} {{ interaction === 'email' ? 'email-sharing-preview-header' : '' }}"
    description="
    Let's customize the default look and feel of your
    <b *ngIf='distributionInteractionsFormatted[interaction]'>
      {{ distributionInteractionsFormatted[interaction] }}
    </b>
    {{ surveyType }}
    <br />
    These settings will be applied to all {{
      surveyType === 'message' ? 'messages' : 'surveys'
    }} created by your team.
    "
    (save)="save()"
    [isLoading]="loading"
    [isDisabled]="hasNotChanged() || isInvalid(org.settings)"
    [error]="error"
  ></settings-header>
</div>

<tag-settings-with-preview
  [(tagSettings)]="org.settings"
  [org]="org"
  [surveyType]="surveyType"
  [interaction]="interaction"
  scope="org"
></tag-settings-with-preview>
