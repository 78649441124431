import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { PageComponentInterface } from "components/PageComponentInterface";

import { NotificationHelper } from "helpers/notification.helper";
import { Org } from "models/org.model";
import { SuperOrgDao } from "models/super-org.dao";
import { SuperOrg } from "models/super-org.model";
import { NzTableSortFn, NzTableSortOrder } from "ng-zorro-antd/table";
import { SessionService } from "services/auth.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { PermissionsService } from "services/permissions.service";
import { RoutingService } from "services/routing.service";
import { UIService } from "services/ui.service";

interface ColumnItem {
  name: string;
  sortKey?: string;
  width?: string;
  align: "left" | "right" | "center";
  sortOrder: NzTableSortOrder | null;
  sortFn: NzTableSortFn | null | true;
  filterMultiple: boolean;
  sortDirections: NzTableSortOrder[];
}

@Component({
  selector: "page-super-org-workspaces",
  templateUrl: "./workspaces.component.html",
  styleUrls: ["./workspaces.component.scss"],
})
export class WorkspacesSuperOrgPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Organization workspaces";
  public name = "Organization workspaces";

  private obs: any = null;

  public minimumMTU: number = 0;
  public superOrg: SuperOrg = null;
  public workspaces: Org[] = [];
  public workspacesTags: string[] = [];
  public filteredWorkspaces: Org[] = [];
  public isLoading: boolean = false;
  public isSaving: boolean = false;

  public keyword: string = null;
  public filteredTags: string[] = [];
  public listOfColumns: ColumnItem[] = [
    {
      name: "Name",
      width: "400px",
      align: "left",
      sortOrder: "ascend",
      sortFn: (a: Org, b: Org) => a.name.localeCompare(b.name),
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
    },
    {
      name: "MTU",
      width: "auto",
      align: "left",
      sortOrder: "ascend",
      sortFn: (a: Org, b: Org) =>
        a.entitlements.max_mtu - b.entitlements.max_mtu,
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
    },
  ];

  // Check changes
  public initialMode: "manual" | "auto" | "even" = "manual";
  public initialWorkspacesMTU: number[] = [];

  constructor(
    private route: ActivatedRoute,
    private routingService: RoutingService,
    public sessionService: SessionService,
    public featureFlaggingService: FeatureFlaggingService,
    public permissionsService: PermissionsService,
    private cdr: ChangeDetectorRef,
    private superOrgDao: SuperOrgDao,
    private notificationHelper: NotificationHelper,
    private uiService: UIService,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.obs = this.route.data.subscribe(async (data) => {
      this.superOrg = data.superOrg;
      this.initialMode = this.superOrg.entitlements.mtu_mode || "manual";
      this.workspaces = data.workspaces;
      this.workspacesTags = [
        ...new Set(
          this.workspaces
            .map((e) => e.tags)
            .flat()
            .filter((e) => e?.length),
        ),
      ];
      this.filteredWorkspaces = [...this.workspaces];
      this.initialWorkspacesMTU = this.workspaces.map(
        (workspace) => workspace.entitlements.max_mtu,
      );
      this.isLoading = false;
    });
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  public getMaxMTUPerWorkspace(): number {
    const orgMaxMTU = this.superOrg?.entitlements.max_mtu || 0;
    const minimumMTUSummed = this.minimumMTU * (this.workspaces.length - 1);
    return Math.max(orgMaxMTU - minimumMTUSummed, 0);
  }

  // Returns the total MTU allocated to all workspaces
  public getAllocatedMTU(): number {
    return this.workspaces.reduce((acc, workspace) => {
      return acc + workspace.entitlements.max_mtu;
    }, 0);
  }

  // Handle MTU change, we need to update the MTU of each workspaces
  public onMTUChange(currentWorkspace: Org, mtu: number): void {
    // Check if MTU is not exceeded after applying new mtu
    currentWorkspace.entitlements.max_mtu = Math.max(mtu, this.minimumMTU);
    const maxMTU = this.superOrg.entitlements.max_mtu || 0;
    const allocatedMTU = this.getAllocatedMTU();
    const mtuExceeded = allocatedMTU > maxMTU;
    if (!mtuExceeded) {
      if (mtu < this.minimumMTU) {
        currentWorkspace.entitlements.max_mtu = this.minimumMTU;
        this.cdr.detectChanges();
      }
      return;
    }

    // Let's decrease the MTU of others workspace evenly to make room for the diff
    // Handle case where there is not enough MTU to decrease, rest should be decreased on next workspaces
    const exceededMTU = Math.abs(maxMTU - allocatedMTU);

    // Sort workspaces to have the ones with the minimum MTU first
    const sortedWorkspaces = [...this.workspaces];
    sortedWorkspaces.sort(
      (a, b) => a.entitlements.max_mtu - b.entitlements.max_mtu,
    );

    // Decrease MTU for each workspace
    let mtuToDecrease = Math.floor(exceededMTU / (this.workspaces.length - 1));
    let remained = exceededMTU % (this.workspaces.length - 1);
    let processedWorkspaces = 0;
    for (const workspace of sortedWorkspaces) {
      if (workspace.id === currentWorkspace.id) {
        continue;
      }

      // Decrease MTU for this workspace
      const newMTU = workspace.entitlements.max_mtu - mtuToDecrease;

      if (newMTU < this.minimumMTU) {
        const diffWorkspace = this.minimumMTU - newMTU;
        mtuToDecrease += Math.floor(
          diffWorkspace / (this.workspaces.length - processedWorkspaces - 2),
        );
        remained +=
          diffWorkspace % (this.workspaces.length - processedWorkspaces - 2);
        workspace.entitlements.max_mtu = this.minimumMTU;
      } else {
        if (remained > 0) {
          const diff = newMTU - this.minimumMTU;
          if (diff > remained) {
            workspace.entitlements.max_mtu = newMTU - remained;
            remained = 0;
          } else {
            workspace.entitlements.max_mtu = this.minimumMTU;
            remained -= diff;
          }
        } else {
          workspace.entitlements.max_mtu = newMTU;
        }
      }
      processedWorkspaces += 1;
    }

    // Check if MTU is still exceeded, maybe we got a little player who found a trick to exceed MTU
    const newAllocatedMTU = this.getAllocatedMTU();
    const newMTUExceeded = newAllocatedMTU > maxMTU;
    if (newMTUExceeded) {
      // Let's decrease the current workspace
      this.cdr.detectChanges();
      const diffMTU = newAllocatedMTU - maxMTU;
      currentWorkspace.entitlements.max_mtu = mtu - diffMTU;
    }
  }

  // Handle MTU mode change, we need to update the MTU of each workspace when updated to even
  public onMTUModeChange(
    mtuMode: "manual" | "auto" | "even",
    enabled: boolean,
  ): void {
    if (enabled) {
      this.superOrg.entitlements.mtu_mode = mtuMode;

      // Let's distribute the MTU evenly
      if (mtuMode === "even") {
        const maxMTU = this.superOrg.entitlements.max_mtu || 0;
        const mtuPerWorkspace = Math.floor(maxMTU / this.workspaces.length);
        const remainder = maxMTU % this.workspaces.length;
        for (const workspace of this.workspaces) {
          workspace.entitlements.max_mtu = mtuPerWorkspace;
        }
        this.workspaces[0].entitlements.max_mtu += remainder;
      }
    }
  }

  // Check if there is unsaved changes
  public hasUnsavedChanges(): boolean {
    return (
      this.initialMode !== (this.superOrg.entitlements.mtu_mode || "manual") ||
      JSON.stringify(this.initialWorkspacesMTU) !==
        JSON.stringify(
          this.workspaces.map((workspace) => workspace.entitlements.max_mtu),
        )
    );
  }

  // Save changes
  public onSave(): void {
    this.isSaving = true;
    this.superOrgDao
      .updateMTU(
        this.superOrg.id,
        this.superOrg.entitlements.mtu_mode,
        this.workspaces.reduce((acc, workspace) => {
          acc[workspace.id] = workspace.entitlements.max_mtu;
          return acc;
        }, {}),
      )
      .then((_) => {
        this.initialMode = this.superOrg.entitlements.mtu_mode || "manual";
        this.initialWorkspacesMTU = this.workspaces.map(
          (workspace) => workspace.entitlements.max_mtu,
        );
      })
      .catch((err) => {
        console.error(err);
        this.notificationHelper.trigger(
          "An error occured while saving your changes. Please try again later.",
          null,
          "error",
        );
      })
      .finally(() => {
        this.isSaving = false;
      });
  }

  /**
   * Search
   */
  public onSearchChange(keyword: string) {
    this.onSearchChangeImmediate(keyword);
  }

  public onSearchChangeImmediate(keyword: string) {
    this.filteredWorkspaces = this.workspaces.filter((workspace) => {
      if (this.filteredTags && this.filteredTags.length > 0) {
        if (
          !this.filteredTags.every(
            (tag) => workspace.tags && workspace.tags.includes(tag),
          )
        ) {
          return false;
        }
      }

      if (keyword?.length) {
        return workspace.name.toLowerCase().includes(keyword.toLowerCase());
      }
      return true;
    });
  }

  public onTagsFilterChange(tags: string[]) {
    this.filteredTags = tags;
    this.onSearchChangeImmediate(this.keyword ?? "");
  }
}
