<header>
  <div class="header-top">
    <nz-breadcrumb>
      <nz-breadcrumb-item>
        <a routerLink="/org/{{ org.id }}/people/respondent">Analytics</a>
      </nz-breadcrumb-item>
      <nz-breadcrumb-item>
        <a routerLink="/org/{{ org.id }}/funnel/list">Funnels</a>
      </nz-breadcrumb-item>
      <nz-breadcrumb-item>
        <span>Edit funnel</span>
      </nz-breadcrumb-item>
    </nz-breadcrumb>
    <div class="header-buttons">
      <a
        title="I need help"
        nz-button
        nzSize="large"
        nzType="link"
        (click)="needHelpVisible = true"
      >
        <span nz-icon nzType="compass" nzTheme="outline"></span>
        <span>I need help</span>
      </a>
      <button
        title="Save"
        nz-button
        class="ant-btn-secondary"
        nzSize="large"
        [disabled]="!hasChanged()"
        (click)="saveFunnel()"
      >
        <i nz-icon nzType="save"></i>
        <span>Save</span>
      </button>
      <button
        routerLink="/org/{{ funnel.org_id }}/funnel/{{ funnel.id }}/report"
        nz-button
        nzType="primary"
        nzSize="large"
      >
        <img
          alt="Report"
          nz-icon
          src="/assets/icons/homepage/graph-white.svg"
        />
        Go to report
      </button>
    </div>
  </div>
  <div class="header-bottom">
    <editable-text [(value)]="funnel.title"></editable-text>
  </div>
</header>
<div class="funnel-container">
  <funnel-grid
    *ngIf="funnel"
    [(funnel)]="funnel"
    [registryEntriesEvent]="registryEntriesEvent"
    [editMode]="true"
    [surveysById]="surveysById"
    (displaySurveyClick)="onDisplaySurveyClick($event)"
  ></funnel-grid>
</div>

<funnel-help
  *ngIf="needHelpVisible"
  (closePanel)="needHelpVisible = false"
></funnel-help>
