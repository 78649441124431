import { Serializable } from "models/serializable.model";

type ChannelType = "widget" | "android" | "ios" | "hosted-page";

const channelTypes = ["widget", "android", "ios", "hosted-page"];

const ChannelTypeFormatted = {
  widget: { title: "Web", color: "#ABF3E0" },
  android: { title: "Android", color: "#12bb66" },
  ios: { title: "iOS", color: "#b4e2cd" },
  "hosted-page": { title: "Hosted page", color: "#5fc6e1" },
};

class Channel extends Serializable {
  constructor(
    public id?: string,
    public type?: ChannelType,
    public name?: string,

    public created_at?: Date,
    public updated_at?: Date,
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);
    return this;
  }
}

export { Channel, ChannelType, channelTypes, ChannelTypeFormatted };
