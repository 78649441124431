<tag-settings-with-preview
  class="survey-settings"
  [(tagSettings)]="tagSettings"
  [defaultTagSettings]="org.settings"
  [org]="org"
  [survey]="survey"
  [orgIntegrations]="integrations"
  [surveyIntegrations]="survey.integrations"
  [translationEnabled]="translationEnabled"
  scope="survey"
  (specificDesignSwitchChange)="specificDesignSwitchChange($event)"
  (specificTranslationEnabledSwitchChange)="
    specifictranslationEnabledSwitchChange($event)
  "
></tag-settings-with-preview>

<ng-template #pageActions>
  <div class="toolbar">
    <nz-button-group *ngIf="'survey.update' | hasPermission" nzSize="large">
      <button
        *ngIf="!getNbrResponse()"
        title="Save and next"
        nz-button
        class="actions-save ant-btn-secondary"
        nzSize="large"
        nzShape="round"
        [nzLoading]="saving"
        [disabled]="saveAndNextButtonDisabled()"
        (click)="actionOnSave()"
      >
        <span>Save and next</span>
        <screeb-icon size="sm" icon="arrow-right"></screeb-icon>
      </button>
      <button
        *ngIf="getNbrResponse()"
        title="Save"
        nz-button
        class="actions-save ant-btn-secondary"
        nzSize="large"
        nzShape="round"
        [nzLoading]="saving"
        [disabled]="saveButtonDisabled()"
        (click)="actionOnSave()"
      >
        <screeb-icon size="sm" icon="save"></screeb-icon>
        <span>Save</span>
      </button>
    </nz-button-group>
  </div>
</ng-template>
