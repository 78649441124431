import { Component, Input } from "@angular/core";
import {
  RegistryEntry,
  RegistryEntrySource,
  RegistryEntrySourceFormatted,
  sortRegistryEntries,
  sortedRegistryEntrySources,
} from "models/registry.model";
import { TargetingRule } from "models/targeting-rule.model";

@Component({
  selector: "rule-user-event-sequence",
  templateUrl: "./rule-user-event-sequence.component.html",
  styleUrls: ["./rule-user-event-sequence.component.scss"],
})
export class TargetingRuleUserEventSequenceComponent {
  @Input() public rule: TargetingRule = null;
  @Input() public registryEntriesEvent: RegistryEntry[];
  @Input() public invalid: boolean = false;
  @Input() public disabled: boolean = false;

  @Input() public eventIds: string;

  public sortedRegistryEntrySources = sortedRegistryEntrySources;
  public registryEntrySourceFormatted = RegistryEntrySourceFormatted;

  constructor() {}

  public getEventNamesBySource(source: RegistryEntrySource): RegistryEntry[] {
    return (
      this.registryEntriesEvent
        // .filter((prop) => !prop.hidden)
        .filter((prop) => {
          return prop.sources.includes(source);
        })
        .sort(sortRegistryEntries)
    );
  }

  public isValid() {
    return this.rule.value.v_s_arr.length >= 1;
  }
}
