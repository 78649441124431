/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { NotificationHelper } from "helpers/notification.helper";
import {
  AddonKey,
  advancedPlanDefaultMAU,
  billingCycleDuration,
  notFreeSubscriptionOptions,
  OrgBilling,
  OrgBillingInvoice,
  OrgBillingPaymentMethods,
  OrgBillingSubscription,
  PlanType,
  scalePlanDefaultMAU,
  subscriptionOptions,
  subscriptionSummary,
} from "models/org_billing.model";
import { SuperOrgDao } from "models/super-org.dao";
import { SuperOrg } from "models/super-org.model";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { SettingsService } from "services/settings.service";
import { TrackingEventName } from "services/trackers.events";
import { TrackersService } from "services/trackers.service";
import { UIService } from "services/ui.service";
import { assert } from "utils/assertion";
import { withRetry } from "utils/promises";
import {
  addonInfos,
  PlanTypeInfo,
  planTypeInfos,
} from "../components/billing-plan/billing-plan.data";
import {
  lateralPanelMode,
  mtuToPlanId,
  subscriptionOptionsToAddonArray,
  YearlyDiscount,
} from "../products.data";

type BillingSubscriptionDetails = {
  superOrg?: SuperOrg;
  billing: OrgBilling;
  billingPaymentMethod?: OrgBillingPaymentMethods;
  billingSubscription?: OrgBillingSubscription;
};

@Component({
  selector: "billing-lateral-panel",
  templateUrl: "./billing-lateral-panel.component.html",
  styleUrls: [
    "./billing-lateral-panel.component.scss",
    "./footer.component.scss",
    "../../../builder/components/LateralPanel/common.scss",
  ],
})
export class SettingsBillingLateralPanelComponent implements OnInit {
  // I/O
  @Input() mode: lateralPanelMode[] = null; // chain of modes
  @Input() currentSubscription: subscriptionOptions = null;
  @Input() targetedSubscription: subscriptionOptions = null;

  @Input() superOrg: SuperOrg = null;
  @Input() orgBilling: OrgBilling = null;
  @Input() orgBillingInvoices: OrgBillingInvoice[] = null;
  @Input() orgBillingPaymentMethods: OrgBillingPaymentMethods = null;
  @Input() orgBillingSubscription: OrgBillingSubscription = null;
  @Input() countries: string[][] = null;

  @Output() superOrgChange = new EventEmitter<SuperOrg>();
  @Output() orgBillingChange = new EventEmitter<OrgBilling>();
  @Output() orgBillingPaymentMethodsChange =
    new EventEmitter<OrgBillingPaymentMethods>();
  @Output() orgBillingSubscriptionChange =
    new EventEmitter<OrgBillingSubscription>();

  @Output() refreshInvoice = new EventEmitter<unknown>();
  @Output() onClose = new EventEmitter<subscriptionOptions | null>();

  public minMAU: number;
  public price: subscriptionSummary;
  public priceError: boolean = false;
  public discount: number = 0;

  public planTypeInfo: PlanTypeInfo;
  public YearlyDiscount = YearlyDiscount;

  public validationLoading: boolean = false;

  constructor(
    private superOrgDao: SuperOrgDao,
    private uiService: UIService,
    public settingsService: SettingsService,
    private notificationHelper: NotificationHelper,
    private trackersService: TrackersService,
    public featureFlaggingService: FeatureFlaggingService,
  ) {}

  ngOnInit(): void {
    this.trackEvent("Organization billing panel opened", {});

    this.onModeChange();
    this.minMAU = this.getMinimalMAU();
  }

  public get currentMode(): lateralPanelMode {
    return this.mode[0];
  }

  private onModeChange() {
    switch (this.currentMode) {
      case "address":
        break;
      case "payment_method_edit":
        break;
      case "subscription":
        this.initModeSubscription();
        break;
      case "activate_addon":
        this.initModeSubscription();
        break;
      case "change_billing_cycle":
        this.initModeSubscription();
        break;
      case "confirm_subscription":
        this.initModeSubscription();
        break;
      case "cancel":
        break;
    }
  }

  private nextMode() {
    if (this.mode.length <= 1) {
      this.onClose.emit(null);
    } else {
      this.mode.shift();
      this.onModeChange();
    }
  }

  private chainModes(modes: lateralPanelMode[]) {
    this.mode = this.mode.concat(modes);
  }

  /**
   * Payment method
   */
  public onPaymentMethodUpdate(
    orgBillingPaymentMethods: OrgBillingPaymentMethods,
  ) {
    this.orgBillingPaymentMethodsChange.emit(orgBillingPaymentMethods);
    this.refreshInvoice.emit();
    this.nextMode();
  }

  /**
   * Billing infos
   */
  public onBillingInfoUpdate(orgBilling: OrgBilling) {
    this.orgBillingChange.emit(orgBilling);
    this.nextMode();
  }

  /**
   * Subscription
   */
  public initModeSubscription() {
    if (this.targetedSubscription?.planType) {
      this.planTypeInfo = planTypeInfos[this.targetedSubscription.planType];
    }
  }

  // Dark pattern: do not allow customers to downgrade to fewer MTU without contacting support.
  public getMinimalMAU(): number {
    if (this.currentSubscription?.planType === "free") {
      return scalePlanDefaultMAU;
    } else if (this.orgBilling.billing_status === "trial") {
      // Most of users signup with the default 10k MTU plan.
      // We would rather not use the dark pattern for this case.
      return this.planTypeInfo.type === "scale"
        ? scalePlanDefaultMAU
        : advancedPlanDefaultMAU;
    }

    return this.currentSubscription.mtu ?? 0;
  }

  public refreshSubscriptionOptions() {
    this.targetedSubscription = { ...this.targetedSubscription };
  }

  public subscriptionNeedsMoreSteps(): boolean {
    // @TODO: we should also check payment method validity
    // I don't check if user already provided address.
    // @TODO: i don't check anymore if we have an inactive billing status, because i could loop.

    const missingPaymentMethod =
      this.orgBillingPaymentMethods.payment_method.length === 0;
    const missingAddress = (this.orgBilling.country ?? "") === "";

    return (
      (missingPaymentMethod || missingAddress) &&
      this.targetedSubscription.planType !== "free"
      // || this.orgBilling.billing_status === 'no_billing'
    );
  }

  private subscriptionNextStep() {
    this.chainModes(["address", "payment_method_edit", "confirm_subscription"]);
    this.nextMode();
  }

  public async subscribe() {
    if (this.subscriptionNeedsMoreSteps()) {
      this.subscriptionNextStep();
      return;
    }

    assert(
      this.targetedSubscription.planType === "scale" ||
        this.targetedSubscription.planType === "advanced",
    );

    const plan = mtuToPlanId(
      this.targetedSubscription.planType,
      this.settingsService.billing,
      this.targetedSubscription.mtu,
    );
    const billingCycle = this.targetedSubscription.billingCycle ?? "month";
    const addons = subscriptionOptionsToAddonArray(this.targetedSubscription);

    assert(plan !== null);

    const now = new Date();
    this.validationLoading = true;

    this.superOrgDao
      .updateSubscription(
        this.superOrg.id,
        plan.product,
        billingCycle,
        addons,
        this.targetedSubscription.coupon,
      )
      .then(async () => {
        this.trackersService.linkedinTrackConversion(10979394);
        this.trackersService.gtmTrackConversion("subscription");
      })
      .then(() => {
        this.waitForSubscription(now);
      })
      .catch(() => {
        this.notificationHelper.trigger(
          "An error occured when selecting plan.",
          null,
          "error",
        );
        this.validationLoading = false;
      });
  }

  public onPriceUpdated(price: subscriptionSummary) {
    this.price = price;
    this.priceError = false;

    this.updateDiscount();
  }

  public async waitForSubscription(date: Date = new Date()) {
    withRetry(this.getBillingSubscriptionDetails(date), 10, 1000)
      .then((details: BillingSubscriptionDetails) => {
        this.superOrg = details.superOrg;
        this.superOrgChange.emit(details.superOrg);
        this.orgBilling = details.billing;
        this.orgBillingChange.emit(details.billing);
        this.orgBillingPaymentMethods = details.billingPaymentMethod;
        this.orgBillingPaymentMethodsChange.emit(details.billingPaymentMethod);
        this.orgBillingSubscription = details.billingSubscription;
        this.orgBillingSubscriptionChange.emit(details.billingSubscription);

        if (
          details.billing.billing_status === "ok" ||
          details.billing.billing_status === "trial"
        ) {
          if (
            details.billing.billing_status !== "trial" &&
            !!details.billingSubscription?.cancel_at
          ) {
            this.notificationHelper.trigger(
              "Your subscription will canceled at the end of the current period.",
              null,
              "success",
              15,
            );
          } else {
            this.notificationHelper.trigger(
              "You have successfully subscribed to Screeb! An invoice has been generated, you can download it in the “Invoices” section. Thank you for trusting Screeb!",
              null,
              "success",
              15,
            );
          }
        } else if (details.billing.billing_status === "paused") {
          this.notificationHelper.trigger(
            "Subscription paused. Please check the payment method.",
            null,
            "warning",
            30,
          );
        } else if (details.billing.billing_status === "no_billing") {
          this.notificationHelper.trigger(
            "Subscription canceled.",
            null,
            "warning",
            30,
          );
        }
      })
      .catch((data: BillingSubscriptionDetails) => {
        this.orgBillingChange.emit(data.billing); // refresh anyway, with the last orgBilling
        this.notificationHelper.trigger(
          "Subscription will be processed in a short moment. Please refresh the page.",
          // "Please contact us in case of issue.",
          null,
          "warning",
          20,
        );
      })
      .finally(() => {
        this.refreshInvoice.emit();
        this.onClose.emit(this.targetedSubscription);
        this.validationLoading = false;
      });
  }

  private getBillingSubscriptionDetails(date: Date) {
    return async (): Promise<BillingSubscriptionDetails> => {
      const orgBilling = await this.superOrgDao.getBillingBySuperOrgId(
        this.superOrg.id,
      );

      if (orgBilling.updated_at > date) {
        const superOrg = await this.superOrgDao.getById(this.superOrg.id);
        const orgBillingPaymentMethod =
          await this.superOrgDao.getPaymentMethodBySuperOrgId(this.superOrg.id);
        const orgBillingSubscription =
          await this.superOrgDao.getBillingSubscriptionBySuperOrgId(
            this.superOrg.id,
          );

        this.uiService.fetchEverything();

        return Promise.resolve({
          superOrg: superOrg,
          billing: orgBilling,
          billingPaymentMethod: orgBillingPaymentMethod,
          billingSubscription: orgBillingSubscription,
        } as BillingSubscriptionDetails);
      }

      return Promise.reject({
        billing: orgBilling,
      } as BillingSubscriptionDetails);
    };
  }

  // private async getBillingSubscriptionDetails(): Promise<BillingSubscriptionDetails> {
  //   const orgBilling = await this.orgDao.getBillingByOrgId(this.org.id);

  //   // @TODO: To detect the subscription has changed, we check if plan+duration is updated.
  //   // We don't check MTU, addons...
  //   const productHasChanged = !deepEqual(
  //     orgBilling.expected_metadata,
  //     this.orgBilling.expected_metadata
  //   );
  //   const cycleHasChanged =
  //     orgBilling.billing_cycle_duration !==
  //     this.orgBilling.billing_cycle_duration;
  //   const trialHasChanged =
  //     orgBilling.billing_trial_end?.getTime() !==
  //     this.orgBilling.billing_trial_end?.getTime();

  //   if (productHasChanged || cycleHasChanged || trialHasChanged) {
  //     const org = await this.orgDao.getById(this.org.id);
  //     const orgBillingSubscription =
  //       await this.orgDao.getBillingSubscriptionByOrgId(this.org.id);

  //     return Promise.resolve({
  //       org: org,
  //       billing: orgBilling,
  //       billingSubscription: orgBillingSubscription,
  //     });
  //   }

  //   return Promise.reject({
  //     billing: orgBilling,
  //   });
  // }

  public addWorkspaceInputClick($event: MouseEvent) {
    $event.preventDefault();
    $event.stopImmediatePropagation();
    this.refreshSubscriptionOptions();
  }

  public addWorkspaceClick($event: MouseEvent) {
    $event.preventDefault();
    $event.stopImmediatePropagation();

    const newValue = (this.targetedSubscription as notFreeSubscriptionOptions)
      .addons.additionalWorkspaces
      ? 0
      : 1;

    (
      this.targetedSubscription as notFreeSubscriptionOptions
    ).addons.additionalWorkspaces = newValue;

    this.refreshSubscriptionOptions();
  }

  private updateDiscount() {
    if (!this.price) {
      return;
    }

    this.discount = Math.round(
      this.price.subtotal.monthly_billing_mrr -
        this.price.subtotal.yearly_billing_mrr,
    );
  }

  private trackEvent(name: TrackingEventName, props: object) {
    const superOrg = { ...this.superOrg, org_billing: { ...this.orgBilling } };
    this.trackersService
      .newEventTrackingBuilder(name)
      .withSuperOrg(superOrg as unknown as SuperOrg)
      .withProps({
        current_subscription_options: this.currentSubscription,
        targeted_subscription_options: this.targetedSubscription,
        mode: this.currentMode,
        ...props,
      })
      .build();
  }

  public trackEventBillingCycle(billingCycleDuration: billingCycleDuration) {
    switch (billingCycleDuration) {
      case "month":
        this.trackEvent("Organization billing monthly billing selected", {});
        break;
      case "year":
        this.trackEvent("Organization billing annual billing selected", {});
        break;
    }
  }

  public trackEventBillingPlanType(planType: PlanType) {}

  public trackEventMTU(mtu: number) {
    this.trackEvent("Organization billing MTU selected", { mtu });
  }

  public isAddonAvailableForPlan(addonKey: AddonKey): boolean {
    return addonInfos[addonKey]?.suggestedForPlans.includes(
      this.targetedSubscription.planType,
    );
  }
}
