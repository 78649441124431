import { Component, Input } from "@angular/core";

@Component({
  selector: "column",
  templateUrl: "./Column.component.html",
  styleUrls: ["./Column.component.scss"],
})
export class ColumnComponent {
  constructor() {}

  @Input()
  columnIndex: number;

  @Input()
  marginTop: number;

  @Input()
  hasMargin: boolean;

  public get classes(): string[] {
    return ["Column", `Column-${this.columnIndex}`];
  }
}
