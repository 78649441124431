import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";

import { Org } from "models/org.model";
import { SurveyDistribution } from "models/survey-distribution.model";
import { Survey } from "models/survey.model";
import { ConfigService } from "services/config.service";
import { EntitlementService } from "services/entitlement.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { TrackingEventName } from "services/trackers.events";
import { TrackersService } from "services/trackers.service";
import { UIService } from "services/ui.service";

@Component({
  selector: "page-survey-share-link",
  templateUrl: "./link.component.html",
  styleUrls: ["./link.component.scss"],
})
export class LinkShareSurveyPageComponent implements OnInit, OnChanges {
  @Input() public org: Org = null;
  @Input() public survey: Survey = null;
  @Input() public surveyDistribution: SurveyDistribution = null;
  @Input() public multiple: boolean = false;

  @Output() public multipleChange = new EventEmitter<boolean>();

  public url: string;
  public baseUrl: string;

  constructor(
    public uiService: UIService,
    public trackersService: TrackersService,
    public featureFlaggingService: FeatureFlaggingService,
    public entitlementService: EntitlementService,
    private configService: ConfigService,
  ) {}

  ngOnInit() {
    this.baseUrl = this.setupURL();
    this.url = this.baseUrl;
  }

  ngOnChanges({ org, survey, surveyDistribution }: SimpleChanges) {
    if (
      (org && org.currentValue !== org.previousValue) ||
      (survey && survey.currentValue !== survey.previousValue) ||
      (surveyDistribution &&
        surveyDistribution.currentValue !== surveyDistribution.previousValue)
    ) {
      this.baseUrl = this.setupURL();
      this.url = this.baseUrl;
    }
  }

  private setupURL() {
    if (this.surveyDistribution.interaction) {
      return `${this.configService.config.hostedEndpoint}/2/${this.surveyDistribution.channel_id}/${this.surveyDistribution.id}/${this.surveyDistribution.survey_id}`;
    }
    return `${this.configService.config.hostedEndpoint}/1/${this.surveyDistribution.channel_id}/${this.surveyDistribution.survey_id}`;
  }

  public onLinkCopy() {
    this.eventTracking("Survey URL copied");
  }

  public onLinkOpened() {
    this.eventTracking("Survey URL opened");
  }

  public onQRCodeOpened() {
    this.eventTracking("Survey URL QRCode opened");
  }

  private eventTracking(eventName: TrackingEventName) {
    this.trackersService
      .newEventTrackingBuilder(eventName)
      .withOrg(this.org)
      .withSurvey(this.survey)
      .build();
  }
}
