<div *ngIf="rule">
  <nz-select
    nzMode="tags"
    class="rule-type-app-version-values"
    [ngClass]="{ invalid: rule.value.v_s_arr.length === 0 }"
    [(ngModel)]="rule.value.v_s_arr"
    [nzDisabled]="!('survey_edition' | hasEntitlement)"
    nzPlaceHolder="Value"
    [nzDropdownMatchSelectWidth]="false"
    nzDropdownClassName="filters-select-value"
  >
    <nz-option-group [nzLabel]="'Android'" *ngIf="androidVersions.length > 0">
      <nz-option
        *ngFor="let clientVersion of androidVersions"
        [nzLabel]="clientVersion.app_version"
        [nzValue]="clientVersion.app_version"
      ></nz-option>
    </nz-option-group>
    <nz-option-group [nzLabel]="'iOS'" *ngIf="iosVersions.length > 0">
      <nz-option
        *ngFor="let clientVersion of iosVersions"
        [nzLabel]="clientVersion.app_version"
        [nzValue]="clientVersion.app_version"
      ></nz-option>
    </nz-option-group>
  </nz-select>
</div>
