import { ChartDataset } from "chart.js";
import { max, min } from "date-fns";
import { AnalyticsQueryResponse } from "models/analytics.filters.type";
import { AnalyticsResponse } from "models/analytics.model";
import { deepCopy } from "utils/object";
import {
  bucketToCountPerKey,
  bucketToDatedAggregation,
  computePercentage,
  computeVariation,
} from "../indicators/indicator.utils";

const responsesEmotions = ["joy", "fear", "sadness", "anger"] as const;
const responsesSentiments = ["positive", "neutral", "negative"] as const;

export type OverallEmotionPerformancesAggregation = {
  joy: number;
  sadness: number;
  fear: number;
  anger: number;
  firstResponseDate?: Date;
  lastResponseDate?: Date;
  date?: Date;
};

export type OverallSentimentPerformancesAggregation = {
  positivityRate: number;
  neutralityRate: number;
  negativityRate: number;
  positive: number;
  neutral: number;
  negative: number;
  firstResponseDate?: Date;
  lastResponseDate?: Date;
  date?: Date;
};

function getDateEmotionBuckets(record: Record<string, any>) {
  return record?.emotions ?? [];
}

function getDateSentimentBuckets(record: Record<string, any>) {
  return record?.emotions?.sentiment?.buckets ?? [];
}

function getDateEmotionSupportBuckets(
  record: Record<string, any>,
  emotion: "joy" | "fear" | "sadness" | "anger",
) {
  return Object.entries(getDateEmotionBuckets(record))
    .map(([key, bucket]) => ({
      key,
      doc_count: bucket["avg"] ? bucket["avg"]["value"] : 0,
    }))
    .filter(({ key }) => key === emotion);
}

function getDateSentimentSupportBuckets(
  record: Record<string, any>,
  sentiment: "positive" | "neutral" | "negative",
) {
  return getDateSentimentBuckets(record)
    .map((bucket) => ({
      key: bucket["key"],
      doc_count: bucket["doc_count"] ?? 0,
    }))
    .filter(({ key }) => key === sentiment);
}

export function getAggregations(
  lastFilters: AnalyticsQueryResponse,
  nbrDateBucket: number,
): AnalyticsQueryResponse {
  lastFilters = deepCopy(lastFilters);
  return {
    ...lastFilters,
    aggregation: [
      {
        by: "by_date",
        params: {
          date_histogram_min_interval: "day",
          date_histogram_buckets: nbrDateBucket,
          date_histogram_timezone_offset: -new Date().getTimezoneOffset(),
        },
      },
      { by: "by_emotions" },
    ],
    size: 0,
    having_answer_only: true,
  };
}

export function computeOverallPerformancesPeriod(
  aggregationPerDate: OverallEmotionPerformancesAggregation[],
): OverallEmotionPerformancesAggregation {
  const joyResponses = aggregationPerDate.reduce(
    (acc: number, { joy }: OverallEmotionPerformancesAggregation): number =>
      acc + joy,
    0,
  );
  const sadnessResponses = aggregationPerDate.reduce(
    (acc: number, { sadness }: OverallEmotionPerformancesAggregation): number =>
      acc + sadness,
    0,
  );
  const fearResponses = aggregationPerDate.reduce(
    (acc: number, { fear }: OverallEmotionPerformancesAggregation): number =>
      acc + fear,
    0,
  );
  const angerResponses = aggregationPerDate.reduce(
    (acc: number, { anger }: OverallEmotionPerformancesAggregation): number =>
      acc + anger,
    0,
  );

  const firstResponseDate = min(
    aggregationPerDate.map(({ firstResponseDate }) => firstResponseDate),
  );

  const lastResponseDate = max(
    aggregationPerDate.map(({ lastResponseDate }) => lastResponseDate),
  );

  return {
    joy: joyResponses,
    sadness: sadnessResponses,
    fear: fearResponses,
    anger: angerResponses,
    date: new Date(),
    firstResponseDate,
    lastResponseDate,
  };
}

export function computeOverallEmotionPerformancesVariationBewteenPeriod(
  previousPeriod: OverallEmotionPerformancesAggregation,
  currentPeriod: OverallEmotionPerformancesAggregation,
): OverallEmotionPerformancesAggregation {
  return {
    joy: computeVariation(previousPeriod.joy, currentPeriod.joy),
    sadness: computeVariation(previousPeriod.sadness, currentPeriod.sadness),
    fear: computeVariation(previousPeriod.fear, currentPeriod.fear),
    anger: computeVariation(previousPeriod.anger, currentPeriod.anger),
  };
}

export function bucketToOverallEmotionPerformancesAggregation(
  oneDayOverallPerformances: Record<string, any>,
): OverallEmotionPerformancesAggregation {
  const [joy, sadness, fear, anger] = responsesEmotions.map((emotion) =>
    bucketToCountPerKey(
      getDateEmotionSupportBuckets(oneDayOverallPerformances, emotion),
      responsesEmotions,
    ),
  );

  const joyResponses = joy.total;

  const sadnessResponses = sadness.total;

  const fearResponses = fear.total;

  const angerResponses = anger.total;

  const date = oneDayOverallPerformances.date;

  return {
    joy: joyResponses,
    sadness: sadnessResponses,
    fear: fearResponses,
    anger: angerResponses,
    date,
    firstResponseDate: date,
    lastResponseDate: date,
  };
}

export function computeOverallSentimentPerformancesVariationBewteenPeriod(
  previousPeriod: OverallSentimentPerformancesAggregation,
  currentPeriod: OverallSentimentPerformancesAggregation,
): OverallSentimentPerformancesAggregation {
  return {
    positivityRate: computeVariation(
      previousPeriod.positivityRate,
      currentPeriod.positivityRate,
    ),
    neutralityRate: computeVariation(
      previousPeriod.neutralityRate,
      currentPeriod.neutralityRate,
    ),
    negativityRate: computeVariation(
      previousPeriod.negativityRate,
      currentPeriod.negativityRate,
    ),
    positive: computeVariation(previousPeriod.positive, currentPeriod.positive),
    neutral: computeVariation(previousPeriod.neutral, currentPeriod.neutral),
    negative: computeVariation(previousPeriod.negative, currentPeriod.negative),
  };
}

export function bucketToOverallSentimentPerformancesAggregation(
  oneDayOverallPerformances: Record<string, any>,
): OverallSentimentPerformancesAggregation {
  const total = bucketToCountPerKey(
    getDateSentimentBuckets(oneDayOverallPerformances),
    responsesSentiments,
  );

  const [positive, neutral, negative] = responsesSentiments.map((sentiment) =>
    bucketToCountPerKey(
      getDateSentimentSupportBuckets(oneDayOverallPerformances, sentiment),
      responsesSentiments,
    ),
  );

  const positiveResponses = positive.total;

  const neutralResponses = neutral.total;

  const negativeResponses = negative.total;

  const date = oneDayOverallPerformances.date;

  return {
    positivityRate: computePercentage(total.countPerKey.positive, total.total),
    neutralityRate: computePercentage(total.countPerKey.neutral, total.total),
    negativityRate: computePercentage(total.countPerKey.negative, total.total),
    positive: positiveResponses,
    neutral: neutralResponses,
    negative: negativeResponses,

    date,
    firstResponseDate: date,
    lastResponseDate: date,
  };
}

export function overallEmotionPerformancesPerDateToChartDataset(
  overallPerformancesPerDate: OverallEmotionPerformancesAggregation[],
): ChartDataset[] {
  const overallPerformancesToData = (
    field: "joy" | "sadness" | "fear" | "anger",
  ) =>
    overallPerformancesPerDate.map((overallPerformances) => {
      return {
        x: +overallPerformances.date,
        y: overallPerformances[field] * 100,
      };
    });
  const style: ChartDataset = {
    yAxisID: "main",
    fill: "transparent",
    data: [],
  };

  return [
    {
      ...style,
      yAxisID: "secondary",
      label: "Joy",
      borderColor: "#1ED5A4",
      pointHoverBorderColor: "#FFFFFF",
      pointBackgroundColor: "#1ED5A4",
      pointHoverBackgroundColor: "#1ED5A4",
      data: overallPerformancesToData("joy"),
    },
    {
      ...style,
      yAxisID: "secondary",
      label: "Sadness",
      borderColor: "#502C9E",
      pointHoverBorderColor: "#FFFFFF",
      pointBackgroundColor: "#502C9E",
      pointHoverBackgroundColor: "#502C9E",
      data: overallPerformancesToData("sadness"),
    },
    {
      ...style,
      label: "Fear",
      borderColor: "#FFB546",
      pointHoverBorderColor: "#FFFFFF",
      pointBackgroundColor: "#FFB546",
      pointHoverBackgroundColor: "#FFB546",
      data: overallPerformancesToData("fear"),
    },
    {
      ...style,
      label: "Anger",
      borderColor: "#F11672",
      pointHoverBorderColor: "#FFFFFF",
      pointBackgroundColor: "#F11672",
      pointHoverBackgroundColor: "#F11672",
      data: overallPerformancesToData("anger"),
    },
  ];
}

export function overallSentimentPerformancesPerDateToChartDataset(
  overallPerformancesPerDate: OverallSentimentPerformancesAggregation[],
): ChartDataset[] {
  const overallPerformancesToData = (
    field: "positivityRate" | "neutralityRate" | "negativityRate",
  ) =>
    overallPerformancesPerDate.map((overallPerformances) => {
      return {
        x: +overallPerformances.date,
        y: overallPerformances[field],
      };
    });
  const style: ChartDataset = {
    yAxisID: "main",
    fill: "transparent",
    data: [],
  };

  return [
    {
      ...style,
      yAxisID: "secondary",
      label: "Positive",
      borderColor: "#1ED5A4",
      pointHoverBorderColor: "#FFFFFF",
      pointBackgroundColor: "#1ED5A4",
      pointHoverBackgroundColor: "#1ED5A4",
      data: overallPerformancesToData("positivityRate"),
    },
    {
      ...style,
      yAxisID: "secondary",
      label: "Neutral",
      borderColor: "#502C9E",
      pointHoverBorderColor: "#FFFFFF",
      pointBackgroundColor: "#502C9E",
      pointHoverBackgroundColor: "#502C9E",
      data: overallPerformancesToData("neutralityRate"),
    },
    {
      ...style,
      label: "Negative",
      borderColor: "#F11672",
      pointHoverBorderColor: "#FFFFFF",
      pointBackgroundColor: "#F11672",
      pointHoverBackgroundColor: "#F11672",
      data: overallPerformancesToData("negativityRate"),
    },
  ];
}

export async function getResponseCallback(
  previousPeriod: AnalyticsResponse,
  currentPeriod: AnalyticsResponse,
  allTimePeriod: AnalyticsResponse,
) {
  const {
    min_date: { value_as_string: minDate },
    max_date: { value_as_string: maxDate },
  } = allTimePeriod.aggregations;

  const overallPerformancesPerDatePreviousPeriod = bucketToDatedAggregation(
    previousPeriod,
    bucketToOverallEmotionPerformancesAggregation,
  );

  const overallPerformancesPerDateCurrentPeriod = bucketToDatedAggregation(
    currentPeriod,
    bucketToOverallEmotionPerformancesAggregation,
  );

  const overallSentimentPerformancesPerDateCurrentPeriod =
    bucketToDatedAggregation(
      currentPeriod,
      bucketToOverallSentimentPerformancesAggregation,
    );

  const emotionTrendChartDataset =
    overallEmotionPerformancesPerDateToChartDataset(
      overallPerformancesPerDateCurrentPeriod,
    );
  const sentimentTrendChartDataset =
    overallSentimentPerformancesPerDateToChartDataset(
      overallSentimentPerformancesPerDateCurrentPeriod,
    );

  const overallPerformancesPreviousPeriod = computeOverallPerformancesPeriod(
    overallPerformancesPerDatePreviousPeriod,
  );
  const overallPerformancesCurrentPeriod = computeOverallPerformancesPeriod(
    overallPerformancesPerDateCurrentPeriod,
  );

  const overallPerformancesVariation =
    computeOverallEmotionPerformancesVariationBewteenPeriod(
      overallPerformancesPreviousPeriod,
      overallPerformancesCurrentPeriod,
    );

  overallPerformancesCurrentPeriod.firstResponseDate = new Date(minDate);
  overallPerformancesCurrentPeriod.lastResponseDate = new Date(maxDate);

  return {
    overallPerformancesPerDatePreviousPeriod,
    overallPerformancesPerDateCurrentPeriod,
    emotionTrendChartDataset,
    sentimentTrendChartDataset,
    overallPerformancesPreviousPeriod,
    overallPerformancesCurrentPeriod,
    overallPerformancesVariation,
  };
}
