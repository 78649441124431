<section>
  <div *ngIf="integration">
    <h2>Connection</h2>

    <integration-settings-oauth
      [org]="org"
      [integration]="integration"
      [confirmMessage]="
        'Now, you can go to the survey reports section to configure some report sharing.'
      "
    ></integration-settings-oauth>
  </div>
</section>
