import { Component, Input, OnInit } from "@angular/core";
import {
  IntegrationSpec,
  integrationSpecs,
  IntegrationType,
} from "models/integrations.model";
import { Post101 } from "components/utils/card-101-link/card-101-link.model";

@Component({
  selector: "integration-instructions",
  templateUrl: "./integration-instructions.component.html",
  styleUrls: ["./integration-instructions.component.scss"],
})
export class IntegrationInstructionsComponent implements OnInit {
  @Input() public type: IntegrationType = null;

  public integrationInfo: IntegrationSpec = null;

  public posts: Post101[];
  public videoUrl: string;

  constructor() {}

  ngOnInit() {
    this.integrationInfo = integrationSpecs[this.type];
    this.posts = integrationSpecs[this.type].posts101;
    this.videoUrl = integrationSpecs[this.type].videoUrl;
  }
}
