<section class="payment-method">
  <div *ngIf="orgBillingPaymentMethods.payment_method.length === 0">
    <nz-empty nzNotFoundContent="No payment methods"></nz-empty>
  </div>
  <nz-list>
    <div *ngFor="let pm of orgBillingPaymentMethods.payment_method">
      <nz-list-item>
        <nz-card>
          <h3>
            {{ pm.type === "card" ? "Card" : "SEPA direct debit" }}
            <div class="tags">
              <nz-tag
                *ngIf="
                  orgBillingPaymentMethods.default_payment_method?.id === pm.id
                "
                [nzColor]="'var(--screeb-color-purple-200)'"
              >
                Default
              </nz-tag>
              <nz-tag
                *ngIf="pm.type === 'card' && isCardExpired(pm)"
                [nzColor]="'var(--screeb-color-purple-200)'"
              >
                Expired
              </nz-tag>
            </div>
          </h3>

          <p *ngIf="pm.type === 'card'">
            Type: {{ pm.card.brand }}
            <br />
            Card number: **** **** **** {{ pm.card.last4 }}
            <br />
            Expiration: {{ pm.card.exp_month }}/{{ pm.card.exp_year }}
          </p>

          <p *ngIf="pm.type === 'sepa_debit'">
            Bank code: {{ pm.sepa_debit.bank_code }}
            <br />
            Branch code: {{ pm.sepa_debit.branch_code }}
            <br />
            Country: {{ isoCountry2ToLabel(pm.sepa_debit.country) }}
            <br />
            Account number: ************{{ pm.sepa_debit.last4 }}
          </p>

          <footer>
            <button
              *ngIf="
                orgBillingPaymentMethods.default_payment_method?.id !== pm.id
              "
              nz-button
              nzType="link"
              [nzLoading]="loadingPaymentMethodChange === pm.id"
              (click)="changeDefaultPaymentMethod(pm.id)"
            >
              Set as default
            </button>
            <button
              *ngIf="
                orgBillingPaymentMethods.default_payment_method?.id !== pm.id
              "
              nz-button
              nzType="text"
              nzDanger
              [nzLoading]="loadingPaymentMethodRemove === pm.id"
              (click)="removePaymentMethod(pm.id)"
            >
              Remove
            </button>
          </footer>
        </nz-card>
      </nz-list-item>
    </div>
  </nz-list>
</section>
