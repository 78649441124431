import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { OrgDao } from "models/org.dao";

const scoreDiffColor: { step: number; color: string }[] = [
  {
    color: "#F11672",
    step: -20,
  },
  {
    color: "#FFB546",
    step: 20,
  },
  {
    color: "#1ED5A4",
    step: 10000,
  },
];

@Component({
  selector: "benchmark-summary",
  templateUrl: "./benchmark-summary.component.html",
  styleUrls: ["./benchmark-summary.component.scss"],
})
export class BenchmarkSummaryComponent implements OnChanges {
  @Input() public orgId: string;
  @Input() public title: string;
  @Input() public type:
    | "NPS"
    | "CES"
    | "CSAT"
    | "RESPONSE_RATE"
    | "RESPONSE_COMPLETION_RATE";
  @Input() public companyScore: number = 0;
  @Input() public competitorScore: number = 0;
  @Input() public topPerformerScore: number = 0;
  @Input() public soon: boolean = false;
  @Input() public withObservation: boolean = true;

  public isPercentage: boolean = false;

  public observation: string = "";
  private observationAsked: boolean = false;

  public scoreDiff: string = "+0";
  public diffColor: string = "green";

  constructor(private orgDao: OrgDao) {}

  ngOnChanges({
    orgId,
    type,
    companyScore,
    competitorScore,
    topPerformerScore,
  }: SimpleChanges) {
    if (!orgId || !orgId.currentValue) return;
    if (!type || !type.currentValue) return;
    if (!companyScore || companyScore.currentValue === undefined) return;
    if (!competitorScore || companyScore.currentValue === undefined) return;
    if (!topPerformerScore || companyScore.currentValue === undefined) return;

    this.getScoreDiff();
    this.isPercentage = ["RESPONSE_RATE", "RESPONSE_COMPLETION_RATE"].includes(
      this.type,
    );

    if (this.observationAsked || !this.withObservation || this.soon) return;
    this.askObservation();
  }

  private askObservation() {
    this.observationAsked = true;
    if (["RESPONSE_RATE", "RESPONSE_COMPLETION_RATE"].includes(this.type))
      return;

    this.orgDao
      .getIndustryObservation(
        this.orgId,
        this.type,
        this.companyScore,
        this.competitorScore,
        this.topPerformerScore,
      )
      .then((observation) => {
        this.observation = observation;
      });
  }

  public getScoreDiff() {
    if (this.companyScore === undefined || this.competitorScore === undefined) {
      this.scoreDiff = "+0.0";
      this.diffColor = scoreDiffColor[1].color;
      return;
    }

    const scoreDiff = this.companyScore - this.competitorScore;
    const max = Math.abs(
      this.competitorScore !== 0 ? this.competitorScore : this.companyScore,
    );

    const diff = (scoreDiff / (max !== 0 ? max : 1)) * 100;

    const sign = diff >= 0 ? "+" : "";
    this.scoreDiff = `${sign}${diff.toFixed(1).replace(".0", "")}`;

    this.diffColor = scoreDiffColor[0].color;

    for (const { step, color } of scoreDiffColor) {
      this.diffColor = color;
      if (diff < step) break;
    }
  }
}
