<div
  class="billing-plan"
  [style]="{
    'background-color': '#FFFFFF',
  }"
>
  <div class="plan-header">
    <h2>
      {{ planTypeInfos[planType].name }}
    </h2>

    <nz-tag *ngIf="isCurrentPlanType" nzColor="#F7F7F8">Current</nz-tag>
    <nz-tag
      *ngIf="!isCurrentPlanType && isRecommendedPlan()"
      nzColor="#5E21F126"
    >
      Recommended
    </nz-tag>
  </div>

  <p class="plan-desc">{{ planTypeInfos[planType].subtitle }}</p>
  <h1 class="plan-price" *ngIf="planTypeInfos[planType]?.startPrice">
    From {{ planTypeInfos[planType].startPrice | price }}/mo
  </h1>
  <h1 class="plan-price" *ngIf="!planTypeInfos[planType]?.startPrice">
    Custom Pricing
  </h1>
  <p *ngIf="inferiorPlanType !== null" class="previous-plan-feature">
    Everything in {{ inferiorPlanType | uppercaseFirst }} plus
  </p>
  <div
    class="plan-feature"
    *ngFor="let feature of planTypeInfos[planType]?.features || []"
  >
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.7071 4.29289C14.0976 4.68342 14.0976 5.31658 13.7071 5.70711L7.70711 11.7071C7.51957 11.8946 7.26522 12 7 12C6.73478 12 6.48043 11.8946 6.29289 11.7071L2.29289 7.70711C1.90237 7.31658 1.90237 6.68342 2.29289 6.29289C2.68342 5.90237 3.31658 5.90237 3.70711 6.29289L7 9.58579L12.2929 4.29289C12.6834 3.90237 13.3166 3.90237 13.7071 4.29289Z"
        fill="#1ED5A4"
      ></path>
    </svg>
    @let strFeature =
      typeOf(feature) === "string" ? feature : feature(orgBilling);
    {{ strFeature }}
  </div>

  <div class="spacer"></div>
  <div class="trusted-by" *ngIf="planType === 'scale'">
    <div class="trusted-by-logo-container">
      <img src="/assets/external/qare.png" alt="qare" />
      <img src="/assets/external/pennylane.png" alt="pennylane" />
    </div>
  </div>
  <div class="trusted-by" *ngIf="planType === 'advanced'">
    <div class="trusted-by-logo-container">
      <img src="/assets/external/cegedim.png" alt="cegedim" />
      <img src="/assets/external/hellowork.png" alt="hellowork" />
    </div>
  </div>
  <div class="trusted-by" *ngIf="planType === 'custom'">
    <div class="trusted-by-logo-container">
      <img src="/assets/external/orange.png" alt="orange" />
      <img src="/assets/external/doctolib.png" alt="doctolib" />
    </div>
  </div>
  <ng-container *ngIf="planTypeInfos[planType].upgradeCTA">
    <button
      *ngIf="!isCurrentPlanType"
      class="upgrade-btn"
      title="Upgrade now"
      nz-button
      nzType="primary"
      nzSize="large"
      [disabled]="isCurrentPlanType"
      (click)="isSuperiorPlan() ? upgrade() : downgrade()"
    >
      <span>
        {{
          isSuperiorPlan() ? planTypeInfos[planType].upgradeCTA : "Contact us"
        }}
      </span>
    </button>
    <div *ngIf="isCurrentPlanType" style="margin-top: 43px"></div>
    <!-- <a (click)="comparePlan()" class="compare-plan-btn">
          Compare our plans
        </a> -->
  </ng-container>
</div>

<ng-template #planSummaryElement let-data="data" let-display="display">
  <div class="current-plan-summary" *ngIf="display">
    <div class="icon">
      <screeb-icon size="md" [icon]="data.icon" />
    </div>
    <div class="content">
      <div class="title">
        <p class="key">
          {{ data.title }}
          <i
            *ngIf="!!data.tooltip"
            nz-icon
            nzType="question-circle"
            nzTheme="outline"
            nz-tooltip
            [nzTooltipTitle]="data.tooltip"
          ></i>
        </p>
        <nz-tag *ngIf="data.addon" nzColor="#E4F8F2">🎟️ Add On</nz-tag>
      </div>
      <p *ngIf="!!data.value" class="value">{{ data.value }}</p>
      <p *ngIf="data.price !== undefined" class="value">
        {{ data.price | price }}
      </p>
    </div>
  </div>
</ng-template>
