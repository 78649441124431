/* eslint-disable @angular-eslint/component-class-suffix */
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { TagSettingsThemeFontFamily } from "models/tag-settings.types";
import { EntitlementService } from "services/entitlement.service";
import { UIService } from "services/ui.service";

export const fontsWithFamily: {
  key: TagSettingsThemeFontFamily;
  value: string;
}[] = [
  { key: "Inter", value: "Inter" },
  { key: "Lato", value: "Lato" },
  { key: "Liberation Sans", value: "Liberation Sans" },
  { key: "Montserrat", value: "Montserrat" },
  { key: "Nunito", value: "Nunito" },
  { key: "Open Sans", value: "Open Sans" },
  { key: "Raleway", value: "Raleway" },
  { key: "Roboto", value: "Roboto" },
  { key: "Rubik", value: "Rubik" },
  { key: "Source Sans Pro", value: "Source Sans Pro" },
  { key: "Ubuntu", value: "Ubuntu" },
  { key: "Avant Garde", value: "AvantGarde Bk BT" },
  { key: "Arial", value: "Arial MT" },
];

export const fonts = fontsWithFamily.map(({ key }) => key);

export type TagFonts = (typeof fonts)[number];

@Component({
  selector: "font-select",
  template: `
    <nz-select
      class="font-select"
      [nzPlaceHolder]="placeholder"
      [nzShowSearch]="true"
      nzSize="large"
      [nzDropdownMatchSelectWidth]="false"
      [nzCustomTemplate]="defaultTemplate"
      [(ngModel)]="ngModel"
      (ngModelChange)="ngModelChange.emit($event)"
    >
      <nz-option
        class="font-option"
        *ngFor="let font of fonts"
        nzCustomContent
        [nzLabel]="font"
        [nzValue]="font"
      >
        @let family = getFontFamily(font);
        <div [style.font-family]="family">
          {{ font }}
        </div>
      </nz-option>
    </nz-select>
    <ng-template #defaultTemplate let-selected>
      @let family = getFontFamily(ngModel);
      <div [style.font-family]="family">
        {{ ngModel }}
      </div>
    </ng-template>
  `,
  styles: [
    `
      @import "../../../../themes/fonts.scss";

      @include loadAvailableFonts();

      .font-select {
        min-width: 150px;
        width: 100%;
      }

      .font-option {
        display: flex;
        align-items: center;
        width: 100%;
      }

      ::ng-deep .ant-select-item-option-content {
        display: flex;
        align-items: center;
        width: 100%;
      }

      ::ng-deep .ant-select-selection-item {
        display: flex;
        align-items: center;
      }

      ::ng-deep .ant-select-selection-item-remove {
        margin-left: 8px;
        font-size: 12px !important;
      }
    `,
  ],
})
export class FontSelect {
  @Input() public placeholder: string = "Select font";
  @Input() public ngModel = "Rubik";

  @Output() public ngModelChange = new EventEmitter<string>();

  fonts = fonts;

  constructor(
    public entitlementService: EntitlementService,
    public uiService: UIService,
  ) {}

  public getFontFamily(font: string): string {
    return fontsWithFamily.find(({ key }) => key === font).value;
  }
}
