import { Component, Input } from "@angular/core";
import {
  AbstractControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from "@angular/forms";

@Component({
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputSelectComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: InputSelectComponent,
    },
  ],
  selector: "input-select",
  template: `
    <nz-select
      nzMode="default"
      nzShowSearch
      [nzPlaceHolder]="placeholder"
      [nzShowArrow]="true"
      nzSuffixIcon="caret-down"
      [(ngModel)]="value"
      (nzOnSearch)="onSearchInputChange($event)"
      (ngModelChange)="onNewInputChange($event)"
    >
      <nz-option
        *ngIf="newInput?.length > 0"
        [nzLabel]="newInput"
        [nzValue]="newInput"
      ></nz-option>
      <nz-option
        *ngIf="searchInput?.length > 0"
        [nzLabel]="searchInput"
        [nzValue]="searchInput"
      ></nz-option>
      <nz-option
        *ngFor="let preset of options"
        [nzLabel]="preset"
        [nzValue]="preset"
      ></nz-option>
    </nz-select>
  `,
  styleUrls: ["./input-select.component.scss"],
})
export class InputSelectComponent {
  //   @Input() formgroup: FormGroup;
  @Input() options: string[] = [];
  @Input() placeholder: string = null;

  public value: string = null;
  public searchInput: string = null;
  public newInput: string = null;

  onChange = (_: string) => {};
  onTouched = () => {};
  touched = false;

  disabled = false;

  constructor() {}

  public onSearchInputChange(value: string): void {
    this.markAsTouched();
    if (this.searchInput === value) return;

    this.searchInput = value;
  }

  public onNewInputChange(value: string): void {
    if (this.newInput === value) return;
    if (this.options.indexOf(value) !== -1) {
      this.searchInput = null;
      this.newInput = null;

      this.onChange(value);

      return;
    }

    this.newInput = value;
    this.onChange(this.newInput);
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const input = control.value;

    if (input === "") {
      return {
        required: true,
      };
    }

    return null;
  }

  writeValue(value: string) {
    this.value = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
