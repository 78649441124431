<nz-card class="qotd-container">
  <div class="qotd-header">
    <h3>Product Quote of the Day</h3>
  </div>
  <div class="qotd-body">
    <div class="quote-of-the-day">
      &#x201C;&nbsp;{{ quoteOfTheDay[0] }}&nbsp;&#x201D;
    </div>
  </div>
  <div class="quote-of-the-day-author">{{ quoteOfTheDay[1] }}</div>
</nz-card>
