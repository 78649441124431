/* eslint-disable @angular-eslint/no-output-on-prefix */
import { CdkOverlayOrigin } from "@angular/cdk/overlay";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SurveyLanguages } from "models/survey.dao.types";
import { Survey } from "models/survey.model";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { UIService } from "services/ui.service";
import { BuilderStore } from "stores/builder.store";

@Component({
  selector: "checkpoint",
  templateUrl: "./Checkpoint.component.html",
  styleUrls: ["./Checkpoint.component.scss"],
})
export class CheckpointComponent {
  constructor(
    public builderStore: BuilderStore,
    public featureFlaggingService: FeatureFlaggingService,
    public uiService: UIService,
  ) {}

  @Output() onClick = new EventEmitter<any>();
  @Output() public defaultLanguageChange: EventEmitter<SurveyLanguages> =
    new EventEmitter();
  @Output() public messageURLChange: EventEmitter<void> = new EventEmitter();
  @Output() public surveyChange: EventEmitter<Survey> = new EventEmitter();
  @Output() public scenarioHistoryChange: EventEmitter<void> =
    new EventEmitter();
  @Output() public scenarioTranslated: EventEmitter<void> = new EventEmitter();
  @Output() public currentLanguageChange: EventEmitter<SurveyLanguages> =
    new EventEmitter();

  @Input() isLoading = false;
  @Input() surveyTags: string[] = [];
  @Input() defaultLanguageWarning = false;
  @Input() currentLanguageWarning = false;
  @Input() openOnInit = false;
  @Input() messageURL: string = "";

  public translationPanelOpened = false;

  public internalOnClick(origin: CdkOverlayOrigin) {
    this.onClick.emit(origin);
  }
}
