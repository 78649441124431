import { Injectable } from "@angular/core";

import { ScreebApiHelper } from "helpers/screeb-api.helper";
import { SessionService } from "services/auth.service";
import { Account, AccountFlagsOnboardingStatus } from "./account.model";

@Injectable()
export class AccountDao {
  constructor(
    private screebApiHelper: ScreebApiHelper,
    private sessionService: SessionService,
  ) {}

  public update(accountFields: {
    email?: string;
    firstname?: string;
    lastname?: string;
    phone_number?: string;
    profile_picture?: string;
    password?: string;
    lang?: string;
    flags?: object;
  }) {
    return this.screebApiHelper
      .patch<Account>(`/account/me`, accountFields)
      .toPromise()
      .then((accountJson: object) => {
        if (!accountJson) {
          return;
        }

        const account = new Account().fromJson(accountJson);

        this.sessionService.update(account);

        return account;
      });
  }

  public setFlags(flags: object): Promise<Account> {
    const body = {
      flags,
    };
    return this.screebApiHelper
      .patch<Account>(`/account/me`, body)
      .toPromise()
      .then((data: object) => {
        const account = new Account().fromJson(data);

        this.sessionService.update(account);

        return account;
      });
  }

  /**
   * Onboarding
   */
  public updateOnboardingStatus(
    onboardingStatus: AccountFlagsOnboardingStatus,
  ) {
    return this.setFlags({
      onboarding_status: onboardingStatus,
    });
  }
}
