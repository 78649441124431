<div class="org-settings">
  <settings-header
    title="Default settings"
    description="Configure the default settings for your workspace.
        <br />
        These settings will be applied to all surveys and messages created by
        your team."
    (save)="save()"
    [isLoading]="loading"
    [isDisabled]="hasNotChanged() || isInvalid(org.settings)"
    [error]="error"
  ></settings-header>

  <section>
    <tag-settings-behaviour
      [org]="org"
      [(tagSettings)]="org.settings"
      (update)="save()"
    ></tag-settings-behaviour>
  </section>
</div>
