<header>
  <h1>All displays</h1>
  <div class="analytics-filters">
    <survey-stats-filters
      reportType="all-displays"
      [canShare]="false"
      [org]="org"
      [survey]="survey"
      [reporting]="'Individual Displays'"
      [languages]="languages"
      [registryEntriesIdentityProperty]="registryEntriesIdentityProperty"
      [registryEntriesGroup]="registryEntriesGroup"
      [registryEntriesEvent]="registryEntriesEvent"
      type="response"
      [isDisplay]="true"
    ></survey-stats-filters>
  </div>
</header>

<survey-stats-individual-responses-questions-table
  [nodes]="nodes"
  [responsesCount]="displaysCount"
  [displaySurveyTitle]="false"
  [questionBodyView]="questionBodyView"
  [isDisplay]="true"
></survey-stats-individual-responses-questions-table>

<ng-template #questionBodyView let-item="item">
  <survey-stats-individual-responses-displays-table-single-question
    [org]="org"
    [survey]="survey"
    [getQuery]="getQuery"
    [nodeCorrelationId]="item.node.correlationId"
  ></survey-stats-individual-responses-displays-table-single-question>
</ng-template>
