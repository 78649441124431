<div class="picker" [ngClass]="{ loading }">
  <div
    class="preview"
    [ngClass]="['preview-' + radius]"
    [style.background-image]="
      'url(' + (imageUrl || 'assets/icons/common/default.svg') + ')'
    "
  ></div>
  <button
    (click)="upload.click()"
    [nzLoading]="loading"
    nz-button
    nzType="default"
    nzSize="large"
    class="upload-button"
  >
    Choose
  </button>
  <input
    #upload
    type="file"
    [accept]="getAllowedExtensions()"
    hidden
    (change)="onChange($event)"
  />
  <button
    *ngIf="canDelete"
    class="delete"
    nz-button
    nzType="default"
    nzSize="large"
    (click)="deleteClick()"
  >
    <i nz-icon nzType="delete" nzTheme="outline"></i>
  </button>
</div>
<div class="upload-error" *ngIf="error">{{ error }}</div>
