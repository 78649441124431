<nz-table
  class="ant-table-wrapper"
  #nzTable
  [nzData]="responses"
  [nzLoading]="loading"
  [nzPageSize]="pageSize"
  [nzPageIndex]="pageIndex"
  [nzTotal]="total"
  [nzShowSizeChanger]="true"
  [nzFrontPagination]="false"
  nzTableLayout="fixed"
  [nzHideOnSinglePage]="true"
  [nzPageSizeOptions]="[10, 25, 100, 1000]"
  (nzQueryParams)="onQueryParamsChange($event)"
  [nzShowPagination]="!noPagination"
  nzNoResult="No responses found"
>
  <thead>
    <tr>
      <th nzWidth="72px"></th>
      <th
        [nzColumnKey]="'last_answer_at'"
        [nzSortDirections]="['ascend', 'descend']"
        [(nzSortOrder)]="responseOrder"
      >
        Displays
      </th>
      <th nzWidth="60px"></th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let item of nzTable.data">
      <tr
        class="answer-header"
        [ngClass]="{ opened: !!expandedResponsesById[item.id] }"
        (click)="
          expandedResponsesById[item.id] = !expandedResponsesById[item.id]
        "
      >
        <td nzAlign="center">
          <a
            routerLink="/org/{{ org.id }}/people/respondent/{{
              item.respondent_id
            }}"
          >
            @let avatarUrl =
              getUserIcon(item.respondent_id, item.getAvatarURL());
            <img class="user-avatar" [src]="avatarUrl" />
          </a>
        </td>
        <td>
          <div class="content">
            <div class="identity">
              {{ getFormatedUserFromResponse(item) }}
            </div>
          </div>
          <div class="footer">
            <!-- Date -->
            <span class="date">
              {{ item.created_at | dfnsFormatRelativeToNow | uppercaseFirst }}
            </span>
          </div>
        </td>
        <td nzAlign="center">
          <button
            *ngIf="!expandedResponsesById[item.id]"
            nz-button
            nzType="link"
          >
            <i nz-icon nzType="down"></i>
          </button>
          <button
            *ngIf="!!expandedResponsesById[item.id]"
            nz-button
            nzType="link"
          >
            <i nz-icon nzType="up"></i>
          </button>
        </td>
      </tr>

      <tr class="answer-body" *ngIf="!!expandedResponsesById[item.id]">
        <td colspan="3">
          <div class="answer-body-inner">
            <h4>Information</h4>

            <div nz-row>
              <div nz-col nzFlex="170px" class="title">User</div>
              <div nz-col nzFlex="auto" class="tags">
                <div
                  *ngFor="let alias of item.respondent_aliases"
                  class="no-break-line"
                >
                  <nz-tag
                    class="no-copy"
                    (click)="onUserIdentifierClick(item.respondent_id)"
                    [nzColor]="'var(--screeb-color-purple-200)'"
                    nz-tooltip
                    [nzTooltipTitle]="alias"
                  >
                    <p>
                      {{
                        alias === item.respondent_id ? "Screeb " : ""
                      }}Identifier: {{ alias }}
                    </p>
                    <i
                      (click)="onHiddenFieldCopy($event, alias)"
                      nz-icon
                      nzType="copy"
                      nzTheme="outline"
                    ></i>
                  </nz-tag>
                </div>
                <div
                  class="no-break-line"
                  *ngFor="let hf of getUserInfoFromResponseHiddenFields(item)"
                >
                  <nz-tag
                    class="no-copy"
                    (click)="onHiddenFieldClick(hf.path, hf.toText())"
                    [nzColor]="'var(--screeb-color-purple-200)'"
                    nz-tooltip
                    [nzTooltipTitle]="hf.toText()"
                  >
                    <p>{{ hf.path }}: {{ hf.toText() }}</p>
                    <i
                      (click)="onHiddenFieldCopy($event, hf.toText())"
                      nz-icon
                      nzType="copy"
                      nzTheme="outline"
                    ></i>
                  </nz-tag>
                </div>
              </div>
            </div>
            <div nz-row>
              <div nz-col nzFlex="170px" class="title">Navigation</div>
              <div nz-col nzFlex="auto" class="tags">
                <div
                  class="no-break-line"
                  *ngFor="
                    let hf of getNavigationInfoFromResponseHiddenFields(item)
                  "
                >
                  <nz-tag
                    class="no-copy"
                    (click)="onHiddenFieldClick(hf.path, hf.toText())"
                    [nzColor]="'var(--screeb-color-purple-200)'"
                    nz-tooltip
                    [nzTooltipTitle]="hf.toText()"
                  >
                    <p>{{ hf.path }}: {{ hf.toText() }}</p>
                    <i
                      (click)="onHiddenFieldCopy($event, hf.toText())"
                      nz-icon
                      nzType="copy"
                      nzTheme="outline"
                    ></i>
                  </nz-tag>
                </div>
              </div>
            </div>
            <div nz-row>
              <div nz-col nzFlex="170px" class="title">
                Your user properties
              </div>
              <div nz-col nzFlex="auto" class="tags">
                <ng-container
                  *ngIf="getOtherInfoFromResponseHiddenFields(item) as fields"
                >
                  <div *ngFor="let hf of fields" class="no-break-line">
                    <nz-tag
                      class="no-copy"
                      (click)="onHiddenFieldClick(hf.path, hf.toText())"
                      [nzColor]="'var(--screeb-color-info-outline)'"
                      nz-tooltip
                      [nzTooltipTitle]="hf.toText()"
                    >
                      <p>{{ hf.path }}: {{ hf.toText() }}</p>
                      <i
                        (click)="onHiddenFieldCopy($event, hf.toText())"
                        nz-icon
                        nzType="copy"
                        nzTheme="outline"
                      ></i>
                    </nz-tag>
                  </div>

                  <a
                    *ngIf="fields.length === 0"
                    href="https://help.screeb.app/en/articles/8611571-all-about-users-properties"
                    target="_blank"
                    class="empty"
                  >
                    <i nz-icon nzType="info-circle" nzTheme="outline"></i>
                    Learn how to display user properties here
                  </a>
                </ng-container>
              </div>
            </div>
            <div
              nz-row
              *ngIf="
                item.emotions &&
                (item.emotions.joy ||
                  item.emotions.sadness ||
                  item.emotions.fear ||
                  item.emotions.anger)
              "
            >
              <div nz-col nzFlex="170px" class="title">User emotions</div>
              <div nz-col nzFlex="auto" class="tags">
                <ng-container>
                  <div class="no-break-line">
                    <nz-tag
                      class="no-copy"
                      [nzColor]="'var(--screeb-color-green-200)'"
                      nz-tooltip
                      [nzTooltipTitle]="
                        (item.emotions.joy * 5).toFixed(2).replace('.00', '')
                      "
                      *ngIf="item.emotions.joy >= 0.2"
                    >
                      joy:
                      {{
                        (item.emotions.joy * 5).toFixed(2).replace(".00", "")
                      }}/5
                      <i
                        (click)="
                          onHiddenFieldCopy(
                            $event,
                            (item.emotions.joy * 5)
                              .toFixed(2)
                              .replace('.00', '')
                          )
                        "
                        nz-icon
                        nzType="copy"
                        nzTheme="outline"
                      ></i>
                    </nz-tag>
                    <div class="no-break-line">
                      <nz-tag
                        class="no-copy"
                        [nzColor]="'var(--screeb-color-yellow-200)'"
                        nz-tooltip
                        [nzTooltipTitle]="
                          (item.emotions.sadness * 5)
                            .toFixed(2)
                            .replace('.00', '')
                        "
                        *ngIf="item.emotions.sadness >= 0.2"
                      >
                        sadness:
                        {{
                          (item.emotions.sadness * 5)
                            .toFixed(2)
                            .replace(".00", "")
                        }}/5
                        <i
                          (click)="
                            onHiddenFieldCopy(
                              $event,
                              (item.emotions.sadness * 5)
                                .toFixed(2)
                                .replace('.00', '')
                            )
                          "
                          nz-icon
                          nzType="copy"
                          nzTheme="outline"
                        ></i>
                      </nz-tag>
                    </div>
                    <div class="no-break-line">
                      <nz-tag
                        class="no-copy"
                        [nzColor]="'var(--screeb-color-blue-200)'"
                        nz-tooltip
                        [nzTooltipTitle]="
                          (item.emotions.fear * 5).toFixed(2).replace('.00', '')
                        "
                        *ngIf="item.emotions.fear >= 0.2"
                      >
                        fear:
                        {{
                          (item.emotions.fear * 5)
                            .toFixed(2)
                            .replace(".00", "")
                        }}/5
                        <i
                          (click)="
                            onHiddenFieldCopy(
                              $event,
                              (item.emotions.fear * 5)
                                .toFixed(2)
                                .replace('.00', '')
                            )
                          "
                          nz-icon
                          nzType="copy"
                          nzTheme="outline"
                        ></i>
                      </nz-tag>
                    </div>
                    <div class="no-break-line">
                      <nz-tag
                        class="no-copy"
                        [nzColor]="'var(--screeb-color-red-200)'"
                        nz-tooltip
                        [nzTooltipTitle]="
                          (item.emotions.anger * 5)
                            .toFixed(2)
                            .replace('.00', '')
                        "
                        *ngIf="item.emotions.anger >= 0.2"
                      >
                        anger:
                        {{
                          (item.emotions.anger * 5)
                            .toFixed(2)
                            .replace(".00", "")
                        }}/5
                        <i
                          (click)="
                            onHiddenFieldCopy(
                              $event,
                              (item.emotions.anger * 5)
                                .toFixed(2)
                                .replace('.00', '')
                            )
                          "
                          nz-icon
                          nzType="copy"
                          nzTheme="outline"
                        ></i>
                      </nz-tag>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
            <div nz-row>
              <div nz-col nzFlex="170px" class="title">Your tags</div>
              <div nz-col nzFlex="auto" class="tags">
                <ng-container *ngIf="getAnswerTags(item) as tags">
                  <div *ngFor="let tag of tags" class="no-break-line">
                    <nz-tag
                      class="no-copy"
                      [nzColor]="'var(--screeb-color-yellow-alpha)'"
                      nz-tooltip
                      [nzTooltipTitle]="tag"
                    >
                      <p>{{ tag }}</p>
                      <i
                        (click)="onHiddenFieldCopy($event, tag)"
                        nz-icon
                        nzType="copy"
                        nzTheme="outline"
                      ></i>
                      <i
                        (click)="
                          handleDeleteTag(
                            item.respondent_id,
                            item.id,
                            getAnswer(item)?.answer_id,
                            tag
                          )
                        "
                        nz-icon
                        nzType="close"
                        nzTheme="outline"
                      ></i>
                    </nz-tag>
                  </div>
                  <nz-tag
                    *ngIf="
                      !this.isAddingTag[item.id] &&
                      ('tagging.update' | hasPermission)
                    "
                    class="no-copy"
                    [nzColor]="'var(--screeb-color-yellow-alpha)'"
                    nz-tooltip
                    (click)="onAddTag(item.id)"
                  >
                    +
                  </nz-tag>
                  <tag-input
                    *ngIf="this.isAddingTag[item.id]"
                    [(ngModel)]="selectedAddedTag[item.id]"
                    [options]="allOrgTags"
                    placeHolder="Tag name"
                  ></tag-input>
                  <!-- TODO redirect to help for tagging -->
                  <!-- <a *ngIf="tags.length === 0" class="empty">
                    <i nz-icon nzType="info-circle" nzTheme="outline"></i>
                    Learn how to automatically display tags here
                  </a> -->
                </ng-container>
                <button
                  *ngIf="this.isAddingTag[item.id]"
                  nz-button
                  nzType="primary"
                  (click)="
                    onConfirmTag(
                      item.respondent_id,
                      item.id,
                      getAnswer(item)?.answer_id
                    )
                  "
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </ng-container>
  </tbody>
</nz-table>
