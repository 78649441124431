import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "onboarding-desktop-preview",
  template: `
    <embed
      style="width: 100%; height: 100%"
      #desktopPreview
      src="/assets/preview/preview-onboarding-desktop.svg"
    />
  `,
})
export class OnboardingDesktopPreviewComponent
  implements AfterViewInit, OnChanges
{
  @ViewChild("desktopPreview") embed: ElementRef<HTMLEmbedElement>;

  @Input()
  public firstName = "";

  @Input()
  public profilePicture = "";

  private loaded = false;
  constructor() {}

  ngAfterViewInit() {
    this.embed.nativeElement.onload = () => {
      this.setPreviewData();
      this.loaded = true;
    };
  }

  ngOnChanges({ firstName, profilePicture }: SimpleChanges) {
    if (!this.loaded) return;

    if (
      (!!firstName && firstName.previousValue !== firstName.currentValue) ||
      (!!profilePicture &&
        profilePicture.previousValue !== profilePicture.currentValue)
    ) {
      this.setPreviewData();
    }
  }

  private setPreviewData() {
    const svg = this.embed.nativeElement.getSVGDocument();

    const greetingMsg = svg.querySelector(".greeting-msg");
    const profilePicture = svg.querySelector(
      ".profile-picture",
    ) as HTMLImageElement;

    greetingMsg.innerHTML = `Good Morning <b>${this.firstName}</b>`;
    profilePicture.src = this.profilePicture;
  }
}
