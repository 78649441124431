import { Component, Input } from "@angular/core";

@Component({
  selector: "utils-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.scss"],
})
export class LoaderComponent {
  // color: blue, red, green...
  @Input() public color: string = "blue";
  // size: big, medium, small, <nothing>
  @Input() public size: string = "medium";

  constructor() {}
}
