import { Pipe, PipeTransform } from "@angular/core";
import { OrgAccountPermission } from "models/account-permissions";
import { PermissionsService } from "services/permissions.service";

@Pipe({
  name: "hasSuperPermission",
})
export class SuperPermissionPipe implements PipeTransform {
  constructor(private permissionsService: PermissionsService) {}

  transform(value: OrgAccountPermission): boolean {
    return this.permissionsService.isSuperAllowed(value);
  }
}
