/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/no-output-rename */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { integrationUpdate } from "components/integration/settings/integration-settings.component";

import { autoTips } from "helpers/form-errors.helper";
import { Integration } from "models/integrations.model";
import { Org } from "models/org.model";

@Component({
  selector: "integration-settings-contentsquare-source",
  templateUrl: "./contentsquare-source.component.html",
  styleUrls: ["./contentsquare-source.component.scss"],
})
export class IntegrationSettingsContentSquareSourceComponent implements OnInit {
  @Input() public org: Org = null;
  @Input() public integration: Integration = null;

  @Output() public valid = new EventEmitter<boolean>();
  @Output("onSettingsChange") public settings =
    new EventEmitter<integrationUpdate>();

  public validateForm: FormGroup = null;
  public autoTips = autoTips;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.validateForm = this.formBuilder.group({
      client_id: [
        this.integration?.client_id ?? "",
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(100),
        ],
      ],
      client_secret: [
        "",
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(100),
        ],
      ],
    });
    this.validateSettings();

    this.validateForm.valueChanges.subscribe(() => {
      this.validateSettings();
    });
  }

  public validateSettings() {
    this.valid.emit(this.validateForm.valid);
  }
}
