<div>
  <tag-settings-with-preview
    class="distribution-settings"
    [(tagSettings)]="tagSettings"
    [defaultTagSettings]="getDefaultDistributionSettings()"
    [org]="org"
    [survey]="survey"
    [surveyDistribution]="surveyDistribution"
    scope="distribution"
    (specificDesignSwitchChange)="specificDesignSwitchChange($event)"
  ></tag-settings-with-preview>

  <ng-template #pageActions>
    <div class="toolbar" *ngIf="'survey.distribution.update' | hasPermission">
      <nz-button-group nzSize="large">
        <button
          title="Save"
          nz-button
          nzType="default"
          nzSize="large"
          nzShape="round"
          class="actions-save"
          [disabled]="saveButtonDisabled()"
          [nzLoading]="saving"
          (click)="actionOnSave()"
        >
          <screeb-icon size="sm" icon="save"></screeb-icon>
          Save
        </button>
      </nz-button-group>
    </div>
  </ng-template>
</div>
