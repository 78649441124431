<!-- This is dirty: i duplicated this block 3 times -->

<a
  *ngIf="url && !internalLink"
  [href]="currentURL"
  target="_blank"
  (click)="onClick()"
  [ngClass]="{ inverted }"
>
  <div
    class="card"
    [ngClass]="{ noShadow }"
    [style]="{
      justifyContent: contentAlign,
      background: currentImageURL,
    }"
  >
    <div class="content">
      <img
        *ngIf="!noIcon"
        [src]="iconUrl"
        class="card-icon"
        alt="{{ preTitle }}"
      />
      <div class="title">
        <span id="pre-title" *ngIf="preTitle">
          {{ preTitle }}
          <br />
        </span>
        <span id="bold-title" [innerHTML]="title"></span>
        <span class="read-time" *ngIf="minuteRead">
          {{ minuteRead }} minutes read.
        </span>
      </div>
    </div>
    <div *ngIf="author" class="author">
      <img
        *ngIf="author.pictureUrl"
        alt="{{ author.name }}"
        [src]="author.pictureUrl"
        class="card-icon"
      />
      <div>
        <p id="bold-author-title" [innerHTML]="author.name"></p>
        <p [innerHTML]="author.description"></p>
      </div>
    </div>
  </div>
</a>

<a *ngIf="url && internalLink" (click)="onClick()" [ngClass]="{ inverted }">
  <div
    class="card"
    [ngClass]="{ noShadow }"
    [style]="{
      justifyContent: contentAlign,
      background: currentImageURL,
    }"
  >
    <div class="content">
      <img
        *ngIf="!noIcon"
        [src]="iconUrl"
        class="card-icon"
        alt="{{ preTitle }}"
      />
      <div class="title">
        <span id="pre-title" *ngIf="preTitle">
          {{ preTitle }}
          <br />
        </span>
        <span id="bold-title" [innerHTML]="title"></span>
        <span class="read-time" *ngIf="minuteRead">
          {{ minuteRead }} minutes read.
        </span>
      </div>
    </div>
    <div *ngIf="author" class="author">
      <img
        *ngIf="author.pictureUrl"
        alt="{{ author.name }}"
        [src]="author.pictureUrl"
        class="card-icon"
      />
      <div>
        <p id="bold-author-title" [innerHTML]="author.name"></p>
        <p [innerHTML]="author.description"></p>
      </div>
    </div>
  </div>
</a>

<div
  *ngIf="!url"
  class="card"
  [ngClass]="{ noShadow }"
  [style]="{
    justifyContent: contentAlign,
    background: currentImageURL,
  }"
  [ngClass]="{ inverted }"
>
  <div class="content">
    <img
      *ngIf="!noIcon"
      [src]="iconUrl"
      class="card-icon"
      alt="{{ preTitle }}"
    />
    <div class="title">
      <span id="pre-title" *ngIf="preTitle">
        {{ preTitle }}
        <br />
      </span>
      <span id="bold-title" [innerHTML]="title"></span>
      <span class="read-time" *ngIf="minuteRead">
        &nbsp;{{ minuteRead }} minutes read.
      </span>
      <div
        class="description"
        *ngIf="description"
        [innerHTML]="description"
      ></div>
    </div>
  </div>
  <div *ngIf="author" class="author">
    <img
      *ngIf="author.pictureUrl"
      alt="{{ author.name }}"
      [src]="author.pictureUrl"
      class="card-icon"
    />
    <div>
      <p id="bold-author-title" [innerHTML]="author.name"></p>
      <p [innerHTML]="author.description"></p>
    </div>
  </div>
</div>
