<survey-preview
  [surveyId]="survey.id"
  [initDistributionId]="surveyDistribution.id"
  [isFullScreen]="true"
  (onSetURL)="setURL($event)"
></survey-preview>
<ng-template #pageActions>
  <div class="toolbar">
    <nz-button-group nzSize="large">
      <button
        title="Share preview"
        nz-button
        nzType="default"
        nzSize="large"
        nzShape="round"
        [disabled]="!previewURL"
        class="actions-save"
        (click)="actionOnShare()"
      >
        <screeb-icon size="sm" icon="share"></screeb-icon>
        Share preview
      </button>
    </nz-button-group>
  </div>
</ng-template>
