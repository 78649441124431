<a
  class="user-record-preview"
  [routerLink]="
    session
      ? ['/org', session.workspace_id, 'people', 'sessions', session.id]
      : null
  "
>
  <div
    class="user-record-preview__player {{
      loading || !initialized ? 'has-shimmer' : ''
    }}"
  >
    <div
      id="rrweb-player-target"
      [class.hidden]="loading || !initialized"
      #rrwebPlayerWrapper
    ></div>
    <div
      class="user-record-preview__player-overlay"
      [class.hidden]="loading || !initialized"
    >
      <div
        class="user-record-preview__player-overlay__play"
        [class.hidden]="loading || !initialized"
        [class.seen]="!!session?.seen_at"
      >
        <screeb-icon size="md" icon="play" />
      </div>
      <div *ngIf="session?.highlights?.length" class="session-highlights">
        @let topHighlight = session.highlights.slice(0, 6);
        <ng-template #highlightTitle let-highlight="highlight">
          <span [innerHTML]="highlight.title"></span>
        </ng-template>
        <div
          class="session-highlight session-highlight__{{ highlight.type }}"
          *ngFor="let highlight of topHighlight"
          nz-tooltip
          [nzTooltipTitle]="highlightTitle"
          [nzTooltipTitleContext]="{ highlight: highlight }"
        >
          <screeb-icon
            *ngIf="highlight.type === 'response'"
            size="sm"
            icon="survey"
          ></screeb-icon>
          <screeb-icon
            *ngIf="highlight.type === 'event'"
            size="md"
            icon="events"
          ></screeb-icon>
          <ng-container *ngIf="highlight.type === 'comment'">
            @let comment = getComment(session, highlight.data);
            <ng-container *ngIf="comment.emoji?.length">
              @let emoji = emojiTranscoder(comment.emoji);
              <span [innerHtml]="emoji"></span>
            </ng-container>
            <screeb-icon
              *ngIf="!comment.emoji?.length"
              size="sm"
              icon="comment"
            ></screeb-icon>
          </ng-container>
          <screeb-icon
            *ngIf="highlight.type === 'console'"
            size="sm"
            style="color: {{
              highlight.data === 'error'
                ? '#F11672'
                : highlight.data === 'warn'
                  ? '#faad14'
                  : undefined
            }} !important"
            icon="{{
              highlight.data === 'error'
                ? 'cross-circle'
                : highlight.data === 'warn'
                  ? 'warning'
                  : 'info-circle'
            }}"
          ></screeb-icon>
        </div>
        <div
          class="session-highlight session-highlight__more"
          *ngIf="session.highlights.length > 6"
        >
          <screeb-icon size="md" icon="response" />
          <span>{{ session.highlights.length - 6 }}</span>
        </div>
      </div>
    </div>
  </div>
  <div clas="user-record-preview__infos">
    <user-simple-list
      [loading]="(isUserLoading || loading) && !user"
      [users]="user ? [user] : []"
      userNameSize="xs"
      [shouldShowArrow]="false"
      [registryEntriesGroup]="registryEntriesGroup"
      [shouldShowPresence]="true"
      [inlineGroup]="true"
      [showEmptyState]="false"
      iconSize="xs"
    ></user-simple-list>
  </div>
</a>
