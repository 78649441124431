import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { Chart, ChartData, ChartOptions } from "chart.js";
import { TreemapController, TreemapElement } from "chartjs-chart-treemap";
import { BaseChartDirective } from "ng2-charts";
import { ColorHelper } from "./color.helper";

Chart.register(TreemapController, TreemapElement);

export type TreemapData = {
  score: number;
  label: string;
}[];

@Component({
  selector: "tree-map",
  template: `
    <div
      class="chart-container"
      style="position: relative; width: 100%; height: 30%; border-radius: 16px; overflow: hidden;"
    >
      <canvas
        baseChart
        type="treemap"
        [data]="chartData"
        [options]="options"
      ></canvas>
    </div>
  `,
  styleUrls: ["./treemap.component.scss"],
})
export class TreeMapComponent implements OnInit, OnChanges {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective;

  @Input() public data: TreemapData = [];

  public sizes = { x: 0, y: 0 };

  public options: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: (ctx) => {
            const data = ctx.raw["_data"] as any;
            const label = data.label;
            const score = data.score;

            return `${label}: ${score}`;
          },
        },
      },
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
  };

  public chartData: ChartData = {
    datasets: [
      {
        data: [],
        tree: this.data,
        key: "score",
        labels: {
          display: true,
          position: "middle",
          color: (ctx) => this.getTextContrastColor(ctx),
          font: {
            size: 14,
            family: "Rubik",
          },
          padding: 8,
          formatter(ctx) {
            const data = ctx.chart.data;

            return (
              //@ts-ignore
              data.datasets[ctx.datasetIndex].data[ctx.dataIndex]?._data
                .label || ""
            );
          },
        },

        borderWidth: 0,
        spacing: 0,

        borderRadius: 0,
        backgroundColor: (ctx) => this.colorFromRaw(ctx),
      },
    ],
  };

  constructor() {}

  ngOnInit() {
    const i = setInterval(() => {
      if (!this.chart || !this.chart.chart) return;

      clearInterval(i);

      this.sizes = {
        x: this.chart?.chart?.width,
        y: this.chart?.chart?.height,
      };
      this.chart?.chart.update();

      // Resize chart on window resize because option.onResize is not working
      window.addEventListener("resize", () => {
        this.sizes = {
          x: this.chart?.chart.width,
          y: this.chart?.chart.height,
        };

        this.chart?.chart.resize();
        this.chart?.chart.update("resize");
      });
    }, 200);
  }

  ngOnChanges({ data }: SimpleChanges): void {
    if (data && data.currentValue !== data.previousValue) {
      //@ts-ignore
      this.chartData.datasets[0].tree = data.currentValue;
      this.chartData.datasets[0].data = [];

      setTimeout(() => {
        if (!!this.chart && !!this.chart.chart) {
          this.chart?.chart.update("reset");
        }
      });
    }
  }

  private colorFromRaw(ctx) {
    if (ctx.type !== "data") {
      return "transparent";
    }

    const value = ctx.raw.v;
    const alpha = this.getAlpha(value);

    const color = "#5E21F1";
    return ColorHelper.color(color).alpha(alpha).rgbString();
  }

  private getTextContrastColor(ctx) {
    if (ctx.type !== "data") {
      return "transparent";
    }

    const value = ctx.raw.v;
    const alpha = this.getAlpha(value);

    return alpha > 0.5 ? "#FFFFFF" : "#502C9E";
  }

  private getAlpha(value: number) {
    const minAlpha = 0.1;
    const maxAlpha = 1;

    const scores = this.data.map(({ score }) => score);
    const minScore = Math.min(...scores);
    const maxScore = Math.max(...scores);

    // Handle cases where all scores are the same
    if (minScore === maxScore) {
      return maxAlpha; // If all scores are the same, use maxAlpha
    }

    // Calculate the alpha value based on the score.
    const alpha =
      minAlpha +
      ((value - minScore) / (maxScore - minScore)) * (maxAlpha - minAlpha);

    return parseFloat(alpha.toFixed(2));
  }
}
