import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";

import { Response } from "models/response.model";
import { UserDao } from "models/user.dao";
import { User } from "models/user.model";

@Component({
  selector: "user-timeline",
  templateUrl: "./user-timeline.component.html",
  styleUrls: ["./user-timeline.component.scss"],
})
export class UserTimelineComponent implements OnInit, OnChanges {
  @Input() public user: User;

  public userResponsesPG: Response[] = [];
  public loadingResponsesPG = false;
  public errorResponsesPG = null;
  public initialFetchResponsesPG = true;

  constructor(private userDao: UserDao) {}

  ngOnInit(): void {
    this.fetchResponses();
  }

  ngOnChanges({ user }: SimpleChanges): void {
    if (user?.currentValue?.id !== user?.previousValue?.id) {
      this.fetchResponses();
    }
  }

  private fetchResponses() {
    this.loadingResponsesPG = true;
    this.errorResponsesPG = null;

    return this.userDao
      .getUserResponses(this.user.org_id, this.user.id, 25)
      .then((responses) => {
        this.userResponsesPG = responses.reverse();
      })
      .catch((error) => {
        this.errorResponsesPG = error;
        console.error(error);
      })
      .finally(() => {
        this.loadingResponsesPG = false;
        this.initialFetchResponsesPG = false;
      });
  }
}
