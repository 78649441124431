{
  "NODE_ENV": "prod",
  "ENV": "prod",
  "FAST_AND_FURIOUS_MODE": false,
  "PLATFORM": "global",
  "REGION": "eu",

  "HOSTNAME": "screeb.app",
  "API_ENDPOINT": "https://api.screeb.app",
  "SELF_ENDPOINT": "https://admin.screeb.app",
  "TAG_ENDPOINT": "https://t.screeb.app",
  "TAG_BYPASS_ENDPOINT": "https://t2.screeb.app",
  "WEBHOOK_ENDPOINT": "https://webhook.screeb.app/webhook",
  "HOSTED_PAGE_ENDPOINT": "https://survey.screeb.app",
  "RPC_ENDPOINT": "https://rpc.screeb.app/rpc",
  "CENTIPEDE_ENDPOINT": "wss://centipede.screeb.app",
  "STATUS_ENDPOINT": "https://status.screeb.app",

  "SESSION_COOKIE_NAME": "x-screeb-global-prod-account",

  "STRIPE_PUBLISHABLE_KEY": "pk_live_51KO3AjDzOH4e32CYiKVBuW8mteikos6lEL3nej8qblBPoQP2lnJArax9luGJ5Pd2IkjlBgcjv3GfdBdqMMCJZQm500acaaEcIG",
  "SENTRY_DSN": "https://b85b9193fa66486eacf10e4c850d1e0a@bttf.screeb.app/2",

  "SIGNUP_INVITED_ONLY": false,

  "AUTH0_DOMAIN": "auth.screeb.app",
  "AUTH0_CLIENT_ID": "aHlgFjsYYX0P0dNgZSuoaw1pB8byg4tO",

  "SCREEB_TAG_CHANNEL_ID": "6c972029-fe74-49d1-8a2a-5de00d1a9493",
  "INTERCOM_TAG_APP_ID": "f9ft79uz",
  "SEGMENT_TAG_ID": "G2N1wZb0nQiDeD6HrZfxKi8JSeBWNbzz",
  "FULLSTORY_ORG_ID": "",
  "HUBSPOT_ID": "8008267",
  "LINKEDIN_PARTNER_ID": "4613194",
  "GTM_ID": "GTM-MNRFNMP",
  "VERSION": "4efa480"
}
