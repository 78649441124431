import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";

import { ApplicationLoadService } from "services/app.service";
import { UserDao } from "models/user.dao";
import { User } from "models/user.model";

@Injectable()
export class UserResolver implements Resolve<any> {
  constructor(
    private applicationLoadService: ApplicationLoadService,
    private userDao: UserDao,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Promise<User> {
    return this.applicationLoadService
      .loader()
      .then(() => {
        return this.userDao.getUser(
          route.params["org_id"],
          route.params["respondent_id"],
        );
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        throw err;
      });
  }
}
