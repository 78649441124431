<section>
  <div nz-row>
    <div
      nz-col
      nzSpan="12"
      class="img"
      [style]="{ 'background-image': getBackgroundImage() }"
    ></div>

    <div nz-col nzSpan="12">
      <h2>{{ title }}</h2>

      <p *ngIf="!!description1">{{ description1 }}</p>
      <p *ngIf="!!description2">{{ description2 }}</p>

      <ul class="actions">
        <li *ngIf="!!secondaryButtonTxt">
          <a
            (click)="secondaryButtonCTA.emit(true)"
            nz-button
            nzType="default"
            nzSize="large"
          >
            {{ secondaryButtonTxt }}
          </a>
        </li>
        <li *ngIf="!!primaryButtonTxt && ('survey.update' | hasPermission)">
          <a
            (click)="primaryButtonCTA.emit(true)"
            nz-button
            nzType="default"
            class="ant-btn-secondary"
            nzSize="large"
          >
            {{ primaryButtonTxt }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</section>
