import { Injectable } from "@angular/core";
import {
  OrgAccountPermission,
  WorkspaceAccountPermission,
} from "models/account-permissions";
import {
  AccountPermissionFeatureKey,
  EntitlementService,
} from "./entitlement.service";
import { UIService } from "./ui.service";

/*
  When Checking for permissions, we also check if the users has the linked entitlement.
  If so, we check the Workspace permissions as usual.
  If not, we deny access.
*/
const WorkspaceAccessPerPermissions: {
  [key in AccountPermissionFeatureKey]: WorkspaceAccountPermission[];
} = {
  survey_edition: [
    "survey.create",
    "survey.update",
    "survey.delete",
    "survey.distribution.update",
    "analytics.response.delete",
    "targeting-rule.create",
    "tagging.list",
    "tagging.update",
    "tagging.delete",
    "sequence.create",
    "sequence.update",
    "sequence.delete",
    "segment.list",
    "segment.update",
    "release.create",
    "release.update",
    "release.delete",
  ],
  message_edition: [
    "survey.create",
    "survey.update",
    "survey.delete",
    "survey.distribution.update",
    "analytics.response.delete",
  ],
};

@Injectable()
class PermissionsService {
  constructor(
    private uiService: UIService,
    private entitlementService: EntitlementService,
  ) {}

  isAllowed(key: WorkspaceAccountPermission): boolean {
    // find access feature key
    const accessFeatureKey = Object.keys(WorkspaceAccessPerPermissions).find(
      (accessKey) => WorkspaceAccessPerPermissions[accessKey].includes(key),
    ) as AccountPermissionFeatureKey;

    if (!accessFeatureKey) {
      return this.uiService.currentOrg?.permissions?.includes(key) ?? false;
    }

    return (
      this.entitlementService.isAvailable(accessFeatureKey) &&
      (this.uiService.currentOrg?.permissions?.includes(key) ?? false)
    );
  }

  isSuperAllowed(key: OrgAccountPermission): boolean {
    return this.uiService.currentSuperOrg?.permissions?.includes(key) ?? false;
  }
}

export { PermissionsService };
