/* eslint-disable @angular-eslint/no-output-on-prefix */
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";

import { NzModalService } from "ng-zorro-antd/modal";

import { GraphNode } from "components/builder/flow";
import {
  ActionButton,
  ActionScoring,
  availableQuestionTypeMessage,
  CTAType,
  getI18nTextLabelTranslation,
  getI18nVideoLabelTranslation,
  messageNodeTypesSettings,
  sanitize18nFileTranslation,
  sanitize18nLabelTranslation,
  ScenarioQuestionType,
  setI18nTextLabelTranslation,
  SurveyLanguages,
  surveyNodeTypesSettings,
} from "models/survey.dao.types";
import { EntitlementService } from "services/entitlement.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { PermissionsService } from "services/permissions.service";
import { BuilderStore } from "stores/builder.store";
import { deepCopy } from "utils/object";
import { stripHtml } from "../Cards/sanitized-message/sanitizer";

export type LateralPanelEmojiPickerConfig = {
  action?: ActionScoring | ActionButton;
  positionTop: number;
  positionRight: number;
  canBeNull: boolean;
  whitelist: string[];
};

export type LateralPanelSavingPayload = {
  updatedNode: GraphNode;
};

export type LateralPanelRemovingPayload = {
  node: GraphNode;
};

@Component({
  selector: "lateral-panel",
  templateUrl: "./LateralPanel.component.html",
  styleUrls: ["./LateralPanel.component.scss", "./common.scss"],
})
export class LateralPanelComponent implements OnChanges, OnInit, OnDestroy {
  @Input() nodeId: string = null;

  @Output() onClose = new EventEmitter<Event>();
  @Output() onSave = new EventEmitter<LateralPanelSavingPayload>();
  @Output() onDelete = new EventEmitter<LateralPanelRemovingPayload>();
  @Output() goToPreviousNode = new EventEmitter();
  @Output() goToNextNode = new EventEmitter();
  @Output() openTagEditorAtIndex = new EventEmitter<number>();

  public node: GraphNode = null;
  public clonedNode: GraphNode = null;
  public messageErrors: boolean[] = [];
  public questionMode: ScenarioQuestionType = "text";
  public integrationsAreValid: boolean = true;
  public emojiPickerConfig: LateralPanelEmojiPickerConfig = null;
  public language: SurveyLanguages = "en";
  public isSuggesting: boolean = false;

  public availableQuestionTypeMessage: string[] = availableQuestionTypeMessage;

  constructor(
    public builderStore: BuilderStore,
    public modalService: NzModalService,
    public featureFlaggingService: FeatureFlaggingService,
    public permissionsService: PermissionsService,
    public entitlementService: EntitlementService,
  ) {}

  ngOnInit(): void {
    this.language = this.builderStore.currentLanguage;
  }

  ngOnChanges() {
    if (!this.nodeId) {
      this.node = null;
      this.clonedNode = null;
      this.questionMode = "text";
      this.messageErrors = [];
    } else {
      this.node = this.builderStore.nodesById[this.nodeId];
      this.clonedNode = deepCopy(this.node);

      if (this.clonedNode?.node?.question?.type === "survey") {
        this.questionMode =
          this.clonedNode.node.question.messages[0]?.type || "text";

        this.messageErrors = new Array(
          this.clonedNode.node.question.messages.length + 1,
        ).fill(false); // +1 is for call to action
      }
    }
  }

  ngOnDestroy() {
    delete this.clonedNode;
  }

  public get panelTitle(): string {
    if (
      !this.node.node.question.type ||
      this.node.node.question.type === "survey"
    ) {
      return surveyNodeTypesSettings[this.node.questionType].title;
    }
    return messageNodeTypesSettings[this.node.node.question.type].title;
  }

  /**
   * Global panel actions
   */
  public duplicate(event: Event) {
    alert("soon");
    this.onClose.emit(event);
  }

  public remove(event: Event) {
    this.onDelete.emit({ node: this.node });
    this.onClose.emit(event);
  }

  public save(event: Event) {
    this.clonedNode.node.question.description =
      this.clonedNode.node.question.description || "";

    if (this.clonedNode?.node?.question?.type === "survey") {
      this.clonedNode.node.question.messages =
        this.clonedNode.node.question.messages.map((message) => {
          switch (message.type) {
            case "text":
              return {
                ...message,
                id: message.id,
                type: message.type,
                text: sanitize18nLabelTranslation(message.text),
              };
            case "video":
              return {
                ...message,
                id: message.id,
                type: message.type,
                video: sanitize18nFileTranslation(message.video),
              };
          }
        });

      if (this.clonedNode.node.question.messages[0]) {
        const msgType = this.clonedNode.node.question.messages[0].type;
        if (msgType === "text") {
          this.clonedNode.node.question.description = stripHtml(
            getI18nTextLabelTranslation(
              this.clonedNode.node.question.messages[0].text,
              this.builderStore.survey.scenario.default_language, // always use default lang here
              this.builderStore.survey.scenario.default_language,
            ),
          );
        } else if (msgType === "video") {
          const overlay = getI18nVideoLabelTranslation(
            this.clonedNode.node.question.messages[0].video,
            this.builderStore.survey.scenario.default_language,
            this.builderStore.survey.scenario.default_language, // always use default lang here
          )?.overlay;

          this.clonedNode.node.question.description = stripHtml(
            overlay?.length > 0 ? overlay : `Question ${this.clonedNode.name}`,
          );
        }
      }

      switch (this.clonedNode.node.question?.call_to_action?.type) {
        case "multiple_choice":
        case "pmf":
          this.clonedNode.node.question.call_to_action.choices =
            this.clonedNode.node.question.call_to_action.choices.map(
              (choice) => ({
                ...choice,
                payload: {
                  ...choice.payload,
                  label: sanitize18nLabelTranslation(
                    choice.payload.label,
                    false,
                  ),
                },
              }),
            );
          break;
      }
    }

    this.onSave.emit({
      updatedNode: this.clonedNode,
    });
    this.onClose.emit(event);
  }

  /**
   * CTA => Options
   */
  public onOptionAdded() {}

  /**
   * Integrations
   */
  public isIntegrationsEnabled(): boolean {
    return (
      [
        "multiple_choice",
        "pmf",
        "scoring",
        "nps",
        "ces",
        "csat",
        "input",
        "range",
      ].includes(this.clonedNode.node.question?.call_to_action.type) &&
      this.permissionsService.isAllowed("integration.create")
    );
  }

  public isCTAType(ctaType: CTAType) {
    return this.clonedNode.node.question?.call_to_action.type === ctaType;
  }

  /**
   * Save
   */
  public saveIsDisabled() {
    if (this.isIntegrationsEnabled() && !this.integrationsAreValid) return true;

    return !!this.messageErrors.find((e) => e === true);
  }

  /**
   * GPT3
   */
  public onSuggested(question: string) {
    if (this.clonedNode?.node?.question?.type === "survey") {
      for (let i = 0; i < this.clonedNode.node.question.messages.length; i++) {
        const msg = this.clonedNode.node.question.messages[i];

        if (msg.type === "text") {
          setI18nTextLabelTranslation(msg.text, question, this.language);
          break;
        }
      }
    }

    this.isSuggesting = false;
  }

  setQuestionMode(mode: ScenarioQuestionType) {
    this.builderStore.setNodeQuestionType(this.clonedNode, mode);
    this.questionMode = mode;
  }

  public onErrorChange(event: boolean) {
    setTimeout(() => {
      if (this.clonedNode?.node?.question?.type === "survey") {
        this.messageErrors[this.clonedNode.node.question.messages.length] =
          event;
      }
    }, 0);
  }

  public onEmojiSelected(emoji: string) {
    if (!this.emojiPickerConfig) return;
    this.emojiPickerConfig.action.payload.emoji = emoji;
    this.emojiPickerConfig = null;
  }
}
