import { Component } from "@angular/core";

@Component({
  selector: "page-internal-error",
  templateUrl: "./error-page.component.html",
  styleUrls: ["./error-page.component.scss"],
})
class InternalErrorPageComponent {
  public subtitle: string = "500 - Internal error";
  public text: string =
    "This error indicates that the server encountered an unexpected condition that prevented it from fulfilling the task.";

  constructor() {}
}

export { InternalErrorPageComponent };
