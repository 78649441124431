export const accountProviderList = [
  "auth0",
  "google-oauth2",
  "office365",
] as const;

export type AccountProvider = (typeof accountProviderList)[number];

export type AccountProviderStatus = {
  provider: AccountProvider;
  linked: boolean;
  primary: boolean;
};

export type AccountProviderResult = {
  links: AccountProviderStatus[];
  canChange: boolean;
};

export type AccountProviders = {
  [key in AccountProvider]: AccountProviderStatus;
};

export const AccountProviderToLabel: { [key in AccountProvider]: string } = {
  auth0: "Email",
  "google-oauth2": "Google",
  office365: "Microsoft",
};

export const AccountProviderToConnection: { [key in AccountProvider]: string } =
  {
    auth0: "Username-Password-Authentication",
    "google-oauth2": "google-oauth2",
    office365: "windowslive",
  };
