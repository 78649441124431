<ng-keyboard-shortcuts
  [shortcuts]="shortcuts"
  *ngIf="_document.activeElement === _document.body"
></ng-keyboard-shortcuts>

<ng-keyboard-shortcuts-help
  [key]="'?'"
  [closeKey]="'escape'"
  *ngIf="_document.activeElement === _document.body"
  title="Keyboard shortcuts"
></ng-keyboard-shortcuts-help>
