<ng-container *ngIf="survey.type !== 'survey'">
  <page-survey-stats-connect-no-integration></page-survey-stats-connect-no-integration>
</ng-container>

<ng-container *ngIf="survey.type === 'survey'">
  <h2 class="title">
    <screeb-icon size="sm" icon="link" />
    Connect
  </h2>
  <p class="desc">
    Let's integrate your
    {{ uiService.isMessagePage ? "message" : "survey" }} with your favorite
    tools
  </p>

  <tag-settings-integrations
    scope="survey"
    [org]="org"
    [survey]="survey"
    [orgIntegrations]="integrations"
    [surveyIntegrations]="survey.integrations"
  ></tag-settings-integrations>

  <ng-template #pageActions>
    <div class="toolbar">
      <nz-button-group *ngIf="'survey.update' | hasPermission" nzSize="large">
        <button
          title="Save"
          nz-button
          class="actions-save ant-btn-secondary"
          nzSize="large"
          nzShape="round"
          [nzLoading]="saving"
          [disabled]="saveButtonDisabled()"
          (click)="actionOnSave()"
        >
          <screeb-icon size="sm" icon="save"></screeb-icon>
          <span>Save</span>
        </button>
      </nz-button-group>
    </div>
  </ng-template>
</ng-container>
