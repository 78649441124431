<div id="tooltip-{{ id }}">
  <div class="tooltip-title"></div>
  <div class="tooltip-indicators"></div>
</div>
<release-chart-overlay
  [trendChart]="trendChart"
  [datasets]="datasets"
  [options]="options"
  [releases]="releases"
  [orgAccounts]="orgAccounts"
  [orgId]="orgId"
  [trendTheme]="theme"
/>
<canvas
  style="position: relative; width: auto"
  #trendChart="base-chart"
  baseChart
  id="trend-chart"
  [class.has-shimmer]="loading"
  type="line"
  [datasets]="datasets"
  [options]="options"
  [options]="options"
  [legend]="false"
  (mouseenter)="onChartMouseEnter()"
  (mouseleave)="onChartMouseLeave()"
  width="800"
  height="250"
></canvas>

<footer [ngClass]="theme" *ngIf="!hideLegend">
  <div class="legend">
    <div class="legend-overall" *ngIf="theme === 'overall'">
      <span class="overall-legend-icon"></span>
      <span>Response rate in your industry</span>
    </div>
  </div>
  <nz-checkbox-group
    [(ngModel)]="reversedLegend"
    (ngModelChange)="onTrendLegendChange($event)"
  ></nz-checkbox-group>
</footer>
