import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TeamMemberInvitation } from "components/common/invite-team-members/invite-team-members";
import { NotificationHelper } from "helpers/notification.helper";
import { AccountDao } from "models/account.dao";
import {
  Account,
  AccountGoal,
  AccountGoalsToLabel,
} from "models/account.model";
import { AnalyticsDao } from "models/analytics.dao";
import { AnalyticsQueryUsers } from "models/analytics.filters.type";
import { IntegrationDao } from "models/integration.dao";
import { Integration, IntegrationSettings } from "models/integrations.model";
import { OrgDao } from "models/org.dao";
import { Org } from "models/org.model";
import { UUID } from "models/survey.dao.types";
import { Survey } from "models/survey.model";
import { SessionService } from "services/auth.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { OAuth2Service } from "services/oauth2.service";
import { PermissionsService } from "services/permissions.service";
import { TrackersService } from "services/trackers.service";

@Component({
  selector: "widget-help",
  templateUrl: "./help.component.html",
  styleUrls: ["./help.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HelpHomeComponent implements OnInit {
  public selectedIndex = 0;

  public goal: AccountGoal = "boost-user-satisfaction";

  public tasks = {
    installDone: false,

    presentationDone: false,
    presentationOpened: true,

    launchFirstSurveysDone: false,
    launchFirstSurveysOpened: true,

    inviteTeamMembersDone: false,
    inviteTeamMembersOpened: true,

    installSlackIntegrationDone: false,
    installSlackIntegrationOpened: true,
  };

  public invitationLoading = false;

  onSubmitInvitations(invitations: TeamMemberInvitation[]) {
    if (!invitations.length) return;

    this.invitationLoading = true;

    Promise.all(
      invitations
        .filter((invitation) => Boolean(invitation.email))
        .map((invitation) =>
          this.orgDao.inviteOrgAccounts(
            this.org.id,
            invitation.email,
            invitation.role,
          ),
        ),
    )
      .then(() => {
        this.trackersService
          .newEventTrackingBuilder("Screeb Invite Done")
          .withOrg(this.org)
          .withProps(invitations)
          .build();

        this.tasks.inviteTeamMembersDone = true;
        this.tasks.inviteTeamMembersOpened = false;

        this.notificationHelper.trigger(
          "You team mates have been invited to Screeb!",
          null,
          "success",
        );
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationHelper.trigger(
          "Something went wrong when inviting your team mates. Please try again later!",
          null,
          "error",
        );
        console.error(err.error);
      })
      .finally(() => {
        this.invitationLoading = false;
      });
  }

  public accountGoalsToLabel = AccountGoalsToLabel;

  constructor(
    private route: ActivatedRoute,
    private accountDao: AccountDao,
    public sessionService: SessionService,
    private analyticsDao: AnalyticsDao,
    public featureFlaggingService: FeatureFlaggingService,
    public permissionsService: PermissionsService,
    public trackersService: TrackersService,
    public integrationDao: IntegrationDao,
    public notificationHelper: NotificationHelper,
    public oauth2Service: OAuth2Service,
    private orgDao: OrgDao,
  ) {}

  private obs: any = null;
  public org: Org = null;
  public orgAccounts: Account[] = [];
  public surveys: Survey[] = [];
  public integrations: Integration[] = [];
  public orgHasUsers = true;
  public orgHasSurveyLive = false;
  public orgHasSlackIntegration = false;
  public installSlackIntegrationLoading = false;

  ngOnInit() {
    this.obs = this.route.data.subscribe(async (data) => {
      this.org = data.org;
      this.orgAccounts = data.orgAccounts;
      this.surveys = data.surveys;
      this.integrations = data.integrations;
      this.goal =
        this.sessionService.session?.flags?.goal ?? "boost-user-satisfaction";
      this.orgHasUsers = await this.hasUsers();
      this.orgHasSurveyLive = this.hasSurveyLive();
      this.orgHasSlackIntegration = this.hasSlackIntegration();

      this.tasks.installDone = this.orgHasUsers;
      this.tasks.presentationDone =
        this.sessionService.session?.flags?.article_read ?? false;
      this.tasks.launchFirstSurveysDone = this.orgHasSurveyLive;
      this.tasks.inviteTeamMembersDone = Boolean(this.orgAccounts.length - 1);
      this.tasks.installSlackIntegrationDone = this.orgHasSlackIntegration;

      this.tasks.presentationOpened = !this.tasks.presentationDone;
      this.tasks.launchFirstSurveysOpened = !this.tasks.launchFirstSurveysDone;
      this.tasks.inviteTeamMembersOpened = !this.tasks.inviteTeamMembersDone;
      this.tasks.installSlackIntegrationOpened =
        !this.tasks.installSlackIntegrationDone;
    });
  }

  private hasSlackIntegration() {
    return this.integrations.some(
      ({ type, auth_ok }) => type === "slack" && auth_ok,
    );
  }

  private hasSurveyLive() {
    return this.surveys.some(({ survey_distributions }) =>
      survey_distributions.some(({ enabled }) => enabled),
    );
  }

  private async hasUsers() {
    const response = await this.analyticsDao.search(
      this.getUsersCountQuery(this.org.created_at, "created_at"),
    );

    return response.hits.total > 0;
  }

  private getUsersCountQuery(
    start: Date,
    field: "last_activity_at" | "created_at",
    end = new Date(),
  ): AnalyticsQueryUsers {
    return {
      type: "respondent",
      org_id: UUID(this.org.id),
      survey_ids: this.surveys.map(({ id }) => UUID(id)),
      identified_only: false,
      filters_bool: "AND",
      filters: [],
      range: {
        start,
        end,
        field,
      },
      size: 0,
    };
  }

  onInstallSlackIntegrationClick() {
    this.integrationDao
      .updateIntegration(
        this.org.id,
        "slack",
        null,
        IntegrationSettings.New("slack"),
      )
      .then(() => this.oauth2Service.getAuthorizeURL(this.org.id, "slack"))
      .then((url) => (window.location.href = url))
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        this.notificationHelper.trigger(
          err?.error?.message ?? "Error",
          null,
          "error",
        );
      });
  }

  public handleArticleClick() {
    this.sessionService.session.flags.article_read = true;
    this.accountDao.setFlags({ article_read: true });
  }
}
