<section>
  <div class="translation-switch">
    <h3>
      <screeb-icon size="sm" icon="save"></screeb-icon>
      Automatic Responses Translation
    </h3>
    <nz-switch
      ngDefaultControl
      [ngModelOptions]="{ standalone: true }"
      [ngModel]="translationEnabled"
      (ngModelChange)="translationEnableChange($event)"
    ></nz-switch>
  </div>
  <ng-container>
    <p class="translation-description">
      When activated, all the responses displayed in your reports will be
      translated in the selected language. You'll still be able to access the
      original feedback in its original language.
      <br />
    </p>
    <p>
      Note: Other Screeb users in your organization can also select their own
      translation language in their settings. In that case, translations in
      their selected language will only be displayed on their account.
    </p>
    <language-select
      *ngIf="translationEnabled"
      class="language-select"
      name="defaultLanguage"
      ngDefaultControl
      [ngModel]="selectedLanguage"
      [languages]="availableTranslationLanguages"
      (ngModelChange)="selectedLanguageChange($event)"
      (click)="$event.preventDefault(); $event.stopPropagation()"
    ></language-select>
  </ng-container>
  <div class="error" *ngIf="isInvalid()">You must choose one language.</div>
</section>
