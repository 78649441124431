import { Component } from "@angular/core";
import { Router } from "@angular/router";

import { AuthService, SessionService } from "services/auth.service";
import { NotificationHelper } from "helpers/notification.helper";
import { UIService } from "services/ui.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import {
  IntegrationCategory,
  integrationCategorySpec,
} from "models/integrations.model";

@Component({
  selector: "layout-back-sidebar-integration",
  templateUrl: "./sidebar-integration.component.html",
  styleUrls: ["./sidebar-integration.component.scss"],
})
export class LayoutBackSidebarIntegrationComponent {
  public categories: IntegrationCategory[] = [
    "source",
    "destination",
    "analytics",
    "cdp",
    "etl",
    "roadmapping",
    "project_management",
    "customer_service",
    // "no_code",
    "communication",
  ];
  public integrationCategorySpec = integrationCategorySpec;
  constructor(
    public router: Router,
    public authService: AuthService,
    public sessionService: SessionService,
    public notificationHelper: NotificationHelper,
    public uiService: UIService,
    public featureFlaggingService: FeatureFlaggingService,
  ) {}
}
