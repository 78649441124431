import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { PageComponentInterface } from "components/PageComponentInterface";
import { NotificationHelper } from "helpers/notification.helper";
import { Account } from "models/account.model";
import { Org } from "models/org.model";
import { RegistryEntry } from "models/registry.model";
import { SequenceDao } from "models/sequence.dao";
import { Sequence, SequenceFunnel } from "models/sequence.types";
import { NzModalService } from "ng-zorro-antd/modal";
import {
  NzTableFilterFn,
  NzTableFilterList,
  NzTableSortFn,
  NzTableSortOrder,
} from "ng-zorro-antd/table";
import { EntitlementService } from "services/entitlement.service";
import { PermissionsService } from "services/permissions.service";
import { RoutingService } from "services/routing.service";
import { TrackersService } from "services/trackers.service";
import { simpleFTS } from "utils/search";

type ColumnItem = {
  name: string;
  width: number;
  align: "left" | "right" | "center";
  sortOrder?: NzTableSortOrder;
  sortFn?: NzTableSortFn;
  listOfFilter?: NzTableFilterList;
  filterFn?: NzTableFilterFn;
  filterMultiple: boolean;
  sortDirections: NzTableSortOrder[];
};

@Component({
  selector: "page-funnel-list",
  templateUrl: "./funnel-list.component.html",
  styleUrls: ["./funnel-list.component.scss"],
})
export class FunnelListPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "List all funnels";
  public name = "List funnels";

  private obs: any = null;
  public org: Org = null;

  public orgAccounts: Account[] = [];
  public registryEntriesEvent: RegistryEntry[] = [];

  public funnels: SequenceFunnel[] = [];
  public filteredFunnels: SequenceFunnel[] = [];

  public keywords = "";
  public createFunnelLateralPanelVisible = false;
  public currentSequenceActionDropDown: string = null;
  private loaderStatus: object = {};

  listOfColumns: ColumnItem[] = [
    {
      name: "Funnels",
      width: 400,
      align: "left",
      sortOrder: null,
      sortFn: (a: SequenceFunnel, b: SequenceFunnel) =>
        a.title.localeCompare(b.title),
      sortDirections: ["ascend", "descend"],
      filterMultiple: true,
      listOfFilter: undefined,
      filterFn: null,
    },
    {
      name: "Last edit",
      width: 150,
      align: "left",
      sortOrder: "descend",
      sortFn: (a: SequenceFunnel, b: SequenceFunnel) =>
        a.updated_at.getTime() - b.updated_at.getTime(),
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
      listOfFilter: undefined,
      filterFn: null,
    },
    {
      name: "Steps",
      width: 100,
      align: "left",
      sortOrder: null,
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
      listOfFilter: undefined,
      filterFn: null,
    },
  ];

  constructor(
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private trackersService: TrackersService,
    private modalService: NzModalService,
    private notificationHelper: NotificationHelper,
    private sequenceDao: SequenceDao,
    public permissionsService: PermissionsService,
    public entitlementService: EntitlementService,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.obs = this.route.data.subscribe((data) => {
      this.org = data.org;
      this.orgAccounts = data.orgAccounts;
      this.registryEntriesEvent = data.registryEntriesEvent;
      this.funnels = data.funnels.map((funnel) => ({
        ...funnel,
        updated_by_account: this.orgAccounts.find(
          ({ id }) => id === funnel.updated_by,
        ),
      }));

      this.filteredFunnels = this.funnels;
    });
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  onCreateFunnelClick() {
    this.createFunnelLateralPanelVisible = true;
    this.trackersService
      .newEventTrackingBuilder("Funnel create clicked")
      .withOrg(this.org)
      .build();
  }

  onCreateFunnelClose() {
    this.createFunnelLateralPanelVisible = false;
  }

  /**
   * Search
   */
  public onSearchChange() {
    this.filterFunnels();
  }

  private filterFunnels(): void {
    this.filteredFunnels = this.funnels.filter((funnel) => {
      return simpleFTS(funnel.title, this.keywords);
    });
  }

  public setCurrentSequenceActionDropDown(sequenceId: string, opened: boolean) {
    if (!opened) {
      sequenceId = null;
    }
    this.currentSequenceActionDropDown = sequenceId;
  }

  public onDelete(funnel: Sequence) {
    this.setSurveyActionLoaderStatus(funnel.id, "deleting", true);

    const deleteFunnel = (removeFunnel: boolean, removeSurveys: boolean) => {
      if (!removeFunnel) {
        return;
      }

      return this.sequenceDao
        .delete(funnel.org_id, funnel.id, removeSurveys)
        .then(() => {
          this.funnels = this.funnels.filter(({ id }) => id !== funnel.id);
          this.onSearchChange();
        })
        .catch((err) => {
          this.notificationHelper.trigger(
            err?.error?.message ?? "Error",
            null,
            "error",
          );
        })
        .finally(() => {
          this.setSurveyActionLoaderStatus(funnel.id, "deleting", false);
          modal.destroy();
        });
    };

    const modal = this.modalService.create({
      nzTitle: "Do you really want to delete this funnel?",
      nzContent: "That operation cannot be undone.",
      nzStyle: {
        display: "flex",
        "align-items": "center",
        "justify-content": "center",
      },
      nzMaskClosable: true,
      nzClosable: false,
      nzKeyboard: false,
      nzCloseOnNavigation: false,
      nzFooter: [
        {
          label: "Close",
          type: "default",
          onClick: () => modal.destroy(),
        },
        {
          label: "Delete only funnel",
          type: "default",
          danger: true,
          loading: false,
          onClick(): void {
            this.loading = true;
            deleteFunnel(true, false);
          },
        },
        {
          label: "Delete funnel and all surveys",
          type: "primary",
          danger: true,
          loading: false,
          onClick(): void {
            this.loading = true;
            deleteFunnel(true, true);
          },
        },
      ],
    });
  }

  private setSurveyActionLoaderStatus(
    surveyId: string,
    statusId: string,
    status: boolean,
  ) {
    if (!this.loaderStatus[surveyId]) {
      this.loaderStatus[surveyId] = {};
    }

    this.loaderStatus[surveyId][statusId] = status;
  }
}
