import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import localeEmoji from "locale-emoji";
import { SurveyLanguages } from "models/survey.dao.types";

export type LanguageWithEmoji = {
  value: SurveyLanguages;
  label: string;
  emoji: string;
};

let cache: output;

type output = {
  surveyLanguages: string[][];
  translationLanguages: string[][];
  countries: string[][];
  surveyLanguagesWithEmojis: LanguageWithEmoji[];
  translationLanguagesWithEmojis: LanguageWithEmoji[];
};

function languagesToEmoji(languages: string[][]): LanguageWithEmoji[] {
  return languages.map(
    ([value, label]): LanguageWithEmoji => ({
      value,
      label,
      emoji: localeEmoji(value) || "🏳️",
    }),
  );
}

@Injectable()
export class AssetLanguagesAndCountriesResolver implements Resolve<output> {
  constructor(private httpClient: HttpClient) {}

  resolve(): Promise<output> {
    if (cache) {
      return Promise.resolve(cache);
    }

    return Promise.all([
      this.httpClient.get<object>(`/assets/data/languages.json`).toPromise(),
      this.httpClient
        .get<object>(`/assets/data/translation_languages.json`)
        .toPromise(),
      this.httpClient.get<object>(`/assets/data/countries.json`).toPromise(),
    ]).then((data) => {
      const languages: string[][] = Object.entries(data[0]);
      const translationLanguages: string[][] = Object.entries(data[1]);
      const countries: string[][] = Object.entries(data[2]);
      const surveyLanguagesWithEmojis = languagesToEmoji(languages);
      const translationLanguagesWithEmojis =
        languagesToEmoji(translationLanguages);

      languages.sort((a, b) => a[1].localeCompare(b[1]));
      translationLanguages.sort((a, b) => a[1].localeCompare(b[1]));
      countries.sort((a, b) => a[1].localeCompare(b[1]));

      cache = {
        surveyLanguages: languages,
        translationLanguages,
        countries,
        surveyLanguagesWithEmojis: surveyLanguagesWithEmojis,
        translationLanguagesWithEmojis,
      };
      return cache;
    });
  }
}
