<div id="settings-follow-mode">
  <div class="label">
    <screeb-icon size="sm" icon="click"></screeb-icon>
    Follow mode
    <nz-switch
      [(ngModel)]="tagSettings.follow_mode"
      [nzCheckedChildren]="checkedTemplate"
      [nzUnCheckedChildren]="unCheckedTemplate"
      (ngModelChange)="update.emit()"
    ></nz-switch>
    <ng-template #checkedTemplate>
      <span nz-icon nzType="check"></span>
    </ng-template>
    <ng-template #unCheckedTemplate>
      <span nz-icon nzType="close"></span>
    </ng-template>
  </div>
  <p>
    To increase response rates, you can use our follow mode which continue to
    display the surveys once they've been displayed to your users until they
    respond or close them, even if they leave the context you were targeting.
  </p>
</div>
<hr />
<div id="settings-skip-anonymous">
  <div class="label">
    <screeb-icon size="sm" icon="user"></screeb-icon>
    Ignore anonymous users
    <nz-switch
      [(ngModel)]="tagSettings.skip_anonymous"
      [nzCheckedChildren]="checkedTemplate"
      [nzUnCheckedChildren]="unCheckedTemplate"
      (ngModelChange)="update.emit()"
    ></nz-switch>
    <ng-template #checkedTemplate>
      <span nz-icon nzType="check"></span>
    </ng-template>
    <ng-template #unCheckedTemplate>
      <span nz-icon nzType="close"></span>
    </ng-template>
  </div>
  <p>
    If you want to exclude anonymous users for this workspace, you can enable
    this setting, and we will not track anything from them, as well as not being
    counted in your MAU.
  </p>
</div>
<hr />
<div id="settings-response-expiration">
  <div class="label">
    <screeb-icon size="sm" icon="comment"></screeb-icon>
    Close response after
  </div>
  <p>
    Define how long you want to keep a response open for your users. After this
    time, the response will be considered as closed.
  </p>

  <nz-select
    class="position"
    [nzOptions]="responseExpirationOptions"
    nzSize="large"
    [(ngModel)]="tagSettings.response_expiration_second"
    (ngModelChange)="update.emit()"
  ></nz-select>
</div>
<div
  id="settings-device-tracking"
  *ngIf="
    ('device_tracking' | hasEntitlement) &&
    ('isForceDeviceTrackingEnabled' | hasFeatureFlag)
  "
>
  <hr />
  <div class="label">
    <screeb-icon size="md" icon="desktop"></screeb-icon>
    Force device tracking
    <nz-switch
      [(ngModel)]="tagSettings.device_tracking_forced"
      [nzCheckedChildren]="checkedTemplate"
      [nzUnCheckedChildren]="unCheckedTemplate"
      (ngModelChange)="update.emit()"
    ></nz-switch>
    <ng-template #checkedTemplate>
      <span nz-icon nzType="check"></span>
    </ng-template>
    <ng-template #unCheckedTemplate>
      <span nz-icon nzType="close"></span>
    </ng-template>
    <span class="feature-flagged">
      <screeb-icon size="sm" icon="dev-documentation"></screeb-icon>
      Feature flagged
    </span>
  </div>
  <p>
    Use only the device tracking for this workspace. If you enable this setting,
    tracking will be done only on the device, and not server-side. Some features
    will be disabled, like Segment and funnel analysis.
  </p>
</div>
<div id="settings-dummy-mode" *ngIf="'isDummyModeEnabled' | hasFeatureFlag">
  <hr />
  <div class="label">
    <screeb-icon size="sm" icon="dev-documentation"></screeb-icon>
    Demo mode
    <nz-switch
      [(ngModel)]="tagSettings.dummy_mode"
      [nzCheckedChildren]="checkedTemplate"
      [nzUnCheckedChildren]="unCheckedTemplate"
      (ngModelChange)="update.emit()"
    ></nz-switch>
    <ng-template #checkedTemplate>
      <span nz-icon nzType="check"></span>
    </ng-template>
    <ng-template #unCheckedTemplate>
      <span nz-icon nzType="close"></span>
    </ng-template>
    <span class="feature-flagged">
      <screeb-icon size="sm" icon="dev-documentation"></screeb-icon>
      Feature flagged
    </span>
  </div>
  <p>
    Demo mode allow you to test your workspace without tracking any real data.
    Sample data will be generated for your workspace, and you will be able to
    check how your workspace will look like with real data.
  </p>
</div>
