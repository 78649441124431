<ng-container [ngSwitch]="getSvgName()">
  <ng-container *ngSwitchCase="'scoring'">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5 0C2.23858 0 0 2.23858 0 5V19C0 21.7614 2.23858 24 5 24H19C21.7614 24 24 21.7614 24 19V5C24 2.23858 21.7614 0 19 0H5ZM2 5C2 3.34315 3.34315 2 5 2H19C20.6569 2 22 3.34315 22 5V19C22 20.6569 20.6569 22 19 22H5C3.34315 22 2 20.6569 2 19V5ZM15.3498 18.8403C15.4923 18.9466 15.6578 19 15.8241 19C15.9917 19 16.1589 18.9462 16.3018 18.8369C16.5847 18.6221 16.7053 18.2408 16.5983 17.8926L15.349 13.6783L18.6686 11.2333C18.9511 11.0159 19.0684 10.6337 18.9601 10.2851C18.8527 9.93781 18.5426 9.7011 18.1914 9.69851L14.0589 9.69206L12.7721 5.58231C12.6614 5.23456 12.3505 5 12.0001 5C11.6497 5 11.3388 5.23413 11.2284 5.58231L9.91945 9.69206L5.80798 9.69851C5.45798 9.7011 5.14833 9.93738 5.03963 10.2851C4.93175 10.6337 5.0491 11.0159 5.33116 11.2333L8.6508 13.6783L7.40151 17.8926C7.29527 18.2408 7.41469 18.6221 7.69839 18.8369C7.9821 19.0538 8.36504 19.0538 8.65039 18.8403L12.0001 16.3295L15.3498 18.8403Z"
        fill="#5E21F1"
      />
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'input'">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 5C0 2.23858 2.23858 0 5 0H19C21.7614 0 24 2.23858 24 5V19C24 21.7614 21.7614 24 19 24H5C2.23858 24 0 21.7614 0 19V5ZM5 2C3.34315 2 2 3.34315 2 5V19C2 20.6569 3.34315 22 5 22H19C20.6569 22 22 20.6569 22 19V5C22 3.34315 20.6569 2 19 2H5ZM16.2426 4.92894C16.6331 4.53841 17.2663 4.53841 17.6568 4.92894L19.071 6.34315C19.4616 6.73368 19.4616 7.36684 19.071 7.75737L11.6018 15.2266C11.2113 15.6171 10.5781 15.6171 10.1876 15.2266L8.7734 13.8124C8.38287 13.4218 8.38287 12.7887 8.7734 12.3981L16.2426 4.92894ZM7.42877 14.4998C7.15961 14.2307 6.70038 14.349 6.59482 14.7147L5.74122 17.672C5.63285 18.0475 5.97771 18.3966 6.35444 18.2927L9.34205 17.4694C9.7116 17.3676 9.83382 16.9049 9.56277 16.6339L7.42877 14.4998Z"
        fill="#0054B6"
      />
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'multiple_choices'">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 5C0 2.23858 2.23858 0 5 0H19C21.7614 0 24 2.23858 24 5V19C24 21.7614 21.7614 24 19 24H5C2.23858 24 0 21.7614 0 19V5ZM5 2C3.34315 2 2 3.34315 2 5V19C2 20.6569 3.34315 22 5 22H19C20.6569 22 22 20.6569 22 19V5C22 3.34315 20.6569 2 19 2H5ZM4 6C4 4.89543 4.89543 4 6 4H9C10.1046 4 11 4.89543 11 6V9C11 10.1046 10.1046 11 9 11H6C4.89543 11 4 10.1046 4 9V6ZM15 4C13.8954 4 13 4.89543 13 6V9C13 10.1046 13.8954 11 15 11H18C19.1046 11 20 10.1046 20 9V6C20 4.89543 19.1046 4 18 4H15ZM15 6H18V9H15V6ZM13 15C13 13.8954 13.8954 13 15 13H18C19.1046 13 20 13.8954 20 15V18C20 19.1046 19.1046 20 18 20H15C13.8954 20 13 19.1046 13 18V15ZM18 15H15V18H18V15ZM6 13C4.89543 13 4 13.8954 4 15V18C4 19.1046 4.89543 20 6 20H9C10.1046 20 11 19.1046 11 18V15C11 13.8954 10.1046 13 9 13H6ZM6 15H9V18H6V15Z"
        fill="#086554"
      />
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'text'">
    <svg
      width="16"
      height="24"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2 1C2 0.447715 2.44772 0 3 0H13C13.5523 0 14 0.447715 14 1V3C14 3.55228 13.5523 4 13 4H10V11C10 11.5523 9.55228 12 9 12H7C6.44771 12 6 11.5523 6 11L6 4H3C2.44772 4 2 3.55228 2 3V1ZM0 15C0 14.4477 0.447715 14 1 14H15C15.5523 14 16 14.4477 16 15C16 15.5523 15.5523 16 15 16H1C0.447715 16 0 15.5523 0 15Z"
        fill="#FFC046"
      />
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'nps'">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M24 21.3333C24 22.806 22.806 24 21.3333 24H2.66667C1.194 24 0 22.806 0 21.3333V2.66667C0 1.194 1.194 0 2.66667 0H21.3333C22.806 0 24 1.194 24 2.66667V21.3333Z"
        fill="#F11672"
      />
      <path
        d="M4.80867 7.31667H3.50333C2.442 7.31667 2 6.54267 2 5.79067C2 5.01733 2.55267 4.26533 3.50333 4.26533H6.64333C7.594 4.26533 8.12533 4.95067 8.12533 5.83467V18.418C8.12533 19.5233 7.41733 20.142 6.46667 20.142C5.516 20.142 4.80867 19.5233 4.80867 18.418V7.31667ZM10.12 12.1373C10.12 8.53267 11.5133 4 15.98 4C20.4467 4 21.8407 8.53267 21.8407 12.1373C21.8407 15.7413 20.4467 20.2753 15.98 20.2753C11.5133 20.2753 10.12 15.7413 10.12 12.1373ZM18.3907 12.1373C18.3907 10.4567 18.236 7.00667 15.98 7.00667C13.7247 7.00667 13.57 10.4567 13.57 12.1373C13.57 13.7073 13.7247 17.2673 15.98 17.2673C18.236 17.2673 18.3907 13.708 18.3907 12.1373Z"
        fill="#FFD6DD"
      />
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'csat'">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 7.38508C24 3.30637 21.0357 0 17.3797 0C15.1602 0 13.2017 1.22211 12 3.09009C10.7983 1.22211 8.83981 0 6.62097 0C2.96427 0 0 3.30562 0 7.38508C0 7.96283 0.0657534 8.52262 0.178474 9.06221C1.09768 15.4332 7.44825 22.1545 12 24C16.5511 22.1545 22.9023 15.4332 23.8202 9.06296C23.9342 8.52337 24 7.96358 24 7.38508Z"
        fill="#5E21F1"
      />
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'ces'">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M10.6513 6.24H13.1773C13.2533 6.11267 13.2753 5.94733 13.216 5.79133L10.652 2.65467V6.24H10.6513Z"
        fill="#EF9645"
      />
      <path
        d="M8.536 14.8513C8.498 14.6187 8.45334 14.3987 8.39867 14.2027C7.98534 12.7207 7.63867 12.0933 7.78667 10.54C7.98 9.484 7.96867 7.36467 10.7753 7.69467C11.5167 7.782 12.6707 8.31267 13.3313 8.30133C13.7093 8.29467 13.9847 7.53 14.0093 7.27533C14.0433 6.92467 13.4327 6.32867 13.1773 6.23933C12.922 6.15 11.836 5.81867 11.39 5.69C10.6973 5.48933 10.7333 4.55333 10.6893 4.22C10.6687 4.06333 10.7453 3.90867 10.8853 3.826C11.0253 3.74333 11.1353 3.82067 11.2713 3.90933L11.8613 4.34133C12.1927 4.62533 11.2787 5.168 11.526 5.25867C11.526 5.25867 12.696 5.698 13.1973 5.78933C13.472 5.83933 14.42 4.77 14.4753 4.14267C14.5187 3.64933 12.21 1.42067 10.564 0.555333C9.98534 0.251333 9.646 0.0746666 9.26467 0.0926666C8.80934 0.114 8.666 0.294 8.09934 0.779333C5.53467 2.97333 3.04534 7.718 2.68 8.768C1.18267 13.0753 0.948669 15.3447 0.895335 16.6267C0.862669 17.02 0.844669 17.4113 0.844669 17.7987C0.888002 17.7987 0.178002 21.132 0.844669 21.7987C1.51134 22.4653 4.39534 22.4653 4.39534 22.4653C11.062 25.1247 23.442 24.4307 23.442 17.6433C23.442 9.52867 10.9 10.6053 8.536 14.8513Z"
        fill="#FFB546"
      />
      <path
        d="M13.7533 21.3853C11.6467 21.164 10.0013 20.1313 9.90867 20.072C9.59867 19.874 9.508 19.4633 9.70533 19.1527C9.90267 18.842 10.314 18.7507 10.6253 18.9473C10.652 18.964 13.294 20.6087 15.856 19.9253C17.2727 19.548 18.434 18.5153 19.3073 16.858C19.4793 16.5313 19.8847 16.4073 20.208 16.5787C20.5333 16.75 20.6587 17.1533 20.4873 17.4793C19.4307 19.4833 17.9847 20.7413 16.1893 21.2167C15.3513 21.438 14.5213 21.466 13.7533 21.3853Z"
        fill="#EF9645"
      />
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'appstore_rating'">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.33334 24C7.33334 24 4.66667 24 4.66667 21.3333V2.66667C4.66667 2.66667 4.66667 0 7.33334 0H16.6667C16.6667 0 19.3333 0 19.3333 2.66667V21.3333C19.3333 21.3333 19.3333 24 16.6667 24H7.33334Z"
        fill="#086554"
      />
      <path d="M6 3.33333H18V20.6667H6V3.33333Z" fill="#1ED5A4" />
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'range'">
    <svg
      width="24"
      height="24"
      viewBox="0 0 32 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32 8.82796C32 3.95236 28.0476 0 23.1729 0C20.2136 0 17.6022 1.46089 16 3.69382C14.3978 1.46089 11.7864 0 8.82796 0C3.95236 0 0 3.95147 0 8.82796C0 9.51859 0.0876712 10.1878 0.237965 10.8328C1.46357 18.4485 9.931 26.483 16 28.6891C22.0681 26.483 30.5364 18.4485 31.7602 10.8337C31.9123 10.1887 32 9.51949 32 8.82796Z"
        fill="url(#paint0_linear_452_881)"
      />
      <path
        d="M30.6242 19.6178L27.2032 18.351L25.9364 14.9301C25.8067 14.5776 25.4712 14.345 25.0973 14.345C24.7234 14.345 24.3879 14.5776 24.2582 14.9292L22.9914 18.3501L19.5704 19.6169C19.2189 19.7457 18.9863 20.0812 18.9863 20.456C18.9863 20.83 19.2197 21.1655 19.5704 21.2952L22.9914 22.5619L24.2582 25.9829C24.3879 26.3345 24.7234 26.5671 25.0973 26.5671C25.4712 26.5671 25.8067 26.3336 25.9364 25.9829L27.2032 22.5619L30.6242 21.2952C30.9748 21.1655 31.2083 20.83 31.2083 20.456C31.2083 20.0821 30.9748 19.7466 30.6242 19.6178ZM10.0483 5.30322L7.93252 4.52044L7.14974 2.4047C7.01913 2.05312 6.68455 1.82053 6.3106 1.82053C5.93576 1.82053 5.60118 2.05312 5.47146 2.4047L4.68779 4.52044L2.57295 5.30322C2.22137 5.43383 1.98877 5.76842 1.98877 6.14236C1.98877 6.51631 2.22137 6.85089 2.57295 6.9815L4.68869 7.76428L5.47146 9.88002C5.60208 10.2316 5.93666 10.4642 6.3106 10.4642C6.68455 10.4642 7.01913 10.2316 7.14974 9.88002L7.93252 7.76428L10.0483 6.9815C10.3998 6.85089 10.6324 6.5172 10.6324 6.14236C10.6324 5.76842 10.3998 5.43383 10.0483 5.30322Z"
        fill="url(#paint1_linear_452_881)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_452_881"
          x1="16"
          y1="0"
          x2="16"
          y2="28.6891"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#EA3A5A" />
          <stop offset="1" stop-color="#DA1746" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_452_881"
          x1="16.5986"
          y1="1.82053"
          x2="16.5986"
          y2="26.5671"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFD361" />
          <stop offset="1" stop-color="#F9A931" />
        </linearGradient>
      </defs>
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'link'">
    <svg
      width="24"
      height="24"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.5"
        y="1.5"
        width="37"
        height="37"
        rx="6.5"
        stroke="#0054B6"
        stroke-width="3"
      />
      <path
        d="M30.1349 18.8741L25.6295 23.378C23.1429 25.8661 19.109 25.8661 16.622 23.378C16.2302 22.9874 15.9237 22.5472 15.6546 22.0933L17.748 20.0001C17.8475 19.8997 17.9704 19.8422 18.0878 19.7745C18.2325 20.2689 18.4852 20.737 18.874 21.1259C20.1159 22.3688 22.1366 22.367 23.3777 21.1259L27.8816 16.6222C29.1244 15.3795 29.1244 13.3592 27.8816 12.1175C26.6405 10.8758 24.6203 10.8758 23.3777 12.1175L21.7761 13.7209C20.4764 13.2148 19.0761 13.0787 17.713 13.2785L21.1259 9.8657C23.6139 7.3781 27.6468 7.3781 30.1349 9.8657C32.6217 12.3532 32.6217 16.3867 30.1349 18.8741ZM18.2247 26.2801L16.6219 27.8834C15.3802 29.1245 13.3594 29.1245 12.1174 27.8834C10.8756 26.6406 10.8756 24.6203 12.1174 23.378L16.6219 18.8741C17.8646 17.6316 19.8841 17.6316 21.1258 18.8741C21.5138 19.2622 21.7667 19.7302 21.9128 20.224C22.0309 20.1554 22.1522 20.0996 22.2517 20L24.3449 17.9076C24.0775 17.4519 23.7695 17.0133 23.3776 16.6223C20.8912 14.1347 16.8569 14.1347 14.3693 16.6223L9.8655 21.1262C7.37817 23.6145 7.37817 27.6469 9.8655 30.135C12.3531 32.6218 16.3864 32.6218 18.874 30.135L22.2878 26.7215C20.9239 26.9224 19.5233 26.7852 18.2247 26.2801Z"
        fill="#0054B6"
      />
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'calendar'">
    <svg
      width="24"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="#5e21f1"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
      />
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'banner'">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5 2C2.23858 2 0 4.23858 0 7V17C0 19.7614 2.23858 22 5 22H19C21.7614 22 24 19.7614 24 17V7C24 4.23858 21.7614 2 19 2H5ZM2 7C2 5.34315 3.34315 4 5 4H19C20.6569 4 22 5.34315 22 7V17C22 18.6569 20.6569 20 19 20H5C3.34315 20 2 18.6569 2 17V7ZM6 6C4.89543 6 4 6.89543 4 8C4 9.10457 4.89543 10 6 10H18C19.1046 10 20 9.10457 20 8C20 6.89543 19.1046 6 18 6H6Z"
        fill="#1ED5A4"
      />
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'modal'">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5 2C2.23858 2 0 4.23858 0 7V17C0 19.7614 2.23858 22 5 22H19C21.7614 22 24 19.7614 24 17V7C24 4.23858 21.7614 2 19 2H5ZM2 7C2 5.34315 3.34315 4 5 4H19C20.6569 4 22 5.34315 22 7V17C22 18.6569 20.6569 20 19 20H5C3.34315 20 2 18.6569 2 17V7ZM8 6C6.89543 6 6 6.89543 6 8V16C6 17.1046 6.89543 18 8 18H16C17.1046 18 18 17.1046 18 16V8C18 6.89543 17.1046 6 16 6H8Z"
        fill="#0094FF"
      />
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'beacon'">
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 5C0 2.23858 2.23858 0 5 0H19C21.7614 0 24 2.23858 24 5V15C24 17.7614 21.7614 20 19 20H5C2.23858 20 0 17.7614 0 15V5ZM5 2C3.34315 2 2 3.34315 2 5V15C2 16.6569 3.34315 18 5 18H19C20.6569 18 22 16.6569 22 15V5C22 3.34315 20.6569 2 19 2H5ZM10.143 5.05968C9.99159 4.99906 9.82568 4.98422 9.66589 5.017C9.50609 5.04978 9.35943 5.12873 9.24408 5.24407C9.12874 5.35941 9.04978 5.50607 9.017 5.66586C8.98422 5.82565 8.99906 5.99154 9.05968 6.14298L12.3931 14.4761C12.4528 14.6252 12.5544 14.7539 12.6855 14.8467C12.8167 14.9395 12.9719 14.9923 13.1324 14.9989C13.293 15.0055 13.452 14.9656 13.5903 14.8839C13.7287 14.8022 13.8405 14.6823 13.9123 14.5386L15.0623 12.2395L17.5773 14.7561C17.7337 14.9123 17.9457 15.0001 18.1668 15C18.3878 14.9999 18.5998 14.912 18.7561 14.7557C18.9123 14.5993 19.0001 14.3873 19 14.1662C18.9999 13.9452 18.912 13.7332 18.7557 13.577L16.2398 11.0604L18.5398 9.91121C18.6833 9.83927 18.8029 9.72742 18.8844 9.58912C18.9658 9.45082 19.0056 9.29195 18.9989 9.1316C18.9922 8.97125 18.9393 8.81624 18.8467 8.6852C18.754 8.55416 18.6255 8.45267 18.4765 8.39292L10.143 5.05968ZM6.5 7C7.32843 7 8 6.32843 8 5.5C8 4.67157 7.32843 4 6.5 4C5.67157 4 5 4.67157 5 5.5C5 6.32843 5.67157 7 6.5 7Z"
        fill="#5E21F1"
      />
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'popover'">
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5 0C2.23858 0 0 2.23858 0 5V15C0 17.7614 2.23858 20 5 20H19C21.7614 20 24 17.7614 24 15V5C24 2.23858 21.7614 0 19 0H5ZM2 5C2 3.34315 3.34315 2 5 2H19C20.6569 2 22 3.34315 22 5V15C22 16.6569 20.6569 18 19 18H5C3.34315 18 2 16.6569 2 15V5ZM8 6.5C8 7.32843 7.32843 8 6.5 8C5.67157 8 5 7.32843 5 6.5C5 5.67157 5.67157 5 6.5 5C7.32843 5 8 5.67157 8 6.5ZM11 5C9.89543 5 9 5.89543 9 7V11C9 12.1046 9.89543 13 11 13H13C14.1046 13 15 12.1046 15 11V7C15 5.89543 14.1046 5 13 5H11Z"
        fill="#FFB546"
      />
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'sidebar'">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5 2C2.23858 2 0 4.23858 0 7V17C0 19.7614 2.23858 22 5 22H19C21.7614 22 24 19.7614 24 17V7C24 4.23858 21.7614 2 19 2H5ZM2 7C2 5.34315 3.34315 4 5 4H19C20.6569 4 22 5.34315 22 7V17C22 18.6569 20.6569 20 19 20H5C3.34315 20 2 18.6569 2 17V7ZM15 6C13.8954 6 13 6.89543 13 8V16C13 17.1046 13.8954 18 15 18H18C19.1046 18 20 17.1046 20 16V8C20 6.89543 19.1046 6 18 6H15Z"
        fill="#F11672"
      />
    </svg>
  </ng-container>
</ng-container>
