import { Component, Input } from "@angular/core";
import localeEmoji from "locale-emoji";
import { UserEvent } from "models/user-event.model";
import { UserRecord } from "models/user-record.model";

@Component({
  selector: "user-context",
  templateUrl: "./user-context.component.html",
  styleUrls: ["./user-context.component.scss"],
})
export class UserContextComponent {
  @Input() public userRecord: UserRecord;
  @Input() public userEvents: UserEvent[];

  constructor() {}

  public getLocaleEmoji(locale: string): string {
    return localeEmoji(locale) || "🏳️";
  }
}
