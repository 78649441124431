import { Component, Input } from "@angular/core";

@Component({
  selector: "determinate-progress",
  template: `
    <div class="determinate-progress">
      <div
        class="determinate-progress-value"
        [style.width.%]="percentage"
      ></div>
    </div>
  `,
  styles: [
    `
      :host {
        width: 100%;
      }

      .determinate-progress {
        height: 8px;
        background-color: rgba(245, 241, 255, 1);
        width: 100%;
        overflow: hidden;
        border-radius: 8px;
      }

      .determinate-progress-value {
        height: 8px;
        background-color: rgba(94, 33, 241, 1);
        border-radius: 8px;
        transition: width 0.3s ease-in-out;
      }
    `,
  ],
})
export class DeterminateProgress {
  @Input() percentage: number = 0;
}
