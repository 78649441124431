import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";

import { ApplicationLoadService } from "services/app.service";
import { SurveyDao } from "models/survey.dao";
import { Survey } from "models/survey.model";

@Injectable()
export class SurveysResolver implements Resolve<any> {
  constructor(
    private applicationLoadService: ApplicationLoadService,
    private surveyDao: SurveyDao,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Promise<Survey[]> {
    // We can't rely on uiService here, as it's too soon
    const isMessagePage = route.routeConfig.path.indexOf("message") === 0;
    return this.applicationLoadService
      .loader()
      .then(() => {
        return this.surveyDao.getAllByOrgId(
          isMessagePage ? "message" : "survey",
          route.params["org_id"],
          Boolean(route.data.withScenario),
          Boolean(route.data.withTargetingRules),
        );
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        throw err;
      });
  }
}
