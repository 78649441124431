import { Component } from "@angular/core";

@Component({
  selector: "page-no-orga",
  templateUrl: "./error-page.component.html",
  styleUrls: ["./error-page.component.scss"],
})
class NoOrgaPageComponent {
  public subtitle: string = "Screeb account disabled";
  public text: string =
    "This error indicates that your Screeb account is not link to any organization. Please contact your administrator or Screeb support team.";

  constructor() {}
}

export { NoOrgaPageComponent };
