import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { FeatureFlaggingService } from "services/feature-flagging.service";

export function featureFlagGuard(
  feature: string,
  redirectTo: string,
): CanActivateFn {
  return () => {
    const featureFlaggingService = inject(FeatureFlaggingService);
    const router = inject(Router);

    let isAvailable = false;

    // Check if the function exist
    if (featureFlaggingService[feature]) {
      isAvailable = featureFlaggingService[feature]();
    }

    return isAvailable || router.createUrlTree([redirectTo]);
  };
}
