import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { AspectRatio, transformToAspectRatio } from "utils/media";

@Component({
  selector: "preview-video-modal",
  templateUrl: "./video-preview-modal.component.html",
  styleUrls: ["./video-preview-modal.component.scss"],
})
export class SurveyPreviewVideoModalComponent implements OnInit {
  @ViewChild("videoPlayer", { static: false })
  videoPlayer: ElementRef<HTMLVideoElement>;

  @Input() videoUrl: string = null;
  @Input() isOpen: boolean = false;
  @Output() modalOpenChange: EventEmitter<boolean> = new EventEmitter();

  public isPlaying: boolean = true;

  public showCropDelimiter = true;

  public selectedAspectRatio = "9:16";
  public aspectRatios = [
    { value: "9:16", label: "9:16", ratio: 9 / 16, soon: false },
    { value: "16:9", label: "16:9", ratio: 16 / 9, soon: true },
    { value: "4:3", label: "4:3", ratio: 4 / 3, soon: true },
    { value: "1:1", label: "1:1", ratio: 1, soon: true },
  ];

  public aspectRatioData: AspectRatio = {
    width: 1280,
    height: 720,
    margin: 0,
  };

  constructor() {}

  ngOnInit() {
    const t = setInterval(() => {
      if (this.videoPlayer) {
        this.onMediaReady();
        clearInterval(t);
      }
    }, 1000);
  }

  private get aspectRatio(): number {
    return this.aspectRatios.find((r) => r.value === this.selectedAspectRatio)
      .ratio;
  }

  handleClose(): void {
    this.isOpen = false;
    this.modalOpenChange.emit(this.isOpen);
  }

  setPlaying(playing: boolean) {
    if (!this.videoPlayer) return;
    const player = this.videoPlayer.nativeElement;

    if (playing && player.paused) {
      player.play();
    } else if (!playing && !player.paused) {
      player.pause();
    }

    this.isPlaying = playing;
  }

  onMediaReady() {
    this.aspectRatioData = transformToAspectRatio(
      this.videoPlayer.nativeElement.offsetWidth,
      this.videoPlayer.nativeElement.offsetHeight,
      this.aspectRatio,
    );
  }
}
