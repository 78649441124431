<section nz-col class="overview-content">
  <div nz-col nzXs="24">
    <section nz-row [nzGutter]="[0, 32]">
      <div nz-col nzXs="24" nzXl="24">
        <div class="indicator-with-border">
          <div class="user">
            <div class="stat-item">
              <survey-stats-indicators-single-indicator
                title="Number of Teammates"
                boldTitle="true"
                size="medium"
                [value]="accounts?.length || 0"
                [loading]="isLoading"
                [thousandsSuffixes]="true"
                [maximumFractionDigits]="1"
              ></survey-stats-indicators-single-indicator>
            </div>
            <div class="stat-item">
              <survey-stats-indicators-single-indicator
                title="Current Active Teammates"
                boldTitle="true"
                size="medium"
                [value]="activeAccounts?.length || 0"
                [loading]="isLoading"
                [thousandsSuffixes]="false"
                [maximumFractionDigits]="1"
              ></survey-stats-indicators-single-indicator>
            </div>
            <div class="stat-item">
              <survey-stats-indicators-single-indicator
                title="Most Active Teammate"
                boldTitle="true"
                size="medium"
                [loading]="isLoading"
              >
                <org-account-avatar
                  *ngIf="mostActive"
                  [account]="mostActive"
                  [withFullname]="true"
                  size="small"
                ></org-account-avatar>
              </survey-stats-indicators-single-indicator>
            </div>
            <div class="stat-item" *ngIf="mostActive?.id !== leastActive?.id">
              <survey-stats-indicators-single-indicator
                title="Least Active Teammate"
                boldTitle="true"
                size="medium"
                [loading]="isLoading"
              >
                <org-account-avatar
                  *ngIf="leastActive"
                  [account]="leastActive"
                  [withFullname]="true"
                  size="small"
                ></org-account-avatar>
              </survey-stats-indicators-single-indicator>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</section>
<h2>Your Teammates</h2>
<section class="content d100">
  <div class="filters-bar">
    <div class="left">
      <div class="tags-filter">
        <nz-select
          nzShowSearch
          nzAllowClear
          nzSuffixIcon="caret-down"
          nzPlaceHolder="All Tags"
          nzSize="large"
          nzMode="multiple"
          [ngModel]="filteredTags"
          [nzShowArrow]="true"
          (ngModelChange)="onTagsFilterChange($event)"
        >
          <ng-container *ngFor="let tag of usersTags">
            <nz-option [nzLabel]="tag" [nzValue]="tag"></nz-option>
          </ng-container>
        </nz-select>
      </div>
      <div class="tags-filter">
        <nz-select
          nzShowSearch
          nzAllowClear
          nzSuffixIcon="caret-down"
          nzPlaceHolder="All Workspaces"
          nzSize="large"
          nzMode="multiple"
          [ngModel]="filteredWorkspacesIDs"
          [nzShowArrow]="true"
          (ngModelChange)="onWorkspacesFilterChange($event)"
        >
          <ng-container *ngFor="let workspace of workspaces">
            <nz-option
              [nzLabel]="workspace.name"
              [nzValue]="workspace.id"
            ></nz-option>
          </ng-container>
        </nz-select>
      </div>
      <div class="search-bar">
        <form nz-form (ngSubmit)="onSearchChangeImmediate(keyword)">
          <nz-input-group
            nzSize="large"
            [nzPrefix]="prefixIcon"
            [nzSuffix]="inputClearButton"
          >
            <input
              type="text"
              nz-input
              placeholder="Search a teammate"
              [ngModel]="keyword"
              (ngModelChange)="onSearchChange($event)"
              [ngModelOptions]="{ standalone: true }"
            />
          </nz-input-group>
          <ng-template #prefixIcon>
            <i nz-icon nzType="search"></i>
          </ng-template>
          <ng-template #inputClearButton>
            <i
              nz-icon
              class="ant-input-clear-icon"
              nzTheme="fill"
              nzType="close-circle"
              *ngIf="keyword"
              (click)="onSearchChangeImmediate('')"
            ></i>
          </ng-template>
        </form>
      </div>
      <!-- <div class="mfa-switch">
            <span
              >Enforce 2FA
              <span
                class="help"
                nz-popover
                [nzPopoverContent]="TwoFADescriptionTemplate"
                nzPopoverPlacement="top"
              >
                <i nz-icon nzType="question-circle" nzTheme="twotone"></i>
              </span>
            </span>

            <ng-template #TwoFADescriptionTemplate>
              <div style="max-width: 300px">
                <p>
                  2-factor authentication (2FA) is a security method based on
                  identity and access management that requires two forms of
                  identification to access resources and data.
                </p>
              </div>
            </ng-template>

            <nz-switch
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [ngModel]="superOrg.flags.mfa_enforced"
              (ngModelChange)="onEnforceMFA($event)"
            >
            </nz-switch>
          </div> -->
    </div>
    <div class="right">
      <button
        *ngIf="'super-org.member.create' | hasSuperPermission"
        title="Add a new teammate"
        nz-button
        nzType="primary"
        nzSize="large"
        (click)="onAdd()"
      >
        <screeb-icon size="sm" icon="plus"></screeb-icon>
        <span>Add a new teammate</span>
      </button>
    </div>
  </div>

  <div class="account-table-group">
    <nz-table
      class="account-table"
      #table
      [nzData]="filteredAccounts"
      [nzPageSize]="100"
      nzNoResult="No teammates found"
      [nzShowSizeChanger]="true"
      [nzFrontPagination]="true"
      [nzHideOnSinglePage]="true"
      [nzPageSizeOptions]="[10, 25, 100]"
      [nzScroll]="{ x: '1100px' }"
    >
      <thead>
        <tr>
          <th
            *ngFor="let column of listOfColumns"
            [(nzSortOrder)]="column.sortOrder"
            [nzSortFn]="column.sortFn"
            [nzSortDirections]="column.sortDirections"
            [nzFilterMultiple]="column.filterMultiple"
            [nzColumnKey]="column.sortKey"
            [nzWidth]="column.width"
            [nzAlign]="column.align"
          >
            {{ column.name }}
          </th>
          <th [nzWidth]="'140px'" nzRight></th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let user of table.data">
          <!--
                HACK: using (click), we cannot right-click on a row and select "open in a new table".
                So we added a "<a>" html tag between tbody and tr.
              -->
          <tr>
            <td nzAlign="left" class="bold-text" nzEllipsis="true">
              <div class="is-flex">
                <org-account-avatar
                  [account]="user"
                  size="small"
                ></org-account-avatar>
                {{ user.fullname }}
              </div>
              <div *ngIf="user.tags?.length" class="user-tags">
                <nz-tag *ngFor="let tag of user.tags.slice(0, 3)">
                  {{ tag }}
                </nz-tag>
                <nz-tag *ngIf="user.tags.length > 3">
                  +{{ user.tags.length - 3 }}
                </nz-tag>
              </div>
            </td>
            <td nzAlign="center">
              {{ user.email }}
            </td>
            <td nzAlign="center">
              {{ user.workspaces_accesses?.length || 0 }}
            </td>
            <td nzAlign="center">
              {{
                user.job_title?.length
                  ? (accountJobTitlesToLabel[user.job_title] ?? user.job_title)
                  : "N/A"
              }}
            </td>
            <td nzAlign="center">
              {{ accountOrgRoleToLabel[user.role] }}
            </td>
            <td nzAlign="center">
              {{
                user.last_seen_at | dfnsFormatDistanceToNow: { addSuffix: true }
              }}
            </td>
            <td nzAlign="right" class="actions" nzRight>
              <div>
                <button
                  *ngIf="
                    sessionService?.session &&
                    sessionService.session.id !== user.id &&
                    ('super-org.member.delete' | hasSuperPermission)
                  "
                  title="Remove member"
                  nz-button
                  nzType="default"
                  nzSize="large"
                  nzDanger
                  (click)="deleteMember(user)"
                >
                  <i nz-icon nzType="delete"></i>
                </button>
                <button
                  *ngIf="'super-org.member.update' | hasSuperPermission"
                  (click)="onEdit(user)"
                  title="Edit user"
                  nz-button
                  nzSize="large"
                  nzType="primary"
                >
                  <img
                    alt="Edit"
                    nz-icon
                    src="/assets/icons/homepage/pen.svg"
                  />
                </button>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </nz-table>
  </div>
</section>
<add-user-modal
  [isOpen]="addOpened"
  (modalOpenChange)="onClose($event)"
  [workspaces]="workspaces"
  [superOrg]="superOrg"
  [tags]="usersTags"
  [user]="editUser"
/>
