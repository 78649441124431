import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import dayjs from "dayjs/esm";
import { Org } from "models/org.model";
import { OrgFlagsHomeGridRange } from "models/org.model";
import { CTAType } from "models/survey.dao.types";
import { Survey } from "models/survey.model";

@Component({
  selector: "widget-wrapper",
  templateUrl: "./widget-wrapper.component.html",
  styleUrls: ["./widget-wrapper.component.scss"],
})
export class WidgetWrapperComponent implements OnChanges {
  @Output() deleteClicked = new EventEmitter<CTAType>();

  @Input() type: string;
  @Input() range: OrgFlagsHomeGridRange;
  @Input() editing: boolean;

  @Input() org: Org;
  @Input() orgHasRespondents: boolean;
  @Input() surveys: Survey[];

  public startDate: Date;
  public endDate: Date;

  constructor() {
    this.setupDates();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.range?.currentValue !== changes.range?.previousValue) {
      this.setupDates();
    }
  }

  private setupDates() {
    switch (this.range) {
      case "day":
        // 24 hours back
        this.startDate = dayjs().subtract(1, "day").toDate();
        this.endDate = dayjs().toDate();
        break;
      case "week":
        // 7 days back
        this.startDate = dayjs().subtract(7, "day").toDate();
        this.endDate = dayjs().toDate();
        break;

      case "year":
        // 365 days back
        this.startDate = dayjs().subtract(365, "day").toDate();
        this.endDate = dayjs().toDate();
        break;
      case "quarter":
        // 90 days back
        this.startDate = dayjs().subtract(90, "day").toDate();
        this.endDate = dayjs().toDate();
        break;
      case "month":
      default:
        // 30 days back
        this.startDate = dayjs().subtract(30, "day").toDate();
        this.endDate = dayjs().toDate();
        break;
    }
  }
}
