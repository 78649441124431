import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NotificationHelper } from "helpers/notification.helper";
import { NzModalService } from "ng-zorro-antd/modal";
import { AccountMFAToLabel } from "./account-mfa.type";
import { AuthService } from "services/auth.service";

@Component({
  selector: "account-mfa",
  templateUrl: "./account-mfa.component.html",
  styleUrls: ["./account-mfa.component.scss"],
})
export class SettingsAccountMFAComponent {
  // @Input()
  // public mfa: AccountMFAStatus;
  @Input()
  public isEnabled: boolean = false;
  @Input()
  public enforced: boolean = false;
  @Input()
  public error: boolean = false;

  @Output()
  public accountMFAChange = new EventEmitter<boolean>();

  public accountMFAToLabel = AccountMFAToLabel;
  public loading = false;

  constructor(
    private authService: AuthService,
    private notificationHelper: NotificationHelper,
    private modalService: NzModalService,
  ) {}

  onEnable() {
    if (this.isEnabled) {
      return;
    }

    this.authService
      .enrollMFA()
      .then(() => {
        this.accountMFAChange.emit(true);
        this.notificationHelper.trigger(
          "Success",
          "Your Screeb account is now protected with 2FA!",
          "success",
        );
      })
      .catch(() =>
        this.notificationHelper.trigger(
          "Error",
          "Could not add 2FA to account. Please try again later.",
          "error",
        ),
      );
  }

  onDisable() {
    this.modalService.warning({
      nzTitle: "Are you sure you want to disable 2FA on your Screeb account?",
      nzContent:
        "You will loose a security level on your account.<br>You'll still be able to re-enable in the future if needed.",
      nzStyle: {
        display: "flex",
        "align-items": "center",
        "justify-content": "center",
      },
      nzMaskClosable: true,
      nzCloseOnNavigation: false,
      nzOkType: "default",
      nzOkDanger: true,
      nzOkText: "Confirm",
      nzCancelText: "Cancel",
      nzOnOk: () => {
        this.authService
          .deleteMFA()
          .then(() => {
            this.notificationHelper.trigger(
              "Success",
              "2FA has been successfully disabled on this Screeb account.",
              "success",
            );

            this.accountMFAChange.emit(false);
          })
          .catch(() =>
            this.notificationHelper.trigger(
              "Error",
              "Could not disable 2FA. Please try again later.",
              "error",
            ),
          )
          .finally(() => (this.loading = false));
      },
      nzOnCancel: () => {
        this.loading = false;
      },
    });
  }
}
