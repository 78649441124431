<div *ngIf="ruleRecurrence || ruleIfAnswered">
  <div class="rule-type-recurrence">
    <div class="rule-type-recurrence-content">
      <p class="rule-type-recurrence-title">Ask again</p>
      <nz-input-group
        [nzSuffix]="'days'"
        [nzPrefix]="'Every:'"
        [ngClass]="{
          invalid: !checkValidValue(),
        }"
      >
        <rule-input-number
          [(value)]="ruleRecurrence.value.v_n"
          [step]="1"
          [minValue]="1"
          [maxValue]="365"
          [inputGroupMode]="true"
          [placeholder]="ruleRecurrence?.getValuePlaceholder() ?? ''"
          [disabled]="!('survey_edition' | hasEntitlement)"
        ></rule-input-number>
      </nz-input-group>
      <p class="rule-type-recurrence-title">
        if responded
        <i
          nz-icon
          nzType="question-circle"
          nzTheme="outline"
          nzTooltipTitle="Leave empty if you don't want to use this condition"
          nzTooltipPlacement="rightBottom"
          nz-tooltip
        ></i>
      </p>
    </div>

    <div class="rule-type-recurrence-content">
      <p class="rule-type-recurrence-title">Ask again</p>
      <nz-input-group
        [nzSuffix]="'days'"
        [nzPrefix]="'Every:'"
        [ngClass]="{
          invalid: !checkValidValue(),
        }"
      >
        <rule-input-number
          [(value)]="ruleIfAnswered?.value.v_n"
          [step]="1"
          [minValue]="1"
          [maxValue]="365"
          [inputGroupMode]="true"
          [placeholder]="ruleIfAnswered?.getValuePlaceholder() ?? ''"
          [disabled]="!('survey_edition' | hasEntitlement)"
        ></rule-input-number>
      </nz-input-group>
      <p class="rule-type-recurrence-title">
        if not responded
        <i
          nz-icon
          nzType="question-circle"
          nzTheme="outline"
          nzTooltipTitle="Leave empty if you don't want to use this condition"
          nzTooltipPlacement="rightBottom"
          nz-tooltip
        ></i>
      </p>
    </div>
  </div>
</div>
