import { ChartDataset } from "chart.js";
import { AnalyticsResponse } from "models/analytics.model";
import { SurveyScenario } from "models/survey.dao.types";
import { RepartitionIndicatorValues } from "../components/repartition-indicator/repartition-indicator.component";
import { TrendIndicatorLegend } from "../components/trend-indicator/trend-indicator.config";
import {
  bucketToCountPerKey,
  computeIndicatorVariation,
  computeRepartition,
  computeVariation,
  getDateAnswerValueBuckets,
  replaceBucketsKeys,
} from "../indicator.utils";

export type NpsAggregation = {
  score: number;
  promoters: number;
  passives: number;
  detractors: number;
  total: number;
  date: Date | null;
};

export const npsEmojis = [
  "0️⃣",
  "1️⃣",
  "2️⃣",
  "3️⃣",
  "4️⃣",
  "5️⃣",
  "6️⃣",
  "7️⃣",
  "8️⃣",
  "9️⃣",
  "🔟",
] as const;

export function computeNpsScore(
  promoters: number,
  passives: number,
  detractors: number,
): number {
  const total = promoters + passives + detractors;

  if (!total) {
    return 0;
  }

  return Math.round(((promoters - detractors) / total) * 100);
}

export function computeNpsPeriod(npsPerDate: NpsAggregation[]): NpsAggregation {
  const detractors = npsPerDate.reduce(
    (acc: number, nps: NpsAggregation): number => acc + nps.detractors,
    0,
  );
  const passives = npsPerDate.reduce(
    (acc: number, nps: NpsAggregation): number => acc + nps.passives,
    0,
  );
  const promoters = npsPerDate.reduce(
    (acc: number, nps: NpsAggregation): number => acc + nps.promoters,
    0,
  );
  const total = npsPerDate.reduce(
    (acc: number, nps: NpsAggregation): number => acc + nps.total,
    0,
  );
  const score = computeNpsScore(promoters, passives, detractors);

  return {
    detractors,
    passives,
    promoters,
    total,
    score,
    date: null,
  };
}

export function computeNpsRepartition(
  periodBucket: AnalyticsResponse,
  scenario: SurveyScenario,
): RepartitionIndicatorValues {
  const getColor = (index: number) => {
    const style = window.getComputedStyle(document.body);
    if (index <= 6) {
      return style.getPropertyValue("--screeb-color-red-500");
    } else if (index <= 8) {
      return style.getPropertyValue("--screeb-color-yellow-500");
    }
    return style.getPropertyValue("--screeb-color-green-500");
  };

  const getLabel = (index: number) => {
    return npsEmojis[index];
  };

  return computeRepartition(
    periodBucket,
    ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
    getColor,
    getLabel,
    scenario,
  );
}

export function computeNpsVariationBewteenPeriod(
  npsPreviousPeriod: NpsAggregation,
  npsCurrentPeriod: NpsAggregation,
): NpsAggregation {
  if (!npsPreviousPeriod.total) {
    return null;
  }

  return {
    detractors: computeIndicatorVariation(
      npsPreviousPeriod.detractors,
      npsPreviousPeriod.total,
      npsCurrentPeriod.detractors,
      npsCurrentPeriod.total,
    ),
    passives: computeIndicatorVariation(
      npsPreviousPeriod.passives,
      npsPreviousPeriod.total,
      npsCurrentPeriod.passives,
      npsCurrentPeriod.total,
    ),
    promoters: computeIndicatorVariation(
      npsPreviousPeriod.promoters,
      npsPreviousPeriod.total,
      npsCurrentPeriod.promoters,
      npsCurrentPeriod.total,
    ),
    score: computeVariation(npsPreviousPeriod.score, npsCurrentPeriod.score),
    total: computeVariation(npsPreviousPeriod.total, npsCurrentPeriod.total),
    date: null,
  };
}

export function bucketToNpsAggregation(
  oneDayNps: Record<string, any>,
  getBuckets = getDateAnswerValueBuckets,
  scenarios: SurveyScenario[],
): NpsAggregation {
  const { countPerKey, total } = bucketToCountPerKey(
    replaceBucketsKeys(getBuckets(oneDayNps), scenarios),
    ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
  );

  const date = oneDayNps.date;

  // aggregate per promoter/passives/detractor
  const detractors =
    countPerKey["0"] +
    countPerKey["1"] +
    countPerKey["2"] +
    countPerKey["3"] +
    countPerKey["4"] +
    countPerKey["5"] +
    countPerKey["6"];
  const passives = countPerKey["7"] + countPerKey["8"];
  const promoters = countPerKey["9"] + countPerKey["10"];
  const score = computeNpsScore(promoters, passives, detractors);

  return { score, promoters, passives, detractors, total, date };
}

export function npsPerDateToChartDataset(
  npsPerDate: NpsAggregation[],
  legend: TrendIndicatorLegend,
): ChartDataset[] {
  const npsToData = (field: "promoters" | "passives" | "detractors") =>
    npsPerDate.map((nps) => {
      return { x: +nps.date, y: (nps[field] / nps.total) * 100 };
    });

  const style = {
    yAxisID: "main",
    fill: true,
  };

  return [
    {
      yAxisID: "secondary",
      data: npsPerDate.map(({ date, score }) => ({
        x: +date,
        y: score,
      })),
      label: "NPS",
      fill: "transparent",
      borderColor: "#5E21F1",
      pointBorderColor: "transparent",
      pointBackgroundColor: "#5E21F1",
      pointHoverBackgroundColor: "#5E21F1",
      radius: 4,
      hoverRadius: 6,
    },
    {
      ...style,
      data: npsToData("detractors"),
      label: "Detractors",
      borderColor: legend[1].color,
      backgroundColor: legend[1].color,
      hoverBackgroundColor: legend[1].hoverColor,
      hoverBorderColor: legend[1].hoverColor,
    },
    {
      ...style,
      data: npsToData("passives"),
      label: "Passives",
      borderColor: legend[2].color,
      backgroundColor: legend[2].color,
      hoverBackgroundColor: legend[2].hoverColor,
      hoverBorderColor: legend[2].hoverColor,
    },
    {
      ...style,
      data: npsToData("promoters"),
      label: "Promoters",
      borderColor: legend[3].color,
      backgroundColor: legend[3].color,
      hoverBackgroundColor: legend[3].hoverColor,
      hoverBorderColor: legend[3].hoverColor,
    },
  ];
}
