import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  RouterStateSnapshot,
} from "@angular/router";

// https://medium.com/better-programming/angular-how-keep-user-from-lost-his-data-by-accidentally-leaving-the-page-before-submit-4eeb74420f0d
@Injectable()
class GenericCanDeactivate implements CanDeactivate<any> {
  constructor() {}

  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): boolean {
    if (!component || !component.canDeactivate) {
      return true;
    }

    return component.canDeactivate(
      component,
      currentRoute,
      currentState,
      nextState,
    );
    // if (component.hasUnsavedData === true) {
    //     currentState['test'] = 'tata';
    //     return confirm("You have unsaved changes! If you leave, your changes will be lost.");
    // }
    // return true;
  }
}

export { GenericCanDeactivate };
