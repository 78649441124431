<label for="actions-integrations" class="lateral-panel-subtitle">
  <div class="left">
    Integration (optional)
    <span
      class="help"
      nz-popover
      nzPopoverTitle="Integration"
      [nzPopoverContent]="integrationsHelpTemplate"
      nzPopoverPlacement="leftTop"
    >
      <i nz-icon nzType="question-circle" nzTheme="twotone"></i>
    </span>
  </div>

  <ng-template #integrationsHelpTemplate>
    Integrate Screeb to your stack.
    <br />
    <a
      rel="noopener"
      href="https://developers.screeb.app/api-and-connectors/webhook-v2.1.0"
      target="_blank"
    >
      <i nz-icon nzType="link"></i>
      Read more about webhooks and how to use them in our Help Center.
    </a>
  </ng-template>
</label>

<ul>
  <li
    *ngFor="let hook of hooks; let i = index"
    class="hook-item hook-item-type-{{ hook.integrationType }}"
  >
    <ng-container *ngIf="hook.integrationType === 'webhook'">
      <nz-input-group nzAddOnBefore="POST" [nzSuffix]="moreButton">
        <input
          [ngClass]="{ invalid: !!hook.error }"
          name="actions-integrations-{{ i }}"
          id="actions-integrations-{{ i }}"
          type="url"
          class="panel-input"
          placeholder="Webhook url"
          [(ngModel)]="hook.settings.path"
          (ngModelChange)="onHookChange()"
        />
      </nz-input-group>
    </ng-container>

    <ng-container *ngIf="hook.integrationType === 'zapier'">
      <nz-input-group [nzSuffix]="moreButton">
        <input
          [ngClass]="{ invalid: !!hook.error }"
          name="actions-integrations-{{ i }}"
          id="actions-integrations-{{ i }}"
          type="url"
          class="panel-input"
          placeholder="Zap url"
          [(ngModel)]="hook.settings.path"
          (ngModelChange)="onHookChange()"
        />
      </nz-input-group>
    </ng-container>

    <ng-template #moreButton>
      <!-- <i nz-icon nzType="close" (click)="onHookRemove(i)"></i> -->
      <i
        nz-icon
        nzType="more"
        nz-dropdown
        nzTrigger="click"
        [nzDropdownMenu]="dropdownMore"
      ></i>
    </ng-template>

    <nz-dropdown-menu #dropdownMore="nzDropdownMenu">
      <ul nz-menu>
        <li nz-menu-item (click)="onHookReplay(hook)">
          Re-Send All Historical Data
        </li>
        <li nz-menu-item (click)="onHookSendSample(hook)">Send Test Data</li>
        <li nz-menu-item (click)="onHookRemove(i, hook.integrationType)">
          Remove
        </li>
      </ul>
    </nz-dropdown-menu>

    <utils-form-error
      [errors]="!!hook.error ? [hook.error] : []"
    ></utils-form-error>
  </li>
</ul>

<div class="add-buttons">
  <button
    *ngIf="getHooksByType('webhook').length === 0"
    nz-button
    nzType="primary"
    (click)="onHookAdd('webhook')"
    [disabled]="!('integration_webhook' | hasEntitlement)"
  >
    + Webhook
  </button>
  <button
    *ngIf="
      getHooksByType('zapier').length === 0 &&
      ('isIntegrationZapierAvailable' | hasFeatureFlag)
    "
    nz-button
    nzType="primary"
    (click)="onHookAdd('zapier')"
    [disabled]="!('integration_zapier' | hasEntitlement)"
  >
    + Zapier
  </button>
  <!-- <button nz-button nzType="primary" (click)="onHookAdd('zapier')">+ Zapier</button> -->
</div>

<upgrade-banner
  class="upgrade-banner"
  title=""
  text="You need to <strong>upgrade</strong> your account to unlock all integrations!"
  *ngIf="
    !('integration_zapier' | hasEntitlement) ||
    !('integration_webhook' | hasEntitlement)
  "
  [orgId]="org.id"
  [small]="true"
></upgrade-banner>
