<header>
  <h1>Mobile Client Screens</h1>
  <p>
    Client screens will allow you create more accurate survey targeting rules
    and will improve response analysis.
    <br />
    Learn how to track visitor events from Javascript SDK
    <a
      href="https://help.screeb.app/en/articles/8611585-all-about-users-events"
      target="_blank"
    >
      <u>here</u>
      .
    </a>
  </p>
</header>

<section id="results">
  <ng-template #notFound>
    <nz-empty nzNotFoundContent="No client screens found"></nz-empty>
  </ng-template>
  <nz-table
    #table
    [nzData]="clientScreenVersions"
    [nzHideOnSinglePage]="true"
    [nzNoResult]="notFound"
    [nzPageSize]="100"
  >
    <thead>
      <tr>
        <th
          *ngFor="let column of listOfColumns"
          [(nzSortOrder)]="column.sortOrder"
          [nzSortFn]="column.sortFn"
          [nzSortDirections]="column.sortDirections"
          [nzFilterMultiple]="column.filterMultiple"
          [nzFilters]="column.listOfFilter"
          [nzFilterFn]="column.filterFn"
        >
          {{ column.name }}
        </th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <ng-template ngFor let-channelClientVersion [ngForOf]="table.data">
        <tr
          (click)="onExpandTable(channelClientVersion)"
          [attr.data-app-version]="channelClientVersion.app_version"
        >
          <td class="uppercase">{{ channelClientVersion.app_version }}</td>
          <td class="uppercase">
            {{ channelClientVersion.sdk_versions.join(", ") }}
          </td>
          <td>
            {{ channelTypeFormatted[channelClientVersion.platform].title }}
          </td>
          <td>
            {{
              channelClientVersion.created_at
                | dfnsFormatDistanceToNow: { addSuffix: true }
            }}
          </td>
          <td nzAlign="center" class="actions">
            <button
              *ngIf="
                !clientScreenInfos[
                  getClientScreenVersionKey(channelClientVersion)
                ]?.expanded
              "
              nz-button
              nzType="default"
            >
              <i nz-icon nzType="down"></i>
            </button>
            <button
              *ngIf="
                !!clientScreenInfos[
                  getClientScreenVersionKey(channelClientVersion)
                ]?.expanded
              "
              class="ant-btn-secondary"
              nz-button
              nzType="default"
            >
              <i nz-icon nzType="up"></i>
            </button>
          </td>
        </tr>
        <tr
          [nzExpand]="
            clientScreenInfos[getClientScreenVersionKey(channelClientVersion)]
              ?.expanded
          "
        >
          <nz-table
            #nestedTable
            [nzData]="
              clientScreenInfos[getClientScreenVersionKey(channelClientVersion)]
                ?.formattedScreenNames
            "
            [nzHideOnSinglePage]="true"
            [nzPageSize]="99"
            [nzPaginationPosition]="'bottom'"
            class="nested-table"
          >
            <thead>
              <tr>
                <th
                  *ngFor="let column of listOfNestedColumns"
                  [(nzSortOrder)]="column.sortOrder"
                  [nzSortFn]="column.sortFn"
                  [nzSortDirections]="column.sortDirections"
                  [nzFilterMultiple]="column.filterMultiple"
                  [nzFilters]="column.listOfFilter"
                  [nzFilterFn]="column.filterFn"
                >
                  <div class="table-title">
                    {{ column.name }}
                    <ng-container *ngIf="column.actionsTpl">
                      <div class="actions-container">
                        <button
                          [disabled]="
                            channelClientVersion.screenHiddenCount ===
                            channelClientVersion.screens.length
                          "
                          class="action-button-link"
                          [class]="{
                            disabled:
                              channelClientVersion.screenHiddenCount ===
                              channelClientVersion.screens.length,
                          }"
                          nz-button
                          nz-type="link"
                          title="Hide all"
                          (click)="
                            showHideScreen(channelClientVersion, [], true)
                          "
                        >
                          Hide all
                        </button>
                        <button
                          [disabled]="
                            channelClientVersion.screenHiddenCount === 0
                          "
                          class="action-button-link"
                          [class]="{
                            disabled:
                              channelClientVersion.screenHiddenCount === 0,
                          }"
                          nz-button
                          nz-type="link"
                          title="Show all"
                          (click)="
                            showHideScreen(channelClientVersion, [], false)
                          "
                        >
                          Show all
                        </button>
                        <help-popover
                          [content]="content"
                          [placement]="'bottom'"
                        ></help-popover>

                        <ng-template #content>
                          <p>
                            Hide or show all screens for this version.
                            <br />
                          </p>
                          <p>
                            Hidden screens will not appear in targeting rules
                            parameters.
                          </p>
                        </ng-template>
                      </div>
                    </ng-container>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody class="nested-table-body">
              <tr *ngFor="let channelClientScreens of nestedTable.data">
                <td *ngFor="let screen of channelClientScreens">
                  {{ screen.name }}
                  <button
                    nz-button
                    nz-tooltip="Show/Hide"
                    nzType="text"
                    nzShape="circle"
                    (click)="
                      showHideScreen(
                        channelClientVersion,
                        [screen.id],
                        !screen.hidden
                      )
                    "
                  >
                    <i nz-icon nzType="eye" *ngIf="!screen.hidden"></i>
                    <i nz-icon nzType="eye-invisible" *ngIf="screen.hidden"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </nz-table>
        </tr>
      </ng-template>
    </tbody>
  </nz-table>
</section>
