<section class="gift-section">
  <h1>Pretty Gift !</h1>
  <p>Here is a pretty gift for you !</p>

  <div class="input-container">
    <p>Workspace ID</p>
    <input
      class="input short"
      [value]="workspaceId"
      (change)="workspaceId = $event.target.value"
    />
  </div>

  <div class="input-container">
    <p>Survey ID</p>
    <input
      class="input short"
      [value]="surveyId"
      (change)="surveyId = $event.target.value"
    />
  </div>

  <div class="input-container">
    <p>Answer Limit Size</p>
    <input
      class="input short"
      [value]="answerLimit"
      type="number"
      (change)="onAnswerLimitChange($event)"
    />
  </div>

  <nz-divider></nz-divider>

  <div class="input-container">
    <p>System Prompt</p>
    <textarea
      class="input"
      [value]="systemPrompt"
      (change)="systemPrompt = $event.target.value"
    ></textarea>
  </div>
  <div class="input-container">
    <p>User Prompt</p>
    <textarea
      class="input"
      [value]="userPrompt"
      (change)="userPrompt = $event.target.value"
    ></textarea>
  </div>

  <button
    nz-button
    nzType="primary"
    [nzLoading]="loading"
    class="chat-btn"
    (click)="chat()"
  >
    Chat
  </button>
  <p class="error" *ngIf="error !== null">{{ error }}</p>

  <p *ngIf="response.length > 0">Total Answer Analysed: {{ total }}</p>
  <markdown
    class="input response"
    *ngIf="response.length > 0"
    ngPreserveWhitespaces
    [data]="response"
  ></markdown>
</section>
