import {
  Directive,
  ElementRef,
  Renderer2,
  Input,
  OnInit,
  HostListener,
  OnDestroy,
  EventEmitter,
  Output,
} from "@angular/core";

type Position = {
  x: number;
  y: number;
};

@Directive({
  selector: "[draggableZone]",
  exportAs: "draggableZone",
})
export class DraggableZoneDirective implements OnDestroy, OnInit {
  private container: HTMLElement = null;
  private element: ElementRef = null;
  private renderer: Renderer2 = null;

  private moving = false;
  private initialMouseAbsolutePosition: Position = null;
  private initialContainerScroll: Position = null;

  @Input()
  set draggableZone(setting: any) {}

  @Output()
  scrollChange = new EventEmitter<{ x: number; y: number }>();

  private scrollCallback = (_: Event): void => undefined;

  constructor(element: ElementRef, renderer: Renderer2) {
    this.element = element;
    this.renderer = renderer;

    this.scrollCallback = ($event: Event) => {
      this.scrollChange.emit({
        x: $event.target["scrollLeft"],
        y: $event.target["scrollTop"],
      });
    };

    setTimeout(() => {
      this.container = element.nativeElement.parentElement;
      this.container?.addEventListener("scroll", this.scrollCallback);
    }, 0);
  }

  ngOnDestroy() {
    this.element = null;
    this.moving = false;

    this.container?.removeEventListener("scroll", this.scrollCallback);
  }

  ngOnInit() {
    this.container?.addEventListener("scroll", this.scrollCallback);
  }

  @HostListener("mousedown", ["$event"])
  @HostListener("touchstart", ["$event"])
  onMouseDown(event: MouseEvent | TouchEvent) {
    // 0. skip if already moving
    if (this.moving) return;

    // 1. skip right click;
    if (event instanceof MouseEvent && event.button === 2) return;

    // 2. if handle is set, the element can only be moved by handle
    const target = event.target || event.srcElement;
    if (!this.checkHandleTarget(target, this.container)) return;

    this.initialMouseAbsolutePosition = this.eventToPosition(event);

    this.initialContainerScroll = {
      x: this.container.scrollLeft,
      y: this.container.scrollTop,
    };

    this.moving = true;

    // event.stopPropagation();
    // event.preventDefault();
  }

  @HostListener("mouseup", ["$event"])
  @HostListener("mouseleave", ["$event"]) // checking if browser is IE or Edge
  @HostListener("touchend", ["$event"])
  @HostListener("touchcancel", ["$event"])
  onMouseUp(event: MouseEvent | TouchEvent) {
    // 0. skip if not already moving
    if (!this.moving) return;

    // 1. skip right click;
    if (event instanceof MouseEvent && event.button === 2) return;

    // 2. if handle is set, the element can only be moved by handle
    const target = event.target || event.srcElement;
    if (!this.checkHandleTarget(target, this.container)) return;

    this.moving = false;

    this.initialMouseAbsolutePosition = null;
    this.initialContainerScroll = null;

    // event.stopPropagation();
    // event.preventDefault();
  }

  @HostListener("mousemove", ["$event"])
  @HostListener("touchmove", ["$event"])
  onMouseMove(event: MouseEvent | TouchEvent) {
    // 0. skip if not already moving
    if (!this.moving) return;

    const currentMouseAbsolutePosition = this.eventToPosition(event);
    const diffMousePosition = {
      x: currentMouseAbsolutePosition.x - this.initialMouseAbsolutePosition.x,
      y: currentMouseAbsolutePosition.y - this.initialMouseAbsolutePosition.y,
    };

    this.container.scrollLeft =
      this.initialContainerScroll.x - diffMousePosition.x;
    this.container.scrollTop =
      this.initialContainerScroll.y - diffMousePosition.y;

    this.scrollChange.emit({
      x: this.container.scrollLeft,
      y: this.container.scrollTop,
    });

    // const nextRelativePosition = {
    //   x: this.initialMouseAbsolutePosition.x - this.initialZoneAbsolutePosition.x + diffMousePosition.x,
    //   y: this.initialMouseAbsolutePosition.y - this.initialZoneAbsolutePosition.y + diffMousePosition.y,
    // };

    // this.moveTo(nextRelativePosition);

    // event.stopPropagation();
    // event.preventDefault();
  }

  private eventToPosition(e: MouseEvent | TouchEvent) {
    if (this.isMouseEvent(e)) return { x: e.clientX, y: e.clientY };
    return { x: e.changedTouches[0].clientX, y: e.changedTouches[0].clientY };
  }

  private isMouseEvent(e: MouseEvent | TouchEvent): e is MouseEvent {
    return Object.prototype.toString.apply(e).indexOf("MouseEvent") === 8;
  }

  private checkHandleTarget(target: EventTarget, element: Element) {
    // Checks if the target is the element clicked, then checks each child element of element as well
    // Ignores button clicks

    // Ignore elements of type button
    if (["A", "BUTTON"].includes(element.tagName)) return false;

    // Ignore panels
    if (
      element.classList.contains("lateral-panel") ||
      element.classList.contains("preview-panel") ||
      element.classList.contains("disable-drag-and-drop")
    )
      return false;

    // If the target was found, return true (handle was found)
    if (element === target) return true;

    // Recursively iterate this elements children
    for (const child in element.children) {
      // eslint-disable-next-line no-prototype-builtins
      if (element.children.hasOwnProperty(child)) {
        if (this.checkHandleTarget(target, element.children[child]))
          return true;
      }
    }

    // Handle was not found in this lineage
    // Note: return false is ignore unless it is the parent element
    return false;
  }

  // private moveTo(nextRelativePosition: Position) {
  //   // limited by element corners
  //   nextRelativePosition = this.boundsCheck(nextRelativePosition);

  //   const value = `translate(${Math.round(nextRelativePosition.x)}px, ${Math.round(nextRelativePosition.y)}px)`;

  //   this.renderer.setStyle(this.element.nativeElement, 'transform', value);
  //   this.renderer.setStyle(this.element.nativeElement, '-webkit-transform', value);
  //   this.renderer.setStyle(this.element.nativeElement, '-ms-transform', value);
  //   this.renderer.setStyle(this.element.nativeElement, '-moz-transform', value);
  //   this.renderer.setStyle(this.element.nativeElement, '-o-transform', value);
  // }

  // private boundsCheck(nextRelativePosition: Position) {
  //   const containerRect = this.container.getBoundingClientRect();
  //   const elementRect = this.element.nativeElement.getBoundingClientRect();

  //   if (nextRelativePosition.x + elementRect.width < containerRect.width)
  //     nextRelativePosition.x = containerRect.width - elementRect.width;
  //   if (nextRelativePosition.x > 0)
  //     nextRelativePosition.x = 0;

  //   if (nextRelativePosition.y + elementRect.height < containerRect.height)
  //     nextRelativePosition.y = containerRect.height - elementRect.height;
  //   if (nextRelativePosition.y > 0)
  //     nextRelativePosition.y = 0;

  //   return nextRelativePosition;
  // }
}
