import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "settings-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class SettingsHeaderComponent implements OnInit {
  @Input() public title: string;
  @Input() public description: string;
  @Input() public isLoading: boolean = false;
  @Input() public isDisabled: boolean = false;
  @Input() public error: boolean | string | null = null;

  @Output() public save = new EventEmitter<void>();

  public isSaveUsed = false;

  ngOnInit() {
    this.isSaveUsed = this.save.observers.length > 0;
  }

  constructor() {}
}
