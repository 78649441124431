import { Component, EventEmitter, Input, Output } from "@angular/core";
import { RegistryEntry } from "models/registry.model";
import { UUID } from "models/survey.dao.types";

@Component({
  selector: "funnel-add-event",
  template: `
    <button
      nz-button
      nzSize="large"
      nzType="primary"
      nz-popover
      nzPopoverPlacement="rightBottom"
      nzPopoverTrigger="click"
      [nzPopoverContent]="contentTemplate"
      (nzPopoverVisibleChange)="onPopoverVisibleChange($event)"
      [(nzPopoverVisible)]="popoverVisible"
    >
      <span nz-icon nzType="plus" nzTheme="outline"></span>
      Add event
    </button>
    <ng-template #contentTemplate>
      <funnel-add-event-popover
        [registryEntriesEvent]="registryEntriesEvent"
        [(value)]="eventIds"
      ></funnel-add-event-popover>
      <div
        [style]="{
          margin: '0 12px 12px 0',
          display: 'flex',
          justifyContent: 'flex-end',
        }"
      >
        <button
          title="Save"
          nz-button
          class="ant-btn-secondary"
          nzSize="large"
          [disabled]="!isValid()"
          (click)="onSubmit()"
        >
          Save
        </button>
      </div>
    </ng-template>
  `,
  styles: [
    `
      :host {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: var(--screeb-color-body-text);
        display: block;
      }

      :host a {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        display: inline-flex;
        align-items: center;
        padding: 4px;
      }

      :host a svg {
        margin-left: 4px;
      }

      :host .text-bold {
        font-weight: 500;
      }
    `,
  ],
})
export class FunnelAddEventComponent {
  @Input() public registryEntriesEvent: RegistryEntry[] = [];
  @Output() public submitEvents = new EventEmitter<UUID[]>();

  public eventIds: UUID[] = [];
  public popoverVisible = false;

  onPopoverVisibleChange(visible: boolean): void {
    if (visible) {
      this.eventIds = [];
    }
  }

  isValid() {
    return this.eventIds.length > 0;
  }

  onSubmit() {
    this.popoverVisible = false;
    this.submitEvents.emit(this.eventIds);
  }
}
