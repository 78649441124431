<nz-modal
  [(nzVisible)]="isOpen"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  [nzClosable]="false"
  (nzOnOk)="send()"
  (nzOnCancel)="handleCancel()"
  nzAutofocus="ok"
  nzWidth="500px"
  nzClassName="forward-atlassian-modal"
>
  <ng-template #modalTitle>
    <h3>Select a Project</h3>
  </ng-template>

  <ng-template #modalContent>
    <div class="modal-content">
      <div *ngIf="!integration.auth_ok">
        <p class="has-error">
          ⚠️ You've been disconnected from Atlassian.
          <span *ngIf="!('integration.create' | hasPermission)">
            Please reconnect.
          </span>
          <a
            routerLink="/org/{{
              uiService.currentOrg.id
            }}/integrations/atlassian"
            *ngIf="'integration.create' | hasPermission"
          >
            Please reconnect.
          </a>
        </p>
      </div>

      <nz-select
        *ngIf="integration.auth_ok"
        class="atlassian-project-select"
        nzSize="large"
        nzShowSearch
        nzPlaceHolder="Select a project"
        [ngModel]="projectId"
        (ngModelChange)="onProjectChange($event)"
        [autofocus]="false"
        (keyup.enter)="$event.preventDefault(); send()"
      >
        <nz-option
          *ngFor="let project of projects"
          [nzLabel]="project.name"
          [nzValue]="project.id"
        ></nz-option>
      </nz-select>
    </div>
  </ng-template>

  <ng-template #modalFooter>
    <button
      nz-button
      nzType="default"
      class="forward-cancel-btn"
      (click)="handleCancel()"
    >
      Cancel
    </button>
    <button
      nz-button
      [class]="'atlassian-forward-btn'.concat(projectId ? '' : ' disabled')"
      [disabled]="!projectId || !integration.auth_ok"
      (click)="send()"
    >
      Send
    </button>
  </ng-template>
</nz-modal>
