<code>
  <p class="title">💥&nbsp;Oops!</p>
  <p class="message">{{ message }}</p>
  <p class="details">{{ details }}</p>

  <p class="buttons">
    <button
      *ngIf="!gotoLink"
      nz-button
      class="ant-btn-primary"
      nzSize="medium"
      nzShape="round"
      (click)="reloadPage()"
    >
      <i nz-icon nzType="reload"></i>
      Refresh page
    </button>
    <a
      *ngIf="gotoLink"
      [routerLink]="gotoLink"
      routerLinkActive="active"
      nz-button
      nzType="primary"
      nzShape="round"
    >
      {{ gotoLabel }}
    </a>
  </p>
</code>
