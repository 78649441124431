import { Injectable } from "@angular/core";

import { ENV } from "environment";
import { BillingSettings } from "models/org_billing.model";
import { ScreebApiHelper } from "services/auth.service";
import { uuidv4 } from "utils/uuid";

const availableTemplateLanguages = ["en", "fr"];
type TemplateLanguages = "en" | "fr";

type SurveyTemplate = {
  slug: string;
  survey_id: string;
  name: string;
  description: string[];
  background: string;
  language: TemplateLanguages;
  is_popular: boolean;
  is_inspired_by_customers: boolean;
};

type SurveyTemplateGroup = {
  slug: string;
  title: object;
  surveys: SurveyTemplate[];
};

export type AdminSettingsLastBannerAndModalClose = {
  last_banner_close_time_mtu_exceeded?: number;
  last_banner_close_time_mtu_almost_reached?: number;
  last_banner_close_time_free_trial_active?: number;
  last_modal_close_time_free_trial_ended?: number;
};
export type AdminSettings = {
  last_organization_id?: string;
  last_super_organization_id?: string;
  funnel_create_survey_from?: "edit" | "report";
} & AdminSettingsLastBannerAndModalClose;

const defaultAdminSettings: AdminSettings = {};

@Injectable()
class SettingsService {
  public billing: BillingSettings;
  public surveyTemplateGroups: SurveyTemplateGroup[];
  public messageTemplateGroups: SurveyTemplateGroup[];
  public tagResponseBackgroundColor: string;
  public tagResponseTextColor: string;

  constructor(private screebApiHelper: ScreebApiHelper) {}

  public load(): Promise<any> {
    return this.screebApiHelper
      .get<object>(`/settings`)
      .toPromise()
      .then((data: object) => {
        this.billing = data["billing"] as BillingSettings;
        this.surveyTemplateGroups = data[
          "survey_templates"
        ] as SurveyTemplateGroup[];
        this.messageTemplateGroups = []; // TODO
        this.tagResponseBackgroundColor = data["tag_response_background_color"];
        this.tagResponseTextColor = data["tag_response_text_color"];
      });
  }

  public getAdminSettings(): AdminSettings {
    let settings = {};
    try {
      settings = JSON.parse(
        window.localStorage.getItem(`x-screeb-admin-${ENV.ENV}`),
      );
    } catch (err) {
      console.error(err);
    }

    return Object.assign({}, defaultAdminSettings, settings);
  }
  private setAdminSettings(settings: AdminSettings) {
    window.localStorage.setItem(
      `x-screeb-admin-${ENV.ENV}`,
      JSON.stringify(settings),
    );
  }
  public setAdminSettingsKey<
    K extends keyof AdminSettings,
    V extends AdminSettings[K],
  >(settingsKey: K, value: V) {
    window.localStorage.setItem(
      `x-screeb-admin-${ENV.ENV}`,
      JSON.stringify({
        ...this.getAdminSettings(),
        [settingsKey]: value,
      }),
    );
  }
  public getAdminAnonymousId(): string {
    return window.localStorage.getItem(`x-screeb-admin-${ENV.ENV}-anonymous`);
  }
  public getOrCreateAdminAnonymousId(): string {
    const anonymousId = this.getAdminAnonymousId();

    if (!anonymousId) {
      const newId = uuidv4();
      this.setAdminAnonymousId(newId);

      return newId;
    } else {
      return anonymousId;
    }
  }
  public setAdminAnonymousId(anonymousId: string) {
    window.localStorage.setItem(
      `x-screeb-admin-${ENV.ENV}-anonymous`,
      anonymousId,
    );
  }

  public getDefaultLanguage(): TemplateLanguages | null {
    const defaultLanguage = window.localStorage.getItem(
      `x-screeb-default-language`,
    );

    if (
      defaultLanguage &&
      availableTemplateLanguages.includes(defaultLanguage)
    ) {
      return defaultLanguage as TemplateLanguages;
    } else {
      return null;
    }
  }

  public setDefaultLanguage(language: TemplateLanguages) {
    window.localStorage.setItem(`x-screeb-default-language`, language);
  }
}

export {
  SettingsService,
  SurveyTemplate,
  SurveyTemplateGroup,
  availableTemplateLanguages,
  TemplateLanguages,
};
