import { HttpErrorResponse } from "@angular/common/http";
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NzSelectOptionInterface } from "ng-zorro-antd/select";

import { NotificationHelper } from "helpers/notification.helper";

import { RangeSelect } from "components/utils/range-button/range-button.component";
import {
  AnalyticsFilters,
  AnalyticsFiltersOperator,
} from "models/analytics.filters.type";
import { hookIntegrationsSpec } from "models/hook.model";
import {
  IntegrationDao,
  IntegrationNotionDatabase,
} from "models/integration.dao";
import { Integration } from "models/integrations.model";
import { Org } from "models/org.model";
import {
  esFiltersToSurveyResponseFilterGroup,
  Survey,
} from "models/survey.model";
import { EntitlementService } from "services/entitlement.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { TrackersService } from "services/trackers.service";
import { Debounce } from "utils/debounce";

@Component({
  selector: "share-lateral-panel-notion",
  templateUrl: "./share-via-notion.component.html",
  styleUrls: ["./share-via-notion.component.scss"],
})
export class NotionShareLateralPanelComponent implements OnInit, OnDestroy {
  @Input() org: Org;
  @Input() survey: Survey;
  @Input() notionDatabaseId: string;
  @Input() notionVersion: string;
  @Input() integrations: Integration[];
  @Input() enabled: boolean;

  @Input() filtersOperator: AnalyticsFiltersOperator = null;
  @Input() filters: AnalyticsFilters = null;
  @Input() withFilters: boolean;

  @Output() notionDatabaseIdChange = new EventEmitter<string>();
  @Output() notionVersionChange = new EventEmitter<string>();
  @Output() enabledChange = new EventEmitter<boolean>();
  @Output() validChange = new EventEmitter<boolean>();
  @Output() triggerSave = new EventEmitter<void>();

  public quotaOk: boolean;

  public pages: NzSelectOptionInterface[] = [];
  public page: string;
  public database: IntegrationNotionDatabase;
  public databaseError: boolean = false;

  public creating: boolean = false;
  public replaying: boolean = false;
  public fetchingDatabase: boolean = false;

  public isNotionTimeout: boolean = false;
  public nbrOfSearches: number = 0;
  private searchingQuery: string = "";

  public integrationNotion?: Integration;
  public integrationNotionStatus?:
    | "not_installed"
    | "not_authed"
    | "loading"
    | "ok"
    | "error";

  private obs: any;

  constructor(
    private route: ActivatedRoute,
    private integrationDao: IntegrationDao,
    private entitlementService: EntitlementService,
    private notificationHelper: NotificationHelper,
    public featureFlaggingService: FeatureFlaggingService,
    private trackersService: TrackersService,
  ) {}

  ngOnInit(): void {
    this.quotaOk = this.entitlementService.isIntegrationAvailable("notion");

    this.fetchPages("").then(() => this.isNotionValid());

    if (this.notionDatabaseId) {
      this.fetchDatabase(this.notionDatabaseId).then(() =>
        this.isNotionValid(),
      );
    }
  }

  ngOnDestroy(): void {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  @Debounce(500)
  public onSearch(keyword: string) {
    this.fetchPages(keyword);
  }

  public async fetchPages(query: string) {
    this.searchingQuery = query;
    const currentQuery = query;
    this.integrationNotion = this.integrations.find(
      ({ type }) => type === "notion",
    );

    if (!this.integrationNotion) {
      this.integrationNotionStatus = "not_installed";
      return;
    }

    if (!this.integrationNotion.auth_ok) {
      this.integrationNotionStatus = "not_authed";
      return;
    }

    this.integrationNotionStatus = "loading";

    this.isNotionTimeout = false;

    this.nbrOfSearches++;

    const notionTimeout = setTimeout(() => {
      this.isNotionTimeout = true;
    }, 30000);

    const p = await this.integrationDao
      .notionListPages(this.org.id, query)
      .then(({ pages }) => {
        if (this.searchingQuery !== currentQuery) {
          return [];
        }
        this.integrationNotionStatus = "ok";

        return pages.map(({ id, name, emoji }) => ({
          value: id,
          label: (emoji ? emoji + " " : "") + name,
        }));
      })
      .catch((err: HttpErrorResponse) => {
        if (this.searchingQuery !== currentQuery) {
          return [];
        }
        console.error(err);
        this.integrationNotionStatus = "error";
        return [];
      });
    if (p.length > 0) {
      this.pages = p;
    }
    clearTimeout(notionTimeout);
  }

  public fetchDatabase(databaseId: string) {
    this.fetchingDatabase = true;
    this.databaseError = false;

    return this.integrationDao
      .notionGetDatabase(this.org.id, databaseId)
      .then((database) => {
        this.fetchingDatabase = false;
        this.databaseError = false;

        this.database = database;
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err);
        this.databaseError = true;
        this.fetchingDatabase = false;
      });
  }

  public createDatabase() {
    const pageName = "Screeb survey: " + this.survey.title;

    this.creating = true;

    return this.integrationDao
      .notionCreateDatabase(
        this.org.id,
        this.page,
        this.survey.id,
        pageName,
        hookIntegrationsSpec.notion.currentVersion,
      )
      .then((database) => {
        this.creating = false;
        this.databaseError = false;

        this.notionDatabaseId = database.id;
        this.notionVersion = database.version;
        this.database = database;

        this.notionDatabaseIdChange.emit(database.id);
        this.notionVersionChange.emit(this.notionVersion);

        this.trackersService
          .newEventTrackingBuilder("Integration Notion database created")
          .withOrg(this.org)
          .withSurvey(this.survey)
          .withProps({
            notion_database_id: database.id,
            notion_database_name: pageName,
            notion_database_version: this.notionVersion,
          })
          .build();
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err);
        this.creating = false;
      })
      .then(() => this.isNotionValid());
  }

  public remove() {
    this.database = null;
    this.notionDatabaseId = null;
    this.notionVersion = null;
  }

  public isNotionValid() {
    let valid = true;

    if (this.enabled) {
      valid =
        Boolean(this.notionDatabaseId) &&
        Boolean(this.notionVersion) &&
        Boolean(this.database);
    }

    this.validChange.emit(valid);
    return valid;
  }

  public onEnabledChange(enabled: boolean) {
    this.enabledChange.emit(enabled);
    this.isNotionValid();
  }

  public triggerReplay(range: RangeSelect) {
    if (this.isNotionValid()) {
      this.triggerSave.emit();

      const convertedFilters = esFiltersToSurveyResponseFilterGroup(
        this.filters,
        this.survey,
        this.filtersOperator,
      );

      this.replaying = true;

      if (range === null) {
        range = {
          from: new Date(2021, 1, 2),
          to: new Date(),
        };
      }

      this.integrationDao
        .triggerHookReplay(
          this.org.id,
          "notion",
          "response.ended",
          [this.survey.id],
          null,
          {
            notion_database_id: this.notionDatabaseId,
            notion_version: this.notionVersion,
          },
          range,
          this.withFilters ? convertedFilters : null,
        )
        .then(() => {
          this.notificationHelper.trigger("That's all folks!", null, "success");
        })
        .catch((err: HttpErrorResponse) => {
          console.error(err);

          this.notificationHelper.trigger(
            err?.error?.message ?? "Failed to replay responses",
            null,
            "error",
          );
        })
        .then(() => {
          this.replaying = false;
        });
    }
  }
}
