import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";

import { ApplicationLoadService } from "services/app.service";
import { Account } from "models/account.model";
import { SuperOrgDao } from "models/super-org.dao";

@Injectable()
export class SuperOrgAccountsResolver implements Resolve<any> {
  constructor(
    private applicationLoadService: ApplicationLoadService,
    private superOrgDao: SuperOrgDao,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Promise<Account[]> {
    return this.applicationLoadService
      .loader()
      .then(() => {
        return this.superOrgDao.getAllSuperOrgAccounts(
          route.params["super_org_id"],
        );
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        throw err;
      });
  }
}
