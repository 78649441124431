import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { integrationUpdate } from "components/integration/settings/integration-settings.component";
import { NotificationHelper } from "helpers/notification.helper";
import { IntegrationDao } from "models/integration.dao";
import { Integration } from "models/integrations.model";
import { Org } from "models/org.model";
import { Survey } from "models/survey.model";
import { TrackingEventName } from "services/trackers.events";
import { TrackersService } from "services/trackers.service";

@Component({
  selector: "integration-settings-zapier",
  templateUrl: "./zapier-settings.component.html",
  styleUrls: ["./zapier-settings.component.scss"],
})
export class IntegrationSettingsZapierComponent implements OnInit {
  @Input() public org: Org = null;
  @Input() public survey: Survey = null;
  @Input() public integration: Integration = null;
  @Input() public createIfEmpty: boolean = true;
  @Input() public hideListOnEmpty: boolean = false;
  @Input() public scope: "org" | "survey" = "org";

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix,@angular-eslint/no-output-rename
  @Output("onSettingsChange") public settings =
    new EventEmitter<integrationUpdate>();

  private currentlyExpandedItem: number = -1;
  private newItemId: string = null;

  constructor(
    public integrationDao: IntegrationDao,
    public notificationHelper: NotificationHelper,
    private trackersService: TrackersService,
  ) {}

  ngOnInit() {}

  public onCopy() {
    this.segmentEventTrack("Integration token copied");
  }

  public onReset() {
    this.integrationDao
      .resetIntegrationToken(this.org.id, this.integration.type)
      .then((r: Integration) => {
        this.segmentEventTrack("Integration token reset");
        this.integration.token = r.token;
        this.notificationHelper.trigger("Token Reset!", null, "success");
      })
      .catch((err) => {
        console.error(err);
        this.notificationHelper.trigger(
          "Error While resetting Token",
          null,
          "error",
        );
      });
  }

  private segmentEventTrack(eventName: TrackingEventName) {
    this.trackersService
      .newEventTrackingBuilder(eventName)
      .withOrg(this.org)
      .withIntegration(this.integration)
      .build();
  }
}
