import { Component, OnDestroy, OnInit } from "@angular/core";

import validateEmail from "all-good-emails";

import { ActivatedRoute } from "@angular/router";
import { PageComponentInterface } from "components/PageComponentInterface";
import { ONE_MEGA_OCTET } from "components/utils/file-image-picker/file-image-picker.component";
import { NotificationHelper } from "helpers/notification.helper";
import { AccountDao } from "models/account.dao";
import {
  Account,
  AccountJobTitlesToLabel,
  getProfilePicture,
} from "models/account.model";
import { SurveyLanguages } from "models/survey.dao.types";
import { UploadDao } from "models/upload.dao";
import { LanguageWithEmoji } from "resolvers/asset-languages-countries";
import { AuthService, SessionService } from "services/auth.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { RoutingService } from "services/routing.service";
import {
  AccountProvider,
  AccountProviders,
  accountProviderList,
} from "./provider/account-provider.type";

import { UIService } from "services/ui.service";
@Component({
  selector: "page-account-settings",
  templateUrl: "./account-settings.component.html",
  styleUrls: ["./account-settings.component.scss"],
})
export class AccountSettingsPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Account Settings";
  public name = "Account settings";

  private obs: any = null;

  public password = "";

  public maxAvatarSize = ONE_MEGA_OCTET * 10;
  public loading = false;
  public loadingPassword = false;
  public loadingTranslation = false;
  public loadingAvatar = false;
  public error: { [key: string]: string } = {};

  public getProfilePicture = (account: Account) => {
    return 'url("' + getProfilePicture(account) + '")';
  };
  public accountJobTitles = Object.keys(AccountJobTitlesToLabel);
  public accountJobTitlesToLabel = AccountJobTitlesToLabel;

  public canLinkAccount = false;
  public accountProviderList = accountProviderList;
  public accountProviderStatus: AccountProviders = {
    auth0: {
      provider: "auth0",
      linked: false,
      primary: false,
    },
    "google-oauth2": {
      provider: "google-oauth2",
      linked: false,
      primary: false,
    },
    office365: {
      provider: "office365",
      linked: false,
      primary: false,
    },
  };
  public accountProvidersError: string = null;

  public accountMFAEnabled = false;
  public mfaEnabled = false;
  public accountMFAsError: string = null;

  constructor(
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private notificationHelper: NotificationHelper,
    private uploadDao: UploadDao,
    private sessionService: SessionService,
    private accountDao: AccountDao,
    private authService: AuthService,
    private uiService: UIService,
    public featureFlaggingService: FeatureFlaggingService,
  ) {}

  public currentAccount: Account = null;

  public translationEnabled: boolean = false;
  public translationLanguage: SurveyLanguages = null;
  public accountTranslationLanguage: SurveyLanguages = null;
  public availableTranslationLanguages: LanguageWithEmoji[] = [];
  public hasTranslationChanged: boolean = false;

  public mfaEnforced: boolean = false;

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.obs = this.route.data.subscribe((data) => {
      this.translationLanguage =
        this.sessionService.session.flags.translation_language;
      this.accountTranslationLanguage = this.translationLanguage;
      this.refreshTranslationChanged();

      this.translationEnabled = this.translationLanguage !== null;

      this.availableTranslationLanguages =
        data.languages_and_countries.translationLanguagesWithEmojis;

      this.currentAccount = this.sessionService.session.copy();

      this.authService
        .getLinkedAccounts()
        .then((linkedAccounts) => {
          linkedAccounts.links.forEach((provider) => {
            this.accountProviderStatus[provider["provider"]] = provider;
          });
          this.canLinkAccount = linkedAccounts.canChange;

          if (!this.canLinkAccount) {
            this.accountProvidersError =
              "Your organization doesn't allow multiple provider per account.";
          }
        })
        .catch((err: Error) => {
          console.error(err);
          this.accountProvidersError = err.message;
        });

      this.authService
        .getMFAs()
        .then((mfas) => {
          this.mfaEnabled = mfas.length > 0;
        })
        .catch((err: Error) => {
          console.error(err);
          this.accountMFAsError = err.message;
        });

      this.accountMFAEnabled = this.currentAccount.flags.mfa;
      this.mfaEnforced = !!this.uiService.currentSuperOrg?.flags?.mfa_enforced;
    });
  }

  public haveErrors(error: { [key: string]: string }) {
    return Object.keys(error).length > 0;
  }

  public translationLanguageChange($event: SurveyLanguages) {
    this.translationLanguage = $event;
    this.refreshTranslationChanged();
  }

  public translationEnabledChange($event: boolean) {
    this.translationEnabled = $event;
  }

  public async imageAvatarFileChange(file: File) {
    this.loadingAvatar = true;
    this.error = {};
    this.uploadDao
      .uploadAccount("profile_picture", file)
      .then(({ public_url }) => {
        this.currentAccount.profile_picture = public_url;
      })
      .catch(() => {
        this.error["avatar"] = "Oops! Something went wrong. Please retry.";
      })
      .finally(() => {
        this.loadingAvatar = false;
      });
  }

  public isValidEmail({ email }: Account) {
    return validateEmail(email);
  }

  public isValidFirstname({ firstname }: Account) {
    return firstname && firstname.length >= 1 && firstname.length <= 28;
  }

  public isValidLastname({ lastname }: Account) {
    return lastname && lastname.length >= 1 && lastname.length <= 28;
  }

  public isValidJobTitle({ flags: { job_title } }: Account) {
    return job_title && job_title.length >= 1 && job_title.length <= 128;
  }

  public isValidProfilePicture({ profile_picture }: Account) {
    return (
      !profile_picture ||
      (profile_picture &&
        profile_picture.length >= 1 &&
        profile_picture.length <= 255)
    );
  }

  public isInvalid(account: Account) {
    return !(
      this.isValidEmail(account) &&
      this.isValidFirstname(account) &&
      this.isValidLastname(account) &&
      this.isValidJobTitle(account) &&
      this.isValidProfilePicture(account)
    );
  }

  public hasAccountNotChanged() {
    return (
      JSON.stringify(this.currentAccount) ===
      JSON.stringify(this.sessionService.session)
    );
  }

  public refreshTranslationChanged() {
    this.hasTranslationChanged =
      this.translationLanguage ===
      this.sessionService.session.flags.translation_language;
  }

  save(type: "pwd" | "account" | "translation" = "account") {
    if (type === "pwd") {
      this.loadingPassword = true;
    } else if (type === "translation") {
      this.loadingTranslation = true;
    } else {
      this.loading = true;
    }
    this.error = {};

    const { email, firstname, lastname, flags, profile_picture } =
      this.currentAccount;

    const payload = () => {
      switch (type) {
        case "pwd":
          return { password: this.password };
        case "translation":
          flags.translation_language = this.translationLanguage;
          return { flags };
        default:
          return {
            email,
            firstname,
            lastname,
            profile_picture,
            flags,
          };
      }
    };

    const successMessage = {
      //@ts-ignore - Bearer read this as a clear Password
      pwd: "Password updated successfully.",
      account: "Account updated successfully.",
      translation: "Translation preferences updated successfully.",
    };

    this.accountDao
      .update(payload())
      .then((account) => {
        if (account) {
          this.sessionService.update(account);
          this.currentAccount = account.copy();
          this.accountTranslationLanguage = this.translationLanguage;
        }
        this.password = "";

        this.notificationHelper.trigger(successMessage[type], null, "success");
      })
      .catch((err) => {
        console.error(err);
        this.error[type] = "Oops! Something went wrong. Please retry.";
      })
      .finally(() => {
        setTimeout(() => {
          this.loading = false;
          this.loadingPassword = false;
          this.loadingTranslation = false;
        }, 500);
      });
  }

  savePassword(password: string) {
    this.password = password;
    this.loadingPassword = true;
    this.save("pwd");
  }

  saveTranslation() {
    if (this.translationEnabled && !this.translationLanguage) {
      this.notificationHelper.trigger(
        "Failed to update user translation languages preferences, please retry.",
        null,
        "error",
      );
      return;
    }
    this.save("translation");
  }

  onMFAChange(status: boolean) {
    this.mfaEnabled = status;
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  public onConnectChange(provider: AccountProvider, status: boolean) {
    this.accountProviderStatus[provider].linked = status;
  }

  public onMFAEnabledChange(status: boolean) {
    this.authService.setMFA(status).then(() => {
      this.accountMFAEnabled = status;
    });
  }
}
