<div
  class="CardType"
  [style.background]="backgroundColor"
  [ngClass]="vertical ? 'vertical' : 'horizontal'"
>
  <div class="Letter" [style.color]="textColor">
    {{ letter }}
  </div>
  <div class="Icon">
    <card-type-icons [questionType]="questionType"></card-type-icons>
  </div>
</div>
