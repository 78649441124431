import { NgModule } from "@angular/core";
import { NZ_ICONS, NzIconModule } from "ng-zorro-antd/icon";

import {
  // ArrowLeftOutline,
  // ArrowRightOutline,
  ApiOutline,
  AppstoreAddOutline,
  BarChartOutline,
  CaretRightOutline,
  CheckCircleOutline,
  CheckOutline,
  ClearOutline,
  CloseOutline,
  CloseCircleOutline,
  CloudUploadOutline,
  DeleteOutline,
  DesktopOutline,
  DoubleLeftOutline,
  DoubleRightOutline,
  DownOutline,
  EditFill,
  EyeOutline,
  EyeInvisibleOutline,
  ExportOutline,
  FilterOutline,
  FireOutline,
  GiftOutline,
  HeartOutline,
  HomeOutline,
  InfoOutline,
  InfoCircleOutline,
  LeftOutline,
  LinkOutline,
  LoadingOutline,
  LogoutOutline,
  LockOutline,
  MobileOutline,
  MoreOutline,
  NotificationFill,
  NotificationOutline,
  PauseOutline,
  PlusOutline,
  ProfileOutline,
  QuestionCircleTwoTone,
  QrcodeOutline,
  RedoOutline,
  ReloadOutline,
  RightOutline,
  RocketOutline,
  SearchOutline,
  SettingOutline,
  SmileOutline,
  StarFill,
  StarOutline,
  TeamOutline,
  UndoOutline,
  UnorderedListOutline,
  UpOutline,
  UserOutline,
} from "@ant-design/icons-angular/icons";

const icons = [
  // ArrowLeftOutline,
  // ArrowRightOutline,
  ApiOutline,
  AppstoreAddOutline,
  BarChartOutline,
  CaretRightOutline,
  CheckCircleOutline,
  CheckOutline,
  ClearOutline,
  CloseOutline,
  CloseCircleOutline,
  CloudUploadOutline,
  DeleteOutline,
  DesktopOutline,
  DoubleLeftOutline,
  DoubleRightOutline,
  DownOutline,
  EditFill,
  EyeOutline,
  EyeInvisibleOutline,
  ExportOutline,
  FilterOutline,
  FireOutline,
  GiftOutline,
  HeartOutline,
  HomeOutline,
  InfoOutline,
  InfoCircleOutline,
  LeftOutline,
  LinkOutline,
  LoadingOutline,
  LogoutOutline,
  LockOutline,
  MobileOutline,
  MoreOutline,
  NotificationFill,
  NotificationOutline,
  PauseOutline,
  PlusOutline,
  ProfileOutline,
  QuestionCircleTwoTone,
  QrcodeOutline,
  RedoOutline,
  ReloadOutline,
  RightOutline,
  RocketOutline,
  SearchOutline,
  SettingOutline,
  SmileOutline,
  StarFill,
  StarOutline,
  TeamOutline,
  UndoOutline,
  UnorderedListOutline,
  UpOutline,
  UserOutline,
];

@NgModule({
  imports: [NzIconModule],
  exports: [NzIconModule],
  providers: [{ provide: NZ_ICONS, useValue: icons }],
})
export class IconsProviderModule {}
