import { TargetingRuleType } from "models/targeting-rule.model";

type SurveyCappingType = "per-org" | "per-survey" | "per-respondent";

enum SurveyCappingTimePeriod { // In Hour
  // ActiveSession = 0,
  OneHour = 3600,
  OneDay = 24 * 3600,
  OneWeek = 7 * 24 * 3600,
  OneMonth = 30 * 24 * 3600,
  ThreeMonth = 3 * 30 * 24 * 3600,
  SixMonth = 6 * 30 * 24 * 3600,
  OneYear = 365 * 24 * 3600,
}

type SurveyCappingTimePeriodInfo = {
  title: string;
  per: string;
  type: SurveyCappingTimePeriod;
};

const surveyCappingTimePeriodTypesAndLabels: {
  [key in SurveyCappingTimePeriod]: SurveyCappingTimePeriodInfo;
} = {
  // [SurveyCappingTimePeriod.ActiveSession]: { title: 'Active Session', type: SurveyCappingTimePeriod.ActiveSession },
  [SurveyCappingTimePeriod.OneHour]: {
    title: "1 Hour",
    per: "hour",
    type: SurveyCappingTimePeriod.OneHour,
  },
  [SurveyCappingTimePeriod.OneDay]: {
    title: "1 Day",
    per: "day",
    type: SurveyCappingTimePeriod.OneDay,
  },
  [SurveyCappingTimePeriod.OneWeek]: {
    title: "1 Week",
    per: "week",
    type: SurveyCappingTimePeriod.OneWeek,
  },
  [SurveyCappingTimePeriod.OneMonth]: {
    title: "1 Month",
    per: "month",
    type: SurveyCappingTimePeriod.OneMonth,
  },
  [SurveyCappingTimePeriod.ThreeMonth]: {
    title: "3 Month",
    per: "quarter",
    type: SurveyCappingTimePeriod.ThreeMonth,
  },
  [SurveyCappingTimePeriod.SixMonth]: {
    title: "6 Month",
    per: "half-year",
    type: SurveyCappingTimePeriod.SixMonth,
  },
  [SurveyCappingTimePeriod.OneYear]: {
    title: "1 Year",
    per: "year",
    type: SurveyCappingTimePeriod.OneYear,
  },
};

const surveyCappingTypeToTimePeriod: {
  [key in SurveyCappingType]: SurveyCappingTimePeriodInfo[];
} = {
  ["per-org"]: [
    // surveyCappingTimePeriodTypesAndLabels[SurveyCappingTimePeriod.ActiveSession],
    surveyCappingTimePeriodTypesAndLabels[SurveyCappingTimePeriod.OneHour],
    surveyCappingTimePeriodTypesAndLabels[SurveyCappingTimePeriod.OneDay],
    surveyCappingTimePeriodTypesAndLabels[SurveyCappingTimePeriod.OneWeek],
    surveyCappingTimePeriodTypesAndLabels[SurveyCappingTimePeriod.OneMonth],
    surveyCappingTimePeriodTypesAndLabels[SurveyCappingTimePeriod.ThreeMonth],
    surveyCappingTimePeriodTypesAndLabels[SurveyCappingTimePeriod.SixMonth],
    surveyCappingTimePeriodTypesAndLabels[SurveyCappingTimePeriod.OneYear],
  ],
  ["per-survey"]: [
    // surveyCappingTimePeriodTypesAndLabels[SurveyCappingTimePeriod.ActiveSession],
    surveyCappingTimePeriodTypesAndLabels[SurveyCappingTimePeriod.OneHour],
    surveyCappingTimePeriodTypesAndLabels[SurveyCappingTimePeriod.OneDay],
    surveyCappingTimePeriodTypesAndLabels[SurveyCappingTimePeriod.OneWeek],
    surveyCappingTimePeriodTypesAndLabels[SurveyCappingTimePeriod.OneMonth],
    surveyCappingTimePeriodTypesAndLabels[SurveyCappingTimePeriod.ThreeMonth],
    surveyCappingTimePeriodTypesAndLabels[SurveyCappingTimePeriod.SixMonth],
    surveyCappingTimePeriodTypesAndLabels[SurveyCappingTimePeriod.OneYear],
  ],
  ["per-respondent"]: [
    // surveyCappingTimePeriodTypesAndLabels[SurveyCappingTimePeriod.ActiveSession],
    surveyCappingTimePeriodTypesAndLabels[SurveyCappingTimePeriod.OneHour],
    surveyCappingTimePeriodTypesAndLabels[SurveyCappingTimePeriod.OneDay],
    surveyCappingTimePeriodTypesAndLabels[SurveyCappingTimePeriod.OneWeek],
    surveyCappingTimePeriodTypesAndLabels[SurveyCappingTimePeriod.OneMonth],
    surveyCappingTimePeriodTypesAndLabels[SurveyCappingTimePeriod.ThreeMonth],
    surveyCappingTimePeriodTypesAndLabels[SurveyCappingTimePeriod.SixMonth],
    surveyCappingTimePeriodTypesAndLabels[SurveyCappingTimePeriod.OneYear],
  ],
};

type SurveyCappingRuleTypeInfo = {
  display: TargetingRuleType;
  response: TargetingRuleType;
};

const surveyCappingRuleTypePerCappingType: {
  [key in SurveyCappingType]: SurveyCappingRuleTypeInfo;
} = {
  "per-org": {
    display: "capping_org_display",
    response: "capping_org_response",
  },
  "per-survey": {
    display: "capping_survey_display",
    response: "capping_survey_response",
  },
  "per-respondent": {
    display: "capping_respondent_display",
    response: "capping_respondent_response",
  },
};

export {
  SurveyCappingType,
  SurveyCappingTimePeriod,
  surveyCappingTypeToTimePeriod,
  surveyCappingTimePeriodTypesAndLabels,
  surveyCappingRuleTypePerCappingType,
};
