<nz-modal
  [(nzVisible)]="isOpen"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  [nzClosable]="false"
  (nzOnCancel)="handleCancel()"
  nzWidth="757px"
  nzClassName="release-modal"
>
  <ng-template #modalTitle>
    <h3>🚀 Add a new Release</h3>
    <p>
      These information will be displayed on your Release Timeline and on every
      Trend Graph in Screeb.
    </p>
  </ng-template>

  <ng-template #modalContent>
    <form nz-form [nzNoColon]="true">
      <nz-form-item>
        <nz-form-label>Name of the Release</nz-form-label>
        <nz-form-control
          class="form-input"
          nzErrorTip="Please fill this field"
          [nzValidateStatus]="formValidator.name"
          required
        >
          <input
            nz-input
            [(ngModel)]="release.name"
            name="name"
            placeholder="Name"
            id="name"
          />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label>Version of the Release</nz-form-label>
        <nz-form-control
          class="form-input"
          nzErrorTip="Please fill this field"
          [nzValidateStatus]="formValidator.version"
          required
        >
          <input
            nz-input
            [(ngModel)]="release.version"
            name="version"
            placeholder="Name or Number of Version"
            id="version"
          />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label>Date of the Release</nz-form-label>
        <nz-form-control
          class="form-input"
          nzErrorTip="Please fill this field"
          [nzValidateStatus]="formValidator.date"
          required
        >
          <screeb-date-picker
            [value]="release.released_at"
            (valueChange)="onDateValueChanged($event)"
          />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label>Description of the Release</nz-form-label>
        <nz-form-control
          class="form-input"
          nzErrorTip="Please fill this field"
          [nzValidateStatus]="formValidator.description"
          required
        >
          <textarea
            nz-input
            [(ngModel)]="release.description"
            name="description"
            placeholder="Description"
            id="description"
          ></textarea>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <div class="item-label-wrap">
          <nz-form-label>Tags</nz-form-label>
          <span>Optional</span>
        </div>
        <nz-form-control class="form-input">
          <nz-select
            nzMode="tags"
            nzPlaceHolder="Choose or add tags"
            name="tags"
            [nzMaxMultipleCount]="100"
            [nzShowArrow]="true"
            nzSuffixIcon="caret-down"
            [(ngModel)]="release.tags"
          >
            <nz-option
              *ngFor="let tag of tagOptions"
              [nzLabel]="tag"
              [nzValue]="tag"
            ></nz-option>
          </nz-select>
          <p class="tooltip">
            You can use tags to easily organize your releases.
          </p>
        </nz-form-control>
      </nz-form-item>
    </form>
  </ng-template>

  <ng-template #modalFooter>
    <button
      *ngIf="canCancel"
      nz-button
      nzType="default"
      class="release-cancel-btn"
      (click)="handleCancel()"
    >
      Cancel
    </button>
    <button
      nz-button
      [class]="'release-add-btn'.concat(isFormValid() ? '' : ' disabled')"
      (click)="onSubmit()"
      [nzLoading]="isConfirmLoading"
      [disabled]="!isFormValid()"
    >
      {{ release.id !== undefined ? "Save" : "Add" }}
    </button>
  </ng-template>
</nz-modal>
