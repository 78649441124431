<section>
  <div *ngIf="integration">
    <form nz-form [nzAutoTips]="autoTips" [formGroup]="validateForm">
      <h2>Webhook URL</h2>
      <nz-form-item>
        <nz-form-control>
          <nz-input-group>
            <input
              type="url"
              nz-input
              nzHasFeedback
              formControlName="server"
              placeholder="https://<your-dataplane-url>/v1/webhook"
            />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <h2>Write key</h2>
      <nz-form-item>
        <nz-form-control>
          <nz-input-group>
            <input
              type="text"
              nz-input
              nzHasFeedback
              formControlName="token"
              placeholder="Write key"
            />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
</section>
