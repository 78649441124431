import { Injectable } from "@angular/core";

@Injectable()
class GetFeedbackDao {
  private API_URL = "https://api.getfeedback.com/v1";

  constructor() {}

  /**
   * The function returns the fetch options for making a GET request with a token
   * authorization header.
   * @param {string} token - The `token` parameter is a string that represents an
   * authentication token. It is used to authorize the request being made.
   * @returns a RequestInit object.
   */
  private getFetchOptions(token: string): RequestInit {
    return {
      method: "GET",
      mode: "no-cors",
      headers: {
        Authorization: `Bearer ${token.replace("Bearer ", "").trim()}`,
      },
    };
  }

  /**
   * The function `getSurveys` makes an asynchronous HTTP GET request to retrieve
   * surveys from the GetFeedback API using the provided token.
   * @param {string} token - The `token` parameter is a string that represents the
   * authentication token required to access the surveys API.
   */
  public async getSurveys(token: string): Promise<any[]> {
    return fetch(`${this.API_URL}/surveys`, this.getFetchOptions(token))
      .then((response) => response.json())
      .then((data) => {
        return data?.surveys || [];
      });
  }

  /**
   * The function `getSurvey` makes an asynchronous HTTP GET request to retrieve a
   * survey from the GetFeedback API using the provided surveyId and token.
   * @param {number} surveyId - The `surveyId` parameter is a number that
   * represents the unique identifier of the survey you want to retrieve.
   * @param {string} token - The `token` parameter is a string that represents the
   * authentication token required to access the survey data. It should be a valid
   * token that is authorized to make requests to the GetFeedback API.
   * @returns a Promise that resolves to the "survey" object from the response
   * data.
   */
  public async getSurvey(surveyId: number, token: string): Promise<any> {
    return fetch(
      `${this.API_URL}/surveys/${surveyId}`,
      this.getFetchOptions(token),
    )
      .then((response) => response.json())
      .then((data) => {
        return data.survey;
      });
  }

  /**
   * The function `getResponses` retrieves survey responses for a given survey ID
   * and page number using an API call with authentication.
   * @param {number} surveyId - The surveyId parameter is a number that represents
   * the unique identifier of a survey.
   * @param {number} page - The `page` parameter is used to specify the page number
   * of responses to retrieve. It is used for pagination, allowing you to retrieve
   * responses in chunks or pages instead of all at once.
   * @param {string} token - The `token` parameter is a string that represents the
   * authentication token required to access the API. It should be provided in the
   * format "Bearer {token}" where {token} is the actual token value.
   * @returns a Promise that resolves to an array of any type.
   */
  public async getResponses(
    surveyId: number,
    page: number,
    token: string,
  ): Promise<any[]> {
    return fetch(
      `${this.API_URL}/surveys/${surveyId}/responses?per_page=100&page=${page}`,
      this.getFetchOptions(token),
    )
      .then((response) => response.json())
      .then((data) => {
        return data.active_models;
      });
  }
}

export { GetFeedbackDao };
