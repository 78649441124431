import { Component, Input, OnInit } from "@angular/core";
import { TargetingRule } from "models/targeting-rule.model";
import { ChannelClientVersion } from "models/channel-screen.model";
import { RegistryEntrySource } from "models/registry.model";

@Component({
  selector: "rule-app-version",
  templateUrl: "./rule-app-version.component.html",
  styleUrls: ["./rule-app-version.component.scss"],
})
export class TargetingRuleAppVersionComponent implements OnInit {
  @Input() public rule: TargetingRule = null;
  @Input() public source: RegistryEntrySource = null; // @TODO: remove at the same time than legacy distribs
  @Input() public channelClientVersions: ChannelClientVersion[] = [];

  private versions: ChannelClientVersion[] = [];
  public androidVersions: ChannelClientVersion[] = [];
  public iosVersions: ChannelClientVersion[] = [];

  constructor() {}

  public ngOnInit() {
    if (this.source) {
      this.versions = this.channelClientVersions.filter(
        (v) => v.source === this.source,
      );
    } else {
      this.versions = this.channelClientVersions;
    }

    this.androidVersions = this.versions.filter(
      (v) => v.platform === "android",
    );
    this.iosVersions = this.versions.filter((v) => v.platform === "ios");
  }
}
