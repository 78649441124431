import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "settings-data-governance-tracking-capability",
  template: `
    <nz-switch
      [nzCheckedChildren]="checkedTemplate"
      [nzUnCheckedChildren]="unCheckedTemplate"
      [ngModelOptions]="{ standalone: true }"
      [ngModel]="value"
      (ngModelChange)="valueChange.emit(!value)"
    ></nz-switch>
    <ng-template #checkedTemplate>
      <span nz-icon nzType="check"></span>
    </ng-template>
    <ng-template #unCheckedTemplate>
      <span nz-icon nzType="close"></span>
    </ng-template>

    {{ what }}
  `,
  styles: [``],
})
export class SettingsDataGovernanceTrackingCapabilityComponent {
  @Input() what: string;
  @Input() value: boolean;
  @Output() valueChange = new EventEmitter<boolean>();

  constructor() {}
}
