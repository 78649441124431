<!-- I need to duplicate "End of survey" in placeholder,
  because it is not displayed when component is initiated with null value -->
<nz-select
  class="selectpicker"
  [nzPlaceHolder]="isSkipAction ? 'Step required' : 'End of survey'"
  [ngModel]="getNodeId() || null"
  (ngModelChange)="selectStep($event)"
  [nzDisabled]="disabled"
>
  <nz-option
    nzCustomContent
    [nzValue]="null"
    [nzLabel]="isSkipAction ? 'Step required' : 'End of survey'"
    selected
  >
    <div class="question-icon">
      <!-- nothing -->
    </div>
    {{ isSkipAction ? "Step required" : "End of survey" }}
  </nz-option>

  <ng-container *ngFor="let node of chainableNodes">
    <nz-option
      nzCustomContent
      [nzValue]="node.id"
      [nzLabel]="'Step ' + node.name"
    >
      <style>
        /* I put the css here, because it will be inserted out of Angular component scope */
        .question-item {
          display: flex;
          height: 24px;
        }
        .question-item .question-icon {
          width: 24px;
          height: 24px;
          margin-right: 10px;
          text-align: center;
        }
        .question-item .question-icon card-type-icons {
        }
        .question-item .question-label {
        }
      </style>
      <div class="question-item">
        <div class="question-icon">
          <card-type-icons [questionType]="node.questionType"></card-type-icons>
        </div>
        <p class="question-label">Step {{ node.name }}</p>
      </div>
    </nz-option>
  </ng-container>
</nz-select>
