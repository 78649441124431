import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LayoutBackHeaderService } from "components/layouts/back/header/header.service";
import { PageComponentInterface } from "components/PageComponentInterface";
import { NotificationHelper } from "helpers/notification.helper";
import { Org } from "models/org.model";
import { SurveyDistribution } from "models/survey-distribution.model";
import { Survey } from "models/survey.model";
import { ClipboardService } from "ngx-clipboard";
import { ConfigService } from "services/config.service";
import { PermissionsService } from "services/permissions.service";
import { RoutingService } from "services/routing.service";

@Component({
  selector: "page-distribution-preview",
  templateUrl: "./distribution-preview.component.html",
  styleUrls: ["./distribution-preview.component.scss"],
})
export class DistributionPreviewPageComponent
  implements PageComponentInterface, OnInit, OnDestroy, AfterViewInit
{
  public title = "Share - Preview";
  public name = "Survey Share Preview"; // @TODO: rename title (survey vs message)

  private obs: any = null;

  @ViewChild("pageActions")
  private pageActionsRef: TemplateRef<any>;

  public org: Org = null;
  public survey: Survey = null;
  public surveyDistribution: SurveyDistribution = null;
  public previewURL: string = null;

  constructor(
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private layoutBackHeaderService: LayoutBackHeaderService,
    public permissionsService: PermissionsService,
    private clipboardService: ClipboardService,
    private notificationHelper: NotificationHelper,
    private configService: ConfigService,
  ) {}

  ngOnInit(): void {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.obs = this.route.data.subscribe((data) => {
      this.org = data["org"];
      this.survey = data["survey"];
      this.surveyDistribution = data["survey_distribution"];
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.layoutBackHeaderService.surveyPageActionsRef = this.pageActionsRef;
    });
  }

  ngOnDestroy(): void {
    if (this.obs) {
      this.obs.unsubscribe();
    }
    this.layoutBackHeaderService.surveyPageActionsRef = null;
  }

  public setURL(url: string) {
    this.previewURL = `${this.configService.config.selfEndpoint}${url}`;
  }

  public async actionOnShare() {
    this.clipboardService.copy(this.previewURL);
    this.notificationHelper.trigger(
      "URL copied to clipboard!",
      null,
      "success",
    );
  }
}
