<section>
  <h3>Webhook</h3>
  <div nz-col nzXs="24" class="description">
    Push your Screeb data to third party solutions via Webhooks. This will
    <b>automatically</b>
    push data from this
    {{ uiService.isMessagePage ? "message" : "survey" }} only.
  </div>
  <div nz-col nzSpan="24">
    <upgrade-banner
      class="upgrade-banner"
      title=""
      text="You need to <strong>upgrade</strong> your account to unlock integrations!"
      *ngIf="!('integration_webhook' | hasEntitlement)"
      [orgId]="org.id"
      [small]="true"
    ></upgrade-banner>

    <integration-settings-webhook
      *ngIf="'integration_webhook' | hasEntitlement"
      [org]="org"
      [survey]="survey"
      [integration]="{ settings: surveyIntegrations }"
      [createIfEmpty]="false"
      [hideListOnEmpty]="true"
      scope="survey"
      (valid)="webhookValid = $event"
      (onSettingsChange)="onWebhookChange($event)"
    ></integration-settings-webhook>
  </div>
</section>

<section>
  <hr />
  <h3>Cobbai</h3>
  <div nz-col nzXs="24" class="description">
    Push your Screeb data to the Cobbai feedback analaysis tool. This will
    <b>automatically</b>
    push data from this
    {{ uiService.isMessagePage ? "message" : "survey" }} only.
  </div>
  <div nz-col nzSpan="24">
    <upgrade-banner
      class="upgrade-banner"
      title=""
      CTAText="Go to integrations"
      [routes]="['/org', org.id, 'integrations']"
      text="You need to enable the <b>Cobbai</b> integration in your workspace!"
      *ngIf="
        ('integration_cobbai' | hasEntitlement) &&
        !integrationIsCreatedInOrganization('cobbai')
      "
      [orgId]="org.id"
      [small]="true"
    ></upgrade-banner>
    <upgrade-banner
      class="upgrade-banner"
      title=""
      text="You need to <strong>upgrade</strong> your account to unlock integrations!"
      *ngIf="!('integration_cobbai' | hasEntitlement)"
      [orgId]="org.id"
      [small]="true"
    ></upgrade-banner>

    <integration-settings-cobbai
      *ngIf="
        ('integration_cobbai' | hasEntitlement) &&
        integrationIsCreatedInOrganization('cobbai')
      "
      [org]="org"
      [survey]="survey"
      [integration]="{ settings: surveyIntegrations }"
      [createIfEmpty]="false"
      [hideListOnEmpty]="true"
      scope="survey"
      (valid)="cobbaiValid = $event"
      (onSettingsChange)="onCobbaiChange($event)"
    ></integration-settings-cobbai>
  </div>
</section>
