<div *ngIf="rule">
  <input
    type="text"
    nz-input
    placeholder="{{ rule.getValuePlaceholder() }}"
    [(ngModel)]="rule.value.v_s"
    [ngClass]="{ invalid: !checkValidQuerySelector(rule) }"
    [disabled]="!('survey_edition' | hasEntitlement)"
  />
</div>
