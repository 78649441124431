<div *ngIf="registryEntriesIdentityPropertyLoading">
  <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
</div>

<section *ngIf="!registryEntriesIdentityPropertyLoading">
  <div *ngIf="integration?.settings?.typeform?.items">
    <nz-table
      #table
      [nzData]="integration.settings.typeform.items"
      [nzHideOnSinglePage]="true"
      [nzPageSize]="100"
    >
      <thead>
        <tr>
          <th>New Typeform Integration</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let item of table.data; let i = index">
          <tr
            class="item-opened-separator"
            [ngClass]="{
              visible:
                !!item.expanded || (i > 0 && !!table.data[i - 1]?.expanded),
            }"
          >
            <td></td>
          </tr>
          <tr
            (click)="expandItem(i)"
            class="item-header"
            [ngClass]="{ opened: isItemExpanded(i) }"
          >
            <td>
              {{ item.name }}
            </td>
          </tr>
          <tr
            *ngIf="isItemExpanded(i)"
            class="item-body"
            [ngClass]="{ opened: isItemExpanded(i) }"
          >
            <td>
              <integration-settings-typeform-edit
                [orgId]="org.id"
                [integrationId]="integration.id"
                [item]="item"
                [registryEntriesIdentityProperty]="
                  registryEntriesIdentityProperty
                "
                (onSave)="onItemSaved(i, $event)"
                (onCancel)="onItemCanceled()"
                (onRemove)="onItemRemoved(i)"
                (onWebhookCopy)="onWebhookCopy($event)"
              ></integration-settings-typeform-edit>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </nz-table>
  </div>

  <div class="left actions">
    <button nz-button nzSize="large" nzType="primary" (click)="onItemAdd()">
      Connect a new Typeform to Screeb
    </button>
  </div>
</section>

<ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
<ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>
