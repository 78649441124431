<div class="read-more">
  <div class="read-more-header">
    <ng-component *ngTemplateOutlet="header"></ng-component>
  </div>
  <div
    *ngIf="!loading"
    class="read-more-content {{ readModeOpen ? 'open' : '' }}"
  >
    <ng-component *ngTemplateOutlet="content"></ng-component>
  </div>
  <button
    *ngIf="!loading"
    class="read-more-button"
    (click)="readModeOpen = !readModeOpen"
  >
    Read {{ readModeOpen ? "less" : "more" }}
  </button>
</div>
