import { HttpErrorResponse } from "@angular/common/http";
import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { NotificationHelper } from "helpers/notification.helper";
import { EmailDao } from "models/email.dao";
import { Org } from "models/org.model";
import { SurveyDistribution } from "models/survey-distribution.model";
import { ClipboardService } from "ngx-clipboard";
import { ConfigService } from "services/config.service";
import { Debounce } from "utils/debounce";

@Component({
  selector: "page-survey-share-email",
  templateUrl: "./email.component.html",
  styleUrls: ["./email.component.scss"],
})
export class EmailShareSurveyPageComponent
  implements OnInit, OnChanges, AfterContentInit
{
  @Input() public org: Org = null;
  @Input() public surveyDistribution: SurveyDistribution = null;

  @Input() public multiple: boolean = false;

  @Output() public multipleChange = new EventEmitter<boolean>();

  public url: string;
  public baseUrl: string;
  public emailTemplate = "";
  public error: string = null;
  public previewModalOpened: boolean = false;

  constructor(
    private configService: ConfigService,
    private clipboardService: ClipboardService,
    private notificationHelper: NotificationHelper,
    private emailDao: EmailDao,
  ) {}

  ngOnInit() {
    this.baseUrl = this.setupURL();
    this.url = this.baseUrl;
  }

  ngAfterContentInit() {
    this.getTemplate();
  }

  ngOnChanges({ org, surveyDistribution }: SimpleChanges): void {
    if (
      (org && org.currentValue !== org.previousValue) ||
      (surveyDistribution &&
        surveyDistribution.currentValue !== surveyDistribution.previousValue)
    ) {
      this.baseUrl = this.setupURL();
      this.url = this.baseUrl;
      this.getTemplate();
    }
  }

  private setupURL() {
    if (this.surveyDistribution.interaction) {
      return `${this.configService.config.hostedEndpoint}/2/${this.surveyDistribution.channel_id}/${this.surveyDistribution.id}/${this.surveyDistribution.survey_id}`;
    }
    return `${this.configService.config.hostedEndpoint}/1/${this.surveyDistribution.channel_id}/${this.surveyDistribution.survey_id}`;
  }

  public onUrlChange(newUrl: string) {
    this.url = newUrl; //encodeURIComponent(newUrl);
    this.getTemplate();
  }

  @Debounce(500)
  getTemplate() {
    this.emailDao
      .getTemplate(
        this.org.id,
        this.surveyDistribution.survey_id,
        this.surveyDistribution.id,
        this.url,
      )
      .then((template) => {
        this.emailTemplate = template.html;
      })
      .catch((error: HttpErrorResponse) => {
        console.error(error);
        switch (error.status) {
          case 404:
            this.error = "Survey doesn't have any question.";
            break;
          default:
            this.error = "An error occurred while fetching the email template.";
        }
        this.notificationHelper.trigger(
          "Error",
          "An error occurred while fetching the email template.",
          "error",
        );
      });
  }

  copy() {
    this.clipboardService.copy(this.emailTemplate);
    this.notificationHelper.trigger(
      "Copied to your clipboard!",
      null,
      "success",
    );
  }

  onPreviewModalOpenChange(open: boolean) {
    this.previewModalOpened = open;
  }
}
