import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NzTableSortOrder } from "ng-zorro-antd/table";

import { PageComponentInterface } from "components/PageComponentInterface";
import { GraphNode, ScenarioGraphBuilder } from "components/builder/flow";
import { AnalyticsDao } from "models/analytics.dao";
import { Channel } from "models/channel.model";
import { Org } from "models/org.model";
import { RegistryEntry } from "models/registry.model";
import { UUID } from "models/survey.dao.types";
import { Survey } from "models/survey.model";
import { LanguageWithEmoji } from "resolvers/asset-languages-countries";
import { AnalyticsFilterService } from "services/analytics-filters.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { RoutingService } from "services/routing.service";
import { UIService } from "services/ui.service";
import { deepCopy } from "utils/object";
import { QuestionDetails } from "../all-responses/questions-table/questions-table.component";
import { AnalyticsQueryResponse } from "models/analytics.filters.type";

@Component({
  selector: "page-survey-stats-all-displays",
  templateUrl: "./all-displays.component.html",
  styleUrls: ["./all-displays.component.scss"],
})
export class AllDisplaysStatsSurveyPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Analyze - All displays";
  public name = "Analyze all displays";

  private obs: any = null;

  private filtersObs$: any = null;
  private lastFilters: AnalyticsQueryResponse;

  public org: Org = null;
  public channel: Channel[] = null;
  public survey: Survey = null;
  public languages: LanguageWithEmoji[] = [];
  public registryEntriesIdentityProperty: RegistryEntry[] = [];
  public registryEntriesGroup: RegistryEntry[] = [];
  public registryEntriesEvent: RegistryEntry[] = [];

  public expandedNodes: { [key: string]: boolean } = {};
  public expandedResponses: { [key: string]: boolean } = {};
  public nodes: QuestionDetails[] = [];

  public displaysCount: { [key: string]: number } = {};
  public questionOrder: NzTableSortOrder = "ascend";

  public translationEnabled = true;
  public translationEnabledDiff = true;

  constructor(
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private analyticsFilterService: AnalyticsFilterService,
    public featureFlaggingService: FeatureFlaggingService,
    public uiService: UIService,
    private analyticsDao: AnalyticsDao,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.filtersObs$ = this.analyticsFilterService
      .subscribe()
      .subscribe((filters: AnalyticsQueryResponse) => {
        this.lastFilters = deepCopy(filters);
        this.resetQuestionList();
      });

    this.obs = this.route.data.subscribe((data) => {
      this.org = data.org;
      this.survey = data.survey;
      this.registryEntriesGroup = data.registryEntriesGroup.groups;
      this.languages = data.languages_and_countries.surveyLanguagesWithEmojis;
      this.registryEntriesIdentityProperty =
        data.registryEntriesIdentityProperty.filter(
          (entry: RegistryEntry) => entry.type !== "object",
        );
      this.registryEntriesEvent = data.registryEntriesEvent;

      this.translationEnabled = this.survey.translation_enabled ?? true;
      this.translationEnabledDiff = this.translationEnabled;

      this.resetFilters();
      this.resetQuestionList();
    });
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }

    if (this.filtersObs$) {
      this.filtersObs$.unsubscribe();
    }
  }

  private resetFilters() {
    this.analyticsFilterService.reset(
      "response",
      this.org.id,
      [this.survey.id],
      this.survey.created_at,
    );
  }

  private resetQuestionList() {
    if (!this.survey) {
      return;
    }

    // list questions with CTA -> remove simple text messages
    const flow = new ScenarioGraphBuilder(this.survey.scenario);
    const nodes = flow.getNodeGraph().flat();

    // nodes = nodes.sort(Question.compare); // determinate response column order
    this.nodes = nodes
      .map((q) => this.getQuestionDetails(q))
      .filter((q) => !!q);

    this.getDisplaysCount(nodes.map(({ correlationId }) => correlationId));
  }

  private getQuestionDetails(node: GraphNode): QuestionDetails {
    return {
      survey: this.survey,
      _node: node,
      node: {
        id: node.node.id,
        correlationId: node.node.correlation_id,
        type: node.questionType,
        letter: node.name,
        text: node.description,
      },
      index: node.index,
    } as QuestionDetails;
  }

  public onSortChange(colName: string, order: NzTableSortOrder) {
    const multiply = order === "ascend" ? 1 : -1;
    this.nodes.sort((a: QuestionDetails, b: QuestionDetails): number => {
      return (a._node.index - b._node.index) * multiply;
    });
  }

  public getQuery(
    pageSize: number,
    pageIndex: number,
    responseOrder: NzTableSortOrder,
    nodeCorrelationId: string,
  ): AnalyticsQueryResponse {
    const query = deepCopy(this.lastFilters) as AnalyticsQueryResponse;

    query.question_correlation_id_path = nodeCorrelationId?.substring(0, 8);
    query.size = pageSize;
    query.offset = (pageIndex - 1) * pageSize;
    query.sort = {
      field: "response.created_at",
      order: responseOrder === "ascend" ? "asc" : "desc",
    };
    query.with_not_started = true;
    query.having_answer_only = true;

    return query;
  }

  public getCardType(node: GraphNode) {
    if (node.isCES) {
      return "[CES] ";
    }
    if (node.isCSAT) {
      return "[CSAT] ";
    }
    if (node.isNPS) {
      return "[NPS] ";
    }

    return "";
  }

  private async getDisplaysCount(ids: UUID[]) {
    if (ids.length === 0) {
      return {};
    }

    const query = deepCopy(this.lastFilters) as AnalyticsQueryResponse;

    query.size = 0;
    query.aggregation = [{ by: "by_question_correlation_id_path" }];
    query.having_answer_only = true;
    query.with_not_started = true;

    const response = await this.analyticsDao.search(query);

    this.displaysCount = this.nodes.reduce((memo, node) => {
      return {
        ...memo,
        [node.node.correlationId]:
          response.aggregations?.question?.buckets.reduce(
            (acc, { doc_count, key }) => {
              if (key.includes(node.node.correlationId.substring(0, 8))) {
                return acc + doc_count;
              }
              return acc;
            },
            0,
          ) || 0,
      };
    }, {});
  }
}
