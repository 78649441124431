<div class="label-container">
  <span class="label">{{ label }}</span>
  <span class="value">{{ value }}</span>
  &nbsp;
  <span class="percent">({{ percentValue | format: "percent" }})</span>
</div>
<nz-progress
  [nzStrokeColor]="color"
  [nzShowInfo]="false"
  [nzPercent]="innerPercentValue"
></nz-progress>
