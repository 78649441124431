/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/no-output-rename */

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { integrationUpdate } from "components/integration/settings/integration-settings.component";

import { autoTips } from "helpers/form-errors.helper";
import { Integration, IntegrationSettings } from "models/integrations.model";
import { Org } from "models/org.model";

@Component({
  selector: "integration-settings-trello",
  templateUrl: "./trello-settings.component.html",
  styleUrls: ["./trello-settings.component.scss"],
})
export class IntegrationSettingsTrelloComponent implements OnInit {
  @Input() public org: Org = null;
  @Input() public integration: Integration = null;

  @Output() public valid = new EventEmitter<boolean>();
  @Output("onSettingsChange") public settings =
    new EventEmitter<integrationUpdate>();

  public validateForm: FormGroup = null;
  public autoTips = autoTips;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.validateForm = this.formBuilder.group({
      email: [
        this.integration?.settings?.trello?.email,
        [
          Validators.required,
          Validators.email,
          Validators.pattern(/^.*@boards\.trello\.com$/),
        ],
      ],
    });
    this.validateSettings();

    this.validateForm.valueChanges.subscribe(() => {
      this.validateSettings();
    });
  }

  public validateSettings() {
    this.valid.emit(this.validateForm.valid);
    this.settings.emit({
      settings: {
        trello: {
          email: this.validateForm.value["email"],
        },
      } as IntegrationSettings,
    });
  }
}
