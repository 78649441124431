import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { SeoService } from "services/seo.service";
import { RoutingService } from "services/routing.service";

@Component({
  selector: "page-embedded-external",
  templateUrl: "./embedded-external-page.component.html",
  styleUrls: ["./embedded-external-page.component.scss"],
})
export class EmbeddedExternalPageComponent implements OnInit, OnDestroy {
  // This page does not implements PageComponentInterface

  private obs: any = null;

  public url: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private seoService: SeoService,
  ) {}

  ngOnInit() {
    this.obs = this.route.data.subscribe((data) => {
      this.routingService.onPageChange(
        data["name"],
        data["title"],
        this.route.snapshot.data,
        true,
      );

      this.url = data["url"];
    });
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }
}
