import { Component, Input, OnDestroy, OnInit } from "@angular/core";

import {
  endOfDay,
  startOfDay,
  startOfYear,
  subDays,
  subMonths,
} from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import dayjs from "dayjs/esm";
import { Org } from "models/org.model";
import { Survey } from "models/survey.model";
import {
  DateRange,
  TimePeriod,
} from "ngx-daterangepicker-material/daterangepicker.component";
import { AnalyticsFilterService } from "services/analytics-filters.service";
import { TrackersService } from "services/trackers.service";
import { filterObject, mapObject } from "utils/object";

@Component({
  selector: "survey-stats-filter-date",
  templateUrl: "./filter-date.component.html",
  styleUrls: ["./filter-date.component.scss"],
})
export class DateFilterStatsSurveyComponent implements OnInit, OnDestroy {
  @Input()
  public org: Org = null;
  @Input()
  public survey: Survey = null;
  @Input()
  public reporting: string = null;

  @Input()
  public dropdownVisible = false;
  @Input()
  public theme: "dark" | "light" = "light";

  public selected: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs };

  private interval: any = null;

  public ranges: Record<string, Date[]>;

  public skipDatesUpdated = false;

  constructor(
    private trackersService: TrackersService,
    public analyticsFilterService: AnalyticsFilterService,
  ) {}

  ngOnInit() {
    this.selected = {
      startDate: dayjs(
        this.analyticsFilterService.get().range.start ?? new Date(),
      ),
      endDate: dayjs(this.analyticsFilterService.get().range.end ?? new Date()),
    };

    this.setRanges();
    this.interval = setInterval(() => {
      // @TODO: hack because the ranges are not updated when the minDate is not available
      this.setRanges();
    }, 1000);
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  private setRanges() {
    const now = new Date();

    const capMinDate = (date: Date): Date =>
      date.getTime() > this.analyticsFilterService.minDate.getTime()
        ? date
        : this.analyticsFilterService.minDate;
    const capMinDates = (dates: Date[]): Date[] => dates.map(capMinDate);

    const ranges = {
      "Last week": [subDays(now, 6), now].map(capMinDate), // we don't substract 7 days, because we return to users the result from 00:00 to 23:59 (= 6 days, 23 hours and 59 minutes)
      "Last month": [subMonths(now, 1), now].map(capMinDate),
      "This year so far": [startOfYear(now), now].map(capMinDate),
      "Previous year": [startOfYear(subMonths(now, 12)), startOfYear(now)].map(
        capMinDate,
      ),
      "All time": [this.analyticsFilterService.minDate, now].map(capMinDate),
    };

    this.ranges = filterObject(
      mapObject(ranges, capMinDates),
      ([_, date]) =>
        date.getTime() > this.analyticsFilterService.minDate.getTime(),
    );
  }

  public isInvalidDate = (date: Date): boolean => {
    const now = new Date();
    return date < this.analyticsFilterService.minDate || date > now;
  };

  public rangeClicked($event: DateRange) {
    const [eventStartDate, eventEndDate] = $event.dates;

    this.skipDatesUpdated = true;
    this.setDates(eventStartDate, eventEndDate);
  }

  public setDates(eventStartDate: dayjs.Dayjs, eventEndDate: dayjs.Dayjs) {
    if (!eventStartDate || !eventEndDate) {
      return;
    }

    const startDate = utcToZonedTime(eventStartDate.toDate(), "UTC");

    // Add One second so that startOfDay() doesn't bring the date back to the previous day
    startDate.setSeconds(startDate.getSeconds() + 1);

    const endDate = utcToZonedTime(eventEndDate.toDate(), "UTC");

    this.analyticsFilterService.setDateRange(
      startOfDay(startDate),
      endOfDay(endDate),
    );

    this.selected = {
      startDate: eventStartDate,
      endDate: eventEndDate,
    };

    const strRange = `${startDate.getUTCDate()} - ${endDate.getUTCDate()}`;

    this.trackersService
      .newEventTrackingBuilder("Reporting date range changed")
      .withOrg(this.org)
      .withSurvey(this.survey)
      .withProps({
        range_from: startDate.toDateString(),
        range_to: endDate.toDateString(),
        reporting_name: this.reporting,
        range_selected: strRange,
      })
      .build();
  }

  public datesUpdated($event: TimePeriod) {
    if (!this.skipDatesUpdated) {
      this.setDates($event.startDate, $event.endDate);
    }
    this.skipDatesUpdated = false;
  }
}
