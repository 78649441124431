import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router } from "@angular/router";
import { WeeklyBriefingDao } from "models/weekly-briefing.dao";
import { WeeklyBriefing } from "models/weekly-briefing.model";
import { ApplicationLoadService } from "services/app.service";

@Injectable()
export class WeeklyBriefingResolver implements Resolve<any> {
  constructor(
    private router: Router,
    private applicationLoadService: ApplicationLoadService,
    private weeklyBriefingDao: WeeklyBriefingDao,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Promise<WeeklyBriefing[]> {
    return this.applicationLoadService
      .loader()
      .then(() => {
        return this.weeklyBriefingDao.getAllByOrgId(route.params["org_id"]);
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        throw err;
      });
  }
}
