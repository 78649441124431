import { Injectable } from "@angular/core";

import { ScreebApiHelper } from "helpers/screeb-api.helper";

import { OrgAccountRole, WorkspaceAccountRole } from "./account-permissions";
import { Account } from "./account.model";
import { Org, OrgStats } from "./org.model";
import {
  OrgBilling,
  OrgBillingCoupon,
  OrgBillingInvoice,
  OrgBillingPaymentMethods,
  OrgBillingSubscription,
  billingCycleDuration,
  subscriptionOptionsAddon,
} from "./org_billing.model";
import { SuperOrg } from "./super-org.model";
import { UUID } from "./survey.dao.types";
import { ConfigService } from "services/config.service";

@Injectable()
class SuperOrgDao {
  constructor(
    private screebApiHelper: ScreebApiHelper,
    private configService: ConfigService,
  ) {}

  public getAll(): Promise<SuperOrg[]> {
    return this.screebApiHelper
      .get<SuperOrg[]>("/super-org")
      .toPromise()
      .then((data: object[]) => {
        return data.map((c: object) => new SuperOrg().fromJson(c));
      });
  }

  public getById(id: string): Promise<SuperOrg> {
    return this.screebApiHelper
      .get<SuperOrg>(`/super-org/${id}`)
      .toPromise()
      .then((data: object) => {
        return new SuperOrg().fromJson(data);
      });
  }

  public create(name: string): Promise<SuperOrg> {
    return this.screebApiHelper
      .post<SuperOrg>("/super-org", { name })
      .toPromise()
      .then((data: object) => {
        return new SuperOrg().fromJson(data);
      });
  }

  /**
   * Team management
   */
  public getAllSuperOrgAccounts(id: string): Promise<Account[]> {
    return this.screebApiHelper
      .get<object[]>(`/super-org/${id}/account`)
      .toPromise()
      .then((data) => data.map((account) => new Account().fromJson(account)));
  }

  public editAccount(
    id: string,
    userID: string,
    role: OrgAccountRole,
    tags: string[],
    workspaces: { workspace: UUID; role: WorkspaceAccountRole },
  ): Promise<object> {
    return this.screebApiHelper
      .patch<object>(`/super-org/${id}/account/${userID}`, {
        role: role,
        tags: tags,
        workspaces: workspaces,
      })
      .toPromise();
  }

  public deleteAccount(id: string, userID: string): Promise<object> {
    return this.screebApiHelper
      .delete<object>(`/super-org/${id}/account/${userID}`)
      .toPromise();
  }

  public invite(
    id: string,
    email: string,
    role: OrgAccountRole,
    tags: string[],
    workspaces: { workspace: UUID; role: WorkspaceAccountRole },
  ): Promise<object> {
    return this.screebApiHelper
      .post<object>(`/super-org/${id}/account`, {
        email: email,
        role: role,
        tags: tags,
        workspaces: workspaces,
      })
      .toPromise();
  }

  /**
   * MTU Management
   */
  public updateMTU(
    id: string,
    mode: "manual" | "auto" | "even",
    // mtus is a object with key = workspaceId and value = mtu
    mtus: object,
  ): Promise<SuperOrg> {
    return this.screebApiHelper
      .patch<SuperOrg>(`/super-org/${id}/mtu`, { mtu_mode: mode, mtus: mtus })
      .toPromise()
      .then((data) => {
        return new SuperOrg().fromJson(data);
      });
  }

  /**
   * Workspaces
   */
  public getWorkspaces(id: string): Promise<Org[]> {
    return this.screebApiHelper
      .get<Org[]>(`/super-org/${id}/org`)
      .toPromise()
      .then((data) => {
        return data.map((c: object) => new Org().fromJson(c));
      });
  }

  public createWorkspace(
    superOrgID: string,
    name: string,
    mtu: number = null,
    tags: string[] = [],
  ): Promise<Org> {
    const body = { name, tags };
    if (mtu) {
      body["mtu"] = mtu;
    }
    return this.screebApiHelper
      .post<Org>(`/super-org/${superOrgID}/org`, body)
      .toPromise()
      .then((data: object) => {
        return new Org().fromJson(data);
      });
  }

  public updateWorkspace(
    superOrgID: string,
    orgID: string,
    name: string,
    mtu: number = null,
    tags: string[] = [],
  ): Promise<Org> {
    const body = { name, tags };
    if (mtu) {
      body["mtu"] = mtu;
    }
    return this.screebApiHelper
      .patch<Org>(`/super-org/${superOrgID}/org/${orgID}`, body)
      .toPromise()
      .then((data: object) => {
        return new Org().fromJson(data);
      });
  }

  public assignUsersToWorkspace(
    orgId: string,
    workspaceId: string,
    accesses: {
      users: string[];
      emails: string[];
      role: WorkspaceAccountRole;
    }[],
  ): Promise<Org> {
    return this.screebApiHelper
      .post<Org>(`/super-org/${orgId}/org/${workspaceId}/users`, {
        accesses: accesses,
      })
      .toPromise()
      .then((data: object) => {
        return new Org().fromJson(data);
      });
  }

  public deleteWorkspace(orgId: string, workspaceId: string): Promise<object> {
    return this.screebApiHelper
      .delete<object>(`/super-org/${orgId}/org/${workspaceId}`)
      .toPromise();
  }

  /**
   * Billing
   */
  public getBillingBySuperOrgId(id: string): Promise<OrgBilling> {
    return this.screebApiHelper
      .get<object>(`/super-org/${id}/billing`)
      .toPromise()
      .then((data) => new OrgBilling().fromJson(data));
  }

  public updateSubscription(
    id: string,
    planId: string | null,
    billingCycle: billingCycleDuration | null,
    addons: subscriptionOptionsAddon[],
    coupon: OrgBillingCoupon | null,
  ): Promise<object> {
    return this.screebApiHelper
      .put<object>(`/super-org/${id}/billing/subscription`, {
        plan_id: planId,
        billing_cycle: billingCycle,
        addons: addons,
        coupon_id: coupon?.id,
      })
      .toPromise();
    // .then((data) => new OrgBilling().fromJson(data));
  }

  public getBillingSubscriptionBySuperOrgId(
    id: string,
  ): Promise<OrgBillingSubscription> {
    return this.screebApiHelper
      .get<object>(`/super-org/${id}/billing/subscription`)
      .toPromise()
      .then((data) => data as OrgBillingSubscription);
  }

  public updateBillingInfo(orgId: string, infos: object): Promise<OrgBilling> {
    return this.screebApiHelper
      .put<object>(`/super-org/${orgId}/billing/info`, infos)
      .toPromise()
      .then((data) => new OrgBilling().fromJson(data));
  }

  public getInvoicesBySuperOrgId(orgId: string): Promise<OrgBillingInvoice[]> {
    return this.screebApiHelper
      .get<object[]>(`/super-org/${orgId}/billing/invoice`)
      .toPromise()
      .then((data) =>
        data.map((invoice) => new OrgBillingInvoice().fromJson(invoice)),
      );
  }

  public getInvoiceURL(id: string, invoiceId: string): string {
    return `${this.configService.config.apiEndpoint}/super-org/${id}/billing/invoice/${invoiceId}/download`;
  }

  public getPaymentMethodBySuperOrgId(
    id: string,
  ): Promise<OrgBillingPaymentMethods> {
    return this.screebApiHelper
      .get<object>(`/super-org/${id}/billing/payment`)
      .toPromise()
      .then((data) => new OrgBillingPaymentMethods().fromJson(data));
  }

  public newPaymentSetupIntentBySuperOrgId(
    id: string,
  ): Promise<{ intent_client_secret: string }> {
    return this.screebApiHelper
      .post<object>(`/super-org/${id}/billing/payment/intent`, {})
      .toPromise()
      .then((data) => {
        return { intent_client_secret: data["intent_client_secret"] };
      });
  }

  public updateDefaultPaymentMethod(
    id: string,
    defaultPaymentMethodId: string,
  ): Promise<OrgBillingPaymentMethods> {
    return this.screebApiHelper
      .post<object>(`/super-org/${id}/billing/payment/default`, {
        default_payment_method_id: defaultPaymentMethodId,
      })
      .toPromise()
      .then((data) => {
        return new OrgBillingPaymentMethods().fromJson(data);
      });
  }

  public removePaymentMethod(
    id: string,
    defaultPaymentMethodId: string,
  ): Promise<OrgBillingPaymentMethods> {
    return this.screebApiHelper
      .delete<object>(
        `/super-org/${id}/billing/payment/${defaultPaymentMethodId}`,
      )
      .toPromise()
      .then((data) => {
        return new OrgBillingPaymentMethods().fromJson(data);
      });
  }

  public getPromotionCodeByCustomerFacingCode(
    id: string,
    customerFacingCode: string,
  ): Promise<OrgBillingCoupon> {
    return this.screebApiHelper
      .get<object>(`/super-org/${id}/billing/coupon/${customerFacingCode}`)
      .toPromise()
      .then((data) => {
        return new OrgBillingCoupon().fromJson(data);
      });
  }

  public getAllStatsByIndustry(id: string): Promise<OrgStats[]> {
    return this.screebApiHelper
      .get<object[]>(`/org/${id}/stats/industry`)
      .toPromise()
      .then((data: object[]) => {
        return data.map((stats) => new OrgStats().fromJson(stats));
      });
  }

  /* MFA */

  public enforceMFA(id: string, status: boolean): Promise<void> {
    return this.screebApiHelper
      .patch<void>(`/super-org/${id}/mfa`, { enabled: status })
      .toPromise();
  }
}

export { SuperOrgDao };
