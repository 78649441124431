<big-checkbox
  class="big-checkbox"
  label="Via Notion"
  [content]="notionBigCheckbox"
  [(checked)]="enabled"
  (checkedChange)="onEnabledChange($event)"
  [disabled]="!quotaOk"
  iconUrl="/assets/icons/integrations/notion-icon.svg"
  name="notionEnabled"
  ngDefaultControl
  collapseWhenNotChecked
>
  <ng-template #notionBigCheckbox>
    <div class="notion-tip" *ngIf="integrationNotionStatus === 'not_installed'">
      <span>No Notion integration installed!</span>
      <br />
      <span>
        Go to
        <a routerLink="/org/{{ org.id }}/integrations">integrations</a>
        to install one.
      </span>
    </div>

    <div
      class="notion-tip"
      *ngIf="
        ((integrationNotionStatus === 'error' ||
          integrationNotionStatus === 'not_authed') &&
          !isNotionTimeout) ||
        (nbrOfSearches > 1 && isNotionTimeout)
      "
    >
      <p
        *ngIf="!isNotionTimeout || (nbrOfSearches > 1 && isNotionTimeout)"
        class="error"
      >
        Something went wrong with Notion integration!
        <br />
      </p>
      <p>
        Go to your
        <a routerLink="/org/{{ org.id }}/integrations/notion">
          Notion integration
        </a>
        to check your connection.
      </p>
    </div>

    <div
      *ngIf="
        (isNotionTimeout && integrationNotionStatus !== 'loading') ||
        integrationNotionStatus === 'loading' ||
        integrationNotionStatus === 'ok'
      "
    >
      <!-- fetching db -->
      <div class="loader" *ngIf="fetchingDatabase">
        <nz-spin nzSimple [nzSize]="'medium'"></nz-spin>
      </div>

      <!-- failed to fetch selected db -->
      <div *ngIf="notionDatabaseId && databaseError && !fetchingDatabase">
        <span class="error">Something went wrong with Notion database!</span>
        <div class="right">
          <button
            nz-button
            nzType="default"
            nzSize="small"
            nzDanger
            (click)="remove()"
          >
            <i nz-icon nzType="delete"></i>
            Remove
          </button>
        </div>
      </div>

      <!-- db selected -->
      <div
        *ngIf="notionDatabaseId && !databaseError && !fetchingDatabase"
        class="notion-version-{{ notionVersion }}"
      >
        <label>
          <span>Share to Notion database &nbsp;</span>
          <!-- <nz-tag nzColor="#00C898">
            <span class="tag">v{{ notionVersion }}</span>
          </nz-tag> -->
        </label>
        <nz-input-group
          class="notion-database"
          nzSearch
          [nzAddOnAfter]="suffixIconButton"
        >
          <a [href]="database?.url" target="_blank">
            <input
              type="text"
              nz-input
              [value]="database?.name"
              [disabled]="true"
            />
          </a>
        </nz-input-group>
        <ng-template #suffixIconButton>
          <button
            nz-button
            nzType="primary"
            nzDanger
            nzSearch
            (click)="remove()"
          >
            <i nz-icon nzType="delete"></i>
          </button>
        </ng-template>
        <div
          class="right"
          *ngIf="'isNotionReplayButtonVisible' | hasFeatureFlag"
        >
          <range-button
            class="sync-btn"
            (click)="triggerReplay($event)"
            [disabled]="!validChange"
            [loading]="replaying"
          ></range-button>
        </div>
      </div>

      <!-- select a page for creating db -->
      <div *ngIf="!notionDatabaseId">
        <nz-select
          nzShowSearch
          nzServerSearch
          nzPlaceHolder="Search a parent page by name or using the ID"
          [nzLoading]="integrationNotionStatus === 'loading'"
          [nzOptions]="pages"
          [(ngModel)]="page"
          (nzOnSearch)="onSearch($event)"
          nzSize="large"
          [nzNotFoundContent]="
            integrationNotionStatus === 'loading'
              ? 'Loading...'
              : 'No page found'
          "
          class="notion-pages-select"
        ></nz-select>
        <div class="right">
          <button
            class="create-btn"
            nz-button
            nzType="primary"
            [nzLoading]="creating"
            [disabled]="!page"
            (click)="createDatabase()"
          >
            Create database into this page
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</big-checkbox>
