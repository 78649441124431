<div nz-row class="page-container">
  <div nz-col [nzXs]="24" [nzMd]="12" class="column-container">
    <div class="inner-left">
      <div class="welcome-header">
        <img src="/assets/logo-purple-500.svg" alt="Screeb logo" />
        <ng-container *ngIf="org.logo">
          <span>x</span>
          <img [src]="org.logo" [alt]="org.name" />
        </ng-container>
      </div>
      <div class="main-description no-margin">
        <span class="emphasis">Fast track your onboarding</span>
        <span>
          &nbsp;with a 1:1 call with one of our Product Discovery specialists
        </span>
      </div>
      <div class="sub-description">
        <span class="emphasis">We'll help you discover our features</span>
        <span>
          and set up your account to
          {{
            accountGoalToLabel[sessionService.session.flags.goal].toLowerCase()
          }}
          even faster.
        </span>
      </div>
      <button
        nz-button
        class="big-button action-skip"
        nzType="primary"
        nzSize="large"
        (click)="onSkip()"
      >
        <screeb-icon size="sm" icon="check"></screeb-icon>
        <span>I'm good. Take me to the app!</span>
      </button>
    </div>
  </div>

  <div nz-col [nzXs]="24" [nzMd]="12" class="column-container column-right">
    <div class="calendly-inline-widget"></div>
  </div>
</div>
