import { Component, EventEmitter, Input, Output } from "@angular/core";
import {
  emojiTranscoder,
  emojiTranscoderURLOnly,
} from "components/builder/components/Cards/sanitized-message/emojis";

export type RepartitionIndicatorValue = {
  color: string;
  value: number;
  label: string;
};

export type RepartitionIndicatorValues = RepartitionIndicatorValue[];

// Weird stuff happening here
const emojisSets = {
  nps: [
    "0\u20E3", // 0️⃣
    "1\u20E3", // 1️⃣
    "2\u20E3", // 2️⃣
    "3\u20E3", // 3️⃣
    "4\u20E3", // 4️⃣
    "5\u20E3", // 5️⃣
    "6\u20E3", // 6️⃣
    "7\u20E3", // 7️⃣
    "8\u20E3", // 8️⃣
    "9\u20E3", // 9️⃣
    "🔟", // 🔟
  ],
  csat: ["😡", "😒", "😐", "😄", "😍"],
  ces: [
    "1\u20E3", // 1️⃣
    "2\u20E3", // 2️⃣
    "3\u20E3", // 3️⃣
    "4\u20E3", // 4️⃣
    "5\u20E3", // 5️⃣
    "6\u20E3", // 6️⃣
    "7\u20E3", // 7️⃣
  ],
};

export type EmojiSet = keyof typeof emojisSets;

export type BarClickEvent = { index: number; value: RepartitionIndicatorValue };

@Component({
  selector: "survey-stats-indicators-repartition-indicator",
  templateUrl: "./repartition-indicator.component.html",
  styleUrls: ["./repartition-indicator.component.scss"],
})
export class RepartitionIndicatorStatsSurveyComponent {
  @Input()
  public values: RepartitionIndicatorValues;

  @Input()
  public emojiSet: EmojiSet;

  @Input()
  public chartWidth = 647;
  @Input()
  public chartHeight = 174;

  public legendHeight = 24;
  public barWidth = 12;
  public legendMargin = 4;

  public currentHoveredBarIndex = -1;

  public tooltipXPosition = -1;
  public tooltipYPosition = -1;
  public tooltipValue = 20;
  public tooltipPercent = 10;
  public tooltipLabel = "";

  @Output()
  public barClick = new EventEmitter<BarClickEvent>();

  constructor() {}

  getBarXPosition(index: number) {
    return (
      ((this.chartWidth - this.legendHeight) * index) /
        (this.values.length - 1) +
      this.legendHeight / 2
    );
  }

  getBarBottomYPosition() {
    return (
      this.chartHeight -
      this.legendHeight -
      this.legendMargin * 2 -
      this.barWidth / 2
    );
  }

  getBarTopYPosition(index: number) {
    const bottomYPosition = this.getBarBottomYPosition();

    return (
      bottomYPosition -
      ((bottomYPosition - this.barWidth / 2 - 0.5) * this.values[index].value) /
        this.getValuesMax()
    );
  }

  getValuesMax() {
    return this.values.reduce((max, { value }) => Math.max(max, value), 0);
  }

  getValuesSum() {
    return this.values.reduce((sum, { value }) => sum + value, 0);
  }

  getBarOpacity(index: number) {
    if (this.currentHoveredBarIndex === -1) {
      return 1;
    }
    return this.currentHoveredBarIndex === index ? 1 : 0.3;
  }

  getValuePercent(value: number): number {
    return Math.round((value / this.getValuesSum()) * 100);
  }

  getLabel(index: number): string {
    return emojiTranscoder(emojisSets[this.emojiSet][index]);
  }

  getLabelURL(index: number): string {
    return emojiTranscoderURLOnly(emojisSets[this.emojiSet][index]);
  }

  getBoundedValue(value: number, min: number, max: number) {
    return Math.max(Math.min(value, max), min);
  }

  barMouseEnter(index: number, $event: { target: SVGLineElement }) {
    this.tooltipXPosition = this.getBoundedValue(
      $event.target.getBoundingClientRect().x - 26,
      0,
      document.body.clientWidth - 160,
    );
    this.tooltipYPosition = $event.target.getBoundingClientRect().y - 70;
    this.tooltipLabel = this.getLabel(index);
    this.tooltipValue = this.values[index].value;
    this.tooltipPercent = this.getValuePercent(this.values[index].value);

    this.currentHoveredBarIndex = index;
  }

  barMouseLeave() {
    this.tooltipXPosition = -1;
    this.tooltipYPosition = -1;
    this.currentHoveredBarIndex = -1;
  }

  trackElement(_, { label }: RepartitionIndicatorValue) {
    return label;
  }
}
