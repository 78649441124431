import { Injectable } from "@angular/core";

import { ScreebApiHelper } from "helpers/screeb-api.helper";
import { Survey, SurveyIntegrations } from "./survey.model";
import { TagSettings } from "./tag-settings.types";

@Injectable()
class TagSettingsDao {
  constructor(private screebApiHelper: ScreebApiHelper) {}

  public getOrgTagSettings(orgId: string): Promise<TagSettings> {
    return this.screebApiHelper
      .get<TagSettings>(`/org/${orgId}/settings`)
      .toPromise();
  }

  public updateOrgTagSettings(
    orgId: string,
    settings: TagSettings | object,
  ): Promise<TagSettings> {
    return this.screebApiHelper
      .patch<TagSettings>(`/org/${orgId}`, { settings })
      .toPromise();
  }

  public updateSurveyTagSettings(
    orgId: string,
    surveyId: string,
    settings: TagSettings | object,
    surveySettings: { title: string; tags: string[] },
    integrations: SurveyIntegrations,
    translation_enabled: boolean,
  ): Promise<Survey> {
    return this.screebApiHelper
      .patch<Survey>(`/org/${orgId}/survey/${surveyId}`, {
        settings,
        integrations,
        translation_enabled,
        ...surveySettings,
      })
      .toPromise();
  }

  public updateDistributionTagSettings(
    orgId: string,
    surveyId: string,
    distributionId: string,
    settings: TagSettings | object,
  ): Promise<TagSettings> {
    return this.screebApiHelper
      .patch<TagSettings>(
        `/org/${orgId}/survey/${surveyId}/distribution/${distributionId}`,
        { settings },
      )
      .toPromise();
  }
}

export { TagSettingsDao };
