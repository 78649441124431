<div>
  <section id="parameters">
    <url-builder [(url)]="url" [baseUrl]="baseUrl"></url-builder>
  </section>

  <section class="link-sharing-header">
    <text-snippet-copy
      [copyText]="url"
      [url]="url"
      [qrCode]="url"
      [canOpen]="true"
      (onCopy)="onLinkCopy()"
      (onOpen)="onLinkOpened()"
      (onQRCode)="onQRCodeOpened()"
    ></text-snippet-copy>
  </section>

  <ng-container>
    <hr />
    <h2 class="title">
      <screeb-icon size="md" icon="button" />
      Behaviour
    </h2>
    <p class="desc">Let's customize the behaviour for this channel</p>
  </ng-container>
  <section id="switch-multiple">
    <div class="recurring-survey-title">
      <p class="label">Multiple response</p>
      <nz-switch
        [ngModel]="multiple"
        (ngModelChange)="multipleChange.emit($event)"
        [nzCheckedChildren]="checkedTemplate"
        [nzUnCheckedChildren]="unCheckedTemplate"
      ></nz-switch>
      <ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
      <ng-template #unCheckedTemplate>
        <i nz-icon nzType="close"></i>
      </ng-template>
    </div>
    <p>
      By default, a user can reply to a survey only once.
      <br />
      Activate the multiple responses, to allow a user to reply many times.
    </p>
  </section>
</div>
