<div *ngIf="rule">
  <div class="rule-type-scroll">
    <nz-input-group
      [nzSuffix]="'%'"
      [ngClass]="{ invalid: !checkValidValue(rule) }"
    >
      <rule-input-number
        [(value)]="rule.value.v_n"
        [step]="1"
        [minValue]="0"
        [maxValue]="100"
        [inputGroupMode]="true"
        [placeholder]="rule.getValuePlaceholder()"
        [disabled]="!('survey_edition' | hasEntitlement)"
      ></rule-input-number>
    </nz-input-group>
  </div>
</div>
