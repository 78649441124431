import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "toLocaleString" })
export class ToLocaleStringPipe implements PipeTransform {
  constructor() {}

  transform(toLocaleString: Date | string) {
    return new Date(toLocaleString).toLocaleString("end");
  }
}
