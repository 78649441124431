import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";

import { ApplicationLoadService } from "services/app.service";
import { Sequence } from "models/sequence.types";
import { SequenceDao } from "models/sequence.dao";

@Injectable()
export class SequenceResolver implements Resolve<Sequence> {
  constructor(
    private applicationLoadService: ApplicationLoadService,
    private sequenceDao: SequenceDao,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Promise<Sequence> {
    return this.applicationLoadService
      .loader()
      .then(() => {
        return this.sequenceDao.getById(
          route.params["org_id"],
          route.params["funnel_id"],
        );
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        throw err;
      });
  }
}
