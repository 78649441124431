<div class="header-group">
  <h1>
    Sessions Replay
    <utils-badge
      *ngIf="
        !('isSessionRecordingEnabled' | hasFeatureFlag) ||
        !('session_recording' | hasEntitlement)
      "
      text="Coming soon 🎉"
    ></utils-badge>
  </h1>
  <div class="spacer"></div>
  <div
    class="is-flex"
    *ngIf="
      ('isSessionRecordingEnabled' | hasFeatureFlag) &&
      ('session_recording' | hasEntitlement)
    "
  >
    <div class="disabled-tip" *ngIf="!loading && !sessionReplayEnabled">
      <screeb-icon size="sm" icon="info-circle"></screeb-icon>
      <span>Session Replay is not enabled on your workspace.</span>
    </div>
    <button
      nz-button
      nzSize="large"
      nzType="default"
      [routerLink]="['/org', org.id, 'settings', 'session-replay']"
    >
      Settings
    </button>
  </div>
</div>

<ng-container
  *ngIf="
    !('isSessionRecordingEnabled' | hasFeatureFlag) ||
    !('session_recording' | hasEntitlement)
  "
>
  <div class="waiting-list">
    <hr />
    <p>See How People are Behaving in their User Journey and more... 👀</p>

    <img
      src="/assets/illustrations/sessions-replay.png"
      alt="Sessions Replay"
    />
    <p>
      Record sessions and watch how people interact with your product on web and
      mobile apps.
      <br />
      Filter sessions by survey responses and access to previous responses while
      watching a replay.
    </p>
    <div>
      <p><b>Feel free to join our waiting list here</b></p>
      <button nz-button nzType="primary" (click)="joinWaitingList()">
        Join the waiting list
      </button>
    </div>
  </div>
</ng-container>
<ng-container
  *ngIf="
    ('isSessionRecordingEnabled' | hasFeatureFlag) &&
    ('session_recording' | hasEntitlement)
  "
>
  <user-records
    [org]="org"
    [registryEntriesIdentityProperty]="registryEntriesIdentityProperty"
    [registryEntriesGroup]="registryEntriesGroup"
    [registryEntriesEvent]="registryEntriesEvent"
    [languages]="languages"
  ></user-records>
</ng-container>
