import { HttpErrorResponse } from "@angular/common/http";
import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, CanDeactivate, Router } from "@angular/router";

import { PageComponentInterface } from "components/PageComponentInterface";
import { LayoutBackHeaderService } from "components/layouts/back/header/header.service";
import { NotificationHelper } from "helpers/notification.helper";
import { Org } from "models/org.model";
import { SurveyDistribution } from "models/survey-distribution.model";
import { SurveyDao } from "models/survey.dao";
import {
  SurveyLanguages,
  SwitchCase,
  getActionsFromQuestion,
} from "models/survey.dao.types";
import {
  DefaultMessageName,
  DefaultSurveyName,
  Survey,
} from "models/survey.model";
import { ClipboardService } from "ngx-clipboard";
import { SessionService } from "services/auth.service";
import {
  ImportQuestion,
  ImportQuestionOption,
  ImportService,
} from "services/import.service";
import { PermissionsService } from "services/permissions.service";
import { RoutingService } from "services/routing.service";
import { TagEditorService } from "services/tag-editor.service";
import { TrackersService } from "services/trackers.service";
import { UIService } from "services/ui.service";
import { BuilderStore } from "stores/builder.store";
import {
  BuilderLayoutComponent,
  NewNodeEvent,
  TagEditorParameters,
} from "./components/BuilderLayout/BuilderLayout.component";
import { CardContainer } from "./components/BuilderLayout/Models";
import {
  LateralPanelRemovingPayload,
  LateralPanelSavingPayload,
} from "./components/LateralPanel/LateralPanel.component";
import { routingGetNextNodeId, routingSetCase } from "./routing";

export type Column = {
  cards: CardContainer[];
  exitDotCount: number;
};

@Component({
  selector: "page-builder",
  templateUrl: "./page-builder.component.html",
  styleUrls: ["./page-builder.component.scss"],
})
export class BuilderPageComponent
  implements
    PageComponentInterface,
    OnInit,
    AfterViewInit,
    OnDestroy,
    CanDeactivate<BuilderPageComponent>
{
  public title = "Edit survey";
  public name = "Build survey";

  public org: Org = null;

  private obs: any = null;
  private surveyUpdatedSubscription: any = null;
  private tokenRequestTimeoutSubscription: any = null;
  private isReadySubscription: any = null;

  public saving: boolean = false;
  public errors: any = null;

  public channelType: string;

  public previewSrc: URL;

  public showInPagePopover = false;

  public isSurveyLoading: boolean = false;

  public builderHasError: boolean = false;

  // history
  public surveyHistory: string[] = [];
  public surveyHistoryIndex: number = 0;

  public columnsWithCards: Column[] = [];
  public flattenCards: CardContainer[] = [];

  @ViewChild("pageActions")
  private pageActionsRef: TemplateRef<any>;

  @ViewChild("builderLayout") // 😵‍💫
  private builderLayoutElement: BuilderLayoutComponent;

  // Modal
  public emptyScenario = false;
  public isAIBuilderOpen = false;
  public isTimeoutModalOpen = false;
  public aiQuery: string = null;
  public isGeneratingIdx: number | null = null;
  public forceHelpBox = false;
  public isLateralPanelOpen = false;

  public _document = document;

  public shortcuts = [
    {
      key: ["cmd + z", "ctrl + z"],
      label: "Undo",
      description: "Undo",
      preventDefault: true,
      command: () => {
        try {
          this.onUndo();

          this.notificationHelper.trigger("Undo successful", null, "info");
        } catch (e) {
          console.error(e);
        }
      },
    },
    {
      key: ["cmd + shift + z", "ctrl + shift + z", "cmd + y", "ctrl + y"],
      label: "Redo",
      description: "Redo",
      preventDefault: true,
      command: () => {
        try {
          this.onRedo();

          this.notificationHelper.trigger("Redo successful", null, "info");
        } catch (e) {
          console.error(e);
        }
      },
    },
    {
      key: ["cmd + s", "ctrl + s"],
      label: "Save",
      description: "Save",
      preventDefault: true,
      command: () => {
        if (this.saveButtonDisabled()) return;
        this.onSave().then(() => {
          this.notificationHelper.trigger("Survey saved", null, "success");
        });
      },
    },
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private notificationHelper: NotificationHelper,
    private trackersService: TrackersService,
    private surveyDao: SurveyDao,
    public builderStore: BuilderStore,
    private layoutBackHeaderService: LayoutBackHeaderService,
    public sessionService: SessionService,
    public permissionsService: PermissionsService,
    private importService: ImportService,
    public uiService: UIService,
    private tagEditorService: TagEditorService,
    private clipboardService: ClipboardService,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.obs = this.route.data.subscribe(async (data) => {
      const org = data["org"];
      const survey: Survey = data["survey"];
      this.channelType = this.route.snapshot.params["channel_type"];
      const integrations = data["integrations"];
      const languagesAndCountries = data["languages_and_countries"];

      this.org = org;

      this.builderStore.initBuilder(
        org,
        survey,
        integrations,
        languagesAndCountries.surveyLanguagesWithEmojis,
        (): Promise<any> => this.onSave(),
        !this.permissionsService.isAllowed("survey.update"),
        false,
      );

      this.historyInit();
      this.refreshCards();

      const rawScenarioInLocalStorage = localStorage.getItem(
        "screeb-restore-scenario",
      );
      if (rawScenarioInLocalStorage) {
        try {
          const scenarioInLocalStorage = JSON.parse(rawScenarioInLocalStorage);

          await this.loadAIScenario(
            scenarioInLocalStorage.title,
            scenarioInLocalStorage.scenario,
          );

          localStorage.removeItem("screeb-restore-scenario");
          // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
        } catch (_) {
          // balek
        }
      }

      if (survey.type === "message") {
        this.previewSrc = this.getPreviewSrc();
        this.surveyUpdatedSubscription =
          this.tagEditorService.surveyUpdated.subscribe(() =>
            this.onSurveyChange(),
          );
        this.tokenRequestTimeoutSubscription =
          this.tagEditorService.tokenRequestTimeout.subscribe((value) =>
            this.onTokenRequestTimeout(value),
          );
      }
    });
  }

  onHelp() {
    this.forceHelpBox = !this.forceHelpBox;
  }

  getDistribution(): SurveyDistribution | undefined {
    const channelId = this.route.snapshot.params["channel_id"];
    const distributions = this.uiService.currentSurveyDistributions;

    return distributions.find((d) => d.channel_id === channelId);
  }

  getPreviewSrc() {
    const distribution = this.getDistribution();

    if (distribution?.type === "widget") {
      const ruleURL = distribution.targeting_rules?.find(
        (r) => r.type === "url",
      );
      if (!ruleURL) {
        return;
      }

      let url = ruleURL.value.v_s_arr[0];
      // Append scheme if not present
      if (!url.match(/^https?:\/\//)) {
        url = `https://${url}`;
      }

      return new URL(url);
    }

    return null;
  }

  ngAfterViewInit() {
    // not available at ngOnInit time
    setTimeout(() => {
      this.layoutBackHeaderService.surveyPageActionsRef = this.pageActionsRef;
    });
  }

  ngOnDestroy() {
    if (this.obs) this.obs.unsubscribe();
    if (this.surveyUpdatedSubscription)
      this.surveyUpdatedSubscription.unsubscribe();
    if (this.tokenRequestTimeoutSubscription)
      this.tokenRequestTimeoutSubscription.unsubscribe();
    if (this.isReadySubscription) this.isReadySubscription.unsubscribe();

    this.builderStore.resetBuilder();
    this.tagEditorService.close();

    this.layoutBackHeaderService.surveyPageActionsRef = null;
  }

  private refreshCards() {
    const cards = this.builderStore.refreshCards();
    if (!cards) return;

    this.columnsWithCards = cards.columnsWithCards;
    this.flattenCards = cards.flattenCards;
  }

  public onSurveyChange() {
    this.historyPush();
    this.refreshCards();
  }

  public onTokenRequestTimeout(clear: boolean) {
    this.isTimeoutModalOpen = !clear;
  }

  public onNodeSavedFromLateralPanel(data: LateralPanelSavingPayload) {
    this.builderStore.replaceNode(data.updatedNode);
    this.onSurveyChange();
  }

  public onNodeDeletedFromLateralPanel(data: LateralPanelRemovingPayload) {
    this.builderStore.removeNode(data.node);
    this.onSurveyChange();

    this.trackersService
      .newEventTrackingBuilder("Question Removed")
      .withOrg(this.builderStore.org)
      .withSurvey(this.builderStore.survey)
      .withProps({
        question_type: data.node.node.question.call_to_action.type.toString(),
      })
      .build();
  }

  // when we add a node
  public onNodeAdded(event: NewNodeEvent) {
    const { type, node, action, actions } = event;
    let newNodeId = null;

    // we need to check cta before messageToChain, because for input question, we receive both
    if (!!node && !!action) {
      // link from existing CTA
      const nextNodeId = routingGetNextNodeId(node.node.routing, action.id);
      newNodeId = this.builderStore.addNode(type, nextNodeId);
      const endEffects = node.node.routing.cases.filter(
        (c: SwitchCase) => c.effect.type === "end",
      );

      // In case of multiple actions (multiple values context)
      // we need to link all actions to the next node
      if (actions?.length) {
        for (const a of actions) {
          routingSetCase(node.node.routing, a.id, newNodeId, "next_node");
        }
      } else if (endEffects.length === node.node.routing.cases.length) {
        const qActions = getActionsFromQuestion(node.node.question);
        for (const a of qActions) {
          routingSetCase(node.node.routing, a.id, newNodeId, "next_node");
        }
      } else {
        routingSetCase(node.node.routing, action.id, newNodeId, "next_node");
      }
    } else if (node) {
      // link from text question (chain a simple text message, without cta)
      const nextNodeId = routingGetNextNodeId(node.node.routing, null); // defaut effect, may be null (survey end)
      newNodeId = this.builderStore.addNode(type, nextNodeId);
      routingSetCase(node.node.routing, null, newNodeId, "next_node");
    } else {
      const entryPointID = this.builderStore.survey.scenario.node_entrypoint_id;

      // it means it is the first question of a flow
      newNodeId = this.builderStore.addNode(
        type,
        this.builderStore.survey.scenario.node_entrypoint_id,
      );
      this.builderStore.survey.scenario.node_entrypoint_id = newNodeId;

      // But we might have questions already in the scenario
      // so we need to link the new node to the existing ones
      if (entryPointID && !action) {
        const node = this.builderStore.survey.scenario.nodes.find(
          (n) => n.id === newNodeId,
        );
        routingSetCase(node.routing, null, entryPointID, "next_node");
      }
    }

    this.onSurveyChange();

    if (type === "calendar" && newNodeId) {
      // Wait 350ms to make sure the node is rendered and animation done
      setTimeout(() => {
        const newNode = this.builderStore.nodesById[newNodeId];
        // THIS. IS. FUCKING. DIRTY.
        this.builderLayoutElement.editNode(newNode);
      }, 350);
    }

    this.trackersService
      .newEventTrackingBuilder("Question Added")
      .withOrg(this.builderStore.org)
      .withSurvey(this.builderStore.survey)
      .withProps({
        question_type: type.toString(),
      })
      .build();
  }

  /**
   * Edition history
   */
  public onUndo() {
    this.historyGoTo(this.surveyHistoryIndex - 1);

    this.trackersService
      .newEventTrackingBuilder("Survey scenario editor undo")
      .withOrg(this.builderStore.org)
      .withSurvey(this.builderStore.survey)
      .build();
  }

  public onRedo() {
    this.historyGoTo(this.surveyHistoryIndex + 1);

    this.trackersService
      .newEventTrackingBuilder("Survey scenario editor redo")
      .withOrg(this.builderStore.org)
      .withSurvey(this.builderStore.survey)
      .build();
  }

  public onReset() {
    this.historyGoTo(0);
    this.historyInit();

    this.trackersService
      .newEventTrackingBuilder("Survey scenario editor reset")
      .withOrg(this.builderStore.org)
      .withSurvey(this.builderStore.survey)
      .build();
  }

  public historyInit() {
    const survey = this.builderStore.survey;
    this.surveyHistory = [JSON.stringify(survey)];
    this.surveyHistoryIndex = 0;
  }

  public historyPush() {
    const survey = this.builderStore.survey;
    const stringifiedSurvey = JSON.stringify(survey);

    if (
      this.surveyHistory[this.surveyHistory.length - 1] === stringifiedSurvey
    ) {
      return;
    }

    this.surveyHistory = this.surveyHistory.slice(
      0,
      this.surveyHistoryIndex + 1,
    );
    this.surveyHistoryIndex++;

    this.surveyHistory.push(stringifiedSurvey);
  }

  public historyGoTo(index: number) {
    if (index < 0)
      throw Error("Cannot decrease surveyHistoryIndex (already 0)");
    if (index > this.surveyHistory.length - 1)
      throw Error(
        "Cannot increase surveyHistoryIndex (already end of history)",
      );

    try {
      const json = JSON.parse(this.surveyHistory[index]);
      const newSurvey = new Survey().fromJson(json);
      this.builderStore.setSurvey(newSurvey);
      this.surveyHistoryIndex = index;
      this.refreshCards();
    } catch (err) {
      console.error(err);
    }
  }

  hasMissingTranslation(lang: SurveyLanguages = null) {
    return this.builderStore.hasMissingTranslation(lang);
  }

  /**
   * Save
   */
  public saveButtonDisabled(saveAndNext = false) {
    if (this.builderHasError) return true;
    if (!this.builderStore.survey?.scenario?.node_entrypoint_id) return true;
    if (this.surveyHistoryIndex === 0 && !saveAndNext) return true;
    if (this.hasMissingTranslation()) return true;
    if (this.builderStore.hasIncorrectSettings()) return true;

    this.showInPagePopover = false;

    return false;
  }

  public onSave(force = false): Promise<any> {
    if (this.saveButtonDisabled() && !force) return Promise.resolve();

    this.saving = true;
    this.errors = null;

    this.builderStore.pruneNotLinkedNodes();
    this.builderStore.pruneUnselectedNodesMode();
    this.builderStore.pruneVideoNodeUrls();
    this.builderStore.pruneNonDefaultEmptyQuestionDescription();

    return this.surveyDao
      .updateFlow(
        this.builderStore.org.id,
        this.builderStore.survey.id,
        this.builderStore.survey.scenario,
      )
      .then((survey: Survey) => {
        this.builderStore.setSurvey(survey);
        this.historyInit();
        this.refreshCards();
        this.saving = false;
        this.errors = null;

        this.notificationHelper.trigger(
          (this.uiService.isMessagePage ? "Message" : "Survey") +
            " has been successfully saved",
          null,
          "success",
        );

        const emailDistribution =
          this.uiService.currentSurveyDistributions.find(
            (d) => d.interaction === "email" && d.status === "running",
          );

        if (emailDistribution) {
          this.notificationHelper.trigger(
            "Refresh Email templates",
            "Email distribution is live. Please refresh your emails with the new template.",
            "info",
          );
        }
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationHelper.trigger(
          err?.error?.message ?? "Failed to update survey",
          null,
          "error",
        );

        this.errors = err.error;
        console.error(err.error);

        this.saving = false;
        throw err;
      });
  }

  public async actionOnSave() {
    await this.onSurveyTitleTagsChange(this.builderStore.survey, false);
    this.builderStore.reOrderScoresQuestion();
    await this.builderStore.save();

    if (
      this.surveyHistoryIndex !== 0 ||
      this.builderStore?.survey?.stats.response_rate !== 0
    ) {
      await this.builderStore.save();
    }

    if (this.builderStore?.survey?.stats.response_rate === 0) {
      this.router.navigate(
        [
          "org",
          this.builderStore.org.id,
          this.uiService.surveyTypePath,
          this.builderStore.survey.id,
          "share",
        ],
        {},
      );
    }
  }

  /**
   * Prevent user from navigating away or F5, when something is not saved
   */
  @HostListener("window:beforeunload", ["$event"])
  preventNavigatingAway($event: any) {
    if (this.hasUnsavedData || this.tagEditorService.isOpen) {
      $event.returnValue =
        "You have unsaved changes! If you leave, your changes will be lost.";
    }
  }

  @HostListener("window:unload", ["$event"])
  unloadHandler() {
    this.tagEditorService.close();
  }

  private get hasUnsavedData(): boolean {
    return !this.saveButtonDisabled();
  }

  public canDeactivate(component: BuilderPageComponent): boolean {
    if (component.hasUnsavedData || this.tagEditorService.isOpen) {
      const result = confirm(
        "You have unsaved changes! If you leave, your changes will be lost.",
      );

      if (result) {
        this.tagEditorService.close();
      }

      return result;
    }
    return true;
  }

  onDefaultLanguageChanged($event: SurveyLanguages) {
    this.builderStore.survey.scenario.default_language = $event;
    this.builderStore.currentLanguage = $event;
    this.builderStore.updateDescriptions();
    this.onSurveyChange();

    if (this.hasMissingTranslation()) {
      this.notificationHelper.trigger(
        "Your survey has some problems!",
        "Your default survey language has incomplete translations.",
        "warning",
      );
    }
  }

  changeDefaultLanguage() {
    this.builderStore.changeDefaultLanguage();
    this.historyPush();
    this.onSurveyChange();
  }

  public onOpenAiBuilder(isEmpty: boolean = false) {
    this.isAIBuilderOpen = true;
    this.emptyScenario = isEmpty;
  }

  public onGenerateScenario(idx: number = 0) {
    this.isGeneratingIdx = idx;

    let query = this.aiQuery;
    if (idx === 1) {
      query = `Are my users satisfied about their experience with ${
        this.builderStore.org.name || "my company"
      }?`;
    } else if (idx === 2) {
      query = "Was it easy to use my last feature?";
    } else if (idx === 3) {
      query = "What should I build next for my users to be happy?";
    }

    if (!query?.length) {
      this.isGeneratingIdx = null;
      return;
    }

    this.surveyDao
      .generateScenario(
        this.builderStore.org.id,
        this.builderStore.survey.id,
        query,
      )
      .then((data: any) => {
        const { title, scenario } = data || {};

        return this.loadAIScenario(title, scenario);
      })
      .catch((err) => {
        console.log(err);
        this.notificationHelper.trigger(
          "An error occured while generating the scenario, please try again.",
          null,
          "error",
        );
      })
      .finally(() => {
        this.isGeneratingIdx = null;
      });
  }

  public async onSurveyTitleTagsChange(
    survey: Survey,
    withSurvey: boolean = true,
  ) {
    this.isSurveyLoading = true;

    (withSurvey ? this.onSave(true) : Promise.resolve())
      .then(() =>
        this.surveyDao.updateTitleAndTags(
          this.uiService.currentOrgId,
          this.uiService.currentSurveyId,
          survey.title,
          survey.tags,
        ),
      )
      .then(async (newSurvey) => {
        this.builderStore.setSurvey(newSurvey, false);
        this.uiService.currentSurvey = newSurvey;
      })
      .catch((err) => {
        console.error(err);
        this.notificationHelper.trigger(
          "An error occurred while saving the survey",
          null,
          "error",
        );
      })
      .finally(() => {
        this.isSurveyLoading = false;
      });
  }

  loadAIScenario(title: string, scenario: any) {
    if (!scenario) {
      this.notificationHelper.trigger(
        "Failed to generate scenario",
        "Please try again later",
        "error",
      );
      return;
    }

    // Update title if current title is "New survey"
    if (
      (this.builderStore.survey.title === DefaultSurveyName ||
        this.builderStore.survey.title === DefaultMessageName) &&
      title?.length
    ) {
      this.surveyDao.updateTitleAndTags(
        this.builderStore.org.id,
        this.builderStore.survey.id,
        title,
        this.builderStore.survey.tags,
      );
      this.uiService.currentSurvey.title = title;
      this.builderStore.survey.title = title;
    }

    // Format the questions to the format expected by the import service
    const formattedQuestions = scenario?.map((q) => {
      const question = new ImportQuestion();
      question.id = q.id;
      question.type = q.type;
      question.description = q.question;
      question.skip_id = q.skip_id;
      question.options = q.options?.map((o) => {
        const option = new ImportQuestionOption();
        option.label = o.label;
        option.emoji = o.emoji;
        option.next_id = o.next_id;
        return option;
      });
      return question;
    });

    this.importService.buildSurveyScenario(formattedQuestions);

    this.onSurveyChange();

    // Close the AI builder
    this.isAIBuilderOpen = false;
    this.aiQuery = null;

    this.trackersService
      .newEventTrackingBuilder("Survey scenario AI generated")
      .withOrg(this.builderStore.org)
      .withSurvey(this.builderStore.survey)
      .build();

    return this.builderStore.save();
  }

  public onOpenTagEditor(
    type: "editor" | "preview",
    url: string,
    parameters: TagEditorParameters,
  ) {
    const token = this.route.snapshot.data["tagEditorToken"]?.token;
    if (!token) {
      this.notificationHelper.trigger(
        "Failed to open the tag editor",
        "Please try again later",
        "error",
      );
      return;
    }

    this.tagEditorService.open(type, token, url, parameters);
  }

  clipboardCopy(code: string) {
    this.clipboardService.copy(code);
    this.notificationHelper.trigger(
      "Copied to your clipboard!",
      null,
      "success",
    );
  }

  getCode() {
    return `$screeb('editor.start', '${this.route.snapshot.data["tagEditorToken"].token}');`;
  }

  public onScenarioTranslated() {
    this.onSurveyChange();
  }

  public onErrorChange(error: boolean) {
    this.builderHasError = error;
  }
}
