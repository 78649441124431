import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { NzModalService } from "ng-zorro-antd/modal";
import { ClipboardService } from "ngx-clipboard";

import { HttpErrorResponse } from "@angular/common/http";
import { PageComponentInterface } from "components/PageComponentInterface";
import { NotificationHelper } from "helpers/notification.helper";
import { IntegrationDao } from "models/integration.dao";
import {
  Integration,
  IntegrationSettings,
  IntegrationSpec,
  IntegrationType,
  integrationSpecs,
} from "models/integrations.model";
import { Org } from "models/org.model";
import { ConfigService } from "services/config.service";
import { RoutingService } from "services/routing.service";

export type integrationUpdate = {
  token?: string;
  settings: IntegrationSettings;
};

@Component({
  selector: "page-integration-settings",
  templateUrl: "./integration-settings.component.html",
  styleUrls: ["./integration-settings.component.scss"],
})
// export class IntegrationSettingsPageComponent implements PageComponentInterface, OnInit, OnDestroy, CanDeactivate<IntegrationSettingsPageComponent> {
export class IntegrationSettingsPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title: string;
  public name: string;

  private obs: any = null;
  public integration: Integration = null;

  public integrationExists: boolean = false;
  public integrationSettingsValid: boolean = true;

  public integrationSlug: IntegrationType = null;
  public integrationSpec: IntegrationSpec = null;

  public blockSave: boolean = false;

  constructor(
    private router: Router,
    private modal: NzModalService,
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private notificationHelper: NotificationHelper,
    private clipboardService: ClipboardService,
    private integrationDao: IntegrationDao,
    private configService: ConfigService,
  ) {}

  @Input() public org: Org = null;

  ngOnInit() {
    this.integrationSlug = this.route.snapshot.params["integration_slug"];
    this.integrationSpec = integrationSpecs[this.integrationSlug];

    this.title = `Configure ${this.integrationSpec.name} integration`;
    this.name = `Integration ${this.integrationSpec.name} page`;

    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.obs = this.route.data.subscribe((data) => {
      this.org = data["org"];
      this.integration =
        data["integration"] ||
        Integration.New(this.org.id, this.integrationSlug);
      this.integrationExists = data["integration"]?.id;

      this.blockSave =
        ["segment-source", "amplitude-source", "rudderstack-source"].includes(
          this.integration.type,
        ) && ["idfm"].includes(this.configService.config.platform);
    });
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  public onCopy() {
    this.clipboardService.copy(this.integration.token);
    this.notificationHelper.trigger(
      "Copied to your clipboard!",
      null,
      "success",
    );
  }

  public onSave() {
    this.integrationDao
      .updateIntegration(
        this.org.id,
        this.integrationSlug,
        this.integrationSpec.tokenModifiable ? this.integration?.token : null,
        this.integration?.settings || {},
      )
      .then((integration: Integration) => {
        if (!this.integrationExists) {
          this.notificationHelper.trigger(
            "Integration installed",
            null,
            "success",
          );
        } else {
          this.notificationHelper.trigger(
            "Integration updated",
            null,
            "success",
          );
        }

        this.integration = integration;
        this.integrationExists = true;
        // this.router.navigate(['org', this.org.id, 'integrations']); // @TODO in the future, don't redirect after each save and disable button when nothing to save
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        this.notificationHelper.trigger(
          err?.error?.message ?? "Error",
          null,
          "error",
        );
      });
  }

  private removeIntegration() {
    this.integrationDao
      .removeIntegration(this.org.id, this.integration.type)
      .then(() => {
        this.notificationHelper.trigger("Integration Removed", null, "success");
        this.router.navigate(["org", this.org.id, "integrations"]);
      })
      .catch((err) => {
        console.error(err.error);
        this.notificationHelper.trigger(
          err?.error?.message ?? "Error",
          null,
          "error",
        );
      });
  }

  public showRemoveConfirm(): void {
    this.modal.warning({
      nzTitle: "Are you sure you want to remove this integration?",
      nzOkText: "Remove",
      nzCancelText: "Cancel",
      nzOkType: "default",
      nzOkDanger: true,
      nzStyle: {
        display: "flex",
        "align-items": "center",
        "justify-content": "center",
      },
      nzOnOk: () => this.removeIntegration(),
    });
  }

  public onSettingsChange(update: integrationUpdate) {
    this.integration.settings = update.settings;
    if (this.integrationSpec.tokenModifiable) {
      this.integration.token = update.token;
    }

    if (this.integrationSpec.autoSave) {
      this.onSave();
    }
  }
}
