<section>
  <h2>Authenticating...</h2>

  <div class="loader" *ngIf="loading">
    <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
  </div>
  <error-message
    *ngIf="error"
    message="An error occured when authenticating."
    [details]="error.message"
    gotoLabel="Back to organization"
    gotoLink="/"
  ></error-message>
</section>
