import {
  Routing,
  RoutingEffect,
  SwitchCase,
  UUID,
} from "models/survey.dao.types";

// when action is null we set default behavior
export function routingGetNextNodeId(
  routing: Routing,
  actionId: UUID | null,
): UUID | null {
  if (routing.type !== "switch") throw Error("unexpected routing type");

  if (!actionId) return routingEffectToNodeId(routing.default_effect);

  const nextNodeCase = routing.cases.find((c: SwitchCase): boolean => {
    return c.action_id === actionId && c.effect.type === "next_node";
  });

  if (nextNodeCase) return routingEffectToNodeId(nextNodeCase.effect);
  return routingEffectToNodeId(routing.default_effect);
}

export function routingGetSkipToNodeId(
  routing: Routing,
  actionId: UUID | null,
): UUID | null {
  if (routing.type !== "switch") throw Error("unexpected routing type");

  // we must have an actionId to get a skip_to_node effect
  if (!actionId) return null;

  const nodeCase = routing.cases.find((c: SwitchCase): boolean => {
    return c.action_id === actionId && c.effect.type === "skip_to_node";
  });

  if (nodeCase) return routingEffectToNodeId(nodeCase.effect);
  return null;
}

export function routingEffectToNodeId(
  routingEffect: RoutingEffect,
): UUID | null {
  if (routingEffect.type === "next_node") return routingEffect.next_node_id;
  else if (routingEffect.type === "skip_to_node")
    return routingEffect.skip_to_node_id;
  else if (routingEffect.type === "end") return null;
  else throw Error("unexpected routing effec type");
}

// when actionId is null we set default behavior
// when nodeId is null, the effect will be of type 'end'
export function routingSetCase(
  routing: Routing,
  actionId: UUID | null,
  nodeId: UUID | null,
  effectType: "next_node" | "skip_to_node" = "next_node",
) {
  if (routing.type !== "switch") throw Error("unexpected routing type");

  if (!actionId) {
    if (effectType === "skip_to_node") {
      throw Error("cannot set skip_to_node effect without action_id");
    }
    routing.default_effect = newRoutingEffect(nodeId, effectType);
    return;
  }

  let routingCase = routing.cases.find((c: SwitchCase): boolean => {
    if (effectType === "skip_to_node") {
      return c.action_id === actionId && c.effect.type === "skip_to_node";
    }
    return c.action_id === actionId && c.effect.type !== "skip_to_node";
  });

  if (routingCase) {
    routingCase.effect = newRoutingEffect(nodeId, effectType);
  } else {
    routingCase = {
      action_id: actionId,
      effect: newRoutingEffect(nodeId, effectType),
    };
    routing.cases.push(routingCase);
  }
}

export function newRoutingEffect(
  nodeId: UUID | null,
  effectType: "next_node" | "skip_to_node",
): RoutingEffect {
  if (!nodeId) {
    return newRoutingEffectToEnd();
  }

  if (effectType === "skip_to_node") {
    return newRoutingEffectToSkipNode(nodeId);
  }
  return newRoutingEffectToNode(nodeId);
}

export function newRoutingEffectToNode(nodeId: UUID): RoutingEffect {
  return {
    type: "next_node",
    next_node_id: nodeId,
  };
}

export function newRoutingEffectToSkipNode(nodeId: UUID): RoutingEffect {
  return {
    type: "skip_to_node",
    skip_to_node_id: nodeId,
  };
}

export function newRoutingEffectToEnd(): RoutingEffect {
  return { type: "end" };
}

// when actionId is null we set default behavior
export function routingRemoveCase(
  routing: Routing,
  actionId: UUID | null,
  effectType: string,
) {
  if (routing.type !== "switch") throw Error("unexpected routing type");

  if (!actionId) {
    if (effectType === "skip_to_node") {
      throw Error("cannot set skip_to_node effect without action_id");
    }
    routing.default_effect = newRoutingEffectToEnd();
    return;
  }

  const oprhanCases = routing.cases.filter((c: SwitchCase): boolean => {
    // When removing a skip_to_node effect, we only want to remove that case
    if (effectType === "skip_to_node") {
      return c.action_id === actionId && c.effect.type === "skip_to_node";
    }
    return c.action_id === actionId;
  });

  oprhanCases.forEach((c: SwitchCase) => {
    routing.cases.splice(routing.cases.indexOf(c), 1);
  });
}

export function routingReplaceCase(
  routing: Routing,
  actionId: UUID | null,
  nodeIds: UUID[] | null,
  effectType: string,
) {
  if (routing.type !== "switch") throw Error("unexpected routing type");

  if (!actionId) {
    if (effectType === "skip_to_node") {
      throw Error("cannot set skip_to_node effect without action_id");
    }
    routing.default_effect = newRoutingEffectToEnd();
    return;
  }

  routing.cases.forEach((c: SwitchCase, idx: number) => {
    if (
      effectType === "skip_to_node" &&
      c.action_id === actionId &&
      c.effect.type === "skip_to_node"
    ) {
      if (nodeIds[0]) {
        routing.cases[idx].effect = newRoutingEffectToSkipNode(nodeIds[0]);
      } else {
        routing.cases[idx].effect = newRoutingEffectToEnd();
      }
      return;
    } else if (
      effectType === "next_node" &&
      c.action_id === actionId &&
      c.effect.type !== "skip_to_node"
    ) {
      if (nodeIds[idx]) {
        routing.cases[idx].effect = newRoutingEffectToNode(nodeIds[idx]);
      } else {
        routing.cases[idx].effect = newRoutingEffectToEnd();
      }
    }
  });
}
