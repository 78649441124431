import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NotificationHelper } from "helpers/notification.helper";
import { NzModalService } from "ng-zorro-antd/modal";
import {
  AccountProviderStatus,
  AccountProviderToLabel,
} from "./account-provider.type";
import { AuthService } from "services/auth.service";

@Component({
  selector: "account-provider",
  templateUrl: "./account-provider.component.html",
  styleUrls: ["./account-provider.component.scss"],
})
export class SettingsAccountProviderComponent {
  @Input()
  public provider: AccountProviderStatus;
  @Input()
  public isConnected: boolean = false;
  @Input()
  public error: boolean = false;
  @Input()
  public readOnly: boolean = false;

  public loading = false;
  public accountProviderToLabel = AccountProviderToLabel;

  @Output()
  public accountLinkChange = new EventEmitter<boolean>();

  constructor(
    private authService: AuthService,
    private notificationHelper: NotificationHelper,
    private modalService: NzModalService,
  ) {}

  public onConnect() {
    if (this.error || this.readOnly) return;

    this.authService
      .openAccountLinkingPopup(this.provider.provider)
      .then(() => {
        this.notificationHelper.trigger(
          "Success",
          "Your Screeb account is now linked to this method!",
          "success",
        );

        this.accountLinkChange.emit(true);
      })
      .catch(() =>
        this.notificationHelper.trigger(
          "Error",
          "Failed to link account. Please try again later.",
          "error",
        ),
      );

    // this.auth0
    //   .loginWithPopup({
    //     authorizationParams: {
    //       connection: AccountProviderToConnection[this.provider.provider],
    //       prompt: "login",
    //       display: "popup",
    //     },
    //   })
    //   .subscribe(() => {
    //     this.authService
    //       .linkAccount2(this.provider.provider)
    //       .then(() => {
    //         this.notificationHelper.trigger(
    //           "Success",
    //           "Your Screeb account is now linked to this method!",
    //           "success",
    //         );

    //         this.accountLinkChange.emit(true);
    //       })
    //       .catch(() =>
    //         this.notificationHelper.trigger(
    //           "Error",
    //           "Failed to link account. Please try again later.",
    //           "error",
    //         ),
    //       );
    //   });
  }

  public onDisconnect() {
    if (this.provider.primary || this.error || this.readOnly) {
      return;
    }

    this.modalService.warning({
      nzTitle:
        "Are you sure you want to unlink this login method from your Screeb account?",
      nzContent:
        "You won't be able to use it to connect to this Screeb account anymore.<br>You'll still be able to re-link in the future if needed.",
      nzStyle: {
        display: "flex",
        "align-items": "center",
        "justify-content": "center",
      },
      nzMaskClosable: true,
      nzCloseOnNavigation: false,
      nzOkType: "default",
      nzOkDanger: true,
      nzOkText: "Confirm",
      nzCancelText: "Cancel",
      nzOnOk: () => {
        this.authService
          .unlinkAccount(this.provider.provider)
          .then(() => {
            this.notificationHelper.trigger(
              "Success",
              "Your login method has been successfully unlinked from this Screeb account.",
              "success",
            );

            this.accountLinkChange.emit(false);
          })
          .catch(() =>
            this.notificationHelper.trigger(
              "Error",
              "Failed to unlink account. Please try again later.",
              "error",
            ),
          )
          .finally(() => (this.loading = false));
      },
      nzOnCancel: () => {
        this.loading = false;
      },
    });
  }
}
