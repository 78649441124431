<settings-header
  title="Install Screeb"
  description="Follow the steps below to install Screeb on your website. If you need help,
  don't hesitate to reach out to us or check our
  <a href='https://developers.screeb.app/' target='_blank' rel='noopener'
    >documentation</a
  >."
></settings-header>
<div class="installation-guide">
  <onboarding-installation-guide
    [channels]="channels"
    [org]="org"
    [isOnboarding]="false"
    [guideType]="'screeb'"
  ></onboarding-installation-guide>

  <a
    href="https://developers.screeb.app"
    target="_blank"
    rel="noopener"
    class="installation-guide__doc"
  >
    💡&nbsp;
    <span>
      Having an integration
      <strong>question?</strong>
      Read our Developer doc here!
    </span>
  </a>
</div>
