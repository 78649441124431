import { Pipe, PipeTransform } from "@angular/core";
import { EntitlementService, FeatureKey } from "services/entitlement.service";

@Pipe({
  name: "hasEntitlement",
})
export class EntitlementPipe implements PipeTransform {
  constructor(private entitlementService: EntitlementService) {}

  transform(value: FeatureKey | string): boolean {
    // Check if the function exist
    if (this.entitlementService[value]) {
      return this.entitlementService[value]();
    }
    return this.entitlementService.isAvailable(value as FeatureKey);
  }
}
