<big-checkbox
  class="big-checkbox"
  label="Via email"
  [content]="emailBigCheckbox"
  [(checked)]="enabled"
  (checkedChange)="onEnabledChange($event)"
  [disabled]="!quotaOk"
  name="emailEnabled"
  ngDefaultControl
  collapseWhenNotChecked
>
  <ng-template #emailBigCheckbox>
    <div>
      <label>Share with</label>
      <div>
        <tag-input
          placeHolder="john@pushbutton.com, ..."
          size="large"
          [activateDropdown]="false"
          [autoFocus]="true"
          [ngModel]="emails"
          (ngModelChange)="emailsChange.emit($event)"
        ></tag-input>
      </div>
    </div>

    <div class="error" *ngIf="!isEmailValid() && !emails.length">
      At least one valid email
    </div>
    <div class="error" *ngIf="!isEmailValid() && emails.length">
      All emails should be valid
    </div>
  </ng-template>
</big-checkbox>
