import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { PageComponentInterface } from "components/PageComponentInterface";
import { AuthService } from "services/auth.service";
import { RoutingService } from "services/routing.service";

@Component({
  template: `
    <div class="auth0-login-page"></div>
  `,
  selector: "page-auth0-login",
})
export class Auth0LoginPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Login to Screeb 🚀";
  public name = "Login";

  private sub: any = null;

  constructor(
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.authService.authStatus = "authenticating";

    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.sub = this.route.queryParams.subscribe((queryParams) => {
      this.authService.redirectOnLogin(queryParams);
    });
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }
}
