import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { NotificationHelper } from "helpers/notification.helper";
import { Org } from "models/org.model";
import { SeoService } from "services/seo.service";
import {
  TargetingRule,
  TargetingRuleOperator,
  TargetingRuleType,
} from "models/targeting-rule.model";
import { PageComponentInterface } from "components/PageComponentInterface";
import { HttpErrorResponse } from "@angular/common/http";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { OrgDao } from "models/org.dao";
import { RegistryEntry } from "models/registry.model";
import { EntitlementService } from "services/entitlement.service";
import { Survey } from "models/survey.model";
import { TagSettingsDao } from "models/tag-settings.dao";
import { adaptTagSettingsToApi } from "models/tag-settings.api";
import { ClipboardService } from "ngx-clipboard";

@Component({
  selector: "session-replay-settings",
  templateUrl: "./session-replay.component.html",
  styleUrls: ["./session-replay.component.scss"],
})
export class SettingsSessionReplayPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Session Replay";
  public name = "Settings session replay";

  private obs: any = null;
  public org: Org = null;
  public surveys: Survey[] = [];

  public sessionReplayEnabled: boolean = false;
  public sessionReplayEnabledBackup: boolean = false;

  public sessionReplayConditionsOn = false;

  public recordTargetingRules: TargetingRule[] = [];
  public recordTargetingRulesBackup: TargetingRule[] = [];

  public recordLookbackDuration: number | null = null;
  public recordLookbackDurationBackup: number | null = null;

  public recordAnonymize: boolean = false;
  public recordAnonymizeBackup: boolean = false;

  public recordConsole: boolean = false;
  public recordConsoleBackup: boolean = false;

  public recordOnDisplay: boolean = false;
  public recordOnDisplayBackup: boolean = false;

  public deviceRule: TargetingRule = null;

  public registryEntriesIdentityProperty?: RegistryEntry[];
  public registryEntriesGroup?: RegistryEntry[];
  public registryEntriesEvent?: RegistryEntry[];

  public saving = false;

  public manualCode =
    "// To start the session recording:\n\
$screeb('session-replay.start');\n\n\
// To stop the session recording:\n\
$screeb('session-replay.stop');";

  constructor(
    private route: ActivatedRoute,
    private notificationHelper: NotificationHelper,
    private seoService: SeoService,
    private orgDao: OrgDao,
    public featureFlaggingService: FeatureFlaggingService,
    public entitlementService: EntitlementService,
    private tagSettingsDao: TagSettingsDao,
    private clipboardService: ClipboardService,
  ) {}

  ngOnInit() {
    this.seoService.set({
      title: "Session Replay",
    });

    this.obs = this.route.data.subscribe((data) => {
      this.org = data.org;
      this.surveys = data["surveys"];
      this.recordTargetingRules = data["recordTargetingRules"];

      this.registryEntriesIdentityProperty =
        data["registryEntriesIdentityProperty"];
      this.registryEntriesGroup = data["registryEntriesGroup"].groups;
      this.registryEntriesEvent = data["registryEntriesEvent"];
    });

    this.sessionReplayEnabled = this.org.settings.session_replay_enabled;
    this.sessionReplayEnabledBackup = this.sessionReplayEnabled;

    this.sessionReplayConditionsOn = this.recordTargetingRules.length > 0;

    this.recordTargetingRulesBackup = JSON.parse(
      JSON.stringify(this.getValidTargetingRules()),
    );

    this.recordLookbackDuration =
      this.org.settings.session_replay_lookback_duration;
    this.recordLookbackDurationBackup = this.recordLookbackDuration;

    this.recordAnonymize = this.org.settings.session_replay_anonymize;
    this.recordAnonymizeBackup = this.recordAnonymize;

    this.recordConsole = this.org.settings.session_replay_record_console;
    this.recordConsoleBackup = this.recordConsole;

    this.recordOnDisplay = this.org.settings.session_replay_on_display;
    this.recordOnDisplayBackup = this.recordOnDisplay;
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  public onAnonymizeChange(ev) {
    this.recordAnonymize = ev;
  }

  public tipFormatter(value: number): string {
    return `${value} seconds`;
  }

  private getCleanedOtherTargetingRules(): TargetingRule[] {
    if (this.sessionReplayConditionsOn === false) {
      return [];
    }

    // remove empty line in Array rules that are not saved to avoid unwanted diff between last change and actual change
    return this.recordTargetingRules
      .map((rule) => {
        if (rule.value.v_s_arr !== undefined && rule.value.v_s_arr !== null) {
          const tmpRule = JSON.parse(JSON.stringify(rule));
          const last = tmpRule.value.v_s_arr.length - 1;
          if (
            tmpRule.value.v_s_arr[last] !== undefined &&
            tmpRule.value.v_s_arr[last].length === 0
          ) {
            tmpRule.value.v_s_arr.pop();
          }
          return tmpRule;
        } else if (
          rule.value.v_n_arr !== undefined &&
          rule.value.v_n_arr !== null
        ) {
          const tmpRule = JSON.parse(JSON.stringify(rule));
          const last = tmpRule.value.v_n_arr.length - 1;
          if (
            tmpRule.value.v_n_arr[last] !== undefined &&
            tmpRule.value.v_n_arr[last] === null
          ) {
            // wtf ?
            tmpRule.value.v_n_arr.pop();
          }
          return tmpRule;
        }
        return rule;
      })
      .filter((rule: TargetingRule) => {
        return (
          ![
            "visitor_event_time",
            "visitor_event_count",
            "device_visitor_event_time",
            "device_visitor_event_count",
          ].includes(rule.type) ||
          (this.registryEntriesEvent.length > 0 &&
            rule.value.name_ids !== null &&
            rule.value.name_ids !== undefined &&
            rule.value.name_ids.length !== 0)
        );
      });
  }

  private getValidTargetingRules(): TargetingRule[] {
    return this.getCleanedOtherTargetingRules().filter(
      (rule: TargetingRule) => {
        return !!rule.type;
      },
    );
  }

  public hasUnsavedChanges() {
    // @TODO: better with deepEqual
    const targetingRules = this.getValidTargetingRules();

    return (
      this.sessionReplayEnabled !== this.sessionReplayEnabledBackup ||
      JSON.stringify(targetingRules) !==
        JSON.stringify(this.recordTargetingRulesBackup) ||
      this.recordLookbackDuration !== this.recordLookbackDurationBackup ||
      this.recordAnonymize !== this.recordAnonymizeBackup ||
      this.recordConsole !== this.recordConsoleBackup ||
      this.recordOnDisplay !== this.recordOnDisplayBackup
    );
  }

  public onSessionReplayChange(ev) {
    if (ev) {
      this.createDefaultSettingsIfNotExist();
    } else {
      this.recordTargetingRules = [];
    }
  }

  public onSessionReplayConditionsChange(ev) {
    if (ev) {
      this.deviceRule = this.recordTargetingRules.find(
        (r: TargetingRule) => r.type === "device",
      );
      if (!this.deviceRule) {
        this.deviceRule = this.addRule("device");
      }
    } else {
      this.recordTargetingRules = [];
    }
  }

  public onSave() {
    this.saving = true;

    // Check if rules are valid
    const targetingRules = this.getValidTargetingRules();
    this.orgDao
      .updateRecordTargetingRules(this.org.id, targetingRules)
      .then(() => {
        this.saving = false;
        this.recordTargetingRules = [
          ...targetingRules.map((c) => new TargetingRule().fromJson(c)),
        ];
        this.recordTargetingRulesBackup = [
          ...targetingRules.map((c) => new TargetingRule().fromJson(c)),
        ];
      })
      .then(() => {
        return this.tagSettingsDao
          .updateOrgTagSettings(
            this.org.id,
            adaptTagSettingsToApi(
              {
                ...this.org.settings,
                session_replay_enabled: this.sessionReplayEnabled,
                session_replay_lookback_duration: this.recordLookbackDuration,
                session_replay_anonymize: this.recordAnonymize,
                session_replay_record_console: this.recordConsole,
                session_replay_on_display: this.recordOnDisplay,
              },
              "org",
            ),
          )
          .then(() => {
            this.sessionReplayEnabledBackup = this.sessionReplayEnabled;
            this.recordLookbackDurationBackup = this.recordLookbackDuration;
            this.recordAnonymizeBackup = this.recordAnonymize;
            this.recordConsoleBackup = this.recordConsole;
            this.recordOnDisplayBackup = this.recordOnDisplay;
          });
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err?.error ?? err);
        this.notificationHelper.trigger(
          err?.error?.message ?? "Error",
          null,
          "error",
        );
        this.saving = false;
      });
  }

  private addRule(
    type: TargetingRuleType,
    operator?: TargetingRuleOperator,
    pushRule: boolean = true,
  ): TargetingRule {
    const r = new TargetingRule();
    r.survey_distribution_id = null;
    r.org_id = this.org.id;
    r.type = type;
    r.operator = operator || r.getAvailableOperators()?.[0]?.type;
    r.value = r.getDefaultValue();
    if (pushRule) {
      this.recordTargetingRules.push(r);
    }

    return r;
  }

  private createDefaultSettingsIfNotExist(): void {
    if (
      this.recordLookbackDuration === null ||
      this.recordLookbackDuration === undefined
    ) {
      this.recordLookbackDuration = 30;
    }

    if (this.recordAnonymize === null || this.recordAnonymize === undefined) {
      this.recordAnonymize = false;
    }

    if (this.recordConsole === null || this.recordConsole === undefined) {
      this.recordConsole = true;
    }

    if (this.recordOnDisplay === null || this.recordOnDisplay === undefined) {
      this.recordOnDisplay = true;
    }
  }

  public clipboardCopy(code: string) {
    this.clipboardService.copy(code);
    this.notificationHelper.trigger(
      "Copied to your clipboard!",
      null,
      "success",
    );
  }
}
