<settings-header
  title="Session Replay"
  description="Define the conditions under which session replay will be activated for your users."
  (save)="onSave()"
  [isLoading]="saving"
  [isDisabled]="!hasUnsavedChanges()"
></settings-header>
<section>
  <div class="toggle">
    <div class="toggle-title">
      <h3>
        <screeb-icon size="md" icon="session-start"></screeb-icon>
        Enable Session Replay
      </h3>
      <nz-switch
        [(ngModel)]="sessionReplayEnabled"
        (ngModelChange)="onSessionReplayChange($event)"
        [nzCheckedChildren]="checkedTemplate"
        [nzUnCheckedChildren]="unCheckedTemplate"
      ></nz-switch>
    </div>
    <p>
      If enabled, we will record user sessions on your website, allowing you to
      replay and analyze user interactions.
      <br />
      You can define conditions under which the session recording will begin.
    </p>
  </div>
</section>
<ng-container *ngIf="sessionReplayEnabled">
  <hr />
  <h2>
    <screeb-icon size="sm" icon="filter"></screeb-icon>
    Start Recording Conditions
  </h2>
  <p>
    Define the conditions under which the session recording will begin for your
    users.
  </p>
  <section>
    <div class="toggle">
      <div class="toggle-title">
        <h3>
          <screeb-icon size="md" icon="run"></screeb-icon>
          Start recording on conditions
        </h3>
        <nz-switch
          [(ngModel)]="sessionReplayConditionsOn"
          (ngModelChange)="onSessionReplayConditionsChange($event)"
          [nzCheckedChildren]="checkedTemplate"
          [nzUnCheckedChildren]="unCheckedTemplate"
        ></nz-switch>
      </div>
      <p>
        If enabled, the session recording will begin only when all specified
        conditions are met. This allows you to capture user interactions that
        are most relevant to your analysis.
      </p>
      <div class="rules-tip" *ngIf="sessionReplayConditionsOn">
        <screeb-icon size="sm" icon="info-circle"></screeb-icon>
        <span>We won't record sessions until these conditions are met.</span>
      </div>
      <survey-share-targeting-advanced
        *ngIf="sessionReplayConditionsOn"
        [isOrgTargeting]="true"
        [isRecordTargeting]="true"
        [org]="org"
        [(rules)]="recordTargetingRules"
        [registryEntriesIdentityProperty]="registryEntriesIdentityProperty"
        [registryEntriesGroup]="registryEntriesGroup"
        [registryEntriesEvent]="registryEntriesEvent"
        [surveys]="surveys"
      ></survey-share-targeting-advanced>
    </div>
  </section>
  <hr />
  <section>
    <div class="toggle">
      <div class="toggle-title">
        <h3>
          <screeb-icon size="md" icon="surveys"></screeb-icon>
          Start recording when a survey/message is displayed
        </h3>
        <nz-switch
          [(ngModel)]="recordOnDisplay"
          [nzCheckedChildren]="checkedTemplate"
          [nzUnCheckedChildren]="unCheckedTemplate"
        ></nz-switch>
      </div>
      <p>
        If enabled, the session recording will begin when the survey or message
        is shown to the user regardless of whether the previous conditions are
        met. This provides additional context for analysis, allowing you to view
        the user's interactions immediately before or after they see the survey
        or message, depending on the lookback duration setting.
      </p>
    </div>
  </section>
  <hr />
  <section>
    <div class="label">
      <screeb-icon size="sm" icon="dev-documentation"></screeb-icon>
      Manual start
    </div>
    <p>
      Want to start recording manually? Use the following code snippet to start
      the session recording with your own logic.
    </p>
    <div class="installation-code-container">
      <code
        class="installation-code"
        [highlight]="manualCode"
        language="javascript"
      ></code>
      <button
        class="copy-button"
        nz-button
        nzSize="medium"
        nzType="primary"
        (click)="clipboardCopy(manualCode)"
      >
        Copy
      </button>
    </div>
  </section>
  <hr />
  <h2>
    <screeb-icon size="md" icon="settings"></screeb-icon>
    Recording Behavior
  </h2>
  <p>Define how the session recording will behave once it starts.</p>
  <section>
    <div class="label">
      <screeb-icon size="md" icon="seek-prev"></screeb-icon>
      Lookback Duration
    </div>
    <p>
      Set the maximum time span to start recording a few seconds before all
      specified conditions are met.
      <br />
      By setting a lookback duration, you can capture user interactions leading
      up to the moment the conditions are met, providing crucial context for
      analysis.
      <br />
      Set to 0 to start recording only at the moment the conditions are met.
    </p>
    <div class="slider-container">
      <div class="slider-label">{{ recordLookbackDuration ?? 0 }}s</div>
      <nz-slider
        [(ngModel)]="recordLookbackDuration"
        [nzStep]="10"
        [nzMin]="0"
        [nzMax]="300"
        [nzTipFormatter]="tipFormatter"
      ></nz-slider>
    </div>
  </section>
  <hr />
  <section>
    <div class="label">
      <screeb-icon size="md" icon="password-hidden"></screeb-icon>
      Mask Content in Sessions
    </div>
    <p>
      Choose to mask all texts or only sensitive information like passwords.
      <br />
      You can also precisely decide what you want to mask in your code by using
      the
      <code>screeb-mask-text</code>
      class or block an entire HTML element using the
      <code>screeb-no-capture</code>
      class.
    </p>
    <div class="anonymize-container">
      <div
        class="anonymize-block {{ !recordAnonymize ? 'active' : '' }}"
        (click)="onAnonymizeChange(false)"
      >
        <div class="anonymize-label">
          <screeb-icon size="md" icon="password-shown"></screeb-icon>
          Partial Masking
        </div>
        <div class="anonymize-sidebar">
          <ul>
            <li>Home</li>
            <li>Analyze</li>
            <li>Settings</li>
            <li>Profile</li>
          </ul>
        </div>
        <div class="anonymize-content">
          <h3>Hi John Doe!</h3>
          <p>
            Welcome to your dashboard. Here you can see all your sessions and
            analyze them.
          </p>
          <p>
            You can also change your settings and profile information.
            <br />
            Here's your current password: ******
          </p>
        </div>
      </div>
      <div
        class="anonymize-block {{ recordAnonymize ? 'active' : '' }}"
        (click)="onAnonymizeChange(true)"
      >
        <div class="anonymize-label">
          <screeb-icon size="md" icon="password-hidden"></screeb-icon>
          Full Masking
        </div>
        <div class="anonymize-sidebar">
          <ul>
            <li>****</li>
            <li>******</li>
            <li>*******</li>
            <li>*****</li>
          </ul>
        </div>
        <div class="anonymize-content">
          <h3>** **** ***</h3>
          <p>
            ****** ** **** ******** **** *** *** **** **** ****** *** ********
            ****
          </p>
          <p>
            *** *** **** ******* *** ******* *********** ** *** **** ***
            ********* **** **** **** ** ******* **
          </p>
        </div>
      </div>
    </div>
  </section>
  <hr />
  <section>
    <div class="toggle">
      <div class="toggle-title">
        <h3>
          <screeb-icon size="md" icon="events"></screeb-icon>
          Enable Console logs
        </h3>
        <nz-switch
          [(ngModel)]="recordConsole"
          [nzCheckedChildren]="checkedTemplate"
          [nzUnCheckedChildren]="unCheckedTemplate"
        ></nz-switch>
      </div>
      <p>
        If enabled, we will capture all console logs from the user's browser
        during the session, providing additional context for analysis.
      </p>
    </div>
  </section>
</ng-container>

<ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
<ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>
