import { UUID } from "./survey.dao.types";
import { Account } from "./account.model";

type GenericSequence = {
  id: UUID;
  org_id: UUID;
  title: string;
  created_at: Date;
  updated_at: Date;
  updated_by: UUID;
  // computed
  updated_by_account?: Account;
};

export type SequenceFunnelStepDropCondition = {
  type: "delay";
  delay: number;
};

export type SequenceFunnelStep = {
  event_id: UUID;
  drop_condition: SequenceFunnelStepDropCondition;
  survey_on_drop?: UUID;
  survey_on_success?: UUID;
};

export type SequenceFunnelPayload = {
  steps: SequenceFunnelStep[];
};

export type SequenceFunnel = GenericSequence & {
  type: "funnel";
  funnel: SequenceFunnelPayload;
};

export type Sequence = SequenceFunnel;

export type SequenceType = Sequence["type"];

export const createSequenceFunnelFromEvents = (
  events: UUID[],
): SequenceFunnelPayload => ({
  steps: events.map((event_id) => ({
    event_id,
    drop_condition: { type: "delay", delay: 24 * 60 * 60 }, // ~1 day
  })),
});
