<nz-modal
  [(nzVisible)]="isOpen"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="null"
  [nzClosable]="false"
  (nzOnCancel)="handleCancel()"
  nzWidth="976px"
  nzClassName="release-api-modal"
>
  <ng-template #modalTitle>
    <div class="modal-header">
      <h1>Create Releases via API</h1>
      <div class="onboarding-logo">
        <div
          class="circle"
          style="background-color: #f1167226; transform: rotate(-10.45deg)"
        >
          🎯
        </div>
        <div
          class="circle big abs"
          style="background-color: var(--screeb-color-warning-outline)"
        >
          🚀️
        </div>
        <div
          class="circle right"
          style="background-color: #5e21f126; transform: rotate(8.96deg)"
        >
          📱
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #modalContent>
    <div class="modal-content">
      <p>
        To integrate Screeb to your deployment workflow and create releases with
        our API,
        <a
          href="https://developers.screeb.app/api-and-connectors/releases-api"
          target="_blank"
        >
          <u>check out our documentation.</u>
        </a>
      </p>
    </div>
    <div class="modal-footer">
      <p>Your Authentication Token:</p>
      <text-snippet-copy
        [copyText]="org.api_token"
        [reset]="true"
        (onReset)="resetToken()"
      />
    </div>
  </ng-template>
</nz-modal>
