<header class="lateral-header">
  <div class="left">
    <button (click)="onClose.emit()" class="previous">
      {{ "<" }}
    </button>
    <h2 class="title">Magic Question Suggestions 🪄</h2>
  </div>
</header>

<div class="lateral-body">
  <div class="lateral-body-content">
    <div class="prompt">
      <label for="question-suggestion-textarea" class="lateral-panel-subtitle">
        <h3>What do you want to ask your users about?</h3>
        <p>Use a natural language and be as precise as possible</p>
      </label>

      <nz-textarea-count [nzMaxCharacterCount]="100">
        <textarea
          id="question-suggestion-gpt3-input"
          nz-input
          name="question-suggestion-textarea"
          id="question-suggestion-textarea"
          class="textarea"
          [(ngModel)]="query"
          placeholder="Eg: How to improve the onboarding of my product?"
          [ngClass]="{ invalid: isQueryValid() }"
        ></textarea>
      </nz-textarea-count>

      <div class="right suggest">
        <button
          id="question-suggestion-gpt3-suggest"
          nz-button
          nzType="primary"
          nzSize="large"
          (click)="suggest()"
          [nzLoading]="loading"
          [disabled]="!isQueryValid()"
        >
          Suggest Questions 🪄
        </button>
      </div>
    </div>

    <div class="results" *ngIf="suggestions.length > 0">
      <h3>Questions Suggestions:</h3>
      <ul>
        <li *ngFor="let item of suggestions; let i = index">
          <div class="suggestion-item">
            <div class="left">
              <p class="suggestion-item-question">
                {{ i + 1 }}. {{ item.question }}
              </p>
              <p class="suggestion-item-explanation">
                {{ item.explanation }}
              </p>
            </div>
            <div class="right">
              <button
                class="ant-btn-secondary question-suggestion-gpt3-use"
                nz-button
                nzType="default"
                nzSize="large"
                (click)="use(item.question)"
              >
                Use
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
