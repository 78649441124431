import { ENV } from "environment";
import { Serializable } from "models/serializable.model";

// @TODO: move to backend
export const scalePlanDefaultMAU = 10000;
export const scalePlanDefaultMaxMAU = 100000;
export const scalePlanDefaultWorkspaces = 1;

export const advancedPlanDefaultMAU = 10000;
export const advancedPlanDefaultMaxMAU = 100000;

export type billingStatus = "no_billing" | "trial" | "ok" | "paused"; // a 0€ subscription is considered as "ok"
export type billingCycleDuration = "month" | "year"; // "once" |"day"| "week"

export class OrgBilling extends Serializable {
  constructor(
    public id?: string,
    public super_org_id?: string,
    public stripe_customer_id?: string,
    public stripe_subscription_id?: string,
    public stripe_product_id?: string,
    public stripe_product_name?: string,
    public stripe_price_id?: string,
    public effective_metadata?: OrgBillingMetadata,
    public expected_metadata?: OrgBillingMetadata,

    public billing_status?: billingStatus,
    public billing_cycle_duration?: billingCycleDuration,
    public billing_cycle_end?: Date,
    public billing_trial_duration?: number | null, // in seconds
    public billing_trial_end?: Date | null,
    public billing_last_trial_end?: Date | null,

    public is_company?: boolean,
    public name?: string | null,
    public email?: string | null,
    public address_line_1?: string | null,
    public address_line_2?: string | null,
    public postal_code?: string | null,
    public city?: string | null,
    public country?: string | null,
    public phone_number?: string | null,
    public vat?: string | null,

    public created_at?: Date | null,
    public updated_at?: Date | null,
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);

    if (json["effective_metadata"]) {
      this.effective_metadata = json[
        "effective_metadata"
      ] as OrgBillingMetadata;
    }

    if (json["expected_metadata"]) {
      this.expected_metadata = json["expected_metadata"] as OrgBillingMetadata;
    }

    if (json["billing_cycle_duration"]) {
      this.billing_cycle_duration = json[
        "billing_cycle_duration"
      ] as billingCycleDuration;
    }

    return this;
  }

  public getStripeAdminURLForCustomer(): string | null {
    if (!this.stripe_customer_id) {
      return null;
    }

    if (ENV["ENV"] !== "prod") {
      return (
        "https://dashboard.stripe.com/test/customers/" + this.stripe_customer_id
      );
    }

    return "https://dashboard.stripe.com/customers/" + this.stripe_customer_id;
  }
}

export type OrgBillingMetadata = {
  mtu_scheme: "limited" | "unlimited";
  max_mtu?: number;

  max_workspaces: number;

  // features
  device_tracking: boolean;
  survey_inapp_desktop: boolean;
  survey_inapp_mobile: boolean;
  message_inapp: boolean;

  survey_edition: boolean;
  message_edition: boolean;
  workspace_settings_edition: boolean;

  content_analysis: boolean;
  advanced_survey_customization: boolean;
  session_recording: boolean;
  audio_video_surveys: boolean;
  response_translation: boolean;

  // retention;
  retention_tracking_days: number;
  retention_sessions_days: number;
  retention_responses_days: number;

  iam_saml: boolean;
  iam_mfa: boolean;

  // forward
  integration_productboard: boolean;
  integration_harvestr: boolean;
  integration_zendesk: boolean;
  integration_intercom: boolean;
  integration_trello: boolean;
  integration_cycle: boolean;
  integration_monday: boolean;
  integration_asana: boolean;

  // cdp
  integration_amplitude_source: boolean;
  integration_segment_source: boolean;
  integration_rudderstack_source: boolean;
  integration_contentsquare_source: boolean;
  integration_typeform: boolean;

  // hooks
  integration_webhook: boolean;
  integration_stitch: boolean;
  integration_salesforce: boolean;
  integration_zapier: boolean;
  integration_cobbai: boolean;
  integration_email: boolean;
  integration_notion: boolean;
  integration_atlassian: boolean;
  integration_amplitude_destination: boolean;
  integration_segment_destination: boolean;
  integration_contentsquare_destination: boolean;
  integration_rudderstack_destination: boolean;

  // reporting
  integration_slack: boolean;

  // addons
  white_label: boolean;
};

export type OrgBillingPaymentMethodsSource = {
  id: string;
  type: "card" | "sepa_debit";
  created: number;

  billing_details: {
    address: {
      line1?: string;
      line2?: string;
      city?: string;
      country?: string;
      postal_code?: string;
      state?: string;
    };
    email?: string;
    name?: string;
    phone?: string;
  };

  card?: {
    brand: string;
    exp_month: number;
    exp_year: number;
    last4: string;
  };

  sepa_debit?: {
    bank_code?: string;
    branch_code?: string;
    country?: string;
    last4?: string;
  };
};

export class OrgBillingPaymentMethods extends Serializable {
  constructor(
    public default_payment_method?: OrgBillingPaymentMethodsSource,
    public payment_method?: OrgBillingPaymentMethodsSource[],
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);

    if (json["default_payment_method"])
      this.default_payment_method = json[
        "default_payment_method"
      ] as OrgBillingPaymentMethodsSource;

    if (json["payment_method"])
      this.payment_method = json["payment_method"].map(
        (item) => item as OrgBillingPaymentMethodsSource,
      );

    return this;
  }
}

export class OrgBillingInvoice extends Serializable {
  constructor(
    public id?: string,
    public super_org_id?: string,
    public org_billing_id?: string,

    public invoice_number?: string,
    // public pdf_path?: string,
    public payment_url?: string,
    public amount?: number,
    public vat?: number,
    public currency?: string,
    public paid?: boolean,

    public created_at?: Date,
    public updated_at?: Date,
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);

    return this;
  }
}

export class OrgBillingCoupon extends Serializable {
  constructor(
    public id?: string,
    public code?: string, // optional
    public active?: boolean, // always true
    public type?: "amount" | "percentage",
    public amount_off?: number,
    public percent_off?: number,
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);

    this.type = json["type"] as "amount" | "percentage";

    return this;
  }
}

export function applyCouponToAmount(
  coupon: OrgBillingCoupon,
  amount: number,
): number {
  if (coupon.type === "amount") {
    return Math.max(0, coupon.amount_off);
  } else if (coupon.type === "percentage") {
    return amount * (coupon.percent_off / 100);
  }

  throw Error("unexpected coupon type");
}

type OrgBillingSubscriptionItemPricePerUnit = {
  billing_scheme: "per_unit";

  unit_amount: number;
  unit_amount_decimal: string;
};

type OrgBillingSubscriptionItemPriceVolume = {
  billing_scheme: "tiered";

  tiers_mode: "volume";
  tiers: {
    flat_amount: number;
    flat_amount_decimal: number;
    unit_amount: number;
    unit_amount_decimal: number;
    up_to: number;
  }[];
};

export type OrgBillingSubscription = {
  billing_cycle_anchor: number;
  current_period_start: number;
  current_period_end: number;
  cancel_at: number;
  items: {
    quantity: number;
    price: {
      id: string;
      currency: string;
      product: {
        id: string;
      };
      recurring: {
        interval: string;
        interval_count: number;
        trial_period_end: number;
      };
    } & (
      | OrgBillingSubscriptionItemPricePerUnit
      | OrgBillingSubscriptionItemPriceVolume
    );
  }[];
  discount?: OrgBillingCoupon;
  status:
    | "active"
    | "all"
    | "canceled"
    | "incomplete"
    | "incomplete_expired"
    | "past_due"
    | "trialing"
    | "unpaid";

  // @TODO: decompose "amount" into "discount_amount" and "items_amount"
  amount: number;
};

export type PlanType = "free" | "scale" | "advanced" | "custom";

export type AddonKey =
  | "white-label"
  | "additional-workspaces"
  | "survey-inapp-mobile"
  | "graphical-survey-customization"
  //| "session-recording"
  | "audio-video-survey"
  | "response-translation"
  // | "message-inapp"
  // | "ai-content-analysis"
  | "iam-mfa";

export type freeSubscriptionOptions = {
  planType: "free";
};
export type notFreeSubscriptionOptions = {
  billingCycle: billingCycleDuration;
  planType: "scale" | "advanced" | "custom";
  addons: {
    whiteLabel: boolean;
    additionalWorkspaces: number;
    responseTranslation: boolean;
    audioVideoSurvey: boolean;
    graphicalSurveyCustomization: boolean;
    // aiContentAnalysis: boolean;
    // messageInApp: boolean;
    surveyInAppMobile: boolean;
    iamMFA: boolean;
    // sessionRecording: boolean;
  };
  mtu: number;
  coupon?: OrgBillingCoupon;
};

type PlanDetails = {
  old_products: string[];
  products: PlanBillingSettings[];
  addons: {
    [key in AddonKey]: AddonBillingSettings;
  };
};

export type BillingSettings = {
  plans: {
    scale: PlanDetails;
    advanced: PlanDetails;
  };
};

export type subscriptionOptions =
  | freeSubscriptionOptions
  | notFreeSubscriptionOptions;

export type subscriptionOptionsAddon = {
  addon_key: AddonKey;
  quantity?: number;
};

export type freeTrialOptions = {
  plan: "scale" | "advanced";
  mtu: number;
  billing_cycle: billingCycleDuration;
};

export type subscriptionSummary = {
  plan: {
    plan: PlanBillingSettings;
    monthly_billing_mrr: number;
    yearly_billing_mrr: number;
    yearly_billing_arr: number;
  };
  addons: {
    addon: AddonBillingSettings;
    quantity: number;
    monthly_billing_mrr: number;
    yearly_billing_mrr: number;
    yearly_billing_arr: number;
    included: boolean;
  }[];
  subtotal: {
    monthly_billing_mrr: number;
    yearly_billing_mrr: number;
    yearly_billing_arr: number;
  };
  coupon?: {
    // coupon are applied to each invoice, so a 100€ coupon does not have the same value when applied to a monthly or yearly subscription
    coupon: OrgBillingCoupon;
    monthly_billing_mrr: number;
    yearly_billing_mrr: number;
    yearly_billing_arr: number;
  };
  total: {
    monthly_billing_mrr: number;
    yearly_billing_mrr: number;
    yearly_billing_arr: number;
  };
};

export type PlanBillingSettings = {
  product: string;
  mtu: number;
  mtu_str: string;

  // Not used and should not be used.
  // We decided it must be the role of the backend to select the right price.
  // This is also a security garantee, to ensure nobody will select a lower price for a product.
  price_monthly: string;
  price_yearly: string;

  monthly_billing_mrr: number;
  monthly_billing_arr: number;
  yearly_billing_mrr: number;
  yearly_billing_arr: number;
};

export type AddonBillingSettingsPerMTU = {
  mtu: number;

  // Not used and should not be used.
  // We decided it must be the role of the backend to select the right price.
  // This is also a security garantee, to ensure nobody will select a lower price for a product.
  price_monthly: string;
  price_yearly: string;

  monthly_billing_mrr: number;
  monthly_billing_arr: number;
  yearly_billing_mrr: number;
  yearly_billing_arr: number;
};

export type AddonBillingSettings = {
  old_products: string[];
  per_mtu: { [key: number]: AddonBillingSettingsPerMTU };

  product: string;
  name: string;
  key: string;

  // Not used and should not be used.
  // We decided it must be the role of the backend to select the right price.
  // This is also a security garantee, to ensure nobody will select a lower price for a product.
  // price_monthly: string;
  // price_yearly: string;

  monthly_billing_mrr: number;
  monthly_billing_arr: number;
  yearly_billing_mrr: number;
  yearly_billing_arr: number;
};

export function computeCurrentPrice(
  orgBillingSubscription: OrgBillingSubscription,
): number {
  const price = orgBillingSubscription.items
    .map((item) => {
      if (item.price.billing_scheme === "per_unit") {
        return (item.price.unit_amount / 100) * item.quantity;
      } else if (
        item.price.billing_scheme === "tiered" &&
        item.price.tiers_mode === "volume"
      ) {
        for (const tier of item.price.tiers) {
          if (item.quantity <= tier.up_to) {
            return (item.quantity * tier.unit_amount + tier.flat_amount) / 100;
          }
        }
      }
      return 0;
    })
    .reduce((acc: number, curr: number): number => {
      return acc + curr;
    }, 0);

  if (orgBillingSubscription.discount)
    return price - applyCouponToAmount(orgBillingSubscription.discount, price);

  return price;
}
