import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { ChartData } from "chart.js";
import { SimpleBucket } from "components/surveys/pages/stats/indicators/indicator.utils";
import { AnalyticsDao } from "models/analytics.dao";
import { AnalyticsQueryResponse } from "models/analytics.filters.type";
import { Org } from "models/org.model";
import { UUID } from "models/survey.dao.types";
import { Survey } from "models/survey.model";
import { UIService } from "services/ui.service";
import { deepEqual } from "utils/object";

@Component({
  selector: "widget-device-type",
  templateUrl: "./device-type.component.html",
  styleUrls: ["./device-type.component.scss"],
})
export class WidgetDeviceTypeComponent implements OnInit, OnChanges {
  @Input() org: Org;
  @Input() surveys: Survey[];
  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() orgHasRespondents: boolean;

  public isLoading: boolean = true;
  public orgHasResponses: boolean = false;
  public respondentDeviceData: ChartData<"doughnut"> = { datasets: [] };

  constructor(
    public uiService: UIService,
    private analyticsDao: AnalyticsDao,
  ) {}

  ngOnInit() {
    this.orgHasRespondents = false;
    this.respondentDeviceData = this.getAnswersPlatformRepartitionData();

    this.reload();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.startDate?.currentValue !== changes.startDate?.previousValue ||
      changes.endDate?.currentValue !== changes.endDate?.previousValue
    ) {
      this.reload();
    }
  }

  async reload() {
    this.isLoading = true;

    try {
      await this.getAnswersPlatformRepartition();
    } catch (error) {
      console.log("error", error);
    } finally {
      this.isLoading = false;
    }
  }

  private async getAnswersPlatformRepartition() {
    if (!this.surveys.length || !this.orgHasRespondents) {
      this.isLoading = false;
      return;
    }

    this.isLoading = true;

    const query: AnalyticsQueryResponse = {
      org_id: UUID(this.org.id),
      survey_ids: ["*"],
      filters_bool: "AND",
      type: "response",
      filters: [],
      range: {
        start: this.startDate,
        end: this.endDate,
        field: "created_at",
      },
      aggregation: [
        {
          by: "by_hidden_field.value",
          params: {
            hidden_field_key: "support",
          },
        },
      ],
      size: 0,
    };
    const response = await this.analyticsDao.search(query);

    this.respondentDeviceData = this.getAnswersPlatformRepartitionData(
      response.aggregations.hidden_field.hidden_field.hidden_field.buckets,
    );
    this.orgHasResponses = true;

    if (deepEqual(this.respondentDeviceData.datasets[0].data, [0, 0])) {
      this.respondentDeviceData = this.getAnswersPlatformRepartitionData();
      this.orgHasResponses = false;
    }

    this.isLoading = false;
  }

  private getAnswersPlatformRepartitionData(buckets?: SimpleBucket[]) {
    const style = window.getComputedStyle(document.body);
    const desktop =
      buckets?.find(({ key }) => key === "desktop")?.doc_count ?? 0;
    const mobile = buckets?.find(({ key }) => key === "mobile")?.doc_count ?? 0;
    const tablet = buckets?.find(({ key }) => key === "tablet")?.doc_count ?? 0;
    const isZero = desktop + mobile + tablet === 0;
    return {
      labels: ["Desktop", "Mobile", "Tablet"],
      datasets: [
        {
          data: isZero
            ? [desktop, mobile, tablet, 100]
            : [desktop, mobile, tablet],
          backgroundColor: isZero
            ? [
                style.getPropertyValue("--screeb-color-purple-500"),
                style.getPropertyValue("--screeb-color-green-500"),
                style.getPropertyValue("--screeb-color-yellow-500"),
                style.getPropertyValue("--screeb-color-grey-200"),
              ]
            : [
                style.getPropertyValue("--screeb-color-purple-500"),
                style.getPropertyValue("--screeb-color-green-500"),
                style.getPropertyValue("--screeb-color-yellow-500"),
              ],
          spacing: 0,
          borderColor: "transparent",
          hoverBorderColor: "transparent",
        },
      ],
    };
  }
}
