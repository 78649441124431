// - returns true when no keyword is provided
// - lowercase both strings
// - trim `keywords`
// - all words of `keywords` must be present
export function simpleFTS(str: string, keywords: string): boolean {
  const parts = keywords
    .toLowerCase()
    .trim()
    .split(" ")
    .filter((part) => part.trim() !== "");

  const normalizedStr = str.toLowerCase();
  return !parts.find((part) => !normalizedStr.includes(part));
}
