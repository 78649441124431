import { Component, EventEmitter, Output } from "@angular/core";
import { PermissionsService } from "services/permissions.service";

@Component({
  selector: "release-onboarding-modal",
  templateUrl: "./release-onboarding-modal.component.html",
  styleUrls: ["./release-onboarding-modal.component.scss"],
})
export class ReleaseOnboardingModalComponent {
  @Output() onboardingClose: EventEmitter<void> = new EventEmitter();

  constructor(public permissionsService: PermissionsService) {}

  handleOnboardingClose(): void {
    this.onboardingClose.emit();
  }
}
