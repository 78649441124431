import { Component, Input } from "@angular/core";
import { TargetingRule } from "models/targeting-rule.model";

@Component({
  selector: "rule-or-neither",
  templateUrl: "./rule-or-neither.component.html",
  styleUrls: ["./rule-or-neither.component.scss"],
})
export class TargetingRuleOrNeitherComponent {
  @Input() public rule: TargetingRule = null;
  @Input() public index: number = null;

  constructor() {}
}
