<nz-modal
  [(nzVisible)]="isOpen"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  [nzClosable]="false"
  (nzOnCancel)="handleCancel()"
  nzWidth="457px"
  nzClassName="receiver-modal"
>
  <ng-template #modalTitle>
    <h3>{{ receiver !== null ? "Edit a Receiver" : "Add a new Receiver" }}</h3>
  </ng-template>

  <ng-template #modalContent>
    <form nz-form nz-form [formGroup]="validateForm" (ngSubmit)="onSubmit()">
      <nz-form-item>
        <nz-form-label>First name</nz-form-label>
        <nz-form-control
          class="form-input"
          nzErrorTip="Please fill this field"
          required
        >
          <input
            type="text"
            nz-input
            nzHasFeedback
            formControlName="firstname"
            placeholder="Frida"
            required
          />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label>Last name</nz-form-label>
        <nz-form-control
          class="form-input"
          nzErrorTip="Please fill this field"
          required
        >
          <input
            type="text"
            nz-input
            nzHasFeedback
            formControlName="lastname"
            placeholder="Khalo"
            required
          />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label>Email</nz-form-label>
        <nz-form-control
          class="form-input"
          nzErrorTip="Please fill this field"
          required
        >
          <input
            type="text"
            nz-input
            nzHasFeedback
            formControlName="email"
            placeholder="frida@screeb.app"
            required
          />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label>Role</nz-form-label>
        <nz-form-control
          class="form-input"
          nzErrorTip="Please fill this field"
          required
        >
          <input-select
            [options]="roles"
            formControlName="role"
            placeholder="Product Manager"
          ></input-select>
        </nz-form-control>
      </nz-form-item>
    </form>
  </ng-template>

  <ng-template #modalFooter>
    <button
      nz-button
      nzType="default"
      nzSize="large"
      class="receiver-cancel-btn"
      (click)="handleCancel()"
    >
      Cancel
    </button>
    <button
      nz-button
      nzSize="large"
      nzType="primary"
      [class]="'receiver-add-btn'.concat(isFormValid() ? '' : ' disabled')"
      (click)="onSubmit()"
      [nzLoading]="loading"
      [disabled]="!isFormValid()"
    >
      {{ receiver !== null ? "Update" : "Add" }}
    </button>
  </ng-template>
</nz-modal>
