export type WidgetSpec = {
  type: string;
  title: string;
  description: string;
  backgroundColor: string;
  icon: string;
  iconSize: "sm" | "md";

  height: string | undefined;

  width: "small" | "medium" | "full" | undefined;
};

export const widgetsSpecs: WidgetSpec[] = [
  {
    type: "nps",
    title: "NPS",
    description:
      "Measures customer loyalty by asking how likely they are to recommend your product/services on a scale of 0-10.",
    backgroundColor: "#e2d6ff",
    icon: "nps",
    iconSize: "md",
    height: undefined,
    width: "small",
  },
  {
    type: "ces",
    title: "CES",
    description:
      "Evaluates the ease of customer interactions by asking how much effort was required to handle their request.",
    backgroundColor: "#e2d6ff",
    icon: "ces",
    iconSize: "md",
    height: undefined,
    width: "small",
  },
  {
    type: "csat",
    title: "CSAT",
    description:
      "Measures customer satisfaction on a scale (usually 1-5) after interactions or overall experience.",
    backgroundColor: "#e2d6ff",
    icon: "talk-to-a-human",
    iconSize: "sm",
    height: undefined,
    width: "small",
  },
  {
    type: "mau",
    title: "Users Activity Trend",
    description:
      "Tracks user engagement patterns, including login frequency and feature usage.",
    backgroundColor: "#e2d6ff",
    icon: "analyze",
    iconSize: "sm",
    height: undefined,
    width: "small",
  },
  {
    type: "completionRate",
    title: "Completion rate",
    description:
      "Measures the percentage of users who fully answered to a survey.",
    backgroundColor: "#e2d6ff",
    icon: "filter",
    iconSize: "sm",
    height: undefined,
    width: "small",
  },
  {
    type: "displayNumber",
    title: "Total displays",
    description:
      "Counts the number of times your survey or messages are shown to users.",
    backgroundColor: "#e2d6ff",
    icon: "password-shown",
    iconSize: "md",
    height: undefined,
    width: "small",
  },
  {
    type: "responseNumber",
    title: "Total responses",
    description: "Measures the total number of responses received from users.",
    backgroundColor: "#e2d6ff",
    icon: "events",
    iconSize: "md",
    height: undefined,
    width: "small",
  },
  {
    type: "responseRate",
    title: "Response rate",
    description:
      "Measures the percentage of users who responded out of those who were prompted.",
    backgroundColor: "#e2d6ff",
    icon: "response",
    iconSize: "md",
    height: undefined,
    width: "small",
  },
  {
    type: "activity",
    title: "Active users",
    description:
      "Tracks the number of users actively engaging with your website/application over a specific period.",
    backgroundColor: "#e2d6ff",
    icon: "active-user",
    iconSize: "sm",
    height: undefined,
    width: "full",
  },
  {
    type: "emotions",
    title: "Emotions",
    description:
      "Measures user sentiment and emotional responses to your product or services.",
    backgroundColor: "#e2d6ff",
    icon: "low-ces",
    iconSize: "sm",
    height: undefined,
    width: "small",
  },
  {
    type: "device_type",
    title: "Devices",
    description: "Get the repartition of devices used by your users",
    backgroundColor: "#e2d6ff",
    icon: "mobile",
    iconSize: "md",
    height: undefined,
    width: "small",
  },
  {
    type: "identification",
    title: "Identification",
    description: "Get the repartition of logged and anonymous users",
    backgroundColor: "#e2d6ff",
    icon: "click",
    iconSize: "sm",
    height: undefined,
    width: "small",
  },
  {
    type: "changelog",
    title: "News & changelog",
    description: "Get the latest news and updates",
    backgroundColor: "#e2d6ff",
    icon: "bookmark",
    iconSize: "sm",
    height: "265px",
    width: "small",
  },
  {
    type: "help",
    title: "Help & quickstart",
    description: "",
    backgroundColor: "#e2d6ff",
    icon: "question-mark",
    iconSize: "sm",
    height: undefined,
    width: "full",
  },
  {
    type: "last_surveys",
    title: "Surveys",
    description: "Show a list of the last surveys",
    backgroundColor: "#e2d6ff",
    icon: "survey",
    iconSize: "sm",
    height: undefined,
    width: "small",
  },
  {
    type: "entitlement_mtu",
    title: "Usage",
    description:
      "Track your Monthly Active Users usage right from your dashboard.",
    backgroundColor: "#e2d6ff",
    icon: "question-mark",
    iconSize: "sm",
    height: undefined,
    width: "small",
  },
  {
    type: "quote_of_the_day",
    title: "Quote of the day",
    description: "A little bit of wisdom to start your day, nothing more.",
    backgroundColor: "#e2d6ff",
    icon: "comment",
    iconSize: "sm",
    height: "265px",
    width: "small",
  },
];
