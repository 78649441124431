import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { GraphNode } from "components/builder/flow";

@Component({
  selector: "lateral-panel-edit-message",
  templateUrl: "./edit-message.component.html",
  styleUrls: ["./edit-message.component.scss"],
})
export class LateralPanelEditMessageComponent implements OnInit {
  @Input() node: GraphNode = null;
  @Output() openTagEditorAtIndex = new EventEmitter<number>();
  @Output() errorChange = new EventEmitter<boolean>();

  constructor() {}

  public ngOnInit() {
    this.validateData();
  }

  /**
   * Data validation
   */
  public validateData() {
    this.errorChange.emit(false);
  }

  public onOpenPreviewClick() {
    this.openTagEditorAtIndex.emit(this.node.index);
  }
}
