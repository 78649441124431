import { Serializable } from "models/serializable.model";
import { RegistryEntrySource } from "models/registry.model";

export class UserEvent extends Serializable {
  constructor(
    public id?: string,
    public name_id?: string,
    public source?: RegistryEntrySource,
    public raw_properties?: object,
    public properties?: object,
    public triggered_at?: Date,
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);

    this.source = json["source"] as RegistryEntrySource;

    return this;
  }
}
