<div class="menu-block" [ngClass]="{ 'menu-collapsed': isMenuCollapsed }">
  <!-- header of sidebar -->
  <div class="sidebar-logo">
    <a routerLink="/" routerLinkActive="active" *ngIf="!isMenuCollapsed">
      <img alt="Screeb" src="/assets/logo-white.png" />
    </a>

    <span
      class="sidebar-trigger reduce"
      *ngIf="!isMenuCollapsed"
      (click)="onClickMenuCollapse()"
    >
      <!--<i class="trigger" nz-icon [nzType]="'double-left'" nzTheme="outline"></i>-->
      <img alt="Menu" class="trigger" src="/assets/icons/layout/collapse.svg" />
    </span>
    <span
      class="sidebar-trigger grow-up"
      *ngIf="isMenuCollapsed"
      (click)="onClickMenuCollapse()"
    >
      <!--<i class="trigger" nz-icon [nzType]="'double-right'" nzTheme="outline"></i>-->
      <img alt="Menu" class="trigger" src="/assets/icons/layout/collapse.svg" />
    </span>
  </div>

  <!-- super org picker -->
  <ng-container
    *ngIf="uiService.superOrgs?.length && uiService.superOrgs.length > 1"
  >
    <ul
      class="org-picker {{ isSuperOrgSidebar ? 'super-org-sidebar' : '' }} {{
        superOrgPickerOpened ? 'is-active' : ''
      }}"
      nz-menu
      [routerLink]="
        uiService.superOrgs.length === 1
          ? '/overview/' + uiService.currentSuperOrgId + '/home'
          : null
      "
      nzMode="inline"
      [nzInlineCollapsed]="isMenuCollapsed"
    >
      <ng-template #orgTitle>
        <div class="org-picker-selected-wrapper">
          <screeb-icon size="md" icon="overview"></screeb-icon>
          <div class="org-picker-selected" *ngIf="!isMenuCollapsed">
            <span>Organization</span>
            <span>
              {{ uiService.currentSuperOrg?.name ?? "My organization" }}
            </span>
          </div>
        </div>
      </ng-template>
      <li
        nz-submenu
        [(nzOpen)]="superOrgPickerOpened"
        [nzTitle]="orgTitle"
        [nzDisabled]="uiService.superOrgs.length === 1"
      >
        <div class="org-picker-search" *ngIf="uiService.superOrgs.length > 9">
          <input
            type="text"
            placeholder="Search"
            [ngModel]="superOrgPickerSearch"
            (ngModelChange)="onSuperOrgSearchChange($event)"
          />
          <screeb-icon
            *ngIf="!superOrgPickerSearch.length"
            size="sm"
            icon="search"
          ></screeb-icon>
          <screeb-icon
            *ngIf="superOrgPickerSearch.length"
            class="clear-search"
            size="sm"
            icon="cross-circle"
            (click)="superOrgPickerSearch = ''"
          ></screeb-icon>
        </div>
        <ul *ngIf="superOrgPickerOpened">
          <li
            *ngIf="uiService.superOrgs.length > 9 && filteredOrgs?.length === 0"
            nz-menu-item
          >
            <div class="no-results">No orgs matching your search</div>
          </li>
          <li
            *ngFor="
              let org of filteredOrgs ?? uiService.superOrgs;
              trackByProperty: 'id'
            "
            nz-menu-item
          >
            @let firstWorkspaceURL = getFirstWorkspaceURL(org.id);
            <a
              [routerLink]="
                ('super-org.update' | hasSuperPermission)
                  ? '/overview/' + org.id
                  : firstWorkspaceURL
              "
              routerLinkActive="active"
              (click)="superOrgPickerOpened = false"
            >
              <span
                *ngIf="org.id === uiService.currentSuperOrg?.id"
                class="selected-org"
              ></span>
              {{ org.name }}
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </ng-container>

  <ul
    *ngIf="isSuperOrgSidebar && uiService.currentSuperOrg"
    nz-menu
    class="menu-actions"
    nzMode="inline"
    [nzInlineCollapsed]="isMenuCollapsed"
  >
    <li
      *ngIf="uiService.currentOrg"
      id="menu-back-workspace"
      class="main-item is-active"
      nz-menu-item
      nzTooltipPlacement="right"
      nz-tooltip
      nzMatchRouter
    >
      <a
        routerLink="/org/{{ uiService.currentOrg.id }}/home"
        routerLinkActive="active"
      >
        <screeb-icon size="sm" icon="back-arrow"></screeb-icon>
        <span *ngIf="!isMenuCollapsed">Back to Workspace</span>
      </a>
    </li>
    <li
      id="menu-overview"
      class="main-item"
      nz-menu-item
      nzTooltipPlacement="right"
      nz-tooltip
      nzMatchRouter
    >
      <a
        routerLink="/overview/{{ uiService.currentSuperOrg.id }}/home"
        routerLinkActive="active"
      >
        <screeb-icon size="md" icon="overview"></screeb-icon>
        <span *ngIf="!isMenuCollapsed">Overview</span>
      </a>
    </li>
  </ul>

  <!-- org picker -->
  <ng-container *ngIf="uiService.orgWorkspaces?.length">
    @let isActive =
      uiService.currentPageRoutePath?.indexOf(
        "/overview/" + uiService.currentSuperOrgId + "/workspaces"
      ) === 0;
    <ul
      class="org-picker {{ isSuperOrgSidebar ? 'super-org-sidebar' : '' }} {{
        isSuperOrgSidebar && (orgPickerOpened || isActive) ? 'is-active' : ''
      }}"
      nz-menu
      nzMode="inline"
      [routerLink]="
        uiService.orgWorkspaces.length === 1 &&
        !('super-org.update' | hasSuperPermission)
          ? '/org/' + uiService.orgWorkspaces[0].id
          : null
      "
      [nzInlineCollapsed]="isMenuCollapsed"
    >
      <ng-template #workspaceTitle>
        <div class="org-picker-selected-wrapper">
          <screeb-icon size="md" icon="desktop"></screeb-icon>
          <div class="org-picker-selected" *ngIf="!isMenuCollapsed">
            <span
              class="{{
                !uiService.currentOrg || isSuperOrgSidebar ? 'main-title' : ''
              }}"
            >
              Workspace
              <span
                class="dummy-mode"
                *ngIf="
                  uiService.currentOrg?.settings?.dummy_mode &&
                  !isSuperOrgSidebar
                "
              >
                Demo
                <i
                  nz-icon
                  nzType="question-circle"
                  nzTheme="outline"
                  nzTooltipTitle="This workspace is operating in demo mode with sample data for demonstration. Additional sample data will be generated periodically."
                  nzTooltipPlacement="rightBottom"
                  nz-tooltip
                ></i>
              </span>
            </span>
            <span *ngIf="uiService.currentOrg && !isSuperOrgSidebar">
              {{ uiService.currentOrg?.name }}
            </span>
          </div>
          <div *ngIf="!isMenuCollapsed" class="spacer"></div>
        </div>
      </ng-template>
      <li
        nz-submenu
        [(nzOpen)]="orgPickerOpened"
        [nzTitle]="workspaceTitle"
        [nzDisabled]="
          uiService.orgWorkspaces.length === 1 &&
          !('super-org.update' | hasSuperPermission)
        "
      >
        <div
          class="org-picker-search"
          *ngIf="uiService.orgWorkspaces.length > 9"
        >
          <input
            type="text"
            placeholder="Search"
            [ngModel]="orgPickerSearch"
            (ngModelChange)="onOrgSearchChange($event)"
          />
          <screeb-icon
            *ngIf="!orgPickerSearch.length"
            size="sm"
            icon="search"
          ></screeb-icon>
          <screeb-icon
            *ngIf="orgPickerSearch.length"
            class="clear-search"
            size="sm"
            icon="cross-circle"
            (click)="orgPickerSearch = ''"
          ></screeb-icon>
        </div>
        <ul *ngIf="orgPickerOpened">
          <li
            *ngIf="
              uiService.orgWorkspaces.length > 9 &&
              filteredWorkspaces?.length === 0
            "
            nz-menu-item
          >
            <div class="no-results">No workspaces matching your search</div>
          </li>
          <li
            nz-menu-item
            *ngIf="
              !isSuperOrgSidebar &&
              uiService.currentSuperOrg &&
              ('super-org.update' | hasSuperPermission)
            "
          >
            <a
              routerLink="/overview/{{ uiService.currentSuperOrg.id }}"
              [queryParams]="{ workspace: uiService.currentOrg?.id }"
              (click)="orgPickerOpened = false"
            >
              <span>
                <screeb-icon size="md" icon="overview"></screeb-icon>
                Organization Overview
              </span>
            </a>
          </li>
          <li
            *ngFor="
              let org of filteredWorkspaces ?? uiService.orgWorkspaces;
              trackByProperty: 'id'
            "
            nz-menu-item
          >
            <a routerLinkActive="active" (click)="onOrgSelected(org)">
              <span
                *ngIf="org.id === uiService.currentOrg?.id"
                class="selected-org"
              ></span>
              <span>
                {{ org.name }}
              </span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </ng-container>

  <!-- Menu Bar -->
  <ng-container *ngIf="uiService.currentOrg && !isSuperOrgSidebar">
    <ul
      nz-menu
      class="menu-actions"
      nzMode="inline"
      [nzInlineCollapsed]="isMenuCollapsed"
    >
      <li
        id="menu-home"
        class="main-item"
        nz-menu-item
        [nzTooltipTitle]="isMenuCollapsed ? 'Home' : undefined"
        nzTooltipPlacement="right"
        nz-tooltip
        nzMatchRouter
      >
        <a
          routerLink="/org/{{ uiService.currentOrg?.id }}/home"
          routerLinkActive="active"
        >
          <screeb-icon size="md" icon="home"></screeb-icon>
          <span *ngIf="!isMenuCollapsed">Home</span>
        </a>
      </li>

      <ng-template #analyticsTitle>
        <a
          routerLink="/org/{{ uiService.currentOrg?.id }}/people/respondent"
          routerLinkActive="active"
          class="sub-menu-title"
        >
          <screeb-icon size="md" icon="analytics"></screeb-icon>
          <span *ngIf="!isMenuCollapsed">Observe</span>
        </a>
      </ng-template>

      <li
        id="menu-analytics"
        class="main-item"
        nz-submenu
        [nzTitle]="analyticsTitle"
        [nzOpen]="true"
      >
        <ul>
          <li
            id="menu-user-profiles"
            nz-menu-item
            nzMatchRouter
            [nzTooltipTitle]="isMenuCollapsed ? 'User Profiles' : undefined"
            nzTooltipPlacement="right"
            nz-tooltip
          >
            <a
              routerLink="/org/{{ uiService.currentOrg?.id }}/people/respondent"
              routerLinkActive="active"
            >
              <span>User Profiles</span>
            </a>
            <div class="selected-item-indicator"></div>
          </li>
          <li
            id="menu-user-segments"
            nz-menu-item
            nzMatchRouter
            [nzTooltipTitle]="isMenuCollapsed ? 'User Segments' : undefined"
            nzTooltipPlacement="right"
            nz-tooltip
          >
            <a
              routerLink="/org/{{ uiService.currentOrg?.id }}/people/segment"
              routerLinkActive="active"
            >
              <span>User Segments</span>
            </a>
            <div class="selected-item-indicator"></div>
          </li>
          <li
            *ngIf="'isNotDeviceTracking' | hasFeatureFlag"
            id="menu-funnels"
            nz-menu-item
            nzMatchRouter
            [nzTooltipTitle]="isMenuCollapsed ? 'Funnels' : undefined"
            nzTooltipPlacement="right"
            nz-tooltip
          >
            <a
              routerLink="/org/{{ uiService.currentOrg?.id }}/funnel/list"
              routerLinkActive="active"
            >
              <span>Funnels Analysis</span>
            </a>
            <div class="selected-item-indicator"></div>
          </li>
          <li
            *ngIf="'isNotDeviceTracking' | hasFeatureFlag"
            id="menu-sessions-recordings"
            nz-menu-item
            nzMatchRouter
            [nzTooltipTitle]="isMenuCollapsed ? 'Sessions Replay' : undefined"
            nzTooltipPlacement="right"
            nz-tooltip
          >
            <a
              routerLink="/org/{{ uiService.currentOrg?.id }}/people/sessions"
              routerLinkActive="active"
            >
              <span>Sessions Replay</span>
              <utils-badge
                *ngIf="
                  ('session_recording' | hasEntitlement) &&
                  ('isSessionRecordingEnabled' | hasFeatureFlag)
                "
                text="Beta"
                top="0"
                backgroundColor="var(--screeb-color-yellow-500)"
                foregroundColor="var(--screeb-color-black)"
              ></utils-badge>
              <utils-badge
                *ngIf="
                  !(
                    ('session_recording' | hasEntitlement) &&
                    ('isSessionRecordingEnabled' | hasFeatureFlag)
                  )
                "
                text="Soon"
                top="0"
                backgroundColor="var(--screeb-color-blue-500)"
                foregroundColor="var(--screeb-color-black)"
              ></utils-badge>
            </a>
            <div class="selected-item-indicator"></div>
          </li>
          <li
            id="menu-benchmark"
            nz-menu-item
            nzMatchRouter
            [nzTooltipTitle]="isMenuCollapsed ? 'Benchmark' : undefined"
            nzTooltipPlacement="right"
            nz-tooltip
          >
            <a
              routerLink="/org/{{ uiService.currentOrg?.id }}/benchmark"
              routerLinkActive="active"
            >
              <span>Benchmark</span>
            </a>
            <div class="selected-item-indicator"></div>
          </li>
        </ul>
      </li>

      <ng-template #studiesTitle>
        <a
          routerLink="/org/{{ uiService.currentOrg?.id }}/survey/list"
          routerLinkActive="active"
          class="sub-menu-title"
        >
          <screeb-icon size="md" icon="studies"></screeb-icon>
          <span *ngIf="!isMenuCollapsed">Collect</span>
        </a>
      </ng-template>
      <li
        id="menu-studies"
        class="main-item"
        nz-submenu
        [nzTitle]="studiesTitle"
        [nzOpen]="true"
      >
        <ul>
          <li
            id="menu-surveys"
            nz-menu-item
            nzMatchRouter
            [nzTooltipTitle]="isMenuCollapsed ? 'Surveys' : undefined"
            nzTooltipPlacement="right"
            nz-tooltip
          >
            <a
              routerLink="/org/{{ uiService.currentOrg?.id }}/survey/list"
              routerLinkActive="active"
            >
              <span>Surveys</span>
            </a>
            <div class="selected-item-indicator"></div>
          </li>
          <li
            *ngIf="'survey.create' | hasPermission"
            id="menu-surveys-templates"
            nz-menu-item
            nzMatchRouter
            [nzTooltipTitle]="isMenuCollapsed ? 'Templates' : undefined"
            nzTooltipPlacement="right"
            nz-tooltip
          >
            <a
              routerLink="/org/{{ uiService.currentOrg?.id }}/survey/create"
              routerLinkActive="active"
            >
              <span>Templates</span>
            </a>
            <div class="selected-item-indicator"></div>
          </li>
        </ul>
      </li>

      <ng-template #engageTitle>
        <a
          routerLink="/org/{{ uiService.currentOrg?.id }}/message/list"
          routerLinkActive="active"
          class="sub-menu-title"
        >
          <screeb-icon size="md" icon="send"></screeb-icon>
          <span *ngIf="!isMenuCollapsed">Engage</span>
        </a>
      </ng-template>
      <li
        id="menu-engage"
        class="main-item"
        nz-submenu
        [nzTitle]="engageTitle"
        [nzOpen]="true"
      >
        <ul>
          <li
            id="menu-messages"
            nz-menu-item
            nzMatchRouter
            [nzTooltipTitle]="isMenuCollapsed ? 'Messages' : undefined"
            nzTooltipPlacement="right"
            nz-tooltip
          >
            <a
              routerLink="/org/{{ uiService.currentOrg?.id }}/message/list"
              routerLinkActive="active"
            >
              <span>
                Messages

                <screeb-icon
                  *ngIf="
                    !('message_inapp' | hasEntitlement) ||
                    !('message_edition' | hasEntitlement)
                  "
                  size="sm"
                  icon="upgrade"
                ></screeb-icon>
              </span>
            </a>
            <div class="selected-item-indicator"></div>
          </li>
          <li
            *ngIf="'survey.create' | hasPermission"
            id="menu-surveys-templates"
            nz-menu-item
            nzMatchRouter
            [nzTooltipTitle]="isMenuCollapsed ? 'Templates' : undefined"
            nzTooltipPlacement="right"
            nz-tooltip
          >
            <a
              routerLink="/org/{{ uiService.currentOrg?.id }}/message/create"
              routerLinkActive="active"
            >
              <span>Templates</span>
            </a>
            <div class="selected-item-indicator"></div>
          </li>
        </ul>
      </li>

      <ng-template #deliverTitle>
        <a
          routerLink="/org/{{ uiService.currentOrg?.id }}/releases"
          routerLinkActive="active"
          class="sub-menu-title"
        >
          <screeb-icon size="md" icon="releases"></screeb-icon>
          <span *ngIf="!isMenuCollapsed">Deliver</span>
        </a>
      </ng-template>
      <li
        id="menu-deliver"
        class="main-item"
        nz-submenu
        [nzTitle]="deliverTitle"
        [nzOpen]="true"
      >
        <ul>
          <li
            id="menu-releases"
            class="main-item"
            nz-menu-item
            nzMatchRouter
            [nzTooltipTitle]="isMenuCollapsed ? 'Releases' : undefined"
            nzTooltipPlacement="right"
            nz-tooltip
          >
            <a
              routerLink="/org/{{ uiService.currentOrg?.id }}/releases"
              routerLinkActive="active"
            >
              <span>Releases</span>
            </a>
          </li>

          <li
            *ngIf="'integration.create' | hasPermission"
            id="menu-integrations"
            class="main-item"
            nz-menu-item
            nzMatchRouter
            [nzTooltipTitle]="isMenuCollapsed ? 'Integrations' : undefined"
            nzTooltipPlacement="right"
            nz-tooltip
          >
            <a
              routerLink="/org/{{ uiService.currentOrg?.id }}/integrations"
              routerLinkActive="active"
            >
              <span>Integrations</span>
            </a>
          </li>

          <li
            *ngIf="
              ('weeklyBriefingEnabled' | hasFeatureFlag) &&
              ('weekly-briefing.list' | hasPermission)
            "
            id="menu-briefings"
            class="main-item"
            nz-menu-item
            nzMatchRouter
            [nzTooltipTitle]="isMenuCollapsed ? 'Weekly Briefs' : undefined"
            nzTooltipPlacement="right"
            nz-tooltip
          >
            <a
              routerLink="/org/{{ uiService.currentOrg?.id }}/weekly-briefs"
              routerLinkActive="active"
            >
              <span>Weekly Briefs</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </ng-container>
  <ng-container *ngIf="uiService.currentSuperOrg && isSuperOrgSidebar">
    <ul
      nz-menu
      class="menu-actions"
      nzMode="inline"
      [nzInlineCollapsed]="isMenuCollapsed"
    >
      <li
        id="menu-teammates"
        class="main-item"
        nz-menu-item
        [nzTooltipTitle]="isMenuCollapsed ? 'Teammates' : undefined"
        nzTooltipPlacement="right"
        nz-tooltip
        nzMatchRouter
      >
        <a
          routerLink="/overview/{{ uiService.currentSuperOrg.id }}/users"
          routerLinkActive="active"
        >
          <screeb-icon size="md" icon="community"></screeb-icon>
          <span *ngIf="!isMenuCollapsed">Teammates</span>
        </a>
      </li>
      <li
        id="menu-billing"
        class="main-item"
        *ngIf="'billing.list' | hasSuperPermission"
        nz-menu-item
        [nzTooltipTitle]="isMenuCollapsed ? 'Billing' : undefined"
        nzTooltipPlacement="right"
        nz-tooltip
        nzMatchRouter
      >
        <a
          routerLink="/overview/{{ uiService.currentSuperOrg.id }}/billing"
          routerLinkActive="active"
        >
          <screeb-icon size="md" icon="nps"></screeb-icon>
          <span *ngIf="!isMenuCollapsed">Billing</span>
        </a>
      </li>
    </ul>
  </ng-container>
</div>

<div class="bottom-blocks">
  <div class="notification-blocks">
    <ng-container
      *ngFor="
        let notificationBlockKey of uiService.notificationBlockKeys;
        let i = index;
        trackByProperty: 'notificationBlockKey'
      "
    >
      <div
        class="notification-block"
        [ngClass]="{ 'menu-collapsed': isMenuCollapsed, hidden: i > 0 }"
        style="z-index: {{ -i }}"
        (click)="isMenuCollapsed ? onClickMenuCollapse() : null"
      >
        <ng-container
          [ngTemplateOutlet]="
            uiService.notificationBlockTemplates[notificationBlockKey]
          "
        >
          <div
            class="notification-block__close"
            *ngIf="!isMenuCollapsed"
            (click)="uiService.closeNotificationBlock()"
          >
            <screeb-icon size="sm" icon="cross"></screeb-icon>
          </div>

          <div
            class="notification-block__close"
            *ngIf="isMenuCollapsed"
            (click)="onClickMenuCollapse()"
          >
            <screeb-icon size="sm" icon="plus"></screeb-icon>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <ng-template #notificationBlockUpgradeTemplate>
    <div class="notification-block__header">
      <img alt="Upgrade" src="/assets/illustrations/upgrade-rocket.svg" />
      <p class="notification-block__title">An upgrade is available!</p>
    </div>
    <p class="notification-block__content">
      We've just pushed some changes to our app, reload it to enjoy them!
    </p>
    <div class="notification-block__cta">
      <a
        nz-button
        nzType="link"
        nzSize="small"
        href="https://changelog.screeb.app"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span>View changelog</span>
      </a>
      <button
        nz-button
        nzType="primary"
        nzSize="small"
        (click)="upgradeAdminService.upgradeApp()"
      >
        <i nz-icon nzType="reload"></i>
        <span>Reload</span>
      </button>
    </div>
  </ng-template>

  <ng-template #notificationBlockMTUTemplate>
    <div class="notification-block__header">
      <screeb-icon
        size="sm"
        icon="warning"
        style="color: var(--screeb-color-error)"
      ></screeb-icon>
      <p class="notification-block__title">
        {{
          (uiService.currentSuperOrg.entitlements.mtu_mode === "auto"
            ? uiService.currentSuperOrg
            : uiService.currentOrg
          ).usedMTUPercent >= 100
            ? "You're out of MAUs!"
            : "You're almost out of MAUs!"
        }}
      </p>
    </div>
    <p class="notification-block__content">
      You used
      {{
        (uiService.currentSuperOrg.entitlements.mtu_mode === "auto"
          ? uiService.currentSuperOrg
          : uiService.currentOrg
        ).usedMTUPercent
      }}% of your MAU, check your usage and upgrade your subscription to avoid
      any disruption.
      <nz-progress
        [nzPercent]="
          (uiService.currentSuperOrg.entitlements.mtu_mode === 'auto'
            ? uiService.currentSuperOrg
            : uiService.currentOrg
          ).usedMTUPercent
        "
        nzSize="small"
        [nzShowInfo]="false"
        [nzStrokeColor]="
          (uiService.currentSuperOrg.entitlements.mtu_mode === 'auto'
            ? uiService.currentSuperOrg
            : uiService.currentOrg
          ).usedMTUPercent <= 80
            ? 'var(--screeb-color-primary)'
            : 'var(--screeb-color-error)'
        "
        [nzStatus]="
          (uiService.currentSuperOrg.entitlements.mtu_mode === 'auto'
            ? uiService.currentSuperOrg
            : uiService.currentOrg
          ).usedMTUPercent > 80
            ? 'exception'
            : 'normal'
        "
      ></nz-progress>
    </p>
    <div class="notification-block__cta">
      <button
        nz-button
        *ngIf="'billing.list' | hasSuperPermission"
        nzType="primary"
        nzSize="small"
        [routerLink]="
          '/overview/' +
          uiService.currentSuperOrg?.id +
          (uiService.currentSuperOrg.entitlements.mtu_mode === 'auto'
            ? '/billing'
            : '/home')
        "
      >
        <span
          *ngIf="uiService.currentSuperOrg.entitlements.mtu_mode === 'auto'"
        >
          Check Billing
        </span>
        <span
          *ngIf="uiService.currentSuperOrg.entitlements.mtu_mode !== 'auto'"
        >
          Check Quota
        </span>
      </button>
    </div>
  </ng-template>

  <ng-template #notificationBlockDegradedTemplate>
    <div class="notification-block__header">
      <screeb-icon
        size="sm"
        icon="dev-documentation"
        style="color: var(--screeb-color-warning)"
      ></screeb-icon>
      <p class="notification-block__title">Degraded mode</p>
    </div>
    <p class="notification-block__content">
      Screeb is currently in degraded mode, some features may not work as
      expected.
    </p>
    <div class="notification-block__cta">
      <a
        nz-button
        nzType="primary"
        nzSize="small"
        href="https://status.screeb.app"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span>Check our status page</span>
      </a>
    </div>
  </ng-template>

  <ng-template #notificationBlockSDKUpdateTemplate>
    <div class="notification-block__header">
      <img alt="Upgrade" src="/assets/illustrations/upgrade-rocket.svg" />
      <p class="notification-block__title">Mobile SDK update is available!</p>
    </div>
    <p class="notification-block__content">
      We've just pushed some changes to our Mobile SDK, make sure to update it
      to enjoy them!
      <span
        *ngIf="
          currentAndroidSDKVersion &&
          currentAndroidSDKVersion !== configService.latestAndroidVersion
        "
        style="display: block"
      >
        🤖 Android
        <b>{{ currentAndroidSDKVersion }}</b>
        →
        <b>{{ configService.latestAndroidVersion }}</b>
      </span>
      <span
        *ngIf="
          currentIOSSDKVersion &&
          currentIOSSDKVersion !== configService.latestIosVersion
        "
        style="display: block"
      >
        🍏 iOS
        <b>{{ currentIOSSDKVersion }}</b>
        →
        <b>{{ configService.latestIosVersion }}</b>
      </span>
    </p>
    <div class="notification-block__cta">
      <button
        nz-button
        nzType="primary"
        nzSize="small"
        [routerLink]="
          '/org/' + uiService.currentOrg?.id + '/settings/sdk-changelogs'
        "
      >
        <span>Check changelog</span>
      </button>
    </div>
  </ng-template>

  <!-- bottom menu -->
  <div class="menu-block" [ngClass]="{ 'menu-collapsed': isMenuCollapsed }">
    <div
      class="schedule-demo"
      *ngIf="
        uiService.currentOrg &&
        !isMenuCollapsed &&
        (('isTrialing' | hasEntitlement) || !('hasSubscribed' | hasEntitlement))
      "
    >
      <button nz-button nzType="default" (click)="scheduleDemo()">
        Schedule a Demo
      </button>
    </div>

    <div class="bottom-menu">
      <button
        class="bottom-menu-button"
        nz-popover
        [nzPopoverPlacement]="isMenuCollapsed ? 'topLeft' : 'topRight'"
        nzPopoverTrigger="click"
        nzPopoverOverlayClassName="bottom-menu-overlay"
        [nzPopoverContent]="bottomMenuTemplate"
        [(nzPopoverVisible)]="bottomMenuPopoverVisible"
      >
        <org-account-avatar
          [noTooltip]="true"
          [account]="sessionService.session"
          size="small"
        ></org-account-avatar>
        <div class="user-fullname" *ngIf="!isMenuCollapsed">
          {{ sessionService.session.fullname }}
        </div>
        <screeb-icon
          size="sm"
          icon="select"
          *ngIf="!isMenuCollapsed"
        ></screeb-icon>
        <div
          class="feature-flag"
          *ngIf="
            (('accountIsScreebTeam' | hasFeatureFlag) ||
              ('accountIsConnectAs' | hasFeatureFlag)) &&
            !featureFlaggingService.disableFeatureFlags
          "
        >
          <screeb-icon
            *ngIf="!featureFlaggingService.disableFeatureFlags"
            size="sm"
            icon="warning"
          ></screeb-icon>
        </div>
      </button>
    </div>
    <ng-template #bottomMenuTemplate>
      <ul>
        <li
          *ngIf="
            uiService.currentOrg &&
            uiService.currentSuperOrg &&
            (uiService.currentSuperOrg.entitlements.mtu_mode === 'auto'
              ? uiService.currentSuperOrg
              : uiService.currentOrg
            ).usedMTUPercent !== null
          "
        >
          <a
            href="javascript:void(0)"
            [routerLink]="
              ('billing.list' | hasSuperPermission)
                ? '/overview/' + uiService.currentSuperOrg?.id + '/billing'
                : null
            "
            (click)="closeBottomMenu()"
          >
            <screeb-icon
              *ngIf="
                (uiService.currentSuperOrg.entitlements.mtu_mode === 'auto'
                  ? uiService.currentSuperOrg
                  : uiService.currentOrg
                ).usedMTUPercent > 80
              "
              size="sm"
              icon="warning"
            ></screeb-icon>
            <screeb-icon
              *ngIf="
                (uiService.currentSuperOrg.entitlements.mtu_mode === 'auto'
                  ? uiService.currentSuperOrg
                  : uiService.currentOrg
                ).usedMTUPercent <= 80
              "
              size="sm"
              icon="info-circle"
            ></screeb-icon>
            <span>MAU Usage</span>
            <div class="spacer"></div>
            <span class="used-mtu">
              {{
                (uiService.currentSuperOrg.entitlements.mtu_mode === "auto"
                  ? uiService.currentSuperOrg.usedMTUPercent
                  : uiService.currentOrg.usedMTUPercent) ?? 0
              }}%
            </span>
          </a>
          <nz-progress
            [nzPercent]="
              (uiService.currentSuperOrg.entitlements.mtu_mode === 'auto'
                ? uiService.currentSuperOrg
                : uiService.currentOrg
              ).usedMTUPercent
            "
            nzSize="small"
            [nzShowInfo]="false"
            [nzStrokeColor]="
              (uiService.currentSuperOrg.entitlements.mtu_mode === 'auto'
                ? uiService.currentSuperOrg
                : uiService.currentOrg
              ).usedMTUPercent <= 80
                ? 'var(--screeb-color-primary)'
                : 'var(--screeb-color-error)'
            "
            [nzStatus]="
              (uiService.currentSuperOrg.entitlements.mtu_mode === 'auto'
                ? uiService.currentSuperOrg
                : uiService.currentOrg
              ).usedMTUPercent > 80
                ? 'exception'
                : 'normal'
            "
          ></nz-progress>
        </li>
        <li id="menu-settings" nzMatchRouter>
          <!-- the workspace queryParams is used by uiService for finding current workspace -->
          <a
            routerLink="/settings/account"
            [queryParams]="{ workspace: uiService.currentOrg?.id }"
            routerLinkActive="active"
            (click)="closeBottomMenu()"
          >
            <screeb-icon size="sm" icon="user"></screeb-icon>
            <span>Account Settings</span>
          </a>
        </li>
        <li
          *ngIf="uiService.currentOrg && !isSuperOrgSidebar"
          id="menu-settings"
          nzMatchRouter
        >
          <a
            routerLink="/org/{{ uiService.currentOrg?.id }}/settings"
            routerLinkActive="active"
            (click)="closeBottomMenu()"
          >
            <screeb-icon size="sm" icon="widget-settings"></screeb-icon>
            <span>Workspace Settings</span>
          </a>
        </li>
        <li
          id="menu-help"
          nz-popover
          nzPopoverPlacement="right"
          nzPopoverOverlayClassName="bottom-menu-overlay bottom-menu-overlay--secondary"
          [nzPopoverOverlayStyle]="{ marginLeft: '12px' }"
          [nzPopoverContent]="bottomMenuHelpTemplate"
        >
          <hr />
          <a href="javascript:void(0)">
            <screeb-icon size="sm" icon="question-mark"></screeb-icon>
            <span>Help & Docs</span>
            <screeb-icon size="sm" icon="right-arrow"></screeb-icon>
          </a>
        </li>
        <li
          *ngIf="
            ('accountIsScreebTeam' | hasFeatureFlag) ||
            ('accountIsConnectAs' | hasFeatureFlag)
          "
          id="menu-switch-feature-flags"
          nzMatchRouter
        >
          <a
            href="javascript:void(0)"
            (click)="featureFlaggingService.toggleFeatureFlags()"
            style="color: {{
              !featureFlaggingService.disableFeatureFlags
                ? 'var(--screeb-color-warning)'
                : 'var(--screeb-color-info)'
            }}"
          >
            <screeb-icon
              *ngIf="!featureFlaggingService.disableFeatureFlags"
              size="sm"
              icon="warning"
              style="color: {{
                !featureFlaggingService.disableFeatureFlags
                  ? 'var(--screeb-color-warning)'
                  : 'var(--screeb-color-info)'
              }}; opacity: 1;"
            ></screeb-icon>
            <screeb-icon
              *ngIf="featureFlaggingService.disableFeatureFlags"
              size="sm"
              icon="info-circle"
              style="color: {{
                !featureFlaggingService.disableFeatureFlags
                  ? 'var(--screeb-color-warning)'
                  : 'var(--screeb-color-info)'
              }}; opacity: 1;"
            ></screeb-icon>
            <span>
              Demo mode
              <b>
                {{ !featureFlaggingService.disableFeatureFlags ? "off" : "on" }}
              </b>
            </span>
          </a>
        </li>
        <li
          *ngIf="
            ('accountIsScreebTeam' | hasFeatureFlag) ||
            ('accountIsConnectAs' | hasFeatureFlag)
          "
          id="menu-switch-debug-mode"
          nzMatchRouter
        >
          <a
            href="javascript:void(0)"
            (click)="showDebugModal = true"
            style="color: {{
              entitlementService.hasDebugMode
                ? 'var(--screeb-color-warning)'
                : null
            }}"
          >
            <screeb-icon
              *ngIf="entitlementService.hasDebugMode"
              size="sm"
              icon="warning"
              style="color: var(--screeb-color-warning); opacity: 1"
            ></screeb-icon>
            <screeb-icon
              *ngIf="!entitlementService.hasDebugMode"
              size="sm"
              icon="info-circle"
            ></screeb-icon>
            <span>
              Debug mode
              <b>
                {{ !entitlementService.hasDebugMode ? "off" : "on" }}
              </b>
            </span>
          </a>
        </li>
        <li
          *ngIf="'isThemeSwitchEnabled' | hasFeatureFlag"
          id="menu-theme"
          nzMatchRouter
        >
          <a href="javascript:void(0)" (click)="toggleTheme()">
            <screeb-icon size="sm" icon="theme"></screeb-icon>
            <span>Toggle theme</span>
          </a>
        </li>
        <li id="menu-logout" nzMatchRouter (click)="closeBottomMenu()">
          <hr />
          <a (click)="logout()">
            <screeb-icon size="sm" icon="log-out"></screeb-icon>
            <span>Logout</span>
          </a>
        </li>
      </ul>
    </ng-template>
    <ng-template #bottomMenuHelpTemplate>
      <ul>
        <li id="menu-feedback" nzMatchRouter>
          <a
            id="feedback"
            href="mailto:simon@screeb.app"
            (click)="onFeedback(); closeBottomMenu()"
            target="_blank"
          >
            <screeb-icon size="sm" icon="share"></screeb-icon>
            <span>Share feedback</span>
          </a>
        </li>
        <li id="menu-talk-to-human" nzMatchRouter (click)="closeBottomMenu()">
          <intercom>
            <a id="intercom-help-i-need-somebody-help">
              <screeb-icon size="sm" icon="talk-to-a-human"></screeb-icon>
              <span>Talk to a human</span>
              <!-- Human assistance -->
            </a>
          </intercom>
        </li>
        <li id="menu-help-center" nzMatchRouter (click)="closeBottomMenu()">
          <a rel="noopener" href="https://help.screeb.app" target="_blank">
            <screeb-icon size="sm" icon="question-mark"></screeb-icon>
            <span>Help Center</span>
          </a>
        </li>
        <li id="menu-changelog" (click)="closeBottomMenu()">
          <a rel="noopener" href="https://changelog.screeb.app" target="_blank">
            <screeb-icon size="sm" icon="notification"></screeb-icon>
            <span>Changelog</span>
          </a>
        </li>
        <li
          id="menu-developer-documentation"
          nzMatchRouter
          (click)="closeBottomMenu()"
        >
          <a
            rel="noopener"
            href="https://developers.screeb.app"
            target="_blank"
          >
            <screeb-icon size="sm" icon="dev-documentation"></screeb-icon>
            <span>Developer doc.</span>
          </a>
        </li>
        <li id="menu-status-page" nzMatchRouter (click)="closeBottomMenu()">
          <a
            rel="noopener"
            href="{{ configService.config.statusEndpoint }}"
            target="_blank"
          >
            <screeb-icon size="sm" icon="analyze"></screeb-icon>
            <span>Status page</span>
          </a>
        </li>
      </ul>
    </ng-template>
  </div>
  <nz-modal
    [(nzVisible)]="showDebugModal"
    nzTitle="Debug mode"
    (nzOnCancel)="showDebugModal = false; onReload()"
    (nzOnOk)="showDebugModal = false; onReload()"
    [nzCancelText]="null"
  >
    <ng-container *nzModalContent>
      <div class="debug">
        <div
          class="debug-entry"
          *ngFor="let entry of debugFlagsNames | keyvalue"
        >
          <nz-switch
            [ngModel]="!!entitlementService.debugFlags[entry.key]"
            (ngModelChange)="entitlementService.setDebugFlag(entry.key, $event)"
          ></nz-switch>
          <span>{{ entry.value }}</span>
        </div>
      </div>
    </ng-container>
  </nz-modal>
</div>
