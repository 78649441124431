import { Injectable } from "@angular/core";
import { ENV } from "environment";
import { UIService } from "./ui.service";

const UPDATE_AVAILABLE_CHECK_INTERVAL = 30000;

@Injectable()
class UpgradeAdminService {
  public upgradeAvailable = false;

  constructor(private uiService: UIService) {}

  async checkAvailableUpgrade() {
    const response = await fetch("/version.json");
    const version: { latest: string } | undefined = await response.json();

    this.upgradeAvailable =
      version &&
      version.latest &&
      ENV["VERSION"] &&
      version.latest !== ENV["VERSION"];

    this.updateNotification();
  }

  public updateNotification() {
    if (this.upgradeAvailable) {
      this.uiService.setNotificationBlockTemplate("upgrade");
    }
  }

  public initialize() {
    this.checkAvailableUpgrade();
    setInterval(
      this.checkAvailableUpgrade.bind(this),
      UPDATE_AVAILABLE_CHECK_INTERVAL,
    );
  }

  public upgradeApp() {
    // @ts-ignore-next-line
    window.location.reload(true);
  }
}

export { UpgradeAdminService };
