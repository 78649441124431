import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { Account, getProfilePicture } from "models/account.model";
import { UIService } from "services/ui.service";

@Component({
  selector: "org-account-avatar",
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="org-account-avatar-wrapper {{ size }}">
      <div
        [class]="'org-account-avatar ' + size"
        nz-tooltip
        [nzTooltipTitle]="
          (ctaWhenNoPicture && !account?.profile_picture) || noTooltip
            ? undefined
            : account.fullname
        "
      >
        <img
          [alt]="account?.fullname || 'User'"
          class="profile-picture"
          loading="lazy"
          (error)="onError()"
          [src]="avatarSrc"
        />
        <a
          routerLink="/settings/account"
          class="avatar-add"
          *ngIf="ctaWhenNoPicture && !account?.profile_picture && !noTooltip"
          nz-tooltip
          nzTooltipTitle="Upload my profile picture"
        >
          +
        </a>
        <div
          *ngIf="account?.isActive && !noActivityStatus"
          class="user-presence"
        ></div>
      </div>
      <div *ngIf="withFullname" class="fullname">
        {{ account?.fullname }}
      </div>
    </div>
  `,
  styles: [
    `
      .org-account-avatar-wrapper {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      .xss.org-account-avatar-wrapper {
        gap: 4px;
      }
      .org-account-avatar {
        position: relative;
      }

      .org-account-avatar .profile-picture {
        object-fit: cover;
        border-radius: 48px;
        background-color: var(--screeb-color-background);
      }

      .xss .profile-picture {
        width: 14px;
        height: 14px;
      }

      .small .profile-picture {
        width: 32px;
        height: 32px;
      }

      .medium .profile-picture {
        width: 40px;
        height: 40px;
      }

      .user-presence {
        position: absolute;
        border-radius: 8px;
        width: 8px;
        height: 8px;
        background: var(--screeb-color-green-500);
      }

      .xss .user-presence {
        top: 14px;
        left: 0;
      }

      .xss .user-presence {
        top: 14px;
        left: 0;
      }

      .small .user-presence {
        top: 24px;
        left: 2px;
      }

      .medium .user-presence {
        top: 32px;
        left: 4px;
      }

      .avatar-add {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border: none;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--screeb-color-green-700);
        content: " ";
        border-radius: 40px;
        font-family: "Rubik";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: var(--screeb-color-white);
        cursor: pointer;
      }

      .avatar-add:hover {
        background: hsla(169, 86%, 31%, 1);
      }
    `,
  ],
})
export class OrgAccountAvatarComponent implements OnInit {
  @Input() public account: Account = null;
  @Input() public size: "xss" | "small" | "medium" = "medium";
  @Input() public ctaWhenNoPicture = false;
  @Input() public noTooltip = false;
  @Input() public noActivityStatus = false;
  @Input() public withFullname = false;

  constructor(public uiService: UIService) {}

  public getProfilePicture = getProfilePicture;
  public avatarSrc: string = null;

  public ngOnInit() {
    this.avatarSrc = this.getProfilePicture(this.account);
  }

  public onError() {
    this.avatarSrc = "/assets/favicon-128.png";
  }
}
