<ul class="stats-list" *ngIf="userEvents.length">
  <ng-container *ngFor="let event of userEvents">
    @let shouldDisplay = shouldDisplayProperties(event.raw_properties);
    <li
      class="stat-item"
      [ngClass]="{
        opened: opened[event.id],
        clickable: shouldDisplay,
      }"
    >
      <div
        class="stat-item-header"
        (click)="opened[event.id] = !opened[event.id]; onEventClicked()"
      >
        <square-icon icon="cursor-click"></square-icon>
        <div class="stat-item-data">
          <div class="stat-item-title">
            <span>{{ event.name }}</span>
            <tag-registry-entry-source
              [sources]="event.sources"
              size="small"
            ></tag-registry-entry-source>
          </div>
          <div
            class="stat-item-value"
            *ngIf="event.triggered_at"
            [title]="event.triggered_at | toLocaleString"
          >
            {{
              event.triggered_at | dfnsFormatDistanceToNow: { addSuffix: true }
            }}
          </div>
        </div>
        <div class="stat-item-title-count" *ngIf="event.count">
          {{ event.count | format: "number" }}
        </div>
      </div>
      <div class="stat-item-content" *ngIf="shouldDisplay">
        <ngx-json-viewer
          [json]="event.raw_properties"
          [depth]="0"
        ></ngx-json-viewer>
      </div>
    </li>
  </ng-container>
</ul>
<nz-empty *ngIf="!userEvents.length" [nzNotFoundContent]="contentTpl">
  <ng-template #contentTpl>
    <div>No events</div>
    <br />
    <a
      target="_blank"
      href="https://help.screeb.app/en/articles/8611585-all-about-users-events"
    >
      <i nz-icon nzType="info-circle"></i>
      &nbsp;
      <span>How to add user events in Screeb</span>
    </a>
  </ng-template>
</nz-empty>
