import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AnalyticsFiltersOperator } from "models/analytics.filters.type";
import { NzSelectOptionInterface } from "ng-zorro-antd/select";
import { searchStringInString } from "utils/string";

export type CheckboxSelectItem = {
  key: string;
  label: string;
};

@Component({
  selector: "checkbox-select",
  template: `
    <div class="checkbox-select">
      <div class="select-item">
        <nz-input-group
          class="select-item-search"
          nzSize="large"
          [nzPrefix]="prefixIconSearch"
        >
          <input
            type="text"
            nz-input
            [placeholder]="placeholder"
            (keyup)="searchChanged($event.target.value)"
          />
          <ng-template #prefixIconSearch>
            <i nz-icon nzType="search"></i>
          </ng-template>
        </nz-input-group>

        <nz-checkbox-wrapper>
          <ul class="select-item-list">
            <ng-container *ngFor="let item of items">
              <li
                class="select-item-list-item"
                [hidden]="!filteredItems.includes(item)"
              >
                <label
                  nz-checkbox
                  [nzChecked]="selectedItems.includes(item.key)"
                  [nzValue]="item.key"
                  (nzCheckedChange)="onCheckboxCheckedChange(item.key, $event)"
                >
                  {{ item.label }}
                </label>
              </li>
            </ng-container>
          </ul>
        </nz-checkbox-wrapper>
      </div>
    </div>
  `,
  styles: [
    `
      :host .select-item-search {
        border-color: var(--screeb-color-border-secondary) !important;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }

      :host .select-item-list {
        border: 1px solid var(--screeb-color-border-secondary);
        border-top: none;
        box-sizing: border-box;
        border-radius: 0px 0px 8px 8px;
        overflow-y: auto;
        max-height: 200px;
      }

      :host .ant-checkbox-group {
        width: 100%;
      }

      :host .select-item-list-item {
        padding: 8px 16px;
      }

      :host .select-item-list-item label {
        font-family: Rubik;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: var(--screeb-color-body-text);
      }
    `,
  ],
})
export class CheckboxSelectComponent implements OnInit {
  @Input() public placeholder: string = "Search item(s)";
  @Input() public selectedItems: CheckboxSelectItem["key"][] = [];
  @Input() public items: readonly CheckboxSelectItem[] = [];
  @Input() public unique: boolean = false;

  public filtersOperator: AnalyticsFiltersOperator = "AND";
  public matchingOperatorOptions: NzSelectOptionInterface[] = [
    { label: "All", value: "AND" },
    { label: "One of", value: "OR" },
  ];

  @Output() public selectedItemsChange: EventEmitter<
    CheckboxSelectItem["key"][]
  > = new EventEmitter();

  public filteredItems: readonly CheckboxSelectItem[] = [];

  ngOnInit(): void {
    this.searchChanged("");
  }

  public searchChanged(searchString: string) {
    this.filteredItems = this.items.filter(
      ({ key, label }) =>
        searchStringInString(key, searchString) ||
        searchStringInString(label, searchString),
    );
  }

  public onCheckboxCheckedChange(key: string, $event: any) {
    if (this.unique) {
      this.selectedItems = [];
    }
    if ($event) {
      this.selectedItems = [...this.selectedItems, key];
    } else {
      this.selectedItems = this.selectedItems.filter((id) => id !== key);
    }
    this.selectedItemsChange.emit(this.selectedItems);
  }
}
