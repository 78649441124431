import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";

import { ApplicationLoadService } from "services/app.service";
import { ChannelDao } from "models/channel.dao";
import { Channel } from "models/channel.model";

@Injectable()
export class ChannelsResolver implements Resolve<any> {
  constructor(
    private applicationLoadService: ApplicationLoadService,
    private channelDao: ChannelDao,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Promise<Channel[]> {
    return this.applicationLoadService
      .loader()
      .then(() => {
        return this.channelDao.getAllByOrgId(route.params["org_id"]);
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        throw err;
      });
  }
}
