<ul class="actions" *ngIf="!!lastFilters">
  <li class="action" *ngIf="canExport">
    <filters-csv-export
      [org]="org"
      [survey]="survey"
      [type]="type"
    ></filters-csv-export>
  </li>
  <li class="action" *ngIf="canShare && ('integration.create' | hasPermission)">
    <share-reporting [reportType]="reportType" class="share"></share-reporting>
  </li>
  <li class="action" *ngIf="canFilterByDate">
    <survey-stats-filter-date
      [org]="org"
      [survey]="survey"
      [reporting]="reporting"
    ></survey-stats-filter-date>
  </li>
  <li
    class="action"
    *ngIf="
      ('isNotDeviceTracking' | hasFeatureFlag) &&
      !hideUserFilters &&
      (lastFilters.type === 'respondent' || lastFilters.type === 'session')
    "
  >
    <nz-select
      class="my-segments"
      nzPlaceHolder="Segments"
      [nzOptions]="userGroupsOptions"
      nzSize="large"
      nzMode="mutiple"
      [nzDropdownMatchSelectWidth]="false"
      [ngModel]="selectedUserGroups"
      (ngModelChange)="selectedUserGroupsChange($event)"
    ></nz-select>
  </li>
  <li class="action">
    <nz-badge [nzCount]="lastFilters?.filters.length">
      <survey-stats-filter-criteria-response
        *ngIf="type === 'response'"
        [survey]="survey"
        [org]="org"
        [languages]="languages"
        [registryEntriesIdentityProperty]="registryEntriesIdentityProperty"
        [registryEntriesGroup]="registryEntriesGroup"
        [registryEntriesEvent]="registryEntriesEvent"
        [isDisplay]="isDisplay"
      ></survey-stats-filter-criteria-response>
      <survey-stats-filter-criteria-user
        *ngIf="type === 'respondent'"
        [survey]="survey"
        [org]="org"
        [registryEntriesIdentityProperty]="registryEntriesIdentityProperty"
        [registryEntriesGroup]="registryEntriesGroup"
        [registryEntriesEvent]="registryEntriesEvent"
      >
        <div
          class="checkbox-action"
          *ngIf="lastFilters.type === 'respondent' && !hideUserFilters"
        >
          <label
            nz-checkbox
            [ngModel]="lastFilters.identified_only"
            (ngModelChange)="hideAnonymousOnChange($event)"
            [ngClass]="{ checked: lastFilters.identified_only }"
            nzSize="large"
          >
            Hide anonymous users
          </label>
        </div>
      </survey-stats-filter-criteria-user>
      <survey-stats-filter-criteria-session
        *ngIf="type === 'session'"
        [org]="org"
        [registryEntriesIdentityProperty]="registryEntriesIdentityProperty"
        [registryEntriesGroup]="registryEntriesGroup"
        [registryEntriesEvent]="registryEntriesEvent"
        [hideUserFilters]="hideUserFilters"
      >
        <div
          class="checkbox-action"
          *ngIf="lastFilters.type === 'session' && !hideUserFilters"
        >
          <label
            nz-checkbox
            [ngModel]="lastFilters.identified_only"
            (ngModelChange)="hideAnonymousOnChange($event)"
            [ngClass]="{ checked: lastFilters.identified_only }"
            nzSize="large"
          >
            Hide anonymous sessions
          </label>
        </div>
        <div class="checkbox-action" *ngIf="lastFilters.type === 'session'">
          <label
            nz-checkbox
            [ngModel]="lastFilters.unviewed_only"
            (ngModelChange)="hideViewedOnChange($event)"
            [ngClass]="{ checked: lastFilters.unviewed_only }"
            nzSize="large"
          >
            Hide viewed sessions
          </label>
        </div>
      </survey-stats-filter-criteria-session>
    </nz-badge>
  </li>
</ul>
