import { Component, Input } from "@angular/core";
import { TargetingRule } from "models/targeting-rule.model";

@Component({
  selector: "rule-date",
  templateUrl: "./rule-date.component.html",
  styleUrls: ["./rule-date.component.scss"],
})
export class TargetingRuleDateComponent {
  @Input()
  public rule: TargetingRule = null;

  constructor() {}
}
