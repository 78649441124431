import { Component, EventEmitter, Input, Output } from "@angular/core";
import {
  RegistryEntrySource,
  RegistryEntrySubject,
} from "models/registry.model";

export type UserNormalizedEvent = {
  id?: string;
  name_id?: string;
  org_id?: string;
  type?: RegistryEntrySubject;
  name?: string;
  created_at?: Date;
  updated_at?: Date;
  triggered_at?: Date;
  sources?: RegistryEntrySource[];
  count?: number;
  raw_properties?: unknown;
  properties?: unknown;
};

@Component({
  selector: "user-events",
  templateUrl: "./user-events.component.html",
  styleUrls: ["./user-events.component.scss"],
})
export class UserEventsComponent {
  @Input() public userEvents: UserNormalizedEvent[] = [];

  @Output() public eventClicked: EventEmitter<object> = new EventEmitter();

  public opened: object = {};

  constructor() {}

  onEventClicked() {
    this.eventClicked.emit();
  }

  shouldDisplayProperties(properties: unknown) {
    if (properties && typeof properties === "object") {
      return Boolean(Object.values(properties).length);
    }
    return Boolean(properties);
  }
}
