import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "preview-audio-modal",
  templateUrl: "./audio-preview-modal.component.html",
  styleUrls: ["./audio-preview-modal.component.scss"],
})
export class SurveyPreviewAudioModalComponent {
  @ViewChild("audioPlayer", { static: false })
  audioPlayer: ElementRef<HTMLVideoElement>;
  @Input() audioUrl: string = null;
  @Input() isOpen: boolean = false;
  @Output() modalOpenChange: EventEmitter<boolean> = new EventEmitter();

  public isLoaded: boolean = false;
  public isPlaying: boolean = false;
  public duration: number = 0;
  public currentTime: number = 0;
  private thumbSize: number = 32;
  private playbackInterval = null;

  constructor() {}

  public get playbackTime(): string {
    const currentPercentage = this.currentTime / this.duration;
    return `calc(${currentPercentage * 100}% - ${
      currentPercentage * this.thumbSize
    }px`;
  }

  handleClose(): void {
    this.isOpen = false;
    this.modalOpenChange.emit(this.isOpen);
    clearInterval(this.playbackInterval);
  }

  playerReady(event: Event) {
    const player = event.target as HTMLMediaElement;

    this.duration = player.duration === Infinity ? 0 : player.duration;

    if (player.duration === Infinity) {
      player.currentTime = Number.MAX_SAFE_INTEGER;
      player.ontimeupdate = () => {
        player.ontimeupdate = null;
        player.currentTime = 0;
        this.duration = player.duration;

        this.isLoaded = true;
        this.setPlaying(true);
      };
    } else {
      this.isLoaded = true;
      this.setPlaying(true);
    }

    this.playbackInterval = setInterval(() => {
      this.currentTime = player.currentTime;
    }, 100);
  }

  setPlaying(playing: boolean) {
    if (!this.audioPlayer) return;
    const player = this.audioPlayer.nativeElement;

    if (playing && player.paused) {
      player.play();
    } else if (!playing && !player.paused) {
      player.pause();
    }

    this.isPlaying = playing;
  }

  formatDuration(duration: number): string {
    const minutes = Math.floor(duration / 60);
    const seconds = Math.floor(duration - minutes * 60);
    return `${minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
  }
}
