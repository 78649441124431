import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { NotificationHelper } from "helpers/notification.helper";
import { Org } from "models/org.model";
import { TagSettings } from "models/tag-settings.types";
import { SeoService } from "services/seo.service";
import {
  surveyCappingTimePeriodTypesAndLabels,
  surveyCappingTypeToTimePeriod,
} from "models/survey-targeting-rule-capping.model";
import {
  orgLevelTargetingRuleTypesExceptCappingRules,
  TargetingRule,
  TargetingRuleOperator,
  TargetingRuleType,
} from "models/targeting-rule.model";
import { PageComponentInterface } from "components/PageComponentInterface";
import { HttpErrorResponse } from "@angular/common/http";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { OrgDao } from "models/org.dao";
import { RegistryEntry } from "models/registry.model";
import { EntitlementService } from "services/entitlement.service";
import { Survey } from "models/survey.model";

@Component({
  selector: "survey-capping-settings",
  templateUrl: "./survey-capping.component.html",
  styleUrls: ["./survey-capping.component.scss"],
})
export class SettingsSurveyCappingPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Survey Capping";
  public name = "Settings survey capping";

  private obs: any = null;
  public org: Org = null;
  public surveys: Survey[] = [];
  public settings: TagSettings = null;

  public surveyEventsAndPropertiesSwitch = false;
  public perOrgCappingSwitch = false;
  public perSurveyCappingSwitch = false;
  public perRespondentCappingSwitch = false;

  public surveyCappingTypeToTimePeriod = surveyCappingTypeToTimePeriod;
  public surveyCappingTimePeriodTypesAndLabels =
    surveyCappingTimePeriodTypesAndLabels;

  public orgTargetingRules: TargetingRule[] = [];
  public orgTargetingRulesBackup: TargetingRule[] = [];
  public cappingTargetingRules: TargetingRule[] = [];
  public otherTargetingRules: TargetingRule[] = [];
  public userEventCountRule: TargetingRule = null;

  public registryEntriesIdentityProperty?: RegistryEntry[];
  public registryEntriesGroup?: RegistryEntry[];
  public registryEntriesEvent?: RegistryEntry[];

  public saving = false;

  constructor(
    private route: ActivatedRoute,
    private notificationHelper: NotificationHelper,
    private seoService: SeoService,
    private orgDao: OrgDao,
    public featureFlaggingService: FeatureFlaggingService,
    public entitlementService: EntitlementService,
  ) {}

  ngOnInit() {
    this.seoService.set({
      title: "Survey Capping",
    });

    this.obs = this.route.data.subscribe((data) => {
      this.org = data.org;
      this.settings = data["settings"];
      this.surveys = data["surveys"];
      this.orgTargetingRules = data["orgTargetingRules"];

      this.cappingTargetingRules = this.orgTargetingRules.filter((rule) => {
        return !orgLevelTargetingRuleTypesExceptCappingRules.includes(
          rule.type,
        );
      });

      this.otherTargetingRules = this.orgTargetingRules.filter((rule) => {
        return orgLevelTargetingRuleTypesExceptCappingRules.includes(rule.type);
      });

      this.registryEntriesIdentityProperty =
        data["registryEntriesIdentityProperty"];
      this.registryEntriesGroup = data["registryEntriesGroup"].groups;
      this.registryEntriesEvent = data["registryEntriesEvent"];
    });

    this.surveyEventsAndPropertiesSwitch = this.otherTargetingRules.length > 0;
    this.perOrgCappingSwitch =
      this.cappingTargetingRules.filter((rule) =>
        ["capping_org_display", "capping_org_response"].includes(rule.type),
      ).length > 0;
    this.perSurveyCappingSwitch =
      this.cappingTargetingRules.filter((rule) =>
        ["capping_survey_display", "capping_survey_response"].includes(
          rule.type,
        ),
      ).length > 0;
    this.perRespondentCappingSwitch =
      this.cappingTargetingRules.filter((rule) =>
        [
          "capping_respondent_display",
          "capping_respondent_response",
          "capping_respondent_time_between_survey_display",
        ].includes(rule.type),
      ).length > 0;

    if (this.otherTargetingRules.length === 0) {
      this.createUserEventCountRuleIfNotExist();
    }

    this.orgTargetingRulesBackup = JSON.parse(
      JSON.stringify(this.getValidTargetingRules()),
    );
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  private getCleanedOtherTargetingRules(): TargetingRule[] {
    if (this.surveyEventsAndPropertiesSwitch === false) {
      return [];
    }

    // remove empty line in Array rules that are not saved to avoid unwanted diff between last change and actual change
    return this.otherTargetingRules
      .map((rule) => {
        if (rule.value.v_s_arr !== undefined && rule.value.v_s_arr !== null) {
          const tmpRule = JSON.parse(JSON.stringify(rule));
          const last = tmpRule.value.v_s_arr.length - 1;
          if (
            tmpRule.value.v_s_arr[last] !== undefined &&
            tmpRule.value.v_s_arr[last].length === 0
          ) {
            tmpRule.value.v_s_arr.pop();
          }
          return tmpRule;
        } else if (
          rule.value.v_n_arr !== undefined &&
          rule.value.v_n_arr !== null
        ) {
          const tmpRule = JSON.parse(JSON.stringify(rule));
          const last = tmpRule.value.v_n_arr.length - 1;
          if (
            tmpRule.value.v_n_arr[last] !== undefined &&
            tmpRule.value.v_n_arr[last] === null
          ) {
            // wtf ?
            tmpRule.value.v_n_arr.pop();
          }
          return tmpRule;
        }
        return rule;
      })
      .filter((rule: TargetingRule) => {
        return (
          ![
            "visitor_event_time",
            "visitor_event_count",
            "device_visitor_event_time",
            "device_visitor_event_count",
          ].includes(rule.type) ||
          (this.registryEntriesEvent.length > 0 &&
            rule.value.name_ids !== null &&
            rule.value.name_ids !== undefined &&
            rule.value.name_ids.length !== 0)
        );
      });
  }

  private getValidTargetingRules(): TargetingRule[] {
    const targetingRules = this.cappingTargetingRules.filter((rule) => {
      if (
        [
          "capping_org_display",
          "capping_org_response",
          "capping_survey_display",
          "capping_survey_response",
          "capping_respondent_display",
          "capping_respondent_response",
        ].includes(rule.type) &&
        rule.value.v_n !== null &&
        rule.value.v_n_p !== null &&
        rule.value.v_n !== undefined &&
        rule.value.v_n_p !== undefined
      ) {
        if (
          ["capping_org_display", "capping_org_response"].includes(rule.type)
        ) {
          return this.perOrgCappingSwitch;
        } else if (
          ["capping_survey_display", "capping_survey_response"].includes(
            rule.type,
          )
        ) {
          return this.perSurveyCappingSwitch;
        } else if (
          [
            "capping_respondent_display",
            "capping_respondent_response",
          ].includes(rule.type)
        ) {
          return this.perRespondentCappingSwitch;
        }
        return true;
      } else if (
        rule.type === "capping_respondent_time_between_survey_display"
      ) {
        return this.perRespondentCappingSwitch && !!rule.value.v_n;
      }
      return false;
    });

    return targetingRules
      .concat(this.getCleanedOtherTargetingRules())
      .filter((rule: TargetingRule) => {
        return !!rule.type;
      });
  }

  public hasUnsavedChanges() {
    // @TODO: better with deepEqual
    const targetingRules = this.getValidTargetingRules();

    return (
      JSON.stringify(targetingRules) !==
      JSON.stringify(this.orgTargetingRulesBackup)
    );
  }

  public onSave() {
    this.saving = true;

    const targetingRules = this.getValidTargetingRules();

    // Check if rules are valid
    this.orgDao
      .updateTargetingRules(this.org.id, targetingRules)
      .then(() => {
        this.saving = false;
        this.orgTargetingRulesBackup = JSON.parse(
          JSON.stringify(targetingRules),
        );
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err?.error ?? err);
        this.notificationHelper.trigger(
          err?.error?.message ?? "Error",
          null,
          "error",
        );
        this.saving = false;
      });
  }

  private addRule(
    type: TargetingRuleType | undefined,
    operator?: TargetingRuleOperator,
    pushRule: boolean = true,
  ): TargetingRule {
    const r = new TargetingRule();
    r.survey_distribution_id = null;
    r.org_id = this.org.id;
    r.type = type || "visitor_event_count";
    r.operator = operator || r.getAvailableOperators()?.[0]?.type;
    r.value = r.getDefaultValue();
    if (pushRule) {
      this.cappingTargetingRules.push(r);
    }

    return r;
  }

  private createUserEventCountRuleIfNotExist(): boolean {
    this.userEventCountRule = this.otherTargetingRules.find(
      (r: TargetingRule) => r.type === "visitor_event_count",
    );
    if (!this.userEventCountRule) {
      this.userEventCountRule = this.addRule(
        "visitor_event_count",
        undefined,
        false,
      );
      this.otherTargetingRules.push(this.userEventCountRule);
      return true;
    }
    return false;
  }
}
