import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  htmlSanitizer,
  stripHtml,
} from "components/builder/components/Cards/sanitized-message/sanitizer";
import { GraphNode } from "components/builder/flow";
import { decode } from "html-entities";
import {
  CTACalendar,
  SurveyLanguages,
  getI18nTextLabelTranslation,
  setI18nTextLabelTranslation,
} from "models/survey.dao.types";
import { EntitlementService } from "services/entitlement.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { BuilderStore } from "stores/builder.store";
import {
  CalendarType,
  isValidURL,
} from "../../BuilderLayout/Validators/CalendarValidator";
import { QuestionValidatorFactory } from "../../BuilderLayout/Validators/CardValidator";

@Component({
  selector: "lateral-panel-edit-calendar",
  templateUrl: "./edit-calendar.component.html",
  styleUrls: ["./edit-calendar.component.scss"],
})
export class LateralPanelEditCalendarComponent implements OnInit {
  @Input() node: GraphNode = null;
  @Input() public language: SurveyLanguages = "en";
  @Output() errorChange = new EventEmitter<boolean>();
  public linkErrors: string[] = [];

  constructor(
    public builderStore: BuilderStore,
    public entitlementService: EntitlementService,
    public featureFlaggingService: FeatureFlaggingService,
  ) {}

  public ngOnInit() {
    this.validateData();
  }

  public getError(): string[] {
    return [...this.linkErrors];
  }

  public get cta(): CTACalendar {
    const cta = this.node.node.question.call_to_action;
    if (cta.type !== "calendar")
      throw Error("unexpected action type for calendar");
    return cta;
  }

  /**
   * Data validation
   */

  public validateData() {
    const { errors, warnings } = QuestionValidatorFactory.getValidatorFromNode(
      this.node,
    )(
      this.node,
      this.language,
      this.builderStore.survey.scenario.default_language,
    );

    if (errors.length === 1) this.linkErrors = errors[0] || [];
    else this.linkErrors = warnings[0] || [];

    this.errorChange.emit(this.linkErrors.length > 0);
  }

  public getLabelLink(): string {
    const cta = this.node.node.question.call_to_action as CTACalendar;
    return decode(
      getI18nTextLabelTranslation(
        cta.calendar.payload.url,
        this.language,
        this.language,
      ) || "",
    );
  }

  public setLabelLink(url: string) {
    const cta = this.node.node.question.call_to_action as CTACalendar;

    // Let alter url to add some params
    let newURL = url;
    const provider = isValidURL(url);
    try {
      const parsedURL = new URL(url);
      if (provider === CalendarType.Calendly) {
        if (!parsedURL.searchParams.has("embed_type")) {
          parsedURL.searchParams.append("embed_type", "Inline");
        }

        if (!parsedURL.searchParams.has("hide_gdpr_banner")) {
          parsedURL.searchParams.append("hide_gdpr_banner", "1");
        }

        if (!parsedURL.searchParams.has("hide_event_type_details")) {
          parsedURL.searchParams.append("hide_event_type_details", "1");
        }
      } else if (provider === CalendarType.Google) {
        if (!parsedURL.searchParams.has("hl")) {
          parsedURL.searchParams.append("hl", this.language);
        }

        if (!parsedURL.searchParams.has("gv")) {
          parsedURL.searchParams.append("gv", "true");
        }
      }

      newURL = parsedURL.toString();
    } catch (e) {
      console.error(e);
    }
    setI18nTextLabelTranslation(
      cta.calendar.payload.url,
      newURL,
      this.language,
    );
  }

  getLength(text: string) {
    return stripHtml(
      htmlSanitizer(text, {
        styling: true,
        CR: false,
        nativeCR: true,
        links: true,
      }),
    ).length;
  }
}
