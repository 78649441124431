import { Component, Input } from "@angular/core";

@Component({
  selector: "settings-data-governance-registry-limits",
  template: `
    <div class="label">{{ what }}</div>
    {{ current }} / {{ max }}
  `,
  styles: [
    `
      .label {
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: var(--screeb-color-body-text);
        margin: 4px 0;
      }
    `,
  ],
})
export class SettingsDataGovernanceRegistryLimitsComponent {
  @Input() what: string;
  @Input() current: number;
  @Input() max: number;

  constructor() {}
}
