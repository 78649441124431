import { Component } from "@angular/core";
import { PageComponentInterface } from "components/PageComponentInterface";
import parseContentSecurityPolicy from "content-security-policy-parser";
import buildContentSecurityPolicy from "content-security-policy-builder";
import { arrayToSet } from "utils/array";
import { NotificationHelper } from "helpers/notification.helper";
import { ClipboardService } from "ngx-clipboard";

@Component({
  selector: "csp-editor",
  template: `
    <div class="container">
      <div class="content">
        <img src="/assets/logo-purple-500.svg" alt="Screeb logo" />

        <h1>Content Security Policy</h1>
        <h4>Get your CSP ready for Screeb</h4>

        <steps-component
          [steps]="['Paste your CSP', 'Review your CSP', 'Save your CSP']"
          [currentStep]="currentStep"
          (stepClicked)="currentStep = $event"
        />

        <div class="step-container" *ngIf="currentStep === 0">
          <h4>
            Paste your current CSP here, if you don't have one yet, leave this
            blank:
          </h4>

          <div class="textarea-container">
            <textarea
              #textarea
              name="csp"
              id="csp"
              placeholder="script-src 'strict-dynamic' http: https: ..."
              [(ngModel)]="initialCSP"
              (ngModelChange)="compute()"
              (paste)="
                onPaste($event);
                textareaCode.scrollTop = 0;
                textarea.scrollTop = 0
              "
              (blur)="initialCSP = prettify(initialCSP); compute()"
              (scroll)="textareaCode.scrollTop = textarea.scrollTop"
              [rows]="10"
            ></textarea>

            <pre>
              <code #textareaCode [highlight]="initialCSP" language="CSP"></code>
            </pre>
          </div>
        </div>

        <div class="step-container" *ngIf="currentStep === 1">
          <h4>Review your CSP and enable the parts you want to keep:</h4>

          <div class="switches-container">
            <div *ngFor="let cspPart of cspParts" class="switch-container">
              <nz-switch
                ngDefaultControl
                [(ngModel)]="cspPart.enabled"
                (ngModelChange)="compute()"
              ></nz-switch>
              <label>
                {{ cspPart.label }}
                <span class="description">
                  {{ cspPart.description }}
                </span>
              </label>
            </div>
          </div>
        </div>

        <div class="step-container" *ngIf="currentStep === 2">
          <h4>
            Here is your new CSP. Check everything is ok, then you can add to
            your website!
          </h4>

          <div class="code-container">
            <pre>
              <code [highlight]="newCSP" language="CSP"></code>
            </pre>
          </div>
        </div>

        <div class="step-actions">
          <button
            *ngIf="currentStep < 2"
            nz-button
            title="Next"
            nzType="primary"
            nzSize="large"
            nzShape="round"
            (click)="currentStep = currentStep + 1"
          >
            <span>Next</span>
            &nbsp;
            <screeb-icon size="sm" icon="arrow-right"></screeb-icon>
          </button>

          <button
            title="Copy"
            *ngIf="currentStep === 2"
            class="copy-button"
            nz-button
            title="Next"
            nzType="primary"
            nzSize="large"
            nzShape="round"
            (click)="clipboardCopy(this.newCSP)"
          >
            Copy
          </button>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      h1 {
        color: var(--screeb-color-body-text);
        margin-top: 28px;
        margin-bottom: 0;
      }

      h4 {
        margin-bottom: 28px;
      }

      .container {
        display: flex;
        align-items: stretch;
        justify-content: center;
        padding: 48px 16px 0 48px;
        max-height: 100%;
        overflow: auto;
      }

      .content {
        flex-basis: 800px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 48px;
        height: max-content;
      }

      .step-container {
        margin-top: 16px;
        width: 100%;

        h4 {
          margin-top: 16px;
          margin-bottom: 8px;
        }
      }

      pre {
        width: 100%;
        max-width: 100%;
        white-space: pre-wrap;
      }

      code {
        background: var(--screeb-color-body-background);
        pointer-events: none;
        width: 100%;
        max-width: 100%;
        padding: 16px !important;
        background: var(--screeb-color-input-background);
        box-shadow: 0px 2px 2px 0px rgba(48, 49, 64, 0.05);
        min-height: 40px;
        border-radius: 8px;
        border: 1px solid var(--screeb-color-border-secondary);
        font-size: 1em;
        font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo,
          Courier, monospace;
      }

      .textarea-container {
        display: flex;
        position: relative;
        width: 100%;

        pre {
          overflow: hidden;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          max-width: 100%;
        }

        code {
          overflow: auto;
          background: var(--screeb-color-body-background);
          pointer-events: none;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          max-width: 100%;
          padding: 16px !important;
          background: transparent;
          border: 1px solid transparent;
          font-size: 1em;
          font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo,
            Courier, monospace;

          &::-webkit-scrollbar-track,
          &::-webkit-scrollbar,
          &::-webkit-scrollbar-thumb {
            background-color: white !important;
          }
        }
      }

      textarea {
        position: relative;
        z-index: 1;
        background: transparent;
        box-shadow: 0px 2px 2px 0px rgba(48, 49, 64, 0.05);
        min-height: 40px;
        border-radius: 8px;
        border: 1px solid var(--screeb-color-border-secondary);
        width: 100%;
        padding: 16px;
        font-size: 1em;
        font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo,
          Courier, monospace;
        color: transparent;
        caret-color: var(--screeb-color-body-text);

        &.errored {
          border-color: var(--screeb-color-red-500);
        }

        &::selection {
          background-color: var(--screeb-color-purple-500);
          color: white;
        }
      }

      .step-actions {
        margin-top: 28px;
      }

      .switches-container {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
      }

      .switch-container {
        display: flex;
        align-items: flex-start;
        gap: 16px;
        max-width: 396px;

        label {
          display: flex;
          flex-direction: column;

          font-family: "Rubik";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;

          .description {
            font-family: Rubik;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 150%;

            color: var(--screeb-color-body-text-secondary);
          }
        }
      }

      .code-container {
        position: relative;

        pre {
          display: flex;
        }
      }
    `,
  ],
})
export class CSPEditorPageComponent implements PageComponentInterface {
  public title = "Screeb - Content Security Policy";
  public name = "Content Security Policy";

  public currentStep = 0;
  public initialCSP = "";
  public defaultCSP =
    "script-src 'unsafe-inline' http: https:; object-src 'none'; base-uri 'none'; require-trusted-types-for 'script';";
  public screebMinimalCSP: Readonly<Record<string, string[]>> = {
    "default-src": ["'unsafe-inline'", "*.screeb.app", "wss://*.screeb.app"],
    "prefetch-src": ["*.screeb.app"],
    "font-src": ["blob:"],
    "media-src": ["'self'", "blob:", "data:", "*.screeb.app"],
  };

  public newCSP = "";

  public cspParts: {
    label: string;
    description: string;
    enabled: boolean;
    directives: Record<string, string[]>;
  }[] = [
    {
      label: "Allow tag",
      description:
        "Allow your website to load our scripts and connect to our servers.",
      enabled: true,
      directives: {
        "script-src": ["'unsafe-inline'", "*.screeb.app"],
        "style-src": ["'unsafe-inline'", "*.screeb.app"],
        "connect-src": ["wss://*.screeb.app", "*.screeb.app;"],
        "prefetch-src": ["*.screeb.app"],
        "frame-src": ["*.screeb.app"],
      },
    },
    {
      label: "Allow fonts",
      description: "Allow your website to load custom fonts from our CDN.",
      enabled: true,
      directives: {
        "font-src": [" blob:", "data:", "*.screeb.app"],
      },
    },
    {
      label: "Allow emojis & images",
      description: "Allow your website to load emojis & images from our CDN.",
      enabled: true,
      directives: {
        "img-src": ["data:", "*.screeb.app"],
      },
    },
    {
      label: "Allow audio/video questions",
      description: "Allow your website to load audio/video from our CDN.",
      enabled: true,
      directives: {
        "media-src": ["*.screeb.app"],
        "prefetch-src": ["*.screeb.app"],
      },
    },
    {
      label: "Allow audio/video answers",
      description:
        "Allow your website to record & upload audio/video to our servers.",
      enabled: true,
      directives: {
        "media-src": ["blob:", "data:", "*.screeb.app"],
        "prefetch-src": ["*.screeb.app"],
      },
    },
    {
      label: "Allow in-app message builder",
      description:
        "Allow the tag to load all necessary resource from our CDN to display the in-app messages builder properly.",
      enabled: true,
      directives: {
        "font-src": [" blob:", "data:", "*.screeb.app"],
        "media-src": ["blob:", "data:", "*.screeb.app"],
        "prefetch-src": ["*.screeb.app"],
      },
    },
  ] as const;

  constructor(
    private clipboardService: ClipboardService,
    private notificationHelper: NotificationHelper,
  ) {
    this.compute();
  }

  prettify(csp: string) {
    return csp.replace(/\s*\;\s*/gi, ";\n").trim();
  }

  compute() {
    const directives = Object.fromEntries(
      parseContentSecurityPolicy(this.initialCSP),
    );

    for (const cspPart of this.cspParts) {
      if (cspPart.enabled) {
        for (const [directive, values] of Object.entries(cspPart.directives)) {
          directives[directive] = directives[directive]
            ? arrayToSet(
                [...directives[directive], ...values].map((v) =>
                  v.trim().replace(/;$/, ""),
                ),
              )
            : ["'self'", ...values.map((v) => v.trim().replace(/;$/, ""))];
        }
      }
    }

    this.newCSP = buildContentSecurityPolicy({ directives }).replace(
      /\s*\;\s*/gi,
      ";\n",
    );
  }

  onPaste(event: ClipboardEvent) {
    setTimeout(() => {
      if (event.target instanceof HTMLTextAreaElement) {
        this.initialCSP = this.prettify(event.target.value);
        this.compute();
      }
    }, 0);
  }

  clipboardCopy(code: string) {
    this.clipboardService.copy(code);
    this.notificationHelper.trigger(
      "Copied to your clipboard!",
      null,
      "success",
    );
  }
}
