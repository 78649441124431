import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { InteractiveGuides } from "components/onboarding/onboarding-discovery/onboarding-discovery.data";
import { PageComponentInterface } from "components/PageComponentInterface";
import { AccountDao } from "models/account.dao";
import {
  AccountGoal,
  AccountGoalsToLabel,
  AccountJobTitlesToLabel,
} from "models/account.model";
import { Org } from "models/org.model";
import { SessionService } from "services/auth.service";
import { RoutingService } from "services/routing.service";
import { TrackersService } from "services/trackers.service";
import { pluralize } from "utils/string";

@Component({
  selector: "page-onboarding-discovery",
  templateUrl: "./onboarding-discovery.component.html",
  styleUrls: ["./onboarding-discovery.component.scss"],
})
export class OnBoardingDiscoveryComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Welcome to Screeb!";
  public name = "Onboarding installation";

  private obs: any = null;
  public org: Org = null;
  public guide: string = null;

  public accountRoleToLabel = AccountJobTitlesToLabel;
  public accountGoalToLabel = AccountGoalsToLabel;

  public interactiveGuide = null;

  public guideStarted = false;

  public guideUrl: SafeResourceUrl = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private routingService: RoutingService,
    private accountDao: AccountDao,
    private trackersService: TrackersService,
    public sessionService: SessionService,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.route.queryParams.subscribe(({ guide }) => {
      this.guide = guide;
      const guideData = InteractiveGuides[guide as AccountGoal];

      this.interactiveGuide = guideData
        ? guideData
        : InteractiveGuides["collect-product-feedback"];
    });

    this.obs = this.route.data.subscribe((data) => {
      this.org = data["org"];
    });

    this.getInteractiveGuideUrl();
    this.guideStarted = true;

    // Mandatory to hide iframe blinking due to page and components load
    // setTimeout(() => {
    //   this.guideStarted = true;
    // }, 1000);
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  public toPlural(value: string) {
    if (value === AccountJobTitlesToLabel.other) {
      return "People";
    }
    return pluralize(value, "s");
  }

  public getInteractiveGuideUrl() {
    this.guideUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.interactiveGuide?.src,
    );
  }

  onDone() {
    this.trackersService
      .newEventTrackingBuilder("Screeb Onboarding Discovery Done")
      .withOrg(this.org)
      .withAccount(this.sessionService.session)
      .withProps({
        guide: this.guide,
      })
      .build();

    return this.goToNextStep();
  }

  goToNextStep() {
    const isInvited = this.sessionService.isOnboardingInvited();

    if (isInvited) {
      this.accountDao.updateOnboardingStatus("product-tour").then(() => {
        this.router.navigate(["org", this.org.id]);
      });
    } else {
      this.router.navigate(["org", this.org.id, "onboarding", "book-demo"]);
    }
  }
}
