/* eslint-disable @angular-eslint/no-output-on-prefix */
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { GraphNode } from "components/builder/flow";
import { decode } from "html-entities";
import {
  ActionScoring,
  ActionSkip,
  CTAScoringAny,
  SurveyLanguages,
  UUID,
  getI18nTextLabelTranslation,
  newCTASkip,
  setI18nTextLabelTranslation,
} from "models/survey.dao.types";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { BuilderStore } from "stores/builder.store";
import { uuidv4 } from "utils/uuid";
import { LateralPanelEmojiPickerConfig } from "../LateralPanel.component";

const RANDOM_EMOJI = ["💪", "🥰", "😎", "🥳", "🤯", "😝", "👏", "🤘", "👌"];

@Component({
  selector: "lateral-panel-edit-score",
  templateUrl: "./edit-score.component.html",
  styleUrls: ["./edit-score.component.scss"],
})
export class LateralPanelEditScoreComponent implements OnInit {
  @Input() node: GraphNode = null;
  @Input() public language: SurveyLanguages = "en";

  @Output() onEmojiPickerOpen =
    new EventEmitter<LateralPanelEmojiPickerConfig>();
  @Output() onOptionAdded = new EventEmitter();
  @Output() errorChange = new EventEmitter<boolean>();

  constructor(
    public builderStore: BuilderStore,
    public featureFlaggingService: FeatureFlaggingService,
  ) {}

  public ngOnInit() {
    if (
      !["scoring", "nps", "ces", "csat"].includes(
        this.node.node.question.call_to_action.type,
      )
    )
      throw Error("unexpected action type for scoring");

    this.validateData();
  }

  public get cta(): CTAScoringAny {
    const cta = this.node.node.question.call_to_action;
    if (!["scoring", "nps", "ces", "csat"].includes(cta.type))
      throw Error("unexpected action type for scoring");
    return cta as CTAScoringAny;
  }

  public get skipAction(): ActionSkip {
    return (this.node.node.question.skip_action ??= newCTASkip());
  }

  public setIsResponsive(responsive: boolean) {
    this.cta.responsive = responsive;
    this.validateData();
  }

  /**
   * Options
   */
  public addOption() {
    if (this.node.isPreset) throw Error("cannot add score in preset");

    // default values
    const value = this.cta.scores.length > 0 ? this.cta.scores.length : 0;
    const emoji = this.getRandomEmoji();

    this.cta.scores.push({
      id: uuidv4() as UUID,
      correlation_id: uuidv4() as UUID,
      type: "scoring",
      payload: {
        emoji,
        value,
      },
    });

    this.onOptionAdded.emit();
    this.validateData();
  }
  public removeOption(index: number) {
    if (this.node.isPreset) throw Error("cannot remove score in preset");

    if (this.cta.scores.length <= 1) return;
    this.cta.scores.splice(index, 1);

    this.reOrderScores();

    this.validateData();
  }
  private getRandomEmoji(): string {
    return RANDOM_EMOJI[Math.floor(Math.random() * RANDOM_EMOJI.length)];
  }

  /**
   * Emoji picker
   */
  public openEmojiPicker(
    buttonWithEmoji: ActionScoring,
    btnElement: HTMLElement,
  ) {
    const buttonRect = btnElement.getBoundingClientRect();
    this.onEmojiPickerOpen.emit({
      action: buttonWithEmoji,
      positionTop: buttonRect.top + window.pageYOffset - 20,
      positionRight:
        window.innerWidth - buttonRect.left + window.pageXOffset + 10,
      canBeNull: false,
      whitelist: this.getEmojiWhitelist(buttonWithEmoji),
    });
  }

  private getEmojiWhitelist(buttonWithEmoji: ActionScoring): string[] {
    if (this.node.isCSAT) {
      switch (buttonWithEmoji.payload.value) {
        case 1:
          return ["😡", "🤬", "1️⃣"];
        case 2:
          return ["😒", "😔", "2️⃣"];
        case 3:
          return ["😐", "3️⃣"];
        case 4:
          return ["😄", "😃", "😀", "4️⃣"];
        case 5:
          return ["😍", "🥰", "🤩", "5️⃣"];
      }
    }
    return [];
  }

  public getLabelLegendMin(): string {
    const cta = this.node.node.question.call_to_action as CTAScoringAny;
    return decode(
      getI18nTextLabelTranslation(
        cta.legend_min || {},
        this.language,
        this.language,
      ) || "",
    );
  }

  public setLabelLegendMin(text: string) {
    const cta = this.node.node.question.call_to_action as CTAScoringAny;
    cta.legend_min ??= {};
    setI18nTextLabelTranslation(cta.legend_min, text, this.language);
  }

  public getLabelLegendMax(): string {
    const cta = this.node.node.question.call_to_action as CTAScoringAny;
    return decode(
      getI18nTextLabelTranslation(
        cta.legend_max || {},
        this.language,
        this.language,
      ) || "",
    );
  }

  public setLabelLegendMax(text: string) {
    const cta = this.node.node.question.call_to_action as CTAScoringAny;
    cta.legend_max ??= {};
    setI18nTextLabelTranslation(cta.legend_max, text, this.language);
  }

  /**
   * Data validation
   */
  public validateData() {
    this.errorChange.emit(false);
  }

  reOrderScores() {
    this.cta.scores.forEach((score, i) => {
      score.payload.value = i;
    });
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.cta.scores, event.previousIndex, event.currentIndex);
    this.reOrderScores();
  }
}
