<div
  class="preview-panel preview-panel-{{
    distributionsByID?.[this.distributionId]?.interaction
  }} {{ isFullScreen ? 'is-fullscreen' : '' }}"
>
  <div class="preview-panel-inner">
    <header class="preview-header" *ngIf="!isFullScreen">
      <div class="left">
        <h2 class="title">
          <i nz-icon nzType="caret-right"></i>
          Preview
        </h2>
      </div>

      <div class="right">
        <div class="interaction">
          <ng-template #defaultTemplate let-selected>
            <label class="interaction-button">
              <screeb-icon
                *ngIf="
                  distributionsByID[selected.nzValue]?.interaction === 'in_app'
                "
                size="md"
                icon="desktop"
              ></screeb-icon>
              <screeb-icon
                *ngIf="
                  distributionsByID[selected.nzValue]?.interaction === 'in_page'
                "
                size="md"
                icon="overview"
              ></screeb-icon>
              <screeb-icon
                *ngIf="
                  distributionsByID[selected.nzValue]?.interaction === 'email'
                "
                size="md"
                icon="email"
              ></screeb-icon>
              <screeb-icon
                *ngIf="
                  distributionsByID[selected.nzValue]?.interaction ===
                  'feedback_button'
                "
                size="md"
                icon="bye"
              ></screeb-icon>
              <screeb-icon
                *ngIf="
                  distributionsByID[selected.nzValue]?.interaction === 'link'
                "
                size="md"
                icon="link"
              ></screeb-icon>
              <screeb-icon
                *ngIf="
                  !distributionsByID[selected.nzValue]?.interaction &&
                  distributionsByID[selected.nzValue]?.type === 'widget'
                "
                size="md"
                icon="desktop"
              ></screeb-icon>
              <screeb-icon
                *ngIf="
                  !distributionsByID[selected.nzValue]?.interaction &&
                  distributionsByID[selected.nzValue]?.type === 'android'
                "
                size="sm"
                icon="android"
              ></screeb-icon>
              <screeb-icon
                *ngIf="
                  !distributionsByID[selected.nzValue]?.interaction &&
                  distributionsByID[selected.nzValue]?.type === 'ios'
                "
                size="sm"
                icon="apple"
              ></screeb-icon>
              <screeb-icon
                *ngIf="
                  !distributionsByID[selected.nzValue]?.interaction &&
                  distributionsByID[selected.nzValue]?.type === 'hosted-page'
                "
                size="sm"
                icon="link"
              ></screeb-icon>
              {{ selected.nzLabel }}
            </label>
          </ng-template>
          <nz-select
            [ngModel]="distributionId"
            nzSize="large"
            nzPlacement="bottomRight"
            [nzCustomTemplate]="defaultTemplate"
            [nzDropdownMatchSelectWidth]="false"
            (ngModelChange)="onDistributionChange($event)"
          >
            <ng-container
              *ngFor="let distribution of distributions; let i = index"
            >
              <ng-container
                *ngIf="
                  !distribution.interaction ||
                  distribution.integration !== 'feedback_button'
                "
              >
                <nz-option
                  *ngIf="
                    i > 0 &&
                    !distributions[i].interaction &&
                    distributions[i - 1].interaction
                  "
                  [nzDisabled]="true"
                  nzLabel="Legacy channels"
                ></nz-option>
                <nz-option
                  [nzValue]="distribution.id"
                  nzCustomContent
                  [nzLabel]="distribution.title"
                >
                  <label class="interaction-button">
                    <screeb-icon
                      *ngIf="distribution.interaction === 'in_app'"
                      size="md"
                      icon="desktop"
                    ></screeb-icon>
                    <screeb-icon
                      *ngIf="distribution.interaction === 'in_page'"
                      size="md"
                      icon="overview"
                    ></screeb-icon>
                    <screeb-icon
                      *ngIf="distribution.interaction === 'email'"
                      size="md"
                      icon="email"
                    ></screeb-icon>
                    <screeb-icon
                      *ngIf="distribution.interaction === 'feedback_button'"
                      size="md"
                      icon="bye"
                    ></screeb-icon>
                    <screeb-icon
                      *ngIf="distribution.interaction === 'link'"
                      size="md"
                      icon="link"
                    ></screeb-icon>
                    <screeb-icon
                      *ngIf="
                        !distribution.interaction &&
                        distribution.type === 'widget'
                      "
                      size="md"
                      icon="desktop"
                    ></screeb-icon>
                    <screeb-icon
                      *ngIf="
                        !distribution.interaction &&
                        distribution.type === 'android'
                      "
                      size="sm"
                      icon="android"
                    ></screeb-icon>
                    <screeb-icon
                      *ngIf="
                        !distribution.interaction && distribution.type === 'ios'
                      "
                      size="sm"
                      icon="apple"
                    ></screeb-icon>
                    <screeb-icon
                      *ngIf="
                        !distribution.interaction &&
                        distribution.type === 'hosted-page'
                      "
                      size="sm"
                      icon="link"
                    ></screeb-icon>
                    {{ distribution.title }}
                  </label>
                </nz-option>
              </ng-container>
            </ng-container>
          </nz-select>
        </div>
        <button nz-button nzType="default" nzSize="large" (click)="onReload()">
          <i nz-icon nzType="reload"></i>
        </button>
        <button
          nz-button
          nzType="default"
          nzSize="large"
          (click)="onClose.emit($event)"
        >
          <i nz-icon nzType="close"></i>
        </button>
      </div>
    </header>

    <div
      class="preview-body {{
        distributionsByID[distributionId]?.interaction === 'email' &&
        emailPreview === 'in'
          ? 'body-email-background'
          : ''
      }}"
      *ngIf="!!iframePath"
    >
      <button
        *ngIf="isFullScreen"
        class="fullscreen-reload"
        nz-button
        nzType="default"
        nzSize="large"
        (click)="onReload()"
      >
        <i nz-icon nzType="reload"></i>
      </button>
      <div class="mode" *ngIf="!forceMobile">
        <nz-radio-group [ngModel]="mode" (ngModelChange)="onModeChange($event)">
          <label class="mode-button" nz-radio nzValue="desktop">
            <i nz-icon nzType="desktop" nzTheme="outline"></i>
          </label>
          <label class="mode-button" nz-radio nzValue="mobile">
            <i nz-icon nzType="mobile" nzTheme="outline"></i>
          </label>
        </nz-radio-group>
      </div>
      <div
        class="help-tip"
        *ngIf="
          distributionsByID[distributionId]?.interaction === 'email' &&
          emailPreview === 'in'
        "
      >
        <screeb-icon size="sm" icon="info-circle"></screeb-icon>
        Template format may change depending the end user email portal.
      </div>
      <div
        *ngIf="distributionsByID[distributionId]?.interaction === 'email'"
        class="email-preview"
      >
        <nz-radio-group
          [ngModel]="emailPreview"
          nzButtonStyle="solid"
          nzSize="large"
          (ngModelChange)="onEmailPreviewChange($event)"
        >
          <label nz-radio-button nzValue="in">
            <span class="email-preview-label">
              <screeb-icon size="md" icon="email"></screeb-icon>
              Embedded
            </span>
          </label>
          <label nz-radio-button nzValue="out">
            <span class="email-preview-label">
              <screeb-icon size="md" icon="desktop"></screeb-icon>
              Browser
            </span>
          </label>
        </nz-radio-group>

        <div class="help-icon">
          <screeb-icon
            size="sm"
            icon="info-circle"
            nz-popover
            nzType="primary"
            nzPopoverPlacement="topLeft"
            [nzPopoverContent]="contentTemplate"
          ></screeb-icon>
        </div>
        <ng-template #contentTemplate>
          <span>
            <strong>Embedded:</strong>
            Preview the survey directly within your email.
          </span>
          <br />
          <span>
            <strong>Browser:</strong>
            Preview the survey in the respondent's web browser.
          </span>
        </ng-template>
      </div>

      <div
        class="is-email"
        *ngIf="
          mode === 'desktop' &&
          !forceMobile &&
          distributionsByID[distributionId]?.interaction === 'email' &&
          emailPreview === 'in'
        "
      >
        <div class="email-background"></div>
        <email-preview-modal
          *ngIf="emailTemplate"
          [template]="emailTemplate"
          [inBuilder]="true"
          (actionClick)="onEmailAction($event)"
        ></email-preview-modal>
        <nz-spin *ngIf="!emailTemplate" [nzSize]="'large'"></nz-spin>
      </div>

      <iframe
        #iframePreview
        *ngIf="
          mode === 'desktop' &&
          !forceMobile &&
          (distributionsByID[distributionId]?.interaction !== 'email' ||
            emailPreview === 'out')
        "
        [src]="iframePath | sanitizeUrl"
        title="Screeb preview"
        id="screeb-iframe-container"
        allowFullscreen="false"
        scrolling="no"
        frameBorder="0"
        tabIndex="-1"
        aria-hidden="true"
      ></iframe>
      <div
        *ngIf="mode === 'mobile' || forceMobile"
        class="mobile-view interaction-{{
          distributionsByID[distributionId]?.interaction
        }} email-preview-{{ emailPreview }}"
      >
        <div class="mobile-view-wrapper">
          <div
            class="is-email"
            *ngIf="
              distributionsByID[distributionId]?.interaction === 'email' &&
              emailPreview === 'in'
            "
          >
            <div class="email-background"></div>
            <email-preview-modal
              *ngIf="emailTemplate"
              [template]="emailTemplate"
              [inBuilder]="true"
              (actionClick)="onEmailAction($event)"
            ></email-preview-modal>
            <nz-spin *ngIf="!emailTemplate" [nzSize]="'large'"></nz-spin>
          </div>
          <iframe
            *ngIf="
              distributionsByID[distributionId]?.interaction !== 'email' ||
              emailPreview === 'out'
            "
            #iframePreview
            [src]="iframePath | sanitizeUrl"
            title="Screeb preview"
            id="screeb-iframe-container"
            allowFullscreen="false"
            scrolling="no"
            frameBorder="0"
            width="310"
            tabIndex="-1"
            aria-hidden="true"
          ></iframe>
        </div>
      </div>

      <!-- </div> -->
    </div>
  </div>
</div>

<div
  *ngIf="!isFullScreen"
  class="preview-panel-backdrop"
  (click)="onClose.emit($event)"
></div>
