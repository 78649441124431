<nz-card style="width: 300px" nzTitle="New organization">
  <form nz-form nz-form [formGroup]="validateForm" (ngSubmit)="onSubmit()">
    <nz-form-item>
      <nz-form-control>
        <nz-input-group>
          <input
            type="text"
            nz-input
            nzHasFeedback
            formControlName="name"
            placeholder="Name"
            required
          />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>

    <div class="center">
      <button nz-button nzType="primary" [nzLoading]="loading">Create</button>
    </div>
  </form>
</nz-card>
