import { Serializable } from "models/serializable.model";
import {
  RegistryEntrySource,
  RegistryEntrySubject,
  RegistryEntryType,
} from "models/registry.model";

export class UserProperty extends Serializable {
  constructor(
    public id?: string,
    public respondent_id?: string,
    public key_id?: string,
    public value_string?: string,
    public value_numeric?: number,
    public value_bool?: boolean,
    public value_date?: Date,
    public source?: RegistryEntrySource,
    public count?: number,

    public created_at?: Date,
    public updated_at?: Date,
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);
    this.source = json["source"] as RegistryEntrySource;
    return this;
  }
}

export type UserPropertyWithDescription = {
  registry_id: string;
  subject: RegistryEntrySubject;
  type: RegistryEntryType;
  slug: string;
  source: RegistryEntrySource;
  value: any;

  // @samber 2024-06-13: i don't know what is it, but the legacy type has this field
  count?: number;
};
