/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, Output } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";

import { NotificationHelper } from "helpers/notification.helper";
import { ScenarioQuestionSuggestion, SurveyDao } from "models/survey.dao";
import { BuilderStore } from "stores/builder.store";
import { TrackersService } from "services/trackers.service";

@Component({
  selector: "lateral-panel-question-suggestion",
  templateUrl: "./question-suggestion.component.html",
  styleUrls: ["../common.scss", "./question-suggestion.component.scss"],
})
export class LateralPanelQuestionSuggestionComponent {
  @Output()
  public suggestion = new EventEmitter<string>();
  @Output()
  public onClose = new EventEmitter();

  public query: string = "";
  public loading: boolean = false;
  public suggestions: ScenarioQuestionSuggestion[] = [];

  constructor(
    private surveyDao: SurveyDao,
    private notificationHelper: NotificationHelper,
    private trackersService: TrackersService,
    private builderStore: BuilderStore,
  ) {}

  public suggest() {
    this.loading = true;

    this.surveyDao
      .generateScenarioQuestionSuggestions(
        this.builderStore.org.id,
        this.builderStore.survey.id,
        this.query.trim(),
      )
      .then((suggestions: ScenarioQuestionSuggestion[]) => {
        this.loading = false;
        this.suggestions = suggestions;
      })
      .catch((err: HttpErrorResponse) => {
        this.loading = false;
        console.error(err);

        this.notificationHelper.trigger(
          err?.error?.message ?? "Failed to suggest questions.",
          null,
          "error",
        );
      });
  }

  public use(label: string) {
    this.trackersService
      .newEventTrackingBuilder("Survey scenario question suggestion selected")
      .withOrg(this.builderStore.org)
      .withSurvey(this.builderStore.survey)
      .withProps({
        query: this.query,
        selected_suggestion: label,
        other_suggestions: this.suggestions,
      })
      .build();

    this.suggestion.emit(label);
  }

  public isQueryValid(): boolean {
    const query = this.query.trim();
    return 3 < query.length && query.length < 100;
  }
}
