<header>
  <div class="header-top">
    <nz-breadcrumb>
      <nz-breadcrumb-item>
        <a routerLink="/org/{{ org.id }}/people/respondent">Analytics</a>
      </nz-breadcrumb-item>
      <nz-breadcrumb-item>
        <a routerLink="/org/{{ org.id }}/funnel/list">Funnels</a>
      </nz-breadcrumb-item>
      <nz-breadcrumb-item>
        <span>Funnel analysis</span>
      </nz-breadcrumb-item>
    </nz-breadcrumb>
    <div class="header-right">
      <a
        title="I need help"
        nz-button
        nzSize="large"
        nzType="link"
        (click)="needHelpVisible = true"
      >
        <span nz-icon nzType="compass" nzTheme="outline"></span>
        <span>I need help</span>
      </a>
      <button
        *ngIf="'sequence.update' | hasPermission"
        routerLink="/org/{{ funnel.org_id }}/funnel/{{ funnel.id }}/edit"
        nz-button
        nzType="primary"
        nzSize="large"
      >
        <img alt="Edit" nz-icon src="/assets/icons/homepage/pen.svg" />
        Edit funnel
      </button>
    </div>
  </div>
  <div class="header-bottom">
    <div class="header-bottom-title">
      {{ funnel.title }}
    </div>
    <p class="header-bottom-subtitle">
      Funnel stats are calculated from events triggered over the last 3 months.
    </p>
    <!-- <div class="date-range" nzTrigger="click" [ngClass]="'light'">
      <i class="date-range-icon" nz-icon nzType="calendar"></i>

      <input
        readonly
        class="date-range-value"
        type="text"
        ngxDaterangepickerMd
        [ngModel]="selected"
        [showCustomRangeLabel]="true"
        [alwaysShowCalendars]="true"
        [ranges]="ranges"
        [linkedCalendars]="true"
        [locale]="{ applyLabel: 'Done', format: 'DD-MM-YYYY' }"
        [autoApply]="true"
        (rangeClicked)="rangeClicked($event)"
        (datesUpdated)="datesUpdated($event)"
        [keepCalendarOpeningWithRange]="true"
        [isInvalidDate]="isInvalidDate"
        [showRangeLabelOnInput]="true"
        opens="left"
        drops="down"
        placeholder="Select please..."
      />

      <i class="date-range-icon-carret" nz-icon nzType="caret-down"></i>
    </div> -->
  </div>
</header>

<section
  *ngIf="'isFunnelSummaryAvailable' | hasFeatureFlag"
  id="funnel-summary"
>
  <read-more
    [loading]="summaryLoading"
    [header]="SummaryHeader"
    [content]="SummaryContent"
  ></read-more>

  <ng-template #SummaryHeader>
    <p ngClass="read-more-title">
      Summary of your Funnel
      <span class="powered-by-tag">Powered by Screeb AI</span>
      <span
        *ngIf="summaryLoading"
        style="margin-left: 8px"
        nz-icon
        nzType="loading"
        class="circle-loader"
        nzTheme="outline"
      ></span>
    </p>
  </ng-template>

  <ng-template #SummaryContent>
    <div *ngFor="let step of summary.steps">
      <h3 style="font-weight: 500">{{ step.title }}</h3>
      <p>{{ step.explanation }}</p>
    </div>

    <h2>Recommandations</h2>
    <markdown [data]="summary.recommendation"></markdown>
  </ng-template>
</section>
<div class="funnel-container">
  <funnel-grid
    *ngIf="funnel && !error"
    [surveysById]="surveysById"
    [(funnel)]="funnel"
    [values]="funnelReportAggregation"
    [registryEntriesEvent]="registryEntriesEvent"
    [loading]="loading"
    [loadingPercentage]="loadingPercentage"
    [editMode]="false"
    (displaySurveyClick)="onDisplaySurveyClick($event)"
  ></funnel-grid>

  <error-message
    *ngIf="error"
    message="An error occured when fetching data."
    [details]="error.message"
    (reload)="fetchData()"
  ></error-message>
</div>

<funnel-help
  *ngIf="needHelpVisible"
  (closePanel)="needHelpVisible = false"
></funnel-help>
