import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";

import { ApplicationLoadService } from "services/app.service";
import { OrgDao } from "models/org.dao";
import { Account } from "models/account.model";

@Injectable()
export class OrgAccountsResolver implements Resolve<any> {
  constructor(
    private applicationLoadService: ApplicationLoadService,
    private orgDao: OrgDao,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Promise<Account[]> {
    return this.applicationLoadService
      .loader()
      .then(() => {
        return this.orgDao.getAllOrgAccounts(route.params["org_id"]);
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        throw err;
      });
  }
}
