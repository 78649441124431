<header>
  <h1>
    <b>{{ surveyDistribution.title }}</b>
    channel
  </h1>
  <p>
    {{ surveyDistribution.description }}
  </p>
  <hr />
</header>

<upgrade-modal
  *ngIf="isMobileDistribution && !('survey_inapp_mobile' | hasEntitlement)"
  position="absolute"
  [canCancel]="false"
  [centeredModal]="true"
  [withOverlay]="true"
  [orgId]="org.id"
  upgradeType="survey_inapp_mobile"
></upgrade-modal>

<free-trial-banner />

<test-mode-banner *ngIf="isTestModeEnabled()"></test-mode-banner>

<org-rules-banner
  *ngIf="
    survey.type === 'survey' &&
    (!surveyDistribution.interaction ||
      surveyDistribution.interaction === 'in_app' ||
      surveyDistribution.interaction === 'in_page') &&
    channel.type !== 'hosted-page' &&
    orgTargetingRules?.length > 0
  "
  [org]="org"
></org-rules-banner>

<div class="container">
  <section
    id="channel-targeting-widget-mobile"
    *ngIf="
      ['widget', 'android', 'ios'].includes(channel.type) &&
      surveyDistribution.interaction !== 'link' &&
      surveyDistribution.interaction !== 'email'
    "
  >
    <section id="channel-targeting">
      <div class="channel-targeting-mode-switch">
        <div>
          <h2 class="title">
            <screeb-icon size="sm" icon="filter" />
            User segmentation
          </h2>
          <p class="desc">Let's customize the targeting for this channel</p>
        </div>
        <nz-radio-group
          [(ngModel)]="editMode"
          nzButtonStyle="solid"
          nzSize="large"
        >
          <label
            *ngIf="channel.type === 'android' || channel.type === 'ios'"
            nz-radio-button
            nzValue="push-notification"
          >
            <screeb-icon size="sm" icon="notification"></screeb-icon>
            Notification
          </label>
          <label nz-radio-button nzValue="manual">
            <screeb-icon size="sm" icon="dev-documentation"></screeb-icon>
            Manual
          </label>
          <label
            nz-radio-button
            nzValue="wizard"
            [nzDisabled]="!isWizardAvailable()"
            nz-tooltip
            [nzTooltipTitle]="
              !isWizardAvailable()
                ? 'There are some rules that are not compatible with the simple mode'
                : null
            "
          >
            <screeb-icon size="sm" icon="newest-user"></screeb-icon>
            Simple
          </label>
          <label nz-radio-button nzValue="advanced">
            <screeb-icon size="sm" icon="active-user"></screeb-icon>
            Advanced
          </label>
        </nz-radio-group>
      </div>
    </section>

    <section
      id="channel-targeting-push-notification"
      *ngIf="editMode === 'push-notification'"
    >
      <survey-share-targeting-push-notification
        [surveyId]="survey.id"
        [channelType]="channel.type"
      ></survey-share-targeting-push-notification>
    </section>

    <section id="channel-targeting-manual" *ngIf="editMode === 'manual'">
      <survey-share-targeting-manual
        [surveyId]="survey.id"
        [surveyType]="survey.type"
        [distributionId]="surveyDistribution.id"
        [interaction]="surveyDistribution.interaction"
      ></survey-share-targeting-manual>
    </section>

    <section id="channel-targeting-wizard" *ngIf="editMode === 'wizard'">
      <survey-share-targeting-wizard
        [surveyId]="survey.id"
        [distribution]="surveyDistribution"
        [orgId]="org.id"
        [rules]="targetingRules"
        (rulesChange)="onTargetingRulesChange($event)"
        [channelClientScreens]="channelClientScreens"
        [languages]="languages"
        [countries]="countries"
        [(selectorCSS)]="surveyDistribution.selector_css"
      ></survey-share-targeting-wizard>
    </section>

    <section id="channel-targeting-advanced" *ngIf="editMode === 'advanced'">
      <survey-share-targeting-advanced
        [isOrgTargeting]="false"
        [org]="org"
        [surveyId]="survey.id"
        [distribution]="surveyDistribution"
        [interaction]="surveyDistribution.interaction"
        [rules]="targetingRules"
        (rulesChange)="onTargetingRulesChange($event)"
        [registryEntriesIdentityProperty]="registryEntriesIdentityProperty"
        [registryEntriesEvent]="registryEntriesEvent"
        [registryEntriesGroup]="registryEntriesGroup"
        [channelClientScreens]="channelClientScreens"
        [channelClientVersions]="channelClientVersions"
        [languages]="languages"
        [countries]="countries"
        [surveys]="surveys"
        [(selectorCSS)]="surveyDistribution.selector_css"
      ></survey-share-targeting-advanced>
    </section>

    <ng-container *ngIf="editMode !== 'manual'">
      <hr *ngIf="editMode !== 'advanced'" />
      <br *ngIf="editMode === 'advanced'" />
      <h2 class="title">
        <screeb-icon size="md" icon="button" />
        Behaviour
      </h2>
      <p class="desc">Let's customize the behaviour for this channel</p>
    </ng-container>

    <section class="test-mode" *ngIf="editMode !== 'manual'">
      <div class="test-mode-title">
        <span class="label">Test mode</span>
        <nz-switch
          [ngModel]="testModeRule?.value?.v_b && !recurrenceRule"
          (ngModelChange)="onTestModeChange($event)"
          [nzCheckedChildren]="checkedTemplate"
          [nzUnCheckedChildren]="unCheckedTemplate"
          [nzDisabled]="
            !!recurrenceRule || !('survey_edition' | hasEntitlement)
          "
          nzTooltipTitle="Test mode is not compatible with a recurrence rule"
          nz-tooltip
          [nzTooltipTrigger]="!!recurrenceRule ? 'hover' : null"
        ></nz-switch>
        <ng-template #checkedTemplate>
          <i nz-icon nzType="check"></i>
        </ng-template>
        <ng-template #unCheckedTemplate>
          <i nz-icon nzType="close"></i>
        </ng-template>
      </div>
      <p class="test-mode-desc">
        Activate test mode to display this
        {{ uiService.isMessagePage ? "message" : "survey" }} multiple times to a
        same user.
      </p>

      <div
        *ngIf="isTestModeEnabled()"
        class="test-mode-content"
        [style]="{
          height: 'auto',
        }"
      >
        <test-mode-banner></test-mode-banner>
      </div>
    </section>

    <section
      class="capping-selection"
      *ngIf="editMode !== 'manual' && !uiService.isMessagePage"
    >
      <div class="capping-selection-title">
        <span class="label">Capping</span>
        <nz-switch
          [ngModel]="overrideSurveyCappingSwitch"
          (ngModelChange)="onCappingTargetingChange($event)"
          [nzCheckedChildren]="checkedTemplate"
          [nzUnCheckedChildren]="unCheckedTemplate"
          [nzDisabled]="!('survey_edition' | hasEntitlement)"
        ></nz-switch>
        <ng-template #checkedTemplate>
          <i nz-icon nzType="check"></i>
        </ng-template>
        <ng-template #unCheckedTemplate>
          <i nz-icon nzType="close"></i>
        </ng-template>
      </div>
      <p>
        Cap the displays and responses of this
        {{ uiService.isMessagePage ? "message" : "survey" }}, over a period of
        time.
        <br />
        This will be only applied to this
        {{ uiService.isMessagePage ? "message" : "survey" }} and will override
        <a href="/org/{{ org.id }}/settings/survey-capping">
          General
          {{ uiService.isMessagePage ? "Messages" : "Surveys" }} Capping
          settings
        </a>
        .
      </p>

      <div
        *ngIf="overrideSurveyCappingSwitch"
        class="capping-selection-content"
      >
        <survey-capping-config
          [(rules)]="targetingRules"
          surveyCappingType="per-survey"
        ></survey-capping-config>
      </div>
    </section>
  </section>

  <section
    id="channel-targeting-hosted-page"
    *ngIf="
      surveyDistribution.interaction === 'link' ||
      surveyDistribution.interaction === 'email' ||
      channel.type === 'hosted-page'
    "
  >
    <page-survey-share-link
      *ngIf="
        surveyDistribution.interaction === 'link' ||
        channel.type === 'hosted-page'
      "
      [org]="org"
      [survey]="survey"
      [surveyDistribution]="surveyDistribution"
      [multiple]="isMultipleEnabled()"
      (multipleChange)="onTargetingRuleMultipleChange($event)"
    ></page-survey-share-link>
    <page-survey-share-email
      *ngIf="surveyDistribution.interaction === 'email'"
      [org]="org"
      [surveyDistribution]="surveyDistribution"
      [multiple]="isMultipleEnabled()"
      (multipleChange)="onTargetingRuleMultipleChange($event)"
    ></page-survey-share-email>
  </section>
</div>

<ng-template #pageActions>
  <div class="toolbar" *ngIf="'survey.distribution.update' | hasPermission">
    <nz-button-group nzSize="large">
      <button
        title="Save"
        nz-button
        [nzTooltipTitle]="saveError"
        nz-tooltip
        [nzTooltipTrigger]="!!saveError ? 'hover' : null"
        nzSize="large"
        nzShape="round"
        [nzLoading]="saving"
        (click)="onSave()"
        [disabled]="!hasUnsavedChanges()"
      >
        <screeb-icon size="sm" icon="save"></screeb-icon>
        <span>Save</span>
      </button>
      <button
        *ngIf="!active"
        id="status-launch"
        class="actions-save ant-btn-secondary"
        title="Set live"
        nz-button
        nzSize="large"
        nzShape="round"
        [nzLoading]="enabling"
        [nzTooltipTitle]="saveError"
        nz-tooltip
        [nzTooltipTrigger]="!!saveError ? 'hover' : null"
        (click)="$event.stopPropagation()"
        nz-popconfirm
        [nzCondition]="bypassLaunchWarning()"
        nzPopconfirmTitle="You're going to make this channel live to all of your users. Are you sure?"
        nzPopconfirmPlacement="bottom"
        nzCancelText="No"
        nzOkText="Yes"
        (nzOnConfirm)="onEnable(true)"
        [nzOkDisabled]="!canLaunch()"
        [disabled]="!canLaunch()"
      >
        <screeb-icon size="sm" icon="launch"></screeb-icon>
        <span>
          Set
          <b>{{ surveyDistribution.title }}</b>
          live
        </span>
      </button>
      <button
        *ngIf="active"
        id="status-pause"
        title="pause"
        class="ant-btn-secondary"
        nzGhost
        nz-button
        nzSize="large"
        nzShape="round"
        [nzLoading]="enabling"
        (click)="onEnable(false)"
        [disabled]="!canLaunch()"
      >
        <screeb-icon size="sm" icon="pause"></screeb-icon>
        <span>
          Pause
          <b>{{ surveyDistribution.title }}</b>
        </span>
      </button>
    </nz-button-group>

    <div class="pending-mode-popover" *ngIf="active && !isInStartEndDates()">
      This survey is currently active but not displayed, as it is outside the
      selected date and time range.
    </div>
  </div>
</ng-template>
