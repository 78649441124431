import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";

import { ApplicationLoadService } from "services/app.service";
import { NotificationHelper } from "helpers/notification.helper";
import { Release } from "models/release.model";
import { ReleaseDao } from "models/release.dao";

@Injectable()
export class OrgReleasesResolver implements Resolve<any> {
  constructor(
    private router: Router,
    private applicationLoadService: ApplicationLoadService,
    private releaseDao: ReleaseDao,
    private notificationHelper: NotificationHelper,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Promise<Release[]> {
    return this.applicationLoadService
      .loader()
      .then(() => {
        return this.releaseDao.getAllByOrgId(route.params["org_id"]);
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        throw err;
      });
  }
}
