import { AccountGoal } from "models/account.model";

export const InteractiveGuides: {
  [key in AccountGoal]: { title: string; src: string };
} = {
  "collect-product-feedback": {
    title: "Collect Product Feedback",
    src: "https://demo.arcade.software/cryq2nFC28Dpdp9QQFAB?embed",
  },
  "boost-user-satisfaction": {
    title: "Create new survey",
    src: "https://demo.arcade.software/WTrrUGs0mFfSLfo8ti51?embed",
  },
  "improve-funnels-conversion-rate": {
    title: "Improve Funnels Conversion Rate",
    src: "https://demo.arcade.software/UL0dxEfBLdOGWNZeiypE?embed",
  },
  "increase-user-retention": {
    title: "Increase User Retention",
    src: "https://demo.arcade.software/Zl2timqJX0KImRfflXmc?embed",
  },
  "increase-product-adoption": {
    title: "Increase my Product Adoption",
    src: "https://demo.arcade.software/3SYeKWDcX4DVQQBoXAWv?embed",
  },
  "discover-new-things-about-users": {
    title: "Discover New Things about my Users",
    src: "https://demo.arcade.software/rkgTStTMrbIW3jd0VRdp?embed",
  },
};
