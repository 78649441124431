export type NumberFormatType = "percent" | "number" | "points";

function _formatNumber(
  value: number,
  maximumFractionDigits = 0,
  thousandsSuffixes = false,
): string {
  if (thousandsSuffixes) {
    return getWithThousandsSuffix(value, maximumFractionDigits);
  }

  return new Intl.NumberFormat("en", { maximumFractionDigits }).format(value);
}

export function formatNumber(
  value: number,
  format: NumberFormatType,
  maximumFractionDigits: number = 0,
  signMode: "auto" | "force" | "hide" = "auto",
  thousandsSuffixes = false,
): string {
  const sign = signMode === "force" && value > 0 ? "+" : "";

  const valueFormatted = _formatNumber(
    signMode === "hide" ? Math.abs(value) : value,
    Math.abs(value) >= 10 ? maximumFractionDigits : 2,
    thousandsSuffixes,
  ).replace(/^-0$/g, "0"); // avoid -0 display

  switch (format) {
    case "points":
      return `${sign}${valueFormatted}pts`;
    case "percent":
      return `${sign}${valueFormatted}%`;
    case "number":
      return `${sign}${valueFormatted}`;
  }
}

export function getWithThousandsSuffix(
  value: number,
  maximumFractionDigits: number,
) {
  const suffixes = ["k", "M", "B"];

  if (Number.isNaN(value)) {
    return "N/A";
  }

  if (value < 1000) {
    return new Intl.NumberFormat("en", { maximumFractionDigits }).format(value);
  }

  const exp = Math.floor(Math.log(value) / Math.log(1000));

  return (
    new Intl.NumberFormat("en", { maximumFractionDigits }).format(
      value / Math.pow(1000, exp),
    ) + suffixes[exp - 1]
  );
}
