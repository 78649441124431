<div class="date-range" nzTrigger="click" [ngClass]="theme">
  <i class="date-range-icon" nz-icon nzType="calendar"></i>

  <input
    readonly
    class="date-range-value"
    type="text"
    ngxDaterangepickerMd
    [ngModel]="selected"
    [showCustomRangeLabel]="true"
    [alwaysShowCalendars]="true"
    [ranges]="ranges"
    [linkedCalendars]="true"
    [locale]="{ applyLabel: 'Done', format: 'DD-MM-YYYY' }"
    [autoApply]="true"
    (rangeClicked)="rangeClicked($event)"
    (datesUpdated)="datesUpdated($event)"
    [keepCalendarOpeningWithRange]="true"
    [isInvalidDate]="isInvalidDate"
    [showRangeLabelOnInput]="true"
    opens="left"
    drops="down"
    placeholder="Select please..."
  />

  <i class="date-range-icon-carret" nz-icon nzType="caret-down"></i>
</div>
