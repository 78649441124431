import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { OrgDao } from "models/org.dao";
import { Debounce } from "utils/debounce";

@Component({
  selector: "email-preview-modal",
  templateUrl: "./preview-modal.component.html",
  styleUrls: ["./preview-modal.component.scss"],
})
export class EmailPreviewModalComponent implements OnChanges {
  @ViewChild("preview") preview: ElementRef;

  @Input() isOpen: boolean = false;
  @Input() template: string = null;
  @Input() inBuilder: boolean = false;
  @Output() modalOpenChange: EventEmitter<boolean> = new EventEmitter();
  @Output() actionClick: EventEmitter<string> = new EventEmitter();

  constructor(public orgDao: OrgDao) {}

  ngOnChanges({ isOpen, template }: SimpleChanges) {
    if (((isOpen && !isOpen.currentValue) || !this.isOpen) && !this.inBuilder) {
      return;
    }

    if (template || this.template) {
      this.setPreview();
    }
  }

  handleCancel(): void {
    this.isOpen = false;
    this.modalOpenChange?.emit(this.isOpen);
  }

  @Debounce(500) // debounce to let the time to body to load once
  private setPreview() {
    const doc =
      this.preview?.nativeElement.contentDocument ||
      this.preview?.nativeElement.contentWindow;
    doc?.open();
    doc?.write(this.simulateEmailEnv());
    doc?.close();

    // prevent and catch click events on the iframe
    if (this.inBuilder) {
      doc?.body.addEventListener("click", (event) => {
        event.preventDefault();
        event.stopPropagation();
        const href = event.target?.getAttribute("href");
        if (href) {
          this.actionClick?.emit(href);
        }
      });

      // resize iframe to fit content when it's loaded
      doc?.body.addEventListener("load", () => {
        this.preview?.nativeElement.setAttribute(
          "height",
          `${doc?.body.scrollHeight + 8}px`,
        );
      });

      // handle case when iframe is already loaded
      if (doc?.body.scrollHeight) {
        this.preview?.nativeElement.setAttribute(
          "height",
          `${doc?.body.scrollHeight + 8}px`,
        );
      }
    }
  }

  // https://gist.github.com/ewencp/6573961
  public simulateEmailEnv() {
    /* First try to handle pages which are actually raw text of the email.
       Extract the HTML part and replace page with it */
    const html = new DOMParser().parseFromString(this.template, "text/html");
    /* let extracted_html = orig_html;
    /* Try splitting it up if it's actually the multipart email. Otherwise, work
          on the document itself, leaving the orig_html in place
    const boundary_pattern = "--===============";
    while (extracted_html.indexOf(boundary_pattern) !== -1) {
      const next_boundary = extracted_html.indexOf(boundary_pattern);
      const next_block = extracted_html.substr(0, next_boundary);
      /* If this block contains the html use it
      const html_pos = next_block.indexOf("<html");
      if (html_pos !== -1) {
        const html_end_pos = next_block.indexOf("/html>");
        extracted_html = next_block.substr(
          html_pos,
          html_end_pos - html_pos + 6,
        );
        break;
      }
      /* Otherwise, continue on next block. We need to make sure we get rid of
              the boundary in the process
      const new_start_idx = extracted_html.indexOf("\n", next_boundary);
      extracted_html = extracted_html.substr(new_start_idx + 1);
    }

    /* Put the replacement in place
    if (extracted_html !== orig_html) {
      document.write(extracted_html);
    } */

    /*Now run through the document clearing out data we shouldn't have. Ideally
       this would match the process that email clients follow. Something like GMail
       or Yahoo Mail, where the data is embedded directly in another page, needs to
       do the most aggressive filtering, so we want to match something like
       that. Our first step is removing entire tags. */
    const excluded_tags = ["head", "style", "link"];
    for (let ex_i = 0; ex_i < excluded_tags.length; ex_i++) {
      const ex_elems = html.getElementsByTagName(excluded_tags[ex_i]);
      for (let exe_i = 0; exe_i < ex_elems.length; exe_i++) {
        const node = ex_elems[exe_i];
        node.parentNode.removeChild(node);
      }
    }

    /*And remove attributes that we can't verify. We don't have a complete
         list, so we filter out attributes only for tags we generate an explicit
         list for. A blacklist of attributes would be nice, but since the possible
         list of tags is ever growing and people generate non-conforming HTML for
         emails, we can't do that.
         Some global attributes are always permitted. Each attribute is
         treated as a prefix so we can match generic sets of tags. Finally, we also
         have list of globally explicitly  attributes that should always be
         stripped. */
    const global_attributes = [
      "accesskey",
      "contenteditable",
      "contextmenu",
      "data-",
      "dir",
      "draggable",
      "dropzone",
      "hidden",
      "itemid",
      "itemprop",
      "itemref",
      "itemscope",
      "itemtype",
      "lang",
      "spellcheck",
      "style",
      "tabindex",
      "title",
    ];
    const valid_attributes = {
      table: [
        "align",
        "bgcolor",
        "border",
        "cellpadding",
        "cellspacing",
        "frame",
        "rules",
        "width",
      ],
      tbody: ["align", "bgcolor", "valign"],
      tr: ["align", "bgcolor", "valign"],
      td: ["align", "bgcolor", "colspan", "rowspan", "valign"],

      img: ["align", "alt", "border", "height", "src", "width"],
    };
    const always_strip_attributes = ["id", "class"];

    const all_elems = html.getElementsByTagName("*");
    for (let elem_i = 0; elem_i < all_elems.length; elem_i++) {
      const elem = all_elems[elem_i];
      const attribs_to_remove = [];
      for (let i = 0; i < elem.attributes.length; i++) {
        const attrib = elem.attributes[i];
        let done = false;
        if (!attrib.specified) continue;
        /* First check if it's in the "always strip" list */
        for (let ai = 0; ai < always_strip_attributes.length; ai++) {
          if (always_strip_attributes[ai] === attrib.name) {
            attribs_to_remove.push(attrib.name);
            done = true;
            break;
          }
        }
        if (done) continue;

        /* Next check if it's one of the valid global
                  attributes. If it is, we let it pass */
        const tag_valid_attributes =
          valid_attributes[elem.tagName.toLowerCase()];
        if (!tag_valid_attributes) continue;
        for (let ai = 0; ai < global_attributes.length; ai++) {
          const global_attrib_prefix = global_attributes[ai];
          if (attrib.name.indexOf(global_attrib_prefix) === 0) {
            /* Setting done & not adding to the list lets it
                          pass */
            done = true;
            break;
          }
        }
        if (done) continue;

        /* Finally, if we have a filter on the element, we can filter based
                  on its valid elements */
        for (let ai = 0; ai < tag_valid_attributes.length; ai++) {
          const valid_attrib = tag_valid_attributes[ai];
          if (valid_attrib === attrib.name) {
            done = true;
            break;
          }
        }
        if (done) continue;
        /* If we didn't continue already, then the attribute wasn't in the
                  safe list. */
        attribs_to_remove.push(attrib.name);
      }

      /* After finishing iterating over them, remove the ones we
              discovered */
      for (let ai = 0; ai < attribs_to_remove.length; ai++)
        elem.removeAttribute(attribs_to_remove[ai]);
    }

    /* And we need to remove any restricted styles. I haven't done any of this yet... */

    /* Now we need to remove any script tags. */
    const scripts = html.getElementsByTagName("script");
    for (let i = 0; i < scripts.length; i++) {
      const node = scripts[i];
      node.parentNode.removeChild(node);
    }

    /* Turn the document back into a string */
    return html.documentElement.outerHTML;
  }
}
