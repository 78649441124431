<h3 class="title">
  <screeb-icon *ngIf="type === 'NPS'" size="md" icon="nps"></screeb-icon>
  <screeb-icon
    *ngIf="type === 'CSAT'"
    size="sm"
    icon="talk-to-a-human"
  ></screeb-icon>
  <screeb-icon *ngIf="type === 'CES'" size="md" icon="ces"></screeb-icon>
  <screeb-icon
    *ngIf="type === 'RESPONSE_RATE'"
    size="md"
    icon="studies"
  ></screeb-icon>
  <screeb-icon
    *ngIf="type === 'RESPONSE_COMPLETION_RATE'"
    size="sm"
    icon="filter"
  ></screeb-icon>
  <screeb-icon *ngIf="!type" size="sm" icon="launch"></screeb-icon>
  {{ title }}
</h3>

<div class="company-summary">
  <div class="soon-cover" *ngIf="soon">
    <div class="soon-text">Soon</div>
  </div>
  <div
    *ngIf="!soon"
    class="compare-badge"
    [style]="{
      backgroundColor: diffColor,
    }"
  >
    {{ scoreDiff }}% than your industry
  </div>
  <div class="score-wrapper">
    <div class="company-score">
      <span>You</span>
      <span class="score">
        {{ companyScore.toFixed(1) }}{{ isPercentage ? " %" : "" }}
      </span>
    </div>
    <div class="industry-score">
      <span>Competitors</span>
      <span class="score">
        {{ competitorScore.toFixed(1) }}{{ isPercentage ? " %" : "" }}
      </span>
    </div>
    <div class="separator"></div>
    <div class="industry-score">
      <span>Top Performer</span>
      <span class="score">
        {{ topPerformerScore.toFixed(1) }}{{ isPercentage ? " %" : "" }}
      </span>
    </div>
  </div>
  <div class="observations" *ngIf="withObservation">
    <h3>Observation</h3>
    <span *ngIf="observation">{{ observation }}</span>
    <text-shimmer *ngIf="!observation"></text-shimmer>
  </div>
</div>
