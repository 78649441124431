export const accountMFAList = ["phone", "otp"] as const;

export type AccountMFA = (typeof accountMFAList)[number];

export type AccountMFAStatus = {
  mfa: AccountMFA;
  enabled: boolean;
};

export type AccountMFAs = {
  [key in AccountMFA]: AccountMFAStatus;
};

export const AccountMFAToLabel: { [key in AccountMFA]: string } = {
  otp: "One-time password",
  phone: "SMS",
};
