<ng-container *ngIf="rule">
  <div>
    <nz-tag
      [ngClass]="{
        disabled:
          !('survey_edition' | hasEntitlement) ||
          !('survey_inapp_desktop' | hasEntitlement),
      }"
      nzMode="checkable"
      [nzChecked]="rule.value.v_s_arr.indexOf('desktop') !== -1"
      (nzCheckedChange)="
        onDeviceChange(rule, 'desktop', $event, 'survey_inapp_desktop')
      "
    >
      Desktop
    </nz-tag>
    <nz-tag
      [ngClass]="{
        disabled:
          !('survey_edition' | hasEntitlement) ||
          !('survey_inapp_mobile' | hasEntitlement),
      }"
      nzMode="checkable"
      [nzChecked]="rule.value.v_s_arr.indexOf('mobile') !== -1"
      (nzCheckedChange)="
        onDeviceChange(rule, 'mobile', $event, 'survey_inapp_mobile')
      "
    >
      Mobile
    </nz-tag>
    <nz-tag
      [ngClass]="{
        disabled:
          !('survey_edition' | hasEntitlement) ||
          !('survey_inapp_mobile' | hasEntitlement),
      }"
      nzMode="checkable"
      [nzChecked]="rule.value.v_s_arr.indexOf('tablet') !== -1"
      (nzCheckedChange)="
        onDeviceChange(rule, 'tablet', $event, 'survey_inapp_mobile')
      "
    >
      Tablet
    </nz-tag>
  </div>
  <utils-form-error
    *ngIf="rule.value.v_s_arr.length === 0"
    [errors]="['Please select at least 1 support']"
  ></utils-form-error>
</ng-container>
