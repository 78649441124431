<div class="integration">
  <div *ngIf="!integrationSpec.haveNoGlobalSettings" class="integration__left">
    <div class="integration__left--wrapper">
      <header>
        <h1>Configure the {{ integrationSpec.name }} integration</h1>
        <section class="integration-description">
          <p [innerHTML]="integrationSpec.description"></p>
        </section>
      </header>
      <hr />
      <section
        class="integration-configuration"
        *ngIf="integration"
        [ngSwitch]="integration.type"
      >
        <integration-settings-amplitude-source
          *ngSwitchCase="'amplitude-source'"
          class="integration-settings-config"
          [org]="org"
          [integration]="integration"
          (valid)="integrationSettingsValid = $event"
          (onSettingsChange)="onSettingsChange($event)"
        ></integration-settings-amplitude-source>
        <integration-settings-segment-source
          *ngSwitchCase="'segment-source'"
          class="integration-settings-config"
          [org]="org"
          [integration]="integration"
          (valid)="integrationSettingsValid = $event"
          (onSettingsChange)="onSettingsChange($event)"
        ></integration-settings-segment-source>
        <integration-settings-rudderstack-source
          *ngSwitchCase="'rudderstack-source'"
          class="integration-settings-config"
          [org]="org"
          [integration]="integration"
          (valid)="integrationSettingsValid = $event"
          (onSettingsChange)="onSettingsChange($event)"
        ></integration-settings-rudderstack-source>
        <integration-settings-segment-destination
          *ngSwitchCase="'segment-destination'"
          class="integration-settings-config"
          [org]="org"
          [integration]="integration"
          (valid)="integrationSettingsValid = $event"
          (onSettingsChange)="onSettingsChange($event)"
        ></integration-settings-segment-destination>
        <integration-settings-contentsquare-source
          *ngSwitchCase="'contentsquare-source'"
          class="integration-settings-config"
          [org]="org"
          [integration]="integration"
          (valid)="integrationSettingsValid = $event"
          (onSettingsChange)="onSettingsChange($event)"
        ></integration-settings-contentsquare-source>
        <integration-settings-amplitude-destination
          *ngSwitchCase="'amplitude-destination'"
          class="integration-settings-config"
          [org]="org"
          [integration]="integration"
          (valid)="integrationSettingsValid = $event"
          (onSettingsChange)="onSettingsChange($event)"
        ></integration-settings-amplitude-destination>
        <integration-settings-rudderstack-destination
          *ngSwitchCase="'rudderstack-destination'"
          class="integration-settings-config"
          [org]="org"
          [integration]="integration"
          (valid)="integrationSettingsValid = $event"
          (onSettingsChange)="onSettingsChange($event)"
        ></integration-settings-rudderstack-destination>
        <integration-settings-harvestr
          *ngSwitchCase="'harvestr'"
          class="integration-settings-config"
          [org]="org"
          [integration]="integration"
          (valid)="integrationSettingsValid = $event"
          (onSettingsChange)="onSettingsChange($event)"
        ></integration-settings-harvestr>
        <integration-settings-productboard
          *ngSwitchCase="'productboard'"
          class="integration-settings-config"
          [org]="org"
          [integration]="integration"
          (valid)="integrationSettingsValid = $event"
          (onSettingsChange)="onSettingsChange($event)"
        ></integration-settings-productboard>
        <integration-settings-zendesk
          *ngSwitchCase="'zendesk'"
          class="integration-settings-config"
          [org]="org"
          [integration]="integration"
          (valid)="integrationSettingsValid = $event"
          (onSettingsChange)="onSettingsChange($event)"
        ></integration-settings-zendesk>
        <integration-settings-intercom
          *ngSwitchCase="'intercom'"
          class="integration-settings-config"
          [org]="org"
          [integration]="integration"
          (valid)="integrationSettingsValid = $event"
          (onSettingsChange)="onSettingsChange($event)"
        ></integration-settings-intercom>
        <integration-settings-trello
          *ngSwitchCase="'trello'"
          class="integration-settings-config"
          [org]="org"
          [integration]="integration"
          (valid)="integrationSettingsValid = $event"
          (onSettingsChange)="onSettingsChange($event)"
        ></integration-settings-trello>
        <integration-settings-cycle
          *ngSwitchCase="'cycle'"
          class="integration-settings-config"
          [org]="org"
          [integration]="integration"
          (valid)="integrationSettingsValid = $event"
          (onSettingsChange)="onSettingsChange($event)"
        ></integration-settings-cycle>
        <integration-settings-monday
          *ngSwitchCase="'monday'"
          class="integration-settings-config"
          [org]="org"
          [integration]="integration"
          (valid)="integrationSettingsValid = $event"
          (onSettingsChange)="onSettingsChange($event)"
        ></integration-settings-monday>
        <integration-settings-asana
          *ngSwitchCase="'asana'"
          class="integration-settings-config"
          [org]="org"
          [integration]="integration"
          (valid)="integrationSettingsValid = $event"
          (onSettingsChange)="onSettingsChange($event)"
        ></integration-settings-asana>
        <integration-settings-typeform
          *ngSwitchCase="'typeform'"
          class="integration-settings-config"
          [org]="org"
          [integration]="integration"
          (valid)="integrationSettingsValid = $event"
          (onSettingsChange)="onSettingsChange($event)"
        ></integration-settings-typeform>
        <integration-settings-webhook
          *ngSwitchCase="'webhook'"
          class="integration-settings-config"
          [org]="org"
          [integration]="integration"
          (valid)="integrationSettingsValid = $event"
          (onSettingsChange)="onSettingsChange($event)"
        ></integration-settings-webhook>
        <integration-settings-stitch
          *ngSwitchCase="'stitch'"
          class="integration-settings-config"
          [org]="org"
          [integration]="integration"
          (valid)="integrationSettingsValid = $event"
          (onSettingsChange)="onSettingsChange($event)"
        ></integration-settings-stitch>
        <integration-settings-zapier
          *ngSwitchCase="'zapier'"
          class="integration-settings-config"
          [org]="org"
          [integration]="integration"
          (valid)="integrationSettingsValid = $event"
          (onSettingsChange)="onSettingsChange($event)"
        ></integration-settings-zapier>
        <integration-settings-cobbai
          *ngSwitchCase="'cobbai'"
          class="integration-settings-config"
          [org]="org"
          [integration]="integration"
          (valid)="integrationSettingsValid = $event"
          (onSettingsChange)="onSettingsChange($event)"
        ></integration-settings-cobbai>
        <integration-settings-slack
          *ngSwitchCase="'slack'"
          class="integration-settings-config"
          [org]="org"
          [integration]="integration"
          (valid)="integrationSettingsValid = $event"
          (onSettingsChange)="onSettingsChange($event)"
        ></integration-settings-slack>
        <integration-settings-notion
          *ngSwitchCase="'notion'"
          class="integration-settings-config"
          [org]="org"
          [integration]="integration"
          (valid)="integrationSettingsValid = $event"
          (onSettingsChange)="onSettingsChange($event)"
        ></integration-settings-notion>
        <integration-settings-atlassian
          *ngSwitchCase="'atlassian'"
          class="integration-settings-config"
          [org]="org"
          [integration]="integration"
          (valid)="integrationSettingsValid = $event"
          (onSettingsChange)="onSettingsChange($event)"
        ></integration-settings-atlassian>
        <!-- <div *ngSwitchDefault>Not found</div> -->
      </section>
      <integration-instructions
        [type]="integrationSlug"
      ></integration-instructions>
    </div>
    <ul class="global-actions">
      <li *ngIf="integrationExists">
        <button
          title="Remove Integration"
          nz-button
          nzType="default"
          nzSize="large"
          nzDanger
          (click)="showRemoveConfirm()"
        >
          <i nz-icon nzType="delete"></i>
        </button>
      </li>
      <li *ngIf="!integrationSpec.autoSave">
        <button
          title="{{ integrationExists ? 'Save' : 'Install' }}"
          nz-button
          nzType="default"
          nzSize="large"
          class="ant-btn-secondary button-done"
          (click)="onSave()"
          [disabled]="!integrationSettingsValid || blockSave"
        >
          <i nz-icon nzType="check"></i>
          {{ integrationExists ? "Save" : "Install" }}
        </button>
      </li>
    </ul>
  </div>
  <div
    class="integration__right {{
      integrationSpec.haveNoGlobalSettings ? 'is-full' : ''
    }}"
  >
    <div class="integration__doc">
      <header>
        <a [href]="integrationSpec.url" target="_blank">
          <img
            class="integration__doc-logo"
            alt="{{ integrationSpec.name }}"
            [src]="integrationSpec.imageSrc"
          />
        </a>
      </header>
      <markdown
        class="markdown-body"
        [src]="'/docs/' + integrationSpec.type + '/index.md'"
      ></markdown>
    </div>
    <ul *ngIf="integrationSpec.haveNoGlobalSettings" class="global-actions">
      <li *ngIf="integrationExists">
        <button
          title="Remove Integration"
          nz-button
          nzType="default"
          nzSize="large"
          nzDanger
          (click)="showRemoveConfirm()"
        >
          <i nz-icon nzType="delete"></i>
        </button>
      </li>
      <li *ngIf="!integrationSpec.autoSave">
        <button
          title="{{ integrationExists ? 'Save' : 'Install' }}"
          nz-button
          nzType="default"
          nzSize="large"
          class="ant-btn-secondary button-done"
          (click)="onSave()"
          [disabled]="!integrationSettingsValid || blockSave"
        >
          <i nz-icon nzType="check"></i>
          {{ integrationExists ? "Save" : "Install" }}
        </button>
      </li>
    </ul>
  </div>
</div>
