<ng-container>
  <nz-tabset
    class="help-container"
    nzTabPosition="left"
    [(nzSelectedIndex)]="selectedIndex"
  >
    <nz-tab [nzTitle]="whattodo">
      <div class="video-container">
        <div class="video-text">
          Screeb has been designed to help you with your Continuous Product
          Discovery, and especially when you want to
          <span class="bold">{{ accountGoalsToLabel[goal] }}</span>
          .
          <br />
          <br />
          Start strong from Day 1 with Screeb by following our quick tech
          checklist. This guide will walk you through everything you need to get
          set up, from installation to tracking user events, so you can gain
          insights and start seeing impact immediately.
          <br />
          <br />
          With Screeb, join leading brands like Doctolib, Nespresso, Pennylane,
          and Sosh in uncovering the feedback that fuels a better user
          experience. Ready to make the most of your platform? Let’s dive into
          the essentials!
        </div>
        <div class="video-card">
          <card-101-link
            title="Day 1 with Screeb : Tech check list"
            color="#FFF6EA"
            url="https://help.screeb.app/en/articles/8611565-day-1-with-screeb-tech-check-list"
            (click)="handleArticleClick()"
          ></card-101-link>
        </div>
      </div>
    </nz-tab>

    <nz-tab [nzTitle]="installScreeb">
      <div class="bloc-install-screeb">
        <img
          src="/assets/survey-templates/product-market-fit.png"
          alt="Install Screeb"
        />
        <p>Install the Screeb SDK now</p>
        <a
          class="add"
          routerLink="/org/{{ org?.id }}/settings/install"
          routerLinkActive="active"
          nz-button
          nzSize="large"
          nzType="primary"
        >
          <i nz-icon nzType="plus"></i>
          Install Screeb
        </a>
      </div>
    </nz-tab>

    <nz-tab [nzTitle]="launch">
      <div class="bloc-create-survey">
        <img src="/assets/survey-templates/ask.png" alt="New survey" />
        <p>Start creating your new survey now</p>
        <a
          *ngIf="'survey.create' | hasPermission"
          class="add"
          routerLink="/org/{{ org?.id }}/survey/create"
          routerLinkActive="active"
          nz-button
          nzSize="large"
          nzType="primary"
        >
          <i nz-icon nzType="plus"></i>
          Create new survey
        </a>
      </div>
    </nz-tab>

    <nz-tab [nzTitle]="invite">
      <div>
        The more people in your team will use Screeb, the more efficient you
        will be! Invite your team mates, collaborate on surveys and reports,
        share your learnings and become better at Product Discovery together!
        <br />
        <br />
        <span class="bold">
          In average, companies save 10,04 days of Product Discovery per month
          when their whole product team is using Screeb!
        </span>

        <invite-team-members
          scope="quickstart"
          [loading]="invitationLoading"
          (submitInvitations)="onSubmitInvitations($event)"
        ></invite-team-members>
      </div>
    </nz-tab>

    <nz-tab [nzTitle]="slack">
      <div class="bloc-install-slack">
        <img
          alt="Install Slack"
          src="/assets/illustrations/quickstart-install-slack.png"
        />
        <p>
          Install our Slack integration and receive your responses and charts
          right in your preferred channels.
        </p>
        <button
          title="Install integration"
          nz-button
          nzType="default"
          nzSize="large"
          (click)="onInstallSlackIntegrationClick()"
          [nzLoading]="installSlackIntegrationLoading"
        >
          <screeb-icon size="sm" icon="live"></screeb-icon>
          <span>Install integration</span>
        </button>
      </div>
    </nz-tab>
    <nz-tab [nzTitle]="chatWithUs">
      <div nz-col nzXs="24" class="bloc-not-ready">
        <h3>Not ready to start?</h3>

        <section nz-row [nzGutter]="[16, 16]">
          <div nz-col nzXs="12">
            <button
              routerLink="/org/{{ org?.id }}/onboarding/book-demo"
              title="Book a call with us"
              nz-button
              nzType="default"
              nzSize="large"
            >
              <screeb-icon size="sm" icon="talk-to-a-human"></screeb-icon>
              <span>Book a call with us</span>
            </button>
          </div>
          <div nz-col nzXs="12">
            <a
              href="https://developers.screeb.app"
              target="_blank"
              rel="noopener"
              title="Read developer doc."
              nz-button
              nzType="default"
              nzSize="large"
            >
              <screeb-icon size="sm" icon="dev-documentation"></screeb-icon>
              <span>Read developer doc.</span>
            </a>
          </div>
          <div nz-col nzXs="12">
            <intercom>
              <button
                title="ask-us-a-question"
                nz-button
                nzType="default"
                nzSize="large"
              >
                <screeb-icon size="sm" icon="survey"></screeb-icon>
                <span>Chat with our team</span>
              </button>
            </intercom>
          </div>
          <!-- <div nz-col nzXs="12">
            <button
              title="Work with partner"
              nz-button
              nzType="default"
              nzSize="large"
              [disabled]="true"
            >
              <screeb-icon size="sm" icon="newest-user"></screeb-icon>
              <span>Work with partner</span>
            </button>
          </div> -->
        </section>
      </div>
    </nz-tab>
  </nz-tabset>
</ng-container>

<ng-template #whattodo>
  <div class="template-container">
    <quickstart-task
      [indice]="1"
      [completed]="tasks.presentationDone"
    ></quickstart-task>
    <div class="template-text">
      See what you'll able to do in Screeb to
      <span class="bold">{{ accountGoalsToLabel[goal] }}</span>
    </div>
  </div>
</ng-template>

<ng-template #installScreeb>
  <div class="template-container">
    <quickstart-task
      [indice]="2"
      [completed]="tasks.installDone"
    ></quickstart-task>
    <div class="template-text">Install Screeb</div>
  </div>
</ng-template>

<ng-template #launch>
  <div class="template-container">
    <quickstart-task
      [indice]="3"
      [completed]="tasks.launchFirstSurveysDone"
    ></quickstart-task>
    <div class="template-text">Launch your first in-app surveys</div>
  </div>
</ng-template>

<ng-template #invite>
  <div class="template-container">
    <quickstart-task
      [indice]="4"
      [completed]="tasks.inviteTeamMembersDone"
    ></quickstart-task>
    <div class="template-text">Invite your team mates to Screeb</div>
  </div>
</ng-template>

<ng-template #slack>
  <div class="template-container">
    <quickstart-task
      [indice]="5"
      [completed]="tasks.installSlackIntegrationDone"
    ></quickstart-task>
    <div class="template-text">Install our Slack Integration</div>
  </div>
</ng-template>

<ng-template #chatWithUs>
  <div class="template-container">
    <quickstart-task [indice]="6"></quickstart-task>
    <div class="template-text">Chat with our team</div>
  </div>
</ng-template>
