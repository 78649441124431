import { Component, Input } from "@angular/core";

@Component({
  selector: "hubspot",
  template: `
    <a [href]="href" target="_blank" rel="noopener">
      <ng-content></ng-content>
    </a>
  `,
  styles: [
    `
      :host {
        cursor: pointer;
      }

      a {
        display: inherit;
        color: inherit;
        font-style: inherit;
        font-weight: inherit;
        font-size: inherit;
      }
    `,
  ],
})
export class HubspotComponent {
  @Input() public href: string = "https://meetings.hubspot.com/salome-cousseau";

  constructor() {}
}
