<div
  class="picker"
  nz-popover
  [nzPopoverContent]="contentTemplate"
  nzPopoverPlacement="bottomRight"
  nzPopoverTrigger="click"
>
  <div class="color" [style.background-color]="color"></div>
  <input
    (click)="$event.stopPropagation()"
    class="input"
    type="text"
    (ngModelChange)="onColorChange($event)"
    [(ngModel)]="color"
    (blur)="onBlur()"
  />
  <i nz-icon class="icon" nzType="caret-down" nzTheme="outline"></i>

  <ng-template #contentTemplate>
    <chrome-picker
      style="box-shadow: none"
      [(color)]="color"
      [control]="chromeControl"
      (colorChange)="onColorChange($event)"
    ></chrome-picker>
  </ng-template>
</div>
