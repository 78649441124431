import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { GraphNode } from "components/builder/flow";
import { decode } from "html-entities";
import {
  ActionSkip,
  CTARange,
  SurveyLanguages,
  getI18nTextLabelTranslation,
  newCTASkip,
  setI18nTextLabelTranslation,
} from "models/survey.dao.types";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { BuilderStore } from "stores/builder.store";

@Component({
  selector: "lateral-panel-edit-range",
  templateUrl: "./edit-range.component.html",
  styleUrls: ["./edit-range.component.scss"],
})
export class LateralPanelEditRangeComponent implements OnInit {
  @Input() node: GraphNode = null;
  @Input() public language: SurveyLanguages = "en";
  @Output() errorChange = new EventEmitter<boolean>();

  public emojiPickerVisible = false;
  public emojiPickerPositionTop = 0;
  public emojiPickerPositionRight = 0;

  constructor(
    public builderStore: BuilderStore,
    public featureFlaggingService: FeatureFlaggingService,
  ) {}

  public ngOnInit() {
    if (!["range"].includes(this.node.node.question.call_to_action.type)) {
      throw Error("unexpected action type for range");
    }

    this.validateData();
  }

  public get cta(): CTARange {
    const cta = this.node.node.question.call_to_action;
    if (!["range"].includes(cta.type)) {
      throw Error("unexpected action type for range");
    }
    return cta as CTARange;
  }

  public get skipAction(): ActionSkip {
    return (this.node.node.question.skip_action ??= newCTASkip());
  }

  /**
   * Emoji picker
   */
  public openEmojiPicker(btnElement: HTMLElement) {
    const buttonRect = btnElement.getBoundingClientRect();

    this.emojiPickerPositionTop = buttonRect.top + window.pageYOffset - 20;
    this.emojiPickerPositionRight =
      window.innerWidth - buttonRect.left + window.pageXOffset + 10;
    this.emojiPickerVisible = true;
  }

  public getLabelLegendMin(): string {
    const cta = this.node.node.question.call_to_action as CTARange;
    return decode(
      getI18nTextLabelTranslation(
        cta.legend_min || {},
        this.language,
        this.language,
      ) || "",
    );
  }

  public setLabelLegendMin(text: string) {
    const cta = this.node.node.question.call_to_action as CTARange;
    cta.legend_min ??= {};
    setI18nTextLabelTranslation(cta.legend_min, text, this.language);
  }

  public getLabelLegendMax(): string {
    const cta = this.node.node.question.call_to_action as CTARange;
    return decode(
      getI18nTextLabelTranslation(
        cta.legend_max || {},
        this.language,
        this.language,
      ) || "",
    );
  }

  public setLabelLegendMax(text: string) {
    const cta = this.node.node.question.call_to_action as CTARange;
    cta.legend_max ??= {};
    setI18nTextLabelTranslation(cta.legend_max, text, this.language);
  }

  /**
   * Data validation
   */
  public validateData() {
    this.errorChange.emit(false);
  }
}
