<header>
  <free-trial-banner />
  <div class="header-top-line">
    <div>
      <div class="hello-message">
        <org-account-avatar
          [account]="sessionService.session"
          size="small"
          [ctaWhenNoPicture]="true"
        ></org-account-avatar>
        <div class="hello-message-text">
          <span>
            {{ helloMessage }}
          </span>
          <span class="hello-message-name">
            {{ sessionService.session?.firstname }}
          </span>
          <span>!</span>
        </div>
      </div>
    </div>

    <div class="right">
      <div class="dashboard-edit">
        <org-account-avatar-list
          [accounts]="orgAccounts"
          [maxDisplayed]="8"
          theme="dark"
          size="medium"
        ></org-account-avatar-list>
        <button
          *ngIf="'org.update' | hasSuperPermission"
          class="add-widget-button"
          nz-button
          nzType="{{ !editingDashboard ? 'primary' : 'default' }}"
          nzSize="medium"
          (click)="setEditingDashboard()"
        >
          <screeb-icon *ngIf="!editingDashboard" size="sm" icon="edit" />
          <screeb-icon *ngIf="editingDashboard" size="sm" icon="cross" />
        </button>
      </div>
      <div class="range-picker" *ngIf="!editingDashboard">
        <button nz-button nz-dropdown [nzDropdownMenu]="menu">
          Range period:
          {{ gridRangeNames[this.orgReport.range ?? "month"] }}
          <screeb-icon size="md" icon="arrow-bottom"></screeb-icon>
        </button>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu nzSelectable>
            <!-- <li nz-menu-item (click)="changeRange('day')">Day</li> -->
            <li nz-menu-item (click)="changeRange('week')">Week</li>
            <li nz-menu-item (click)="changeRange('month')">Month</li>
            <li nz-menu-item (click)="changeRange('quarter')">Quarter</li>
            <li nz-menu-item (click)="changeRange('year')">Year</li>
          </ul>
        </nz-dropdown-menu>
      </div>
      <div *ngIf="editingDashboard" class="grid-actions">
        <button
          class="add-widget-button"
          nz-button
          nzType="default"
          nzSize="medium"
          (click)="resetHomeGrid()"
        >
          <span>Reset&nbsp;</span>
          <screeb-icon size="sm" icon="reset" />
        </button>
        <button
          class="add-widget-button"
          nz-button
          nzType="primary"
          nzSize="medium"
          (click)="isAddWidgetOpen = true"
        >
          <span>Add widget&nbsp;</span>
          <screeb-icon size="sm" icon="plus" />
        </button>
        <button
          class="add-widget-button"
          nz-button
          nzType="primary"
          nzSize="medium"
          (click)="onSave()"
        >
          <span>Save&nbsp;</span>
          <screeb-icon size="sm" icon="save" />
        </button>
      </div>
    </div>
  </div>
</header>

<div class="grid-container" [ngClass]="gridVisible ? 'visible' : 'not-visible'">
  <div class="grid">
    <div
      *ngFor="let n of items; let i = $index"
      [id]="n.id"
      class="item"
      [ngClass]="'item-' + n.w"
      [ngStyle]="{
        height: n.h ? 'calc(' + n.h + ' - 10px)' : '',
      }"
    >
      <div class="item-content">
        <widget-wrapper
          [editing]="editingDashboard"
          [type]="n.id"
          [range]="this.orgReport.range ?? 'month'"
          (deleteClicked)="removeWidget(n)"
          [org]="org"
          [orgHasRespondents]="orgHasRespondents"
          [surveys]="surveys"
        ></widget-wrapper>
      </div>
    </div>
  </div>
</div>
<!--<gridstack #grid [options]="gridOptions" (changeCB)="onChange($event)">-->
<!--  <nz-empty-->
<!--    *ngIf="!items.length"-->
<!--    nzNotFoundContent="Empty home, please add widgets to your dashboard"-->
<!--  ></nz-empty>-->

<!--  <gridstack-item *ngFor="let n of items; trackBy: identify" [options]="n">-->
<!--    <widget-wrapper-->
<!--      [editing]="editingDashboard"-->
<!--      [type]="n.id"-->
<!--      [range]="uiService.currentOrg?.flags?.home_grid?.range ?? 'month'"-->
<!--      (deleteClicked)="removeWidget(n)"-->
<!--      [org]="org"-->
<!--      [orgHasRespondents]="orgHasRespondents"-->
<!--      [surveys]="surveys"-->
<!--    ></widget-wrapper>-->
<!--  </gridstack-item>-->
<!--</gridstack>-->

<nz-modal
  [(nzVisible)]="isAddWidgetOpen"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  [nzClosable]="false"
  nzCentered
  (nzOnCancel)="isAddWidgetOpen = false"
  nzWidth="650px"
  nzClassName="add-widget-modal"
>
  <ng-template #modalTitle></ng-template>

  <ng-template #modalContent>
    <div class="card-menu-popin-container">
      <div class="card-menu-popin">
        <div class="card-menu-popin__left">
          <div class="card-menu-popin-items">
            <label class="card-menu-popin-title">Add widget</label>
            <button
              *ngFor="let widgetSpec of widgetsSpecs"
              [disabled]="isItemAlreadyAdded(widgetSpec.type)"
              class="card-menu-popin-item"
              [class]="{ focused: widgetSpec.type === focusedType?.type }"
              [style.background]="widgetSpec.backgroundColor"
              (mouseenter)="onFocusType(widgetSpec)"
              (click)="addWidget(widgetSpec)"
            >
              <div class="card-menu-popin-item__icon">
                <card-type-icons
                  *ngIf="widgetSpec.type === 'nps' || widgetSpec.type === 'ces'"
                  [questionType]="widgetSpec.type"
                  [style.display]="'flex'"
                ></card-type-icons>
                <screeb-icon
                  *ngIf="widgetSpec.type !== 'nps' && widgetSpec.type !== 'ces'"
                  [size]="widgetSpec.iconSize"
                  [icon]="widgetSpec.icon"
                ></screeb-icon>
              </div>

              <div class="card-menu-popin-item-label">
                <div class="card-menu-popin-item-label__title">
                  <span>{{ widgetSpec.title }}</span>
                </div>
              </div>
            </button>
          </div>
        </div>
        <div
          *ngIf="focusedType"
          class="card-menu-popin__right"
          [style.background]="focusedType.backgroundColor"
        >
          <p>{{ focusedType.description }}</p>
          <img
            alt="{{ focusedType.title }}"
            [src]="'/assets/illustrations/widgets/' + focusedType.type + '.png'"
          />
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #modalFooter></ng-template>
</nz-modal>
