import { Serializable } from "models/serializable.model";
import { RegistryEntry, RegistryEntryType } from "./registry.model";
import { UUID } from "./survey.dao.types";
import { DistributionInteraction } from "./survey-distribution.model";
import { ChannelType } from "./channel.model";

type TargetingRuleType =
  | "url"
  | "device"
  | "sdk_platform"
  | "language"
  | "country"
  | "delay"
  | "session_duration"
  | "scroll"
  | "scroll_reach_element"
  | "dom_element"
  | "intent_leave_page"
  | "visitor_property"
  | "visitor_event_time"
  | "visitor_event_count"
  | "visitor_event_sequence"
  | "visitor_group"
  | "visitor_answered_survey"
  | "visitor_alias"
  | "device_visitor_property"
  | "device_visitor_event_time"
  | "device_visitor_event_count"
  | "device_visitor_event_sequence"
  | "capping_org_display"
  | "capping_org_response"
  | "capping_survey_display"
  | "capping_survey_response"
  | "capping_respondent_display"
  | "capping_respondent_response"
  | "capping_respondent_time_between_survey_display"
  | "screen"
  | "app_version"
  | "multiple"
  | "recurrence"
  | "recurrence_not_answered"
  | "start_date"
  | "end_date";

export const deviceTrackingTargetingRuleTypes: TargetingRuleType[] = [
  "device_visitor_property",
  "device_visitor_event_time",
  "device_visitor_event_count",
  "device_visitor_event_sequence",
];

export const orgLevelTargetingRuleTypes: TargetingRuleType[] = [
  "visitor_alias",
  "visitor_property",
  "visitor_group",
  "visitor_answered_survey",
  "visitor_event_time",
  "visitor_event_count",
  "device_visitor_property",
  "device_visitor_event_time",
  "device_visitor_event_count",
  "capping_org_display",
  "capping_org_response",
  "capping_survey_display",
  "capping_survey_response",
  "capping_respondent_display",
  "capping_respondent_response",
  "capping_respondent_time_between_survey_display",
];

export const cappingTargetingRuleTypes: TargetingRuleType[] = [
  "capping_org_display",
  "capping_org_response",
  "capping_survey_display",
  "capping_survey_response",
  "capping_respondent_display",
  "capping_respondent_response",
  "capping_respondent_time_between_survey_display",
];

export const TargetingRuleTypes: TargetingRuleType[] = [
  ...cappingTargetingRuleTypes,
  "multiple",
  "recurrence",
  "recurrence_not_answered",
];

export const orgLevelTargetingRuleTypesExceptCappingRules =
  orgLevelTargetingRuleTypes.filter(
    (type) => !cappingTargetingRuleTypes.includes(type),
  );

type TargetingRuleTypeGroup =
  | "Navigation"
  | "Device"
  | "Tracking"
  | "Response"
  | "Capping"
  | "Other";

type TargetingRuleOperator =
  | "loaded"
  | "not_loaded"
  | "equal"
  | "not equal"
  | "contains"
  | "not contains"
  | "gt"
  | "lt"
  | "date_relative_before"
  | "date_relative_after"
  | "date_absolute_before"
  | "date_absolute_after";

type TargetingRuleInfo = {
  title: string;
  type: TargetingRuleType;
  group: TargetingRuleTypeGroup | null;
  allowedChannel: ChannelType[];
  allowedInteraction: DistributionInteraction[];

  // removable ?
  removable: boolean;
  // can i have a 'multiple' rule twice ?
  atMostOnce: boolean;
  // can i see the operator ?
  visibleOperator: boolean;
  // if false, we cannot fallback to wizard mode
  wizardModeEnabled: boolean;
  // if true, we can only use this rule in mobile sdk, will be ignored in web sdk
  mobileSdkOnly?: boolean;
  // if true, we can only use this rule in web sdk, will be ignored in mobile sdk
  webSdkOnly?: boolean;
};

type TargetingRuleOperatorInfo = {
  title: string;
  type: TargetingRuleOperator;
  group: string | null;
};

const targetingRulesTypesAndLabels: {
  [key in TargetingRuleType]: TargetingRuleInfo;
} = {
  url: {
    title: "URL",
    type: "url",
    group: "Navigation",
    allowedChannel: ["widget"],
    allowedInteraction: ["in_app", "in_page", "feedback_button"],
    removable: true,
    atMostOnce: false,
    visibleOperator: true,
    wizardModeEnabled: true,
    webSdkOnly: true,
  },
  screen: {
    title: "Screen",
    type: "screen",
    group: "Navigation",
    allowedChannel: ["android", "ios"],
    allowedInteraction: ["in_app", "in_page", "feedback_button"],
    removable: true,
    atMostOnce: false,
    visibleOperator: true,
    wizardModeEnabled: true,
    mobileSdkOnly: true,
  },
  delay: {
    title: "Delay",
    type: "delay",
    group: "Navigation",
    allowedChannel: ["widget", "android", "ios"],
    allowedInteraction: ["in_app", "in_page", "feedback_button"],
    removable: true,
    atMostOnce: true,
    visibleOperator: false,
    wizardModeEnabled: true,
  },
  session_duration: {
    title: "Session duration",
    type: "session_duration",
    group: "Navigation",
    allowedChannel: ["widget", "android", "ios"],
    allowedInteraction: ["in_app", "in_page", "feedback_button"],
    removable: true,
    atMostOnce: true,
    visibleOperator: false,
    wizardModeEnabled: true,
  },
  scroll: {
    title: "Scroll",
    type: "scroll",
    group: "Navigation",
    allowedChannel: ["widget"],
    allowedInteraction: ["in_app", "in_page", "feedback_button"],
    removable: true,
    atMostOnce: true,
    visibleOperator: true,
    wizardModeEnabled: false,
  },
  scroll_reach_element: {
    title: "Reached an element",
    type: "scroll_reach_element",
    group: "Navigation",
    allowedChannel: ["widget"],
    allowedInteraction: ["in_app", "in_page", "feedback_button"],
    removable: true,
    atMostOnce: true,
    visibleOperator: false,
    wizardModeEnabled: false,
  },
  dom_element: {
    title: "DOM element",
    type: "dom_element",
    group: "Navigation",
    allowedChannel: ["widget"],
    allowedInteraction: ["in_app", "in_page", "feedback_button"],
    removable: true,
    atMostOnce: false,
    visibleOperator: false,
    wizardModeEnabled: false,
  },
  intent_leave_page: {
    title: "Intent to leave page",
    type: "intent_leave_page",
    group: "Navigation",
    allowedChannel: ["widget"],
    allowedInteraction: ["in_app", "in_page", "feedback_button"],
    removable: true,
    atMostOnce: true,
    visibleOperator: false,
    wizardModeEnabled: false,
  },
  app_version: {
    title: "App Version",
    type: "app_version",
    group: "Device",
    allowedChannel: ["android", "ios"],
    allowedInteraction: ["in_app", "in_page", "feedback_button"],
    removable: true,
    atMostOnce: true,
    visibleOperator: true,
    wizardModeEnabled: false,
  },
  device: {
    title: "Device",
    type: "device",
    group: "Device",
    allowedChannel: ["widget", "android", "ios"],
    allowedInteraction: ["in_app", "in_page", "feedback_button"],
    removable: false,
    atMostOnce: true,
    visibleOperator: false,
    wizardModeEnabled: true,
  },
  sdk_platform: {
    title: "SDK Platform",
    type: "sdk_platform",
    group: "Device",
    allowedChannel: ["widget", "android", "ios"],
    allowedInteraction: ["in_app", "in_page", "feedback_button"],
    removable: true,
    atMostOnce: true,
    visibleOperator: false,
    wizardModeEnabled: false,
  },
  language: {
    title: "Language",
    type: "language",
    group: "Device",
    allowedChannel: ["widget", "android", "ios"],
    allowedInteraction: ["in_app", "in_page", "feedback_button"],
    removable: true,
    atMostOnce: false,
    visibleOperator: true,
    wizardModeEnabled: false,
  },
  country: {
    title: "Country",
    type: "country",
    group: "Device",
    allowedChannel: ["widget", "android", "ios"],
    allowedInteraction: ["in_app", "in_page", "feedback_button"],
    removable: true,
    atMostOnce: false,
    visibleOperator: true,
    wizardModeEnabled: false,
  },
  visitor_alias: {
    title: "User identifier",
    type: "visitor_alias",
    group: "Tracking",
    allowedChannel: ["widget", "android", "ios"],
    allowedInteraction: ["in_app", "in_page", "feedback_button"],
    removable: true,
    atMostOnce: false,
    visibleOperator: true,
    wizardModeEnabled: false,
  },
  visitor_property: {
    title: "User Property",
    type: "visitor_property",
    group: "Tracking",
    allowedChannel: ["widget", "android", "ios"],
    allowedInteraction: ["in_app", "in_page", "feedback_button"],
    removable: true,
    atMostOnce: false,
    visibleOperator: true,
    wizardModeEnabled: false,
  },
  visitor_event_time: {
    title: "User Event Time",
    type: "visitor_event_time",
    group: "Tracking",
    allowedChannel: ["widget", "android", "ios"],
    allowedInteraction: ["in_app", "in_page", "feedback_button"],
    removable: true,
    atMostOnce: false,
    visibleOperator: true,
    wizardModeEnabled: false,
  },
  visitor_event_count: {
    title: "User Event Count",
    type: "visitor_event_count",
    group: "Tracking",
    allowedChannel: ["widget", "android", "ios"],
    allowedInteraction: ["in_app", "in_page", "feedback_button"],
    removable: true,
    atMostOnce: false,
    visibleOperator: true,
    wizardModeEnabled: false,
  },
  visitor_event_sequence: {
    title: "User Event Sequence",
    type: "visitor_event_sequence",
    group: "Tracking",
    allowedChannel: ["widget", "android", "ios"],
    allowedInteraction: ["in_app", "in_page", "feedback_button"],
    removable: true,
    atMostOnce: false,
    visibleOperator: true,
    wizardModeEnabled: false,
  },
  visitor_group: {
    title: "User Segment",
    type: "visitor_group",
    group: "Tracking",
    allowedChannel: ["widget", "android", "ios"],
    allowedInteraction: ["in_app", "in_page", "feedback_button"],
    removable: true,
    atMostOnce: false,
    visibleOperator: true,
    wizardModeEnabled: false,
  },
  device_visitor_property: {
    title: "Device User Property",
    type: "device_visitor_property",
    group: "Tracking",
    allowedChannel: ["widget", "android", "ios"],
    allowedInteraction: ["in_app", "in_page", "feedback_button"],
    removable: true,
    atMostOnce: false,
    visibleOperator: true,
    wizardModeEnabled: false,
  },
  device_visitor_event_time: {
    title: "Device User Event Time",
    type: "device_visitor_event_time",
    group: "Tracking",
    allowedChannel: ["widget", "android", "ios"],
    allowedInteraction: ["in_app", "in_page", "feedback_button"],
    removable: true,
    atMostOnce: false,
    visibleOperator: true,
    wizardModeEnabled: false,
  },
  device_visitor_event_count: {
    title: "Device User Event Count",
    type: "device_visitor_event_count",
    group: "Tracking",
    allowedChannel: ["widget", "android", "ios"],
    allowedInteraction: ["in_app", "in_page", "feedback_button"],
    removable: true,
    atMostOnce: false,
    visibleOperator: true,
    wizardModeEnabled: false,
  },
  device_visitor_event_sequence: {
    title: "Device User Event Sequence",
    type: "device_visitor_event_sequence",
    group: "Tracking",
    allowedChannel: ["widget", "android", "ios"],
    allowedInteraction: ["in_app", "in_page", "feedback_button"],
    removable: true,
    atMostOnce: false,
    visibleOperator: true,
    wizardModeEnabled: false,
  },
  visitor_answered_survey: {
    title: "User Answered Survey",
    type: "visitor_answered_survey",
    group: "Response",
    allowedChannel: ["widget", "android", "ios"],
    allowedInteraction: ["in_app", "in_page", "feedback_button"],
    removable: true,
    atMostOnce: false,
    visibleOperator: true,
    wizardModeEnabled: false,
  },
  capping_org_display: {
    title: "Capping Org Display",
    type: "capping_org_display",
    group: "Capping",
    allowedChannel: [],
    allowedInteraction: [],
    removable: true,
    atMostOnce: false,
    visibleOperator: false,
    wizardModeEnabled: false,
  },
  capping_org_response: {
    title: "Capping Org Response",
    type: "capping_org_response",
    group: "Capping",
    allowedChannel: [],
    allowedInteraction: [],
    removable: true,
    atMostOnce: false,
    visibleOperator: false,
    wizardModeEnabled: false,
  },
  capping_survey_display: {
    title: "Capping Survey Display",
    type: "capping_survey_display",
    group: "Capping",
    allowedChannel: [],
    allowedInteraction: [],
    removable: true,
    atMostOnce: false,
    visibleOperator: false,
    wizardModeEnabled: true,
  },
  capping_survey_response: {
    title: "Capping Survey Response",
    type: "capping_survey_response",
    group: "Capping",
    allowedChannel: [],
    allowedInteraction: [],
    removable: true,
    atMostOnce: false,
    visibleOperator: false,
    wizardModeEnabled: true,
  },
  capping_respondent_display: {
    title: "Capping Respondent Display",
    type: "capping_respondent_display",
    group: "Capping",
    allowedChannel: [],
    allowedInteraction: [],
    removable: true,
    atMostOnce: false,
    visibleOperator: false,
    wizardModeEnabled: false,
  },
  capping_respondent_response: {
    title: "Capping Respondent Response",
    type: "capping_respondent_response",
    group: "Capping",
    allowedChannel: [],
    allowedInteraction: [],
    removable: true,
    atMostOnce: false,
    visibleOperator: false,
    wizardModeEnabled: false,
  },
  capping_respondent_time_between_survey_display: {
    title: "Capping Respondent Time Between Survey Display",
    type: "capping_respondent_time_between_survey_display",
    group: "Capping",
    allowedChannel: [],
    allowedInteraction: [],
    removable: true,
    atMostOnce: false,
    visibleOperator: false,
    wizardModeEnabled: false,
  },
  multiple: {
    title: "Multiple responses",
    type: "multiple",
    group: "Other",
    allowedChannel: ["widget", "android", "ios"],
    allowedInteraction: ["in_app", "in_page", "feedback_button"],
    removable: false,
    atMostOnce: true,
    visibleOperator: false,
    wizardModeEnabled: true,
  },
  recurrence: {
    title: "Recurrent Survey",
    type: "recurrence",
    group: "Other",
    allowedChannel: ["widget", "android", "ios"],
    allowedInteraction: ["in_app", "in_page", "feedback_button"],
    removable: true,
    atMostOnce: true,
    visibleOperator: false,
    wizardModeEnabled: true,
  },
  recurrence_not_answered: {
    title: "Recurrent Survey not answered",
    type: "recurrence_not_answered",
    group: "Other",
    allowedChannel: ["widget", "android", "ios"],
    allowedInteraction: ["in_app", "in_page", "feedback_button"],
    removable: true,
    atMostOnce: true,
    visibleOperator: false,
    wizardModeEnabled: false,
  },
  start_date: {
    title: "Start Date",
    type: "start_date",
    group: "Other",
    allowedChannel: ["widget", "android", "ios"],
    allowedInteraction: ["in_app", "in_page", "feedback_button"],
    removable: true,
    atMostOnce: true,
    visibleOperator: false,
    wizardModeEnabled: false,
  },
  end_date: {
    title: "End Date",
    type: "end_date",
    group: "Other",
    allowedChannel: ["widget", "android", "ios"],
    allowedInteraction: ["in_app", "in_page", "feedback_button"],
    removable: true,
    atMostOnce: true,
    visibleOperator: false,
    wizardModeEnabled: false,
  },
};

const targetingRulesOperatorAndLabels: {
  [key in TargetingRuleOperator]: TargetingRuleOperatorInfo;
} = {
  ["loaded"]: {
    title: "Loaded",
    type: "loaded",
    group: null,
  },
  ["not_loaded"]: {
    title: "Not loaded",
    type: "not_loaded",
    group: null,
  },
  ["equal"]: {
    title: "Is",
    type: "equal",
    group: null,
  },
  ["not equal"]: {
    title: "Is not",
    type: "not equal",
    group: null,
  },
  ["contains"]: {
    title: "Contains",
    type: "contains",
    group: null,
  },
  ["not contains"]: {
    title: "Does not contain",
    type: "not contains",
    group: null,
  },
  ["gt"]: {
    title: "Greater than",
    type: "gt",
    group: null,
  },
  ["lt"]: {
    title: "Less than",
    type: "lt",
    group: null,
  },
  ["date_relative_before"]: {
    title: "More than",
    type: "date_relative_before",
    group: "Relative date",
  },
  ["date_relative_after"]: {
    title: "Less than",
    type: "date_relative_after",
    group: "Relative date",
  },
  ["date_absolute_before"]: {
    title: "Before than",
    type: "date_absolute_before",
    group: null,
  },
  ["date_absolute_after"]: {
    title: "After than",
    type: "date_absolute_after",
    group: null,
  },
};

const targetingRulesTypesToOperator: {
  [key in TargetingRuleType]: TargetingRuleOperatorInfo[];
} = {
  url: [
    targetingRulesOperatorAndLabels["equal"],
    targetingRulesOperatorAndLabels["not equal"],
    targetingRulesOperatorAndLabels["contains"],
    targetingRulesOperatorAndLabels["not contains"],
  ],
  device: [
    targetingRulesOperatorAndLabels["equal"],
    targetingRulesOperatorAndLabels["not equal"],
  ],
  sdk_platform: [
    targetingRulesOperatorAndLabels["equal"],
    targetingRulesOperatorAndLabels["not equal"],
  ],
  language: [
    targetingRulesOperatorAndLabels["equal"],
    targetingRulesOperatorAndLabels["not equal"],
  ],
  country: [
    targetingRulesOperatorAndLabels["equal"],
    targetingRulesOperatorAndLabels["not equal"],
  ],
  scroll: [
    targetingRulesOperatorAndLabels["gt"],
    targetingRulesOperatorAndLabels["equal"],
  ],
  scroll_reach_element: [],
  dom_element: [
    targetingRulesOperatorAndLabels["loaded"],
    targetingRulesOperatorAndLabels["not_loaded"],
  ],
  intent_leave_page: [],
  delay: [],
  session_duration: [],
  visitor_property: [
    targetingRulesOperatorAndLabels["equal"],
    targetingRulesOperatorAndLabels["not equal"],
    targetingRulesOperatorAndLabels["contains"],
    targetingRulesOperatorAndLabels["not contains"],
    targetingRulesOperatorAndLabels["gt"],
    targetingRulesOperatorAndLabels["lt"],
    targetingRulesOperatorAndLabels["date_relative_before"],
    targetingRulesOperatorAndLabels["date_relative_after"],
  ],
  visitor_event_time: [
    targetingRulesOperatorAndLabels["date_relative_before"],
    targetingRulesOperatorAndLabels["date_relative_after"],
  ],
  visitor_event_count: [
    targetingRulesOperatorAndLabels["gt"],
    targetingRulesOperatorAndLabels["lt"],
    targetingRulesOperatorAndLabels["equal"],
    targetingRulesOperatorAndLabels["not equal"],
  ],
  visitor_event_sequence: [
    targetingRulesOperatorAndLabels["equal"],
    targetingRulesOperatorAndLabels["not equal"],
  ],
  visitor_group: [
    targetingRulesOperatorAndLabels["equal"],
    targetingRulesOperatorAndLabels["not equal"],
  ],
  visitor_answered_survey: [
    targetingRulesOperatorAndLabels["equal"],
    targetingRulesOperatorAndLabels["not equal"],
  ],
  visitor_alias: [
    targetingRulesOperatorAndLabels["equal"],
    targetingRulesOperatorAndLabels["not equal"],
  ],
  device_visitor_property: [
    targetingRulesOperatorAndLabels["equal"],
    targetingRulesOperatorAndLabels["not equal"],
    targetingRulesOperatorAndLabels["contains"],
    targetingRulesOperatorAndLabels["not contains"],
    targetingRulesOperatorAndLabels["gt"],
    targetingRulesOperatorAndLabels["lt"],
    targetingRulesOperatorAndLabels["date_relative_before"],
    targetingRulesOperatorAndLabels["date_relative_after"],
  ],
  device_visitor_event_time: [
    targetingRulesOperatorAndLabels["date_relative_before"],
    targetingRulesOperatorAndLabels["date_relative_after"],
  ],
  device_visitor_event_count: [
    targetingRulesOperatorAndLabels["gt"],
    targetingRulesOperatorAndLabels["lt"],
    targetingRulesOperatorAndLabels["equal"],
    targetingRulesOperatorAndLabels["not equal"],
  ],
  device_visitor_event_sequence: [
    targetingRulesOperatorAndLabels["equal"],
    targetingRulesOperatorAndLabels["not equal"],
  ],
  capping_org_display: [],
  capping_org_response: [],
  capping_survey_display: [],
  capping_survey_response: [],
  capping_respondent_display: [],
  capping_respondent_response: [],
  capping_respondent_time_between_survey_display: [],
  screen: [
    targetingRulesOperatorAndLabels["equal"],
    targetingRulesOperatorAndLabels["not equal"],
    targetingRulesOperatorAndLabels["contains"],
    targetingRulesOperatorAndLabels["not contains"],
  ],
  app_version: [
    targetingRulesOperatorAndLabels["equal"],
    targetingRulesOperatorAndLabels["not equal"],
  ],
  multiple: [],
  recurrence: [],
  recurrence_not_answered: [],
  start_date: [targetingRulesOperatorAndLabels["date_absolute_after"]],
  end_date: [targetingRulesOperatorAndLabels["date_absolute_before"]],
};

const userPropertiesTypesToOperator: {
  [key in RegistryEntryType]: TargetingRuleOperatorInfo[];
} = {
  string: [
    targetingRulesOperatorAndLabels["equal"],
    targetingRulesOperatorAndLabels["not equal"],
    targetingRulesOperatorAndLabels["contains"],
    targetingRulesOperatorAndLabels["not contains"],
  ],
  number: [
    targetingRulesOperatorAndLabels["gt"],
    targetingRulesOperatorAndLabels["lt"],
    targetingRulesOperatorAndLabels["equal"],
    targetingRulesOperatorAndLabels["not equal"],
  ],
  bool: [
    targetingRulesOperatorAndLabels["equal"],
    targetingRulesOperatorAndLabels["not equal"],
  ],
  time: [
    targetingRulesOperatorAndLabels["date_relative_after"],
    targetingRulesOperatorAndLabels["date_relative_before"],
  ],
  object: [],
};

export class TargetingRuleValue extends Serializable {
  constructor(
    public key_id?: string,
    public name_ids?: string[],
    public v_s?: string,
    public v_n?: number,
    public v_n_p?: number,
    public v_b?: boolean,
    public v_t?: Date,
    public v_s_arr?: string[],
    public v_n_arr?: number[],
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);
    return this;
  }
}

class TargetingRule extends Serializable {
  constructor(
    public survey_distribution_id?: string,
    public org_id?: string,
    public type?: TargetingRuleType,
    public operator?: TargetingRuleOperator,
    public value?: TargetingRuleValue,
    public created_at?: Date,
    public updated_at?: Date,
    public sequence_id?: UUID,
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);

    // @ts-ignore
    this.type = json.type as TargetingRuleType;
    // @ts-ignore
    this.operator = json.operator as TargetingRuleOperator;
    this.value = new TargetingRuleValue().fromJson(json["value"]);

    return this;
  }

  public getValuePlaceholder(): string {
    // lol, we should write morer accurate descriptions here
    if (this.type === "url" && ["equal", "not_equal"].includes(this.operator)) {
      return "Example: https://example.com/product/fork?color=red#reviews";
    } else if (
      this.type === "url" &&
      ["contains", "not_contains"].includes(this.operator)
    ) {
      return "Example: /product/ or https://www.my-website.com/";
    } else if (this.type === "delay") {
      return "5";
    } else if (this.type === "session_duration") {
      return "42";
    } else if (this.type === "scroll") {
      return "50";
    } else if (this.type === "scroll_reach_element") {
      return "Example: #elementId, .elementClass, input#login...";
    } else if (this.type === "dom_element") {
      return "Example: #elementId, .elementClass, input#login...";
    } else if (
      this.type === "visitor_property" ||
      this.type === "device_visitor_property"
    ) {
      return "Example: premium";
    } else if (
      this.type === "visitor_event_time" ||
      this.type === "device_visitor_event_time"
    ) {
      return "5";
    } else if (
      this.type === "visitor_event_count" ||
      this.type === "device_visitor_event_count"
    ) {
      return "5";
    } else if (this.type === "visitor_group") {
      return "Example: Power users, VIP..";
    } else if (this.type === "visitor_answered_survey") {
      return "Example: NPS Global, Effort score..";
    } else if (this.type === "visitor_alias") {
      return "Example: frida@kahlo.fr, 991827..";
    } else if (this.type === "screen") {
      return "Example: HomeScreen";
    } else if (this.type === "recurrence") {
      return "30";
    } else if (this.type === "recurrence_not_answered") {
      return "30";
    }

    return targetingRulesTypesAndLabels[this.type]?.title; // fallback
  }

  // some operators or not availables to types
  public getAvailableOperators() {
    return targetingRulesTypesToOperator[this.type];
  }

  // some operators or not availables to types
  public getDefaultValue(): TargetingRuleValue {
    if (["multiple"].includes(this.type)) {
      return new TargetingRuleValue(
        null,
        null,
        null,
        null,
        null,
        false,
        null,
        null,
        null,
      );
    } else if (this.type === "delay") {
      return new TargetingRuleValue(
        null,
        null,
        null,
        5,
        null,
        null,
        null,
        null,
        null,
      );
    } else if (this.type === "session_duration") {
      return new TargetingRuleValue(
        null,
        null,
        null,
        300,
        null,
        null,
        null,
        null,
        null,
      );
    } else if (this.type === "scroll") {
      return new TargetingRuleValue(
        null,
        null,
        null,
        50,
        null,
        null,
        null,
        null,
        null,
      );
    } else if (this.type === "scroll_reach_element") {
      return new TargetingRuleValue(
        null,
        null,
        "",
        null,
        null,
        null,
        null,
        null,
        null,
      );
    } else if (this.type === "dom_element") {
      return new TargetingRuleValue(
        null,
        null,
        "",
        null,
        null,
        null,
        null,
        null,
        null,
      );
    } else if (this.type === "visitor_property") {
      return new TargetingRuleValue(
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      );
    } else if (this.type === "visitor_event_time") {
      return new TargetingRuleValue(
        null,
        null,
        "latest",
        null,
        600,
        null,
        null,
        null,
        null,
      );
    } else if (this.type === "visitor_event_count") {
      return new TargetingRuleValue(
        null,
        null,
        null,
        0,
        null,
        null,
        null,
        null,
        null,
      );
    } else if (this.type === "visitor_event_sequence") {
      return new TargetingRuleValue(
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        [],
        null,
      );
    } else if (this.type === "visitor_group") {
      return new TargetingRuleValue(
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        [],
        null,
      );
    } else if (this.type === "visitor_answered_survey") {
      return new TargetingRuleValue(
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        [],
        null,
      );
    } else if (this.type === "visitor_alias") {
      return new TargetingRuleValue(
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        [],
        null,
      );
    } else if (this.type === "device_visitor_property") {
      return new TargetingRuleValue(
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      );
    } else if (this.type === "device_visitor_event_time") {
      return new TargetingRuleValue(
        null,
        null,
        "latest",
        null,
        600,
        null,
        null,
        null,
        null,
      );
    } else if (this.type === "device_visitor_event_count") {
      return new TargetingRuleValue(
        null,
        null,
        null,
        0,
        null,
        null,
        null,
        null,
        null,
      );
    } else if (this.type === "device_visitor_event_sequence") {
      return new TargetingRuleValue(
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        [],
        null,
      );
    } else if (this.type === "screen") {
      return new TargetingRuleValue(
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        [],
        null,
      );
    } else if (this.type === "app_version") {
      return new TargetingRuleValue(
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        [],
        null,
      );
    } else if (this.type === "device") {
      return new TargetingRuleValue(
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        ["desktop", "mobile", "tablet"],
        null,
      );
    } else if (this.type === "sdk_platform") {
      return new TargetingRuleValue(
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        ["sdk-js", "sdk-android", "sdk-ios"],
        null,
      );
    } else if (this.type === "url") {
      return new TargetingRuleValue(
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        [""],
        null,
      );
    } else if (this.type === "language") {
      return new TargetingRuleValue(
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        [],
        null,
      );
    } else if (this.type === "country") {
      return new TargetingRuleValue(
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        [],
        null,
      );
    } else if (this.type === "recurrence") {
      return new TargetingRuleValue(
        null,
        null,
        null,
        // Do not change this value, because on page refresh if a "recurrence_not_answered" is present, a new "recurrence" rule will be created with this default value.
        // We should definitely decouple UI and model.
        null,
        null,
        null,
        null,
        null,
        null,
      );
    } else if (this.type === "recurrence_not_answered") {
      return new TargetingRuleValue(
        null,
        null,
        null,
        // Do not change this value, because on page refresh if a "recurrence" is present, a new "recurrence_not_answered" rule will be created with this default value.
        // We should definitely decouple UI and model.
        null,
        null,
        null,
        null,
        null,
        null,
      );
    } else if (this.type === "start_date") {
      return new TargetingRuleValue(
        null,
        null,
        null,
        null,
        null,
        null,
        new Date(),
        null,
        null,
      );
    } else if (this.type === "end_date") {
      return new TargetingRuleValue(
        null,
        null,
        null,
        null,
        null,
        null,
        // Now + 1 week
        new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000),
        null,
        null,
      );
    } else if (this.type === "capping_respondent_time_between_survey_display") {
      return new TargetingRuleValue(
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      );
    } else if (
      [
        "capping_org_display",
        "capping_org_response",
        "capping_survey_display",
        "capping_survey_response",
        "capping_respondent_display",
        "capping_respondent_response",
      ].includes(this.type)
    ) {
      return new TargetingRuleValue(
        null,
        null,
        null,
        null,
        24 * 3600,
        null,
        null,
        null,
        null,
      );
    }
    return new TargetingRuleValue(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    );
  }

  public getUserPropertyValuePlaceHolder(registryEntry: RegistryEntry): string {
    if (!registryEntry) {
      return "";
    }
    switch (registryEntry.type) {
      case "string":
        return "Example: premium";
      case "number":
        return "10";
      case "bool":
        return "Example: false";
      case "time":
        return "10";
      default:
        return "";
    }
  }

  public initRuleValues() {
    this.value.v_s =
      this.value.v_s !== undefined && this.value.v_s !== null
        ? this.value.v_s
        : null;
    this.value.v_n =
      this.value.v_n !== undefined && this.value.v_n !== null
        ? this.value.v_n
        : null;
    this.value.v_n_p =
      this.value.v_n_p !== undefined && this.value.v_n_p !== null
        ? this.value.v_n_p
        : null;
    this.value.v_b =
      this.value.v_b !== undefined && this.value.v_b !== null
        ? this.value.v_b
        : null;
    this.value.v_t =
      this.value.v_t !== undefined && this.value.v_t !== null
        ? this.value.v_t
        : null;
    this.value.v_s_arr =
      this.value.v_s_arr !== undefined && this.value.v_s_arr !== null
        ? this.value.v_s_arr
        : null;
    this.value.v_n_arr =
      this.value.v_n_arr !== undefined && this.value.v_n_arr !== null
        ? this.value.v_n_arr
        : null;
  }

  private resetRuleValues() {
    this.value.v_s = null;
    this.value.v_n = null;
    this.value.v_n_p = null;
    this.value.v_b = null;
    this.value.v_t = null;
    this.value.v_s_arr = null;
    this.value.v_n_arr = null;
  }

  public setDefaultUserPropertyValue(registryEntry: RegistryEntry) {
    this.resetRuleValues();
    switch (registryEntry.type) {
      case "number":
        this.value.v_n = null;
        break;
      case "string":
        this.value.v_s_arr = [""];
        break;
      case "bool":
        this.value.v_b = false;
        break;
      case "time":
        this.value.v_t = new Date();
        break;
      default:
        break;
    }
  }

  public serializeUserPropertyRuleKey(registryEntry: RegistryEntry) {
    this.value.key_id = registryEntry.id;
    this.value.name_ids = null;
    this.operator = userPropertiesTypesToOperator[registryEntry.type][0].type;
    this.setDefaultUserPropertyValue(registryEntry);
  }

  public setNameIds(name_ids: string[]) {
    this.value.key_id = null;
    this.value.name_ids = name_ids;
  }

  public getRegistryEntrySlug(registryEntry: RegistryEntry): string {
    return registryEntry?.slug || "";
  }

  public getRegistryEntryType(registryEntry: RegistryEntry): string {
    return registryEntry?.type || "";
  }

  public getRegistryEntryId(registryEntry: RegistryEntry): string {
    return registryEntry?.id || "";
  }
}

export {
  // models
  TargetingRule,
  // const
  TargetingRuleInfo,
  TargetingRuleOperator,
  // types
  TargetingRuleType,
  targetingRulesOperatorAndLabels,
  targetingRulesTypesAndLabels,
  targetingRulesTypesToOperator,
  userPropertiesTypesToOperator,
};
