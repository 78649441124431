<ng-container *ngIf="rule">
  <div>
    <nz-tag
      [ngClass]="{
        disabled:
          !('survey_edition' | hasEntitlement) ||
          !('survey_inapp_desktop' | hasEntitlement),
      }"
      nzMode="checkable"
      [nzChecked]="rule.value.v_s_arr.indexOf('sdk-js') !== -1"
      (nzCheckedChange)="
        onPlatformChange(rule, 'sdk-js', $event, 'survey_inapp_desktop')
      "
    >
      Web
    </nz-tag>
    <nz-tag
      [ngClass]="{
        disabled:
          !('survey_edition' | hasEntitlement) ||
          !('survey_inapp_mobile' | hasEntitlement),
      }"
      nzMode="checkable"
      [nzChecked]="rule.value.v_s_arr.indexOf('sdk-ios') !== -1"
      (nzCheckedChange)="
        onPlatformChange(rule, 'sdk-ios', $event, 'survey_inapp_mobile')
      "
    >
      iOS
    </nz-tag>
    <nz-tag
      [ngClass]="{
        disabled:
          !('survey_edition' | hasEntitlement) ||
          !('survey_inapp_mobile' | hasEntitlement),
      }"
      nzMode="checkable"
      [nzChecked]="rule.value.v_s_arr.indexOf('sdk-android') !== -1"
      (nzCheckedChange)="
        onPlatformChange(rule, 'sdk-android', $event, 'survey_inapp_mobile')
      "
    >
      Android
    </nz-tag>
  </div>
  <utils-form-error
    *ngIf="rule.value.v_s_arr.length === 0"
    [errors]="['Please select at least 1 platform']"
  ></utils-form-error>
</ng-container>
