import { ChartDataset } from "chart.js";
import { AnalyticsResponse } from "models/analytics.model";
import { SurveyScenario } from "models/survey.dao.types";
import { RepartitionIndicatorValues } from "../components/repartition-indicator/repartition-indicator.component";
import {
  bucketToCountPerKey,
  computeIndicatorVariation,
  computePercentage,
  computeRepartition,
  computeVariation,
  getDateAnswerValueBuckets,
  replaceBucketsKeys,
} from "../indicator.utils";

export type CesAggregation = {
  score: number;
  smallEffort: number;
  mediumEffort: number;
  highEffort: number;
  smallEffortPercent: number;
  mediumEffortPercent: number;
  highEffortPercent: number;
  responsesSum: number;
  total: number;
  date: Date | null;
};

export const cesEmojis = ["1️⃣", "2️⃣", "3️⃣", "4️⃣", "5️⃣", "6️⃣", "7️⃣"];

export function computeCesScore(responsesSum: number, total: number) {
  if (!total) {
    return 0;
  }
  return responsesSum / total;
}

export function computeCesRepartition(
  periodBucket: AnalyticsResponse,
  scenario: SurveyScenario,
): RepartitionIndicatorValues {
  const getColor = (index: number) => {
    const style = window.getComputedStyle(document.body);
    if (index <= 2) {
      return style.getPropertyValue("--screeb-color-green-500");
    } else if (index <= 4) {
      return style.getPropertyValue("--screeb-color-yellow-500");
    }
    return style.getPropertyValue("--screeb-color-red-500");
  };

  const getLabel = (index: number) => {
    return cesEmojis[index];
  };

  return computeRepartition(
    periodBucket,
    ["1", "2", "3", "4", "5", "6", "7"],
    getColor,
    getLabel,
    scenario,
  );
}

export function computeCesPeriod(cesPerDate: CesAggregation[]): CesAggregation {
  const total = cesPerDate.reduce(
    (acc: number, ces: CesAggregation): number => acc + ces.total,
    0,
  );
  const smallEffort = cesPerDate.reduce(
    (acc: number, ces: CesAggregation): number => acc + ces.smallEffort,
    0,
  );
  const mediumEffort = cesPerDate.reduce(
    (acc: number, ces: CesAggregation): number => acc + ces.mediumEffort,
    0,
  );
  const highEffort = cesPerDate.reduce(
    (acc: number, ces: CesAggregation): number => acc + ces.highEffort,
    0,
  );
  const smallEffortPercent = computePercentage(smallEffort, total);
  const mediumEffortPercent = computePercentage(mediumEffort, total);
  const highEffortPercent = computePercentage(highEffort, total);
  const responsesSum = cesPerDate.reduce(
    (acc: number, ces: CesAggregation): number => acc + ces.responsesSum,
    0,
  );
  const score = computeCesScore(responsesSum, total);

  return {
    total,
    smallEffort,
    mediumEffort,
    highEffort,
    smallEffortPercent,
    mediumEffortPercent,
    highEffortPercent,
    responsesSum,
    score,
    date: null,
  };
}

export function computeCesVariationBewteenPeriod(
  previousPeriod: CesAggregation,
  currentPeriod: CesAggregation,
): CesAggregation {
  if (!previousPeriod.total) {
    return null;
  }

  return {
    score: computeVariation(previousPeriod.score, currentPeriod.score),
    smallEffort: computeIndicatorVariation(
      previousPeriod.smallEffort,
      previousPeriod.total,
      currentPeriod.smallEffort,
      currentPeriod.total,
    ),
    mediumEffort: computeIndicatorVariation(
      previousPeriod.mediumEffort,
      previousPeriod.total,
      currentPeriod.mediumEffort,
      currentPeriod.total,
    ),
    highEffort: computeIndicatorVariation(
      previousPeriod.highEffort,
      previousPeriod.total,
      currentPeriod.highEffort,
      currentPeriod.total,
    ),
    smallEffortPercent: computeVariation(
      previousPeriod.smallEffortPercent,
      currentPeriod.smallEffortPercent,
    ),
    mediumEffortPercent: computeVariation(
      previousPeriod.mediumEffortPercent,
      currentPeriod.mediumEffortPercent,
    ),
    highEffortPercent: computeVariation(
      previousPeriod.highEffortPercent,
      currentPeriod.highEffortPercent,
    ),
    responsesSum: computeVariation(
      previousPeriod.responsesSum,
      currentPeriod.responsesSum,
    ),
    total: computeVariation(previousPeriod.total, currentPeriod.total),
    date: null,
  };
}

export function bucketToCesAggregation(
  oneDayCes: Record<string, any>,
  getBuckets = getDateAnswerValueBuckets,
  scenarios: SurveyScenario[],
): CesAggregation {
  const { countPerKey, total } = bucketToCountPerKey(
    replaceBucketsKeys(getBuckets(oneDayCes), scenarios),
    ["1", "2", "3", "4", "5", "6", "7"],
  );

  const date = oneDayCes.date;

  const smallEffort = countPerKey["1"] + countPerKey["2"] + countPerKey["3"];
  const mediumEffort = countPerKey["4"] + countPerKey["5"];
  const highEffort = countPerKey["6"] + countPerKey["7"];

  const smallEffortPercent = computePercentage(smallEffort, total);
  const mediumEffortPercent = computePercentage(mediumEffort, total);
  const highEffortPercent = computePercentage(highEffort, total);

  const responsesSum = Object.values(countPerKey).reduce(
    (sum, count, index) => sum + count * (index + 1),
    0,
  );

  return {
    score: computeCesScore(responsesSum, total),
    smallEffort,
    mediumEffort,
    highEffort,
    smallEffortPercent,
    mediumEffortPercent,
    highEffortPercent,
    responsesSum,
    total,
    date,
  };
}

export function cesPerDateToChartDataset(
  cesPerDate: CesAggregation[],
): ChartDataset[] {
  const cesToData = (
    field:
      | "highEffortPercent"
      | "mediumEffortPercent"
      | "smallEffortPercent"
      | "score",
  ) =>
    cesPerDate.map((ces) => {
      return { x: +ces.date, y: ces[field] };
    });

  const style: ChartDataset = {
    yAxisID: "main",
    fill: "transparent",
    data: [],
  };

  return [
    {
      ...style,
      yAxisID: "secondary",
      data: cesToData("score"),
      label: "CES",
      borderColor: "#5E21F1",
      pointHoverBorderColor: "#FFFFFF",
      pointBackgroundColor: "#5E21F1",
      pointHoverBackgroundColor: "#5E21F1",
    },
    {
      ...style,
      data: cesToData("highEffortPercent"),
      label: "High effort",
      borderColor: "#F11672",
      pointHoverBorderColor: "#FFFFFF",
      pointBackgroundColor: "#F11672",
      pointHoverBackgroundColor: "#F11672",
    },
    {
      ...style,
      data: cesToData("mediumEffortPercent"),
      label: "Medium effort",
      borderColor: "#FFC046",
      pointHoverBorderColor: "#FFFFFF",
      pointBackgroundColor: "#FFC046",
      pointHoverBackgroundColor: "#FFC046",
    },
    {
      ...style,
      data: cesToData("smallEffortPercent"),
      label: "Small effort",
      borderColor: "#1ED5A4",
      pointHoverBorderColor: "#FFFFFF",
      pointBackgroundColor: "#1ED5A4",
      pointHoverBackgroundColor: "#1ED5A4",
    },
  ];
}
