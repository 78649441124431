<div
  class="range-slider-container"
  nz-tooltip
  [nzTooltipTitle]="getFormattedValue()"
>
  <div class="range-slider">
    <div class="range-slider-track">
      <div
        class="range-slider-progress"
        [style]="{ right: 100 - percentage * 100 + '%' }"
      ></div>
    </div>
    <div class="range-slider-thumb" [style]="{ left: percentage * 100 + '%' }">
      <div class="range-slider-value">
        <sanitized-message-component
          [content]="emoji"
          [options]="{
            styling: true,
            emojiTranscoder: true,
          }"
        ></sanitized-message-component>
        <!-- {{ getFormattedValue() }} -->
      </div>
    </div>
  </div>
</div>
