@let validSurveyName = isValidSurveyName(survey.title);
@let isNewSurvey =
  survey.title.startsWith(defaultMessageName) ||
  survey.title.startsWith(defaultSurveyName);
<div
  class="FlowTag {{ isEditMode ? 'is-edit' : '' }} {{
    builderStore.readOnly ? 'is-read-only' : ''
  }}"
  [ngClass]="{ invalid: !validSurveyName }"
  (click)="builderStore.readOnly ? null : onEdit()"
  nz-tooltip
  [nzTooltipTitle]="
    !builderStore.readOnly
      ? !validSurveyName
        ? 'Name should have a length between 3 and 255 characters'
        : (!isEditMode || editOnInit) && isNewSurvey
          ? 'Name your ' + uiService.surveyType + ' here ✍️'
          : null
      : null
  "
  [nzTooltipColor]="!validSurveyName ? 'red' : 'blue'"
  [nzTooltipVisible]="
    !builderStore.readOnly &&
    (!validSurveyName || ((!isEditMode || editOnInit) && isNewSurvey))
  "
>
  <span *ngIf="!isEditMode">
    {{ label }}
  </span>
  <input
    *ngIf="isEditMode"
    nz-input
    #nameInput
    [(ngModel)]="survey.title"
    (keydown.enter)="onSave()"
    placeholder="{{ label }}"
  />
  <button
    *ngIf="isEditMode"
    nz-button
    nzType="primary"
    nzSize="large"
    [nzLoading]="isLoading || isSaving"
    (click)="$event.stopPropagation(); onSave()"
  >
    <screeb-icon size="sm" icon="save"></screeb-icon>
  </button>
  <button
    class="close"
    *ngIf="isEditMode"
    nz-button
    nzSize="large"
    (click)="$event.stopPropagation(); onEditClose()"
  >
    <screeb-icon size="sm" icon="cross"></screeb-icon>
  </button>
  <button
    *ngIf="!isEditMode && !builderStore.readOnly"
    nz-button
    nzType="primary"
    nzSize="large"
    (click)="$event.stopPropagation(); onEdit()"
  >
    <screeb-icon icon="edit" size="sm"></screeb-icon>
  </button>
</div>
<div
  *ngIf="
    (builderStore.readOnly && survey.tags?.length) || !builderStore.readOnly
  "
  class="tags {{ builderStore.readOnly ? 'is-read-only' : '' }}"
  [ngClass]="{ invalid: !isValidSurveyTags() }"
  nz-tooltip
  [nzTooltipTitle]="
    !isValidSurveyTags()
      ? 'Tags name should have a length between 1 and 255 characters and have a maximum of 10 tags'
      : null
  "
  [nzTooltipColor]="'red'"
  [nzTooltipVisible]="!isValidSurveyTags() && !builderStore.readOnly"
>
  <nz-select
    class="survey-tags-select"
    [ngModel]="survey.tags"
    [nzDisabled]="builderStore.readOnly"
    (ngModelChange)="onTagsChange($event)"
    nzSize="large"
    nzMode="tags"
    nzPlaceHolder="Add tags"
  >
    <nz-option
      *ngFor="let tag of surveyTags"
      [nzLabel]="tag"
      [nzValue]="tag"
    ></nz-option>
  </nz-select>
  <button
    *ngIf="haveTagsDiff"
    nz-button
    nzType="primary"
    nzSize="large"
    [nzLoading]="isLoading || isSaving"
    (click)="$event.stopPropagation(); onSave()"
  >
    <screeb-icon size="sm" icon="save"></screeb-icon>
  </button>
  <button
    *ngIf="haveTagsDiff"
    class="close"
    nz-button
    nzSize="large"
    (click)="$event.stopPropagation(); onTagsDiscard()"
  >
    <screeb-icon size="sm" icon="cross"></screeb-icon>
  </button>
</div>
<div
  class="FlowTag is-message {{ builderStore.readOnly ? 'is-read-only' : '' }}"
  *ngIf="messageURL?.length"
>
  <div class="message-preview">
    <screeb-icon size="sm" icon="link"></screeb-icon>
    <p>{{ messageURL }}</p>
    <div class="is-spacer"></div>
    <button
      *ngIf="!builderStore.readOnly"
      nz-button
      nzType="primary"
      nzSize="large"
      (click)="messageURLChange.emit()"
    >
      <screeb-icon icon="edit" size="sm"></screeb-icon>
    </button>
  </div>
</div>
