import { Component, Input, OnChanges } from "@angular/core";
import { htmlSanitizer, htmlToMarkdown, SanitizeOptions } from "./sanitizer";
import { marked } from "marked";

@Component({
  selector: "sanitized-message-component",
  templateUrl: "./sanitized-message.component.html",
  styleUrls: ["./sanitized-message.component.scss"],
})
export class SanitizedMessageComponent implements OnChanges {
  @Input()
  public content: string;
  @Input()
  public options: SanitizeOptions;
  @Input()
  public rotate: "left" | "right" | undefined = undefined;

  public _content: string = "";

  constructor() {}

  ngOnChanges() {
    this._content = htmlSanitizer(
      marked.parse(
        htmlToMarkdown(this.content, this.options.styling, this.options.links),
        {
          breaks: true,
          async: false,
        },
      ),
      this.options,
    );
  }
}
