<ng-container *ngIf="posts.length > 0">
  <h2>Go further with {{ integrationInfo.name }} and Screeb</h2>

  <div nz-row class="grid-tutorial">
    <div nz-col nzSpan="24" class="blog-link-col">
      <div class="blog-link-container">
        <card-101-link
          *ngFor="let post of posts"
          [page]="'IntegrationSettings'"
          [title]="'Screeb 101. ' + post.title"
          [minuteRead]="post.readTime"
          [url]="post.url"
          [color]="post.color"
        ></card-101-link>
      </div>
    </div>
    <!-- <div nz-col nzSpan="15">
      <iframe width="560"
              height="560"
              [src]="videoUrl"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen>
      </iframe>
    </div> -->
  </div>
</ng-container>
