import { EventEmitter, Injectable } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { filter } from "rxjs/operators";

enum ScreebEventType {
  ScreebLoaded = "screeb.loaded",
  AuthLogin = "auth.login",
  AuthLogout = "auth.logout",
}

type ScreebEvent = {
  type: ScreebEventType;
  payload: object;
};

@Injectable()
class EventService {
  private eventStream = new EventEmitter<ScreebEvent>();

  constructor() {}

  public publish(type: ScreebEventType, payload: object): ScreebEvent {
    const evt: ScreebEvent = { type, payload };
    this.eventStream.emit(evt);
    // console.log('Screeb event:', type, payload);
    return evt;
  }

  public subscribe(type: ScreebEventType): Observable<ScreebEvent> {
    // console.log('Screeb subscribe event:', type);
    return this.eventStream.pipe(
      filter((event: ScreebEvent) => event.type === type),
    );
  }
  public subscribeAll(
    next?: (value: ScreebEvent) => void,
    error?: (error: any) => void,
    complete?: () => void,
  ): Subscription {
    // console.log('Screeb subscribe event:', type);
    return this.eventStream.subscribe(next, error, complete);
  }
}

export { EventService, ScreebEvent, ScreebEventType };
