import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";

import { ApplicationLoadService } from "services/app.service";
import { SuperOrgDao } from "models/super-org.dao";
import { SuperOrg } from "models/super-org.model";
import { UIService } from "services/ui.service";

@Injectable()
export class SuperOrgsResolver implements Resolve<any> {
  constructor(
    private applicationLoadService: ApplicationLoadService,
    private superOrgDao: SuperOrgDao,
    private uiService: UIService,
  ) {}

  resolve(): Promise<SuperOrg[]> {
    return this.applicationLoadService
      .loader()
      .then(() => {
        return this.superOrgDao.getAll().then((orgs: SuperOrg[]) => {
          this.uiService.superOrgs = orgs;
          return orgs;
        });
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        throw err;
      });
  }
}
