<div class="indicator-with-border">
  <div class="indicator-header">
    <h4>User device used</h4>
  </div>
  <div
    class="fullsize-indicator user-repartition"
    [ngClass]="{
      'not-available': !orgHasRespondents && !isLoading,
    }"
  >
    <donut-indicator
      [data]="respondentDeviceData"
      [loading]="isLoading"
    ></donut-indicator>
  </div>
</div>
