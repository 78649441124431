<div class="page-type-error">
  <header>
    <h1>Oops!</h1>
  </header>

  <h2>{{ subtitle }}</h2>

  <p>{{ text }}</p>

  <a
    nz-button
    nzType="primary"
    nzShape="round"
    routerLink="/"
    routerLinkActive="active"
  >
    <i nz-icon [nzType]="'home'"></i>
    Go To Homepage
  </a>
</div>
