import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { HttpErrorResponse } from "@angular/common/http";
import { Post101 } from "components/utils/card-101-link/card-101-link.model";
import { NotificationHelper } from "helpers/notification.helper";
import { SurveyDistributionDao } from "models/survey-distribution.dao";
import { SurveyDistribution } from "models/survey-distribution.model";
import { AuthService, SessionService } from "services/auth.service";
import { EntitlementService } from "services/entitlement.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { UIService } from "services/ui.service";

@Component({
  selector: "layout-back-sidebar-sharing",
  templateUrl: "./sidebar-sharing.component.html",
  styleUrls: ["./sidebar-sharing.component.scss"],
})
export class LayoutBackSidebarSharingComponent implements OnInit, OnDestroy {
  private obs: any = null;
  private obsUi: any = null;

  @Input() public orgId: string = null;

  public distributions: SurveyDistribution[] = [];
  public legacyDistributions: SurveyDistribution[] = [];
  public activeDistribution: SurveyDistribution = null;

  public isMobileDistribution: boolean = false;

  public isNewOrgSinceNewDistribution: boolean = false;
  public isLegacyDistributionExpanded: boolean = false;

  public loading: string | null = null;

  public posts101: Post101[] = [
    {
      color: "var(--screeb-color-info-outline)",
      title: "My survey is not displayed, what can I do?",
      url: "https://help.screeb.app/en/articles/6007748-my-survey-is-not-displayed-to-my-users-help-me",
    },
    {
      color: "var(--screeb-color-info-outline)",
      title:
        "NPS: Why <b>You Should Measure It Every Quarter</b> (And How To Do It With Screeb)",
      url: "https://screeb.app/blog/nps-why-you-should-measure-it-every-quarter-and-how-to-do-it-with-screeb/",
    },
    {
      color: "var(--screeb-color-warning-outline)",
      title:
        "How to <b>use your users’ events</b> to choose when to display your surveys",
      url: "https://help.screeb.app/en/articles/5252769-how-to-use-your-users-events-to-choose-when-to-display-your-surveys",
    },
    {
      color: "var(--screeb-color-info-outline)",
      title:
        "How to <b>use your users’ properties</b> to choose when to display surveys",
      url: "https://help.screeb.app/en/articles/5040116-how-to-use-your-users-properties-to-choose-when-to-display-surveys",
    },
    {
      color: "var(--screeb-color-primary-outline)",
      title:
        "How In-App Surveys Can Help You <b>Reduce Funnel Drop-Off Rate</b>",
      url: "https://screeb.app/blog/how-in-app-surveys-can-help-you-reduce-funnel-drop-off-rate/",
    },
  ];

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    public authService: AuthService,
    public sessionService: SessionService,
    public notificationHelper: NotificationHelper,
    public uiService: UIService,
    public featureFlaggingService: FeatureFlaggingService,
    private surveyDistributionDao: SurveyDistributionDao,
    private entitlementService: EntitlementService,
  ) {}

  ngOnInit(): void {
    this.onRouteChange();
    this.obs = this.router.events.subscribe(() => {
      this.isNewOrgSinceNewDistribution =
        this.uiService.currentOrg.created_at > new Date("2024-07-15");
      this.isLegacyDistributionExpanded = !this.isNewOrgSinceNewDistribution;
      this.fetchDistributions();
      this.onRouteChange();
    });

    this.obsUi = this.uiService.updateSubject.subscribe(() => {
      this.fetchDistributions();
    });
  }

  private fetchDistributions() {
    const distributions = this.uiService.currentSurveyDistributions;
    if (!distributions) return;
    this.distributions = distributions.filter((d) => !d.type);
    this.legacyDistributions = distributions.filter((d) => d.type);

    this.isLegacyDistributionExpanded =
      !this.isNewOrgSinceNewDistribution ||
      this.legacyDistributions.some((d) => d.enabled);

    // If current survey is a message, we only want to show one channel
    if (this.uiService.currentSurvey?.type === "message") {
      const distribution =
        this.uiService.currentSurvey.survey_distributions.find(
          (d) => Number(d.updated_at) !== Number(d.created_at),
        );

      if (distribution) {
        this.legacyDistributions = this.legacyDistributions.filter(
          (d) => d.type === distribution.type,
        );
      }
    }
  }

  public toggleLegacyDistribution() {
    if (this.isNewOrgSinceNewDistribution) {
      this.isLegacyDistributionExpanded = !this.isLegacyDistributionExpanded;
    }
  }

  private onRouteChange() {
    this.activeDistribution = this.uiService.currentSurveyDistributions?.find(
      (d) =>
        this.router.isActive(
          "/org/" +
            this.uiService.currentOrgId +
            "/" +
            this.uiService.surveyTypePath +
            "/" +
            this.uiService.currentSurveyId +
            "/share/" +
            d.id,
          false,
        ),
    );

    if (this.activeDistribution) {
      this.refreshIsMobileDistribution();
    }
  }

  public toggleDistributionStatus(distribution: SurveyDistribution) {
    if (!this.entitlementService.isAvailable("survey_edition")) return;
    this.loading = distribution.id;
    return this.surveyDistributionDao
      .updateStatus(
        this.uiService.currentOrgId,
        distribution.survey_id,
        distribution.id,
        !distribution.enabled,
        distribution.selector_css,
      )
      .then(() => this.uiService.fetchEverything())
      .then(() => this.fetchDistributions())
      .then(() => {
        this.loading = null;
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        this.notificationHelper.trigger(
          err?.error?.message ?? "Error",
          null,
          "error",
        );
        this.loading = null;
      });
  }

  ngOnDestroy() {
    if (this.obs) this.obs.unsubscribe();
    if (this.obsUi) this.obsUi.unsubscribe();
  }

  private refreshIsMobileDistribution() {
    this.isMobileDistribution = ["android", "ios"].includes(
      this.activeDistribution.type,
    );
  }

  public isDistribAvailableInEntitlements(
    distribution: SurveyDistribution,
  ): boolean {
    if (this.uiService.currentSurvey?.type === "message") {
      return this.entitlementService.isAvailable("message_inapp");
    }

    if (
      ["widget", "hosted-page"].includes(distribution.type) &&
      !this.entitlementService.isAvailable("survey_inapp_desktop")
    ) {
      return false;
    }

    if (
      ["android", "ios"].includes(distribution.type) &&
      !this.entitlementService.isAvailable("survey_inapp_mobile")
    ) {
      return false;
    }

    return true;
  }

  public goToBilling() {
    this.router.navigate([
      "/overview/" + this.uiService.currentSuperOrgId + "/billing",
    ]);
  }
}
