import { Injectable, TemplateRef } from "@angular/core";
import {
  NzNotificationDataOptions,
  NzNotificationService,
} from "ng-zorro-antd/notification";

@Injectable()
class NotificationHelper {
  constructor(private notification: NzNotificationService) {}

  public trigger(
    title: string,
    description: string = null,
    status: "success" | "info" | "warning" | "error" | "blank" | null = null,
    durationSecond: number = null,
    key: string = undefined,
  ) {
    const opts = {
      nzPlacement: "bottomRight",
      nzKey: key,
      nzStyle: {
        borderRadius: "16px",
        boxShadow: "0px 2px 24px 0px rgba(48, 49, 64, 0.1)",
      },
    } as NzNotificationDataOptions;
    if (durationSecond !== null && durationSecond !== undefined)
      opts.nzDuration = durationSecond * 1000;

    this.notification.create(
      !status ? "blank" : status,
      title,
      description,
      opts,
    );
  }

  public template(
    template: TemplateRef<object>,
    durationSecond: number = null,
    key: string = undefined,
  ) {
    const opts = {
      nzPlacement: "bottomRight",
      nzKey: key,
      nzStyle: {
        borderRadius: "16px",
        boxShadow: "0px 2px 24px 0px rgba(48, 49, 64, 0.1)",
      },
    } as NzNotificationDataOptions;
    if (durationSecond !== null && durationSecond !== undefined)
      opts.nzDuration = durationSecond * 1000;

    return this.notification.template(template, opts);
  }

  public close(key: string) {
    this.notification.remove(key);
  }
}

export { NotificationHelper };
