<label class="lateral-panel-section-title">Content and actions</label>
<p class="preview-box">
  <span class="preview-box-message">
    Manage and write your content directly on the preview!
  </span>
  <button nz-button nzType="primary" (click)="onOpenPreviewClick()">
    <span>Open builder</span>
    &nbsp;
    <screeb-icon size="sm" icon="right-arrow"></screeb-icon>
  </button>
</p>
