<ng-template let-template="template" #templateCard>
  <card
    [ngClass]="{
      'inspired-by-customers': template.is_inspired_by_customers,
    }"
    [title]="template.name"
    [content]="template.description"
    [headerCss]="{
      'background-image': 'url(\'' + template.background + '\')',
    }"
    [actionRight]="actionRight"
    (cardClick)="onDuplicate(template)"
  ></card>
  <ng-template #actionRight>
    <a
      nz-button
      nzType="default"
      rel="noopener"
      (click)="$event.stopPropagation()"
      href="http://preview.screeb.app/templates.html?sid={{
        template.survey_id
      }}&vid={{ sessionService.session.id }}&platform={{
        configService.config.platform
      }}"
      target="_blank"
    >
      Preview
    </a>
    <a
      nz-button
      nzType="primary"
      (click)="$event.stopPropagation(); onDuplicate(template)"
    >
      Use
    </a>
  </ng-template>
</ng-template>

<div
  class="header-wrapper"
  style="{{ searching || category !== 'all' ? 'padding-bottom: 32px' : '' }}"
>
  <div class="breadcrumb">
    <p>CREATE {{ uiService.isMessagePage ? "MESSAGE" : "SURVEY" }}</p>
    <h1>Templates Gallery</h1>
  </div>
  <header>
    <span>
      Start from one of these templates, or create your own
      {{ uiService.isMessagePage ? "messsage" : "survey" }} from scratch
    </span>

    <div>
      <!-- <nz-select
        nzSize="large"
        [ngModel]="templateLanguage"
        (ngModelChange)="onLanguageChange($event)"
      >
        <nz-option nzValue="en" nzLabel="🇬🇧 English"></nz-option>
        <nz-option nzValue="fr" nzLabel="🇫🇷 French"></nz-option>
      </nz-select> -->

      <nz-input-group
        nzSize="large"
        [nzPrefix]="prefixIcon"
        [nzSuffix]="inputClearButton"
      >
        <input
          type="text"
          nz-input
          placeholder="Search a template"
          [(ngModel)]="keywords"
          (ngModelChange)="onSearchChange()"
        />
      </nz-input-group>
      <ng-template #prefixIcon>
        <i nz-icon nzType="search"></i>
      </ng-template>
      <ng-template #inputClearButton>
        <i
          nz-icon
          class="ant-input-clear-icon"
          nzTheme="fill"
          nzType="close-circle"
          *ngIf="keywords"
          (click)="keywords = ''; onSearchChange()"
        ></i>
      </ng-template>
    </div>
  </header>

  <ng-template #actionRightDefault>
    <a
      nz-button
      nzType="primary"
      (click)="$event.stopPropagation(); onCreate()"
    >
      Use
    </a>
  </ng-template>
  <ng-template #actionRightAI>
    <a
      nz-button
      nzType="primary"
      (click)="
        $event.stopPropagation(); onCreate(null, null, null, 0, 'success', true)
      "
    >
      Use
    </a>
  </ng-template>
  <ng-container *ngIf="!searching && category === 'all'">
    <p class="popular-title">POPULAR TEMPLATES</p>
    <div class="populars">
      <card
        [title]="
          templateLanguage === 'fr' ? 'Partir de zéro' : 'Create from Scratch'
        "
        [content]="[
          templateLanguage === 'fr'
            ? uiService.isMessagePage
              ? 'Créez votre propre message et questionnez vos utilisateurs.'
              : 'Créez votre propre sondage et questionnez vos utilisateurs.'
            : uiService.isMessagePage
              ? 'Create your own messages and chain them for your needs.'
              : 'Create your own survey from scratch and ask tailored questions to your users.',
        ]"
        headerIcon="plus"
        [actionRight]="actionRightDefault"
        (cardClick)="onCreate()"
      ></card>
      <card
        *ngIf="!uiService.isMessagePage"
        [title]="
          templateLanguage === 'fr' ? 'Créer avec l\'IA' : 'Create with AI'
        "
        [content]="[
          templateLanguage === 'fr'
            ? uiService.isMessagePage
              ? 'Expliquez ce que vous voulez demander et notre IA créera un message.'
              : 'Expliquez ce que vous voulez demander et notre IA créera un sondage.'
            : uiService.isMessagePage
              ? 'Use AI to generate a flow of messages. Edit as you like.'
              : 'Explain what you want to ask and our AI will create a survey.',
        ]"
        headerIcon="lightning"
        headerIconSize="sm"
        [actionRight]="actionRightAI"
        (cardClick)="onCreate(null, null, null, 0, 'success', true)"
      ></card>
      <ng-container *ngIf="!loading">
        <ng-container *ngFor="let tpl of popularSurveys; let surveyId = index">
          <ng-container
            *ngIf="tpl.language === templateLanguage"
            [ngTemplateOutlet]="templateCard"
            [ngTemplateOutletContext]="{ template: tpl }"
          ></ng-container>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</div>

<div *ngIf="loading" class="center">
  <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
</div>

<ng-container *ngIf="!loading">
  <div *ngIf="!searching">
    <ul class="survey-template-groups">
      <!-- loop over each template categories -->
      <ng-container
        *ngFor="
          let group of uiService.isMessagePage
            ? settingsService.messageTemplateGroups
            : settingsService.surveyTemplateGroups;
          let groupId = index
        "
      >
        <li *ngIf="category === 'all' || group.slug === category">
          <h2 [id]="'template-' + group.slug">
            {{ group.title[templateLanguage] }}
          </h2>
          <ng-container
            [ngTemplateOutlet]="templateCards"
            [ngTemplateOutletContext]="{ templates: group.surveys }"
          ></ng-container>
        </li>
      </ng-container>
    </ul>
  </div>

  <!-- loop over search results -->
  <div *ngIf="searching">
    <ul class="survey-template-groups">
      <li>
        <h2>Results</h2>
        <nz-empty
          *ngIf="filteredTemplates.length === 0"
          nzNotFoundContent="No template matching"
        ></nz-empty>
        <ng-container
          *ngIf="filteredTemplates.length > 0"
          [ngTemplateOutlet]="templateCards"
          [ngTemplateOutletContext]="{ templates: filteredTemplates }"
        ></ng-container>
      </li>
    </ul>
  </div>

  <ng-template #templateCards let-templates="templates">
    <ul class="survey-templates">
      <ng-container *ngFor="let tpl of templates">
        <li *ngIf="tpl.language === templateLanguage">
          <ng-container
            [ngTemplateOutlet]="templateCard"
            [ngTemplateOutletContext]="{ template: tpl }"
          ></ng-container>
        </li>
      </ng-container>
    </ul>
  </ng-template>
</ng-container>
