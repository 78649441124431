import { AnalyticsResponse } from "models/analytics.model";

export type FunnelReportAggregation = {
  total: number;
  currentValue: number;
  nextValue?: number;
}[];

export const computeFunnelReportAggregationFromResponse = (
  response: AnalyticsResponse,
): FunnelReportAggregation => {
  if (!response?.aggregations) {
    return [];
  }

  const values = Object.entries(response.aggregations)
    .sort(([key1], [key2]) => key1.length - key2.length)
    .map(([, { doc_count }]) => doc_count);

  if (!values.length) {
    return [];
  }

  return values.map((value, index, values) => ({
    total: values[0],
    currentValue: value,
    nextValue: values[index + 1] ?? undefined,
  }));
};
