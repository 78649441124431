<div class="stats-header">
  <free-trial-banner />
  <header class="">
    <h1>{{ uiService.isMessagePage ? "Message" : "Survey" }} analytics</h1>

    <div class="analytics-filters">
      <survey-stats-filters
        reportType="survey-analytics"
        [canExport]="true"
        [org]="org"
        [survey]="survey"
        [reporting]="
          (uiService.isMessagePage ? 'Message' : 'Survey') + ' analytics'
        "
        [languages]="languages"
        [registryEntriesIdentityProperty]="registryEntriesIdentityProperty"
        [registryEntriesGroup]="registryEntriesGroup"
        [registryEntriesEvent]="registryEntriesEvent"
        type="response"
      ></survey-stats-filters>
    </div>
  </header>

  <error-message
    *ngIf="error && !loading"
    message="An error occured when fetching data."
    [details]="error.message"
    (reload)="analyticsFilterService.triggerChange()"
  ></error-message>

  <section *ngIf="!error" class="top-indicators">
    <div class="top-indicators__left">
      <div class="card-background">
        <survey-stats-indicators-single-indicator
          title="Total number of displays"
          [value]="overallPerformancesCurrentPeriod?.totalDisplays"
          [diffPercent]="overallPerformancesVariation?.totalDisplays"
          format="number"
          [loading]="loading"
          diffFormat="percent"
          imageSrc="/assets/illustrations/displays.png"
        ></survey-stats-indicators-single-indicator>
      </div>
      <div class="card-background">
        <survey-stats-indicators-single-indicator
          title="{{
            uiService.isMessagePage
              ? 'Total number of interactions'
              : 'Total number of responses'
          }}"
          [loading]="loading"
          [value]="overallPerformancesCurrentPeriod?.totalResponses"
          [diffPercent]="overallPerformancesVariation?.totalResponses"
          format="number"
          diffFormat="percent"
          imageSrc="/assets/illustrations/responses.png"
        ></survey-stats-indicators-single-indicator>
      </div>
      <div class="card-background">
        <survey-stats-indicators-single-indicator
          title="{{
            uiService.isMessagePage ? 'Interaction rate' : 'Response rate'
          }}"
          [loading]="loading"
          [value]="overallPerformancesCurrentPeriod?.responseRate"
          [diffPercent]="overallPerformancesVariation?.responseRate"
          format="percent"
          diffFormat="percent"
          [diffIndustryAverage]="
            overallPerformancesCurrentPeriod
              ? overallPerformancesCurrentPeriod.responseRate -
                industryAverageResponseRate
              : null
          "
          imageSrc="/assets/illustrations/response-rate.png"
        ></survey-stats-indicators-single-indicator>
      </div>
      <div class="card-background">
        <survey-stats-indicators-single-indicator
          title="{{
            uiService.isMessagePage ? 'Flow completion rate' : 'Completion rate'
          }}"
          [loading]="loading"
          [value]="overallPerformancesCurrentPeriod?.completionRate"
          [diffPercent]="overallPerformancesVariation?.completionRate"
          format="percent"
          diffFormat="percent"
          imageSrc="/assets/illustrations/completion.png"
        ></survey-stats-indicators-single-indicator>
      </div>
    </div>
    <div class="top-indicators__right">
      <div class="card-background">
        <p>Responses Emotions Analysis</p>
        <survey-stats-indicators-radar-indicator
          [labels]="[
            '😡 Anger',
            '&nbsp;😄 Joy&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;',
            '😱 Fear',
            '😢 Sadness',
          ]"
          [legend]="emotionsChartLegend"
          [datasets]="emotionsChartDatasets"
          [loading]="loadingEmotions"
        />
      </div>
    </div>
  </section>
</div>
<section *ngIf="!error" nz-row [nzGutter]="[32, 16]">
  <div nz-col nzSpan="24">
    <div
      class="indicator-with-border d100"
      *ngIf="
        !loading &&
        (!overallPerformancesPerDateCurrentPeriod ||
          overallPerformancesPerDateCurrentPeriod.length <= 1)
      "
    >
      <nz-empty
        [nzNotFoundContent]="
          !!overallPerformancesCurrentPeriod?.totalDisplays
            ? 'Please select a longer period to display trend'
            : 'No data available'
        "
      ></nz-empty>
    </div>
    <div
      class="indicator-with-border trend-indicator d200"
      *ngIf="
        loading ||
        (overallPerformancesPerDateCurrentPeriod &&
          overallPerformancesPerDateCurrentPeriod.length > 1)
      "
    >
      <survey-stats-indicators-trend-indicator
        theme="overall"
        [datasets]="trendChartDataset"
        [legend]="trendChartLegend"
        [releases]="releases"
        [orgAccounts]="orgAccounts"
        [orgId]="org.id"
        [loading]="loading"
      ></survey-stats-indicators-trend-indicator>
    </div>
  </div>
</section>

<section *ngIf="!error" nz-row [nzGutter]="[32, 16]">
  <div nz-col nzOrder="1" nzXs="24">
    <section class="bottom-indicators" nz-row [nzGutter]="[32, 16]">
      <div nz-col nzXs="24" nzLg="8">
        <survey-stats-indicators-single-indicator
          title="Current status of the {{
            uiService.isMessagePage ? 'message' : 'survey'
          }}"
          [loading]="loading"
          [value]="surveyStats.distributions"
        ></survey-stats-indicators-single-indicator>
      </div>
      <div nz-col nzXs="24" nzLg="16"></div>
      <div nz-col nzXs="24" nzLg="8">
        <survey-stats-indicators-single-indicator
          title="💻 Displays on Desktop"
          [loading]="loading"
          [value]="overallPerformancesCurrentPeriod?.desktop?.displays"
          [diffPercent]="overallPerformancesVariation?.desktop?.displays"
          format="number"
          diffFormat="percent"
        ></survey-stats-indicators-single-indicator>
      </div>
      <div nz-col nzXs="24" nzLg="8">
        <survey-stats-indicators-single-indicator
          title="🍫 Displays on Tablet"
          [loading]="loading"
          [value]="overallPerformancesCurrentPeriod?.tablet?.displays"
          [diffPercent]="overallPerformancesVariation?.tablet?.displays"
          format="number"
          diffFormat="percent"
        ></survey-stats-indicators-single-indicator>
      </div>
      <div nz-col nzXs="24" nzLg="8">
        <survey-stats-indicators-single-indicator
          title="📱 Displays on Mobile"
          [loading]="loading"
          [value]="overallPerformancesCurrentPeriod?.mobile?.displays"
          [diffPercent]="overallPerformancesVariation?.mobile?.displays"
          format="number"
          diffFormat="percent"
        ></survey-stats-indicators-single-indicator>
      </div>
      <div nz-col nzXs="24" nzLg="8">
        <survey-stats-indicators-single-indicator
          title="💻 {{
            uiService.isMessagePage ? 'Interactions' : 'Responses'
          }} on Desktop"
          [loading]="loading"
          [value]="overallPerformancesCurrentPeriod?.desktop?.responses"
          [diffPercent]="overallPerformancesVariation?.desktop?.responses"
          format="number"
          diffFormat="percent"
        ></survey-stats-indicators-single-indicator>
      </div>
      <div nz-col nzXs="24" nzLg="8">
        <survey-stats-indicators-single-indicator
          title="🍫 {{
            uiService.isMessagePage ? 'Interactions' : 'Responses'
          }} on Tablet"
          [loading]="loading"
          [value]="overallPerformancesCurrentPeriod?.tablet?.responses"
          [diffPercent]="overallPerformancesVariation?.tablet?.responses"
          format="number"
          diffFormat="percent"
        ></survey-stats-indicators-single-indicator>
      </div>
      <div nz-col nzXs="24" nzLg="8">
        <survey-stats-indicators-single-indicator
          title="📱 {{
            uiService.isMessagePage ? 'Interaction' : 'Response'
          }} on Mobile"
          [loading]="loading"
          [value]="overallPerformancesCurrentPeriod?.mobile?.responses"
          [diffPercent]="overallPerformancesVariation?.mobile?.responses"
          format="number"
          diffFormat="percent"
        ></survey-stats-indicators-single-indicator>
      </div>
      <div nz-col nzXs="24" nzLg="8">
        <survey-stats-indicators-single-indicator
          title="💻 {{
            uiService.isMessagePage ? 'Interaction' : 'Response'
          }} rate on Desktop"
          [loading]="loading"
          [value]="overallPerformancesCurrentPeriod?.desktop?.responseRate"
          [diffPercent]="overallPerformancesVariation?.desktop?.responseRate"
          format="percent"
          diffFormat="percent"
        ></survey-stats-indicators-single-indicator>
      </div>
      <div nz-col nzXs="24" nzLg="8">
        <survey-stats-indicators-single-indicator
          title="🍫 {{
            uiService.isMessagePage ? 'Interaction' : 'Response'
          }} rate on Tablet"
          [loading]="loading"
          [value]="overallPerformancesCurrentPeriod?.tablet?.responseRate"
          [diffPercent]="overallPerformancesVariation?.tablet?.responseRate"
          format="percent"
          diffFormat="percent"
        ></survey-stats-indicators-single-indicator>
      </div>
      <div nz-col nzXs="24" nzLg="8">
        <survey-stats-indicators-single-indicator
          title="📱 {{
            uiService.isMessagePage ? 'Interaction' : 'Response'
          }} rate on Mobile"
          [loading]="loading"
          [value]="overallPerformancesCurrentPeriod?.mobile?.responseRate"
          [diffPercent]="overallPerformancesVariation?.mobile?.responseRate"
          format="percent"
          diffFormat="percent"
        ></survey-stats-indicators-single-indicator>
      </div>
      <div nz-col nzXs="24" nzLg="8">
        <survey-stats-indicators-single-indicator
          title="Last edit"
          [loading]="loading"
          [value]="surveyStats?.lastEdit"
        ></survey-stats-indicators-single-indicator>
      </div>
      <div nz-col nzXs="24" nzLg="8">
        <survey-stats-indicators-single-indicator
          title="Date of first {{
            uiService.isMessagePage ? 'interaction' : 'responses'
          }}"
          [loading]="loading"
          [value]="overallPerformancesCurrentPeriod?.firstResponseDate"
        ></survey-stats-indicators-single-indicator>
      </div>
      <div nz-col nzXs="24" nzLg="8">
        <survey-stats-indicators-single-indicator
          title="Date of last {{
            uiService.isMessagePage ? 'interaction' : 'responses'
          }}"
          [loading]="loading"
          [value]="overallPerformancesCurrentPeriod?.lastResponseDate"
          dateFormat="distance-from-now"
        ></survey-stats-indicators-single-indicator>
      </div>
    </section>
  </div>
</section>
