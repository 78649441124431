<section class="capping-selection-group">
  <settings-header
    title="Survey and Message Capping"
    description="Cap the displays and responses of your surveys and messages over periods of time."
    (save)="onSave()"
    [isLoading]="saving"
    [isDisabled]="!hasUnsavedChanges()"
  ></settings-header>

  <ul>
    <li class="capping-selection">
      <div class="capping-selection-title">
        <h3>
          <screeb-icon size="sm" icon="filter"></screeb-icon>
          Conditions Before Displays
        </h3>
        <nz-switch
          [(ngModel)]="surveyEventsAndPropertiesSwitch"
          [nzCheckedChildren]="checkedTemplate"
          [nzUnCheckedChildren]="unCheckedTemplate"
        ></nz-switch>
      </div>
      <div
        class="capping-config-container"
        *ngIf="surveyEventsAndPropertiesSwitch"
      >
        <div class="rules-tip">
          <screeb-icon size="sm" icon="info-circle"></screeb-icon>
          <span>
            We won't display your surveys until these conditions are met.
          </span>
        </div>
        <survey-share-targeting-advanced
          [isOrgTargeting]="true"
          [org]="org"
          [(rules)]="otherTargetingRules"
          [registryEntriesIdentityProperty]="registryEntriesIdentityProperty"
          [registryEntriesGroup]="registryEntriesGroup"
          [registryEntriesEvent]="registryEntriesEvent"
          [surveys]="surveys"
        ></survey-share-targeting-advanced>
      </div>
    </li>
  </ul>
</section>

<section class="capping-selection-group">
  <header>
    <div>
      <h1>In-app survey capping</h1>
      <p>
        Cap the displays and responses of your in-app surveys over periods of
        time.
        <br />
        If you activate more than one capping type, they will add up. The
        following policies have no impact on email or full page surveys.
      </p>
    </div>
  </header>
  <hr />

  <ul>
    <li class="capping-selection">
      <div class="capping-selection-title">
        <h3>
          <screeb-icon size="sm" icon="customize"></screeb-icon>
          Global Capping
        </h3>
        <nz-switch
          [(ngModel)]="perOrgCappingSwitch"
          [nzCheckedChildren]="checkedTemplate"
          [nzUnCheckedChildren]="unCheckedTemplate"
        ></nz-switch>
      </div>
      <div class="capping-config-container">
        <!--Config Global Capping-->
        <p>
          Cap the displays and responses of all your surveys over a specific
          period of time.
          <br />
          This will be applied globally, for all your surveys.
          <br />
          Once those limits are reached, we won't display your surveys until the
          end of the period.
        </p>
        <survey-capping-config
          *ngIf="perOrgCappingSwitch"
          [(rules)]="cappingTargetingRules"
          [orgId]="org.id"
          surveyCappingType="per-org"
        ></survey-capping-config>
      </div>
    </li>
    <li class="capping-selection">
      <div class="capping-selection-title">
        <h3>
          <screeb-icon size="sm" icon="survey"></screeb-icon>
          Per Survey Capping
        </h3>
        <nz-switch
          [(ngModel)]="perSurveyCappingSwitch"
          [nzCheckedChildren]="checkedTemplate"
          [nzUnCheckedChildren]="unCheckedTemplate"
        ></nz-switch>
      </div>
      <div class="capping-config-container">
        <!--Config Per Survey Capping-->
        <p>
          Cap the displays and responses of all your surveys individually, over
          a period of time.
          <br />
          This will be applied to each of your surveys and when one of them
          reach these limits, the others will continue to be displayed.
        </p>
        <survey-capping-config
          *ngIf="perSurveyCappingSwitch"
          [(rules)]="cappingTargetingRules"
          [orgId]="org.id"
          surveyCappingType="per-survey"
        ></survey-capping-config>
      </div>
    </li>
    <li class="capping-selection">
      <div class="capping-selection-title">
        <h3>
          <screeb-icon size="sm" icon="user"></screeb-icon>
          Per User Capping
        </h3>
        <nz-switch
          [(ngModel)]="perRespondentCappingSwitch"
          [nzCheckedChildren]="checkedTemplate"
          [nzUnCheckedChildren]="unCheckedTemplate"
        ></nz-switch>
      </div>
      <div class="capping-config-container">
        <!--Config Per User Capping-->
        <p>
          Cap how many surveys a user will see over a period of time and how
          long we'll wait before displaying a new survey after the user answered
          or closed one.
        </p>
        <survey-capping-config
          *ngIf="perRespondentCappingSwitch"
          [(rules)]="cappingTargetingRules"
          [orgId]="org.id"
          surveyCappingType="per-respondent"
        ></survey-capping-config>
      </div>
    </li>
    <ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
    <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>
  </ul>
</section>

<section class="tuto">
  <h2>Need help?</h2>
  <p>Learn how to use survey capping in less than 5 minutes</p>

  <div class="iframe-wrapper">
    <iframe
      src="https://www.loom.com/embed/75d465b9a0da476298e7c67e11c98897"
      frameborder="0"
      webkitallowfullscreen
      mozallowfullscreen
      allowfullscreen
      style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
    ></iframe>
  </div>
</section>
