import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { PageComponentInterface } from "components/PageComponentInterface";
import { AccountDao } from "models/account.dao";
import { AccountGoalsToLabel } from "models/account.model";
import { Org } from "models/org.model";
import { SessionService } from "services/auth.service";
import { RoutingService } from "services/routing.service";
import { TrackersService } from "services/trackers.service";
import { UIService } from "services/ui.service";

@Component({
  selector: "page-onboarding-book-demo",
  templateUrl: "./onboarding-book-demo.component.html",
  styleUrls: [
    "./onboarding-book-demo.component.scss",
    "../layout/onboarding-layout.component.scss",
  ],
})
export class OnBoardingBookDemoPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Welcome to Screeb!";
  public name = "Onboarding book demo";

  public accountGoalToLabel = AccountGoalsToLabel;

  private obs: any = null;
  public org: Org = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private routingService: RoutingService,
    public sessionService: SessionService,
    private trackersService: TrackersService,
    public accountDao: AccountDao,
    private uiService: UIService,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );
    this.obs = this.route.data.subscribe((data) => {
      this.org = data["org"];
    });

    window["Calendly"].initInlineWidget({
      url: "https://calendly.com/simonrobic/30min?hide_gdpr_banner=1&primary_color=5e21f1",
      parentElement: document.querySelector(".calendly-inline-widget"),
    });
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  onSkip() {
    this.trackersService
      .newEventTrackingBuilder("Screeb Onboarding Book Demo Skipped")
      .withOrg(this.org)
      .withAccount(this.sessionService.session)
      .build();

    return this.goToNextStep();
  }

  goToNextStep() {
    return this.accountDao.updateOnboardingStatus("product-tour").then(() => {
      this.router.navigate(["org", this.org.id]).then(() => {
        // Reload page to update billing status
        window.location.reload();
      });
    });
  }
}
