<div class="indicator-with-border">
  <div class="indicator-header">
    <h4>Timeline</h4>
    <a
      routerLink="/org/{{ user.org_id }}/people/respondent/{{ user.id }}"
      [queryParams]="{ tab: 'responses' }"
    >
      See all
    </a>
  </div>
  <div class="loader" *ngIf="loadingResponsesPG && initialFetchResponsesPG">
    <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
  </div>
  <ng-container *ngIf="!initialFetchResponsesPG || !loadingResponsesPG">
    <nz-timeline
      class="timeline"
      [nzPending]="userResponsesPG.length ? 'Tracking...' : null"
      [nzReverse]="true"
    >
      <ng-container
        *ngFor="let response of userResponsesPG; trackByProperty: 'id'"
      >
        <nz-timeline-item>
          <div class="timeline-label" *ngIf="response.survey?.deleted_at">
            {{ response.survey.title }}
            <span>(Deleted)</span>
          </div>
          <a
            class="timeline-label"
            *ngIf="!response.survey?.deleted_at"
            routerLink="/org/{{ user.org_id }}/survey/{{
              response.survey_id
            }}/stats"
          >
            {{ response.survey?.title }}
          </a>
          <div class="timeline-date">
            {{
              response.created_at | dfnsFormatDistanceToNow: { addSuffix: true }
            }}
          </div>
          <nz-tag
            nzColor="var(--screeb-color-green-500)"
            nz-tooltip
            nzTooltipTitle="Survey version created at: {{
              response.scenario?.created_at | toLocaleString
            }}"
          >
            <span>v.{{ response.scenario?.version }}</span>
          </nz-tag>
          <nz-tag [nzColor]="response.completion | responseCompletionColor">
            <span>
              {{ response.completion | responseCompletion }}
            </span>
          </nz-tag>
          <nz-tag *ngIf="response.end_reason === 'closed'" nzColor="#292929">
            <span>Closed</span>
          </nz-tag>
          <nz-tag
            *ngIf="response.end_reason === 'interrupted'"
            nzColor="#292929"
          >
            <span>Interrupted</span>
          </nz-tag>
          <nz-tag *ngIf="response.end_reason === 'expired'" nzColor="#292929">
            <span>Expired</span>
          </nz-tag>
          <tag-registry-entry-source
            [sources]="[response.source]"
            size="small"
          ></tag-registry-entry-source>
        </nz-timeline-item>
      </ng-container>
    </nz-timeline>
    <nz-empty *ngIf="!userResponsesPG.length" [nzNotFoundContent]="contentTpl">
      <ng-template #contentTpl>Empty timeline</ng-template>
    </nz-empty>
  </ng-container>
</div>
