import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { PageComponentInterface } from "components/PageComponentInterface";

import { NotificationHelper } from "helpers/notification.helper";
import { OrgDao } from "models/org.dao";
import {
  GridRangeNames,
  Org,
  OrgFlagsHomeGridCell,
  OrgFlagsHomeGridCellType,
  OrgFlagsHomeGridRange,
  OrgReport,
} from "models/org.model";
import { Survey } from "models/survey.model";
import { AnalyticsFilterService } from "services/analytics-filters.service";
import { SessionService } from "services/auth.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { PermissionsService } from "services/permissions.service";
import { RoutingService } from "services/routing.service";
import { UIService } from "services/ui.service";
import { WidgetSpec, widgetsSpecs } from "./widget-specs";
import { RANDOM_MSGS } from "components/home/widgets/quote-of-the-day/quote-of-the-day.component";
import Muuri from "muuri";
import Grid from "muuri";
import { Account } from "models/account.model";
import _default from "chart.js/dist/plugins/plugin.tooltip";
import reset = _default.reset;

@Component({
  selector: "page-org-overview",
  templateUrl: "./overview.component.html",
  styleUrls: ["./overview.component.scss", "./dashboard.scss"],
})
export class OverviewOrgPageComponent
  implements
    PageComponentInterface,
    OnInit,
    OnDestroy,
    AfterViewInit,
    AfterViewChecked
{
  public title = "Workspace overview";
  public name = "Workspace overview";

  private obs: any = null;

  public error: Error;

  public org: Org = null;
  public orgAccounts: Account[] = [];
  public helloMessage: string = "";
  public quoteOfTheDay =
    RANDOM_MSGS[new Date().getDate() % RANDOM_MSGS.length].split(" — ");

  public editingDashboard = false;
  public isAddWidgetOpen = false;

  public widgetsSpecs = widgetsSpecs;

  public orgHasRespondents = true;
  public surveys: Survey[] = [];

  public focusedType: WidgetSpec = null;

  public orgReport: OrgReport = null;

  public gridRangeNames = GridRangeNames;

  public items: { id: OrgFlagsHomeGridCellType; w: string; h: string }[] = [];
  public tmpItems: { id: OrgFlagsHomeGridCellType; w: string; h: string }[] =
    [];

  public gridVisible: boolean = false;

  grid: Grid;
  dragEnabled = false;

  constructor(
    private route: ActivatedRoute,
    private routingService: RoutingService,
    public sessionService: SessionService,
    public featureFlaggingService: FeatureFlaggingService,
    public permissionsService: PermissionsService,
    private orgDao: OrgDao,
    private notificationHelper: NotificationHelper,
    public uiService: UIService,
    private analyticsFilterService: AnalyticsFilterService,
  ) {}

  ngAfterViewInit() {
    this.instantiateGrid();
    setTimeout(() => {
      this.gridVisible = true;
    }, 150);
  }

  ngAfterViewChecked() {
    if (this.grid) {
      this.grid.refreshItems();
      this.grid.layout();
    } else {
      this.instantiateGrid();
      setTimeout(() => {
        this.gridVisible = true;
      }, 300);
    }
  }

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.helloMessage = this.getHelloMessage();

    this.obs = this.route.data.subscribe(async (data) => {
      this.grid?.destroy();
      this.grid = null;

      this.org = data.org;
      this.orgAccounts = data.orgAccounts;
      this.surveys = data.surveys;

      this.getStats();

      this.focusedType = this.widgetsSpecs.find(
        (w) => !this.isItemAlreadyAdded(w.type),
      );

      this.orgReport = data.orgReports.find(
        (report) => report.report_type === "home",
      );
      const cells = this.orgReport?.widgets;
      if (!cells.length) {
        this.setDefaultHomeGrid();
      } else {
        this.items = cells.map((cell) => {
          const spec = this.widgetsSpecs.find((w) => w.type === cell.type);
          return {
            id: cell.type,
            w: spec?.width,
            h: spec?.height,
          };
        });
      }
    });
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  private setDefaultHomeGrid() {
    const cells = [
      // {
      //   type: "help",
      //   width: 12,
      //   height: 3,
      // } as OrgFlagsHomeGridCell,
      {
        type: "quote_of_the_day",
        width: "small",
        height: "265px",
      } as OrgFlagsHomeGridCell,
      {
        type: "nps",
        width: "small",
        height: undefined,
      } as OrgFlagsHomeGridCell,
      {
        type: "mau",
        width: "small",
        height: undefined,
      } as OrgFlagsHomeGridCell,
      // {
      //   type: "entitlement_mtu",
      //   width: 4,
      //   height: 2,
      // } as OrgFlagsHomeGridCell,
      {
        type: "activity",
        width: "full",
        height: undefined,
      } as OrgFlagsHomeGridCell,
      {
        type: "device_type",
        width: "medium",
        height: undefined,
      } as OrgFlagsHomeGridCell,
      {
        type: "identification",
        width: "medium",
        height: undefined,
      } as OrgFlagsHomeGridCell,
      {
        type: "emotions",
        width: "small",
        height: undefined,
      } as OrgFlagsHomeGridCell,
      {
        type: "changelog",
        width: "small",
        height: "265px",
      } as OrgFlagsHomeGridCell,
      {
        type: "last_surveys",
        width: "small",
        height: undefined,
      } as OrgFlagsHomeGridCell,
      {
        type: "entitlement_mtu",
        width: "small",
        height: undefined,
      } as OrgFlagsHomeGridCell,
    ];
    this.items = cells.map((cell) => {
      const spec = this.widgetsSpecs.find((w) => w.type === cell.type);
      return {
        id: cell.type,
        w: spec.width,
        h: spec.height,
      };
    });
  }

  private async getStats() {
    this.error = null;

    try {
      this.orgHasRespondents =
        this.org.stats.total_respondents > 0 ||
        (await this.analyticsFilterService.workspaceHasUsers(this.org.id));
    } catch (err) {
      this.error = err;
      console.error(err);
    }
  }

  private getHelloMessage = () => {
    const hours = new Date().getHours();

    if (hours >= 18 || hours < 5) {
      return "Good evening";
    } else if (hours >= 5 && hours < 12) {
      return "Good morning";
    } else {
      return "Good afternoon";
    }
  };

  public setEditingDashboard() {
    this.editingDashboard = !this.editingDashboard;
    // this.gridComp.grid.setStatic(!this.editingDashboard);
    this.gridVisible = false;
    setTimeout(() => {
      if (this.editingDashboard) {
        this.grid.destroy();
        this.dragEnabled = true;
        this.instantiateGrid();
        this.tmpItems = [...this.items];
      } else {
        this.items = [...this.tmpItems];
        this.tmpItems = [];
        this.grid.destroy();
        this.dragEnabled = false;
        this.instantiateGrid();
      }
      this.gridVisible = true;
    }, 300);
  }

  public resetHomeGrid() {
    this.gridVisible = false;
    setTimeout(() => {
      this.items = [];
      this.setDefaultHomeGrid();
      this.onSave(true, true);
      this.dragEnabled = false;
    }, 300);
  }

  public changeRange(range: OrgFlagsHomeGridRange) {
    this.orgReport.range = range;

    if (this.permissionsService.isSuperAllowed("org.update")) {
      this.onSave(false);
    }
  }

  public onSave(notify = true, reset = false) {
    this.editingDashboard = false;

    if (!reset) {
      const gridItems = this.grid.getItems().map((item) => {
        const id = item.getElement().id as OrgFlagsHomeGridCellType;
        return {
          type: id,
        };
      });

      this.items.sort((a, b) => {
        const indexA = gridItems.findIndex((v) => v.type === a.id);
        const indexB = gridItems.findIndex((v) => v.type === b.id);

        return indexA - indexB;
      });
    }

    const itemsCell = this.items.map((item) => {
      return {
        type: item.id,
        width: item.w,
        height: item.h,
      };
    });

    this.dragEnabled = false;
    this.grid.destroy();
    this.grid = undefined;
    this.gridVisible = false;

    this.orgDao
      .updateReport(
        this.org.id,
        this.orgReport.id,
        itemsCell as OrgFlagsHomeGridCell[],
        this.orgReport.range,
      )
      .then(() => {
        if (notify) {
          this.notificationHelper.trigger(
            "Success",
            "Dashboard updated",
            "success",
          );
        }
      })
      .catch((err) => {
        console.error(err);
        if (notify) {
          this.notificationHelper.trigger(
            "Error",
            "Error while saving the dashboard. Please try again later.",
            "error",
          );
        }
      });
  }

  public onFocusType(ctaTypeItem: WidgetSpec): void {
    this.focusedType = ctaTypeItem;
  }

  public addWidget(widgetSpec: WidgetSpec) {
    if (this.items.some((w) => w.id === widgetSpec.type)) {
      return;
    }

    this.isAddWidgetOpen = false;
    this.items.push({
      w: widgetSpec.width,
      h: widgetSpec.height,
      id: widgetSpec.type as OrgFlagsHomeGridCellType,
    });
    this.grid.destroy();
    this.grid = undefined;
  }

  public removeWidget(widget: {
    id: OrgFlagsHomeGridCellType;
    w: number;
    h: number;
  }) {
    this.grid.remove([
      this.grid.getItem(this.items.findIndex((i) => i.id === widget.id)),
    ]);
    this.items = this.items.filter((w) => w.id !== widget.id);
  }

  public isItemAlreadyAdded(type: string): boolean {
    return this.items.some((w) => w.id === type);
  }

  public instantiateGrid() {
    this.grid = new Muuri(".grid", {
      dragEnabled: this.dragEnabled,
      layout: { fillGaps: false },
    });
  }
}
