import { ChartDataset } from "chart.js";
import { max, min } from "date-fns";
import { AnalyticsQueryResponse } from "models/analytics.filters.type";
import { AnalyticsResponse } from "models/analytics.model";
import { deepCopy } from "utils/object";
import {
  bucketToCountPerKey,
  bucketToDatedAggregation,
  computeIndicatorVariation,
  computePercentage,
  computeVariation,
  getDateCompletionBuckets,
  getDateCompletionSupportBuckets,
} from "../indicators/indicator.utils";

const responseStatuses = [
  "inserted",
  "not_started",
  "partially_completed",
  "fully_completed",
] as const;
const supports = ["desktop", "mobile", "tablet"] as const;

export type OverallPerformancesAggregation = {
  totalDisplays: number;
  totalResponses: number;
  totalCompletedResponses: number;
  responseRate: number;
  completionRate: number;
  desktop?: {
    displays: number;
    responses: number;
    responseRate: number;
  };
  tablet?: {
    displays: number;
    responses: number;
    responseRate: number;
  };
  mobile?: {
    displays: number;
    responses: number;
    responseRate: number;
  };
  firstResponseDate?: Date;
  lastResponseDate?: Date;
  date?: Date;
};

export function getAggregations(
  lastFilters: AnalyticsQueryResponse,
  nbrDateBucket: number,
): AnalyticsQueryResponse {
  lastFilters = deepCopy(lastFilters);
  return {
    ...lastFilters,
    aggregation: [
      {
        by: "by_date",
        params: {
          date_histogram_min_interval: "day",
          date_histogram_buckets: nbrDateBucket,
          date_histogram_timezone_offset: -new Date().getTimezoneOffset(),
        },
      },
      {
        by: "by_hidden_field.value",
        params: {
          hidden_field_key: "support",
        },
      },
      { by: "by_completion" },
    ],
    size: 0,
  };
}

export function computeOverallPerformancesPeriod(
  aggregationPerDate: OverallPerformancesAggregation[],
): OverallPerformancesAggregation {
  const totalDisplays = aggregationPerDate.reduce(
    (acc: number, { totalDisplays }: OverallPerformancesAggregation): number =>
      acc + totalDisplays,
    0,
  );
  const totalResponses = aggregationPerDate.reduce(
    (acc: number, { totalResponses }: OverallPerformancesAggregation): number =>
      acc + totalResponses,
    0,
  );
  const totalCompletedResponses = aggregationPerDate.reduce(
    (
      acc: number,
      { totalCompletedResponses }: OverallPerformancesAggregation,
    ): number => acc + totalCompletedResponses,
    0,
  );
  const desktopDisplays = aggregationPerDate.reduce(
    (
      acc: number,
      { desktop: { displays } }: OverallPerformancesAggregation,
    ): number => acc + displays,
    0,
  );
  const desktopResponses = aggregationPerDate.reduce(
    (
      acc: number,
      { desktop: { responses } }: OverallPerformancesAggregation,
    ): number => acc + responses,
    0,
  );
  const tabletDisplays = aggregationPerDate.reduce(
    (
      acc: number,
      { tablet: { displays } }: OverallPerformancesAggregation,
    ): number => acc + displays,
    0,
  );
  const tabletResponses = aggregationPerDate.reduce(
    (
      acc: number,
      { tablet: { responses } }: OverallPerformancesAggregation,
    ): number => acc + responses,
    0,
  );
  const mobileDisplays = aggregationPerDate.reduce(
    (
      acc: number,
      { mobile: { displays } }: OverallPerformancesAggregation,
    ): number => acc + displays,
    0,
  );
  const mobileResponses = aggregationPerDate.reduce(
    (
      acc: number,
      { mobile: { responses } }: OverallPerformancesAggregation,
    ): number => acc + responses,
    0,
  );

  const firstResponseDate = min(
    aggregationPerDate.map(({ firstResponseDate }) => firstResponseDate),
  );

  const lastResponseDate = max(
    aggregationPerDate.map(({ lastResponseDate }) => lastResponseDate),
  );

  return {
    totalDisplays,
    totalResponses,
    totalCompletedResponses,
    responseRate: computePercentage(totalResponses, totalDisplays),
    completionRate: computePercentage(totalCompletedResponses, totalResponses),

    desktop: {
      displays: desktopDisplays,
      responses: desktopResponses,
      responseRate: computePercentage(desktopResponses, desktopDisplays),
    },
    tablet: {
      displays: tabletDisplays,
      responses: tabletResponses,
      responseRate: computePercentage(tabletResponses, tabletDisplays),
    },
    mobile: {
      displays: mobileDisplays,
      responses: mobileResponses,
      responseRate: computePercentage(mobileResponses, mobileDisplays),
    },
    date: new Date(),
    firstResponseDate,
    lastResponseDate,
  };
}

export function computeOverallPerformancesVariationBewteenPeriod(
  previousPeriod: OverallPerformancesAggregation,
  currentPeriod: OverallPerformancesAggregation,
): OverallPerformancesAggregation {
  if (!previousPeriod.totalDisplays) {
    return null;
  }

  return {
    totalDisplays: computeVariation(
      previousPeriod.totalDisplays,
      currentPeriod.totalDisplays,
    ),
    totalResponses: computeVariation(
      previousPeriod.totalResponses,
      currentPeriod.totalResponses,
    ),
    totalCompletedResponses: computeVariation(
      previousPeriod.totalCompletedResponses,
      currentPeriod.totalCompletedResponses,
    ),
    responseRate: computeIndicatorVariation(
      previousPeriod.totalResponses,
      previousPeriod.totalDisplays,
      currentPeriod.totalResponses,
      currentPeriod.totalDisplays,
    ),
    completionRate: computeIndicatorVariation(
      previousPeriod.totalCompletedResponses,
      previousPeriod.totalResponses,
      currentPeriod.totalCompletedResponses,
      currentPeriod.totalResponses,
    ),

    desktop: {
      displays: computeVariation(
        previousPeriod.desktop.displays,
        currentPeriod.desktop.displays,
      ),
      responses: computeVariation(
        previousPeriod.desktop.responses,
        currentPeriod.desktop.responses,
      ),
      responseRate: computeIndicatorVariation(
        previousPeriod.desktop.responses,
        previousPeriod.desktop.displays,
        currentPeriod.desktop.responses,
        currentPeriod.desktop.displays,
      ),
    },
    tablet: {
      displays: computeVariation(
        previousPeriod.tablet.displays,
        currentPeriod.tablet.displays,
      ),
      responses: computeVariation(
        previousPeriod.tablet.responses,
        currentPeriod.tablet.responses,
      ),
      responseRate: computeIndicatorVariation(
        previousPeriod.tablet.responses,
        previousPeriod.tablet.displays,
        currentPeriod.tablet.responses,
        currentPeriod.tablet.displays,
      ),
    },
    mobile: {
      displays: computeVariation(
        previousPeriod.mobile.displays,
        currentPeriod.mobile.displays,
      ),
      responses: computeVariation(
        previousPeriod.mobile.responses,
        currentPeriod.mobile.responses,
      ),
      responseRate: computeIndicatorVariation(
        previousPeriod.mobile.responses,
        previousPeriod.mobile.displays,
        currentPeriod.mobile.responses,
        currentPeriod.mobile.displays,
      ),
    },
  };
}

export function bucketToOverallPerformancesAggregation(
  oneDayOverallPerformances: Record<string, any>,
): OverallPerformancesAggregation {
  const total = bucketToCountPerKey(
    getDateCompletionBuckets(oneDayOverallPerformances),
    responseStatuses,
  );

  const [inserted, notStarted, partiallyCompleted, fullyCompleted] =
    responseStatuses.map((completion) =>
      bucketToCountPerKey(
        getDateCompletionSupportBuckets(oneDayOverallPerformances, completion),
        supports,
      ),
    );

  const desktopResponses =
    partiallyCompleted.countPerKey.desktop + fullyCompleted.countPerKey.desktop;
  const desktopDisplays = desktopResponses + notStarted.countPerKey.desktop;

  const tabletResponses =
    partiallyCompleted.countPerKey.tablet + fullyCompleted.countPerKey.tablet;
  const tabletDisplays = tabletResponses + notStarted.countPerKey.tablet;

  const mobileResponses =
    partiallyCompleted.countPerKey.mobile + fullyCompleted.countPerKey.mobile;
  const mobileDisplays = mobileResponses + notStarted.countPerKey.mobile;

  const date = oneDayOverallPerformances.date;

  return {
    totalDisplays: total.total - inserted.total,
    totalResponses:
      total.countPerKey.partially_completed + total.countPerKey.fully_completed,
    totalCompletedResponses: total.countPerKey.fully_completed,
    responseRate: computePercentage(
      total.countPerKey.partially_completed + total.countPerKey.fully_completed,
      total.total,
    ),
    completionRate: computePercentage(
      total.countPerKey.fully_completed,
      total.countPerKey.partially_completed + total.countPerKey.fully_completed,
    ),

    desktop: {
      displays: desktopDisplays,
      responses: desktopResponses,
      responseRate: computePercentage(desktopResponses, desktopDisplays),
    },
    tablet: {
      displays: tabletDisplays,
      responses: tabletResponses,
      responseRate: computePercentage(tabletResponses, tabletDisplays),
    },
    mobile: {
      displays: mobileDisplays,
      responses: mobileResponses,
      responseRate: computePercentage(mobileResponses, mobileDisplays),
    },
    date,
    firstResponseDate: date,
    lastResponseDate: date,
  };
}

export function overallPerformancesPerDateToChartDataset(
  overallPerformancesPerDate: OverallPerformancesAggregation[],
  industryAverageResponseRate: number,
): ChartDataset[] {
  const overallPerformancesToData = (
    field:
      | "totalDisplays"
      | "totalResponses"
      | "responseRate"
      | "completionRate",
  ) =>
    overallPerformancesPerDate.map((overallPerformances) => {
      return {
        x: +overallPerformances.date,
        y: overallPerformances[field],
      };
    });

  const style: ChartDataset = {
    yAxisID: "main",
    fill: "transparent",
    data: [],
  };

  return [
    {
      yAxisID: "secondary",
      data: overallPerformancesToData("completionRate").map((data) => ({
        ...data,
        y: industryAverageResponseRate,
      })),
      borderColor: "rgba(8, 101, 84, 0.5)",
      fill: "transparent",
      pointBorderColor: "transparent",
      pointBorderWidth: 0,
      pointHoverRadius: 0,
      pointRadius: 0,
      borderDash: [4, 10],
      borderDashOffset: 0,
    },
    {
      ...style,
      yAxisID: "secondary",
      label: "Completion rate",
      borderColor: "#0094FF",
      pointHoverBorderColor: "#FFFFFF",
      pointBackgroundColor: "#0094FF",
      pointHoverBackgroundColor: "#0094FF",
      data: overallPerformancesToData("completionRate"),
    },
    {
      ...style,
      yAxisID: "secondary",
      label: "Response rate",
      borderColor: "#1ED5A4",
      pointHoverBorderColor: "#FFFFFF",
      pointBackgroundColor: "#1ED5A4",
      pointHoverBackgroundColor: "#1ED5A4",
      data: overallPerformancesToData("responseRate"),
    },
    {
      ...style,
      label: "Answers",
      borderColor: "#0054B6",
      pointHoverBorderColor: "#FFFFFF",
      pointBackgroundColor: "#0054B6",
      pointHoverBackgroundColor: "#0054B6",
      data: overallPerformancesToData("totalResponses"),
    },
    {
      ...style,
      label: "Displays",
      borderColor: "#5E21F1",
      pointHoverBorderColor: "#FFFFFF",
      pointBackgroundColor: "#5E21F1",
      pointHoverBackgroundColor: "#5E21F1",
      data: overallPerformancesToData("totalDisplays"),
    },
  ];
}

export async function getResponseCallback(
  previousPeriod: AnalyticsResponse,
  currentPeriod: AnalyticsResponse,
  allTimePeriod: AnalyticsResponse,
  industryAverageResponseRate: number,
) {
  const {
    min_date: { value_as_string: minDate },
    max_date: { value_as_string: maxDate },
  } = allTimePeriod.aggregations;

  const overallPerformancesPerDatePreviousPeriod = bucketToDatedAggregation(
    previousPeriod,
    bucketToOverallPerformancesAggregation,
  );

  const overallPerformancesPerDateCurrentPeriod = bucketToDatedAggregation(
    currentPeriod,
    bucketToOverallPerformancesAggregation,
  );

  const trendChartDataset = overallPerformancesPerDateToChartDataset(
    overallPerformancesPerDateCurrentPeriod,
    industryAverageResponseRate,
  );
  const overallPerformancesPreviousPeriod = computeOverallPerformancesPeriod(
    overallPerformancesPerDatePreviousPeriod,
  );
  const overallPerformancesCurrentPeriod = computeOverallPerformancesPeriod(
    overallPerformancesPerDateCurrentPeriod,
  );

  const overallPerformancesVariation =
    computeOverallPerformancesVariationBewteenPeriod(
      overallPerformancesPreviousPeriod,
      overallPerformancesCurrentPeriod,
    );

  overallPerformancesCurrentPeriod.firstResponseDate = new Date(minDate);
  overallPerformancesCurrentPeriod.lastResponseDate = new Date(maxDate);

  return {
    overallPerformancesPerDatePreviousPeriod,
    overallPerformancesPerDateCurrentPeriod,
    trendChartDataset,
    overallPerformancesPreviousPeriod,
    overallPerformancesCurrentPeriod,
    overallPerformancesVariation,
  };
}
