import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HttpErrorResponse } from "@angular/common/http";

import { PageComponentInterface } from "components/PageComponentInterface";
import { RoutingService } from "services/routing.service";
import { SuperOrgDao } from "models/super-org.dao";
import { SuperOrg } from "models/super-org.model";

@Component({
  selector: "page-org-create",
  templateUrl: "./create.component.html",
  styleUrls: ["./create.component.scss"],
})
export class CreateSuperOrgPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "New organization";
  public name = "New organization";

  private obs: any = null;

  public loading: boolean = false;
  public errors: any = null;

  public validateForm: FormGroup = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private superOrgDao: SuperOrgDao,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.validateForm = this.formBuilder.group({
      name: [
        null,
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(128),
        ],
      ],
    });
  }

  ngOnDestroy() {
    if (this.obs) this.obs.unsubscribe();
  }

  public onSubmit() {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (!this.validateForm.valid) return;

    this.loading = true;
    this.errors = null;

    const value = this.validateForm.value;
    this.superOrgDao
      .create(value.name)
      .then((superOrg: SuperOrg) => {
        this.errors = null;
        this.router.navigate([`/overview/${superOrg.id}/`]);
      })
      .catch((err: HttpErrorResponse) => {
        this.errors = err.error;
        console.error(err.error);
      })
      .then(() => {
        this.loading = false;
      });
  }
}
