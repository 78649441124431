import { NzSelectOptionInterface } from "ng-zorro-antd/select";

import { endOfDay, isDate, startOfDay } from "date-fns";

import { GraphNode } from "components/builder/flow";
import {
  AnalyticsFilter,
  AnalyticsFilters,
  AnalyticsFiltersOperator,
  AnalyticsQuery,
} from "models/analytics.filters.type";
import { ChannelDao } from "models/channel.dao";
import { Channel } from "models/channel.model";
import { Org } from "models/org.model";
import { RegistryEntry } from "models/registry.model";
import { Survey } from "models/survey.model";
import { LanguageWithEmoji } from "resolvers/asset-languages-countries";
import { AnalyticsFilterService } from "services/analytics-filters.service";
import { TrackersService } from "services/trackers.service";
import { UIService } from "services/ui.service";
import {
  FilterGroup,
  operators,
  valueComponents,
} from "./filters/filter-criteria-filters.component";

export type valueComponent =
  | "none"
  | "text"
  | "number"
  | "boolean"
  | "time"
  | "tag_input"
  | "preset_compare"
  | "preset_select"
  | "channel_select";

export type AvailableField = {
  value: {
    type: AnalyticsFilter["type"];
    key: AnalyticsFilter["key"];
  };
  label: string;
  groupLabel?: FilterGroup;
};

export class CriteriaFilterStatsSurveyComponent {
  public channels: Channel[] = null;

  public nodes: GraphNode[] = [];
  public nodesByKey: object = {};

  public loadingDropdown = false;
  public visibleDropdown = false;

  public lastFilters: AnalyticsQuery;
  public filters: AnalyticsFilters = null;
  public filtersValidation: object[] = null;
  public filtersOperator: AnalyticsFiltersOperator = null;

  public keysOptions: NzSelectOptionInterface[] = [];

  public registryEntriesGroup: RegistryEntry[] = [];

  public onlyDropdown = false;

  public availableLanguages: LanguageWithEmoji[];
  public availableTags: NzSelectOptionInterface[] = [];

  protected operators = operators;
  protected valueComponents = valueComponents;

  public title = "Segment data";

  constructor(
    protected analyticsFilterService: AnalyticsFilterService,
    protected channelDao: ChannelDao,
    protected trackersService: TrackersService,
    protected uiService: UIService,
  ) {
    // Needed for template
    this.getAvailableFields = this.getAvailableFields.bind(this);
  }

  public async switchPanelVisibility(visible: boolean) {
    if (visible) {
      this.loadingDropdown = true;
      this.visibleDropdown = true;
      this.initPanel();
    } else {
      this.closePanel();
      this.visibleDropdown = false;
    }
  }

  protected async initPanel() {}

  protected getOrg(): Org {
    return null;
  }

  protected getSurvey(): Survey {
    return null;
  }

  public fetchChannels(): Promise<Channel[]> {
    return this.channelDao.getAllByOrgId(this.uiService.currentOrgId);
  }

  public onSave() {
    this.filters.forEach((f) => {
      if (f.value && isDate(f.value)) {
        f.value = f.operator
          ? f.operator === "before"
            ? startOfDay(f.value)
            : endOfDay(f.value)
          : f.value;
      }
    });

    this.analyticsFilterService.setFilters(this.filtersOperator, this.filters);

    const trackingEvent = this.trackersService
      .newEventTrackingBuilder("Reporting Segmented")
      .withOrg(this.getOrg())
      .withAnalyticsFilters(this.filters, this.lastFilters.type);

    if (this.lastFilters.type === "response") {
      trackingEvent.withSurvey(this.getSurvey());
    }

    trackingEvent.build();

    this.switchPanelVisibility(false);

    this.visibleDropdown = false;
  }

  protected closePanel() {
    this.lastFilters = null;
    this.filters = null;
    this.filtersValidation = null;
    this.filtersOperator = null;
  }

  protected async getAvailableFields() {
    this.keysOptions = [];
  }

  protected getDefaultFilter(_channels): AnalyticsFilter {
    return null;
  }

  /**
   * Filters CRUD / validation
   */
  public onFilterAdded(channels: Channel[]) {
    this.filters.push(
      this.getDefaultFilter(channels) as never, // wtf
    );
  }

  /**
   * Filter keys
   */
  public compareFilterKeys(a: AnalyticsFilter, b: AnalyticsFilter): boolean {
    return (
      !!a &&
      !!b &&
      !!a.type && // && !!b.type
      !!a.key && // && !!b.key
      a.type === b.type &&
      a.key === b.key
    );
  }

  public onFilterKeyChange(f: AnalyticsFilter, { type, key }) {
    f.type = type;
    f.key = key;

    const availbleOperators = this.getOperatorOptions(f).map(
      (opt) => opt.value,
    );
    if (!availbleOperators.includes(f.operator)) {
      f.operator = availbleOperators[0];
    }
    this.onOperatorChange(f);
  }

  /**
   * Filter operators
   */
  public onOperatorChange(f: AnalyticsFilter) {
    this.setDefaultValue(f);
  }

  // inherited
  public getOperatorOptions(_: AnalyticsFilter): NzSelectOptionInterface[] {
    return [];
  }

  /**
   * Filter value
   */
  public setDefaultValue(f: AnalyticsFilter) {
    (f as any).value = null;
    (f as any).values = null;
    (f as any).action_correlation_ids = null;

    const valueComponent = this.getValueComponent(f);

    if (valueComponent === "boolean") {
      (f as any).value = true;
    }
  }

  // inherited
  public getValueComponent(_: AnalyticsFilter): valueComponent {
    return "none";
  }

  public getValueSuffix(filter: AnalyticsFilter): string | null {
    if (filter.type === "respondent.event" && filter.operator !== "not_null") {
      return "times";
    }
    return null;
  }

  public getValueNumberMin(filter: AnalyticsFilter): number | null {
    if (
      filter.type === "respondent.event" &&
      ["gt", "lt"].includes(filter.operator)
    ) {
      return 0;
    } else if (
      filter.type === "respondent.event" &&
      ["eq"].includes(filter.operator)
    ) {
      return 1;
    }
    return null;
  }

  public getValuePresetOptions(_: AnalyticsFilter): NzSelectOptionInterface[] {
    return [];
  }

  public getLabel(_: AnalyticsFilter, __: string): string {
    return "";
  }

  public getValue(_: AnalyticsFilter, __: string): string | number {
    return "";
  }

  public onPresetCompareChange(_: AnalyticsFilter) {}

  public onDateChange(f: AnalyticsFilter) {
    if (["after", "before"].includes(f.operator)) {
      (f as any).value = startOfDay((f as any).value);
    }
  }
}
