import { Component, Input, OnInit } from "@angular/core";
import { integrationUpdate } from "components/integration/settings/integration-settings.component";
import { Integration, IntegrationType } from "models/integrations.model";
import { Org } from "models/org.model";
import { Survey, SurveyIntegrations } from "models/survey.model";

import { FeatureFlaggingService } from "services/feature-flagging.service";
import { EntitlementService } from "services/entitlement.service";
import { UIService } from "services/ui.service";

@Component({
  selector: "tag-settings-integrations",
  templateUrl: "./tag-settings-integrations.component.html",
  styleUrls: ["./tag-settings-integrations.component.scss"],
})
export class TagSettingsIntegrationsComponent implements OnInit {
  @Input() public org: Org = null;
  @Input() public survey: Survey = null;
  @Input() public surveyIntegrations: SurveyIntegrations = null;
  @Input() public orgIntegrations: Integration[] = null;
  @Input() public scope: "survey" = "survey" as const;

  public integrationsDiff: string = null;
  public webhookValid: boolean = true;
  public cobbaiValid: boolean = true;

  public error: string = null;

  constructor(
    public featureFlaggingService: FeatureFlaggingService,
    public entitlementService: EntitlementService,
    public uiService: UIService,
  ) {}

  ngOnInit() {
    this.integrationsDiff = JSON.stringify(this.surveyIntegrations);
  }

  public hasNotChanged() {
    return this.integrationsDiff === JSON.stringify(this.surveyIntegrations);
  }

  public onWebhookChange(update: integrationUpdate) {
    this.surveyIntegrations.webhook = update.settings.webhook;
  }

  public onCobbaiChange(update: integrationUpdate) {
    this.surveyIntegrations.cobbai = update.settings.cobbai;
  }

  public integrationIsCreatedInOrganization(type: IntegrationType): boolean {
    return !!this.orgIntegrations?.find(
      (integration: Integration) => integration.type === type,
    );
  }
}
