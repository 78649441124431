import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router } from "@angular/router";
import { OrgStats } from "models/org.model";

import { SuperOrgDao } from "models/super-org.dao";
import { ApplicationLoadService } from "services/app.service";

@Injectable()
export class OrgIndustryStatsResolver implements Resolve<any> {
  constructor(
    private router: Router,
    private applicationLoadService: ApplicationLoadService,
    private superOrgDao: SuperOrgDao,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Promise<OrgStats[]> {
    return this.applicationLoadService
      .loader()
      .then(() => {
        return this.superOrgDao.getAllStatsByIndustry(route.params["org_id"]);
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        throw err;
      });
  }
}
