<ul id="steps-menu">
  <li
    *ngFor="let step of steps; let idx = index"
    [class]="{
      active: idx === currentStep,
      disabled: idx !== currentStep,
    }"
  >
    <div (click)="onStepClick(idx)" nzType="primary" nzSize="large">
      <span class="badge">
        <ng-container *ngIf="!validatePastSteps || idx >= currentStep">
          {{ idx + 1 }}
        </ng-container>
        <screeb-icon
          *ngIf="validatePastSteps && idx < currentStep"
          size="sm"
          icon="rounded-tick"
        ></screeb-icon>
      </span>

      {{ step }}
    </div>
  </li>
</ul>
