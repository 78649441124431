<div *ngIf="rule">
  <div
    class="rule-type-event-time-values"
    *ngIf="rule.operator && registryEntriesEvent.length > 0"
  >
    <rule-input-number
      [disabled]="!!rule.sequence_id"
      [(value)]="nbr"
      (valueChange)="onValueChange()"
      [step]="1"
      [minValue]="1"
      [maxValue]="999999"
      [placeholder]="rule.getValuePlaceholder()"
      [invalid]="!checkValidTime(rule)"
      [disabled]="!('survey_edition' | hasEntitlement)"
    ></rule-input-number>

    <nz-select
      [disabled]="!!rule.sequence_id"
      [(ngModel)]="unit"
      (ngModelChange)="onValueChange()"
      [nzDropdownMatchSelectWidth]="false"
      [nzOptions]="unitOptions"
      [nzDisabled]="!('survey_edition' | hasEntitlement)"
    ></nz-select>

    <span class="suffix">ago</span>
  </div>
</div>

<div *ngIf="registryEntriesEvent.length === 0">
  <span>
    You don’t collect visitor events yet. Learn how to do it to use this trigger
    <a
      href="https://help.screeb.app/en/articles/8611585-all-about-users-events"
      target="_blank"
    >
      here
    </a>
    .
  </span>
</div>
