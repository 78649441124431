<ul nz-menu>
  <li id="menu-stats-survey-analytics" nz-menu-item nzMatchRouter>
    <a
      routerLink="/org/{{ uiService.currentOrgId }}/{{
        uiService.surveyTypePath
      }}/{{ uiService.currentSurveyId }}/stats/overall-performances"
      [queryParams]="searchParams"
      routerLinkActive="active"
    >
      <screeb-icon size="md" icon="analytics"></screeb-icon>
      {{ uiService.isMessagePage ? "Message" : "Survey" }} analytics
    </a>
  </li>

  <li id="menu-stats-survey-breakdown" nz-menu-item nzMatchRouter>
    <a
      routerLink="/org/{{ uiService.currentOrgId }}/{{
        uiService.surveyTypePath
      }}/{{ uiService.currentSurveyId }}/stats/flow-analysis"
      [queryParams]="searchParams"
      routerLinkActive="active"
    >
      <screeb-icon size="sm" icon="flow"></screeb-icon>
      {{ uiService.isMessagePage ? "Message" : "Survey" }} breakdown
    </a>
  </li>
  <li id="menu-stats-all-displays" nz-menu-item nzMatchRouter>
    <a
      routerLink="/org/{{ uiService.currentOrgId }}/{{
        uiService.surveyTypePath
      }}/{{ uiService.currentSurveyId }}/stats/all-displays"
      [queryParams]="searchParams"
      routerLinkActive="active"
    >
      <screeb-icon size="sm" icon="analyze"></screeb-icon>
      All displays
    </a>
  </li>
  <li id="menu-stats-all-responses" nz-menu-item nzMatchRouter>
    <a
      routerLink="/org/{{ uiService.currentOrgId }}/{{
        uiService.surveyTypePath
      }}/{{ uiService.currentSurveyId }}/stats/all-responses"
      [queryParams]="searchParams"
      routerLinkActive="active"
    >
      <screeb-icon size="sm" icon="comment"></screeb-icon>
      All responses
    </a>
  </li>
  <ng-container *ngIf="!uiService.isMessagePage">
    <li id="menu-stats-content-analysis" nz-menu-item nzMatchRouter>
      <a
        routerLink="/org/{{ uiService.currentOrgId }}/{{
          uiService.surveyTypePath
        }}/{{ uiService.currentSurveyId }}/stats/content-analysis"
        [queryParams]="searchParams"
        routerLinkActive="active"
      >
        <screeb-icon size="md" icon="studies"></screeb-icon>
        Content analysis
        <screeb-icon
          *ngIf="!('content_analysis' | hasEntitlement)"
          size="sm"
          icon="upgrade"
        ></screeb-icon>
      </a>
    </li>
    <li id="menu-stats-nps" nz-menu-item nzMatchRouter>
      <a
        routerLink="/org/{{ uiService.currentOrgId }}/{{
          uiService.surveyTypePath
        }}/{{ uiService.currentSurveyId }}/stats/nps"
        [queryParams]="searchParams"
        routerLinkActive="active"
      >
        <screeb-icon size="md" icon="nps"></screeb-icon>
        Net Promoter Score
      </a>
    </li>
    <li id="menu-stats-csat" nz-menu-item nzMatchRouter>
      <a
        routerLink="/org/{{ uiService.currentOrgId }}/{{
          uiService.surveyTypePath
        }}/{{ uiService.currentSurveyId }}/stats/csat"
        [queryParams]="searchParams"
        routerLinkActive="active"
      >
        <screeb-icon size="sm" icon="talk-to-a-human"></screeb-icon>
        Customer Satisfaction
      </a>
    </li>
    <li id="menu-stats-ces" nz-menu-item nzMatchRouter>
      <a
        routerLink="/org/{{ uiService.currentOrgId }}/{{
          uiService.surveyTypePath
        }}/{{ uiService.currentSurveyId }}/stats/ces"
        [queryParams]="searchParams"
        routerLinkActive="active"
      >
        <screeb-icon size="md" icon="ces"></screeb-icon>
        Customer Effort Score
      </a>
    </li>

    <li
      id="menu-stats-pmf"
      nz-menu-item
      nzMatchRouter
      *ngIf="'isProductMarketFitEnabled' | hasFeatureFlag"
    >
      <a
        routerLink="/org/{{ uiService.currentOrgId }}/{{
          uiService.surveyTypePath
        }}/{{ uiService.currentSurveyId }}/stats/product-market-fit"
        [queryParams]="searchParams"
        routerLinkActive="active"
      >
        Product Market Fit
      </a>
    </li>
  </ng-container>
  <li
    id="menu-connect"
    nz-menu-item
    nzMatchRouter
    *ngIf="'integration.create' | hasPermission"
  >
    <hr />
    <a
      routerLink="/org/{{ uiService.currentOrgId }}/{{
        uiService.surveyTypePath
      }}/{{ uiService.currentSurveyId }}/stats/connect"
      [queryParams]="searchParams"
      routerLinkActive="active"
    >
      <screeb-icon size="sm" icon="link"></screeb-icon>
      Connect
    </a>
  </li>
</ul>
<div class="spacer"></div>
<ul nz-menu nzMode="inline" class="screeb-menu help-menu">
  <ng-template #helpMenu>
    <screeb-icon
      size="sm"
      icon="question-mark"
      style="margin-right: 0.35rem"
    ></screeb-icon>
    Need help?
  </ng-template>
  <li nz-submenu [nzTitle]="helpMenu">
    <ul>
      <ng-container *ngFor="let article of posts101">
        <li nz-menu-item>
          <a
            class="help-article"
            href="{{ article.url }}"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span [innerHtml]="article.title"></span>
          </a>
        </li>
      </ng-container>
    </ul>
  </li>
</ul>
