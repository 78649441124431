<ng-template #variablesHelpTemplate>
  <p style="max-width: 500px; word-break: break-word">
    Montly Active Users or Monthly Tracked Users are the number of unique users
    who triggered at least one event in your app in the last month. To estimate
    your number of MAUs, add your Monthly Active Users to the number of
    anonymous visitors you get every month.
    <br />
    For SaaS or mobile apps, MAUs are usually the number of users who logged in
    during the month. If most of your users are not logged in, then MAU is
    similar to Monthly Unique Sessions.
  </p>
</ng-template>

<nz-card class="mau-usage-container">
  <div class="mau-usage-header">
    <div class="title">
      <screeb-icon
        *ngIf="
          (uiService.currentSuperOrg.entitlements.mtu_mode === 'auto'
            ? uiService.currentSuperOrg
            : uiService.currentOrg
          ).usedMTUPercent > 80
        "
        size="sm"
        icon="warning"
      ></screeb-icon>

      <span>Quota Usage</span>
      <span
        nz-popover
        [nzPopoverContent]="variablesHelpTemplate"
        nzPopoverPlacement="rightTop"
      >
        <i nz-icon nzType="info-circle" nzTheme="outline"></i>
      </span>
    </div>
  </div>

  <div class="mau-usage-body">
    You have used
    <ng-container
      *ngIf="uiService.currentSuperOrg.entitlements.mtu_scheme === 'limited'"
    >
      <span
        class="used-mau"
        [style]="{
          color:
            (uiService.currentSuperOrg.entitlements.mtu_mode === 'auto'
              ? uiService.currentSuperOrg
              : uiService.currentOrg
            ).usedMTUPercent <= 80
              ? 'var(--screeb-color-primary)'
              : 'var(--screeb-color-error)',
        }"
      >
        {{
          uiService.currentSuperOrg.entitlements.mtu_mode === "auto"
            ? uiService.currentSuperOrg.usedMTUPercent
            : uiService.currentOrg.usedMTUPercent
        }}%
      </span>
      of your monthly active user quota this month.
    </ng-container>
    <ng-container
      *ngIf="uiService.currentSuperOrg.entitlements.mtu_scheme !== 'limited'"
    >
      <span
        class="used-mau"
        [style]="{
          color: 'var(--screeb-color-primary)',
        }"
      >
        (uiService.currentSuperOrg.entitlements.mtu_mode === "auto" ?
        uiService.currentSuperOrg.usedMTU : uiService.currentOrg.usedMTU ) |
        format: "number"
      </span>
      of monthly active user this month.
    </ng-container>

    <p class="ratio-mau">
      {{
        (uiService.currentSuperOrg.entitlements.mtu_mode === "auto"
          ? uiService.currentSuperOrg.usedMTU
          : uiService.currentOrg.usedMTU
        ) | format: "number"
      }}
      <span>
        &thinsp;/&thinsp;{{
          uiService.currentSuperOrg.entitlements.mtu_scheme === "limited"
            ? ((uiService.currentSuperOrg.entitlements.mtu_mode === "auto"
                ? uiService.currentSuperOrg.maxMTU
                : uiService.currentOrg.maxMTU
              ) | format: "number")
            : "∞"
        }}
      </span>
      <span>mau</span>
    </p>

    <nz-progress
      class="progress-bar"
      [nzPercent]="
        (uiService.currentSuperOrg.entitlements.mtu_mode === 'auto'
          ? uiService.currentSuperOrg
          : uiService.currentOrg
        ).usedMTUPercent
      "
      [nzShowInfo]="false"
      nzStrokeWidth="16"
      [nzStrokeColor]="
        (uiService.currentSuperOrg.entitlements.mtu_mode === 'auto'
          ? uiService.currentSuperOrg
          : uiService.currentOrg
        ).usedMTUPercent <= 80
          ? 'var(--screeb-color-primary)'
          : 'var(--screeb-color-error)'
      "
      [nzStatus]="
        (uiService.currentSuperOrg.entitlements.mtu_mode === 'auto'
          ? uiService.currentSuperOrg
          : uiService.currentOrg
        ).usedMTUPercent > 80
          ? 'exception'
          : 'normal'
      "
    ></nz-progress>
  </div>
</nz-card>
