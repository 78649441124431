import { Component, Input, OnDestroy, OnInit } from "@angular/core";

import {
  RegistryEntry,
  RegistryEntrySourceFormatted,
} from "models/registry.model";
import { UserRecord } from "models/user-record.model";
import { UIService } from "services/ui.service";
import {
  NzTableQueryParams,
  NzTableSortFn,
  NzTableSortOrder,
} from "ng-zorro-antd/table";
import {
  AnalyticsQuerySession,
  AnalyticsQueryUsers,
  SortForSession,
  SortOrder,
} from "models/analytics.filters.type";
import { UUID } from "models/survey.dao.types";
import { Org } from "models/org.model";
import { AnalyticsDao } from "models/analytics.dao";
import { AnalyticsFilterService } from "services/analytics-filters.service";
import { deepCopy } from "utils/object";
import { Debounce } from "utils/debounce";
import { AnalyticsResponseItemUser } from "models/analytics.model";
import { UserRecordDao } from "models/user-record.dao";
import { intervalToDuration } from "date-fns";
import { LanguageWithEmoji } from "resolvers/asset-languages-countries";
import { emojiTranscoder } from "components/builder/components/Cards/sanitized-message/emojis";
import { User } from "models/user.model";

interface ColumnItem {
  name: string;
  sortKey?: string;
  width?: string;
  align: "left" | "right" | "center";
  sortOrder: NzTableSortOrder | null;
  sortFn: NzTableSortFn | null | true;
  filterMultiple: boolean;
  sortDirections: NzTableSortOrder[];
}

@Component({
  selector: "user-records",
  templateUrl: "./user-records.component.html",
  styleUrls: ["./user-records.component.scss"],
})
export class UserRecordsComponent implements OnInit, OnDestroy {
  @Input() public org: Org;
  @Input() public user: User | null = null;
  @Input() public pageSize = 25;
  @Input() public registryEntriesIdentityProperty: RegistryEntry[] = [];
  @Input() public registryEntriesGroup: RegistryEntry[] = [];
  @Input() public registryEntriesEvent: RegistryEntry[] = [];
  @Input() public languages: LanguageWithEmoji[] = [];

  public registryEntrySourceFormatted = RegistryEntrySourceFormatted;
  public emojiTranscoder = emojiTranscoder;

  protected filtersObs$: any = null;
  public lastFilters: AnalyticsQuerySession;

  public keyword = "";
  public searching = false;
  public loading = true;
  public isUsersLoading = true;

  public error: Error;
  public pageIndex = 1;
  public maxOffset = 0;
  public sessions: UserRecord[] = [];
  public suggestedSessions: UserRecord[] = [null, null]; // loading state
  public users: Record<string, AnalyticsResponseItemUser> = {};

  public isSummaryLoading = true;
  public summaryTrimLength = 450;
  public summary: string | null = null;
  public summaryError: Error;
  public viewMoreSummary = false;

  constructor(
    public uiService: UIService,
    private analyticsDao: AnalyticsDao,
    public analyticsFilterService: AnalyticsFilterService,
    private userRecorDao: UserRecordDao,
  ) {}

  private sort?: SortForSession = {
    field: "session.started_at",
    order: "desc",
  };

  public listOfColumns: ColumnItem[] = [
    {
      name: "",
      width: "88px",
      align: "center",
      sortOrder: undefined,
      sortFn: undefined,
      sortDirections: [],
      filterMultiple: true,
    },
    {
      name: "Highlights",
      width: "275px",
      align: "left",
      sortOrder: undefined,
      sortFn: undefined,
      sortDirections: [],
      filterMultiple: true,
    },
    {
      name: "User",
      width: "200px",
      align: "left",
      sortOrder: undefined,
      sortFn: undefined,
      sortDirections: [],
      filterMultiple: false,
    },
    {
      name: "Duration",
      width: "150px",
      sortKey: "session.duration",
      align: "left",
      sortOrder: "",
      sortFn: true,
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
    },
    {
      name: "Date and device",
      width: "150px",
      sortKey: "session.started_at",
      align: "left",
      sortOrder: "descend",
      sortFn: true,
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
    },
    {
      name: "Pertinence",
      width: "75px",
      sortKey: "session.score",
      align: "left",
      sortOrder: "",
      sortFn: true,
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
    },
  ];

  ngOnInit() {
    if (this.user) {
      this.listOfColumns = this.listOfColumns.filter((c) => c.name !== "User");
    }

    this.resetFilters();
  }

  ngOnDestroy() {
    if (this.filtersObs$) {
      this.filtersObs$.unsubscribe();
    }
  }

  private resetFilters() {
    this.analyticsFilterService.reset(
      "session",
      this.org.id,
      [],
      this.org.created_at,
      this.org.created_at,
    );

    this.filtersObs$?.unsubscribe();
    this.filtersObs$ = this.analyticsFilterService
      .subscribe()
      .subscribe((filters: AnalyticsQuerySession) => {
        this.lastFilters = deepCopy(filters);

        this.getSessions();
      });
  }

  public onQueryParamsChange(params: NzTableQueryParams) {
    let { pageIndex } = params;
    const { pageSize } = params;
    const { key, value } = params.sort.find(({ value }) => Boolean(value));

    const sort = {
      field: key as SortForSession["field"],
      order: this.adaptSortDirection(value),
    };

    if (pageSize !== this.pageSize) {
      pageIndex = 1;
    }

    const pageChange =
      pageIndex !== this.pageIndex || pageSize !== this.pageSize;
    const sortChange =
      sort.field !== this.sort.field || sort.order !== this.sort.order;

    if (!pageChange && !sortChange) return;

    this.sort = sort;
    this.pageSize = pageSize;
    this.pageIndex = pageIndex;

    this.getSessions();
  }

  private adaptSortDirection(value: string): SortOrder {
    switch (value) {
      case "descend":
        return "desc";
      case "ascend":
      default:
        return "asc";
    }
  }

  @Debounce(250)
  private getSessions() {
    this.loading = true;
    this.error = null;

    const query = deepCopy(this.lastFilters) as AnalyticsQuerySession;
    query.range.start = this.org.created_at;
    query.range.end = new Date();
    query.size = this.pageSize;
    query.offset = (this.pageIndex - 1) * this.pageSize;
    query.sort = deepCopy(this.sort);

    if (this.keyword?.length) {
      query.filters.push({
        type: "session",
        key: "summary",
        operator: "contains",
        value: this.keyword,
      });
    }

    if (this.user?.id) {
      query.filters.push({
        type: "session",
        key: "user_id",
        operator: "eq",
        value: this.user.id,
      });
    }

    return this.analyticsDao
      .search(query)
      .then((res) => {
        this.maxOffset = Math.min(res?.hits?.total || 0, 10000);
        this.sessions = res?.hits?.sessions || [];
        this.suggestedSessions = res?.hits?.suggested_sessions || [];
        this.loading = false;

        const userIDs = this.sessions
          .map((r) => r.user_id)
          .concat(this.suggestedSessions.slice(0, 2).map((r) => r.user_id));

        this.getUsersByIds(userIDs);
        this.getSummary();
      })
      .catch((error) => {
        // this.errorRecords = error;
        console.error(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private async getUsersByIds(ids: string[]) {
    // Filters ids already fetched
    const idsToFetch = Array.from(new Set(ids)).filter((id) => !this.users[id]);
    if (idsToFetch.length === 0) {
      return;
    }

    this.isUsersLoading = true;
    const query: AnalyticsQueryUsers = {
      org_id: UUID(this.org.id),
      survey_ids: ["*"],
      filters_bool: "OR",
      type: "respondent",
      filters: idsToFetch.map((id) => ({
        type: "respondent",
        key: "aliases",
        operator: "eq",
        value: id,
      })),
      range: {
        start: this.org.created_at,
        end: new Date(),
        field: "created_at",
      },
      identified_only: false,
      size: idsToFetch.length,
    };
    const response = await this.analyticsDao.search(query);
    for (const user of response.hits.respondents ?? []) {
      this.users[user.id] = user;
    }
    this.isUsersLoading = false;
  }

  public onSearchChange() {
    this.getSessions();
  }

  public trackByFn(index: number, _: UserRecord) {
    return index;
  }

  public getSummary() {
    this.summaryError = null;

    if (!this.suggestedSessions?.length) {
      this.isSummaryLoading = false;
      this.summary = null;
      return;
    }

    this.isSummaryLoading = true;
    this.userRecorDao
      .getSummary(
        this.org.id,
        this.suggestedSessions.map((s) => s.id),
      )
      .then((summary) => {
        this.summary = summary;
      })
      .catch((error) => {
        this.summaryError = error;
      })
      .finally(() => {
        this.isSummaryLoading = false;
      });
  }

  public duration(seconds: number) {
    return intervalToDuration({ start: 0, end: seconds * 1000 });
  }

  public getComment(session: UserRecord, id: string) {
    return session.comments.find((h) => h.id === id);
  }
}
