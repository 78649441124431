import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Org } from "models/org.model";

@Component({
  selector: "duplicate-survey-modal",
  templateUrl: "./duplicate-survey-modal.component.html",
  styleUrls: ["./duplicate-survey-modal.component.scss"],
})
export class DuplicateSurveyModalComponent {
  @Input() isOpen: boolean = false;
  @Input() orgs: Org[] = [];
  @Input() workspaceId: string = null;
  @Output() workspaceChange: EventEmitter<string> = new EventEmitter();
  @Output() duplicate: EventEmitter<void> = new EventEmitter();
  @Output() modalClose: EventEmitter<void> = new EventEmitter();

  constructor() {}

  handleClose(): void {
    this.isOpen = false;
    this.modalClose.emit();
  }

  onWorkspaceChange(workspaceId: string) {
    this.workspaceId = workspaceId;
    this.workspaceChange.emit(workspaceId);
  }

  onDuplicate() {
    this.duplicate.emit();
    this.handleClose();
  }
}
