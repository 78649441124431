import {
  Component,
  DoCheck,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { RegistryEntry } from "models/registry.model";
import {
  TargetingRule,
  TargetingRuleOperator,
  targetingRulesTypesToOperator,
  userPropertiesTypesToOperator,
} from "models/targeting-rule.model";

import { onOrRuleChangeEnsureEmptyEndingField } from "../utils/rule-change";

@Component({
  selector: "rule-operator",
  templateUrl: "./rule-operator.component.html",
  styleUrls: ["./rule-operator.component.scss"],
})
export class TargetingRuleOperatorComponent implements OnInit, DoCheck {
  @Input() public disabled = false;
  @Input() public rule: TargetingRule = null;
  @Input() public registryEntriesIdentityPropertyById: Map<
    string,
    RegistryEntry
  > = new Map();

  @Output() public ruleChange = new EventEmitter<TargetingRule>();

  public options = [];
  public ruleSnapshot: string;

  public targetingRulesTypesToOperator = targetingRulesTypesToOperator;
  public userPropertiesTypesToOperator = userPropertiesTypesToOperator;

  constructor() {}

  public ngOnInit() {
    this.refreshOptions();
  }

  public ngDoCheck() {
    this.refreshOptions();
  }

  private refreshOptions() {
    if (this.ruleSnapshot === JSON.stringify(this.rule)) {
      return;
    }

    let types = targetingRulesTypesToOperator[this.rule.type];
    if (
      this.rule.type === "visitor_property" ||
      this.rule.type === "device_visitor_property"
    ) {
      // @TODO: hotfix, we should support property deletion in a better way
      if (
        this.registryEntriesIdentityPropertyById.get(this.rule.value.key_id)
      ) {
        types =
          userPropertiesTypesToOperator[
            this.rule.getRegistryEntryType(
              this.registryEntriesIdentityPropertyById.get(
                this.rule.value.key_id,
              ),
            )
          ];
      }
    }

    this.options = types.map((op) => {
      return {
        label: op.title,
        value: op.type,
        groupLabel: op.group,
      };
    });
    this.ruleSnapshot = JSON.stringify(this.rule);
  }

  public onOperatorChange(value) {
    const previousValue = this.rule.operator;
    this.rule.operator = value;

    // here, we only list operators that may need to switch values from rule.value.v_s and rule.value.v_s_arr, on operator change
    switch (this.rule.type) {
      case "screen":
        this.onOperatorChangeTypeChannelScreen(previousValue);
        break;

      case "visitor_property":
      case "device_visitor_property":
        const key = this.registryEntriesIdentityPropertyById.get(
          this.rule.value.key_id,
        );
        this.onOperatorChangeTypeUserProperty(key);
        break;

      case "visitor_event_time":
      case "device_visitor_event_time":
        this.onOperatorChangeTypeUserEventTime();
        break;

      case "visitor_event_count":
      case "device_visitor_event_count":
        this.onOperatorChangeTypeUserEventCount();
        break;
    }

    this.ruleChange.emit(this.rule);
  }

  private onOperatorChangeTypeChannelScreen(
    previousValue: TargetingRuleOperator,
  ) {
    if (
      ["equal", "not equal"].includes(this.rule.operator) &&
      !["equal", "not equal"].includes(previousValue)
    ) {
      // reset values when we come from simple input (equal/not equal values must be chose in a <select>)
      this.rule.value.v_s = null;
      this.rule.value.v_s_arr = [];
    }

    if (["contains", "not contains"].includes(this.rule.operator)) {
      this.rule.value.v_s = null;
      this.rule.value.v_s_arr = this.rule.value.v_s_arr ?? [];
      this.rule.value.v_s_arr =
        this.rule.value.v_s_arr.length > 0 ? this.rule.value.v_s_arr : [""];
      onOrRuleChangeEnsureEmptyEndingField(this.rule, false);
    }
  }

  // similar to onOperatorChangeTypeUserEventCount
  private onOperatorChangeTypeUserProperty(key: RegistryEntry) {
    switch (key.type) {
      case "number":
        if (["lt", "gt"].includes(this.rule.operator)) {
          if (this.rule.value.v_n_arr) {
            this.rule.value.v_n = this.rule.value.v_n_arr[0];
            this.rule.value.v_n_arr = null;
          }
        } else if (["equal", "not equal"].includes(this.rule.operator)) {
          if (!this.rule.value.v_n_arr) {
            this.rule.value.v_n_arr = [this.rule.value.v_n];
            this.rule.value.v_n = null;
          }
          onOrRuleChangeEnsureEmptyEndingField(this.rule, true);
        }
        break;

      case "string":
        if (
          ["equal", "not equal", "contains", "not contains"].includes(
            this.rule.operator,
          )
        ) {
          onOrRuleChangeEnsureEmptyEndingField(this.rule, false);
        }
        break;

      case "bool":
      case "time":
        break;

      default:
        throw Error("unexpected operator");
    }
  }

  // similar to onOperatorChangeTypeUserProperty when key.type === RegistryEntryType.RegistryEntryTypeNumeric
  private onOperatorChangeTypeUserEventTime() {}

  // similar to onOperatorChangeTypeUserProperty when key.type === RegistryEntryType.RegistryEntryTypeNumeric
  private onOperatorChangeTypeUserEventCount() {
    if (["lt", "gt"].includes(this.rule.operator)) {
      if (this.rule.value.v_n_arr) {
        this.rule.value.v_n = this.rule.value.v_n_arr[0];
        this.rule.value.v_n_arr = null;
      }
    } else if (["equal", "not equal"].includes(this.rule.operator)) {
      if (!this.rule.value.v_n_arr) {
        this.rule.value.v_n_arr = [this.rule.value.v_n];
        this.rule.value.v_n = null;
      }
      onOrRuleChangeEnsureEmptyEndingField(this.rule, true);
    }
  }
}
