import { Serializable } from "models/serializable.model";
import { isAfterToday, isBeforeToday, isTodayBetweenDates } from "utils/date";
import { Channel, ChannelType, ChannelTypeFormatted } from "./channel.model";
import { TargetingRule } from "./targeting-rule.model";
import { TagSettings } from "./tag-settings.types";

export type DistributionInteraction =
  | "in_app"
  | "in_page"
  | "email"
  | "feedback_button"
  | "link";

export const DistributionInteractions = [
  "in_app",
  "in_page",
  "email",
  "feedback_button",
  "link",
];

export const DistributionInteractionsFormatted = {
  in_app: "In-App",
  in_page: "In-Page",
  email: "Email",
  feedback_button: "Feedback Button",
  link: "Link",
};

export const DistributionInteractionsDescription = {
  in_app:
    "Gather instant feedback from users during key moments of their app experience with dynamic in-app surveys",
  in_page:
    "Collect contextual feedback directly on your website's content, seamlessly integrated into the user journey",
  feedback_button:
    "Add a permanent feedback button to your website and app, making it easy for users to share their thoughts anytime.",
  email:
    "Engage your users with email surveys to capture their feedback anytime, even when they're offline",
  link: "Promote your survey through a shareable link via social media, email, or your website for broad user engagement",
};

export type SurveyDistributionStatus =
  | "running"
  | "programmed"
  | "paused"
  | "stopped";

export class SurveyDistribution extends Serializable {
  constructor(
    public id?: string,
    public survey_id?: string,
    public channel_id?: string,

    public type?: ChannelType,
    public interaction?: DistributionInteraction,
    public enabled?: boolean,
    public settings?: TagSettings,
    public test_mode?: boolean,
    public selector_css?: string,

    public created_at?: Date,
    public updated_at?: Date,

    public targeting_rules?: TargetingRule[],
    public channel?: Channel,
  ) {
    super();
  }

  public get title() {
    if (this.interaction) {
      return DistributionInteractionsFormatted[this.interaction];
    }

    return ChannelTypeFormatted[this.type].title;
  }

  public get description() {
    if (this.interaction) {
      return DistributionInteractionsDescription[this.interaction];
    }

    return "This distribution is deprecated and will be removed soon. Please consider using the new distributions.";
  }

  public get isTestMode() {
    return (
      this.targeting_rules?.find((r: TargetingRule) => r.type === "multiple")
        ?.value?.v_b &&
      !this.targeting_rules?.find((r: TargetingRule) =>
        ["recurrence", "recurrence_not_answered"].includes(r.type),
      ) &&
      this.interaction !== "link" &&
      this.interaction !== "email" &&
      this.type !== "hosted-page"
    );
  }

  public get status(): SurveyDistributionStatus {
    if (!this.enabled) {
      return "stopped";
    }

    const start_date = this.targeting_rules?.filter(
      (rule) => rule.type === "start_date",
    )[0]?.value.v_t;

    const end_date = this.targeting_rules?.filter(
      (rule) => rule.type === "end_date",
    )[0]?.value.v_t;

    if (isAfterToday(start_date)) {
      return "programmed";
    }

    if (isBeforeToday(end_date)) {
      return "paused";
    }

    if (isTodayBetweenDates(start_date, end_date)) {
      return "running";
    }

    return "stopped";
  }

  public get statusLabel(): string {
    switch (this.status) {
      case "running":
        return "Running";
      case "programmed":
        return "Scheduled";
      case "paused":
        return "Paused";
      case "stopped":
        return "Stopped";
    }
  }

  public fromJson(json: object) {
    super.fromJson(json);

    if (json["settings"] && !Object.keys(json["settings"]).length) {
      this.settings = null;
    }

    if (json["targeting_rules"])
      this.targeting_rules = json["targeting_rules"].map((r) =>
        new TargetingRule().fromJson(r),
      );

    if (json["selectors_css"] && json["selectors_css"].length > 0) {
      this.selector_css = json["selectors_css"][0];
    }

    return this;
  }
}
