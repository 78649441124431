import { Injectable } from "@angular/core";

import { ScreebApiHelper } from "helpers/screeb-api.helper";
import { TagEditorToken } from "./tag-editor-token.types";

@Injectable()
export class TagEditorTokenDao {
  constructor(private screebApiHelper: ScreebApiHelper) {}

  public getWorkspaceIdAndChannelIdAndSurveyId(
    workspaceId: string,
    surveyId: string,
    channelId: string,
  ) {
    return this.screebApiHelper
      .get<TagEditorToken>(
        `/org/${workspaceId}/survey/${surveyId}/${channelId}/tag-editor-token`,
      )
      .toPromise();
  }
}
