import { Component, Input } from "@angular/core";
import { SessionService } from "services/auth.service";
import {
  AccountGoal,
  AccountGoalsToLabel,
  AccountJobTitle,
  AccountJobTitlesToLabel,
} from "models/account.model";
import { Router } from "@angular/router";
import { TrackersService } from "services/trackers.service";
import { Org } from "models/org.model";
import { pluralize } from "utils/string";

@Component({
  selector: "onboarding-setup-discovery",
  template: `
    <div class="description">
      Discover in 30 seconds how Screeb can help
      <b>{{ toPlural(accountRoleToLabel[jobTitle]) }}</b>
      to
      <b>{{ accountGoalToLabel[goal].toLowerCase() }}</b>
    </div>
    <button
      class="cta-btn"
      title="Discover now"
      nz-button
      nzType="primary"
      nzSize="large"
      (click)="onClick()"
    >
      <screeb-icon size="sm" icon="click" />
      <span>Discover now</span>
    </button>
    <img class="preview" src="/assets/onboarding/{{ goal }}.png" [alt]="goal" />
  `,
  styleUrls: ["./onboarding-setup-discovery.component.scss"],
})
export class OnboardingSetupDiscoveryComponent {
  @Input() public org: Org = null;
  @Input() public jobTitle: AccountJobTitle = null;
  @Input() public goal: AccountGoal = null;

  public accountRoleToLabel = AccountJobTitlesToLabel;
  public accountGoalToLabel = AccountGoalsToLabel;

  constructor(
    public sessionService: SessionService,
    public router: Router,
    private trackersService: TrackersService,
  ) {}

  public toPlural(value: string) {
    if (value === AccountJobTitlesToLabel.other) {
      return "People";
    }
    return pluralize(value, "s");
  }

  public onClick() {
    this.trackersService
      .newEventTrackingBuilder("Screeb Onboarding Qualification Done")
      .withOrg(this.org)
      .withAccount(this.sessionService.session)
      .build();

    this.goToDiscovery();
  }

  goToDiscovery() {
    this.router.navigate(["org", this.org.id, "onboarding", "discovery"], {
      queryParams: {
        guide: this.goal,
      },
    });
  }
}
