<div
  class="feedback_button"
  (click)="
    feedbackButtonPreviewChange.emit(
      feedbackButtonPreview === 'closed' ? 'opened' : 'closed'
    )
  "
  [style]="getFeedbackButtonCssVars()"
  [ngClass]="[
    tagSettings.feedback_button_position === 'center-left'
      ? 'feedback-button-left'
      : 'feedback-button-right',
    tagSettings.feedback_button_cta_format === 'label'
      ? 'feedback-button-label'
      : '',
    'feedback-button-' + feedbackButtonPreview,
  ]"
>
  <sanitized-message-component
    *ngIf="tagSettings.feedback_button_cta_format === 'label'"
    [content]="tagSettings.feedback_button_cta_label"
    [options]="{
      links: true,
      styling: true,
      emojiTranscoder: true,
      useDarkAndLightMode: true,
      primaryTextColor: tagSettings.feedback_button_cta_color_primary_text,
    }"
    [rotate]="
      tagSettings.feedback_button_position === 'center-left' ? 'left' : 'right'
    "
  ></sanitized-message-component>
  <img
    class="feedback-button-icon"
    *ngIf="
      tagSettings.feedback_button_cta_format === 'label' &&
      tagSettings.feedback_button_cta_label_icon_type !== 'none'
    "
    src="{{
      tagSettings.feedback_button_cta_label_icon_type === 'custom'
        ? tagSettings.feedback_button_cta_label_icon
        : isDarkColor(tagSettings.feedback_button_cta_color_primary)
          ? '/assets/screeby-white.png'
          : '/assets/screeby.png'
    }}"
    (error)="onImgError($event)"
    alt="Feedback button icon"
  />

  <div
    *ngIf="tagSettings.feedback_button_cta_format === 'avatar'"
    class="feedback-button-image"
    [ngClass]="[
      tagSettings.feedback_button_identity_type === 'fixed'
        ? 'feedback-button-image-fixed'
        : 'feedback-button-image-screeby',
    ]"
  >
    <img
      *ngIf="tagSettings.feedback_button_identity_type === 'screeby'"
      src="{{
        isDarkColor(tagSettings.feedback_button_cta_color_primary)
          ? '/assets/screeby-white.png'
          : '/assets/screeby.png'
      }}"
      (error)="onImgError($event)"
      alt="Screeby"
    />
  </div>
</div>
