<div class="instructions">
  <div class="code" [ngClass]="{ padding: withPadding }">
    <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
      <input type="text" nz-input [value]="copyText" disabled />
    </nz-input-group>
    <ng-template #suffixIconButton>
      <div class="button-sections">
        <button
          *ngIf="copyText"
          nz-button
          nzType="primary"
          class="copy-button"
          (click)="copyContent($event)"
        >
          <span>Copy</span>
        </button>
        <button
          *ngIf="qrCode"
          nz-button
          nzType="primary"
          class="qrcode-button"
          (click)="displayQRCode()"
        >
          <i nz-icon nzType="qrcode"></i>
          QR Code
        </button>
        <button
          *ngIf="reset === true"
          nz-button
          nzType="text"
          class="reset-button"
          (click)="onReset.emit()"
        >
          Reset
        </button>
        <a
          *ngIf="canOpen"
          nz-button
          nzType="primary"
          [href]="url"
          class="open-button"
          target="_blank"
          (click)="onOpen.emit()"
        >
          <i nz-icon nzType="link"></i>
          Open
        </a>
      </div>
    </ng-template>
  </div>
</div>
