/* eslint-disable @angular-eslint/component-class-suffix */
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Router } from "@angular/router";
import { SupportedLanguages } from "models/survey.dao.types";
import { NzSelectSizeType } from "ng-zorro-antd/select";
import { LanguageWithEmoji } from "resolvers/asset-languages-countries";
import { EntitlementService } from "services/entitlement.service";
import { UIService } from "services/ui.service";
import { deepCopy } from "utils/object";

@Component({
  selector: "language-select",
  template: `
    <nz-select
      class="language-select"
      nzPlaceHolder="Select language"
      [ngClass]="{
        defaultLanguageWarning,
        'language-select-max-width': maxWidth,
      }"
      [nzMode]="nzMode"
      [nzCustomTemplate]="defaultTemplate"
      [nzShowSearch]="nzMode !== 'default'"
      [ngModel]="innerValue"
      [nzSize]="nzSize"
      [nzOptionHeightPx]="34"
      [name]="name"
      [nzDropdownMatchSelectWidth]="false"
      [nzDisabled]="disabled"
      (ngModelChange)="onChange($event)"
    >
      <nz-option
        class="lang-option"
        *ngFor="let lang of languages; trackByProperty: 'value'"
        nzCustomContent
        [nzLabel]="lang.label"
        [nzValue]="lang.value"
      >
        <sanitized-message-component
          class="lang-option-icon"
          [content]="lang.emoji"
          [options]="{ emojiTranscoder: true }"
        ></sanitized-message-component>
        <span class="lang-option-label">{{ lang.label }}</span>
        <a
          *ngIf="canSetAsDefault && lang.value !== defaultLanguage"
          class="set-as-default-button"
          nz-button
          nzType="link"
          nzSize="small"
          (click)="defaultLanguageChange.emit(lang.value)"
        >
          Set as default
        </a>
        <nz-tag
          class="default-tag"
          *ngIf="lang.value === defaultLanguage"
          nzColor="default"
          [ngClass]="{ defaultLanguageWarning }"
        >
          <span>default</span>
        </nz-tag>
      </nz-option>
    </nz-select>
    <ng-template #defaultTemplate let-selected>
      @let emoji = getEmoji(selected.nzValue);
      <sanitized-message-component
        class="lang-option-icon"
        [content]="emoji"
        [options]="{ emojiTranscoder: true }"
      ></sanitized-message-component>
      <span class="lang-option-label">{{ selected.nzLabel }}</span>
      <nz-tag
        class="default-tag"
        *ngIf="selected.nzValue === defaultLanguage"
        nzColor="default"
        [ngClass]="{ defaultLanguageWarning }"
      >
        <span>default</span>
      </nz-tag>
      <span
        *ngIf="showInfo"
        class="info"
        nz-popover
        nzPopoverPlacement="right"
        [nzPopoverContent]="contentTemplate"
      >
        <i class="lang-select-info" nz-icon nzType="info-circle"></i>
        <ng-template #contentTemplate>
          <div [style]="{ maxWidth: '250px' }">
            This list can be configured in your organisation
            <a
              routerLink="/org/{{ uiService.currentOrg.id }}/settings/language"
            >
              Language preferences
            </a>
            <br />
            <br />
            The default language will be used when the respondent's language has
            no translation.
          </div>
        </ng-template>
      </span>
    </ng-template>
  `,
  styles: [
    `
      .language-select {
        min-width: 150px;
      }

      .language-select-max-width {
        width: 100%;
        min-width: unset;
      }

      .set-as-default-button {
        margin-left: 16px;
      }

      .default-tag {
        margin-left: 8px;
        margin-right: 0;
        padding: 0px 8px;
        font-size: 13px;
      }

      .defaultLanguageWarning ::ng-deep .ant-select-selector {
        border-color: var(--screeb-color-red-500);
      }

      .defaultLanguageWarning.ant-tag {
        border-color: var(--screeb-color-red-500);
        color: var(--screeb-color-red-500);
      }

      .lang-option {
        display: flex;
        align-items: center;
        width: 100%;
      }

      .info {
        margin-left: 0.35rem;

        i {
          color: var(--screeb-color-body-text-secondary);
        }
      }

      ::ng-deep .ant-select-item-option-content {
        display: flex;
        align-items: center;
        width: 100%;
      }

      ::ng-deep .ant-select-selection-item {
        display: flex;
        align-items: center;
      }

      ::ng-deep .ant-select-selection-item-remove {
        margin-left: 8px;
        font-size: 12px !important;
      }

      .lang-option-label {
        margin-left: 36px;
        flex: 1;
      }

      .lang-option-icon {
        font-size: 24px;
        position: absolute;
        margin-top: 1px;
      }
    `,
  ],
})
export class LanguageSelect implements OnInit, OnChanges {
  @Input() public placeholder: string;
  @Input() public ngModel: SupportedLanguages | SupportedLanguages[] = null;
  @Input() public nzSize: NzSelectSizeType = "default";
  @Input() public canSetAsDefault = false;
  @Input() public defaultLanguage = null;
  @Input() public name = null;
  @Input() public nzMode = "default";
  @Input() public languages: LanguageWithEmoji[] = [];
  @Input() public defaultLanguageWarning = false;
  @Input() public showInfo = false;
  @Input() public disabled = false;
  @Input() public maxWidth = false;

  @Output() public ngModelChange: EventEmitter<
    SupportedLanguages | SupportedLanguages[]
  > = new EventEmitter();
  @Output() public defaultLanguageChange: EventEmitter<SupportedLanguages> =
    new EventEmitter();

  // wtf
  innerValue = null;

  constructor(
    private router: Router,
    public entitlementService: EntitlementService,
    public uiService: UIService,
  ) {}

  ngOnInit(): void {
    // wtf
    this.innerValue = deepCopy(this.ngModel);
  }

  ngOnChanges({ ngModel }: SimpleChanges): void {
    if (!!ngModel && ngModel.currentValue !== this.innerValue) {
      // wtf
      this.innerValue = deepCopy(this.ngModel);
    }
  }

  getEmoji(lang: string) {
    return this.languages.find(({ value }) => value === lang)?.emoji ?? "";
  }

  onUpgradeClick() {
    this.router.navigate([
      "org",
      this.uiService.currentOrgId,
      "settings",
      "billing",
    ]);
  }

  onChange($event: SupportedLanguages | SupportedLanguages[]) {
    if (this.nzMode === "default") {
      this.ngModelChange.emit($event);
    } else if (Array.isArray($event)) {
      this.ngModelChange.emit($event);
    }
  }
}
