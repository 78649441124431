import { Component, EventEmitter, Input, Output } from "@angular/core";
import { isPasswordStrengthValid } from "components/auth/page/password-validator";

@Component({
  selector: "account-password",
  templateUrl: "./password.component.html",
  styleUrls: ["./password.component.scss"],
})
export class SettingsAccountPasswordComponent {
  @Input()
  public error: string = null;
  @Input()
  public loading: boolean = false;

  @Output()
  public passwordChange: EventEmitter<string> = new EventEmitter<string>();

  public passwordEdit = false;

  public password = "";
  public passwordAgain = "";

  public passwordErrors: { [key: string]: boolean } = null;

  constructor() {}

  public isPasswordSectionInvalid(password: string, passwordAgain: string) {
    return !(
      this.isValidPassword(password) && this.isValidPasswordAgain(passwordAgain)
    );
  }

  public isValidPassword(password: string) {
    this.passwordErrors = isPasswordStrengthValid(password);
    return password === "" || !this.passwordErrors;
  }

  public isValidPasswordAgain(password: string) {
    return password === this.password;
  }

  public async changePassword() {
    this.passwordChange.emit(this.password);
    this.password = "";
    this.passwordAgain = "";
    this.passwordEdit = false;
  }
}
