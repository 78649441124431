import { Injectable } from "@angular/core";

import { ScreebApiHelper } from "helpers/screeb-api.helper";
import { deepCopy } from "utils/object";
import {
  AnalyticsFilterForResponse,
  AnalyticsQueryResponse,
} from "./analytics.filters.type";
import { AnalyticsResponse } from "./analytics.model";

@Injectable()
export class ResponseDao {
  constructor(private screebApiHelper: ScreebApiHelper) {}

  public searchResponses(
    baseQuery: AnalyticsQueryResponse,
    overview: boolean = false,
  ): Promise<AnalyticsResponse> {
    const orgId = baseQuery.org_id;
    const surveyIds = baseQuery.survey_ids;

    // we won't need a deep copy here
    const query = Object.assign({}, baseQuery);
    delete query.org_id;
    delete query.survey_ids;

    if (baseQuery.aggregation) {
      query.aggregation = baseQuery.aggregation.reduce(
        (finalAggregation: any, aggregration) => ({
          ...finalAggregation,
          ...aggregration["params"],
          by: [...finalAggregation.by, aggregration.by],
        }),
        { by: [] },
      );
    }

    query.filters = this.replaceAbstractedOperators(query.filters);

    const start = new Date().getTime();

    return this.screebApiHelper
      .request(
        "SEARCH",
        `/org/${orgId}/survey/${surveyIds.join(",")}/response${overview ? "?overview=true" : ""}`,
        { body: query },
      )
      .toPromise()
      .then((data: object): AnalyticsResponse => {
        const res = AnalyticsResponse.fromJson(data);
        const end = new Date().getTime();

        console.log(`Took ${res.took} ms (${end - start} ms e2e)`);

        return res;
      });
  }

  public exportResponses(query: AnalyticsQueryResponse): string {
    const orgId = query.org_id;
    const surveyIds = query.survey_ids;

    // we won't need a deep copy here
    query = Object.assign({}, query);

    delete query.org_id;
    delete query.survey_ids;
    delete query.aggregation;

    query.filters = this.replaceAbstractedOperators(query.filters);

    const q = encodeURIComponent(JSON.stringify(query));
    // const q = encodeURIComponent(window.btoa(JSON.stringify(query)));

    const basePath = this.screebApiHelper.getApiEndpoint();
    return `${basePath}/org/${orgId}/survey/${surveyIds.join(
      ",",
    )}/response/csv?query=${q}`;
  }

  private replaceAbstractedOperators(
    filters: AnalyticsFilterForResponse[],
  ): AnalyticsFilterForResponse[] {
    return filters?.map((f: AnalyticsFilterForResponse) => {
      if (!["preset.gt", "preset.lt"].includes(f.operator)) {
        return f;
      }

      f = deepCopy(f);
      if (f.operator === "preset.gt") {
        f.operator = "gt" as any;
      } else if (f.operator === "preset.lt") {
        f.operator = "lt" as any;
      }
      return f;
    });
  }

  public delete(orgId: string, respondentId: string, responseId: string) {
    return this.screebApiHelper
      .delete<object>(
        `/org/${orgId}/user/${respondentId}/response/${responseId}`,
      )
      .toPromise();
  }

  public getMediaUrl(
    orgId: string,
    respondentId: string,
    responseId: string,
    nodeAnswerFileId: string,
  ): Promise<{ url: string; content_type: string }> {
    return this.screebApiHelper
      .get<object>(
        `/org/${orgId}/user/${respondentId}/response/${responseId}/media/${nodeAnswerFileId}`,
      )
      .toPromise()
      .then((data: { url: string; content_type: string }) => data);
  }

  public addTagsToAnswer(
    orgId: string,
    respondentId: string,
    responseId: string,
    answerId: string,
    tags: string[],
  ): Promise<object>[] {
    return tags.map((tag) =>
      this.screebApiHelper
        .put<object>(
          `/org/${orgId}/user/${respondentId}/response/${responseId}/answer/${answerId}/tag/${tag}`,
          null,
        )
        .toPromise(),
    );
  }

  public removeTagsFromAnswer(
    orgId: string,
    respondentId: string,
    responseId: string,
    answerId: string,
    tag: string,
  ): Promise<{ status: string }> {
    return this.screebApiHelper
      .delete<{
        status: string;
      }>(
        `/org/${orgId}/user/${respondentId}/response/${responseId}/answer/${answerId}/tag/${tag}`,
      )
      .toPromise();
  }
}
