import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
} from "@angular/core";
import { fontsWithFamily } from "components/utils/font-select/font-select.component";

import { TagSettings, TagSettingsPosition } from "models/tag-settings.types";
import { isDarkColor } from "utils/color";

@Component({
  selector: "tag-settings-preview",
  templateUrl: "./tag-settings-preview.component.html",
  styleUrls: ["./tag-settings-preview.component.scss"],
})
export class TagSettingsPreviewComponent implements OnInit, OnChanges {
  @Input() public tagSettings: TagSettings = null;
  @Input() public forceMobile = false;
  @Input() public format:
    | "cards"
    | "in_page"
    | "conversationnal"
    | "link"
    | "hosted-page"
    | "feedback_button"
    | "email" = "conversationnal";
  @Input() public scope: "org" | "survey" | "distribution" = "org";

  @Input() public mode: "mobile" | "desktop" = "desktop";

  @Input() public emailPreview: "in" | "out" = "in";

  public feedbackButtonPreview: "closed" | "opened" = "closed";

  public verticalAlign: "flex-end" | "center" = "flex-end";
  public horizontalAlign: "flex-end" | "center" | "flex-start" = "flex-start";

  @ViewChild("feedbackContainer")
  feedbackButtonPreviewComponent: ElementRef<HTMLElement>;

  public isDarkColor = isDarkColor;

  constructor() {}

  ngOnInit() {
    this.setupForceMobile();
  }

  ngOnChanges() {
    this.setupForceMobile();
  }

  private setupForceMobile() {
    if (this.forceMobile) {
      this.mode = "mobile";
    }
  }

  public getAffixTarget(): HTMLElement {
    return document.querySelector("#affix-anchor-target");
  }

  public getPosition(): TagSettingsPosition {
    switch (this.format) {
      case "cards":
        return this.tagSettings.cards_position;
      case "conversationnal":
        return this.tagSettings.conversational_position;
      case "feedback_button":
        return this.tagSettings.feedback_button_position;
      default:
        return "bottom-left";
    }
  }

  public getVerticalAlign() {
    if (this.format === "hosted-page" && this.tagSettings.format !== "cards") {
      return "center";
    }

    if (["link", "in_page", "email"].includes(this.format)) {
      return "center";
    }

    if (
      this.mode === "mobile" &&
      (this.format !== "feedback_button" ||
        this.feedbackButtonPreview === "opened")
    ) {
      return "flex-end";
    }

    switch (this.getPosition()) {
      case "bottom-left":
      case "bottom-center":
      case "bottom-right":
        return "flex-end";
      default:
        return "center";
    }
  }

  public getHorizontalAlign(noCenter = false) {
    if (this.format === "hosted-page") {
      if (noCenter) {
        return "flex-start";
      }
      return "center";
    }

    if (
      this.mode === "mobile" &&
      (this.format !== "feedback_button" ||
        this.feedbackButtonPreview === "opened")
    ) {
      if (noCenter) {
        return "flex-start";
      }
      return "center";
    }

    if (["link", "in_page", "email"].includes(this.format)) {
      return "center";
    }

    switch (this.getPosition()) {
      case "bottom-left":
      case "center-left":
        return "flex-start";
      case "center-center":
      case "bottom-center":
        if (noCenter) {
          return "flex-start";
        }
        return "center";
      case "bottom-right":
      case "center-right":
        return "flex-end";
    }
  }

  public getBackgroundImage(): string {
    if (
      (this.format === "link" ||
        this.format === "hosted-page" ||
        (this.format === "email" && this.emailPreview === "out")) &&
      this.mode === "desktop"
    ) {
      return this.getBackgroundImageUrl();
    }

    if (this.format === "in_page") {
      return "/assets/preview/preview-background-in-page-up.png";
    }

    if (this.format === "email" && this.emailPreview === "in") {
      return "/assets/preview/preview-background-email.svg";
    }

    if (this.mode === "mobile") {
      return "/assets/preview/preview-background-mobile.svg";
    }

    if (this.mode === "desktop") {
      return "/assets/preview/preview-background.svg";
    }

    return "";
  }

  public getInnerBackgroundImage(): string {
    if (
      (this.format === "link" ||
        this.format === "hosted-page" ||
        (this.format === "email" && this.emailPreview === "out")) &&
      this.mode === "mobile"
    ) {
      return this.getBackgroundImageUrl();
    }

    return "";
  }

  public getBackgroundImageUrl() {
    const prefix = this.format === "email" ? "emails_" : "";
    return this.tagSettings[`${prefix}page_background_type`] === "image"
      ? this.tagSettings[`${prefix}page_background_image_url`]
      : "";
  }

  public getInnerBackgroundColor() {
    if (
      (this.format === "link" ||
        this.format === "hosted-page" ||
        (this.format === "email" && this.emailPreview === "out")) &&
      this.mode === "mobile"
    ) {
      const style = window.getComputedStyle(document.body);
      const prefix = this.format === "email" ? "emails_" : "";
      return this.tagSettings[`${prefix}page_background_type`] === "color"
        ? this.tagSettings[`${prefix}page_background_color`]
        : style.getPropertyValue("--screeb-color-background-secondary");
    }

    if (this.format === "in_page") {
      const style = window.getComputedStyle(document.body);
      return style.getPropertyValue("--screeb-color-grey-200");
    }

    return "transparent";
  }

  public getBackgroundColor() {
    const style = window.getComputedStyle(document.body);

    if (
      this.format === "in_page" ||
      (this.format === "email" && this.emailPreview === "in")
    ) {
      return style.getPropertyValue("--screeb-color-grey-200");
    }

    const prefix = this.format === "email" ? "emails_" : "";
    return this.tagSettings[`${prefix}page_background_type`] === "color" &&
      this.mode === "desktop"
      ? this.tagSettings[`${prefix}page_background_color`]
      : style.getPropertyValue("--screeb-color-background-secondary");
  }

  public shouldInvertWhiteLabel(colorRef?: string) {
    const color = colorRef || this.tagSettings.cards_color_primary;
    return isDarkColor(color);
  }

  public shouldInvertWhiteLabelInPage() {
    return isDarkColor(this.tagSettings.in_page_color_primary);
  }

  public shouldInvertWhiteLabelLink() {
    return isDarkColor(this.tagSettings.link_color_primary);
  }

  public getFormat(format, tagSettingsFormat) {
    if (format === "hosted-page") {
      return tagSettingsFormat;
    }

    return format;
  }

  public handleFeedbackButtonChange(event: "closed" | "opened") {
    if (this.mode === "mobile") {
      this.feedbackButtonPreview = event;
      return;
    }

    if (event === "closed") {
      this.feedbackButtonPreviewComponent.nativeElement.classList.remove(
        "feedback-button-animation",
      );
      setTimeout(() => {
        this.feedbackButtonPreviewComponent.nativeElement.classList.add(
          "feedback-button-animation",
        );
      }, 1);
      setTimeout(() => {
        this.feedbackButtonPreview = "closed";
      }, 200);
    } else {
      this.feedbackButtonPreviewComponent.nativeElement.classList.remove(
        "no-animation",
      );
      this.feedbackButtonPreviewComponent.nativeElement.classList.remove(
        "feedback-button-animation",
      );
      this.feedbackButtonPreview = "opened";

      setTimeout(() => {
        this.feedbackButtonPreviewComponent.nativeElement.classList.add(
          "no-animation",
        );
        this.feedbackButtonPreviewComponent.nativeElement.querySelector(
          "div",
        ).style.display = "flex";
      }, 1);
    }
  }

  public onImgError(event, type) {
    isDarkColor(this.tagSettings[type + "_color_secondary"])
      ? (event.target.src = "/assets/screeby-white-fallback.png")
      : (event.target.src = "/assets/screeby-fallback.png");
  }

  public getFontFamily(font: string) {
    return fontsWithFamily.find((f) => f.key === font).value;
  }

  getCssVars() {
    return {
      "--cards-border-line-color": this.tagSettings.cards_border_line_color,
      "--cards-border-line-width":
        this.tagSettings.cards_border_line_width + "px",
      "--cards-border-line-style": this.tagSettings.cards_border_line
        ? "solid"
        : "none",
      "--cards-border-line": this.tagSettings.cards_border_line,
      "--cards-border-radius": this.tagSettings.cards_border_radius + "px",
      "--cards-border-shadow-color": this.tagSettings.cards_border_shadow
        ? this.tagSettings.cards_border_shadow_color
        : "transparent",
      "--cards-border-shadow": this.tagSettings.cards_border_shadow,
      "--cards-color-primary-text": this.tagSettings.cards_color_primary_text,
      "--cards-color-primary": this.tagSettings.cards_color_primary,
      "--cards-color-secondary-text":
        this.tagSettings.cards_color_secondary_text,
      "--cards-color-secondary": this.tagSettings.cards_color_secondary,
      "--cards-display-reduce-button":
        this.tagSettings.cards_display_reduce_button,
      "--cards-font-family": this.getFontFamily(
        this.tagSettings.cards_font_family,
      ),
      "--cards-font-line-height":
        this.tagSettings.cards_font_line_height + "px",
      "--cards-font-size": this.tagSettings.cards_font_size + "px",
      "--cards-identity-avatar-format":
        this.tagSettings.cards_identity_avatar_format,
      "--cards-identity-fixed-avatar": `url('${this.tagSettings["cards_identity_fixed_avatar"] ?? "/assets/icons/common/default.svg"}')`,
      "--cards-identity-fixed-name":
        this.tagSettings["cards_identity_fixed_name"],
      "--cards-identity-fixed-title":
        this.tagSettings["cards_identity_fixed_title"],
      "--cards-identity-type": this.tagSettings.cards_identity_type,
      "--cards-identity-avatar-border-radius":
        {
          round: 100,
          square: 2,
          rounded_square: 8,
        }[this.tagSettings.cards_identity_avatar_format] + "px",
      "--cards-overlay-color": this.tagSettings.cards_overlay_color,
      "--cards-overlay-visibility": this.tagSettings.cards_overlay_visibility,
      "--cards-position": this.tagSettings.cards_position,
      "--cards-size": this.tagSettings.cards_size - 15 + "%",

      "--conversational-border-line-color":
        this.tagSettings.conversational_border_line_color,
      "--conversational-border-line-width":
        this.tagSettings.conversational_border_line_width + "px",
      "--conversational-border-line-style": this.tagSettings
        .conversational_border_line
        ? "solid"
        : "none",
      "--conversational-border-radius":
        this.tagSettings.conversational_border_radius + "px",
      "--conversational-border-shadow-color": this.tagSettings
        .conversational_border_shadow
        ? this.tagSettings.conversational_border_shadow_color
        : "transparent",
      "--conversational-border-shadow":
        this.tagSettings.conversational_border_shadow,
      "--conversational-color-primary-text":
        this.tagSettings.conversational_color_primary_text,
      "--conversational-color-primary":
        this.tagSettings.conversational_color_primary,
      "--conversational-color-secondary-text":
        this.tagSettings.conversational_color_secondary_text,
      "--conversational-color-secondary":
        this.tagSettings.conversational_color_secondary,
      "--conversational-display-close-reduce":
        this.tagSettings.conversational_display_close_reduce,
      "--conversational-font-family": this.getFontFamily(
        this.tagSettings.conversational_font_family,
      ),
      "--conversational-font-line-height":
        this.tagSettings.conversational_font_line_height + "px",
      "--conversational-font-size":
        this.tagSettings.conversational_font_size + "px",
      "--conversational-font-line-height-intro":
        Math.round(this.tagSettings.conversational_font_line_height / 1.2) +
        "px",
      "--conversational-font-size-intro":
        Math.round(this.tagSettings.conversational_font_size / 1.15) + "px",
      "--conversational-identity-avatar-border-radius":
        {
          round: 100,
          square: 2,
          rounded_square: 8,
        }[this.tagSettings.conversational_identity_avatar_format] + "px",
      "--conversational-identity-fixed-avatar": `url('${this.tagSettings["conversational_identity_fixed_avatar"] ?? "/assets/icons/common/default.svg"}')`,
      "--conversational-identity-fixed-name":
        this.tagSettings["conversational_identity_fixed_name"],
      "--conversational-identity-fixed-title":
        this.tagSettings["conversational_identity_fixed_title"],
      "--conversational-identity-type":
        this.tagSettings.conversational_identity_type,
      "--conversational-overlay-color":
        this.tagSettings.conversational_overlay_color,
      "--conversational-overlay-visibility":
        this.tagSettings.conversational_overlay_visibility,
      "--conversational-position": this.tagSettings.conversational_position,
      "--conversational-size": this.tagSettings.conversational_size + "%",

      "--feedback_button-border-line-color":
        this.tagSettings.feedback_button_border_line_color,
      "--feedback_button-border-line-width":
        this.tagSettings.feedback_button_border_line_width + "px",
      "--feedback_button-border-line-style": this.tagSettings
        .feedback_button_border_line
        ? "solid"
        : "none",
      "--feedback_button-border-line":
        this.tagSettings.feedback_button_border_line,
      "--feedback_button-border-radius":
        this.tagSettings.feedback_button_border_radius + "px",
      "--feedback_button-border-shadow-color": this.tagSettings
        .feedback_button_border_shadow
        ? this.tagSettings.feedback_button_border_shadow_color
        : "transparent",
      "--feedback_button-border-shadow":
        this.tagSettings.feedback_button_border_shadow,
      "--feedback_button-color-primary-text":
        this.tagSettings.feedback_button_color_primary_text,
      "--feedback_button-color-primary":
        this.tagSettings.feedback_button_color_primary,
      "--feedback_button-color-secondary-text":
        this.tagSettings.feedback_button_color_secondary_text,
      "--feedback_button-color-secondary":
        this.tagSettings.feedback_button_color_secondary,

      "--feedback_button-font-family": this.getFontFamily(
        this.tagSettings.feedback_button_font_family,
      ),
      "--feedback_button-font-line-height":
        this.tagSettings.feedback_button_font_line_height + "px",
      "--feedback_button-font-size":
        this.tagSettings.feedback_button_font_size + "px",
      "--feedback_button-identity-fixed-avatar": `url('${this.tagSettings["feedback_button_identity_fixed_avatar"] ?? "/assets/icons/common/default.svg"}')`,
      "--feedback_button-identity-fixed-name":
        this.tagSettings["feedback_button_identity_fixed_name"],
      "--feedback_button-identity-fixed-title":
        this.tagSettings["feedback_button_identity_fixed_title"],
      "--feedback_button-identity-type":
        this.tagSettings.feedback_button_identity_type,
      "--feedback_button-overlay-color":
        this.tagSettings.feedback_button_overlay_color,
      "--feedback_button-overlay-visibility":
        this.tagSettings.feedback_button_overlay_visibility,
      "--feedback_button-position": this.tagSettings.feedback_button_position,
      "--feedback_button-size":
        this.tagSettings.feedback_button_size - 15 + "%",

      // Feedback button CTA
      "--feedback_button_cta-label": this.tagSettings.feedback_button_cta_label,

      "--feedback_button_cta-border-line":
        this.tagSettings.feedback_button_cta_border_line,
      "--feedback_button_cta-border-line-width":
        this.tagSettings.feedback_button_cta_border_line_width,
      "--feedback_button_cta-border-line-color":
        this.tagSettings.feedback_button_cta_border_line_color,
      "--feedback_button_cta-border-radius":
        this.tagSettings.feedback_button_cta_border_radius + "px",
      "--feedback_button_cta-border-shadow":
        this.tagSettings.feedback_button_cta_border_shadow,
      "--feedback_button_cta-border-shadow-color":
        this.tagSettings.feedback_button_cta_border_shadow_color,

      "--feedback_button_cta-color-primary":
        this.tagSettings.feedback_button_cta_color_primary,
      "--feedback_button_cta-color-primary-text":
        this.tagSettings.feedback_button_cta_color_primary_text,

      "--feedback_button_cta-font-size":
        this.tagSettings.feedback_button_cta_font_size + "px",
      "--feedback_button_cta-font-line-height":
        this.tagSettings.feedback_button_cta_font_line_height + "px",
      "--feedback_button_cta-font-family": this.getFontFamily(
        this.tagSettings.feedback_button_cta_font_family,
      ),

      "--emails-border-line-color": this.tagSettings.emails_border_line_color,
      "--emails-border-line-width":
        this.tagSettings.emails_border_line_width + "px",
      "--emails-border-line-style": this.tagSettings.emails_border_line
        ? "solid"
        : "none",
      "--emails-border-line": this.tagSettings.emails_border_line,
      "--emails-border-radius": this.tagSettings.emails_border_radius + "px",
      "--emails-border-shadow-color": this.tagSettings.emails_border_shadow
        ? this.tagSettings.emails_border_shadow_color
        : "transparent",
      "--emails-border-shadow": this.tagSettings.emails_border_shadow,
      "--emails-color-primary-text": this.tagSettings.emails_color_primary_text,
      "--emails-color-primary": this.tagSettings.emails_color_primary,
      "--emails-color-secondary-text":
        this.tagSettings.emails_color_secondary_text,
      "--emails-color-secondary": this.tagSettings.emails_color_secondary,
      "--emails-font-family": this.getFontFamily(
        this.tagSettings.emails_font_family,
      ),
      "--emails-font-line-height":
        this.tagSettings.emails_font_line_height + "px",
      "--emails-font-size": this.tagSettings.emails_font_size + "px",
      "--emails-identity-avatar-border-radius":
        {
          round: 100,
          square: 2,
          rounded_square: 8,
        }[this.tagSettings.emails_identity_avatar_format] + "px",
      "--emails-identity-fixed-avatar": `url('${this.tagSettings["emails_identity_fixed_avatar"] ?? "/assets/icons/common/default.svg"}')`,
      "--emails-identity-fixed-name":
        this.tagSettings["emails_identity_fixed_name"],
      "--emails-identity-fixed-title":
        this.tagSettings["emails_identity_fixed_title"],
      "--emails-identity-type": this.tagSettings.emails_identity_type,
      "--emails-size": this.tagSettings.emails_size - 15 + "%",

      "--in-page-border-line-color": this.tagSettings.in_page_border_line_color,
      "--in-page-border-line-width":
        this.tagSettings.in_page_border_line_width + "px",
      "--in-page-border-line-style": this.tagSettings.in_page_border_line
        ? "solid"
        : "none",
      "--in-page-border-line": this.tagSettings.in_page_border_line,
      "--in-page-border-radius": this.tagSettings.in_page_border_radius + "px",
      "--in-page-border-shadow-color": this.tagSettings.in_page_border_shadow
        ? this.tagSettings.in_page_border_shadow_color
        : "transparent",
      "--in-page-border-shadow": this.tagSettings.in_page_border_shadow,
      "--in-page-color-primary-text":
        this.tagSettings.in_page_color_primary_text,
      "--in-page-color-primary": this.tagSettings.in_page_color_primary,
      "--in-page-color-secondary-text":
        this.tagSettings.in_page_color_secondary_text,
      "--in-page-color-secondary": this.tagSettings.in_page_color_secondary,
      "--in-page-font-family": this.getFontFamily(
        this.tagSettings.in_page_font_family,
      ),
      "--in-page-font-line-height":
        this.tagSettings.in_page_font_line_height + "px",
      "--in-page-font-size": this.tagSettings.in_page_font_size + "px",
      "--in-page-identity-avatar-border-radius":
        {
          round: 100,
          square: 2,
          rounded_square: 8,
        }[this.tagSettings.in_page_identity_avatar_format] + "px",
      "--in-page-identity-fixed-avatar": `url('${this.tagSettings["in_page_identity_fixed_avatar"] ?? "/assets/icons/common/default.svg"}')`,
      "--in-page-identity-fixed-name":
        this.tagSettings["in_page_identity_fixed_name"],
      "--in-page-identity-fixed-title":
        this.tagSettings["in_page_identity_fixed_title"],
      "--in-page-identity-type": this.tagSettings.in_page_identity_type,
      "--in-page-size": this.tagSettings.in_page_size - 15 + "%",

      "--link-border-line-color": this.tagSettings.link_border_line_color,
      "--link-border-line-width":
        this.tagSettings.link_border_line_width + "px",
      "--link-border-line-style": this.tagSettings.link_border_line
        ? "solid"
        : "none",
      "--link-border-line": this.tagSettings.link_border_line,
      "--link-border-radius": this.tagSettings.link_border_radius + "px",
      "--link-border-shadow-color": this.tagSettings.link_border_shadow
        ? this.tagSettings.link_border_shadow_color
        : "transparent",
      "--link-border-shadow": this.tagSettings.link_border_shadow,
      "--link-color-primary-text": this.tagSettings.link_color_primary_text,
      "--link-color-primary": this.tagSettings.link_color_primary,
      "--link-color-secondary-text": this.tagSettings.link_color_secondary_text,
      "--link-color-secondary": this.tagSettings.link_color_secondary,
      "--link-font-family": this.getFontFamily(
        this.tagSettings.link_font_family,
      ),
      "--link-font-line-height": this.tagSettings.link_font_line_height + "px",
      "--link-font-size": this.tagSettings.link_font_size + "px",
      "--link-identity-avatar-border-radius":
        {
          round: 100,
          square: 2,
          rounded_square: 8,
        }[this.tagSettings.link_identity_avatar_format] + "px",
      "--link-identity-fixed-avatar": `url('${this.tagSettings["link_identity_fixed_avatar"] ?? "/assets/icons/common/default.svg"}')`,
      "--link-identity-fixed-name":
        this.tagSettings["link_identity_fixed_name"],
      "--link-identity-fixed-title":
        this.tagSettings["link_identity_fixed_title"],
      "--link-identity-type": this.tagSettings.link_identity_type,
      "--link-size": this.tagSettings.link_size - 15 + "%",
    };
  }
}
