<section class="amplitude-token-display">
  <div *ngIf="integration">
    <form nz-form [nzAutoTips]="autoTips" [formGroup]="validateForm">
      <h2>API Key</h2>
      <nz-form-item>
        <nz-form-control>
          <nz-input-group>
            <input
              type="text"
              nz-input
              nzHasFeedback
              formControlName="token"
              placeholder="Write key"
            />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
</section>
