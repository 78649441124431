import { HttpErrorResponse } from "@angular/common/http";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";

import { AnalyticsDao } from "models/analytics.dao";
import { AnalyticsResponse } from "models/analytics.model";
import { AnalyticsFilterService } from "services/analytics-filters.service";
import { deepCopy } from "utils/object";
import { TrackersService } from "services/trackers.service";
import { Org } from "models/org.model";
import { Survey } from "models/survey.model";
import { GraphNode } from "components/builder/flow";
import {
  AnalyticsFilter,
  AnalyticsQueryResponse,
} from "models/analytics.filters.type";
import { ScenarioQuestion, UUID } from "models/survey.dao.types";
import {
  bucketToNpsAggregation,
  NpsAggregation,
} from "../../indicators/nps/nps.aggregation";
import {
  bucketToCsatAggregation,
  CsatAggregation,
} from "../../indicators/csat/csat.aggregation";
import {
  bucketToCesAggregation,
  CesAggregation,
} from "../../indicators/ces/ces.aggregation";
import {
  replaceBucketsKeys,
  SimpleBucket,
} from "../../indicators/indicator.utils";

@Component({
  selector: "survey-stats-individual-responses-bar-chart-single-question",
  templateUrl: "./bar-chart-single-question.component.html",
  styleUrls: ["./bar-chart-single-question.component.scss"],
})
export class BarChartSingleQuestionComponent implements OnInit, OnDestroy {
  @Input()
  public org: Org;
  @Input()
  public survey: Survey;
  @Input()
  public node: GraphNode;

  private filtersObs$: any = null;
  private lastFilters: AnalyticsQueryResponse;

  public error: Error;
  public loading = true;

  public total = 0;
  public buckets: SimpleBucket[] = [];
  public sortedBuckets: SimpleBucket[] = [];

  public orderAnswerByMostResponded = true;

  public indicators: {
    nps?: NpsAggregation;
    csat?: CsatAggregation;
    ces?: CesAggregation;
  } = {};

  constructor(
    private analyticsFilterService: AnalyticsFilterService,
    private trackersService: TrackersService,
    private analyticsDao: AnalyticsDao,
  ) {}

  ngOnInit() {
    // initial fetch is done automatically
    this.filtersObs$ = this.analyticsFilterService
      .subscribe()
      .subscribe((filters: AnalyticsQueryResponse) => {
        this.lastFilters = deepCopy(filters);
        this.getResponses();
      });
  }

  ngOnDestroy() {
    if (this.filtersObs$) {
      this.filtersObs$.unsubscribe();
    }
  }

  public getCTAActionsCorrelationIds(
    question: ScenarioQuestion,
    defaultLanguage: string,
  ): {
    id: UUID;
    label: string;
  }[] {
    switch (question.call_to_action.type) {
      case "nps":
      case "ces":
      case "csat":
      case "scoring":
        return question.call_to_action.scores.map(
          ({ correlation_id, payload: { emoji } }) => ({
            id: correlation_id,
            label: emoji,
          }),
        );
      case "multiple_choice":
      case "pmf":
      case "link":
        return question.call_to_action.choices.map(
          ({ correlation_id, payload: { emoji, label } }) => ({
            id: correlation_id,
            label: [emoji, label[defaultLanguage]].join(" "),
          }),
        );
      case "appstore_rating":
      case "input":
      case "none":
        return [];
    }
  }

  private getResponses() {
    this.loading = true;
    this.error = null;

    const query = deepCopy(this.lastFilters) as AnalyticsQueryResponse;
    query.question_ids = [this.node.node.correlation_id];
    query.aggregation = [
      {
        by: "by_answer.value",
        params: {
          answer_ids: [UUID(this.node.node.correlation_id)],
        },
      },
    ];
    query.size = 0;

    this.analyticsDao
      .search(query)
      .then((res: AnalyticsResponse) => {
        const buckets = res.aggregations?.answer?.answer?.answer?.buckets ?? [];

        if (!buckets) {
          throw new Error("Aggregation failed.");
        }

        this.total =
          res.aggregations?.answer?.answer?.answer_count?.doc_count ?? 0;

        this.sortedBuckets = replaceBucketsKeys(buckets, [
          this.survey.scenario,
        ]).sort(
          (a: Record<string, any>, b: Record<string, any>) =>
            b.doc_count - a.doc_count,
        );

        const ctaActionsCorrelationIds = this.getCTAActionsCorrelationIds(
          this.node.node.question,
          this.survey.scenario.default_language,
        ).map(
          ({ id, label }) =>
            this.sortedBuckets.find(
              ({ correlation_id }) => correlation_id === id,
            ) ?? {
              id: id,
              key: label,
              correlation_id: id,
              doc_count: 0,
            },
        );

        this.buckets = replaceBucketsKeys(ctaActionsCorrelationIds, [
          this.survey.scenario,
        ]);

        if (this.node.isNPS) {
          this.indicators = {
            nps: bucketToNpsAggregation(buckets, (a) => a, [
              this.survey.scenario,
            ]),
          };
        }
        if (this.node.isCSAT) {
          this.indicators = {
            csat: bucketToCsatAggregation(buckets, (a) => a, [
              this.survey.scenario,
            ]),
          };
        }
        if (this.node.isCES) {
          this.indicators = {
            ces: bucketToCesAggregation(buckets, (a) => a, [
              this.survey.scenario,
            ]),
          };
        }
      })
      .catch((err: HttpErrorResponse) => {
        this.error = err;
        console.error(err);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  public onBucketClick(answer: string) {
    let filter: AnalyticsFilter = null;
    if (this.node.isPreset) {
      filter = {
        type: "response.answer",
        key: this.node.node.correlation_id,
        operator: "in",
        action_correlation_ids: [answer],
      };
    } else {
      filter = {
        type: "response.answer",
        key: this.node.node.correlation_id,
        operator: "in",
        action_correlation_ids: [answer],
      };
    }

    this.analyticsFilterService.switchFilter(filter);

    const props = {
      filter_type: filter.type,
      filter_key: filter.key,
      filter_operator: filter.operator,
    };
    this.trackersService
      .newEventTrackingBuilder("Switched filter")
      .withOrg(this.org)
      .withSurvey(this.survey)
      .withProps(props)
      .build();
  }

  public getResponsePercentage(count: number): string {
    const percent = Math.round((count / this.total) * 100);

    if (isNaN(percent)) {
      return "";
    }

    return `${percent}%`;
  }
}
