import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { NotificationHelper } from "helpers/notification.helper";
import { UUID } from "models/survey.dao.types";
import { TagEditorTokenDao } from "models/tag-editor-token.dao";
import { TagEditorService } from "services/tag-editor.service";
import { UIService } from "services/ui.service";

@Component({
  selector: "css-selector",
  templateUrl: "./css-selector.component.html",
  styleUrls: ["./css-selector.component.scss"],
})
export class CSSSelectorComponent implements OnInit, OnDestroy {
  @Input() public selectorCSS: string = null;
  @Input() public channelID: string = null;
  @Output() public selectorCSSChange = new EventEmitter<string>();

  public constructor(
    private uiService: UIService,
    private tagEditorService: TagEditorService,
    private notificationHelper: NotificationHelper,
    private tagEditorTokenDao: TagEditorTokenDao,
  ) {}

  public isPickVisible = false;
  public currentUrl = "";
  public currentURLs: string[] = [];

  private pickerResultTimeoutSubscription: any = null;

  ngOnInit() {
    // Fetch current urls
    try {
      this.currentURLs = JSON.parse(
        localStorage.getItem(`screeb-message-urls`) || "[]",
      );
    } catch (e) {
      this.currentURLs = [];
      console.error(e);
    }

    this.pickerResultTimeoutSubscription =
      this.tagEditorService.selectorResult.subscribe((value) => {
        this.selectorCSS = value;
        this.selectorCSSChange.emit(value);
        this.notificationHelper.trigger(
          "Channel",
          `The CSS selector have been filled.`,
          "success",
        );
      });
  }

  ngOnDestroy() {
    this.pickerResultTimeoutSubscription?.unsubscribe();
  }

  public onURLChosen(url: string) {
    this.tagEditorTokenDao
      .getWorkspaceIdAndChannelIdAndSurveyId(
        this.uiService.currentOrgId,
        this.uiService.currentSurveyId,
        this.channelID,
      )
      .then((res) => {
        if (!res?.token) {
          throw new Error("Failed to get the token");
        }

        this.tagEditorService.open("picker", res?.token, url, {
          surveyId: UUID(this.uiService.currentSurveyId),
        });
        this.isPickVisible = false;

        // Save in local storage
        if (this.currentURLs.length && this.currentURLs[0] === url) {
          return;
        }

        this.currentURLs = this.currentURLs.filter((u) => u !== url);
        localStorage.setItem(
          `screeb-message-urls`,
          JSON.stringify([url, ...this.currentURLs].slice(-10)),
        );
      })
      .catch((e) => {
        console.error(e);
        this.notificationHelper.trigger(
          "Failed to open the website picker",
          "Please try again later",
          "error",
        );
      });
  }
}
