import {
  OrgAccountRole,
  WorkspaceAccountRole,
} from "models/account-permissions";
import { Serializable } from "models/serializable.model";
import { SupportedLanguages } from "./survey.dao.types";
import { getUserIcon } from "./user.model";

type AccountGoal =
  | "collect-product-feedback"
  | "boost-user-satisfaction"
  | "improve-funnels-conversion-rate"
  | "increase-user-retention"
  | "increase-product-adoption"
  | "discover-new-things-about-users";

type AccountJobTitle =
  | "product-manager"
  | "ux-researcher"
  | "product-ops"
  | "product-designer"
  | "developer"
  | "other";

const AccountJobTitlesToLabel: { [key in AccountJobTitle]: string } = {
  "product-manager": "Product Manager",
  "ux-researcher": "UX Researcher",
  "product-ops": "Product Ops",
  "product-designer": "Product Designer",
  developer: "Developer",
  other: "Other",
};

const AccountGoalsToLabel: { [key in AccountGoal]: string } = {
  "collect-product-feedback": "Collect Product feedback",
  "boost-user-satisfaction": "Boost User Satisfaction",
  "improve-funnels-conversion-rate": "Improve Funnels Conversion Rate",
  "increase-user-retention": "Increase User Retention",
  "increase-product-adoption": "Increase my product Adoption",
  "discover-new-things-about-users": "Discover new Things About my Users",
};

const AccountOrgRoleToLabel: { [key in OrgAccountRole]: string } = {
  admin: "Admin",
  member: "Member",
};

class AccountWorkspacesAccesses extends Serializable {
  constructor(
    public workspace_id?: string,
    public role?: WorkspaceAccountRole,
  ) {
    super();
  }
}

class Account extends Serializable {
  constructor(
    public id?: string,
    public email?: string,
    public auth0_id?: string,
    public firstname?: string,
    public lastname?: string,
    public flags?: AccountFlags,
    public language?: string,

    public phone_number?: string,
    public profile_picture?: string,
    public created_at?: Date,
    public updated_at?: Date,
    public last_seen_at?: Date,
    public role?: OrgAccountRole,

    // Computed
    public workspaces_accesses?: AccountWorkspacesAccesses[],
    public job_title?: AccountJobTitle,
    public tags: string[] = [],
  ) {
    super();
  }

  public get fullname(): string {
    return this.firstname + " " + this.lastname;
  }

  public get isActive(): boolean {
    return +new Date() - +this.last_seen_at <= 5 * 60 * 1000;
  }

  public copy(): Account {
    return new Account().fromJson(JSON.parse(JSON.stringify(this)));
  }
}

function getProfilePicture(account: Account | null) {
  return getUserIcon(account?.id, account?.profile_picture);
}

export type AccountFlagsOnboardingStatus =
  | "qualification"
  | "qualification-invited"
  | "goal"
  | "goal-invited"
  | "setup"
  | "setup-invited"
  | "product-tour"
  | "done";

export function isOnboardingStatus(flag: string): boolean {
  const flags: AccountFlagsOnboardingStatus[] = [
    "qualification",
    "qualification-invited",
    "goal",
    "goal-invited",
    "setup",
    "setup-invited",
  ];

  return flags.includes(flag as AccountFlagsOnboardingStatus);
}

class AccountFlags extends Serializable {
  constructor(
    public job_title: AccountJobTitle, // optional
    public goal: AccountGoal, // optional
    public onboarding_status?: AccountFlagsOnboardingStatus,
    public article_read?: boolean,
    public translation_language?: SupportedLanguages,
    public mfa?: boolean,
  ) {
    super();
  }
}

type AccountAuth0Identity = {
  provider: string;
  user_id: string;
  connection: string;
  isSocial: boolean;
};

type AccountAuth0Enrollment = {
  authenticator_type: string;
  secret: string;
  barcode_uri: string;
  recovery_codes: string[];
};

export {
  Account,
  AccountAuth0Enrollment,
  AccountAuth0Identity,
  AccountGoal,
  AccountGoalsToLabel,
  AccountJobTitle,
  AccountJobTitlesToLabel,
  AccountOrgRoleToLabel,
  getProfilePicture,
};
