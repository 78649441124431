import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { EntitlementService, FeatureKey } from "services/entitlement.service";

export function entitlementGuard(
  entitlement: FeatureKey,
  redirectTo: string,
): CanActivateFn {
  return () => {
    const entitlementService = inject(EntitlementService);
    const router = inject(Router);

    // Check if the function exist
    if (entitlementService[entitlement]) {
      return entitlementService[entitlement]();
    }

    const isAvailable = entitlementService.isAvailable(entitlement);

    return isAvailable || router.createUrlTree([redirectTo]);
  };
}
