import {
  AddonKey,
  billingCycleDuration,
  OrgBilling,
  PlanType,
} from "models/org_billing.model";
import { formatNumber } from "utils/number";

export const billingCycleDurationLabels: {
  [key in billingCycleDuration]: string;
} = {
  // day: "Daily",
  // week: "Weekly",
  month: "Monthly",
  year: "Yearly",
  // once: "Once",
};

export type PlanTypeInfo = {
  type: PlanType;
  name: string;
  startPrice?: number;
  upgradeCTA?: string;
  subtitle: string;
  features: string[];
  currentFeatures: (string | ((orgBilling: OrgBilling) => string))[];
};

export const planTypeInfos: { [key in PlanType]: PlanTypeInfo } = {
  free: {
    type: "free",
    name: "Inactive Subscription",
    startPrice: 0,
    upgradeCTA: null,
    subtitle:
      "Ideal for Startups to measure NPS on auto-pilot every month and kickstart your user research.",
    features: [],
    currentFeatures: [
      "1 Team Member Access",
      "Read Only Access to Surveys & Responses",
      // "1 User Segment",
      //"1 Funnel Report",
      //"Limited Access to our Integrations",
    ],
  },

  scale: {
    type: "scale",
    name: "Scale Plan",
    startPrice: 299,
    upgradeCTA: "Upgrade",
    subtitle:
      "Ideal for companies who need a highly effective, small-scale feedback collection & analysis tool integrated into their product stack.",
    features: [
      "Unlimited Team Member Access",
      "Unlimited Surveys & Responses",
      "1 Workspace",
      "User Segmentation",
      "Funnel Analysis",
      "User Profiling",
      "Targeting: events, properties, device",
      "Access to In-App web, In-Page and Email Surveys",
      "NPS, CSAT and CES Dashboard",
      "Product integration with Segment, Amplitude, Webhooks, etc.",

      // legacy
      // "Unlimited Team Member Access",
      // "Unlimited Surveys & Responses",
      // // "Unlimited User Segments & Segment Analytics",
      // "Unlimited Funnel Reports",
      // "Full Access to Integrations",
      // "Multi-Lingual Surveys",
      // "Survey Capping",
      // "Dedicated Support",
    ],
    currentFeatures: [
      (orgBilling: OrgBilling) => {
        if (orgBilling.expected_metadata.mtu_scheme === "unlimited") {
          return "Unlimited MAU";
        }
        return `${formatNumber(
          orgBilling.expected_metadata.max_mtu,
          "number",
        )} MAU`;
      },
      (orgBilling: OrgBilling) => {
        if (orgBilling.expected_metadata.max_workspaces === -1) {
          return "Unlimited Workspaces";
        }
        return `Up to ${orgBilling.expected_metadata.max_workspaces} Workspaces`;
      },
      "Unlimited Team Member Access",
      "Unlimited Surveys & Responses",
      // "Unlimited User Segments & Segment Analytics",
      "Unlimited Funnel Reports",
      "Full Access to Integrations",
      "Livechat Support",
      "Up to 1 year data retention",
    ],
  },

  advanced: {
    type: "advanced",
    name: "Advanced Plan",
    startPrice: 465,
    upgradeCTA: "Upgrade",
    subtitle:
      "For companies looking to collect and analyze large volumes of feedback, and effectively communicate those insights.",
    features: [
      // "Unlimited Team Member Access",
      // "Unlimited Surveys & Responses",
      "2 Workspaces",
      "Access to Mobile SDKs",
      "Audio and Video Surveys",
      "Feedback Translation",
      "AI Content Analysis",
      "In-App Messages & Guides",
      "Integration with Jira software",

      // legacy
      /*
      // "Unlimited User Segments & Segment Analytics",
      "Unlimited Funnel Reports",
      "Full Access to Integrations",
      "Multi-Lingual Surveys",
      "Survey Capping",
      "Dedicated Support", */
    ],
    currentFeatures: [
      (orgBilling: OrgBilling) => {
        if (orgBilling.expected_metadata.mtu_scheme === "unlimited") {
          return "Unlimited MAU";
        }
        return `${formatNumber(
          orgBilling.expected_metadata.max_mtu,
          "number",
        )} MAU`;
      },
      (orgBilling: OrgBilling) => {
        if (orgBilling.expected_metadata.max_workspaces === -1) {
          return "Unlimited Workspaces";
        }
        return `Up to ${orgBilling.expected_metadata.max_workspaces} Workspaces`;
      },
      "Unlimited Team Member Access",
      "Unlimited Surveys & Responses",
      // "Unlimited User Segments & Segment Analytics",
      "Unlimited Funnel Reports",
      "Full Access to Integrations",
      "Livechat Support",
      "Up to 1 year data retention",
    ],
  },

  custom: {
    type: "custom",
    name: "Custom Plan",
    startPrice: null,
    upgradeCTA: "Contact us",
    subtitle:
      "Tailored for organizations seeking a custom platform with dedicated support for a larg-scale customer feeeback strategy.",
    features: [
      "Custom number of Workspaces",
      "Advanced Survey Graphical Customisation",
      "Custom data retention duration",
      "Dedicated training for your team members",
      "Dedicated Customer Success Manager",
      "Ability to pay via wire transfer with custom contract",
    ],
    currentFeatures: [
      (orgBilling: OrgBilling) => {
        if (orgBilling.expected_metadata.mtu_scheme === "unlimited") {
          return "Unlimited MAU";
        }
        return `${formatNumber(
          orgBilling.expected_metadata.max_mtu,
          "number",
        )} MAU`;
      },
      (orgBilling: OrgBilling) => {
        if (orgBilling.expected_metadata.max_workspaces === -1) {
          return "Unlimited Workspaces";
        }
        return `Up to ${orgBilling.expected_metadata.max_workspaces} Workspaces`;
      },
      "Unlimited Team Member Access",
      "Unlimited Surveys & Responses",
      // "Unlimited User Segments & Segment Analytics",
      "Unlimited Funnel Reports",
      "Full Access to Integrations",
      "Advanced Survey Customisation",
      "Custom data retention duration",
      "Dedicated Customer Success Manager",
    ],
  },
};

export const recommendedPlanTypeUpgrade: {
  [key in PlanType]: PlanType | null;
} = {
  free: "scale",
  scale: "advanced",
  advanced: "custom",
  custom: null,
};

export type AddonInfo = {
  name: string;
  img: string;
  suggestedForPlans?: PlanType[];
  includedInPlans?: PlanType[];
};

export type displayedAddonKeys = AddonKey | "additional-mtu";

export const addonInfos: { [key in displayedAddonKeys]: AddonInfo } = {
  "additional-workspaces": {
    name: "Additional Workspaces",
    img: "workspace.png",
    suggestedForPlans: ["scale", "advanced", "custom"],
    includedInPlans: [],
  },
  "white-label": {
    name: "Remove 'Powered by Screeb'",
    img: "powered-by-screeb.png",
    suggestedForPlans: ["scale", "advanced"],
    includedInPlans: ["custom"],
  },
  "additional-mtu": {
    name: "Add more MAUs",
    img: "additional-mtu.png",
    suggestedForPlans: ["scale", "advanced", "custom"],
    includedInPlans: [],
  },
  "graphical-survey-customization": {
    name: "Graphical Survey Customization",
    img: "advanced-graphical-customisation.png",
    suggestedForPlans: ["scale", "advanced"],
    includedInPlans: ["custom"],
  },
  "response-translation": {
    name: "Response Translation",
    img: "response-translation.png",
    suggestedForPlans: ["scale"],
    includedInPlans: ["advanced", "custom"],
  },
  "audio-video-survey": {
    name: "Audio / Video Format",
    img: "audio-video.png",
    suggestedForPlans: ["scale"],
    includedInPlans: ["advanced", "custom"],
  },
  // "ai-content-analysis": {
  //   name: "AI Content Analysis",
  //   img: "ai-content-analysis.png",
  //   suggestedForPlans: [],
  //   includedInPlans: [], // ["advanced", "custom"], // commented until addon is present in stripe
  // },
  "survey-inapp-mobile": {
    name: "Mobile SDKs",
    img: "mobile-sdk.png",
    suggestedForPlans: ["scale"],
    includedInPlans: ["advanced", "custom"],
  },
  // "message-inapp": {
  //   name: "In-App Messages",
  //   img: "survey-inapp-mobile.png",
  //   suggestedForPlans: [],
  //   includedInPlans: [], // ["advanced", "custom"], // commented until addon is present in stripe
  // },
  "iam-mfa": {
    name: "Two Factor Authentication",
    img: "mfa.png",
    suggestedForPlans: ["advanced"],
    includedInPlans: ["custom"],
  },
  // "additional-events": {
  //   name: "Collect more Events and Properties",
  //   img: "powered-by-screeb.png",
  // },
  // "session-recording": {
  //   name: "Activate Session Recording",
  //   img: "session-recording.png",
  // },
};
