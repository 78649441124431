<div class="manual-tip">
  <screeb-icon size="sm" icon="info-circle"></screeb-icon>
  <span>
    With manual mode, there's no need to make live the channel, as the start
    command ignores the channel status.
    <br />
    Unless you want both manual and targeting to start the
    {{ uiService.isMessagePage ? "message" : "survey" }}.
  </span>
</div>
<div nz-row [nzGutter]="[0, 0]">
  <div nz-col [nzSpan]="24" class="row">
    <h3>Start the {{ uiService.isMessagePage ? "message" : "survey" }}</h3>

    <nz-radio-group [(ngModel)]="multiple">
      <big-radio [nzValue]="true" label="Every time"></big-radio>
      <big-radio [nzValue]="false" label="Only once"></big-radio>
    </nz-radio-group>
  </div>

  <div nz-col [nzSpan]="24">
    <p>
      Display the {{ uiService.isMessagePage ? "message" : "survey" }} by
      executing a command from Screeb SDK (ideal if you want to display the
      {{ uiService.isMessagePage ? "message" : "survey" }} after a click on a
      button, for example)
      <br />
      You want to link data to this
      {{ uiService.isMessagePage ? "message" : "survey" }} responses?
      <a [href]="getDocURL('widget')" target="_blank">Read our doc here</a>
      <br />
      Use this code below on any element of your app to trigger the display of
      the {{ uiService.isMessagePage ? "message" : "survey" }}:
    </p>

    <div>
      <h3>Web</h3>
      <div class="installation-code-container">
        <code
          class="installation-code"
          [highlight]="getCode('widget', this.multiple)"
        ></code>
        <button
          class="copy-button"
          nz-button
          nzSize="medium"
          nzType="primary"
          (click)="clipboardCopy(getCode('widget', multiple))"
        >
          Copy
        </button>
      </div>

      <ng-container *ngIf="interaction !== 'in_page'">
        <br />
        <h3>iOS</h3>
        <div class="installation-code-container">
          <code
            class="installation-code"
            [highlight]="getCode('ios', multiple)"
          ></code>
          <button
            class="copy-button"
            nz-button
            nzSize="medium"
            nzType="primary"
            (click)="clipboardCopy(getCode('ios', multiple))"
          >
            Copy
          </button>
        </div>
      </ng-container>

      <ng-container *ngIf="interaction !== 'in_page'">
        <br />

        <h3>Android</h3>
        <div class="installation-code-container">
          <code
            class="installation-code"
            [highlight]="getCode('android', multiple)"
          ></code>
          <button
            class="copy-button"
            nz-button
            nzSize="medium"
            nzType="primary"
            (click)="clipboardCopy(getCode('android', multiple))"
          >
            Copy
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</div>
