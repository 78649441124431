<div class="indicator-with-border">
  <div class="indicator-header">
    <h4>Last Surveys</h4>
    <button
      *ngIf="'survey.create' | hasPermission"
      [routerLink]="'/org/' + uiService.currentOrgId + '/survey/create'"
      nz-button
      nzSize="medium"
      [nzType]="'primary'"
    >
      + New survey
    </button>
  </div>
  <div>
    <nz-empty
      class="survey-empty-screen"
      *ngIf="!lastSurveys.length"
      [nzNotFoundContent]="contentTpl"
      nzNotFoundImage="/assets/empty-screen/empty-screen-surveys.png"
    >
      <ng-template #contentTpl>
        <div>
          Ask a first question to your Users now and get qualitative insights
          about your product!
        </div>
        <a [routerLink]="'/org/' + uiService.currentOrgId + '/survey/create'">
          Create my First Survey&nbsp;
          <span nz-icon nzType="arrow-right" nzTheme="outline"></span>
        </a>
      </ng-template>
    </nz-empty>

    <table class="surveys-response-rate" *ngIf="lastSurveys.length">
      <thead>
        <tr>
          <th></th>
          <th style="min-width: 120px; width: 120px">Response rate</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let survey of lastSurveys">
          <td>
            <a
              [routerLink]="
                '/org/' +
                uiService.currentOrgId +
                '/survey/' +
                survey.id +
                '/stats'
              "
            >
              {{ survey.title }}
            </a>
          </td>
          <td>
            <a
              nzTooltipTitle="Compared to the average response rate in your industry"
              nz-tooltip
              class="surveys-response-rate-indicators"
              [routerLink]="
                '/org/' +
                uiService.currentOrgId +
                '/survey/' +
                survey.id +
                '/stats'
              "
            >
              @let responseRatePct =
                (survey.stats.response_rate * 100).toFixed();
              <span>{{ responseRatePct }}%</span>
              <indicator-variation
                [variation]="
                  (
                    survey.stats.response_rate * 100 -
                    industryAverageResponseRate
                  ).toFixed()
                "
                sign="force"
                format="points"
              ></indicator-variation>
              <svg
                width="14"
                height="10"
                viewBox="0 0 14 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.9241 4.61722C13.8753 4.49927 13.803 4.38877 13.7071 4.29289L9.70711 0.292893C9.31658 -0.0976311 8.68342 -0.097631 8.29289 0.292893C7.90237 0.683418 7.90237 1.31658 8.29289 1.70711L10.5858 4L1 4C0.447715 4 0 4.44772 0 5C0 5.55229 0.447715 6 1 6L10.5858 6L8.29289 8.29289C7.90237 8.68342 7.90237 9.31658 8.29289 9.70711C8.68342 10.0976 9.31658 10.0976 9.70711 9.70711L13.7071 5.70711C13.9 5.51419 13.9976 5.26207 14 5.00923M13.9241 4.61722C13.9719 4.73244 13.9988 4.85854 14 4.99077L13.9241 4.61722Z"
                  fill="#727387"
                />
              </svg>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <a
      *ngIf="haveMore"
      class="see-all-surveys-link"
      [routerLink]="'/org/' + uiService.currentOrgId + '/survey/list'"
    >
      See all your surveys&nbsp;
      <span nz-icon nzType="arrow-right" nzTheme="outline"></span>
    </a>
  </div>
</div>
