<button nz-button nzType="primary" nzSize="large" (click)="openPanel()">
  <!-- <img class="label-icon" src="/assets/icons/integrations/slack-icon.svg" /> -->
  <img
    class="label-icon"
    src="/assets/icons/common/share-via.svg"
    alt="Share"
  />
  Share
</button>

<share-lateral-panel
  [reportType]="reportType"
  *ngIf="lateralPanelOpened"
  (onClose)="closePanel()"
></share-lateral-panel>
