export type Post101Author = {
  name: string;
  description: string;
  pictureUrl?: string;
};

export type Post101 = {
  title: string;
  url?: string | ((currentOrgId: string) => string);
  author?: Post101Author;
  color?: string;
  readTime?: number;
};

export const authors = {
  "Simon Robic": {
    name: "Simon Robic",
    description: "Head of Product & Marketing at Screeb",
    pictureUrl: "/assets/people/simon-robic.png",
  } as Post101Author,
};
