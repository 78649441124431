import { Component, Input } from "@angular/core";
import { TargetingRule } from "models/targeting-rule.model";
import { EntitlementService, FeatureKey } from "services/entitlement.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";

@Component({
  selector: "rule-device",
  templateUrl: "./rule-device.component.html",
  styleUrls: ["./rule-device.component.scss"],
})
export class TargetingRuleDeviceComponent {
  @Input()
  public rule: TargetingRule = null;

  constructor(
    public featureFlaggingService: FeatureFlaggingService,
    private entitlementService: EntitlementService,
  ) {}

  public onDeviceChange(
    rule: TargetingRule,
    device: string,
    checked: boolean,
    entitlement: FeatureKey,
  ) {
    // if (this.survey.type === "message") {
    //   return this.entitlementService.isAvailable("message_inapp");
    // }

    if (!this.entitlementService.isAvailable("survey_edition")) {
      return;
    }

    if (!this.entitlementService.isAvailable(entitlement)) {
      return;
    }

    if (checked) {
      rule.value.v_s_arr?.push(device);
    } else {
      rule.value.v_s_arr = rule.value.v_s_arr?.filter((d) => d !== device);
    }
  }
}
