/* eslint-disable @angular-eslint/component-class-suffix */
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import {
  entitlementToIllustration,
  FeatureKey,
} from "services/entitlement.service";

@Component({
  selector: "upgrade-banner",
  template: `
    <div class="upgrade-banner" [ngClass]="{ positionFixed, small, danger }">
      <img
        alt="Upgrade"
        class="upgrade-illustration"
        src="/assets/illustrations{{ illustration }}"
      />
      <div class="upgrade-content">
        <div *ngIf="title" class="upgrade-title">{{ title }}</div>
        <div *ngIf="text" class="upgrade-description" [innerHTML]="text"></div>
        <div class="upgrade-progress">
          <div
            *ngIf="progressText"
            class="upgrade-progress-text"
            [innerHTML]="progressText"
          ></div>
          <nz-progress
            *ngIf="progress !== -1"
            [nzPercent]="progress"
            [nzStrokeColor]="getProgressColor()"
            [nzShowInfo]="false"
            nzStatus="active"
          ></nz-progress>
        </div>
      </div>
      <div class="upgrade-actions">
        <button
          nz-button
          nzType="primary"
          [nzSize]="small ? 'medium' : 'large'"
          nzShape="round"
          (click)="upgradeClick(); closeClick.emit()"
        >
          {{ CTAText !== null ? CTAText : "Upgrade my account" }}
        </button>

        <a
          *ngIf="positionFixed"
          nz-button
          nzType="text"
          class="upgrade-close"
          (click)="closeClick.emit()"
        >
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.9502 1.05022C10.5597 0.659693 9.92651 0.659693 9.53599 1.05022L6.00049 4.58571L2.465 1.05022C2.07448 0.659695 1.44131 0.659694 1.05079 1.05022C0.660262 1.44074 0.660262 2.07391 1.05079 2.46443L4.58628 5.99993L1.05071 9.5355C0.660182 9.92602 0.660181 10.5592 1.05071 10.9497C1.44123 11.3402 2.0744 11.3402 2.46492 10.9497L6.00049 7.41414L9.53607 10.9497C9.92659 11.3402 10.5598 11.3402 10.9503 10.9497C11.3408 10.5592 11.3408 9.92602 10.9503 9.5355L7.41471 5.99993L10.9502 2.46443C11.3407 2.07391 11.3407 1.44074 10.9502 1.05022Z"
              fill="#727387"
            />
          </svg>
        </a>
      </div>
    </div>
  `,
  styles: [
    `
      .upgrade-banner {
        display: flex;
        align-items: center;
        background: var(--screeb-color-purple-200);
        border-radius: 8px;
        padding: 32px 40px;
        width: 100%;
        animation: shadeAppear 200ms;
      }
      .upgrade-title {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 150%;
        color: var(--screeb-color-black);
      }
      .small .upgrade-title {
        font-size: 19px;
        line-height: 130%;
      }
      .upgrade-illustration {
        width: auto;
        height: 100px;
        min-width: 200px;
      }
      .upgrade-content {
        flex: 1;
      }
      .upgrade-description {
        margin-top: 8px;
        font-family: "Rubik";
        font-style: normal;
        font-size: 16px;
        line-height: 150%;
        color: var(--screeb-color-black);
      }
      .small .upgrade-description {
        font-size: 14px;
        line-height: 130%;
      }
      .upgrade-actions {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 8px;
      }

      .positionFixed {
        position: fixed;
        z-index: 799;
        right: 0;
        top: 0;
        padding: 16px 40px;
        align-items: center;
        min-height: 100px;
        box-shadow: 0px 2px 24px rgba(48, 49, 64, 0.1);
        padding-right: 60px;
        padding-left: 20px;
        border-radius: 12px;
        margin: 12px 124px;
        width: calc(100% - 248px);

        @media (max-width: 768px) {
          flex-direction: column;
          width: calc(100% - 24px);
          padding-right: 0.75rem;
          padding-left: 0.75rem;
          margin: 12px;

          .upgrade-actions {
            margin-top: 0.75rem;
          }
        }
      }

      .positionFixed .upgrade-illustration {
        height: 60px;
        min-width: 100px;
      }

      .positionFixed .upgrade-title {
        font-weight: 700;
        font-size: 18px;
        line-height: 150%;
      }

      .small {
        min-height: 60px;
        padding: 8px 50px 8px 24px;
      }

      .small .upgrade-illustration {
        height: 40px;
        min-width: 60px;
      }

      .upgrade-description {
        margin-top: 4px;
      }

      .upgrade-close {
        position: absolute;
        right: 6px;
        top: 6px;
      }

      .small .upgrade-close {
        top: 16px;
      }

      .upgrade-banner.danger {
        background: var(--screeb-color-warning);
      }

      .upgrade-progress {
        width: 80%;
        display: flex;
      }

      .upgrade-progress nz-progress {
        flex: 1;
      }

      .upgrade-progress-text {
        margin-right: 12px;
      }

      @keyframes shadeAppear {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }
    `,
  ],
})
export class UpgradeBanner implements OnInit {
  @Input() public title: string = "Upgrade your account!";
  @Input() public text: string = "";
  @Input() public CTAText: string = null;
  @Input() public orgId: string = null;
  @Input() public positionFixed = false;
  @Input() public small = false;
  @Input() public danger = false;
  @Input() public progress: number = -1;
  @Input() public progressText: string = null;
  @Input() public upgradeType: FeatureKey = null;
  @Input() public routes: string[] = null;

  @Output() public closeClick = new EventEmitter<object>();

  public illustration: string = "/gauge-product-value.svg";

  constructor(private router: Router) {}

  ngOnInit() {
    if (this.upgradeType !== null) {
      this.getIllustration();
    }
  }

  upgradeClick() {
    if (this.routes) {
      this.router.navigate(this.routes);
      return;
    }
    this.router.navigate(["org", this.orgId, "settings", "billing"]);
  }

  getProgressColor() {
    if (this.progress >= 80) {
      return "var(--screeb-color-error)";
    }

    return "var(--screeb-color-primary)";
  }

  getIllustration() {
    const visual = entitlementToIllustration[this.upgradeType];

    if (visual !== undefined) {
      this.text = visual.text;
      this.illustration = visual.illustration;
    }
  }
}
