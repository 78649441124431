<free-trial-banner />
<header>
  @let avatarUrl =
    loadingProperties
      ? "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
      : getUserIcon(user.id, getAvatarURL(userProperties));
  <img
    [class.has-shimmer]="loadingProperties"
    loading="lazy"
    alt="User's icon"
    nz-icon
    [src]="avatarUrl"
  />
  <div class="identity">
    <h1>
      @let formattedUserNameOrID =
        getFormattedUserNameOrIDByNormalizedProperty(
          user.id,
          user.aliases,
          userProperties
        );
      <span [class.has-shimmer]="loadingProperties">
        {{ formattedUserNameOrID }}
      </span>
      <ng-container *ngIf="user.is_anonymous">
        <nz-tag
          nz-popover
          nzPopoverTitle="Anonymous users"
          [nzPopoverContent]="anonymousHelp"
        >
          Anonymous
        </nz-tag>

        <ng-template #anonymousHelp>
          This user has not been identified in Javascript tag and mobile SDKs.
          <br />
          <a rel="noopener" href="https://help.screeb.app" target="_blank">
            <i nz-icon nzType="link"></i>
            See our help section.
          </a>
        </ng-template>
      </ng-container>
    </h1>
    <div class="groups" *ngIf="userGroups.length">
      <span>Part of these segments:</span>
      <nz-tag
        *ngFor="let userGroup of userGroups"
        [nzMode]="('segment.update' | hasPermission) ? 'closeable' : 'default'"
        (nzOnClose)="removeUserFromGroup(userGroup); $event.preventDefault()"
      >
        @let formattedUserGroupNameOrID =
          getFormattedUserGroupNameOrID(userGroup);
        {{ formattedUserGroupNameOrID }}
      </nz-tag>
    </div>
  </div>
  <div class="right-actions">
    <user-add-to-segment
      *ngIf="'segment.update' | hasPermission"
      [org]="org"
      [user]="user"
      [orgUserGroups]="orgUserGroups"
      [userAssignedGroups]="userGroups"
      (addedToSegments)="onAddedToSegments($event)"
    ></user-add-to-segment>
    <a
      routerLink="/org/{{ org.id }}/survey/create"
      [queryParams]="{ respondentIds: user.id }"
    >
      <button
        *ngIf="'survey.create' | hasPermission"
        nz-button
        nzType="primary"
        nzSize="large"
        class="ask-a-question-button"
      >
        <img
          alt="Ask a question"
          class="icon"
          src="/assets/icons/common/ask-a-question.svg"
        />
        Ask a question
      </button>
    </a>
  </div>
</header>

<nz-tabset
  [nzSelectedIndex]="tabIndex"
  (nzSelectedIndexChange)="onTabChange($event)"
  [nzTabBarExtraContent]="extraTemplate"
>
  <nz-tab nzTitle="Highlights">
    <div class="highlight-header" nz-row [nzGutter]="[32, 16]">
      <div nz-col nzSpan="8">
        <widget-graph
          type="nps"
          [startDate]="startDate"
          [endDate]="endDate"
          [userId]="user.id"
        ></widget-graph>
      </div>
      <div nz-col nzSpan="8">
        <widget-graph
          type="ces"
          [startDate]="startDate"
          [endDate]="endDate"
          [userId]="user.id"
        ></widget-graph>
      </div>
      <div nz-col nzSpan="8">
        <widget-graph
          type="csat"
          [startDate]="startDate"
          [endDate]="endDate"
          [userId]="user.id"
        ></widget-graph>
      </div>
    </div>
    <section nz-row [nzGutter]="[32, 16]" class="indicators">
      <div nz-col nzSpan="8" class="fullsize-indicator">
        <user-timeline [user]="user"></user-timeline>
      </div>
      <div nz-col nzSpan="8" class="fullsize-indicator">
        <div class="indicator-with-border full-height emotions">
          <div class="indicator-header">
            <h4>
              <span>Responses Emotions Analysis</span>
            </h4>
          </div>
          <survey-stats-indicators-radar-indicator
            [labels]="[
              '😡 Anger',
              '&nbsp;😄 Joy&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;',
              '😱 Fear',
              '😢 Sadness',
            ]"
            [legend]="emotionsChartLegend"
            [datasets]="emotionsChartDatasets"
            [loading]="loadingEmotions"
          />
        </div>
      </div>
      <div nz-col nzSpan="8" class="fullsize-indicator">
        <user-activity [user]="user"></user-activity>
      </div>
      <ng-container
        *ngIf="
          (!initialFetchEventsTypeform || !loadingEventsTypeform) &&
          userEventsTypeform.length
        "
      >
        <div nz-col nzSpan="24" class="fullsize-indicator">
          <h2>
            <img
              alt="Typeform"
              class="icon"
              src="/assets/icons/users/typeform.svg"
            />
            <span>Typeform responses</span>
          </h2>
          <div class="indicator-with-border">
            <ul class="stats-list">
              <li
                *ngFor="let event of userEventsTypeform"
                class="stat-item clickable"
              >
                @let typeformEventURL = getTypeformEventURL(event);
                <a
                  class="stat-item-header typeform"
                  [href]="typeformEventURL"
                  (click)="event.opened = !event.opened; onEventClicked()"
                  target="_blank"
                >
                  <square-icon icon="typeform"></square-icon>
                  <div class="stat-item-data">
                    <div class="stat-item-title">
                      <span>{{ event.slug }}</span>
                      <tag-registry-entry-source
                        [sources]="[event.source]"
                      ></tag-registry-entry-source>
                    </div>
                    <div
                      class="stat-item-value"
                      [title]="event.triggered_at | toLocaleString"
                    >
                      {{
                        event.triggered_at
                          | dfnsFormatDistanceToNow: { addSuffix: true }
                      }}
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </ng-container>
    </section>
  </nz-tab>
  <nz-tab nzTitle="Responses">
    <div
      class="empty-responses"
      *ngIf="!userResponsesES?.length && !initialFetchResponsesES"
    >
      <img
        src="/assets/illustrations/user-qualification.svg"
        alt="No responses"
      />
      <h3>No responses</h3>
      <p>
        This user has not answered any surveys yet. You can ask a question to
        get started.
      </p>
    </div>
    <div *ngFor="let response of userResponsesES">
      <h2 class="response-header">
        <div class="left">
          <img
            alt="{{ response.survey.title }}"
            class="icon"
            src="/assets/icons/users/response.svg"
          />
          <span>{{ response.survey.title }}</span>
        </div>
        <div class="right">
          <button
            *ngIf="'analytics.response.delete' | hasPermission"
            nz-button
            nzType="default"
            nzSize="medium"
            nzDanger
            [nzLoading]="deletingByResponseId[response.response.id] === true"
            (click)="
              deleteResponse(
                response.response.respondent_id,
                response.response.id
              )
            "
          >
            Delete
          </button>
        </div>
      </h2>

      <survey-stats-individual-responses-questions-table
        [(expandedNodes)]="expandedResponses[response.response.id]"
        [nodes]="response.questions"
        [displaySurveyTitle]="false"
        [questionBodyView]="questionBodyView"
      ></survey-stats-individual-responses-questions-table>

      <ng-template #questionBodyView let-item="item">
        <survey-stats-individual-responses-answers-table-single-question
          [(expandedResponsesById)]="expandedResponsesById"
          [org]="org"
          [survey]="item.survey"
          [responses]="[response.response]"
          [nodeCorrelationId]="item.node.correlationId"
          [integrations]="[]"
          [removable]="false"
        ></survey-stats-individual-responses-answers-table-single-question>
      </ng-template>
    </div>
  </nz-tab>
  <nz-tab
    nzTitle="Sessions Replay"
    *ngIf="
      ('session_recording' | hasEntitlement) &&
      ('isSessionRecordingEnabled' | hasFeatureFlag)
    "
  >
    <user-records
      *ngIf="tabIndex === tabsMapping['replays']"
      [org]="org"
      [user]="user"
      [registryEntriesIdentityProperty]="orgUserProperties"
      [registryEntriesGroup]="orgUserGroups"
      [registryEntriesEvent]="orgUserEvents"
      [languages]="languages"
    ></user-records>
  </nz-tab>
  <nz-tab nzTitle="Properties & Events">
    <section nz-row [nzGutter]="[32, 16]" class="indicators">
      <div nz-col nzSpan="12" class="fullsize-indicator">
        <div class="indicator-with-border full-height">
          <div class="indicator-header">
            <h4>Properties</h4>
          </div>
          <div
            class="loader"
            *ngIf="loadingProperties && initialFetchProperties"
          >
            <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
          </div>
          <ng-container *ngIf="!initialFetchProperties || !loadingProperties">
            <user-properties
              [userProperties]="userProperties"
            ></user-properties>
          </ng-container>
        </div>
      </div>
      <div nz-col nzSpan="12" class="fullsize-indicator">
        <div class="indicator-with-border full-height">
          <div class="indicator-header">
            <h4>Events</h4>
          </div>
          <div class="loader" *ngIf="loadingEvents && initialFetchEvents">
            <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
          </div>
          <ng-container *ngIf="!initialFetchEvents || !loadingEvents">
            <user-events
              [userEvents]="userEvents"
              (eventClicked)="onEventClicked()"
            ></user-events>
          </ng-container>
        </div>
      </div>
    </section>
  </nz-tab>
</nz-tabset>
<ng-template #extraTemplate>
  <button
    *ngIf="'user.delete' | hasPermission"
    nz-button
    nzType="link"
    class="gdpr-request"
    [nzLoading]="loadingRequestDataDeletion"
    (click)="requestDataDeletion($event)"
  >
    <span>Request data deletion</span>
    <screeb-icon size="md" icon="delete"></screeb-icon>
  </button>
</ng-template>
