import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { startOfYear, subDays, subMonths } from "date-fns";
import dayjs from "dayjs/esm";

import { PageComponentInterface } from "components/PageComponentInterface";
import { AnalyticsDao } from "models/analytics.dao";
import { AnalyticsQuery } from "models/analytics.filters.type";
import { AnalyticsResponse } from "models/analytics.model";
import { Org } from "models/org.model";
import { RegistryEntry } from "models/registry.model";
import { SequenceDao } from "models/sequence.dao";
import { SequenceFunnel } from "models/sequence.types";
import { UUID } from "models/survey.dao.types";
import { Survey } from "models/survey.model";
import {
  DateRange,
  TimePeriod,
} from "ngx-daterangepicker-material/daterangepicker.component";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { PermissionsService } from "services/permissions.service";
import { RoutingService } from "services/routing.service";
import { SettingsService } from "services/settings.service";
import { UIService } from "services/ui.service";
import { filterObject, mapObject } from "utils/object";
import {
  FunnelReportAggregation,
  computeFunnelReportAggregationFromResponse,
} from "./funnel-report.aggregation";
import { FunnelReportSummary } from "./funnel-report.types";

@Component({
  selector: "page-funnel-report",
  templateUrl: "./funnel-report.component.html",
  styleUrls: ["./funnel-report.component.scss"],
})
export class FunnelReportPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Funnel report";
  public name = "Funnel report";

  private obs: any = null;
  public org: Org = null;
  public surveys: Survey[] = null;
  public funnel: SequenceFunnel = null;
  public surveysById: Record<UUID, Survey> = {};
  public registryEntriesEvent: RegistryEntry[] = [];
  public funnelReportAggregation: FunnelReportAggregation = [];

  public loading = true;
  public loadingPercentage = 0;
  public error: Error = null;
  public needHelpVisible = false;

  public selected: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs };

  private interval: any = null;

  public ranges: Record<string, Date[]>;

  public skipDatesUpdated = false;

  public summary: FunnelReportSummary = null;
  public summaryLoading = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private routingService: RoutingService,
    private analyticsDao: AnalyticsDao,
    private settingsService: SettingsService,
    public uiService: UIService,
    public permissionsService: PermissionsService,
    private featureFlaggingService: FeatureFlaggingService,
    private sequenceDao: SequenceDao,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.obs = this.route.data.subscribe((data) => {
      this.org = data.org;
      this.funnel = data.funnel;
      this.registryEntriesEvent = data.registryEntriesEvent;
      this.surveys = data.surveys;
      this.surveysById = this.surveys.reduce(
        (surveysById, survey) => ({
          ...surveysById,
          [survey.id]: survey,
        }),
        {},
      );

      let minDate = dayjs(new Date()).startOf("month").toDate();
      if (this.org.created_at > minDate) {
        minDate = this.org.created_at;
      }
      this.selected = {
        startDate: dayjs(new Date()).subtract(90, "days"),
        endDate: dayjs(new Date()),
      };

      this.setRanges();
      this.interval = setInterval(() => {
        // @TODO: hack because the ranges are not updated when the minDate is not available
        this.setRanges();
      }, 1000);

      this.fetchData();
    });
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }

    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  async fetchData() {
    this.error = null;
    this.loading = true;
    this.loadingPercentage = 0;

    const queries = this.funnel.funnel.steps.map(
      (_, idx) =>
        ({
          type: "respondent",
          org_id: UUID(this.org.id),
          survey_ids: [],
          filters_bool: "AND",
          filters: [],
          range: {
            field: "last_activity_at",
            start: this.org.created_at,
            end: this.selected.endDate.toDate(),
          },
          size: 0,
          aggregation: [
            {
              by: "by_event.sequence",
              params: {
                event_sequence_date_from: this.selected.startDate.toDate(),
                event_sequence_date_to: this.selected.endDate.toDate(),
                event_sequence_ids: this.funnel.funnel.steps
                  .slice(0, idx + 1)
                  .map(({ event_id }) => event_id),
              },
            },
          ],
        }) as AnalyticsQuery,
    );

    const responses: AnalyticsResponse[] = [];

    for (const query of queries) {
      try {
        const response = await this.analyticsDao.search(query);

        responses.push(response);
        this.loadingPercentage = (responses.length / queries.length) * 100;
      } catch (error) {
        this.error = error;
      }
    }

    const response: AnalyticsResponse = responses.reduce(
      (finalResponse, response) => {
        if (!finalResponse) {
          return response;
        }

        const aggs = finalResponse.aggregations;
        const aggsResponse = response?.aggregations ?? {};

        //aggs.doc_count += aggsResponse.doc_count;

        delete aggsResponse.doc_count;
        Object.entries(aggsResponse).forEach(([key, value]) => {
          if (!aggs[key]) {
            aggs[key] = value;
            return;
          }

          aggs[key].doc_count += value.doc_count;
        });

        finalResponse.aggregations = aggs;

        return finalResponse;
      },
      null,
    );

    this.funnelReportAggregation =
      computeFunnelReportAggregationFromResponse(response);

    const steps = this.funnel.funnel.steps.map((step) => step.event_id);
    const events = this.registryEntriesEvent
      .filter((entry) => steps.includes(entry.id as UUID))
      .sort((a, b) => steps.indexOf(a.id as UUID) - steps.indexOf(b.id as UUID))
      .map((entry) => entry.slug);

    const completeFunnelSteps = this.funnelReportAggregation.map(
      (step, idx) => {
        const total =
          idx === 0
            ? step.total
            : this.funnelReportAggregation[idx - 1].currentValue;
        const converted = (step.currentValue / total) * 100;
        const dropped = ((total - step.currentValue) / total) * 100;

        return {
          idx: idx,
          title: events[idx],
          converted: converted,
          dropped: dropped,
        };
      },
    );

    if (!this.featureFlaggingService.isFunnelSummaryAvailable()) {
      this.loading = false;
      return;
    }

    this.summaryLoading = true;
    this.sequenceDao
      .getSummary(this.org.id, completeFunnelSteps)
      .then((summary) => {
        this.summary = summary;
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.summaryLoading = false;
      });

    this.loading = false;
  }

  async onDisplaySurveyClick(url: string) {
    this.settingsService.setAdminSettingsKey(
      "funnel_create_survey_from",
      "report",
    );
    this.router.navigateByUrl(url);
  }

  private setRanges() {
    const now = new Date();

    const capMinDate = (date: Date): Date =>
      date.getTime() > this.org.created_at.getTime()
        ? date
        : this.org.created_at;
    const capMinDates = (dates: Date[]): Date[] => dates.map(capMinDate);

    const ranges = {
      "Last week": [subDays(now, 6), now].map(capMinDate), // we don't substract 7 days, because we return to users the result from 00:00 to 23:59 (= 6 days, 23 hours and 59 minutes)
      "Last month": [subMonths(now, 1), now].map(capMinDate),
      "This year so far": [startOfYear(now), now].map(capMinDate),
      "Previous year": [startOfYear(subMonths(now, 12)), startOfYear(now)].map(
        capMinDate,
      ),
      "All time": [this.org.created_at, now].map(capMinDate),
    };

    this.ranges = filterObject(
      mapObject(ranges, capMinDates),
      ([_, date]) => date.getTime() > this.org.created_at.getTime(),
    );
  }

  public isInvalidDate = (date: Date): boolean => {
    const now = new Date();
    return date < this.org.created_at || date > now;
  };

  public rangeClicked($event: DateRange) {
    const [eventStartDate, eventEndDate] = $event.dates;

    this.skipDatesUpdated = true;
    this.setDates(eventStartDate, eventEndDate);
  }

  public setDates(eventStartDate: dayjs.Dayjs, eventEndDate: dayjs.Dayjs) {
    if (!eventStartDate || !eventEndDate) {
      return;
    }

    this.selected = {
      startDate: eventStartDate,
      endDate: eventEndDate,
    };

    this.fetchData();
  }

  public datesUpdated($event: TimePeriod) {
    if (!this.skipDatesUpdated) {
      this.setDates($event.startDate, $event.endDate);
    }
    this.skipDatesUpdated = false;
  }
}
