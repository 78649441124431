<div
  class="scenario-history"
  nz-dropdown
  [nzDropdownMenu]="menu"
  (nzVisibleChange)="onVisibleChange($event)"
>
  <screeb-icon size="sm" icon="history"></screeb-icon>
  <div>
    <div class="scenario-history__header">{{ prefix }} {{ relativeDate }}</div>
    <div class="scenario-history__author" *ngIf="updatedBy">
      by
      <org-account-avatar
        [account]="updatedBy"
        size="xss"
        [withFullname]="true"
        [noTooltip]="true"
        [noActivityStatus]="true"
      ></org-account-avatar>
    </div>
  </div>
  <screeb-icon class="action" size="sm" icon="down"></screeb-icon>
</div>
<nz-dropdown-menu #menu="nzDropdownMenu">
  <div class="loader" nz-menu *ngIf="!fetched">
    <nz-spin nzSimple></nz-spin>
  </div>
  <ul class="scenario-list" nz-menu *ngIf="fetched">
    <li
      *ngFor="let scenario of scenarios; let i = index"
      nz-menu-item
      (click)="onScenarioClick(scenario)"
    >
      <div class="scenario-history__header">
        <small>
          Version {{ scenario.version }}
          <div class="current" *ngIf="scenario.id === survey?.scenario?.id">
            Current
          </div>
          <div
            class="current latest"
            *ngIf="scenario.id !== survey?.scenario?.id && i === 0"
          >
            Latest
          </div>
        </small>
        {{ scenario.datePrefix }} {{ scenario.relativeDate }}
      </div>
      <div class="scenario-history__author">
        by
        <org-account-avatar
          [account]="scenario.author"
          size="xss"
          [withFullname]="true"
          [noTooltip]="true"
          [noActivityStatus]="true"
        ></org-account-avatar>
      </div>
    </li>
  </ul>
</nz-dropdown-menu>
