<section class="content" nz-row [nzGutter]="[24, 24]">
  <billing-plan
    nz-col
    nzSpan="8"
    nzXXl="8"
    nzXl="8"
    nzMd="12"
    nzXs="24"
    [superOrg]="superOrg"
    [orgBilling]="orgBilling"
    [orgBillingSubscription]="orgBillingSubscription"
    [orgBillingPaymentMethods]="orgBillingPaymentMethods"
    [orgBillingInvoices]="orgBillingInvoices"
    planType="scale"
    (onUpgrade)="onUpgrade.emit($event)"
    (onDowngrade)="onDowngrade.emit()"
    (onCancel)="onCancel.emit()"
  />

  <billing-plan
    nz-col
    nzSpan="8"
    nzXXl="8"
    nzXl="8"
    nzMd="12"
    nzXs="24"
    [superOrg]="superOrg"
    [orgBilling]="orgBilling"
    [orgBillingSubscription]="orgBillingSubscription"
    [orgBillingPaymentMethods]="orgBillingPaymentMethods"
    [orgBillingInvoices]="orgBillingInvoices"
    planType="advanced"
    (onUpgrade)="onUpgrade.emit($event)"
    (onDowngrade)="onDowngrade.emit()"
    (onCancel)="onCancel.emit()"
  />

  <billing-plan
    nz-col
    nzSpan="8"
    nzXXl="8"
    nzXl="8"
    nzMd="12"
    nzXs="24"
    [superOrg]="superOrg"
    [orgBilling]="orgBilling"
    [orgBillingSubscription]="orgBillingSubscription"
    [orgBillingPaymentMethods]="orgBillingPaymentMethods"
    [orgBillingInvoices]="orgBillingInvoices"
    planType="custom"
    (onUpgrade)="onUpgrade.emit($event)"
    (onDowngrade)="onDowngrade.emit()"
    (onCancel)="onCancel.emit()"
  />
</section>
