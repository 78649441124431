import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { ChartData } from "chart.js";

const polarToCartesian = (
  centerX: number,
  centerY: number,
  radius: number,
  angle: number,
) => ({
  x: centerX + radius * Math.cos(angle),
  y: centerY + radius * Math.sin(angle),
});

const describeArc = (
  x: number,
  y: number,
  radius: number,
  startAngle: number,
  endAngle: number,
) => {
  const start = polarToCartesian(x, y, radius, endAngle);
  const end = polarToCartesian(x, y, radius, startAngle);
  const largeArcFlag = endAngle - startAngle <= Math.PI ? "0" : "1";

  return [
    "M",
    start.x,
    start.y,
    "A",
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
  ].join(" ");
};

@Component({
  selector: "donut-indicator",
  template: `
    <div class="donut-indicator">
      <nz-spin size="medium" *ngIf="loading"></nz-spin>
      <svg
        class="donut-chart"
        [style]="{ visibility: loading ? 'hidden' : 'visible' }"
        xmlns="http://www.w3.org/2000/svg"
        [attr.viewBox]="'0 0 374 374'"
      >
        <ng-container *ngFor="let arc of arcs; let index = index">
          <path
            [attr.d]="arc.sPath"
            [attr.stroke-width]="width"
            [attr.stroke]="arc.sColor"
            fill="transparent"
          />
          <image
            *ngIf="icons?.[index]"
            [attr.href]="icons[index]"
            [attr.x]="arc.icon.x"
            [attr.y]="arc.icon.y"
            height="24"
            width="24"
          />
        </ng-container>
      </svg>
      <div class="donut-legend" *ngIf="!loading">
        <div class="donut-legend-title" *ngIf="title.length">
          {{ title }}
        </div>
        <div
          *ngFor="let label of this.data.labels; let index = index"
          class="donut-legend-item"
        >
          <div class="donut-legend-item-text">
            <div
              class="donut-legend-dot"
              [style]="{
                backgroundColor: this.data.datasets?.[0].backgroundColor[index],
              }"
            ></div>
            {{
              displayCount
                ? (this.data.datasets?.[0].data[index] | format: "number")
                : label
            }}
          </div>
          <span class="value">
            {{ getPercentage(index) | format: "percent" }}
          </span>
        </div>
      </div>
    </div>
    <div class="donut-bottom-legend" *ngIf="displayCount && !loading">
      <div
        *ngFor="let label of this.data.labels; let index = index"
        class="donut-legend-item"
      >
        <div
          class="donut-legend-dot"
          [style]="{
            backgroundColor: this.data.datasets?.[0].backgroundColor[index],
          }"
        ></div>
        {{ label }}
      </div>
    </div>
  `,
  styles: [
    `
      :host .donut-indicator {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        height: 100%;
        flex-wrap: wrap;
      }

      :host nz-spin {
        margin-top: 16px;
      }

      :host .donut-chart {
        height: 100%;
        aspect-ratio: 1;
        animation: donutAppear 200ms;
        flex: 1;
        min-width: 100px;
      }

      :host .donut-legend {
        flex: 1;
        min-width: 100px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        animation: donutAppear 200ms;
        animation-delay: 50ms;
      }

      :host .donut-legend-title {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: var(--screeb-color-body-text);
        margin-bottom: 4px;
      }

      :host .value {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 40px;
        color: var(--screeb-color-body-text);
        word-break: break-word;
      }

      :host .donut-legend-item {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: var(--screeb-color-body-text-secondary);
        margin-top: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      :host .donut-legend-item-text {
        display: flex;
        align-items: center;
      }

      :host .donut-legend-dot {
        width: 8px;
        height: 8px;
        border-radius: 8px;
        margin-right: 8px;
      }

      :host .donut-bottom-legend {
        display: flex;
        align-items: center;
        justify-content: center;
        animation: donutAppear 200ms;
        animation-delay: 50ms;
      }

      :host .donut-bottom-legend .donut-legend-item {
        display: inline-flex;
      }

      :host .donut-bottom-legend .donut-legend-item + .donut-legend-item {
        margin-left: 8px;
      }

      @keyframes donutAppear {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
    `,
  ],
})
export class DonutIndicatorComponent implements OnInit, OnChanges {
  public pieLineWidth = 14;
  public pieWidth = 374;
  public pieHeight = 374;
  public padding = 10;
  public width = 56;

  public arcs = [];

  @Input() public title = "";
  @Input() public displayCount = false;
  @Input() public loading = false;
  @Input() public icons: string[] = [];
  @Input() public data: ChartData<"doughnut"> = {
    labels: [],
    datasets: [],
  };

  ngOnInit() {
    this.arcs = this.getArcs();
  }

  ngOnChanges() {
    this.arcs = this.getArcs();
  }

  getPercentage(index: number) {
    const max = this.data.datasets?.[0].data.reduce((a, b) => a + b, 0);
    if (!max) {
      return 0;
    }
    return (this.data.datasets?.[0].data[index] / max) * 100;
  }

  getAngle(sum: number, value: number) {
    const precision = 0.000001;
    const angle = (value * Math.PI * 2) / sum;

    return (
      Math.min(Math.max(angle, precision), 2 * Math.PI - precision) -
      Math.PI / 2
    );
  }

  getColor(index) {
    return this.data.datasets?.[0].backgroundColor[index];
  }

  getArcs() {
    const data = this.data.datasets?.[0]?.data ?? [];
    const sum = data.reduce((sum, value) => sum + value, 0);

    if (!data) {
      return [];
    }

    return data
      .reduce(
        (memo, value) => [...memo, value + (memo[memo.length - 1] ?? 0)],
        [],
      )
      .map((_, index, data) => data.slice(Math.max(0, index - 1), index + 1))
      .map(([start, end]) => ({
        start: this.getAngle(sum, end === undefined ? 0 : start),
        end: this.getAngle(sum, end === undefined ? start : end),
      }))
      .map((arc, i) => ({
        ...arc,
        icon: this.getIconPosition(arc),
        sPath: this.getPath(arc.start, arc.end),
        sColor: this.getColor(i),
      }));
  }

  getIconPosition({ start, end }: { start: number; end: number }) {
    const angle = start + (end - start) / 2;
    const iconSize = 24;

    return {
      x:
        this.pieWidth / 2 +
        Math.cos(angle) * (this.pieWidth / 2 - this.width + this.padding / 2) -
        iconSize / 2,
      y:
        this.pieHeight / 2 +
        Math.sin(angle) * (this.pieHeight / 2 - this.width + this.padding / 2) -
        iconSize / 2,
    };
  }

  getPath(startAngle: number, endAngle: number) {
    return describeArc(
      this.pieWidth / 2,
      this.pieHeight / 2,
      Math.min(this.pieWidth / 2, this.pieHeight / 2) -
        (this.padding * 2 + this.width / 2),
      startAngle,
      endAngle,
    );
  }
}
