import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { PageComponentInterface } from "components/PageComponentInterface";
import { addSeconds } from "date-fns";
import { NotificationHelper } from "helpers/notification.helper";
import { Org } from "models/org.model";
import { RegistryDao } from "models/registry.dao";
import { RegistryEntry } from "models/registry.model";
import { SequenceDao } from "models/sequence.dao";
import { SequenceFunnel } from "models/sequence.types";
import { SurveyDistributionDao } from "models/survey-distribution.dao";
import { TargetingRule, TargetingRuleValue } from "models/targeting-rule.model";
import { SurveyDao } from "models/survey.dao";
import { UUID } from "models/survey.dao.types";
import {
  DefaultMessageName,
  DefaultSurveyName,
  Survey,
  TemplateCategory,
} from "models/survey.model";
import { UserDao } from "models/user.dao";
import { combineLatest } from "rxjs";
import { SessionService } from "services/auth.service";
import { ConfigService } from "services/config.service";
import { EntitlementService } from "services/entitlement.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { RoutingService } from "services/routing.service";
import {
  SettingsService,
  SurveyTemplate,
  SurveyTemplateGroup,
  TemplateLanguages,
} from "services/settings.service";
import { TrackersService } from "services/trackers.service";
import { UIService } from "services/ui.service";

import { deepCopy } from "utils/object";

@Component({
  selector: "page-survey-create",
  templateUrl: "./create.component.html",
  styleUrls: ["./create.component.scss"],
})
export class CreateSurveyPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Create new survey";
  public name = DefaultSurveyName;

  private obs: any = null;
  public org: Org = null;
  public surveys: Survey[] = [];
  public pathPrefix = "survey";
  public registryEntriesEvent: RegistryEntry[] = [];

  public creating1: boolean = false;
  public creating2: boolean = false;

  public templateLanguage: string = "en";
  public popularSurveys: SurveyTemplate[] = [];
  public filteredTemplates: SurveyTemplate[] = [];

  public category: "all" | TemplateCategory = "all";

  public keywords: string = "";
  public searching: boolean = false;

  public loading = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private routingService: RoutingService,
    public uiService: UIService,
    private trackersService: TrackersService,
    private notificationHelper: NotificationHelper,
    public settingsService: SettingsService,
    public sessionService: SessionService,
    public surveyDao: SurveyDao,
    public userDao: UserDao,
    public registryDao: RegistryDao,
    public entitlementService: EntitlementService,
    public surveyDistributionDao: SurveyDistributionDao,
    public sequenceDao: SequenceDao,
    public featureFlaggingService: FeatureFlaggingService,
    public configService: ConfigService,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.obs = combineLatest(this.route.data, this.route.queryParams).subscribe(
      async (d) => {
        const data = d[0];
        const qs = d[1];

        this.org = data["org"];
        this.surveys = data["surveys"];
        this.registryEntriesEvent = data["registryEntriesEvent"].filter(
          (entry: RegistryEntry) => entry.type !== "object",
        );

        if (this.uiService.isMessagePage) {
          this.title = "Create a new message";
          this.name = "New Message";
          this.pathPrefix = "message";
        }

        this.category = qs["category"] ?? "all";

        this.popularSurveys = (
          (this.uiService.isMessagePage
            ? this.settingsService.messageTemplateGroups
            : this.settingsService.surveyTemplateGroups) ?? []
        )
          .map((g: SurveyTemplateGroup) => g.surveys)
          .flat()
          .filter((t: SurveyTemplate) => t.is_popular);

        // const currentBrowserLang = this.translateService.getBrowserLang();
        // const savedLang = this.settingsService.getDefaultLanguage();
        // if (savedLang !== null) {
        //   this.templateLanguage = savedLang;
        // } else if (availableTemplateLanguages.includes(currentBrowserLang)) {
        //   this.templateLanguage = currentBrowserLang;
        // }

        if (qs.duplicateById) {
          this.duplicateById(
            DefaultSurveyName + ": Measure NPS",
            qs.duplicateById,
          );
        } else if (
          qs.respondentIds ||
          qs.segmentId ||
          qs.userGroupType ||
          qs.sequenceId ||
          qs.fromScenario
        ) {
          const userIds = qs.respondentIds
            ? [].concat(qs.respondentIds) // array or string flattening
            : qs.respondentIds;

          try {
            await this.onCreate(
              userIds,
              qs.segmentId,
              qs.sequenceId,
              Number(qs.sequenceStepIndex ?? 0),
              qs.sequenceStepType,
              qs.fromScenario,
            );
          } catch (error) {
            this.loading = false;
            this.notificationHelper.trigger(
              "Something went wrong!",
              `Please create a ${
                this.uiService.isMessagePage ? "message" : "survey"
              } manually.`,
              "error",
            );
          }
        }
      },
    );
  }

  ngOnDestroy() {
    if (this.obs) this.obs.unsubscribe();
  }

  public onLanguageChange(lang: string) {
    this.templateLanguage = lang;
    this.settingsService.setDefaultLanguage(lang as TemplateLanguages);
  }

  public async onCreate(
    userIds?: string[],
    userGroupId?: string,
    sequenceId?: UUID,
    sequenceStepIndex: number = 0,
    sequenceStepType: "success" | "drop" = "success",
    openAIPrompt: boolean = false,
  ) {
    if (userIds || userGroupId || sequenceId) {
      this.loading = true;
    }

    this.creating1 = true;

    let surveyName = this.uiService.isMessagePage
      ? DefaultMessageName
      : DefaultSurveyName;

    if (userIds) {
      surveyName = `Ask a question to user(s)`;
    } else if (userGroupId) {
      surveyName = `Ask a question to segment`;
    }

    let sequence: SequenceFunnel;

    if (sequenceId) {
      sequence = await this.sequenceDao.getById(this.org.id, sequenceId);

      const sequenceEventName = this.registryEntriesEvent.find(
        (registryEntry) =>
          registryEntry.id ===
          sequence.funnel.steps[sequenceStepIndex].event_id,
      )?.slug;

      surveyName = `${sequence.title} - ${sequenceEventName} - ${sequenceStepType}`;
    }

    const scenarioInLocalStorage = localStorage.getItem(
      "screeb-restore-scenario",
    );

    if (scenarioInLocalStorage) {
      try {
        const scenario = JSON.parse(scenarioInLocalStorage);
        surveyName = scenario.title;
      } catch (_) {
        //balek
      }
    }

    this.surveyDao
      .create(
        this.org.id,
        this.uiService.surveyType,
        surveyName,
        this.org.survey_languages[0] ?? null,
        sequenceId,
      )
      .then(async (survey: Survey) => {
        // async
        await Promise.all([
          this.uiService.fetchEverything(),
          sequence &&
            this.patchSequenceWithSurveyId(
              sequence,
              sequenceStepIndex,
              sequenceStepType,
              UUID(survey.id),
            ),
        ]);

        return survey;
      })
      .then(async (survey: Survey) => {
        if (userIds || userGroupId || sequenceId) {
          await this.initSurvey(
            survey,
            userIds,
            userGroupId,
            sequence,
            sequenceStepIndex,
            sequenceStepType,
          );
        }

        return survey;
      })
      .then((survey: Survey) => {
        if (openAIPrompt) {
          this.router.navigate(
            [`/org/${survey.org_id}/${this.pathPrefix}/${survey.id}/edit`],
            { queryParams: { ai: true } },
          );
        } else {
          this.router.navigate([
            `/org/${survey.org_id}/${this.pathPrefix}/${survey.id}/edit`,
          ]);
        }
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationHelper.trigger(
          err?.error?.message ?? "Could not create survey",
          null,
          "error",
        );
        console.error(err.error);
      })
      .then(() => {
        this.creating1 = false;
      });
  }

  async patchSequenceWithSurveyId(
    sequence: SequenceFunnel,
    sequenceStepIndex: number,
    sequenceStepType: "drop" | "success",
    surveyId: UUID,
  ) {
    const newSequence = deepCopy(sequence);

    switch (sequenceStepType) {
      case "drop":
        newSequence.funnel.steps[sequenceStepIndex].survey_on_drop = surveyId;
        break;
      case "success":
        newSequence.funnel.steps[sequenceStepIndex].survey_on_success =
          surveyId;
        break;
    }

    this.trackersService
      .newEventTrackingBuilder("Funnel step display survey clicked")
      .withOrg(this.org)
      .withSequence(newSequence)
      .withProps({ sequence_step_type: sequenceStepType })
      .build();

    return this.sequenceDao.patch(this.org.id, sequence.id, newSequence);
  }

  async initSurvey(
    survey: Survey,
    userId?: string[],
    userGroupId?: string,
    sequence?: SequenceFunnel,
    sequenceStepIndex?: number,
    sequenceStepType: "drop" | "success" = "success",
  ) {
    const surveyDistributions = (
      await this.surveyDistributionDao.getAllBySurveyId(this.org.id, survey.id)
    ).filter(
      (d) =>
        d.interaction !== "link" &&
        d.interaction !== "email" &&
        d.type !== "hosted-page",
    );

    await surveyDistributions.map(async (surveyDistribution) =>
      this.surveyDistributionDao.updateTargetingRules(
        this.org.id,
        survey.id,
        surveyDistribution.id,
        [
          new TargetingRule(
            surveyDistribution.id,
            this.org.id,
            "multiple",
            null,
            new TargetingRuleValue().fromJson({
              v_b: false,
            }),
            new Date(),
            new Date(),
          ),
          new TargetingRule(
            surveyDistribution.id,
            this.org.id,
            "device",
            "equal",
            new TargetingRuleValue().fromJson({
              v_s_arr: ["desktop", "mobile", "tablet"],
            }),
            new Date(),
            new Date(),
          ),
          userId &&
            new TargetingRule(
              surveyDistribution.id,
              this.org.id,
              "visitor_alias",
              "equal",
              new TargetingRuleValue().fromJson({
                v_s_arr: userId,
              }),
              new Date(),
              new Date(),
            ),
          userGroupId &&
            new TargetingRule(
              surveyDistribution.id,
              this.org.id,
              "visitor_group",
              "equal",
              new TargetingRuleValue().fromJson({
                v_s_arr: [userGroupId],
              }),
              new Date(),
              new Date(),
            ),
          ...(sequence
            ? this.getTargetingRuleForSequence(
                sequence,
                sequenceStepIndex,
                sequenceStepType,
                UUID(surveyDistribution.id),
              )
            : []),
        ].filter(Boolean),
        null,
      ),
    );

    return survey;
  }

  getTargetingRuleForSequence(
    sequence: SequenceFunnel,
    sequenceStepIndex: number,
    sequenceStepType: "drop" | "success",
    surveyDistributionId: UUID,
  ) {
    const eventsIdsSequence = sequence.funnel.steps
      .slice(0, sequenceStepIndex + 1)
      .map(({ event_id }) => event_id);
    const eventsIdsSequenceDrop = sequence.funnel.steps
      .slice(0, sequenceStepIndex + 2)
      .map(({ event_id }) => event_id);

    return [
      new TargetingRule(
        surveyDistributionId,
        this.org.id,
        "visitor_event_sequence",
        "equal",
        new TargetingRuleValue().fromJson({
          v_s_arr: eventsIdsSequence,
        }),
        addSeconds(new Date(), 1),
        addSeconds(new Date(), 1),
        sequence.id,
      ),
      sequenceStepType === "drop" &&
        new TargetingRule(
          surveyDistributionId,
          this.org.id,
          "visitor_event_sequence",
          "not equal",
          new TargetingRuleValue().fromJson({
            v_s_arr: eventsIdsSequenceDrop,
          }),
          addSeconds(new Date(), 1),
          addSeconds(new Date(), 1),
          sequence.id,
        ),
      ...eventsIdsSequence
        .map((eventId, index, eventsIdsSequence) => {
          if (index >= eventsIdsSequence.length - 1) {
            return;
          }

          return new TargetingRule(
            surveyDistributionId,
            this.org.id,
            "visitor_event_time",
            "date_relative_after",
            new TargetingRuleValue().fromJson({
              name_ids: [eventId],
              v_s: "latest",
              v_n_p:
                sequence.funnel.steps
                  .slice(index, sequenceStepIndex)
                  .reduce((sum, step) => {
                    if (step.drop_condition.type === "delay") {
                      return sum + step.drop_condition.delay;
                    }

                    return sum;
                  }, 0) +
                sequence.funnel.steps[sequenceStepIndex].drop_condition.delay,
            }),
            addSeconds(new Date(), 1 + index),
            addSeconds(new Date(), 1 + index),
            sequence.id,
          );
        })
        .filter(Boolean),
      sequenceStepType === "success" &&
        new TargetingRule(
          surveyDistributionId,
          this.org.id,
          "visitor_event_time",
          "date_relative_after",
          new TargetingRuleValue().fromJson({
            name_ids: [sequence.funnel.steps[sequenceStepIndex].event_id],
            v_s: "latest",
            v_n_p:
              sequence.funnel.steps[sequenceStepIndex].drop_condition.delay,
          }),
          addSeconds(new Date(), eventsIdsSequence.length + 1),
          addSeconds(new Date(), eventsIdsSequence.length + 1),
          sequence.id,
        ),
      sequenceStepType === "drop" &&
        new TargetingRule(
          surveyDistributionId,
          this.org.id,
          "visitor_event_time",
          "date_relative_before",
          new TargetingRuleValue().fromJson({
            name_ids: [sequence.funnel.steps[sequenceStepIndex].event_id],
            v_s: "latest",
            v_n_p:
              sequence.funnel.steps[sequenceStepIndex].drop_condition.delay,
          }),
          addSeconds(new Date(), eventsIdsSequence.length + 1),
          addSeconds(new Date(), eventsIdsSequence.length + 1),
          sequence.id,
        ),
    ].filter(Boolean);
  }

  duplicateById(name: string, surveyId: string) {
    this.creating2 = true;

    return this.surveyDao
      .duplicate(
        this.org.id,
        this.org.id,
        this.uiService.surveyType,
        name,
        surveyId,
      )
      .then((survey: Survey) => {
        this.router.navigate([
          `/org/${survey.org_id}/${this.pathPrefix}/${survey.id}/edit`,
        ]);
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
      })
      .then(() => {
        this.creating2 = false;
      });
  }

  public onDuplicate(tpl: SurveyTemplate) {
    this.duplicateById(
      (this.uiService.isMessagePage ? DefaultMessageName : DefaultSurveyName) +
        ": " +
        tpl.name,
      tpl.survey_id,
    );
  }

  /**
   * Search
   */
  public onSearchChange() {
    const parts = this.keywords
      .trim()
      .split(" ")
      .filter((part) => part.trim() !== "");
    this.searching = parts.length > 0;
    if (this.searching) this.filteredTemplates = this.filterTemplates();
    else this.filteredTemplates = [];
  }

  private filterTemplates(): SurveyTemplate[] {
    // search each word of keyword in each template (name + description)
    const parts = this.keywords
      .toLowerCase()
      .trim()
      .split(" ")
      .filter((part) => part.trim() !== "");
    const templates = [].concat(
      ...(this.uiService.isMessagePage
        ? this.settingsService.messageTemplateGroups
        : this.settingsService.surveyTemplateGroups
      ).map((group: SurveyTemplateGroup) => group.surveys),
    );
    return templates
      .filter((tpl: SurveyTemplate) => tpl.language === this.templateLanguage)
      .filter((tpl: SurveyTemplate) => {
        const index = `${tpl.name} ${tpl.description}`.toLowerCase();
        return !parts.find((part) => !index.includes(part));
      });
  }

  /**
   * Preview
   */
  public onPreview(
    $event,
    surveyTemplate: SurveyTemplate,
    category: SurveyTemplateGroup | null,
  ) {
    $event.stopPropagation();
    this.trackersService
      .newEventTrackingBuilder("Template Previewed")
      .withOrg(this.org)
      .withProps({
        template_id: surveyTemplate.survey_id,
        template_category_slug: category?.slug, // not available for "popular" templates
        template_category_name: category?.title?.[this.templateLanguage], // not available for "popular" templates
        template_slug: surveyTemplate?.slug,
        template_name: surveyTemplate.name,
        template_type: this.uiService.isMessagePage ? "message" : "survey",
        template_language: surveyTemplate.language,
        template_is_popular: surveyTemplate.is_popular,
      })
      .build();
  }
}
