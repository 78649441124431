export const usersKPIs = [
  {
    key: "users-count",
    label: "Total Number of Users",
    hasRepeat: true,
    hasFilters: false,
    supportedFilterTypes: [],
  },
  {
    key: "users-daily-·active",
    label: "Daily Active Users",
    hasRepeat: true,
    hasFilters: false,
    supportedFilterTypes: [],
  },
  {
    key: "users-monthly-active",
    label: "Monthly Active Users",
    hasRepeat: true,
    hasFilters: false,
    supportedFilterTypes: [],
  },
] as const;

export const surveyAnalyticsKPIs = [
  {
    key: "survey-analytics-display-count",
    label: "Total Survey Displays",
    hasRepeat: true,
    hasFilters: false,
    supportedFilterTypes: [],
  },
  {
    key: "survey-analytics-display-count-desktop",
    label: "Total Survey Displays on Desktop",
    hasRepeat: true,
    hasFilters: false,
    supportedFilterTypes: [],
  },
  {
    key: "survey-analytics-display-count-mobile",
    label: "Total Survey Displays on Mobile",
    hasRepeat: true,
    hasFilters: false,
    supportedFilterTypes: [],
  },
  {
    key: "survey-analytics-response-count",
    label: "Total Survey Responses",
    hasRepeat: true,
    hasFilters: false,
    supportedFilterTypes: [],
  },
  {
    key: "survey-analytics-response-count-desktop",
    label: "Total Survey Responses on Desktop",
    hasRepeat: true,
    hasFilters: false,
    supportedFilterTypes: [],
  },
  {
    key: "survey-analytics-response-count-mobile",
    label: "Total Survey Responses on Mobile",
    hasRepeat: true,
    hasFilters: false,
    supportedFilterTypes: [],
  },
  {
    key: "survey-analytics-response-rate",
    label: "Response Rate",
    hasRepeat: true,
    hasFilters: false,
    supportedFilterTypes: [],
  },
  {
    key: "survey-analytics-response-rate-desktop",
    hasRepeat: true,
    label: "Response Rate on Desktop",
    hasFilters: false,
    supportedFilterTypes: [],
  },
  {
    key: "survey-analytics-response-rate-mobile",
    label: "Response Rate on Mobile",
    hasRepeat: true,
    hasFilters: false,
    supportedFilterTypes: [],
  },
  {
    key: "survey-analytics-completion-rate",
    label: "Completion Rate",
    hasRepeat: true,
    hasFilters: false,
    supportedFilterTypes: [],
  },
  {
    key: "survey-analytics-completion-rate-desktop",
    label: "Completion Rate on Desktop",
    hasRepeat: true,
    hasFilters: false,
    supportedFilterTypes: [],
  },
  {
    key: "survey-analytics-completion-rate-mobile",
    label: "Completion Rate on Mobile",
    hasRepeat: true,
    hasFilters: false,
    supportedFilterTypes: [],
  },
] as const;

export const allResponsesKPIs = [
  {
    key: "all-responses-raw-content",
    label: "All Responses",
    hasRepeat: false,
    hasFilters: false,
    supportedFilterTypes: [],
  },
  {
    key: "filtered-responses",
    label: "Filtered responses",
    hasRepeat: false,
    hasFilters: true,
    supportedFilterTypes: [
      "response.keyword",
      "response.tag",
      "response.interaction",
      "response.media_type",
      "response.answer",
      "response.emotion",
      "response.hidden_field",
    ],
  },
] as const;

export const contentAnalysisKPIs = [
  {
    key: "content-analysis",
    label: "Responses analysis",
    hasRepeat: true,
    hasFilters: false,
    supportedFilterTypes: [],
  },
  {
    key: "content-analysis-longest-feedback",
    label: "Longest feedbacks",
    hasRepeat: true,
    hasFilters: false,
    supportedFilterTypes: [],
  },
] as const;

export const npsKPIs = [
  {
    key: "nps-score",
    label: "[NPS] Score",
    hasRepeat: true,
    hasFilters: false,
    supportedFilterTypes: [],
  },
  {
    key: "nps-results-repartition",
    label: "[NPS] Responses repartition",
    hasRepeat: true,
    hasFilters: false,
    supportedFilterTypes: [],
  },
  {
    key: "nps-promoters-count",
    label: "[NPS] Total Number of Promoters",
    hasRepeat: true,
    hasFilters: false,
    supportedFilterTypes: [],
  },
  {
    key: "nps-passives-count",
    label: "[NPS] Total Number of Passives",
    hasRepeat: true,
    hasFilters: false,
    supportedFilterTypes: [],
  },
  {
    key: "nps-detractors-count",
    label: "[NPS] Total Number of Detractors",
    hasRepeat: true,
    hasFilters: false,
    supportedFilterTypes: [],
  },
] as const;

export const csatKPIs = [
  {
    key: "csat-score",
    label: "[CSAT] Score",
    hasRepeat: true,
    hasFilters: false,
    supportedFilterTypes: [],
  },
  {
    key: "csat-results-repartition",
    label: "[CSAT] Responses repartition",
    hasRepeat: true,
    hasFilters: false,
    supportedFilterTypes: [],
  },
  {
    key: "csat-response-count",
    label: "[CSAT] Total CSAT Responses",
    hasRepeat: true,
    hasFilters: false,
    supportedFilterTypes: [],
  },
  {
    key: "csat-low-scores-percent",
    label: "[CSAT] Percentage of Unsatisfied Customers",
    hasRepeat: true,
    hasFilters: false,
    supportedFilterTypes: [],
  },
] as const;

export const cesKPIs = [
  {
    key: "ces-score",
    label: "[CES] Score",
    hasRepeat: true,
    hasFilters: false,
    supportedFilterTypes: [],
  },
  {
    key: "ces-results-repartition",
    label: "[CES] Responses repartition",
    hasRepeat: true,
    hasFilters: false,
    supportedFilterTypes: [],
  },
  {
    key: "ces-response-count",
    label: "[CES] Total CES Response",
    hasRepeat: true,
    hasFilters: false,
    supportedFilterTypes: [],
  },
  {
    key: "ces-small-effort-percent",
    label: "[CES] Percentage of Small Effort Responses",
    hasRepeat: true,
    hasFilters: false,
    supportedFilterTypes: [],
  },
  {
    key: "ces-medium-effort-percent",
    label: "[CES] Percentage of Medium Effort Responses",
    hasRepeat: true,
    hasFilters: false,
    supportedFilterTypes: [],
  },
  {
    key: "ces-high-effort-percent",
    label: "[CES] Percentage of High Effort Responses",
    hasRepeat: true,
    hasFilters: false,
    supportedFilterTypes: [],
  },
] as const;

export const allKPIs = [
  ...usersKPIs,
  ...surveyAnalyticsKPIs,
  ...allResponsesKPIs,
  ...contentAnalysisKPIs,
  ...npsKPIs,
  ...csatKPIs,
  ...cesKPIs,
] as const;

export type KPIKey = (typeof allKPIs)[number]["key"];
export type KPI = (typeof allKPIs)[number];
