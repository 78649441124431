<nz-modal
  [(nzVisible)]="isOpen"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  [nzClosable]="false"
  (nzOnCancel)="handleCancel()"
  nzWidth="957px"
  nzClassName="user-modal"
>
  <ng-template #modalTitle>
    <h3>{{ user !== null ? "Edit a Teammate" : "Add a new Teammate" }}</h3>
  </ng-template>

  <ng-template #modalContent>
    <div class="tabs" *ngIf="user === null">
      <div class="tab">Individual Invitation</div>
      <div class="tab">
        Bulk Invitations
        <utils-badge text="Soon"></utils-badge>
      </div>
    </div>
    <form nz-form nz-form [formGroup]="validateForm" (ngSubmit)="onSubmit()">
      <div class="is-flex">
        <nz-form-item>
          <nz-form-label>Email</nz-form-label>
          <nz-form-control
            class="form-input"
            nzErrorTip="Please fill this field"
            required
          >
            <input
              *ngIf="user !== null"
              type="text"
              nz-input
              [value]="user.email"
              placeholder="frida@screeb.app"
              disabled
            />
            <input
              *ngIf="user === null"
              type="text"
              nz-input
              nzHasFeedback
              formControlName="email"
              placeholder="frida@screeb.app"
              required
            />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label>Organization role</nz-form-label>
          <nz-form-control class="role-select">
            <nz-select
              formControlName="role"
              nzPlaceHolder="Assign a role for organization"
              [nzOptions]="orgsRoles"
              nzSuffixIcon="caret-down"
              [nzDropdownMatchSelectWidth]="false"
              (ngModelChange)="onOrgRoleChange($event)"
              [nzDisabled]="
                user !== null && sessionService.session.id === user.id
              "
            ></nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <nz-form-item>
        <div class="item-label-wrap">
          <nz-form-label>Tags</nz-form-label>
          <span>Optional</span>
        </div>
        <nz-form-control class="form-input">
          <nz-select
            nzMode="tags"
            nzPlaceHolder="Choose or add tags"
            name="tags"
            [nzMaxMultipleCount]="100"
            [nzShowArrow]="true"
            nzSuffixIcon="caret-down"
            formControlName="tags"
          >
            <nz-option
              *ngFor="let tag of tags"
              [nzLabel]="tag"
              [nzValue]="tag"
            ></nz-option>
          </nz-select>
          <p class="tooltip">
            You can use tags to easily organize your workspaces.
          </p>
        </nz-form-control>
      </nz-form-item>
      <div class="user-workspaces">
        <div
          *ngFor="let entry of userWorkspacesFormArray.controls; let i = index"
        >
          <div [formGroup]="entry" class="is-flex">
            <nz-form-item>
              <nz-form-label>Workspaces</nz-form-label>
              <nz-form-control class="form-input">
                <nz-select
                  nzMode="tags"
                  nzPlaceHolder="Choose one or more workspaces"
                  name="workspaces"
                  [nzMaxMultipleCount]="100"
                  [nzShowArrow]="true"
                  nzSuffixIcon="caret-down"
                  (ngModelChange)="onWorkspaceChange($event)"
                  formControlName="workspace"
                >
                  <nz-option
                    *ngFor="let workspace of workspaces"
                    [nzLabel]="workspace.name"
                    [nzValue]="workspace.id"
                    [nzDisabled]="
                      usedWorkspaces.indexOf(workspace.id) !== -1 &&
                      (entry.get('workspace').value?.indexOf(workspace.id) ??
                        -1) === -1
                    "
                  ></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item class="role-select">
              <nz-form-label>Role</nz-form-label>
              <nz-form-control>
                <nz-select
                  formControlName="role"
                  nzPlaceHolder="Assign a role for those workspaces"
                  [nzOptions]="dynamicWorkspacesRoles"
                  nzSuffixIcon="caret-down"
                  [nzDropdownMatchSelectWidth]="false"
                  [nzDisabled]="validateForm.get('role').value === 'admin'"
                ></nz-select>
              </nz-form-control>
            </nz-form-item>
            <div class="flex-action">
              <button
                *ngIf="i === userWorkspacesFormArray.controls.length - 1"
                nz-button
                nzType="default"
                nzShape="circle"
                nzSize="small"
                (click)="$event.preventDefault(); addNewWorkspace()"
              >
                <screeb-icon size="sm" icon="plus"></screeb-icon>
              </button>
              <button
                *ngIf="i !== userWorkspacesFormArray.controls.length - 1"
                nz-button
                nzType="default"
                nzShape="circle"
                nzSize="small"
                (click)="removeWorkspace(i)"
              >
                <screeb-icon size="sm" icon="neutral"></screeb-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template #modalFooter>
    <button
      nz-button
      nzType="default"
      class="user-cancel-btn"
      (click)="handleCancel()"
    >
      Cancel
    </button>
    <button
      nz-button
      [class]="'user-add-btn'.concat(isFormValid() ? '' : ' disabled')"
      (click)="onSubmit()"
      [nzLoading]="loading"
      [disabled]="!isFormValid()"
    >
      {{ user !== null ? "Update" : "Invite to Organization" }}
    </button>
  </ng-template>
</nz-modal>
