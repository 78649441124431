import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";

import { ApplicationLoadService } from "services/app.service";
import { RegistryDao } from "models/registry.dao";
import { RegistryEntry } from "models/registry.model";

@Injectable()
export class RegistryIdentityPropertyResolver implements Resolve<any> {
  constructor(
    private applicationLoadService: ApplicationLoadService,
    private registryDao: RegistryDao,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Promise<RegistryEntry[]> {
    return this.applicationLoadService
      .loader()
      .then(() => {
        return this.registryDao.getIdentityProperties(
          route.params["org_id"],
          route.data.allLocations ?? false,
        );
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        throw err;
      });
  }
}

@Injectable()
export class RegistryGroupResolver implements Resolve<any> {
  constructor(
    private applicationLoadService: ApplicationLoadService,
    private registryDao: RegistryDao,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
  ): Promise<{ group_types: RegistryEntry[]; groups: RegistryEntry[] }> {
    return this.applicationLoadService
      .loader()
      .then(() => {
        return this.registryDao.getGroups(
          route.params["org_id"],
          route.data.allLocations ?? false,
        );
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        throw err;
      });
  }
}

@Injectable()
export class RegistryEventsResolver implements Resolve<any> {
  constructor(
    private applicationLoadService: ApplicationLoadService,
    private registryDao: RegistryDao,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Promise<RegistryEntry[]> {
    return this.applicationLoadService
      .loader()
      .then(() => {
        return this.registryDao.getEvents(
          route.params.org_id,
          route.data.allLocations ?? false,
        );
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        throw err;
      });
  }
}
