import { Component, EventEmitter, Input, Output } from "@angular/core";

/**
 * This component has been built for ensuring number are valid (no way to copy-paste letters, push the min/max values...)
 */
@Component({
  selector: "rule-input-number",
  templateUrl: "./rule-input-number.component.html",
  styleUrls: ["./rule-input-number.component.scss"],
})
export class TargetingRuleInputNumberComponent {
  @Input() public value: number;
  @Input() public step: number | null = null;
  @Input() public minValue: number | null = null;
  @Input() public maxValue: number | null = null;
  @Input() public placeholder: string | null = null;
  @Input() public disabled: boolean | null = null;
  @Input() public invalid: boolean | null = null;
  @Input() public inputGroupMode: boolean | null = null; // for styling
  @Input() public nzSize: string;

  @Output() public valueChange = new EventEmitter<number>();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() public paste = new EventEmitter<ClipboardEvent>();

  constructor() {}

  private setValue(value: number | null) {
    this.value = value;
    this.valueChange.emit(value);
  }

  public setNumericValueWithLimits(value: number | null) {
    if (value === null || value === undefined) {
      this.setValue(null);
      return;
    }

    const valueBackup = value;

    if (
      this.minValue !== null &&
      this.minValue !== undefined &&
      value < this.minValue
    ) {
      value = this.minValue;
    } else if (
      this.maxValue !== null &&
      this.maxValue !== undefined &&
      value > this.maxValue
    ) {
      value = this.maxValue;
    }

    if (!!this.step && value % this.step > 0) {
      value = value - (value % this.step);
    }

    if (this.value === value) {
      // small hack for forcing the value refresh
      // needed when we update (with the keyboard) the max value (eg: 3600) with an overflow value (eg: 3800)
      this.setValue(valueBackup);
      setTimeout(() => this.setValue(value), 50);
    } else {
      this.setValue(value);
    }
  }
}
