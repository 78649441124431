<!--
    code ascii:
        48-57 is 0-9
        44 is ","
-->

<input
  nz-input
  type="number"
  [nzSize]="nzSize"
  [min]="minValue"
  [max]="maxValue"
  [step]="step"
  [placeholder]="placeholder"
  onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode === 44"
  [ngModel]="value"
  (ngModelChange)="setNumericValueWithLimits($event)"
  [disabled]="disabled"
  [ngClass]="{ invalid: invalid === true, 'input-group-mode': inputGroupMode }"
  (paste)="paste.emit($event)"
/>
