import { Routes } from "@angular/router";

// layout
import { EmbeddedExternalPageComponent } from "components/layouts/back/embedded-external-page/embedded-external-page.component";
import { InternalErrorPageComponent } from "components/layouts/error-pages/internalerror-page.component";
import { NoOrgaPageComponent } from "components/layouts/error-pages/no-orga-page.component";
import { NotFoundPageComponent } from "components/layouts/error-pages/notfound-page.component";
import { LayoutComponent } from "components/layouts/layout.component";
import { ParentLayoutComponent } from "components/layouts/parent-layout.component";

// pages
import { Oauth2CallbackComponent } from "components/auth/page/callback/oauth2/oauth2-callback.component";
import { BuilderPageComponent } from "components/builder/page-builder.component";
import { FunnelEditPageComponent } from "components/funnels/pages/edit/funnel-edit.component";
import { FunnelListPageComponent } from "components/funnels/pages/list/funnel-list.component";
import { FunnelReportPageComponent } from "components/funnels/pages/report/funnel-report.component";
import { HomePageComponent } from "components/home/home.component";
import { IntegrationListPageComponent } from "components/integration/list/integration-list.component";
import { IntegrationSettingsPageComponent } from "components/integration/settings/integration-settings.component";
import { OnBoardingGoalPageComponent } from "components/onboarding/onboarding-goal/onboarding-goal.component";
import { OnBoardingQualificationPageComponent } from "components/onboarding/onboarding-qualification/onboarding-qualification.component";
import { OnBoardingSetupPageComponent } from "components/onboarding/onboarding-setup/onboarding-setup.component";
import { OverviewOrgPageComponent } from "components/org/overview/overview.component";
import { SegmentBulkImportPageComponent } from "components/segment/bulk-import/segment-bulk-import.component";
import { SegmentDetailsPageComponent } from "components/segment/details/segment-details.component";
import { SegmentListPageComponent } from "components/segment/list/segment-list.component";
import { AccountSettingsPageComponent } from "components/settings/account/account-settings.component";
import { SettingsChannelInstallPageComponent } from "components/settings/channel-install/channel-install.component";
import { ClientScreensListPageComponent } from "components/settings/client-screens/client-screens-list.component";
import { SettingsDataGovernancePageComponent } from "components/settings/data-governance/data-governance.component";
import { SettingsLanguagePageComponent } from "components/settings/language/language.component";
import { RegistryEventsListPageComponent } from "components/settings/registry-events/registry-events-list.component";
import { RegistryIdentityPropertiesListPageComponent } from "components/settings/registry-identity-properties/registry-identity-properties-list.component";
import { SettingsSessionReplayPageComponent } from "components/settings/session-replay/session-replay.component";
import { SettingsSurveyCappingPageComponent } from "components/settings/survey-capping/survey-capping.component";
import { SettingsSurveyCustomizationPageComponent } from "components/settings/survey-customization/survey-customization.component";
import { SettingsTaggingPageComponent } from "components/settings/tagging/tagging.component";
import { SettingsBillingPageComponent } from "components/super-org/billing/billing.component";
import { OverviewSuperOrgPageComponent } from "components/super-org/overview/overview.component";
import { UsersSuperOrgPageComponent } from "components/super-org/users/users.component";
import { WorkspacesSuperOrgPageComponent } from "components/super-org/workspaces/workspaces.component";
import { CreateSurveyPageComponent } from "components/surveys/pages/create/create.component";
import { SurveyListPageComponent } from "components/surveys/pages/list/survey-list.component";
import { SurveySettingsPageComponent } from "components/surveys/pages/settings/settings.component";
import { DistributionSettingsPageComponent } from "components/surveys/pages/share/distribution-settings/distribution-settings.component";
import { ShareSurveyPageComponent } from "components/surveys/pages/share/share-page/share.component";
import { ShareRedirectSurveyPageComponent } from "components/surveys/pages/share/share-redirect.component";
import { AllResponsesStatsSurveyPageComponent } from "components/surveys/pages/stats/all-responses/all-responses.component";
import { ContentAnalysisStatsSurveyPageComponent } from "components/surveys/pages/stats/content-analysis/content-analysis.component";
import { CESIndicatorStatsSurveyPageComponent } from "components/surveys/pages/stats/indicators/ces/ces.component";
import { CSATIndicatorStatsSurveyPageComponent } from "components/surveys/pages/stats/indicators/csat/csat.component";
import { NPSIndicatorStatsSurveyPageComponent } from "components/surveys/pages/stats/indicators/nps/nps.component";
import { PMFIndicatorStatsSurveyPageComponent } from "components/surveys/pages/stats/indicators/pmf/pmf.component";
import { StatsSurveyPageComponent } from "components/surveys/pages/stats/stats.component";
import { OverallPerformancesStatsSurveyPageComponent } from "components/surveys/pages/stats/survey-analysis/survey-analysis.component";
import { SurveyBreakdownStatsSurveyPageComponent } from "components/surveys/pages/stats/survey-breakdown/survey-breakdown.component";
import { SurveyPageComponent } from "components/surveys/pages/survey.component";
import { UserDetailsPageComponent } from "components/user/details/user-details.component";
import { UserListPageComponent } from "components/user/list/user-list.component";

// resolver
import { GrantCallbackComponent } from "components/auth/page/callback/grant/grant-callback.component";
import { OnBoardingBookDemoPageComponent } from "components/onboarding/onboarding-book-demo/onboarding-book-demo.component";
import { OnBoardingDiscoveryComponent } from "components/onboarding/onboarding-discovery/onboarding-discovery.component";
import { OnboardingHeaderComponent } from "components/onboarding/onboarding-header/onboarding-header";
import { OnBoardingInstallationPageComponent } from "components/onboarding/onboarding-installation/onboarding-installation.component";
import { ReleaseListPageComponent } from "components/release/list/release-list.component";
import { AssetIndustriesScoresResolver } from "resolvers/asset-industries-scores";
import { AssetLanguagesAndCountriesResolver } from "resolvers/asset-languages-countries";
import {
  ChannelClientScreensResolver,
  ChannelClientVersionsResolver,
  ChannelsClientVersionsResolver,
} from "resolvers/channel-screen";
import { ChannelsResolver } from "resolvers/channels";
import {
  OrgIntegrationOrNullResolver,
  OrgIntegrationsResolver,
} from "resolvers/integrations";
import { OrgResolver } from "resolvers/org";
import { OrgAccountsResolver } from "resolvers/org-accounts";
import {
  OrgBillingInvoicesResolver,
  OrgBillingPaymentMethodsResolver,
  OrgBillingResolver,
  OrgBillingSubscriptionResolver,
} from "resolvers/org-billing";
import { OrgIndustryStatsResolver } from "resolvers/org-industry";
import { OrgTargetingRulesResolver } from "resolvers/org-targeting-rules";
import { WorkspacesResolver } from "resolvers/orgs";
import {
  RegistryEventsResolver,
  RegistryGroupResolver,
  RegistryIdentityPropertyResolver,
} from "resolvers/registry";
import { OrgReleasesResolver } from "resolvers/releases";
import { SequenceResolver } from "resolvers/sequence";
import { SequencesResolver } from "resolvers/sequences";
import { SurveyResolver } from "resolvers/survey";
import { SurveyScenarioNodeResolver } from "resolvers/survey-scenario-node";
import { SurveyTagsResolver } from "resolvers/survey-tags";
import { SurveysResolver } from "resolvers/surveys";
import { UserResolver } from "resolvers/user";

// guards
import { Auth0AuthorizePageComponent } from "components/auth/page/auth/authorize.component";
import { Auth0LoginPageComponent } from "components/auth/page/auth/login.component";
import { LogoutPageComponent } from "components/auth/page/auth/logout.component";
import { Auth0RegisterPageComponent } from "components/auth/page/auth/register.component";
import { CreateWithAIPageComponent } from "components/auth/page/create-with-ai/create-with-ai.component";
import { CSPEditorPageComponent } from "components/auth/page/csp-editor/csp-editor.component";
import { BenchmarkPageComponent } from "components/benchmark/benchmark.component";
import { PrettyGiftPageComponent } from "components/debug/pretty-gift/pretty-gift.component";
import { SettingsPageComponent } from "components/settings/settings.component";
import { SettingsSurveyBehaviourPageComponent } from "components/settings/survey-behaviour/survey-behaviour.component";
import { CreateSuperOrgPageComponent } from "components/super-org/create/create.component";
import { GetFeedbackImportPageComponent } from "components/surveys/pages/import/tools/getfeedback/getfeedback-import.component";
import { DistributionPreviewPageComponent } from "components/surveys/pages/share/distribution-preview/distribution-preview.component";
import { AllDisplaysStatsSurveyPageComponent } from "components/surveys/pages/stats/all-displays/all-displays.component";
import { ConnectStatsSurveyPageComponent } from "components/surveys/pages/stats/connect/connect.component";
import { UserRecordDetailsPageComponent } from "components/user-record/details/user-record-details.component";
import { UserRecordListPageComponent } from "components/user-record/list/user-record-list.component";
import { WeeklyBriefsListPageComponent } from "components/weekly-briefs/weekly-briefs.component";
import { appGuard } from "guards/app.guard";
import {
  authGuard,
  EnsureNotAuthGuard,
  IsNotAuthGuard,
} from "guards/auth.guard";
import { CanCreateSuperOrgGuard } from "guards/can-create-super-org.guard";
import { entitlementGuard } from "guards/entitlement.guard";
import { featureFlagGuard } from "guards/feature-flag.guard";
import { GenericCanDeactivate } from "guards/generic-can-deactivate.guard";
import { IsIntegrationSlugValidGuard } from "guards/integration.guard";
import { OnBoardingGuard } from "guards/onboarding.guard";
import { DoOrgHasSurveysGuard } from "guards/survey.guard";
import { uiGuard } from "guards/ui.gard";
import { SurveyType } from "models/survey.model";
import { IntegrationAtlassianProjectsResolver } from "resolvers/integration-atlassian-projects";
import { RecordTargetingRulesResolver } from "resolvers/record-targeting-rules";
import { SuperOrgResolver } from "resolvers/super-org";
import { SuperOrgAccountsResolver } from "resolvers/super-org-accounts";
import { SuperOrgWorkspacesResolver } from "resolvers/super-org-workspaces";
import { SuperOrgsResolver } from "resolvers/super-orgs";
import { SurveyDistributionResolver } from "resolvers/survey-distribution";
import { TagEditorTokenResolver } from "resolvers/tag-editor-token";
import { UserRecordResolver } from "resolvers/user-record";
import { WeeklyBriefingResolver } from "resolvers/weekly-briefing";
import { OrgReportResolver } from "resolvers/org-report";

const debugRoutes = [
  {
    path: "prettygift",
    canActivate: [featureFlagGuard("accountIsScreebTeam", "/")],
    component: PrettyGiftPageComponent,
  },
];

const errorRoutes = [
  // error pages and fallback
  // thoses page should be accessed without url rewriting

  {
    path: "404",
    component: NotFoundPageComponent,
  },
  {
    path: "500",
    component: InternalErrorPageComponent,
  },
  // {
  //   path: "**",
  //   component: NotFoundPageComponent,
  // },
];

const authRoutes: Routes = [
  {
    path: "auth",
    runGuardsAndResolvers: "always",
    children: [
      {
        path: "login",
        pathMatch: "full",
        canActivate: [IsNotAuthGuard],
        component: Auth0LoginPageComponent,
      },
      {
        path: "register",
        pathMatch: "full",
        canActivate: [IsNotAuthGuard],
        component: Auth0RegisterPageComponent,
      },
      {
        path: "authorize",
        pathMatch: "full",
        component: Auth0AuthorizePageComponent,
      },
      {
        path: "logout",
        pathMatch: "full",
        component: LogoutPageComponent,
      },
      {
        path: "grant/callback",
        pathMatch: "full",
        canActivate: [EnsureNotAuthGuard],
        component: GrantCallbackComponent,
      },
      {
        // for auth0 integrations
        // @TODO: url should be under /integration path, but we need to change every auth0 provider settings
        path: "oauth2/callback/:integration_slug",
        pathMatch: "full",
        component: Oauth2CallbackComponent,
        canActivate: [IsIntegrationSlugValidGuard],
      },
      ...errorRoutes,
    ],
  },
];

const getSurveyRoutesByType = (type: SurveyType): Routes => {
  const pathPrefix = type === "message" ? "message" : "survey";
  const guard = [];

  return [
    {
      path: `${pathPrefix}/list`,
      pathMatch: "full",
      canActivate: [DoOrgHasSurveysGuard, ...guard],
      component: SurveyListPageComponent,
      resolve: {
        surveys: SurveysResolver,
        channels: ChannelsResolver,
        surveyTags: SurveyTagsResolver,
        sequences: SequencesResolver,
      },
      data: {
        allSurveys: true,
        withScenario: false,
        withTargetingRules: true,
      },
    },
    {
      path: `${pathPrefix}/create`,
      pathMatch: "full",
      component: CreateSurveyPageComponent,
      resolve: {
        registryEntriesEvent: RegistryEventsResolver,
        surveys: SurveysResolver,
      },
    },
    {
      path: `${pathPrefix}/:survey_id`,
      resolve: {},
      runGuardsAndResolvers: "paramsOrQueryParamsChange",
      children: [
        {
          path: "",
          pathMatch: "full",
          component: SurveyPageComponent,
          resolve: {
            survey: SurveyResolver,
          },
        },
        {
          path: "settings",
          pathMatch: "full",
          canDeactivate: [GenericCanDeactivate],
          component: SurveySettingsPageComponent,
          resolve: {
            survey: SurveyResolver,
            surveyTags: SurveyTagsResolver,
            integrations: OrgIntegrationsResolver,
            channels: ChannelsResolver,
          },
        },
        // Edit page behaves differently for surveys and messages
        // on message edit page, we need to know which channel we are editing
        ...(type === "message"
          ? <Routes>[
              {
                path: "edit",
                pathMatch: "full",
                component: ShareRedirectSurveyPageComponent,
                resolve: {
                  survey: SurveyResolver,
                },
              },
              {
                path: "edit/:channel_id/:channel_type",
                pathMatch: "full",
                canDeactivate: [GenericCanDeactivate],
                component: BuilderPageComponent,
                resolve: {
                  survey: SurveyResolver,
                  integrations: OrgIntegrationsResolver,
                  languages_and_countries: AssetLanguagesAndCountriesResolver,
                  tagEditorToken: TagEditorTokenResolver,
                  surveyTags: SurveyTagsResolver,
                },
              },
            ]
          : <Routes>[
              {
                path: "edit",
                pathMatch: "full",
                canDeactivate: [GenericCanDeactivate],
                component: BuilderPageComponent,
                resolve: {
                  survey: SurveyResolver,
                  integrations: OrgIntegrationsResolver,
                  languages_and_countries: AssetLanguagesAndCountriesResolver,
                  surveyTags: SurveyTagsResolver,
                },
              },
            ]),
        {
          path: "share",
          pathMatch: "full",
          component: ShareRedirectSurveyPageComponent,
          resolve: {
            survey: SurveyResolver,
          },
        },
        {
          path: "share/:distribution_id",
          resolve: {},
          data: {
            allLocations: true,
            withScenario: false,
          },
          runGuardsAndResolvers: "paramsOrQueryParamsChange",
          children: [
            {
              path: "",
              redirectTo: "diffusion",
              pathMatch: "full",
            },
            {
              path: "diffusion",
              pathMatch: "full",
              canDeactivate: [GenericCanDeactivate],
              component: ShareSurveyPageComponent,
              resolve: {
                survey: SurveyResolver,
                surveys: SurveysResolver,
                survey_distribution: SurveyDistributionResolver,
                registryEntriesIdentityProperty:
                  RegistryIdentityPropertyResolver,
                registryEntriesGroup: RegistryGroupResolver,
                registryEntriesEvent: RegistryEventsResolver,
                orgTargetingRules: OrgTargetingRulesResolver,
                channelClientScreens: ChannelClientScreensResolver,
                channelClientAppVersions: ChannelClientVersionsResolver,
                languages_and_countries: AssetLanguagesAndCountriesResolver,
              },
            },
            {
              path: "design",
              pathMatch: "full",
              canDeactivate: [GenericCanDeactivate],
              component: DistributionSettingsPageComponent,
              resolve: {
                survey: SurveyResolver,
                survey_distribution: SurveyDistributionResolver,
              },
            },
            {
              path: "preview",
              pathMatch: "full",
              component: DistributionPreviewPageComponent,
              resolve: {
                survey: SurveyResolver,
                survey_distribution: SurveyDistributionResolver,
              },
            },
            ...errorRoutes,
          ],
        },
        {
          path: "stats",
          resolve: {},
          runGuardsAndResolvers: "paramsOrQueryParamsChange",
          children: [
            {
              path: "",
              pathMatch: "full",
              component: StatsSurveyPageComponent,
              resolve: {},
            },
            {
              path: "all-responses",
              pathMatch: "full",
              component: AllResponsesStatsSurveyPageComponent,
              resolve: {
                survey: SurveyResolver,
                integrations: OrgIntegrationsResolver,
                integrationAtlassianProjects:
                  IntegrationAtlassianProjectsResolver,
                languages_and_countries: AssetLanguagesAndCountriesResolver,
                registryEntriesGroup: RegistryGroupResolver,
                registryEntriesIdentityProperty:
                  RegistryIdentityPropertyResolver,
                registryEntriesEvent: RegistryEventsResolver,
              },
            },
            {
              path: "all-displays",
              pathMatch: "full",
              component: AllDisplaysStatsSurveyPageComponent,
              resolve: {
                survey: SurveyResolver,
                languages_and_countries: AssetLanguagesAndCountriesResolver,
                registryEntriesGroup: RegistryGroupResolver,
                registryEntriesIdentityProperty:
                  RegistryIdentityPropertyResolver,
                registryEntriesEvent: RegistryEventsResolver,
              },
            },
            {
              path: "overall-performances",
              pathMatch: "full",
              component: OverallPerformancesStatsSurveyPageComponent,
              resolve: {
                releases: OrgReleasesResolver,
                industriesScores: AssetIndustriesScoresResolver,
                survey: SurveyResolver,
                integrations: OrgIntegrationsResolver,
                languages_and_countries: AssetLanguagesAndCountriesResolver,
                registryEntriesGroup: RegistryGroupResolver,
                registryEntriesIdentityProperty:
                  RegistryIdentityPropertyResolver,
                registryEntriesEvent: RegistryEventsResolver,
              },
            },
            {
              path: "content-analysis",
              pathMatch: "full",
              component: ContentAnalysisStatsSurveyPageComponent,
              resolve: {
                releases: OrgReleasesResolver,
                survey: SurveyResolver,
                integrations: OrgIntegrationsResolver,
                languages_and_countries: AssetLanguagesAndCountriesResolver,
                registryEntriesGroup: RegistryGroupResolver,
                registryEntriesIdentityProperty:
                  RegistryIdentityPropertyResolver,
                registryEntriesEvent: RegistryEventsResolver,
              },
            },
            {
              path: "flow-analysis",
              pathMatch: "full",
              component: SurveyBreakdownStatsSurveyPageComponent,
              resolve: {
                survey: SurveyResolver,
                integrations: OrgIntegrationsResolver,
                languages_and_countries: AssetLanguagesAndCountriesResolver,
                registryEntriesGroup: RegistryGroupResolver,
                registryEntriesIdentityProperty:
                  RegistryIdentityPropertyResolver,
                registryEntriesEvent: RegistryEventsResolver,
              },
            },
            {
              path: "nps",
              pathMatch: "full",
              component: NPSIndicatorStatsSurveyPageComponent,
              resolve: {
                releases: OrgReleasesResolver,
                industriesScores: AssetIndustriesScoresResolver,
                nodes: SurveyScenarioNodeResolver,

                survey: SurveyResolver,
                integrations: OrgIntegrationsResolver,
                languages_and_countries: AssetLanguagesAndCountriesResolver,
                registryEntriesGroup: RegistryGroupResolver,
                registryEntriesIdentityProperty:
                  RegistryIdentityPropertyResolver,
                registryEntriesEvent: RegistryEventsResolver,
              },
              data: { node_type: "nps" },
            },
            {
              path: "product-market-fit",
              pathMatch: "full",
              component: PMFIndicatorStatsSurveyPageComponent,
              resolve: {
                survey: SurveyResolver,
                integrations: OrgIntegrationsResolver,
                languages_and_countries: AssetLanguagesAndCountriesResolver,
                registryEntriesGroup: RegistryGroupResolver,
                registryEntriesIdentityProperty:
                  RegistryIdentityPropertyResolver,
                registryEntriesEvent: RegistryEventsResolver,
              },
              data: { node_type: "pmf" },
            },
            {
              path: "ces",
              pathMatch: "full",
              component: CESIndicatorStatsSurveyPageComponent,
              resolve: {
                releases: OrgReleasesResolver,
                industriesScores: AssetIndustriesScoresResolver,
                nodes: SurveyScenarioNodeResolver,
                survey: SurveyResolver,
                integrations: OrgIntegrationsResolver,
                languages_and_countries: AssetLanguagesAndCountriesResolver,
                registryEntriesGroup: RegistryGroupResolver,
                registryEntriesIdentityProperty:
                  RegistryIdentityPropertyResolver,
                registryEntriesEvent: RegistryEventsResolver,
              },
              data: { node_type: "ces" },
            },
            {
              path: "csat",
              pathMatch: "full",
              component: CSATIndicatorStatsSurveyPageComponent,
              resolve: {
                releases: OrgReleasesResolver,
                industriesScores: AssetIndustriesScoresResolver,
                nodes: SurveyScenarioNodeResolver,
                survey: SurveyResolver,
                integrations: OrgIntegrationsResolver,
                languages_and_countries: AssetLanguagesAndCountriesResolver,
                registryEntriesGroup: RegistryGroupResolver,
                registryEntriesIdentityProperty:
                  RegistryIdentityPropertyResolver,
                registryEntriesEvent: RegistryEventsResolver,
              },
              data: { node_type: "csat" },
            },
            {
              path: "connect",
              pathMatch: "full",
              component: ConnectStatsSurveyPageComponent,
              resolve: {
                survey: SurveyResolver,
                surveyTags: SurveyTagsResolver,
                integrations: OrgIntegrationsResolver,
                channels: ChannelsResolver,
              },
              data: { node_type: "csat" },
            },
            ...errorRoutes,
          ],
        },
        ...errorRoutes,
      ],
    },
    {
      path: "survey/import/getfeedback",
      pathMatch: "full",
      component: GetFeedbackImportPageComponent,
      resolve: {
        registryEntriesEvent: RegistryEventsResolver,
        languages_and_countries: AssetLanguagesAndCountriesResolver,
      },
    },
  ];
};

const orgSettingsRoutes: Routes = [
  {
    path: "settings",
    runGuardsAndResolvers: "paramsOrQueryParamsChange",
    resolve: {},
    children: [
      {
        path: "",
        pathMatch: "full",
        component: SettingsPageComponent,
      },
      {
        path: "behaviour",
        pathMatch: "full",
        component: SettingsSurveyBehaviourPageComponent,
        resolve: {
          channels: ChannelsResolver,
        },
        canDeactivate: [GenericCanDeactivate],
      },
      {
        path: "customize/:survey_type/:interaction",
        pathMatch: "full",
        component: SettingsSurveyCustomizationPageComponent,
        resolve: {
          channels: ChannelsResolver,
        },
        canActivate: [entitlementGuard("survey_edition", "/")],
        canDeactivate: [GenericCanDeactivate],
      },
      {
        path: "customize/:survey_type",
        pathMatch: "full",
        component: SettingsSurveyCustomizationPageComponent,
        resolve: {
          channels: ChannelsResolver,
        },
        canActivate: [entitlementGuard("survey_edition", "/")],
        canDeactivate: [GenericCanDeactivate],
      },
      {
        path: "install",
        pathMatch: "full",
        component: SettingsChannelInstallPageComponent,
        resolve: {
          channels: ChannelsResolver,
        },
      },
      {
        path: "sdk-changelogs",
        pathMatch: "full",
        component: EmbeddedExternalPageComponent,
        data: {
          name: "Screeb SDK Changelogs",
          title: "Track SDK updates",
          // https://embednotion.com -> https://screeb.notion.site/Screeb-SDK-Changelogs-af13736d29ad44c5b2724a241d84fa0b
          url: "https://v2-embednotion.com/Screeb-SDK-Changelogs-af13736d29ad44c5b2724a241d84fa0b",
        },
        canActivate: [entitlementGuard("survey_inapp_mobile", "/")],
      },
      {
        path: "survey-capping",
        pathMatch: "full",
        component: SettingsSurveyCappingPageComponent,
        resolve: {
          surveys: SurveysResolver,
          orgTargetingRules: OrgTargetingRulesResolver,
          registryEntriesIdentityProperty: RegistryIdentityPropertyResolver,
          registryEntriesEvent: RegistryEventsResolver,
          registryEntriesGroup: RegistryGroupResolver,
        },
        data: {
          allLocations: true,
        },
      },
      {
        path: "user-identity-properties",
        pathMatch: "full",
        component: RegistryIdentityPropertiesListPageComponent,
        resolve: {
          registryEntriesIdentityProperty: RegistryIdentityPropertyResolver,
        },
        data: {
          allLocations: true,
        },
      },
      {
        path: "user-events",
        pathMatch: "full",
        component: RegistryEventsListPageComponent,
        resolve: {
          registryEntriesEvent: RegistryEventsResolver,
        },
        data: {
          allLocations: true,
        },
      },
      {
        path: "mobile-screens",
        pathMatch: "full",
        component: ClientScreensListPageComponent,
        resolve: {
          channels: ChannelsResolver,
          channelsClientScreenVersions: ChannelsClientVersionsResolver,
        },
      },
      {
        path: "data-governance",
        pathMatch: "full",
        component: SettingsDataGovernancePageComponent,
      },
      {
        path: "session-replay",
        pathMatch: "full",
        component: SettingsSessionReplayPageComponent,
        resolve: {
          surveys: SurveysResolver,
          recordTargetingRules: RecordTargetingRulesResolver,
          registryEntriesIdentityProperty: RegistryIdentityPropertyResolver,
          registryEntriesEvent: RegistryEventsResolver,
          registryEntriesGroup: RegistryGroupResolver,
        },
        data: {
          allLocations: true,
        },
      },
      {
        path: "billing",
        pathMatch: "full",
        component: SettingsBillingPageComponent,
        resolve: {
          superOrg: SuperOrgResolver,
          orgBilling: OrgBillingResolver,
          orgBillingSubscription: OrgBillingSubscriptionResolver,
          orgBillingPaymentMethods: OrgBillingPaymentMethodsResolver,
          orgBillingInvoices: OrgBillingInvoicesResolver,
          languages_and_countries: AssetLanguagesAndCountriesResolver,
          workspaces: SuperOrgWorkspacesResolver,
        },
      },
      {
        path: "language",
        pathMatch: "full",
        component: SettingsLanguagePageComponent,
        resolve: {
          languages_and_countries: AssetLanguagesAndCountriesResolver,
        },
      },
      {
        path: "tagging",
        pathMatch: "full",
        component: SettingsTaggingPageComponent,
      },
      ...errorRoutes,
    ],
  },
];

const onboardingRoutes: Routes = [
  {
    path: "onboarding",
    component: OnboardingHeaderComponent,
    children: [
      {
        path: "",
        pathMatch: "full",
        redirectTo: "qualification",
      },
      {
        path: "qualification",
        pathMatch: "full",
        component: OnBoardingQualificationPageComponent,
      },
      {
        path: "goal",
        pathMatch: "full",
        component: OnBoardingGoalPageComponent,
      },
      {
        path: "setup",
        pathMatch: "full",
        component: OnBoardingSetupPageComponent,
      },
      {
        path: "installation",
        pathMatch: "full",
        component: OnBoardingInstallationPageComponent,
        resolve: {
          channels: ChannelsResolver,
        },
      },
      {
        path: "discovery",
        pathMatch: "full",
        component: OnBoardingDiscoveryComponent,
      },
      {
        path: "book-demo",
        pathMatch: "full",
        component: OnBoardingBookDemoPageComponent,
      },
      ...errorRoutes,
    ],
  },
];

const superOrgRoutes: Routes = [
  {
    path: "overview/last",
    children: [
      {
        path: "**",
        component: HomePageComponent,
        resolve: {
          superOrgs: SuperOrgsResolver,
        },
      },
    ],
  },
  {
    path: "overview/:super_org_id",
    resolve: {
      superOrg: SuperOrgResolver,
      orgs: WorkspacesResolver, // Needed for ui service
    },
    runGuardsAndResolvers: "paramsOrQueryParamsChange",
    children: [
      {
        path: "",
        pathMatch: "full",
        redirectTo: "home",
      },
      {
        path: "home",
        pathMatch: "full",
        runGuardsAndResolvers: "paramsOrQueryParamsChange",
        component: OverviewSuperOrgPageComponent,
        resolve: {
          superOrgAccounts: SuperOrgAccountsResolver,
          industriesScores: AssetIndustriesScoresResolver,
          workspaces: SuperOrgWorkspacesResolver,
        },
        data: {},
      },
      {
        path: "workspaces",
        pathMatch: "full",
        runGuardsAndResolvers: "paramsOrQueryParamsChange",
        component: WorkspacesSuperOrgPageComponent,
        resolve: {
          superOrg: SuperOrgResolver,
          workspaces: SuperOrgWorkspacesResolver,
        },
        data: {},
      },
      {
        path: "users",
        pathMatch: "full",
        runGuardsAndResolvers: "paramsOrQueryParamsChange",
        component: UsersSuperOrgPageComponent,
        resolve: {
          superOrgAccounts: SuperOrgAccountsResolver,
          workspaces: SuperOrgWorkspacesResolver,
        },
        data: {},
      },
      {
        path: "billing",
        pathMatch: "full",
        component: SettingsBillingPageComponent,
        resolve: {
          superOrg: SuperOrgResolver,
          orgBilling: OrgBillingResolver,
          orgBillingSubscription: OrgBillingSubscriptionResolver,
          orgBillingPaymentMethods: OrgBillingPaymentMethodsResolver,
          orgBillingInvoices: OrgBillingInvoicesResolver,
          languages_and_countries: AssetLanguagesAndCountriesResolver,
          workspaces: SuperOrgWorkspacesResolver,
        },
      },
    ],
  },
];

const accountRoutes: Routes = [
  {
    path: "settings",
    runGuardsAndResolvers: "paramsOrQueryParamsChange",
    resolve: {},
    children: [
      {
        path: "",
        pathMatch: "full",
        redirectTo: "account",
      },
      {
        path: "account",
        pathMatch: "full",
        component: AccountSettingsPageComponent,
        resolve: {
          languages_and_countries: AssetLanguagesAndCountriesResolver,
        },
      },
    ],
  },
];

const orgRoutes: Routes = [
  {
    path: "org/:org_id",
    resolve: {
      org: OrgResolver,
      orgAccounts: OrgAccountsResolver,
    },
    runGuardsAndResolvers: "paramsOrQueryParamsChange",
    children: [
      {
        path: "",
        pathMatch: "full",
        redirectTo: "home",
      },
      ...onboardingRoutes,
      {
        path: "",
        canActivateChild: [OnBoardingGuard],
        runGuardsAndResolvers: "always",
        children: [
          {
            path: "home",
            pathMatch: "full",
            runGuardsAndResolvers: "paramsOrQueryParamsChange",
            component: OverviewOrgPageComponent,
            resolve: {
              industriesScores: AssetIndustriesScoresResolver,
              surveys: SurveysResolver,
              integrations: OrgIntegrationsResolver,
              orgReports: OrgReportResolver,
            },
            data: {
              allSurveys: true,
              withScenario: true, // this is used for measuring NPS, but i'm pretty sure we should not do it this way
            },
          },
          {
            path: "user-recruitment",
            pathMatch: "full",
            component: EmbeddedExternalPageComponent,
            data: {
              name: "User Recruitment",
              title: "Recruit Users for Interviews or Beta Tests with Screeb",
              // https://embednotion.com -> https://screeb.notion.site/Recruit-Users-for-Interviews-or-Beta-Tests-with-Screeb-fa7700df16444078b588d8005e93a22a
              url: "https://v2-embednotion.com/Recruit-Users-for-Interviews-or-Beta-Tests-with-Screeb-fa7700df16444078b588d8005e93a22a",
            },
          },
          {
            path: "people/sessions",
            children: [
              {
                path: "",
                pathMatch: "full",
                component: UserRecordListPageComponent,
                resolve: {
                  org: OrgResolver,
                  languages_and_countries: AssetLanguagesAndCountriesResolver,
                  registryEntriesGroup: RegistryGroupResolver,
                  registryEntriesIdentityProperty:
                    RegistryIdentityPropertyResolver,
                  registryEntriesEvent: RegistryEventsResolver,
                },
              },
              {
                path: ":session_id",
                pathMatch: "full",
                component: UserRecordDetailsPageComponent,
                resolve: {
                  org: OrgResolver,
                  orgAccounts: OrgAccountsResolver,
                  surveys: SurveysResolver,
                  userRecord: UserRecordResolver,
                  industriesScores: AssetIndustriesScoresResolver,
                  orgProperties: RegistryIdentityPropertyResolver,
                  orgEvents: RegistryEventsResolver,
                  orgGroups: RegistryGroupResolver,
                },
              },
              ...errorRoutes,
            ],
          },
          {
            path: "people/respondent",
            children: [
              {
                path: "",
                pathMatch: "full",
                component: UserListPageComponent,
                resolve: {
                  org: OrgResolver,
                  registryEntriesIdentityProperty:
                    RegistryIdentityPropertyResolver,
                  registryEntriesEvent: RegistryEventsResolver,
                  registryEntriesGroup: RegistryGroupResolver,
                },
              },
              {
                path: ":respondent_id",
                pathMatch: "full",
                component: UserDetailsPageComponent,
                resolve: {
                  org: OrgResolver,
                  user: UserResolver,
                  industriesScores: AssetIndustriesScoresResolver,
                  orgProperties: RegistryIdentityPropertyResolver,
                  orgEvents: RegistryEventsResolver,
                  orgGroups: RegistryGroupResolver,
                  languages_and_countries: AssetLanguagesAndCountriesResolver,
                },
              },
              ...errorRoutes,
            ],
          },
          {
            path: "people/segment",
            resolve: {},
            runGuardsAndResolvers: "paramsOrQueryParamsChange",
            children: [
              {
                path: "",
                pathMatch: "full",
                component: SegmentListPageComponent,
                resolve: {
                  org: OrgResolver,
                  registryEntriesGroup: RegistryGroupResolver,
                },
              },
              {
                path: ":segment_id",
                pathMatch: "full",
                component: SegmentDetailsPageComponent,
                resolve: {
                  org: OrgResolver,
                  releases: OrgReleasesResolver,
                  surveys: SurveysResolver,
                  industriesScores: AssetIndustriesScoresResolver,
                  registryEntriesIdentityProperty:
                    RegistryIdentityPropertyResolver,
                  registryEntriesGroup: RegistryGroupResolver,
                  registryEntriesEvent: RegistryEventsResolver,
                },
                data: {
                  allSurveys: true,
                  withScenario: true, // this is used for measuring NPS, but i'm pretty sure we should not do it this way
                },
              },
              {
                path: ":segment_id/bulk-import",
                pathMatch: "full",
                component: SegmentBulkImportPageComponent,
                resolve: {
                  registryEntriesIdentityProperty:
                    RegistryIdentityPropertyResolver,
                  registryEntriesGroup: RegistryGroupResolver,
                },
              },
              ...errorRoutes,
            ],
          },
          ...getSurveyRoutesByType("survey"),
          ...getSurveyRoutesByType("message"),
          {
            path: "funnel/list",
            pathMatch: "full",
            component: FunnelListPageComponent,
            resolve: {
              funnels: SequencesResolver,
              registryEntriesEvent: RegistryEventsResolver,
            },
            data: { sequence_type: "funnel" },
          },
          {
            path: "funnel/:funnel_id",
            resolve: {
              funnel: SequenceResolver,
              surveys: SurveysResolver,
              registryEntriesEvent: RegistryEventsResolver,
            },
            data: {
              allSurveys: true,
              withScenario: false,
            },
            runGuardsAndResolvers: "paramsOrQueryParamsChange",
            children: [
              {
                path: "",
                redirectTo: "/404",
                pathMatch: "full",
              },
              {
                path: "edit",
                pathMatch: "full",
                canDeactivate: [GenericCanDeactivate],
                component: FunnelEditPageComponent,
              },
              {
                path: "report",
                pathMatch: "full",
                component: FunnelReportPageComponent,
              },
            ],
          },
          {
            path: "benchmark",
            runGuardsAndResolvers: "paramsOrQueryParamsChange",
            resolve: {},
            children: [
              {
                path: "",
                pathMatch: "full",
                component: BenchmarkPageComponent,
                resolve: {
                  orgIndustrisStats: OrgIndustryStatsResolver,
                },
              },
              ...errorRoutes,
            ],
          },
          {
            path: "releases",
            runGuardsAndResolvers: "paramsOrQueryParamsChange",
            resolve: {},
            children: [
              {
                path: "",
                pathMatch: "full",
                component: ReleaseListPageComponent,
                resolve: {
                  orgAccounts: OrgAccountsResolver,
                },
              },
              ...errorRoutes,
            ],
          },
          {
            path: "integrations",
            children: [
              {
                path: "",
                pathMatch: "full",
                component: IntegrationListPageComponent,
                resolve: {
                  integrations: OrgIntegrationsResolver,
                },
              },
              {
                path: ":integration_slug",
                pathMatch: "full",
                canActivate: [IsIntegrationSlugValidGuard],
                // canDeactivate: [GenericCanDeactivate],
                resolve: {
                  integration: OrgIntegrationOrNullResolver,
                },
                component: IntegrationSettingsPageComponent,
              },
              ...errorRoutes,
            ],
          },
          {
            path: "weekly-briefs",
            resolve: {},
            children: [
              {
                path: "",
                pathMatch: "full",
                component: WeeklyBriefsListPageComponent,
                resolve: { weeklyBriefings: WeeklyBriefingResolver },
              },
              ...errorRoutes,
            ],
          },
          ...orgSettingsRoutes,
          ...errorRoutes,
        ],
      },
      ...errorRoutes,
    ],
  },
];

const screebRoutes: Routes = [
  {
    path: "",
    canActivate: [appGuard()],
    component: ParentLayoutComponent,
    children: [
      {
        path: "",
        canActivate: [uiGuard()],
        runGuardsAndResolvers: "always",
        children: [
          ...authRoutes,
          {
            path: "",
            canActivate: [authGuard()],
            runGuardsAndResolvers: "always",
            component: LayoutComponent,
            children: [
              {
                path: "",
                pathMatch: "full",
                redirectTo: "redirect-home",
              },
              {
                path: "missing-org",
                component: NoOrgaPageComponent,
              },
              {
                path: "redirect-home",
                pathMatch: "full",
                component: HomePageComponent,
                runGuardsAndResolvers: "always",
                resolve: {
                  orgs: WorkspacesResolver,
                },
              },
              {
                path: "channel",
                redirectTo: "/redirect-home",
              }, // @TODO: to delete
              {
                path: "org/last",
                children: [
                  {
                    path: "**",
                    component: HomePageComponent,
                    runGuardsAndResolvers: "always",
                    resolve: {
                      orgs: WorkspacesResolver,
                    },
                  },
                ],
              },
              {
                path: "org/create",
                pathMatch: "full",
                runGuardsAndResolvers: "always",
                component: CreateSuperOrgPageComponent,
                canActivate: [CanCreateSuperOrgGuard],
                resolve: {},
              },
              ...accountRoutes,
              ...superOrgRoutes,
              ...orgRoutes,
              ...debugRoutes,
              ...errorRoutes,
            ],
          },
          {
            path: "create-with-ai",
            pathMatch: "full",
            component: CreateWithAIPageComponent,
            resolve: {
              languages_and_countries: AssetLanguagesAndCountriesResolver,
            },
          },
          {
            path: "csp",
            pathMatch: "full",
            component: CSPEditorPageComponent,
          },
          ...errorRoutes,
          {
            path: "**",
            component: NotFoundPageComponent,
          },
        ],
      },
    ],
  },
];

export { screebRoutes };
