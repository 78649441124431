import { GraphNode } from "components/builder/flow";
import { decode } from "html-entities";
import {
  CTACalendar,
  getI18nTextLabelTranslation,
  SurveyLanguages,
} from "models/survey.dao.types";
import { CardValidator, getLength } from "./CardValidator";

export enum CalendarType {
  Calendly = "calendly",
  Google = "google",
  Hubspot = "hubspot",
  CalCom = "calcom",
  SavvyCal = "savvycal",
  Microsoft = "microsoft",
}

export const isValidURL = (url: string): string | null => {
  // Check calendly url
  // https://calendly.com/screeb/discoverycall?embed_domain=screeb.app&embed_type=Inline
  const urlPattern = new RegExp(
    "^(https?:\\/\\/)?calendly.com\\/[a-zA-Z0-9-_]+\\/?",
    "i",
  );

  if (urlPattern.test(url)) {
    return CalendarType.Calendly;
  }

  // Check google calendar url
  // https://calendar.google.com/calendar/appointments/schedules/AcZssZ2aaNm7vn5JtI3YhCNV8u34hIElxroU7kDsGPJmuLqL_nlME97eB-U9pOBvP6nPtdGStmb8ZQVR
  // https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ1-85je20btsnWuDIhX9g207gfHAlW5iuiecfW3ZfP9x4Cbih1wlPAheOBQ49FMhJ776v3u80rn
  // https://calendar.google.com/calendar/u/0/appointments/AcZssZ0ZjC-s-ONvQCweNYdyc0B7m_sM6uiNcBFWssE=
  const googleUrlPattern = new RegExp(
    "^(https?:\\/\\/)?calendar.google.com\\/calendar\\/(u\\/[0-9]+\\/)?appointments\\/[a-zA-Z0-9-_]+\\/?",
    "i",
  );

  if (googleUrlPattern.test(url)) {
    return CalendarType.Google;
  }

  // Check hubspot calendar url
  // https://meetings.hubspot.com/simon-robic
  // I'm allowing anything after - with a limit of 5 char because i'm not sure about what zone hubspot support
  const hubspotUrlPattern = new RegExp(
    "^(https?:\\/\\/)?meetings(-[a-zA-Z0-9-_]{1,5})?\\.hubspot\\.com\\/[a-zA-Z0-9-_]+\\/?",
    "i",
  );

  if (hubspotUrlPattern.test(url)) {
    return CalendarType.Hubspot;
  }

  // Check cal.com calendar url
  // https://cal.com/alexis-rouillard-fzqhou
  const calcomUrlPattern = new RegExp(
    "^(https?:\\/\\/)?cal.com\\/[a-zA-Z0-9-_]+\\/?",
    "i",
  );

  if (calcomUrlPattern.test(url)) {
    return CalendarType.CalCom;
  }

  // Check savvycal calendar url
  // https://savvycal.com/alexisrouillard/b24791cb
  const savvycalUrlPattern = new RegExp(
    "^(https?:\\/\\/)?savvycal.com\\/[a-zA-Z0-9-_]+\\/[a-zA-Z0-9-_]+\\/?",
    "i",
  );

  if (savvycalUrlPattern.test(url)) {
    return CalendarType.SavvyCal;
  }

  // Check microsoft calendar url
  // https://outlook.office.com/bookwithme/user/66dc2de4de1b42ad9b806afb1ffd3982@epitech.eu?anonymous&ep=plink
  const microsoftUrlPattern = new RegExp(
    "^(https?:\\/\\/)?outlook.office.com\\/bookwithme\\/user\\/[a-zA-Z0-9-_@.?&=]+",
    "i",
  );

  if (microsoftUrlPattern.test(url)) {
    return CalendarType.Microsoft;
  }

  return null;
};

export const CalendarValidator: CardValidator = (
  node: GraphNode,
  currentLanguage: SurveyLanguages,
  defaultLanguage: SurveyLanguages,
) => {
  const isDefaultLanguage = currentLanguage === defaultLanguage;
  const cta = node.node.question.call_to_action;

  if (cta.type !== "calendar") throw new Error("unexpected CTA type");

  const getLabelLink = (): string => {
    const cta = node.node.question.call_to_action as CTACalendar;
    return decode(
      getI18nTextLabelTranslation(
        cta.calendar.payload.url,
        currentLanguage,
        currentLanguage,
      ) || "",
    );
  };

  const url = getLabelLink() || "";
  const urlLength = getLength(url);

  const linkErrors = [];
  if (urlLength < 1) {
    linkErrors.push("URL Required");
  } else if (urlLength > 250) {
    linkErrors.push("Url: Max 250 characters allowed");
  } else if (!isValidURL(url.trim())) {
    linkErrors.push("Invalid URL");
  }

  if (!isDefaultLanguage) {
    return {
      errors: [],
      warnings: [linkErrors],
    };
  }

  return {
    errors: [linkErrors],
    warnings: [],
  };
};
