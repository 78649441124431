<div
  [ngClass]="
    'SmallCard SmallCardType-' +
    type +
    (builderStore.readOnly ? ' ReadOnly' : '')
  "
  (click)="onClick.emit($event)"
>
  <div class="SmallCardInner">
    <ng-container [ngSwitch]="type">
      <ng-container *ngSwitchCase="'Emoji'">
        <div class="SmallCardEmoji">
          <sanitized-message-component
            [content]="emoji"
            [options]="{
              styling: true,
              emojiTranscoder: true,
            }"
          ></sanitized-message-component>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'EmojiAndText'">
        <div *ngIf="emoji" class="SmallCardEmoji">
          <sanitized-message-component
            [content]="emoji"
            [options]="{
              styling: true,
              emojiTranscoder: true,
            }"
          ></sanitized-message-component>
        </div>
        <div class="SmallCardLabel" [ngClass]="emoji ? '' : 'NoEmoji'">
          <sanitized-message-component
            [content]="getTranslatedLabel()"
            [options]="{
              styling: true,
              emojiTranscoder: true,
            }"
          ></sanitized-message-component>

          <span
            class="has-error"
            *ngIf="!this.label[builderStore.currentLanguage]"
          >
            <i nz-icon nzType="warning" nzTheme="fill"></i>
          </span>
          <span
            class="has-error"
            *ngIf="!this.label[builderStore.currentLanguage] && haveError"
          >
            <i nz-icon nzType="warning" nzTheme="fill"></i>
          </span>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div class="ActionDotContainer">
    <action-dot
      *ngIf="!builderStore.readOnly && !multiple"
      [displayPlainState]="false"
      (onClick)="actionDotClick($event)"
    ></action-dot>
  </div>
  <div
    class="PathPercentContainer"
    [class]="{ 'have-skip': haveSkip }"
    *ngIf="haveNext && builderStore.readOnly && builderStore.showPathCount"
  >
    {{ pathCount ?? 0 | format: "percent" }}
  </div>
  <div
    class="SkipPathPercentContainer"
    [class]="{ 'have-next': haveNext }"
    *ngIf="haveSkip && builderStore.readOnly && builderStore.showPathCount"
  >
    {{ skipPathCount ?? 0 | format: "percent" }}
  </div>
</div>
