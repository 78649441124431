/**
 * Ce sera en français ici, car c'est chaud de l'expliquer en EN
 *
 * Une ligne est composé de 2 elements HTML, un segment gauche et un segment droit, la ligne peut soit "descendre" soit "monter" soit être "horizontale".
 * La partie "descendante" ou "montante" de la ligne, qui est la ligne verticale est positionné dans l'espace entre la card de démarrage et celle d'arrivée.
 * On part du postulat que l'écart entre 2 columns est basé sur la constante COMPUTED_SPACE_BETWEEN_COLUMNS.
 * Elle relie une card de démarrage et une card de fin.
 *
 * Si elle descends :
 *   Le segment gauche est une div avec un border-top et un border-right, et un radius-top-right.
 *   Le segment droit est une div de hauteur 2px, et un seul border-top afin de faire une ligne horizontale.
 *
 * Si elle monte :
 *   Le segment gauche est une div avec un border-bottom et un border-right, et un radius-bottom-right.
 *   Le segment droit est une div de hauteur 2px, et un seul border-top afin de faire une ligne horizontale.
 *
 * Si elle est horizontale :
 *  Les segments gauche et droit sont fusionné en une seule div de 2px, et un seul border-top afin de faire une ligne horizontale.
 *
 */

import { Column } from "components/builder/page-builder.component";
import { CardContainer, CardContainerType, CardDataAndElement } from "./Models";

// 100 = margin between columns - 32 = doubled dot size on each side to ensure gap aside the dot
// hard coded, don't forge to move this if you change the CSS margin between columns
const DOT_BUTTON_SIZE = 16;
const SPACE_BETWEEN_COLUMNS = 100 - DOT_BUTTON_SIZE * 2;
const INVISIBLE_CARDS_HEIGHT = 2;
const DEFAULT_BORDER_SIZE = 2;

// Return the Y position of the from card line
const getFromCardY = (
  fromClientRect: DOMRect,
  fromCard: CardDataAndElement,
  isSkipLine: boolean,
): number => {
  let fromCardY = fromClientRect.y;

  // Height 2 === invisible card
  if (Math.round(fromClientRect.height) !== INVISIBLE_CARDS_HEIGHT) {
    fromCardY += 16; // Border radius

    // If we have both a target and a skip, we want to place the line on the right axis and centered
    // Skip line will always be under
    if (fromCard.cardData.targetNodeId && fromCard.cardData.skipTargetNodeId) {
      const yOffset = (fromClientRect.height - 32) / 2;
      fromCardY += yOffset / 2;

      if (isSkipLine) {
        fromCardY += yOffset;
      }
    } else {
      fromCardY += (fromClientRect.height - 32) / 2;
    }
  }
  return fromCardY;
};

// Return the Y position of the to card line
const getToCardY = (
  toClientRect: DOMRect,
  toSourcesCards: CardContainer[],
  fromCard: CardDataAndElement,
  fromCardY: number,
  toCardIndex: number,
  column: Column,
  isSkipLine: boolean,
  isMerged: boolean,
): number => {
  let toCardY = toClientRect.y;

  // Height 2 === invisible card
  if (Math.round(toClientRect.height) !== INVISIBLE_CARDS_HEIGHT) {
    toCardY += 16; // Border radius

    // Only move the line if "to" have multiple sources or if a card at the same index on previous column have an exit
    // When the line is coming from the same card group, we'll group the lines on the same axis
    // Skip first card of first column when checking for collapse, as this card is upper
    const haveCollapse = column.cards.some(
      (card) =>
        card.component === CardContainerType.BigCard &&
        card.cardIndex === toCardIndex &&
        !(card.columnIndex === 0 && card.cardIndex === 0) &&
        toSourcesCards.length &&
        !toSourcesCards.some(
          (c) =>
            c.targetNodeId === card.targetNodeId ||
            c.skipTargetNodeId === card.skipTargetNodeId,
        ),
    );
    if (toSourcesCards.length || haveCollapse) {
      const idx = toSourcesCards.findIndex((card) => {
        const { cardIndex, columnIndex } = fromCard.cardData;
        return (
          cardIndex === card.cardIndex &&
          columnIndex === card.columnIndex &&
          ((card.skipTargetNodeId && isSkipLine) ||
            (card.targetNodeId && !isSkipLine))
        );
      });

      // Merge same lines with same parent together
      if (idx !== -1) {
        let pos = 0;
        const positions = {};
        let uniqueLinesNumber = toSourcesCards.length;
        toSourcesCards.forEach((card, index) => {
          if (!isMerged || card.component !== CardContainerType.SmallCard) {
            positions[index] = pos++;
            return;
          }

          for (let i = 0; i < toSourcesCards.length; i += 1) {
            if (toSourcesCards[i].component !== CardContainerType.SmallCard) {
              continue;
            }

            if (
              card.originCards.some((c) =>
                toSourcesCards[i].originCards.some(
                  (o) => o.cardIndex === c.cardIndex,
                ),
              )
            ) {
              uniqueLinesNumber -= 1;
              if (positions[i] === undefined) {
                uniqueLinesNumber += 1;
                positions[i] = pos++;
              }
              positions[index] = positions[i];
              return;
            }
          }
        });

        let linePosition = positions[idx];

        if (haveCollapse) {
          linePosition += 1;
          uniqueLinesNumber += 1;
        }

        // Place the line on the right axis and centered
        const yOffset = (toClientRect.height - 32) / uniqueLinesNumber;
        toCardY += yOffset / 2 + yOffset * linePosition;
      }
    }

    // Allow straight line if line is still in direction of the card
    // And it's the only line or if all sources cards are the same
    if (
      !haveCollapse &&
      (toSourcesCards.length <= 1 ||
        toSourcesCards.every(
          (card) =>
            card.cardIndex === toSourcesCards[0].cardIndex &&
            card.columnIndex === toSourcesCards[0].columnIndex,
        )) &&
      fromCardY !== toCardY &&
      fromCardY >= toClientRect.y + 16.0 &&
      fromCardY <= toClientRect.y + toClientRect.height - 16.0
    ) {
      toCardY = fromCardY;
    }

    // We want to ensure that the line is not going out of the card
    toCardY = Math.min(toClientRect.y + toClientRect.height - 16, toCardY);
    toCardY = Math.max(toClientRect.y + 16, toCardY);
  }
  return toCardY;
};

const getLineXOffset = (
  column: Column,
  fromCard: CardDataAndElement,
  fromCardY: number,
  toCardY: number,
  isSkipLine: boolean,
  isMerged: boolean,
): number => {
  // Calculate where to place the line on the horizontal axis
  // Where the farest line will be the closest to the card
  const filteredCards = column.cards.filter(
    (card) => card.skipTargetNodeId || card.targetNodeId,
  );

  // Move first card of first column to the end as it's the upper card
  if (
    filteredCards.length &&
    filteredCards[0].columnIndex === 0 &&
    filteredCards[0].cardIndex === 0
  ) {
    filteredCards.push(filteredCards.shift());
  }

  // Reverse to get the farest line first
  filteredCards.reverse();

  const lineIdx = filteredCards.findIndex(
    (card) =>
      card.cardIndex === fromCard.cardData.cardIndex &&
      card.columnIndex === fromCard.cardData.columnIndex,
  );

  let pos = 0;
  const mergedLines = {};
  const positions = {};

  // Merge same lines with same parent together and get real position
  let uniqueExitDotCount = column.exitDotCount;
  filteredCards.forEach((card, index) => {
    if (!isMerged || card.component !== CardContainerType.SmallCard) {
      positions[index] = pos++;
      return;
    }

    for (let i = 0; i < filteredCards.length; i += 1) {
      if (filteredCards[i].component !== CardContainerType.SmallCard) {
        continue;
      }

      // Check if card match the same origin
      if (
        card.originCards.some((c) =>
          filteredCards[i].originCards.some((o) => o.cardIndex === c.cardIndex),
        ) &&
        ((card.targetNodeId &&
          card.targetNodeId === filteredCards[i].targetNodeId) ||
          (card.skipTargetNodeId &&
            card.skipTargetNodeId === filteredCards[i].skipTargetNodeId))
      ) {
        uniqueExitDotCount -= 1;
        if (positions[i] === undefined) {
          uniqueExitDotCount += 1;
          positions[i] = pos++;
        }
        positions[index] = positions[i];

        if (!mergedLines[i]) {
          mergedLines[i] = [];
        }
        mergedLines[i].push(index);
        return;
      }
    }
  });

  let linePosition = positions[lineIdx];
  if (
    filteredCards[lineIdx].targetNodeId ===
      filteredCards[lineIdx].skipTargetNodeId &&
    isSkipLine
  ) {
    linePosition += 1;
  }

  const linesSpaces = SPACE_BETWEEN_COLUMNS / uniqueExitDotCount;
  return linesSpaces / 2 + linesSpaces * linePosition + DOT_BUTTON_SIZE;
};

export const compute = (
  builderLayoutClientRect: DOMRect,
  fromCard: CardDataAndElement,
  toCard: CardDataAndElement,
  sourcesCards: CardContainer[],
  column: Column,
  isSkipLine?: boolean,
  isMerged: boolean = true,
) => {
  const fromClientRect = fromCard.element.getBoundingClientRect();
  const toRect = toCard.element.getBoundingClientRect();

  const fromCardY = getFromCardY(fromClientRect, fromCard, isSkipLine);
  const toCardY = getToCardY(
    toRect,
    sourcesCards,
    fromCard,
    fromCardY,
    toCard.cardData.cardIndex,
    column,
    isSkipLine,
    isMerged,
  );

  const lineOffset = getLineXOffset(
    column,
    fromCard,
    fromCardY,
    toCardY,
    isSkipLine,
    isMerged,
  );
  const toCardEndingX = toRect.x;
  const toCardStartingX = toCardEndingX - lineOffset;

  const fromCardStartingX = fromClientRect.width + fromClientRect.x;
  const fromCardEndingX = toCardStartingX;

  const leftPartWidth = fromCardEndingX - fromCardStartingX;
  const height = Math.abs(fromCardY - toCardY) / 2;
  const leftPartHeight = height;
  const leftPartY = fromCardY + window.pageYOffset;
  const leftPartX = fromCardStartingX + window.pageXOffset;

  const rightPartWidth = toCardEndingX - toCardStartingX;
  const rightPartHeight = height;
  const rightPartY = toCardY + window.pageYOffset;
  const rightPartX = toCardStartingX + window.pageXOffset;

  const leftSegment = {
    x: leftPartX - builderLayoutClientRect.x,
    y: leftPartY - builderLayoutClientRect.y,
    width: leftPartWidth,
    height: leftPartHeight,
  };

  const rightSegment = {
    x: rightPartX - builderLayoutClientRect.x,
    y: rightPartY - builderLayoutClientRect.y,
    width: rightPartWidth,
    height: rightPartHeight,
  };

  if (Math.round(fromCardY) === Math.round(toCardY)) {
    return [
      {
        ...createBaseLineStyle(
          leftSegment.width + rightSegment.width,
          0,
          leftSegment.x,
          leftSegment.y - DEFAULT_BORDER_SIZE / 2,
          DEFAULT_BORDER_SIZE,
          isSkipLine,
        ),
        "border-bottom": "none",
        "border-left": "none",
        "border-right": "none",
      },
    ];
  } else if (fromCardY < toCardY) {
    const leftSegmentStyle = {
      ...createBaseLineStyle(
        leftSegment.width,
        leftSegment.height,
        leftSegment.x,
        leftSegment.y - DEFAULT_BORDER_SIZE / 2,
        DEFAULT_BORDER_SIZE,
        isSkipLine,
      ),
      "border-bottom": "none",
      "border-left": "none",
      "border-top-right-radius": `${Math.min(leftSegment.height, 8)}px`,
    };
    const rightSegmentStyle = {
      ...createBaseLineStyle(
        rightSegment.width,
        rightSegment.height,
        rightSegment.x,
        rightSegment.y - rightPartHeight - DEFAULT_BORDER_SIZE / 2,
        DEFAULT_BORDER_SIZE,
        isSkipLine,
      ),
      "border-right": "none",
      "border-top": "none",
      "border-bottom-left-radius": `${Math.min(rightSegment.height, 8)}px`,
    };

    return [leftSegmentStyle, rightSegmentStyle];
  } else {
    const leftSegmentStyle = {
      ...createBaseLineStyle(
        leftSegment.width,
        leftSegment.height,
        leftSegment.x,
        leftSegment.y - leftSegment.height - DEFAULT_BORDER_SIZE / 2,
        DEFAULT_BORDER_SIZE,
        isSkipLine,
      ),
      "border-top": "none",
      "border-left": "none",
      "border-bottom-right-radius": `${Math.min(leftSegment.height, 8)}px`,
    };
    const rightSegmentStyle = {
      ...createBaseLineStyle(
        rightSegment.width,
        rightSegment.height,
        rightSegment.x,
        rightSegment.y - DEFAULT_BORDER_SIZE / 2,
        DEFAULT_BORDER_SIZE,
        isSkipLine,
      ),
      "border-right": "none",
      "border-bottom": "none",
      "border-top-left-radius": `${Math.min(rightSegment.height, 8)}px`,
    };

    return [leftSegmentStyle, rightSegmentStyle];
  }
};

const px = (value: number) => `${value}px`;
const createBaseLineStyle = (
  width: number,
  height: number,
  leftAsX: number,
  topAsY: number,
  borderSize: number,
  isSkipLine: boolean,
) => ({
  position: "absolute",
  width: px(width),
  height: px(height),
  left: px(leftAsX),
  top: px(topAsY),
  background: "transparent",
  border: `${isSkipLine ? "dotted" : "solid"} ${borderSize}px #C7C7CF`,
  "box-sizing": "initial",
});
