import { Injectable } from "@angular/core";

import { ScreebApiHelper } from "helpers/screeb-api.helper";
import {
  ChannelClientScreen,
  ChannelClientVersion,
} from "models/channel-screen.model";
import { Channel, ChannelType } from "./channel.model";

@Injectable()
export class ChannelDao {
  private channelsByOrgId: { [orgId: string]: Channel[] } = {};

  constructor(private screebApiHelper: ScreebApiHelper) {}

  public getAllByOrgId(orgId: string): Promise<Channel[]> {
    if (this.channelsByOrgId[orgId]) {
      return Promise.resolve(this.channelsByOrgId[orgId]);
    }

    return this.screebApiHelper
      .get<Channel[]>(`/org/${orgId}/channel`)
      .toPromise()
      .then((data: object[]) => {
        this.channelsByOrgId[orgId] = data.map((c: object) =>
          new Channel().fromJson(c),
        );
        return this.channelsByOrgId[orgId];
      });
  }

  public getById(orgId: string, id: string): Promise<Channel> {
    if (this.channelsByOrgId[orgId]) {
      const channel = this.channelsByOrgId[orgId].find((c) => c.id === id);
      if (channel) {
        return Promise.resolve(channel);
      }
    }

    return this.screebApiHelper
      .get<Channel[]>(`/org/${orgId}/channel/${id}`)
      .toPromise()
      .then((data: object) => {
        return new Channel().fromJson(data);
      });
  }

  public getChannelClientScreensByWorkspaceId(
    orgId: string,
  ): Promise<ChannelClientScreen[]> {
    return this.screebApiHelper
      .get<ChannelClientScreen[]>(`/org/${orgId}/client/screen`)
      .toPromise()
      .then((data: object) => {
        if (Array.isArray(data)) {
          return data;
        }
        throw Error("Unexpected channel screens properties");
      });
  }

  public getChannelClientScreenVersionsByWorkspaceId(
    orgId: string,
  ): Promise<ChannelClientVersion[]> {
    return this.screebApiHelper
      .get<ChannelClientVersion[]>(`/org/${orgId}/client/version`)
      .toPromise()
      .then((data: object) => {
        if (Array.isArray(data)) {
          return data;
        }
        throw Error("Unexpected channel screens properties");
      });
  }

  public getChannelClientMaxSDKVersionByChannelId(
    orgId: string,
    id: string,
  ): Promise<string> {
    return this.screebApiHelper
      .get<ChannelClientVersion[]>(
        `/org/${orgId}/channel/${id}/client/sdk-version`,
      )
      .toPromise()
      .then((data: object) => {
        return data["sdk_version"];
      });
  }

  public updateChannelClientScreenHidden(
    orgId: string,
    channelId: string,
    ids: string[],
    hidden: boolean,
  ): Promise<any> {
    return this.screebApiHelper
      .put(
        `/org/${orgId}/channel/${channelId}/client/screen/${ids.join(",")}`,
        { hidden },
      )
      .toPromise();
  }

  public create(
    orgId: string,
    type: ChannelType,
    name: string,
  ): Promise<Channel> {
    const body = { type, name };
    return this.screebApiHelper
      .post<Channel[]>(`/org/${orgId}/channel`, body)
      .toPromise()
      .then((data: object) => {
        return new Channel().fromJson(data);
      });
  }
}
