import {
    ChangeDetectionStrategy,
    Component,
    Input,
    TemplateRef,
} from "@angular/core";

@Component({
  selector: "read-more",
  templateUrl: "./read-more.component.html",
  styleUrls: ["./read-more.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReadMoreComponent {
  @Input()
  public header: TemplateRef<any> | string;

  @Input()
  public content: TemplateRef<any> | string;

  @Input()
  public closedHeight: number = 100;

  @Input()
  public loading: boolean = false;

  public readModeOpen = false;

  constructor() {}
}
