import { Component, Input } from "@angular/core";
import {
  messageNodeTypesSettings,
  surveyNodeTypesSettings,
} from "models/survey.dao.types";
import { CTAType } from "models/survey.dao.types";

@Component({
  selector: "card-type",
  templateUrl: "./CardType.component.html",
  styleUrls: ["./CardType.component.scss"],
})
export class CardTypeComponent {
  @Input() letter: string;
  @Input() questionType: CTAType;
  @Input() hasLetter: boolean = true;
  @Input() vertical: boolean = true;

  public get backgroundColor(): string {
    const color = (
      surveyNodeTypesSettings[this.questionType] ??
      messageNodeTypesSettings[this.questionType]
    )?.colors?.[0];
    if (color?.indexOf("--") === 0) {
      return getComputedStyle(document.body).getPropertyValue(color);
    }
    return color;
  }

  public get textColor(): string {
    const color = (
      surveyNodeTypesSettings[this.questionType] ??
      messageNodeTypesSettings[this.questionType]
    )?.colors?.[1];
    if (color?.indexOf("--") === 0) {
      return getComputedStyle(document.body).getPropertyValue(color);
    }
    return color;
  }
}
