import { Injectable } from "@angular/core";

import { ScreebApiHelper } from "helpers/screeb-api.helper";
import {
  adaptRegistryEntry,
  RegistryEntry,
  RegistryEntrySubject,
} from "./registry.model";

@Injectable()
export class RegistryDao {
  constructor(private screebApiHelper: ScreebApiHelper) {}

  public getIdentityProperties(
    orgId: string,
    allLocations = true,
  ): Promise<RegistryEntry[]> {
    return this.screebApiHelper
      .get(`/org/${orgId}/registry?include_subjects=identity.property`)
      .toPromise()
      .then((data: object) => {
        if (Array.isArray(data)) {
          return data
            .map(adaptRegistryEntry)
            .filter(({ location }) => location === "screeb" || allLocations);
        }
      });
  }

  public getGroups(
    orgId: string,
    allLocations = true,
  ): Promise<{ group_types: RegistryEntry[]; groups: RegistryEntry[] }> {
    return this.screebApiHelper
      .get(
        `/org/${orgId}/registry?include_subjects=group_type,group&with_parents=true`,
      )
      .toPromise()
      .then((data: object) => {
        if (Array.isArray(data)) {
          return data
            .map(adaptRegistryEntry)
            .filter(({ location }) => location === "screeb" || allLocations);
        }
      })
      .then((registryEntries: RegistryEntry[]) => {
        return {
          group_types: registryEntries.filter(
            (re) => re.subject === "group_type",
          ),
          groups: registryEntries.filter((re) => re.subject === "group"),
        };
      });
  }

  public getEvents(
    orgId: string,
    allLocations = true,
  ): Promise<RegistryEntry[]> {
    return this.screebApiHelper
      .get(`/org/${orgId}/registry?include_subjects=track,screen`)
      .toPromise()
      .then((data: object) => {
        if (Array.isArray(data)) {
          return data
            .map(adaptRegistryEntry)
            .filter(({ location }) => location === "screeb" || allLocations);
        }
      });
  }

  public getTracks(
    orgId: string,
    allLocations = true,
  ): Promise<RegistryEntry[]> {
    return this.screebApiHelper
      .get(`/org/${orgId}/registry?include_subjects=track`)
      .toPromise()
      .then((data: object) => {
        if (Array.isArray(data)) {
          return data
            .map(adaptRegistryEntry)
            .filter(({ location }) => location === "screeb" || allLocations);
        }
      });
  }

  public getScreens(
    orgId: string,
    allLocations = false,
  ): Promise<RegistryEntry[]> {
    return this.screebApiHelper
      .get(`/org/${orgId}/registry?include_subjects=screen`)
      .toPromise()
      .then((data: object) => {
        if (Array.isArray(data)) {
          return data
            .map(adaptRegistryEntry)
            .filter(({ location }) => location === "screeb" || allLocations);
        }
      });
  }

  public getBySubjectAndSlug(
    orgId: string,
    slug: string,
    includeSubject?: RegistryEntrySubject[] | null,
    excludeSubject?: RegistryEntrySubject[] | null,
  ): Promise<RegistryEntry[]> {
    includeSubject = includeSubject ?? [];
    excludeSubject = excludeSubject ?? [];

    const _includeSubject = includeSubject.join(",");
    const _excludeSubject = excludeSubject.join(",");

    return this.screebApiHelper
      .get(
        `/org/${orgId}/registry?include_subjects=${_includeSubject}&exclude_subjects=${_excludeSubject}&slug=${slug}`,
      )
      .toPromise()
      .then((data: object) => {
        if (Array.isArray(data)) {
          return data.map(adaptRegistryEntry);
        }
      });
  }

  public getByParentIdAndSlug(
    orgId: string,
    parentId: string,
    slug: string,
  ): Promise<RegistryEntry[]> {
    return this.screebApiHelper
      .get(`/org/${orgId}/registry?parent_id=${parentId}&slug=${slug}`)
      .toPromise()
      .then((data: object) => {
        if (Array.isArray(data)) {
          return data.map(adaptRegistryEntry);
        }
      });
  }

  public updateFavorite(
    orgId: string,
    ids: string[],
    favorite: boolean,
  ): Promise<any> {
    return this.screebApiHelper
      .put(`/org/${orgId}/registry/${ids.join(",")}`, { favorite })
      .toPromise();
  }

  public updateHidden(
    orgId: string,
    ids: string[],
    hidden: boolean,
  ): Promise<any> {
    return this.screebApiHelper
      .put(`/org/${orgId}/registry/${ids.join(",")}`, { hidden })
      .toPromise();
  }

  public remove(orgId: string, ids: string[]): Promise<any> {
    return this.screebApiHelper
      .delete(`/org/${orgId}/registry/${ids.join(",")}`)
      .toPromise();
  }
}
