import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { OrgDao } from "models/org.dao";
import { TargetingRule } from "models/targeting-rule.model";
import { ApplicationLoadService } from "services/app.service";

@Injectable()
export class OrgTargetingRulesResolver implements Resolve<any> {
  constructor(
    private applicationLoadService: ApplicationLoadService,
    private orgDao: OrgDao,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Promise<TargetingRule[]> {
    return this.applicationLoadService
      .loader()
      .then(() => {
        return this.orgDao.getAllTargetingRulesByOrgId(route.params["org_id"]);
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        throw err;
      });
  }
}
