<div class="modal-container">
  <div class="release-onboarding-modal">
    <div class="modal-header">
      <h1>Add your first Release</h1>
      <div class="onboarding-logo">
        <div
          class="circle"
          style="background-color: #f1167226; transform: rotate(-10.45deg)"
        >
          🎯
        </div>
        <div
          class="circle big abs"
          style="background-color: var(--screeb-color-warning-outline)"
        >
          🚀️
        </div>
        <div
          class="circle right"
          style="background-color: #5e21f126; transform: rotate(8.96deg)"
        >
          📱
        </div>
      </div>
    </div>
    <div class="modal-content">
      <p>
        Add your First Release to Screeb and start measuring the impact of your
        new features and your users. Track every new release and see how they
        make all your metrics move.
      </p>
      <p>
        You want to add Screeb Releases to your deployment workflow?
        <a
          href="https://developers.screeb.app/api-and-connectors/releases-api"
          target="_blank"
        >
          <u>Check out our API!</u>
        </a>
      </p>
    </div>
    <div class="modal-footer">
      <button
        *ngIf="'release.create' | hasPermission"
        nz-button
        nzSize="large"
        nzType="primary"
        title="Add your first Release"
        (click)="handleOnboardingClose()"
      >
        <i nz-icon nzType="plus"></i>
        <span>Add your first Release</span>
      </button>
    </div>
  </div>
</div>
