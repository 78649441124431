import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";

import { ApplicationLoadService } from "services/app.service";
import { OrgDao } from "models/org.dao";
import { Org } from "models/org.model";
import { UIService } from "services/ui.service";

@Injectable()
export class OrgResolver implements Resolve<any> {
  constructor(
    private applicationLoadService: ApplicationLoadService,
    private orgDao: OrgDao,
    private uiService: UIService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Promise<Org> {
    return this.applicationLoadService
      .loader()
      .then(() => {
        // Check if we already have the org in the ui service
        const tmp = this.uiService.orgs.find(
          (org) => org.id === route.params["org_id"],
        );
        if (tmp) {
          return tmp;
        }
        return this.orgDao.getById(route.params["org_id"]);
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        throw err;
      });
  }
}
