<!-- new question popin -->
<card-menu-popin
  *ngIf="!!cardMenuPopinOrigin && !showPickStartUrl"
  [origin]="cardMenuPopinOrigin"
  [surveyType]="builderStore.survey?.type ?? 'survey'"
  [isNodeLeaf]="cardMenuPopinIsNodeLeaf"
  [isNodeRoot]="cardMenuPopinIsNodeRoot"
  (onClickOutside)="closeMenu()"
  (onNewNode)="addNode($event)"
  (onGenerateSurvey)="generateSurvey()"
></card-menu-popin>

<checkpoint
  *ngIf="!showPickStartUrl"
  [openOnInit]="openOnInit"
  [messageURL]="messageURL"
  (messageURLChange)="editMessageURL()"
  (surveyChange)="onSurveyTitleTagsChange.emit($event)"
  (scenarioHistoryChange)="onScenarioHistoryChange($event)"
  (scenarioTranslated)="onScenarioTranslated.emit()"
  [isLoading]="isSurveyLoading"
  [surveyTags]="surveyTags"
  [defaultLanguageWarning]="defaultLanguageWarning"
  [currentLanguageWarning]="currentLanguageWarning"
  (defaultLanguageChange)="defaultLanguageChange.emit($event)"
  (currentLanguageChange)="computeLines()"
></checkpoint>
<div class="LineAndButtonContainer" *ngIf="!showPickStartUrl">
  <ng-template #iconTpl>
    <span nz-icon nzType="question-circle-o" style="color: blue"></span>
    &nbsp;&nbsp;
  </ng-template>
  <add-button
    [surveyType]="builderStore.survey?.type ?? 'survey'"
    (onClick)="
      columnsWithCards.length > 0 &&
      (builderStore.survey?.type ?? 'survey') === 'survey'
        ? null
        : internalClick()
    "
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    *ngIf="!builderStore.readOnly"
    nz-popconfirm
    [nzPopconfirmTrigger]="
      columnsWithCards.length > 0 &&
      (builderStore.survey?.type ?? 'survey') === 'survey'
        ? 'click'
        : null
    "
    nzPopconfirmTitle="Add a step at the beginning or end of the flow?"
    [nzIcon]="iconTpl"
    nzPopconfirmPlacement="bottom"
    nzCancelText="Begin"
    nzOkText="End"
    (nzOnCancel)="internalClick(true)"
    (nzOnConfirm)="internalClick()"
  ></add-button>
  <div class="LineContainer">
    <svg
      width="15"
      height="54"
      viewBox="0 0 15 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 27H14"
        stroke="#C7C7CF"
        stroke-linecap="round"
        stroke-linejoin="round"
        *ngIf="!builderStore.readOnly"
      />
      <path
        d="M1 53.5V1"
        stroke="#C7C7CF"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</div>
<div
  #builderLayout
  *ngIf="!showPickStartUrl"
  id="builder-layout"
  [ngClass]="isLoading ? 'loading' : ''"
  (window:resize)="onResize()"
>
  <!-- columns -->
  <ng-container
    *ngFor="
      let column of columnsWithCards;
      index as columnIndex;
      count as columnsCount
    "
  >
    <ng-container *ngIf="isFirstColumnMarginReady || columnIndex === 0">
      <column
        [columnIndex]="columnIndex"
        [marginTop]="firstColumnMargin"
        [hasMargin]="columnIndex > 0"
      >
        <!-- cards of current column -->
        <ng-container
          *ngFor="
            let card of column.cards;
            index as cardIndex;
            count as cardsCount
          "
        >
          <ng-container [ngSwitch]="card.component">
            <ng-container *ngSwitchCase="CardContainerType.BigCard">
              @let isHovered = isCardHovered(card, hoveredCard);
              <big-card
                [type]="card.type"
                [letter]="card.letter"
                [labelType]="card.node.node.question.messages?.[0]?.type"
                [label]="card.label"
                [question]="card.node.node.question"
                [subLabel]="card.subLabel"
                [multiple]="card.multiple"
                [pathCount]="card.pathCountPercent"
                [skipPathCount]="card.skipPathCountPercent"
                [haveNext]="!!card.targetNodeId"
                [haveSkip]="!!card.skipTargetNodeId"
                [haveError]="
                  !!errorsByNodeIds[builderStore.currentLanguage]?.[
                    card.node.node.id
                  ]
                "
                [isHovered]="isHovered"
                (onActionDotClick)="
                  onActionDotClick($event, card.node, card.action, card.actions)
                "
                (mouseenter)="onCardMouseEnter(card)"
                (mouseleave)="onCardMouseLeave(card)"
                (onClick)="editNode(card.node)"
                (onSizeUpdate)="
                  updateCardsBoundingClientRect(
                    columnIndex,
                    cardIndex,
                    $event,
                    card
                  )
                "
              ></big-card>
            </ng-container>
            <ng-container *ngSwitchCase="CardContainerType.SmallCard">
              <small-card
                [emoji]="card.emoji"
                [label]="card.label"
                [multiple]="card.multiple"
                [pathCount]="card.pathCountPercent"
                [skipPathCount]="card.skipPathCountPercent"
                [haveNext]="!!card.targetNodeId || card.multiple"
                [haveSkip]="!!card.skipTargetNodeId"
                [haveError]="
                  !!errorsByNodeIds[builderStore.currentLanguage]?.[
                    card.node.node.id
                  ]
                "
                (onActionDotClick)="
                  onActionDotClick($event, card.node, card.action, card.actions)
                "
                (mouseenter)="onCardMouseEnter(card)"
                (mouseleave)="onCardMouseLeave(card)"
                (onClick)="editNode(card.node)"
                (onSizeUpdate)="
                  updateCardsBoundingClientRect(
                    columnIndex,
                    cardIndex,
                    $event,
                    card
                  )
                "
              ></small-card>
            </ng-container>
            <ng-container *ngSwitchCase="CardContainerType.InvisibleCard">
              @let isHovered = isInvisibleCardHovered(card, hoveredCard);
              <!-- invisible card contains link when lines jump a column -->
              <invisible-card
                [isEven]="card.columnIndex % 2 === 0"
                [isSkipLine]="!!card.skipTargetNodeId"
                [hovered]="isHovered"
                (onSizeUpdate)="
                  updateCardsBoundingClientRect(
                    columnIndex,
                    cardIndex,
                    $event,
                    card
                  )
                "
              ></invisible-card>
            </ng-container>
          </ng-container>
        </ng-container>
      </column>
    </ng-container>
  </ng-container>

  <!-- lines 😱 -->
  <div class="lines--wrapper">
    <ng-container *ngFor="let line of lines">
      @let isHovered = isLineHovered(line, hoveredCard);
      <div
        [class]="isHovered ? 'line line--hovered' : 'line'"
        [style]="line.style"
      ></div>
    </ng-container>
  </div>

  <!-- preview sidebar -->
  <survey-preview
    *ngIf="isPreviewOpened !== null && builderStore.survey.type === 'survey'"
    class="disable-drag-and-drop"
    [surveyId]="builderStore.survey.id"
    [initDistributionId]="isPreviewOpened"
    [lang]="builderStore.currentLanguage"
    (onClose)="onPreviewClosed()"
  ></survey-preview>

  <!-- edit sidebar -->
  <lateral-panel
    *ngIf="isLateralPanelOpen"
    class="disable-drag-and-drop"
    [nodeId]="currentSelectedNodeId"
    (goToPreviousNode)="goToPreviousNode()"
    (goToNextNode)="goToNextNode()"
    (onClose)="closeLateralPanel()"
    (onSave)="onLateralPanelSave.emit($event)"
    (onDelete)="onNodeRemoved.emit($event)"
    (openTagEditorAtIndex)="onOpenTagEditorAtIndex($event)"
  ></lateral-panel>
</div>

<div class="message-preview-container" *ngIf="messageDistribution">
  <div
    class="message-web-url-container"
    *ngIf="messageDistribution?.type === 'widget' && showPickStartUrl"
  >
    <h1>On which page do you want the first message to be displayed?</h1>

    <div class="is-flex">
      <input
        type="text"
        placeholder="Enter website url"
        [ngClass]="{
          invalid: false,
        }"
        [(ngModel)]="messageURL"
        (keyup.enter)="onMessageURLChosen()"
      />
      <button
        nz-button
        nzType="primary"
        nzSize="large"
        [disabled]="!messageURL?.length"
        [nzLoading]="showPickStartUrlLoading"
        (click)="onMessageURLChosen()"
      >
        Choose
      </button>
    </div>
    <p class="url-tip">You will manage who see it in the next step.</p>

    <ng-container *ngIf="currentURLs?.length">
      <div class="recently-used-pages">
        <h4>Your recently used pages</h4>
        <div
          class="recently-used-page"
          *ngFor="let url of currentURLs"
          (click)="onMessageURLChosen(url)"
        >
          <div class="url" [title]="url">{{ url }}</div>
          <screeb-icon size="sm" icon="bottom-arrow"></screeb-icon>
        </div>
      </div>
    </ng-container>
  </div>
  <div
    class="message-web-url-container"
    *ngIf="
      !builderStore.readOnly &&
      (messageDistribution?.type === 'ios' ||
        messageDistribution?.type === 'android')
    "
  >
    <h1>Scan the QR code with a phone having the app installed 📱</h1>
    <img [src]="qrCode" alt="QR code" class="qr-code" />

    <p>
      Using a simulator? You can also copy the command below and paste it your
      terminal:
    </p>

    <div class="installation-code-container">
      <code class="installation-code" [highlight]="mobileQrCode"></code>
      <button
        class="copy-button"
        nz-button
        nzSize="medium"
        nzType="primary"
        (click)="clipboardCopy(mobileQrCode)"
      >
        Copy
      </button>
    </div>
  </div>
</div>

<!-- fab -->
<div class="actions fab disable-drag-and-drop" *ngIf="!showPickStartUrl">
  <button
    *ngIf="builderStore.survey.type === 'message'"
    nz-button
    [nzLoading]="isSaving"
    nzType="default"
    nzSize="large"
    (click)="onOpenTagEditorAtIndex(0)"
  >
    <i nz-icon nzType="tool" nzTheme="fill"></i>
    Open builder
  </button>
  <div class="preview-group">
    <button
      nz-button
      class="{{ builderStore.survey.type !== 'message' ? 'is-group' : '' }}"
      [nzLoading]="isSaving"
      nzType="primary"
      nzSize="large"
      [disabled]="
        !columnsWithCards.length && builderStore.survey.type !== 'message'
      "
      (click)="onPreviewRequested()"
    >
      <i nz-icon nzType="caret-right"></i>
      Preview
    </button>
    <ng-template #defaultTemplate let-selected></ng-template>
    <nz-select
      *ngIf="builderStore.survey.type !== 'message'"
      [ngModel]="distributionId"
      nzSize="large"
      class="interaction"
      [nzPlacement]="innerWidth < 1200 ? 'topRight' : 'bottomRight'"
      [nzCustomTemplate]="defaultTemplate"
      [nzDropdownMatchSelectWidth]="false"
      (ngModelChange)="onDistributionChange($event)"
      (nzFocus)="distributionId = null"
      (nzBlur)="distributionId = null"
    >
      <ng-container *ngFor="let distribution of distributions; let i = index">
        <ng-container
          *ngIf="
            !distribution.interaction ||
            distribution.integration !== 'feedback_button'
          "
        >
          <nz-option
            *ngIf="
              i > 0 &&
              !distributions[i].interaction &&
              distributions[i - 1].interaction
            "
            [nzDisabled]="true"
            nzLabel="Legacy channels"
          ></nz-option>
          <nz-option
            [nzValue]="distribution.id"
            nzCustomContent
            [nzLabel]="distribution.title"
          >
            <label class="interaction-button">
              <screeb-icon
                *ngIf="distribution.interaction === 'in_app'"
                size="md"
                icon="desktop"
              ></screeb-icon>
              <screeb-icon
                *ngIf="distribution.interaction === 'in_page'"
                size="md"
                icon="overview"
              ></screeb-icon>
              <screeb-icon
                *ngIf="distribution.interaction === 'email'"
                size="md"
                icon="email"
              ></screeb-icon>
              <screeb-icon
                *ngIf="distribution.interaction === 'feedback_button'"
                size="md"
                icon="bye"
              ></screeb-icon>
              <screeb-icon
                *ngIf="distribution.interaction === 'link'"
                size="md"
                icon="link"
              ></screeb-icon>
              <screeb-icon
                *ngIf="
                  !distribution.interaction && distribution.type === 'widget'
                "
                size="md"
                icon="desktop"
              ></screeb-icon>
              <screeb-icon
                *ngIf="
                  !distribution.interaction && distribution.type === 'android'
                "
                size="sm"
                icon="android"
              ></screeb-icon>
              <screeb-icon
                *ngIf="!distribution.interaction && distribution.type === 'ios'"
                size="sm"
                icon="apple"
              ></screeb-icon>
              <screeb-icon
                *ngIf="
                  !distribution.interaction &&
                  distribution.type === 'hosted-page'
                "
                size="sm"
                icon="link"
              ></screeb-icon>
              {{ distribution.title }}
            </label>
          </nz-option>
        </ng-container>
      </ng-container>
    </nz-select>
  </div>
</div>

<div *ngIf="(errorsByNodeIds | keyvalue).length > 0" class="errors-marker">
  <div *ngIf="showErrorList" class="errors-list">
    <ng-container
      *ngFor="let langErr of errorsByNodeIds | keyvalue; index as langIdx"
    >
      <div class="lang-header" (click)="onSurveyLanguageChange(langErr.key)">
        @let lang = getSurveyLanguageWithEmoji(langErr.key);
        <sanitized-message-component
          class="icon"
          [content]="lang.emoji"
          [options]="{ emojiTranscoder: true }"
        ></sanitized-message-component>
        <span class="label">{{ lang.label }}</span>
      </div>

      <ng-container
        *ngFor="let cardErr of langErr.value | keyvalue; index as idx"
      >
        <ng-container *ngFor="let err of cardErr.value; index as errIdx">
          <div class="separator" *ngIf="idx > 0 || errIdx > 0"></div>
          <div
            class="error-item"
            (click)="
              onSurveyLanguageChange(langErr.key); editNodeByNodeId(cardErr.key)
            "
            (mouseenter)="hoverCardError(cardErr.key)"
            (mouseleave)="hoverCardError(null)"
          >
            <span class="title">
              <card-type
                [letter]="err.label"
                [questionType]="err.questionType"
                [vertical]="false"
              ></card-type>
            </span>
            <ul>
              <li *ngFor="let error of err.errors">{{ error }}</li>
            </ul>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
  <div class="errors-indicator">
    <button
      nz-button
      nzType="primary"
      nzSize="large"
      class="errors-btn"
      (click)="showErrorList = !showErrorList"
    >
      <screeb-icon size="sm" icon="warning"></screeb-icon>
    </button>
    <div *ngIf="!showErrorList" class="errors-count">{{ errorsCount }}</div>
  </div>
</div>

<!-- @TODO: show this again -->
<a
  *ngIf="!builderStore.readOnly"
  target="_blank"
  class="how-to-button disable-drag-and-drop"
  rel="noopener"
  href="https://screeb.app/blog/how-to-get-a-great-response-rate-for-your-in-app-surveys-the-complete-guide"
  hidden
>
  💡&nbsp;
  <span>How to design your survey to</span>
  &nbsp;
  <strong>get more responses?</strong>
  &nbsp;
  <span>Read our guide here!</span>
</a>

<div
  class="progress-bar"
  *ngIf="!isLateralPanelOpen && uploadState.state === 'IN_PROGRESS'"
>
  <nz-progress class="upload-progress" [nzPercent]="uploadState.progress" />
  <screeb-icon
    class="cancel-btn"
    size="sm"
    icon="cross-circle"
    (click)="onVideoUploadCancel()"
  />
</div>
