import { Component, Input } from "@angular/core";
import { OrgBilling } from "models/org_billing.model";

@Component({
  selector: "billing-subscription-paused-subscription-alert",
  template: `
    <div
      *ngIf="orgBilling.billing_status === 'paused'"
      class="payment-issue-banner"
    >
      <screeb-icon icon="warning" size="sm" />
      <span class="payment-issue-title">
        Your subscription has been paused.
        <br />
        <small>Please check your payment methods or unpaid invoices.</small>
      </span>

      <intercom-button title="Talk to us!" />
    </div>
  `,
  styles: [
    `
      .payment-issue-banner {
        display: flex;
        align-items: center;
        background-color: #ffeef1;
        border: 2px solid #ff4d61;
        justify-content: center;
        gap: 16px;
        border-radius: 16px;
        width: 100%;
        min-height: 72px;
        padding: 16px 32px;
        animation: shadeAppear 200ms;
        margin-bottom: 32px;
      }

      screeb-icon {
        color: #ff4d61;
        height: 24px !important;
        width: 24px !important;

        ::ng-deep svg {
          height: 24px !important;
          width: 24px !important;
        }
      }

      .payment-issue-title {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: normal;
        color: #303140;
      }

      @keyframes shadeAppear {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }
    `,
  ],
})
export class SettingsBillingSubscriptionPausedSubscriptionAlertComponent {
  @Input() orgBilling: OrgBilling;

  constructor() {}
}
