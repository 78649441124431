<nz-tabset class="installation-tabs" [(nzSelectedIndex)]="selectedTab">
  <nz-tab [nzTitle]="tab.title" *ngFor="let tab of codeBlocks">
    <div class="installation-options" *ngIf="!!tab.options">
      <ng-container *ngFor="let option of tab.options; let idx = index">
        <ng-container *ngIf="!!option">
          <brand-card
            class="option-card"
            [title]="option.title"
            folder="tech"
            [icon]="option.icon"
            [color]="option.color"
            [active]="selectedOption === idx"
            (click)="setSelectedOption(idx)"
          />
        </ng-container>
      </ng-container>
    </div>

    <ng-container
      *ngTemplateOutlet="
        InstallStepsTpl;
        context: { steps: getOptionsSteps(tab) }
      "
    ></ng-container>
    <div class="actions">
      <button
        *ngIf="verifyInstall"
        nz-button
        [nzType]="installationStatus === 'not-checked' ? 'default' : 'primary'"
        nzSize="large"
        [ngClass]="installationStatus"
        [nzLoading]="verifyInstallationLoading"
        (click)="verifyInstallation()"
      >
        <span
          *ngIf="
            installationStatus === 'not-checked' && !verifyInstallationLoading
          "
        >
          <i nz-icon nzType="search" nzTheme="outline"></i>
          &nbsp;
          <span>Verify installation</span>
        </span>
        <span *ngIf="verifyInstallationLoading">
          <span>Checking Screeb installation</span>
        </span>
        <span *ngIf="installationStatus === 'succeeded'">
          <svg
            style="transform: translateY(3px)"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13.7071 4.29289C14.0976 4.68342 14.0976 5.31658 13.7071 5.70711L7.70711 11.7071C7.51957 11.8946 7.26522 12 7 12C6.73478 12 6.48043 11.8946 6.29289 11.7071L2.29289 7.70711C1.90237 7.31658 1.90237 6.68342 2.29289 6.29289C2.68342 5.90237 3.31658 5.90237 3.70711 6.29289L7 9.58579L12.2929 4.29289C12.6834 3.90237 13.3166 3.90237 13.7071 4.29289Z"
              fill="white"
            />
          </svg>
          &nbsp;
          <span>Screeb is installed in your app 🎉</span>
        </span>
        <span *ngIf="installationStatus === 'failed'">
          <i nz-icon nzType="undo" nzTheme="outline"></i>
          &nbsp;
          <span>Screeb is not installed! Retry.</span>
        </span>
      </button>

      <ng-container *ngIf="isOnboarding">
        <a class="do-it-later" (click)="onSkip()">I'll do it later</a>
        <button
          nz-button
          nzType="primary"
          nzSize="large"
          class="done"
          (click)="!!tab.showNext ? onNext() : onDone()"
        >
          <screeb-icon size="sm" icon="arrow-right" />
          &nbsp;
          <span>{{ !!tab.showNext ? "Next Step" : "It's done!" }}</span>
        </button>
      </ng-container>
    </div>
  </nz-tab>

  <nz-tab nzTitle="Ask a colleague">
    <span class="installation-tip">
      We will send an email to the colleague of your choice, with Need help in
      setting up the Screeb SDK? We will send the instructions by email to the
      colleague of your choice.
    </span>

    <form
      class="ask-email-container"
      nz-form
      [nzAutoTips]="autoTips"
      [formGroup]="validateForm"
      (ngSubmit)="sendEmail()"
    >
      <nz-form-item>
        <nz-form-label nzFor="email">Email</nz-form-label>
        <nz-form-control>
          <nz-input-group class="ask-email-input">
            @let companyDomain =
              getCompanyDomain(sessionService.session?.email);
            <input
              id="email"
              type="email"
              nz-input
              nzHasFeedback
              formControlName="email"
              placeholder="my-lovely-dev@{{ companyDomain }}"
            />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>

      <div class="ask-email-actions">
        <button
          nz-button
          nzSize="large"
          [nzLoading]="emailLoading"
          [nzType]="emailSent ? 'primary' : 'default'"
          [disabled]="emailSent"
        >
          <span *ngIf="emailSent">Email sent!</span>
          <span *ngIf="!emailSent">Send email</span>
        </button>
      </div>
    </form>
  </nz-tab>
</nz-tabset>

<ng-template #InstallStepsTpl let-steps="steps">
  <ng-container *ngIf="steps.length === 1">
    <ng-container
      *ngTemplateOutlet="InstallStepTpl; context: { step: steps[0] }"
    ></ng-container>
  </ng-container>
  <ng-container *ngIf="steps.length > 1">
    <nz-collapse nzAccordion nzExpandIconPosition="right">
      <nz-collapse-panel
        *ngFor="let step of steps; let idx = index"
        [nzHeader]="collapseHeader"
        [nzActive]="idx === selectedStep"
      >
        <ng-template #collapseHeader>
          <div class="collapse-header">
            <span class="idx-bullet">{{ idx + 1 }}</span>
            <span>{{ step.title }}</span>
            <span class="step-info">
              <span *ngIf="step.required !== undefined">
                {{ step.required ? "Required" : "Optional" }} -
              </span>
              <span>About {{ step.estimatedTime }} min</span>
            </span>
          </div>
        </ng-template>

        <upgrade-banner
          *ngIf="!!step.upgrade && !isOnboarding"
          class="upgrade-banner"
          title=""
          [text]="step.upgrade"
          [small]="true"
          [orgId]="org.id"
        ></upgrade-banner>

        <ng-container
          *ngTemplateOutlet="InstallStepTpl; context: { step: step }"
        ></ng-container>
      </nz-collapse-panel>
    </nz-collapse>
  </ng-container>
</ng-template>
<ng-template #InstallStepTpl let-step="step">
  <ng-container *ngFor="let stepInstruction of step.instructions">
    <span
      class="installation-tip"
      [innerHtml]="stepInstruction.instruction"
    ></span>
    <div class="installation-code-container" *ngIf="stepInstruction.code">
      <code
        class="installation-code"
        [highlight]="stepInstruction.code"
        [languages]="step.noHighlight ? [] : undefined"
      ></code>
      <button
        class="copy-button"
        nz-button
        nzSize="medium"
        nzType="primary"
        (click)="clipboardCopy(stepInstruction.code, step.trackerEvent)"
      >
        Copy
      </button>
    </div>
  </ng-container>
</ng-template>
<ng-template #verifyInstallTpl>
  <div class="actions">
    <button
      nz-button
      [nzType]="installationStatus === 'not-checked' ? 'default' : 'primary'"
      nzSize="large"
      [ngClass]="installationStatus"
      [nzLoading]="verifyInstallationLoading"
      (click)="verifyInstallation()"
    >
      <span
        *ngIf="
          installationStatus === 'not-checked' && !verifyInstallationLoading
        "
      >
        <i nz-icon nzType="search" nzTheme="outline"></i>
        &nbsp;
        <span>Verify installation</span>
      </span>
      <span *ngIf="verifyInstallationLoading">
        <span>Checking Screeb installation</span>
      </span>
      <span *ngIf="installationStatus === 'succeeded'">
        <svg
          style="transform: translateY(3px)"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.7071 4.29289C14.0976 4.68342 14.0976 5.31658 13.7071 5.70711L7.70711 11.7071C7.51957 11.8946 7.26522 12 7 12C6.73478 12 6.48043 11.8946 6.29289 11.7071L2.29289 7.70711C1.90237 7.31658 1.90237 6.68342 2.29289 6.29289C2.68342 5.90237 3.31658 5.90237 3.70711 6.29289L7 9.58579L12.2929 4.29289C12.6834 3.90237 13.3166 3.90237 13.7071 4.29289Z"
            fill="white"
          />
        </svg>
        &nbsp;
        <span>Screeb is installed in your app 🎉</span>
      </span>
      <span *ngIf="installationStatus === 'failed'">
        <i nz-icon nzType="undo" nzTheme="outline"></i>
        &nbsp;
        <span>Screeb is not installed! Retry.</span>
      </span>
    </button>

    <a class="do-it-later">I'll do it later</a>
    <button
      nz-button
      nzType="primary"
      nzSize="large"
      class="done"
      (click)="onDone()"
    >
      <screeb-icon size="sm" icon="arrow-right" />
      &nbsp;
      <span>It's done!</span>
    </button>
  </div>
</ng-template>
