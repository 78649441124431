import { Injectable } from "@angular/core";

import { ScreebApiHelper } from "helpers/screeb-api.helper";
import { CTAType, ScenarioNode } from "./survey.dao.types";

@Injectable()
class SurveyScenarioNodeDao {
  constructor(private screebApiHelper: ScreebApiHelper) {}

  public getBySurveyIDAndCTAType(
    orgId: string,
    surveyId: string,
    type: CTAType,
  ): Promise<ScenarioNode[]> {
    return this.screebApiHelper
      .get<{ nodes: ScenarioNode[] }>(
        `/org/${orgId}/survey/${surveyId}/scenario/nodes?cta_type=${type}`,
      )
      .toPromise()
      .then(({ nodes }) => nodes);
  }
}

export { SurveyScenarioNodeDao };
