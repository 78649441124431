import { Component } from "@angular/core";
import { Router } from "@angular/router";

import { ShortcutInput } from "ng-keyboard-shortcuts";

import { AuthService, SessionService } from "services/auth.service";
import { UIService } from "services/ui.service";

@Component({
  selector: "layout-back-keyboard-shortcuts",
  templateUrl: "./keyboard-shortcuts.component.html",
})
export class LayoutBackKeyboardShortcutsComponent {
  public shortcuts: ShortcutInput[] = [
    {
      key: "b",
      label: "Billing",
      description:
        "Manage your subscription, payment methods, invoices and billing details.",
      preventDefault: true,
      command: () => {
        if (this.uiService.currentOrgId)
          this.router.navigate([
            `/org/${this.uiService.currentOrgId}/settings/billing`,
          ]);
      },
    },
    {
      key: "p",
      label: "Analytics",
      description: "User profiles",
      preventDefault: true,
      command: () => {
        if (this.uiService.currentOrgId)
          this.router.navigate([
            `/org/${this.uiService.currentOrgId}/people/respondent`,
          ]);
      },
    },
    {
      key: "g",
      label: "Analytics",
      description: "User segments",
      preventDefault: true,
      command: () => {
        if (this.uiService.currentOrgId)
          this.router.navigate([
            `/org/${this.uiService.currentOrgId}/people/segment`,
          ]);
      },
    },
    {
      key: "s",
      label: "Survey",
      description: "Survey list",
      preventDefault: true,
      command: () => {
        if (this.uiService.currentOrgId)
          this.router.navigate([
            `/org/${this.uiService.currentOrgId}/survey/list`,
          ]);
      },
    },
    {
      key: "n",
      label: "Survey",
      description: "New Survey",
      preventDefault: true,
      command: () => {
        if (this.uiService.currentOrgId)
          this.router.navigate([
            `/org/${this.uiService.currentOrgId}/survey/create`,
          ]);
      },
    },
    {
      key: "1",
      label: "Survey",
      description: "Edit flow",
      preventDefault: true,
      command: () => {
        if (!!this.uiService.currentOrgId && !!this.uiService.currentSurveyId)
          this.router.navigate([
            `/org/${this.uiService.currentOrgId}/survey/${this.uiService.currentSurveyId}/edit`,
          ]);
      },
    },
    // {
    //   key: "2",
    //   label: "Survey",
    //   description: "Settings and design",
    //   preventDefault: true,
    //   command: () => {
    //     if (!!this.uiService.currentOrgId && !!this.uiService.currentSurveyId)
    //       this.router.navigate([
    //         `/org/${this.uiService.currentOrgId}/survey/${this.uiService.currentSurveyId}/settings`,
    //       ]);
    //   },
    // },
    {
      key: "2",
      label: "Survey",
      description: "Share survey",
      preventDefault: true,
      command: () => {
        if (!!this.uiService.currentOrgId && !!this.uiService.currentSurveyId)
          this.router.navigate([
            `/org/${this.uiService.currentOrgId}/survey/${this.uiService.currentSurveyId}/share`,
          ]);
      },
    },
    {
      key: "3",
      label: "Survey",
      description: "Analyze responses",
      preventDefault: true,
      command: () => {
        if (!!this.uiService.currentOrgId && !!this.uiService.currentSurveyId)
          this.router.navigate([
            `/org/${this.uiService.currentOrgId}/survey/${this.uiService.currentSurveyId}/stats`,
          ]);
      },
    },
    {
      key: "r",
      label: "Releases",
      description: "Releases",
      preventDefault: true,
      command: () => {
        if (this.uiService.currentOrgId)
          this.router.navigate([
            `/org/${this.uiService.currentOrgId}/releases`,
          ]);
      },
    },
    {
      key: "i",
      label: "Integrations",
      description: "Integrations",
      preventDefault: true,
      command: () => {
        if (this.uiService.currentOrgId)
          this.router.navigate([
            `/org/${this.uiService.currentOrgId}/integrations`,
          ]);
      },
    },
  ];

  public disabled = true;
  public _document = document;

  constructor(
    public router: Router,
    public authService: AuthService,
    public sessionService: SessionService,
    public uiService: UIService,
  ) {}
}
