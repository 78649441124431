//https://stackoverflow.com/questions/12504042/what-is-a-method-that-can-be-used-to-increment-letters
export function incrementString(value: string): string {
  let carry = 1;
  let res = "";

  for (let i = value.length - 1; i >= 0; i--) {
    let char = value.toUpperCase().charCodeAt(i);

    char += carry;

    if (char > 90) {
      char = 65;
      carry = 1;
    } else {
      carry = 0;
    }

    res = String.fromCharCode(char) + res;

    if (!carry) {
      res = value.substring(0, i) + res;
      break;
    }
  }

  if (carry) {
    res = "A" + res;
  }

  return res;
}

export function asciiSum(value: string) {
  return value.split("").reduce((acc, val) => acc + val.charCodeAt(0), 0);
}

export function unspaceAndLowerCase(value: string) {
  return value.toLowerCase().replace(/\s/g, "");
}

export function searchStringInString(text: string, search: string): boolean {
  return unspaceAndLowerCase(text).includes(unspaceAndLowerCase(search));
}

export function pluralize(value: string, suffix = "s"): string {
  const canPluralize = value.charAt(value.length - 1) !== suffix;
  return `${value}${canPluralize ? suffix : ""}`;
}

export function sprintf(format: string, ...args: any[]): string {
  let index = 0;
  return format.replace(/%[a-zA-Z]/g, (match) => {
    const arg = args[index++];
    switch (match) {
      case "%s":
        return String(arg);
      case "%d":
      case "%i":
      case "%f":
        return Number(arg).toString();
      default:
        return match;
    }
  });
}

export function stringToHash(string: string) {
  let hash = 0;

  if (!string.length) return hash;

  for (let i = 0; i < string.length; i++) {
    const char = string.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }

  return hash;
}
