import { Component } from "@angular/core";
import { TrackersService } from "services/trackers.service";

@Component({
  selector: "intercom",
  template: `
    <a href="mailto:michael@screeb.app" (click)="askUsAQuestion($event)">
      <ng-content></ng-content>
    </a>
  `,
  styles: [
    `
      :host {
        cursor: pointer;
      }

      a {
        display: inherit;
        color: inherit;
        font-style: inherit;
        font-weight: inherit;
        font-size: inherit;
      }
    `,
  ],
})
export class IntercomComponent {
  constructor(private trackersService: TrackersService) {}

  public askUsAQuestion(event: Event) {
    if (this.trackersService.intercomIsBooted()) {
      this.trackersService.intercomOpenConversation();
      event.preventDefault();
    }
  }
}
