import { Serializable } from "models/serializable.model";

class WeeklyBriefing extends Serializable {
  constructor(
    public id?: string,
    public workspace_id?: string,
    public firstname?: string,
    public lastname?: string,
    public email?: string,
    public role?: string,

    public created_at?: Date,
    public updated_at?: Date,
  ) {
    super();
  }

  public copy(): WeeklyBriefing {
    return new WeeklyBriefing().fromJson(JSON.parse(JSON.stringify(this)));
  }
}

export { WeeklyBriefing };
