import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";

import { ApplicationLoadService } from "services/app.service";
import { Sequence } from "models/sequence.types";
import { SequenceDao } from "models/sequence.dao";

@Injectable()
export class SequencesResolver implements Resolve<Sequence[]> {
  constructor(
    private applicationLoadService: ApplicationLoadService,
    private sequenceDao: SequenceDao,
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.applicationLoadService
      .loader()
      .then(() =>
        this.sequenceDao.getByType(
          route.params["org_id"],
          route.data.sequence_type,
        ),
      )
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        throw err;
      });
  }
}
