import { Injectable } from "@angular/core";
import { intervalToDuration, startOfDay } from "date-fns";
import { IntegrationType } from "models/integrations.model";
import { UIService } from "services/ui.service";

type BooleanFeatureKey =
  | "device_tracking"
  | "survey_inapp_desktop"
  | "survey_inapp_mobile"
  | "message_inapp"
  | "workspace_settings_edition"
  | "content_analysis"
  | "advanced_survey_customization"
  | "session_recording"
  | "audio_video_surveys"
  | "response_translation"
  | "iam_mfa"
  | "iam_saml"
  // integration of type "forward"
  | "integration_productboard"
  | "integration_harvestr"
  | "integration_zendesk"
  | "integration_intercom"
  | "integration_trello"
  | "integration_cycle"
  | "integration_monday"
  | "integration_asana"
  // integration of type "cdp"
  | "integration_amplitude_source"
  | "integration_segment_source"
  | "integration_rudderstack_source"
  | "integration_contentsquare_source"
  | "integration_typeform"
  // integration of type "hook"
  | "integration_webhook"
  | "integration_stitch"
  | "integration_salesforce"
  | "integration_zapier"
  | "integration_cobbai"
  | "integration_email"
  | "integration_notion"
  | "integration_atlassian"
  | "integration_amplitude_destination"
  | "integration_segment_destination"
  | "integration_contentsquare_destination"
  | "integration_rudderstack_destination"
  // integration of type "reporting"
  | "integration_slack";

type TieredFeatureKey = "max_workspace";
// "max_mtu";

export type AccountPermissionFeatureKey = "survey_edition" | "message_edition";

export type FeatureKey =
  | BooleanFeatureKey
  | TieredFeatureKey
  | AccountPermissionFeatureKey;

const slugToEntitlement: { [key in IntegrationType]: FeatureKey } = {
  // forward
  productboard: "integration_productboard",
  harvestr: "integration_harvestr",
  zendesk: "integration_zendesk",
  intercom: "integration_intercom",
  trello: "integration_trello",
  cycle: "integration_cycle",
  monday: "integration_monday",
  asana: "integration_asana",

  // cdp
  "amplitude-source": "integration_amplitude_source",
  "segment-source": "integration_segment_source",
  "rudderstack-source": "integration_rudderstack_source",
  "contentsquare-source": "integration_contentsquare_source",
  typeform: "integration_typeform",

  // hook
  webhook: "integration_webhook",
  stitch: "integration_stitch",
  // salesforce: "integration_salesforce",
  zapier: "integration_zapier",
  cobbai: "integration_cobbai",
  email: "integration_email",
  notion: "integration_notion",
  atlassian: "integration_atlassian",
  "amplitude-destination": "integration_amplitude_destination",
  "segment-destination": "integration_segment_destination",
  "contentsquare-destination": "integration_contentsquare_destination",
  "rudderstack-destination": "integration_rudderstack_destination",

  // reporting
  slack: "integration_slack",
};

type EntitlementLabel = {
  illustration: string;
  text: string;
};

export const entitlementToIllustration: {
  [key in FeatureKey]?: EntitlementLabel;
} = {
  // max_segments: {
  //   illustration: "/upgrade/list-of-segments.png",
  //   text: "You’re using Screeb for free 🤗 You can only consult segment. Upgrade now to create as many segments as you want and unlock all our features!",
  // },
};

type DebugFlags = {
  "force-free-trial": boolean;
  "free-plan": boolean;
  "mtu-exceeded": boolean;
};

export const DebugFlagsNames: { [key in keyof DebugFlags]: string } = {
  "force-free-trial": "Free Trial",
  "free-plan": "Free Plan",
  "mtu-exceeded": "MTU Exceeded",
};

@Injectable()
export class EntitlementService {
  public debugFlags: DebugFlags = {} as DebugFlags;

  public get hasDebugMode(): boolean {
    return Object.keys(this.debugFlags).some(
      (key) => this.debugFlags[key as keyof DebugFlags],
    );
  }

  constructor(private uiService: UIService) {
    this.debugFlags = JSON.parse(
      window.localStorage.getItem("x-screeb-debug-mode") || "{}",
    );
  }

  public setDebugFlag(flag: keyof DebugFlags, value: boolean): void {
    this.debugFlags[flag] = value;
    window.localStorage.setItem(
      "x-screeb-debug-mode",
      JSON.stringify(this.debugFlags),
    );
  }

  getMTUUsagePercent() {
    if (this.debugFlags["mtu-exceeded"]) return 100;
    const mtu =
      this.uiService.currentSuperOrg?.stats.current_month_respondents ?? 0;
    const max =
      this.uiService.currentSuperOrg?.entitlements.max_mtu ?? Infinity;
    return Math.min(100, (mtu * 100) / max);
  }

  isMTUExceeded() {
    if (this.debugFlags["mtu-exceeded"]) return true;
    return this.uiService.currentSuperOrg?.mtuExceeded();
  }

  isMTUAlmostReached() {
    return this.getMTUUsagePercent() >= 90;
  }

  isTrialing() {
    if (this.debugFlags["force-free-trial"]) return true;
    if (this.uiService.currentSuperOrg?.flags?.billing_status !== "trial") {
      return false;
    }

    const freeTrialEndDate =
      this.uiService.currentSuperOrg?.flags?.last_trial_end_date;

    if (!freeTrialEndDate) {
      return false;
    }

    return new Date(freeTrialEndDate) >= new Date();
  }

  isFreePlan() {
    if (this.debugFlags["free-plan"]) return true;
    return !this.hasSubscribed() && !this.isTrialing();
  }

  hasSubscribed() {
    return (
      this.uiService.currentSuperOrg?.flags?.billing_status !== "no_billing"
    );
  }

  isHavingPaymentIssue() {
    return this.uiService.currentSuperOrg?.flags?.billing_status === "paused";
  }

  getRemainingFreeTrialDays(): number {
    if (this.debugFlags["force-free-trial"]) return 42;
    if (!this.uiService.currentSuperOrg?.flags?.last_trial_end_date) {
      return 0;
    }

    const lasTrialEndDate = new Date(
      this.uiService.currentSuperOrg?.flags.last_trial_end_date,
    );

    if (lasTrialEndDate < new Date()) {
      return 0;
    }

    return (
      intervalToDuration({
        start: startOfDay(new Date()),
        end: startOfDay(lasTrialEndDate),
      }).days || 0
    );
  }

  isFreeTrialEnded() {
    const freeTrialEndDate =
      this.uiService.currentSuperOrg?.flags?.last_trial_end_date;

    if (!freeTrialEndDate) {
      return false;
    }

    return freeTrialEndDate < new Date();
  }

  timeSinceFreeTrialEnded() {
    if (!this.isFreeTrialEnded()) {
      return null;
    }

    return (
      new Date().getTime() -
      this.uiService.currentSuperOrg?.flags?.last_trial_end_date.getTime()
    );
  }

  isQuotaExceeded(key: TieredFeatureKey, value: number) {
    // TODO(alexis): later we'll want to check on workspace level
    const entitlements = this.uiService.currentSuperOrg?.entitlements;

    if (!entitlements) {
      return false;
    }

    switch (key) {
      case "max_workspace":
        return (
          entitlements.max_workspaces !== -1 &&
          value >= entitlements.max_workspaces
        );
      // case "max_mtu":
      //   return false; // entitlements.max_mtu < value;
      default:
        return false;
    }
  }

  isAvailable(key: FeatureKey): boolean {
    if (this.debugFlags["free-plan"]) return false;

    // TODO(alexis): later we'll want to check on workspace level
    const entitlements = this.uiService.currentSuperOrg?.entitlements;

    if (!entitlements) {
      return false;
    }

    switch (key) {
      case "device_tracking":
        return entitlements.device_tracking_enabled;
      case "survey_inapp_desktop":
        return entitlements.survey_inapp_desktop_enabled;
      case "survey_inapp_mobile":
        return entitlements.survey_inapp_mobile_enabled;
      case "message_inapp":
        return entitlements.message_inapp_enabled;
      case "survey_edition":
        return entitlements.survey_edition_enabled;
      case "message_edition":
        return entitlements.message_edition_enabled;
      case "workspace_settings_edition":
        return entitlements.workspace_settings_edition_enabled;
      case "content_analysis":
        return entitlements.content_analysis_enabled;
      case "advanced_survey_customization":
        return entitlements.advanced_survey_customization_enabled;
      case "session_recording":
        return entitlements.session_recording_enabled;
      case "audio_video_surveys":
        return entitlements.audio_video_surveys_enabled;
      case "response_translation":
        return entitlements.response_translation_enabled;
      case "iam_mfa":
        return entitlements.iam_mfa_enabled;
      case "iam_saml":
        return entitlements.iam_saml_enabled;

      // integrations
      // forward
      case "integration_productboard":
        return Boolean(entitlements.integration_productboard_enabled);
      case "integration_harvestr":
        return Boolean(entitlements.integration_harvestr_enabled);
      case "integration_zendesk":
        return Boolean(entitlements.integration_zendesk_enabled);
      case "integration_intercom":
        return Boolean(entitlements.integration_intercom_enabled);
      case "integration_trello":
        return Boolean(entitlements.integration_trello_enabled);
      case "integration_cycle":
        return Boolean(entitlements.integration_cycle_enabled);
      case "integration_monday":
        return Boolean(entitlements.integration_monday_enabled);
      case "integration_asana":
        return Boolean(entitlements.integration_asana_enabled);

      // cdp
      case "integration_amplitude_source":
        return Boolean(entitlements.integration_amplitude_source_enabled);
      case "integration_segment_source":
        return Boolean(entitlements.integration_segment_source_enabled);
      case "integration_rudderstack_source":
        return Boolean(entitlements.integration_rudderstack_source_enabled);
      case "integration_contentsquare_source":
        return Boolean(entitlements.integration_contentsquare_source_enabled);
      case "integration_typeform":
        return Boolean(entitlements.integration_typeform_enabled);

      // hook
      case "integration_webhook":
        return Boolean(entitlements.integration_webhook_enabled);
      case "integration_stitch":
        return Boolean(entitlements.integration_stitch_enabled);
      case "integration_salesforce":
        return Boolean(entitlements.integration_salesforce_enabled);
      case "integration_zapier":
        return Boolean(entitlements.integration_zapier_enabled);
      case "integration_cobbai":
        return Boolean(entitlements.integration_cobbai_enabled);
      case "integration_email":
        return Boolean(entitlements.integration_email_enabled);
      case "integration_notion":
        return Boolean(entitlements.integration_notion_enabled);
      case "integration_atlassian":
        return Boolean(entitlements.integration_atlassian_enabled);
      case "integration_amplitude_destination":
        return Boolean(entitlements.integration_amplitude_destination_enabled);
      case "integration_segment_destination":
        return Boolean(entitlements.integration_segment_destination_enabled);
      case "integration_contentsquare_destination":
        return Boolean(
          entitlements.integration_contentsquare_destination_enabled,
        );
      case "integration_rudderstack_destination":
        return Boolean(
          entitlements.integration_rudderstack_destination_enabled,
        );

      // reporting
      case "integration_slack":
        return Boolean(entitlements.integration_slack_enabled);
      default:
        return false;
    }
  }

  isIntegrationAvailable(type: IntegrationType): boolean {
    return this.isAvailable(slugToEntitlement[type]);
  }
}
