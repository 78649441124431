<div class="indicator-with-border">
  <div class="indicator-header">
    <h4>User identification</h4>
  </div>
  <div
    class="fullsize-indicator"
    [ngClass]="{
      'not-available': !orgHasRespondents && !isLoading,
    }"
  >
    <donut-indicator
      [data]="respondentIdentificationData"
      [loading]="isLoading"
    ></donut-indicator>
  </div>
</div>
