<span
  class="help"
  nz-popover
  [nzPopoverContent]="PopoverTemplate"
  [nzPopoverPlacement]="placement"
>
  <i nz-icon nzType="question-circle" nzTheme="twotone"></i>
</span>

<ng-template #PopoverTemplate>
  <ng-container [ngTemplateOutlet]="content"></ng-container>
</ng-template>
