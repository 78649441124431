import { Component, Input } from "@angular/core";
import { TargetingRule } from "models/targeting-rule.model";

@Component({
  selector: "rule-recurrence",
  templateUrl: "./rule-recurrence.component.html",
  styleUrls: ["./rule-recurrence.component.scss"],
})
export class TargetingRuleRecurrenceComponent {
  @Input()
  public ruleRecurrence: TargetingRule = null;
  @Input()
  public ruleIfAnswered: TargetingRule = null;

  constructor() {}

  public checkValidValue(): boolean {
    if (
      (this.ruleRecurrence?.value?.v_n === null ||
        this.ruleRecurrence?.value?.v_n === undefined) &&
      (this.ruleIfAnswered?.value?.v_n === null ||
        this.ruleIfAnswered?.value?.v_n === undefined)
    ) {
      return false;
    }
    return true;
  }
}
