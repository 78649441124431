<nz-modal
  [(nzVisible)]="isOpen"
  [nzTitle]="null"
  [nzContent]="modalContent"
  [nzFooter]="null"
  [nzClosable]="false"
  (nzOnCancel)="handleClose()"
  nzWidth="757px"
  nzHeight="633px"
  nzClassName="preview-video-modal"
>
  <ng-template #modalContent>
    <div class="modal-content">
      <div class="crop-options">
        Show crop delimiter
        <nz-switch [(ngModel)]="showCropDelimiter"></nz-switch>
      </div>
      <div class="video-container">
        <video
          #videoPlayer
          preload="auto"
          [src]="videoUrl"
          autoplay
          loop
        ></video>
        <ng-container *ngIf="showCropDelimiter">
          <div
            class="ratio-border left"
            [style]="{
              width: aspectRatioData.margin + 'px',
            }"
          ></div>
          <div
            class="ratio-border right"
            [style]="{
              width: aspectRatioData.margin + 'px',
            }"
          ></div>
        </ng-container>
      </div>
      <div class="actions-container">
        <div class="action-btn" (click)="setPlaying(!isPlaying)">
          <screeb-icon *ngIf="!isPlaying" size="md" icon="play" />
          <screeb-icon *ngIf="isPlaying" size="md" icon="pause" />
        </div>
      </div>
    </div>
  </ng-template>
</nz-modal>
