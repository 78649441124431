/* eslint-disable @angular-eslint/no-output-on-prefix */

import { HttpErrorResponse } from "@angular/common/http";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { autoTips } from "helpers/form-errors.helper";
import { NotificationHelper } from "helpers/notification.helper";
import {
  hookIntegrationsSpec,
  HookType,
  REGEXP_VALIDATION_WEBHOOK,
} from "models/hook.model";
import { IntegrationDao } from "models/integration.dao";
import { IntegrationSettingsWebhookItem } from "models/integrations.model";
import { Org } from "models/org.model";
import { Survey } from "models/survey.model";
import { TrackersService } from "services/trackers.service";

@Component({
  selector: "integration-settings-webhook-edit",
  templateUrl: "./webhook-settings-edit.component.html",
  styleUrls: ["./webhook-settings-edit.component.scss"],
})
export class IntegrationSettingsWebhookEditComponent implements OnInit {
  @Input() public org: Org = null;
  @Input() public survey: Survey = null;
  @Input() public hook: IntegrationSettingsWebhookItem = null;

  @Output() public onSave = new EventEmitter<IntegrationSettingsWebhookItem>();
  @Output() public onCancel = new EventEmitter<unknown>();
  @Output() public onRemove = new EventEmitter<unknown>();

  public validateForm: FormGroup = null;
  public autoTips = autoTips;
  public hooksSpec = hookIntegrationsSpec.webhook.hooksSpec;
  public replaying: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private integrationDao: IntegrationDao,
    private notificationHelper: NotificationHelper,
    private trackersService: TrackersService,
  ) {}

  ngOnInit() {
    const hooksFormGroup = hookIntegrationsSpec.webhook.hooksSpec.reduce(
      (acc, curr) => {
        acc[curr.type] = [this.hook.hooks.includes(curr.type)];
        return acc;
      },
      {},
    );

    this.validateForm = this.formBuilder.group({
      version: [this.hook.version, [Validators.required]],
      name: [this.hook.name, [Validators.required]],
      path: [
        this.hook.path,
        [Validators.required, Validators.pattern(REGEXP_VALIDATION_WEBHOOK)],
      ],
      hooks: this.formBuilder.group(hooksFormGroup, { validators: [] }), // @TODO: add a custom validator that ensure we have at least 1 hook
      // hooks: new FormArray(hooksOptions.map((hook) => new FormControl(false))),
    });

    this.validate();
  }

  private validate() {}

  public save() {
    this.validate();

    if (!this.validateForm.valid) return;

    const hooks = Object.entries(this.validateForm.value["hooks"])
      .filter((pair) => pair[1] === true)
      .map((pair) => pair[0]);
    const item = {
      id: this.hook.id,
      version: this.validateForm.value["version"],
      name: this.validateForm.value["name"],
      path: this.validateForm.value["path"],
      hooks: hooks,
    } as IntegrationSettingsWebhookItem;

    this.onSave.emit(item);
  }

  public cancel() {
    this.onCancel.emit();
  }

  public remove() {
    this.onRemove.emit();
  }

  public sendHookSample(hookType: HookType) {
    this.integrationDao
      .triggerHookSample(
        this.org.id,
        "webhook",
        hookType,
        this.validateForm.value["path"],
        this.validateForm.value["version"],
      )
      .then(() => {
        this.notificationHelper.trigger(
          "A sample has been sent to your webhook!",
          null,
          "success",
        );
      })
      .catch((err) => {
        console.error(err);

        this.notificationHelper.trigger(
          err?.error?.message ?? "Failed to send sample to webhook.",
          null,
          "error",
        );
      });
  }

  public triggerHookReplay(hook: HookType) {
    const params = {
      webhook_endpoint: this.validateForm.value["path"],
      webhook_version: this.validateForm.value["version"],
    };

    this.replaying = true;

    this.integrationDao
      .triggerHookReplay(
        this.org.id,
        "webhook",
        hook,
        this.survey?.id ? [this.survey.id] : [],
        null,
        params,
      )
      .then(() => {
        this.notificationHelper.trigger(
          "A synchronisation of your webhook has been ordered!",
          null,
          "success",
        );

        this.trackersService
          .newEventTrackingBuilder("Integration webhook sync requested")
          .withOrg(this.org)
          .withSurvey(this.survey)
          .withProps(params)
          .build();
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err);

        this.notificationHelper.trigger(
          err?.error?.message ?? "Failed to synchronize to webhook.",
          null,
          "error",
        );
      })
      .finally(() => {
        this.replaying = false;
      });
  }
}
