import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
} from "@angular/router";
import { integrationSpecs, IntegrationType } from "models/integrations.model";
import { ApplicationLoadService } from "services/app.service";
import { SessionService } from "services/auth.service";

@Injectable()
export class IsIntegrationSlugValidGuard
  implements CanActivate, CanActivateChild
{
  constructor(
    private router: Router,
    private applicationLoadService: ApplicationLoadService,
    private sessionService: SessionService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return this.applicationLoadService.loader().then(() => {
      const integrationSlug = route.params[
        "integration_slug"
      ] as IntegrationType;
      if (integrationSpecs[integrationSlug]) {
        return true;
      }
      this.router.navigate(["/404"]);
      return false;
    });
  }

  canActivateChild(route: ActivatedRouteSnapshot): Promise<boolean> {
    return this.canActivate(route);
  }
}
