<section>
  <div *ngIf="integration">
    <h2>Email</h2>
    <form
      nz-form
      [nzAutoTips]="autoTips"
      [formGroup]="validateForm"
      class="login-form"
    >
      <nz-form-item>
        <nz-form-control>
          <nz-input-group>
            <input
              type="email"
              nz-input
              nzHasFeedback
              formControlName="email"
              placeholder="*******@*******.zendesk.com"
            />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
</section>
