import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";

import { PageComponentInterface } from "components/PageComponentInterface";
import { RoutingService } from "services/routing.service";
import { EntitlementService } from "services/entitlement.service";
import { PermissionsService } from "services/permissions.service";
import { NotificationHelper } from "helpers/notification.helper";
import { OrgDao } from "models/org.dao";
import { Org } from "models/org.model";

@Component({
  selector: "page-settings-data-governance",
  templateUrl: "./data-governance.component.html",
  styleUrls: ["./data-governance.component.scss"],
})
export class SettingsDataGovernancePageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Data governance";
  public name = "Settings Data Governance";

  private obs: any = null;

  public org: Org = null;
  public loading: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private orgDao: OrgDao,
    public notificationHelper: NotificationHelper,
    public entitlementService: EntitlementService,
    public permissionsService: PermissionsService,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.obs = this.route.data.subscribe((data) => {
      this.org = data.org;
    });
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  public save() {
    return this.orgDao
      .updateDataGovernance(this.org.id, this.org.data_governance)
      .then((org: Org) => {
        this.notificationHelper.trigger("Saved successfully", null, "success");
        this.org = org;
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        this.notificationHelper.trigger(
          err?.error?.message ?? "Could not save configuration",
          null,
          "error",
        );
      });
  }
}
