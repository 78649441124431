export function Debounce(milliseconds: number) {
  let timeoutRef = null;
  return (_, __, descriptor: any) => {
    const original = descriptor.value;

    descriptor.value = function DebounceInternal() {
      clearTimeout(timeoutRef);
      timeoutRef = setTimeout(() => {
        // eslint-disable-next-line prefer-rest-params
        original.apply(this, arguments);
      }, milliseconds);
    };

    return descriptor;
  };
}
