import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, CanDeactivate } from "@angular/router";
import { isInvalid } from "components/common/customize/tag-settings/component/tag-settings-design/tag-settings-design.component";
import { LayoutBackHeaderService } from "components/layouts/back/header/header.service";
import { PageComponentInterface } from "components/PageComponentInterface";
import { Org } from "models/org.model";
import { SurveyDistribution } from "models/survey-distribution.model";
import { Survey } from "models/survey.model";
import {
  adaptTagSettingsToApi,
  mergeTagSettings,
} from "models/tag-settings.api";
import { TagSettingsDao } from "models/tag-settings.dao";
import { TagSettings } from "models/tag-settings.types";
import { PermissionsService } from "services/permissions.service";
import { RoutingService } from "services/routing.service";

@Component({
  selector: "page-distribution-settings",
  templateUrl: "./distribution-settings.component.html",
  styleUrls: ["./distribution-settings.component.scss"],
})
export class DistributionSettingsPageComponent
  implements
    PageComponentInterface,
    OnInit,
    AfterViewInit,
    OnDestroy,
    CanDeactivate<DistributionSettingsPageComponent>
{
  public title = "Share - Design";
  public name = "Survey Share Design"; // @TODO: rename title (survey vs message)

  @ViewChild("pageActions")
  private pageActionsRef: TemplateRef<any>;

  private obs: any = null;

  public org: Org = null;
  public survey: Survey = null;
  public surveyDistribution: SurveyDistribution = null;

  public saving = false;
  public error: string = null;

  public tagSettings: TagSettings = null;
  public tagSettingsDiff: TagSettings = null;

  public isInvalid = isInvalid;

  constructor(
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private layoutBackHeaderService: LayoutBackHeaderService,
    private tagSettingsDao: TagSettingsDao,
    public permissionsService: PermissionsService,
  ) {}

  ngOnInit(): void {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.obs = this.route.data.subscribe((data) => {
      this.org = data["org"];
      this.survey = data["survey"];
      this.surveyDistribution = data["survey_distribution"];
      this.tagSettings = this.surveyDistribution.settings
        ? mergeTagSettings(
            this.org.settings,
            this.survey.settings,
            this.surveyDistribution.settings,
          )
        : null;
      this.tagSettingsDiff = { ...this.tagSettings };
    });
  }

  getDefaultDistributionSettings() {
    return {
      ...this.org.settings,
      ...this.survey.settings,
    };
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.layoutBackHeaderService.surveyPageActionsRef = this.pageActionsRef;
    });
  }

  ngOnDestroy(): void {
    if (this.obs) {
      this.obs.unsubscribe();
    }
    this.layoutBackHeaderService.surveyPageActionsRef = null;
  }

  specificDesignSwitchChange(activated: boolean) {
    if (activated) {
      this.tagSettings = this.getDefaultDistributionSettings();
    } else {
      this.tagSettings = null;
    }
  }

  public async actionOnSave() {
    this.saving = true;
    this.error = null;
    this.tagSettingsDao
      .updateDistributionTagSettings(
        this.org.id,
        this.survey.id,
        this.surveyDistribution.id,
        adaptTagSettingsToApi(this.tagSettings, "distribution"),
      )
      .then(() => {
        this.tagSettingsDiff = { ...this.tagSettings };
      })
      .catch(() => {
        this.error = "Oops! Something went wrong. Please retry.";
      })
      .finally(() => {
        setTimeout(() => {
          this.saving = false;
        }, 500);
      });
  }

  public hasNotChanged() {
    return (
      JSON.stringify(this.tagSettings || {}) ===
      JSON.stringify(this.tagSettingsDiff)
    );
  }

  saveButtonDisabled() {
    return (
      this.hasNotChanged() ||
      isInvalid(this.tagSettings, this.surveyDistribution.interaction)
    );
  }

  /**
   * Prevent user from navigating away or F5, when something is not saved
   */
  @HostListener("window:beforeunload", ["$event"])
  preventNavigatingAway($event: any) {
    if (this.hasUnsavedData) $event.returnValue = true;
  }

  private get hasUnsavedData(): boolean {
    return !this.saveButtonDisabled();
  }

  canDeactivate(): boolean {
    if (this.hasUnsavedData) {
      return confirm(
        "You have unsaved changes! If you leave, your changes will be lost.",
      );
    }
    return true;
  }
}
