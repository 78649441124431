import { Injectable } from "@angular/core";

import { enUS } from "date-fns/locale";
import { DateLocale, NzI18nService } from "ng-zorro-antd/i18n";

import { AuthService, SessionService } from "services/auth.service";
import { ConfigService } from "./config.service";
import { FeatureFlaggingService } from "./feature-flagging.service";
import { LoggerService } from "./logging.service";
import { TrackersService } from "./trackers.service";

@Injectable()
class ApplicationLoadService {
  public isMaintenance = false;

  constructor(
    private sessionService: SessionService,
    private featureFlaggingService: FeatureFlaggingService,
    private authService: AuthService,
    private loggerService: LoggerService,
    private nzI18nService: NzI18nService,
    private configService: ConfigService,
    private _: TrackersService, // does not delete this, because we need to import it at least once in the app
  ) {}

  public async loader(): Promise<boolean> {
    if (this.configService.loader) {
      return await this.configService.loader;
    }

    this.configService.loader = this.onApplicationLoad()
      .then((isAuth) => {
        console.log("Screeb loaded! 🚀");
        // if (ENV['ENV'] === 'prod')
        //   console.log('%cThis is the place where we\'re supposed to say we are hiring, but we actually don\'t. Follow us on Linkedin: https://www.linkedin.com/company/screebapp/', 'color: #5E21F1; font-weight: bold;');
        return isAuth;
      })
      .catch((err) => {
        console.error("Unable to load Screeb :-(");
        throw err;
      });

    return this.configService.loader;
  }

  private onApplicationLoad(): Promise<boolean> {
    this.loggerService.load();
    return this.recoverSessionOnApplicationLoad();
  }

  private recoverSessionOnApplicationLoad(): Promise<boolean> {
    return this.authService
      .getProfile(false)
      .catch(() => {
        // console.error(err.error);
      })
      .then((): boolean => {
        this.switchLanguage(enUS);
        return this.sessionService.isAuth();
      });
  }

  private switchLanguage(lang: DateLocale) {
    this.nzI18nService.setDateLocale(lang);
  }
}

export { ApplicationLoadService };
