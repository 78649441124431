/* eslint-disable @angular-eslint/no-output-on-prefix */
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { GraphNode } from "components/builder/flow";
import QRCode from "qrcode";

import { CdkOverlayOrigin } from "@angular/cdk/overlay";
import { ActivatedRoute } from "@angular/router";
import { Column } from "components/builder/page-builder.component";
import {
  routingGetNextNodeId,
  routingGetSkipToNodeId,
} from "components/builder/routing";
import { NotificationHelper } from "helpers/notification.helper";
import { SurveyDistributionDao } from "models/survey-distribution.dao";
import {
  DistributionInteractionsFormatted,
  SurveyDistribution,
} from "models/survey-distribution.model";
import { SurveyDao } from "models/survey.dao";
import {
  CTAAction,
  CTAType,
  SurveyLanguages,
  SurveyScenario,
  UUID,
  setI18nVideoLabelTranslation,
} from "models/survey.dao.types";
import { Survey } from "models/survey.model";
import { TagEditorToken } from "models/tag-editor-token.types";
import { TargetingRule, TargetingRuleValue } from "models/targeting-rule.model";
import { ClipboardService } from "ngx-clipboard";
import { Subscription } from "rxjs";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { MediaUploadService, UploadState } from "services/media-upload.service";
import { TrackersService } from "services/trackers.service";
import { UIService } from "services/ui.service";
import { BuilderStore } from "stores/builder.store";
import { Debounce } from "utils/debounce";
import { deepCopy } from "utils/object";
import { LateralPanelSavingPayload } from "../LateralPanel/LateralPanel.component";
import { compute } from "./LinesHelper";
import {
  BigCardContainer,
  CardContainer,
  CardContainerType,
  CardDataAndElement,
  SmallCardContainer,
} from "./Models";

export type NewNodeEvent = {
  type: CTAType;
  node?: GraphNode;
  action?: CTAAction;
  actions?: CTAAction[];
};

export type TagEditorParameters = {
  addAtIndex?: number;
  openAtIndex?: number;
  surveyId?: UUID;
};

type CardError = {
  label: string;
  errors: string[];
  warnings: string[];
  questionType: CTAType;
  // if nodeId is the same as record key it is stored in, then it's a big card, otherwise, it's a small card groupped withing big cards error.
  nodeId?: string;
};

@Component({
  selector: "builder-layout",
  templateUrl: "./BuilderLayout.component.html",
  styleUrls: ["./BuilderLayout.component.scss"],
})
export class BuilderLayoutComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit
{
  @Input()
  public columnsWithCards: Column[];
  @Input()
  public defaultLanguageWarning = false;
  @Input()
  public currentLanguageWarning = false;
  @Input()
  public forceHelpBox = false;
  @Input() public isSurveyLoading = false;

  @Output()
  onLateralPanelSave = new EventEmitter<LateralPanelSavingPayload>();
  @Output() onNodeAdded = new EventEmitter<NewNodeEvent>();
  @Output() onNodeRemoved = new EventEmitter<any>();
  @Output() public defaultLanguageChange: EventEmitter<SurveyLanguages> =
    new EventEmitter();

  @Output() onScenarioHistory = new EventEmitter<void>();
  @Output() onScenarioTranslated = new EventEmitter<void>();
  @Output() onTriggerSave = new EventEmitter<void>();
  @Output() onOpenAiBuilder = new EventEmitter<boolean>();
  @Output() onDisableHelpBox = new EventEmitter<any>();
  @Output() onOpenTagEditor = new EventEmitter<{
    type: "editor" | "preview";
    url: string;
    parameters: TagEditorParameters;
  }>();
  @Output() onSurveyTitleTagsChange = new EventEmitter<Survey>();
  @Output() onErrorChange = new EventEmitter<boolean>();

  @ViewChild(CdkOverlayOrigin) trigger!: any; // CdkOverlayOrigin;

  @ViewChild("builderLayout")
  private builderLayoutElement: ElementRef<HTMLElement>;

  private obs: any = null;

  public isLoading = true;

  public openOnInit = false;
  public hoveredCard: CardContainer | null = null;
  public errorsByNodeIds: Record<
    SurveyLanguages,
    Record<string, Array<CardError>>
  > = {};

  public showErrorList = false;

  public innerWidth: number = window.innerWidth;

  public uploadState: UploadState = { state: "PENDING", progress: 0 };
  public uploadId: string = null;

  // Message stuff
  public messageDistribution: SurveyDistribution | null = null;
  public messageURL: string = null;
  public showPickStartUrl = false;
  public showPickStartUrlLoading = false;
  public currentURLs: string[] = [];
  public qrCode: string = null;
  public mobileQrCode: string = null;

  private subscriptionStart: Subscription;
  private subscriptionProgress: Subscription;
  private subscriptionComplete: Subscription;

  public tagEditorToken: TagEditorToken;

  public surveyTags: string[] = [];

  public distributions: SurveyDistribution[] = [];
  public newDistributions: SurveyDistribution[] = [];
  public legacyDistributions: SurveyDistribution[] = [];
  public distributionId: string = null;
  public distributionsByID: { [key: string]: SurveyDistribution } = {};
  public distributionInteractionsFormatted = DistributionInteractionsFormatted;

  constructor(
    private route: ActivatedRoute,
    public builderStore: BuilderStore,
    public uiService: UIService,
    private mediaUploadService: MediaUploadService,
    private trackersService: TrackersService,
    public featureFlaggingService: FeatureFlaggingService,
    private surveyDistributionDao: SurveyDistributionDao,
    private clipboardService: ClipboardService,
    private notificationHelper: NotificationHelper,
    private surveyDao: SurveyDao,
  ) {}

  ngOnInit() {
    this.obs = this.route.data.subscribe((data) => {
      if (this.columnsWithCards.length === 0) {
        this.openOnInit = true;
        this.isLoading = false;
      }
      this.surveyTags = data["surveyTags"];

      this.newDistributions = this.uiService.currentSurveyDistributions.filter(
        (d) => d.interaction,
      );

      this.legacyDistributions =
        this.uiService.currentSurveyDistributions.filter((d) => !d.interaction);
      this.distributions = [
        ...this.newDistributions,
        ...this.legacyDistributions,
      ];
      this.distributions.forEach((d) => {
        this.distributionsByID[d.id] = d;
      });

      // If message, check which distribution is selected and show the preview
      if (this.builderStore.survey.type === "message") {
        this.tagEditorToken = this.route.snapshot.data["tagEditorToken"];
        const channelId = this.route.snapshot.params["channel_id"];

        // Find first edited distribution or with channel_id if provided
        this.messageDistribution = this.distributions?.find((d) =>
          !d.interaction && channelId?.length
            ? d.channel_id === channelId
            : d.targeting_rules?.some(
                (targeting_rule) =>
                  Number(targeting_rule.created_at) !== Number(d.created_at),
              ),
        );

        const ruleURL = this.messageDistribution?.targeting_rules?.find(
          (r) => r.type === "url",
        );

        this.showPickStartUrl = true;

        if (ruleURL) {
          this.messageURL = ruleURL.value.v_s_arr[0];
          this.showPickStartUrl = false;

          this.builderStore.setMessageUrl(this.messageURL);
        } else if (
          this.messageDistribution.type === "ios" ||
          this.messageDistribution.type === "android"
        ) {
          // Force update targeting rules for ios/android if not present
          // In order to block this message for the correct type as we rely on last updated distribution
          const noDistributionsEditedSoFar = this.distributions.every(
            (distribution) => {
              if (
                Number(distribution.updated_at) !==
                Number(distribution.created_at)
              ) {
                return false;
              }

              return distribution.targeting_rules?.every(
                (targeting_rule) =>
                  Number(targeting_rule.created_at) ===
                  Number(distribution.created_at),
              );
            },
          );

          if (noDistributionsEditedSoFar) {
            this.surveyDistributionDao
              .updateTargetingRules(
                this.uiService.currentOrgId,
                this.uiService.currentSurveyId,
                this.messageDistribution.id,
                this.messageDistribution.targeting_rules,
                null,
              )
              .catch((err) => {
                console.error(err);
              });
          }

          const url = `screeb-${this.messageDistribution.channel_id}://editor?token=${this.tagEditorToken.token}`;
          this.mobileQrCode = `xcrun simctl openurl booted "${url}"`;
          QRCode.toDataURL(url, { scale: 8 })
            .then((qrcode: string) => {
              this.qrCode = qrcode;
            })
            .catch((err) => {
              this.notificationHelper.trigger(
                "An error occurred while generating the QR code",
                null,
                "error",
              );
              console.error(err);
              return null;
            });
        }

        if (this.builderStore.survey.type === "message") {
          this.openOnInit = false;
        }

        // Fetch current urls
        try {
          this.currentURLs = JSON.parse(
            localStorage.getItem(`screeb-message-urls`) || "[]",
          );
        } catch (e) {
          this.currentURLs = [];
          console.error(e);
        }
      }

      this.subscriptionStart = this.mediaUploadService
        .subscribeStart()
        .subscribe((uploadId) => {
          if (uploadId) {
            this.uploadId = uploadId;
            this.subscribeToMediaUploadProgress();
            this.subscribeToMediaUploadComplete();
          }
        });

      // Check if we have ai params in the url
      this.route.queryParams.subscribe((params) => {
        const hasScenarioInLocalStorage = Boolean(
          localStorage.getItem("screeb-restore-scenario"),
        );

        if (params.ai === "true" && !hasScenarioInLocalStorage) {
          this.openOnInit = false;
          this.onOpenAiBuilder.emit(true);
        }
      });
    });
  }

  ngAfterViewInit(): void {
    // Wait for page transition to be over
    setTimeout(() => {
      if (this.openOnInit) {
        this.onActionDotClick(this.trigger);
      }
    }, 200);
  }

  ngOnDestroy() {
    if (this.obs) this.obs.unsubscribe();
    this.onVideoUploadCancel();
    this.subscriptionStart?.unsubscribe();
    this.subscriptionProgress?.unsubscribe();
    this.subscriptionComplete?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      !!changes?.columnsWithCards?.currentValue &&
      changes?.columnsWithCards?.currentValue?.length === 0
    ) {
      this.computeLines();
      this.isLoading = false;
    }

    this.refreshErrors();
  }

  @Debounce(500)
  private refreshErrors() {
    this.errorsByNodeIds = {};

    this.columnsWithCards.forEach((column) => {
      column.cards.forEach((card) => {
        if (
          card.component === CardContainerType.BigCard ||
          card.component === CardContainerType.SmallCard
        ) {
          this.checkCardValidity(card);
        }
      });
    });

    this.onErrorChange.emit(this.errorsCount > 0);
  }

  private checkCardValidity(card: BigCardContainer | SmallCardContainer) {
    this.builderStore.availableLanguages.forEach((lang) => {
      const surveyLang = lang.value as SurveyLanguages;
      const { errors, warnings } = card.validate(
        card.node,
        surveyLang,
        this.builderStore.survey.scenario.default_language,
      );

      const errs = errors.reduce((acc, err) => [...acc, ...err], []);
      const warns = warnings.reduce((acc, warn) => [...acc, ...warn], []);

      if (errs.length === 0) return;

      if (card.component === CardContainerType.BigCard) {
        if (!this.errorsByNodeIds[surveyLang]) {
          this.errorsByNodeIds[surveyLang] = {
            [card.node.id]: [],
          };
        } else if (!this.errorsByNodeIds[surveyLang][card.node.id]) {
          this.errorsByNodeIds[surveyLang][card.node.id] = [];
        }

        this.errorsByNodeIds[surveyLang][card.node.id].push({
          label: card.letter,
          errors: errs,
          warnings: warns,
          nodeId: card.node.id,
          questionType: card.node.questionType,
        });
      } else if (card.component === CardContainerType.SmallCard) {
        const parentCard = card.originCards[0] as BigCardContainer;

        if (!this.errorsByNodeIds[surveyLang]) {
          this.errorsByNodeIds[surveyLang] = {
            [parentCard.node.id]: [],
          };
        } else if (!this.errorsByNodeIds[surveyLang][parentCard.node.id]) {
          this.errorsByNodeIds[surveyLang][parentCard.node.id] = [];
        }

        const count = this.errorsByNodeIds[surveyLang][
          parentCard.node.id
        ].filter((err) => err.nodeId !== parentCard.node.id).length;

        this.errorsByNodeIds[surveyLang][parentCard.node.id].push({
          label: `${parentCard.letter}.${count + 1}`,
          errors: errs,
          warnings: warns,
          nodeId: card.node.id,
          questionType: card.node.questionType,
        });
      }
    });
  }

  public get errorsCount() {
    return Object.values(this.errorsByNodeIds).reduce(
      (acc, errors) =>
        acc + Object.values(errors).reduce((a, b) => a + b.length, 0),
      0,
    );
  }

  private get defaultLangErrorsCount() {
    return Object.values(
      this.errorsByNodeIds[
        this.builderStore.survey.scenario.default_language
      ] || {},
    ).reduce(
      (acc, errors) => acc + errors.reduce((a, b) => a + b.errors.length, 0),
      0,
    );
  }

  public internalClick(atBegin: boolean = false) {
    if (atBegin) {
      this.onActionDotClick(this.trigger);
    } else {
      const reversed = [...this.columnsWithCards].reverse();
      const column = reversed.find(
        (column) =>
          column.cards.filter(
            (card) =>
              card.component === CardContainerType.SmallCard ||
              CardContainerType.BigCard,
          ).length > 0,
      );
      const cards = column?.cards.filter(
        (card) =>
          card.component === CardContainerType.SmallCard ||
          CardContainerType.BigCard,
      );

      if (!cards?.length) {
        this.onActionDotClick(this.trigger);
        return;
      }

      const card = cards[cards.length - 1] as
        | BigCardContainer
        | SmallCardContainer;
      this.onActionDotClick(
        this.trigger,
        card.node,
        card.action,
        (card as BigCardContainer)?.actions,
      );
    }
  }

  public onDistributionChange(distributionId: string) {
    this.distributionId = distributionId;
    this.onPreviewRequested(distributionId);
  }

  public async onScenarioHistoryChange(scenario: SurveyScenario) {
    const newSurvey = new Survey().fromJson(this.builderStore.survey);
    newSurvey.scenario = scenario;
    this.builderStore.setSurvey(newSurvey);
    this.onScenarioHistory.emit();
  }

  public onChangeUrl() {
    this.showPickStartUrl = true;
  }

  private subscribeToMediaUploadProgress() {
    this.subscriptionProgress = this.mediaUploadService
      .subscribeProgress()
      .subscribe((status) => {
        if (!!status && status.state === "IN_PROGRESS") {
          this.uploadState = status;
        } else {
          this.uploadState = { state: "PENDING", progress: 0 };
        }
      });
  }

  private subscribeToMediaUploadComplete() {
    this.subscriptionComplete = this.mediaUploadService
      .subscribeComplete()
      .subscribe((result) => {
        this.subscriptionProgress?.unsubscribe();
        this.subscriptionComplete?.unsubscribe();
        this.uploadState = { state: "PENDING", progress: 0 };
        if (result === null) {
          return;
        }
        this.uploadId = undefined;

        if (this.isLateralPanelOpen) return;
        if (result) {
          const node: GraphNode = this.builderStore.nodesById[result.id];
          if (!node || node.index <= 0) return;

          const clonedNode = deepCopy(node);

          // Message upload stuff is handled in the tag
          if (clonedNode.node.question.type === "survey") {
            // async type change. User uploaded a video for this node,
            // but the lateral panel has been closed before the upload was complete
            if (clonedNode.node.question.messages[0].type === "text") {
              this.builderStore.setNodeQuestionType(clonedNode, "video");
            }

            const nodeType = clonedNode.node.question.messages[0].type;

            // forced to set a timeout before setting the videLabel data,
            // otherwise the value gets override by the setNodeQuestionType result
            setTimeout(() => {
              if (nodeType !== "video") {
                return;
              }

              if (clonedNode.node.question.type === "survey") {
                setI18nVideoLabelTranslation(
                  clonedNode.node.question.messages[0].video,
                  {
                    url: result.public_url,
                    video_id: result.video_id,
                    overlay:
                      clonedNode.node.question.messages[0].video[
                        this.builderStore.currentLanguage
                      ].overlay,
                  },
                  this.builderStore.currentLanguage,
                );
              }

              this.onLateralPanelSave.emit({
                updatedNode: clonedNode,
              });
            }, 100);
          }
        }
      });
  }

  public CardContainerType = CardContainerType;

  public isFirstColumnMarginReady: boolean = false;

  // private areAllCardsMetadataSaved: boolean = false;

  private setCardsAndMetadata(id: string, data: CardDataAndElement) {
    this.cardsAndElements[id] = data;
  }

  private getCardsAndMetadata(id: string): CardDataAndElement | null;
  private getCardsAndMetadata(
    cardIndex: number,
    columnIndex: number,
  ): CardDataAndElement | null;
  private getCardsAndMetadata(
    cardIndexOrId: string | number,
    columnIndex?: number,
  ): CardDataAndElement | null {
    if (typeof cardIndexOrId === "string") {
      return this.cardsAndElements[cardIndexOrId];
    }

    return this.cardsAndElements[`${cardIndexOrId}-${columnIndex}`];
  }

  public onResize() {
    setTimeout(() => {
      this.computeLines();
    }, 200);
  }

  public onCardMouseEnter(card: CardContainer) {
    this.hoveredCard = card;
  }

  public onCardMouseLeave(card: CardContainer) {
    if (this.hoveredCard === card) this.hoveredCard = null;
  }

  /**
   * Cette fonction est appelé à chaque rendu d'une card
   *
   * Coté vue, on ne render que la premiere colonne afin de pouvoir extraire la marge verticale à appliquer aux autres colonnes
   * Lorsque c'est fait, dans le cas où cette fonction est appelée pour la premiere card de la premiere colonne, on calcul la marge et on sauvegarde l'info
   * On force un boolean afin de forcer un refresh de la UI qui va afficher les autres cards.
   *
   * Pour chaque card on sauvegarde sa position et ses metadonnées
   * Pour les BigCard et InvisibleCard on sauvegarde dans une clé basé sur l'index de colonne et de card ainsi que pour l'id.
   * Seul les SmallCard (qui n'ont pas d'id) sont sauvegardés dans une clé basé sur l'index de colonne et card.
   * Seul les SmallCards ne peuvent pas être des target, donc ce n'est pas genant si on ne les retrouve pas basés sur leur ID.
   *
   * Lorsque toutes les cards sont render et que leur position est bien sauvegardé
   */
  public updateCardsBoundingClientRect(
    columnIndex: number,
    cardIndex: number,
    { clientRect, element }: { clientRect: ClientRect; element: HTMLElement },
    cardData: CardContainer,
  ) {
    setTimeout(() => {
      this.isLoading = true;
    }, 0);
    if (columnIndex === 0 && cardIndex === 0) {
      // I Known, chaining 2 setTimeout is bad
      // I have to do this because i want to trigger a second UI refresh when the margin value will be saved
      setTimeout(() => {
        // 100 (0 column margin-top) + 5 (half margin-top of possible first small card) + 8 half of card border radius
        this.firstColumnMargin = 113 + clientRect.height;
        this.isFirstColumnMarginReady = true;
        this.computeLines();
      }, 0);
    }

    const cardAndPositionMetadata = {
      cardData,
      element,
    };

    // SmallCard don't have id, so we have to save metadata based on column and card index
    this.setCardsAndMetadata(
      `${cardData.columnIndex}-${cardData.cardIndex}`,
      cardAndPositionMetadata,
    );

    // BigCard and InvisibleCard are targets, so to draw line we have to find them based on there id
    if (
      cardData.component === CardContainerType.BigCard ||
      cardData.component === CardContainerType.InvisibleCard
    ) {
      this.setCardsAndMetadata(cardData.id, cardAndPositionMetadata);
    }

    setTimeout(() => {
      this.isLoading = false;
    }, 200);
  }

  public computeLines() {
    // Wrap in setTimeout to force a refresh of the UI after computing
    setTimeout(() => {
      const builderLayoutClientRect =
        this.builderLayoutElement?.nativeElement?.getBoundingClientRect();
      if (!builderLayoutClientRect) return;

      const getTargetSources = (nodeId: string) => {
        const sources: CardContainer[] = [];
        this.columnsWithCards.forEach((column) => {
          column.cards.forEach((card) => {
            if (card.targetNodeId === nodeId) {
              sources.push({ ...card, skipTargetNodeId: null });
            }

            if (card.skipTargetNodeId === nodeId) {
              sources.push({ ...card, targetNodeId: null });
            }
          });
        });

        // Sort sources to have card with the biggest index and minimal column index last
        sources.sort((a, b) => {
          if (a.cardIndex === b.cardIndex) {
            return a.columnIndex - b.columnIndex;
          }

          return a.cardIndex - b.cardIndex;
        });
        return sources;
      };

      // Sort cards by cardIndex and column index
      const sortedColumnsCards = this.columnsWithCards.map((column) => {
        const cards = [...column.cards];
        cards.sort((a, b) => {
          if (a.cardIndex === b.cardIndex) {
            return a.columnIndex - b.columnIndex;
          }

          return a.cardIndex - b.cardIndex;
        });
        return { ...column, cards };
      });

      this.lines = this.columnsWithCards.reduce((acc, column) => {
        const cardsWithTarget = column.cards.filter(
          (card) => card.targetNodeId || card.skipTargetNodeId,
        );

        // Group cards by targetNodeId or skipTargetNodeId
        const cardsGroupedByTargetNodeId = Object.values(
          cardsWithTarget.reduce<Record<string, CardContainer[]>>(
            (acc, card) => {
              if (card.targetNodeId) {
                if (!acc[card.targetNodeId]) {
                  acc[card.targetNodeId] = [];
                }
                acc[card.targetNodeId].push(card);
              } else if (card.skipTargetNodeId) {
                if (!acc[card.skipTargetNodeId]) {
                  acc[card.skipTargetNodeId] = [];
                }
                acc[card.skipTargetNodeId].push(card);
              }
              return acc;
            },
            {},
          ),
        );

        cardsGroupedByTargetNodeId.forEach((cardsForTarget) => {
          cardsForTarget.forEach((card) => {
            const fromCard = this.getCardsAndMetadata(
              card.columnIndex,
              card.cardIndex,
            );

            if (!fromCard?.element) {
              return;
            }

            if (card.targetNodeId && fromCard.cardData.targetNodeId) {
              const toCard = this.getCardsAndMetadata(card.targetNodeId);

              if (toCard?.element) {
                const lines = compute(
                  builderLayoutClientRect,
                  fromCard,
                  toCard,
                  getTargetSources(card.targetNodeId),
                  sortedColumnsCards[card.columnIndex],
                );

                let originCards = [card];
                if (card.component === CardContainerType.InvisibleCard) {
                  originCards = card.originCards;
                }

                acc.push(
                  ...lines.map((line) => ({
                    style: line,
                    indexes: originCards.map((originCard) => ({
                      cardIndex: originCard.cardIndex,
                      columnIndex: originCard.columnIndex,
                    })),
                  })),
                );
              }
            }

            if (card.skipTargetNodeId && fromCard.cardData.skipTargetNodeId) {
              const toCardSkip = this.getCardsAndMetadata(
                card.skipTargetNodeId,
              );

              if (toCardSkip?.element) {
                const lines = compute(
                  builderLayoutClientRect,
                  fromCard,
                  toCardSkip,
                  getTargetSources(card.skipTargetNodeId),
                  sortedColumnsCards[card.columnIndex],
                  true,
                );

                let originCards = [card];
                if (card.component === CardContainerType.InvisibleCard) {
                  originCards = card.originCards;
                }

                acc.push(
                  ...lines.map((line) => ({
                    style: line,
                    indexes: originCards.map((originCard) => ({
                      cardIndex: originCard.cardIndex,
                      columnIndex: originCard.columnIndex,
                    })),
                  })),
                );
              }
            }
          });
        });

        return acc;
      }, []);
    }, 1);
  }

  clipboardCopy(code: string) {
    this.clipboardService.copy(code);
    this.notificationHelper.trigger(
      "Copied to your clipboard!",
      null,
      "success",
    );
  }

  public cardsAndElements: Record<string, CardDataAndElement> = {};
  public lines: any = [];

  public isNodeLeaf(node: GraphNode | null, action: CTAAction | null) {
    if (!node) {
      return this.builderStore.nodes.length === 0;
    }

    return (
      !routingGetNextNodeId(node.node.routing, action?.id) &&
      !routingGetSkipToNodeId(node.node.routing, action?.id)
    );
  }

  public hoverCardError(nodeId: string) {
    if (!nodeId) {
      this.hoveredCard = null;
      return;
    }
    this.columnsWithCards.some((column) => {
      const card = column.cards.find(
        (card) =>
          card.component !== CardContainerType.InvisibleCard &&
          card.node.id === nodeId,
      );
      if (card) {
        this.hoveredCard = card;
        return true;
      }
      return false;
    });
  }

  /**
   * "Add a node" popin
   */
  public hasMenuOpen: boolean = false;
  public cardMenuPopinOrigin: ElementRef | null = null;
  public cardMenuPopinNode: GraphNode | null = null;
  public cardMenuPopinAction: CTAAction | null = null;
  public cardMenuPopinActions: CTAAction[] | null = null;
  public cardMenuPopinIsNodeLeaf: boolean = false;
  public cardMenuPopinIsNodeRoot: boolean = false;

  public onActionDotClick(
    origin: ElementRef,
    node: GraphNode | null = null,
    action: CTAAction | null = null,
    actions: CTAAction[] | null = null,
  ) {
    if (this.builderStore.survey.type === "message") {
      if (!node) {
        this.onOpenTagEditor.emit({
          type: "editor",
          url: this.messageURL,
          parameters: { addAtIndex: 0 },
        });
      } else if (node.node.question.type !== "survey") {
        this.onOpenTagEditor.emit({
          type: "editor",
          url: node.node.question.url,
          parameters: { addAtIndex: node.index + 1 },
        });
      }
    } else {
      this.openMenu(origin, node, action, actions);
    }
  }

  public openMenu(
    origin: ElementRef,
    node: GraphNode | null = null,
    action: CTAAction | null = null,
    actions: CTAAction[] | null = null,
  ) {
    this.hasMenuOpen = true;
    this.cardMenuPopinIsNodeLeaf = this.isNodeLeaf(node, action);
    this.cardMenuPopinIsNodeRoot = node === null;
    this.cardMenuPopinOrigin = origin;
    this.cardMenuPopinNode = node;
    this.cardMenuPopinAction = action;
    this.cardMenuPopinActions = actions;
  }

  public closeMenu() {
    this.hasMenuOpen = false;
    this.cardMenuPopinOrigin = null;
    this.cardMenuPopinNode = null;
    this.cardMenuPopinAction = null;
    this.cardMenuPopinActions = null;
  }

  public addNode(type: CTAType) {
    this.onNodeAdded.emit({
      type: type,
      node: this.cardMenuPopinNode,
      action: this.cardMenuPopinAction,
      actions: this.cardMenuPopinActions,
    });
    this.closeMenu();
  }

  public generateSurvey() {
    this.onOpenAiBuilder.emit(this.columnsWithCards.length === 0);
    this.closeMenu();
  }

  /**
   * Lateral panel
   */
  public firstColumnMargin = 0;
  public isLateralPanelOpen: boolean = false;
  public currentSelectedNodeId?: string;

  public editNode(node: GraphNode) {
    if (this.builderStore.readOnly) {
      return;
    }

    this.isLateralPanelOpen = true;
    this.currentSelectedNodeId = node.id;
  }

  public editNodeByNodeId(nodId: string) {
    if (this.builderStore.readOnly) {
      return;
    }
    const node = this.builderStore.nodesById[nodId];
    if (!node) {
      return;
    }
    this.isLateralPanelOpen = true;
    this.currentSelectedNodeId = node.id;
  }

  public closeLateralPanel() {
    this.isLateralPanelOpen = false;
    this.currentSelectedNodeId = null;
  }

  public goToPreviousNode() {
    if (!this.currentSelectedNodeId || !this.isLateralPanelOpen) return;

    const node = this.builderStore.nodesById[this.currentSelectedNodeId];
    if (!node || node.index <= 0) return;

    this.currentSelectedNodeId = this.builderStore.nodes.find(
      (n: GraphNode) => n.index === node.index - 1,
    )?.id;
    if (!this.currentSelectedNodeId) this.isLateralPanelOpen = false;
  }

  public goToNextNode() {
    if (!this.currentSelectedNodeId || !this.isLateralPanelOpen) return;

    const node = this.builderStore.nodesById[this.currentSelectedNodeId];
    if (!node || node.index >= this.builderStore.nodes.length - 1) return;

    this.currentSelectedNodeId = this.builderStore.nodes.find(
      (n: GraphNode) => n.index === node.index + 1,
    )?.id;
    if (!this.currentSelectedNodeId) this.isLateralPanelOpen = false;
  }

  public editMessageURL() {
    this.showPickStartUrl = true;
  }

  public onMessageURLChosen(url: string = null) {
    if (url?.length) {
      this.messageURL = url;
    }

    if (this.messageURL !== this.builderStore.messageUrl) {
      this.builderStore.setMessageUrl(this.messageURL);
      this.onTriggerSave.emit();
    }

    // Save targeting rule asynchrounously
    this.showPickStartUrlLoading = true;
    const rules =
      this.messageDistribution.targeting_rules?.filter(
        (rule) => rule.type !== "url",
      ) || [];
    rules.push(
      new TargetingRule(
        this.messageDistribution.id,
        this.uiService.currentOrgId,
        "url",
        "contains",
        new TargetingRuleValue().fromJson({
          v_s_arr: [this.messageURL],
        }),
      ),
    );
    this.surveyDistributionDao
      .updateTargetingRules(
        this.uiService.currentOrgId,
        this.uiService.currentSurveyId,
        this.messageDistribution.id,
        rules,
        null,
      )
      .then(() => {
        this.messageDistribution.targeting_rules = rules;
        return this.uiService.fetchEverything();
      })
      .then(() => {
        this.showPickStartUrl = false;
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        this.showPickStartUrlLoading = false;
      });

    // Save in local storage
    if (this.currentURLs.length && this.currentURLs[0] === this.messageURL) {
      return;
    }

    this.currentURLs = this.currentURLs.filter((u) => u !== this.messageURL);
    localStorage.setItem(
      `screeb-message-urls`,
      JSON.stringify([this.messageURL, ...this.currentURLs].slice(-10)),
    );
  }

  public isCardHovered(
    card: CardContainer,
    hoveredCard: CardContainer | null,
  ): boolean {
    if (!hoveredCard) return false;
    return (
      hoveredCard.cardIndex === card.cardIndex &&
      hoveredCard.columnIndex === card.columnIndex
    );
  }

  /**
   * Preview panel
   */
  public isPreviewOpened: string = null;
  public isSaving = false;

  public onPreviewRequested(distributionId: string = "") {
    this.isSaving = true;

    this.builderStore
      .save()
      .then(() => {
        this.trackersService
          .newEventTrackingBuilder("Survey preview opened")
          .withOrg(this.builderStore.org)
          .withSurvey(this.builderStore.survey)
          .build();

        if (this.builderStore.survey.type === "survey") {
          this.isPreviewOpened = distributionId;
        } else {
          let url = this.messageURL;

          // Message url might be overrided to use a contain rule
          // Let's use the first node url
          const firstNode = this.builderStore.nodes?.[0]?.node;
          if ((firstNode?.question as any)?.url?.length) {
            url = (firstNode.question as any).url;
          }

          this.onOpenTagEditor.emit({
            type: "preview",
            url,
            parameters: {
              surveyId: UUID(this.builderStore.survey.id),
            },
          });
        }
      })
      .finally(() => {
        this.isSaving = false;
      });
  }

  public onPreviewClosed() {
    this.isPreviewOpened = null;
  }

  onVideoUploadCancel() {
    if (this.uploadId === null) {
      return;
    }
    this.mediaUploadService.cancelUpload(
      "survey_question",
      {
        orgId: this.builderStore.org.id,
        surveyId: this.builderStore.survey.id,
      },
      this.uploadId,
    );
  }

  public isInvisibleCardHovered(
    card: CardContainer,
    hoveredCard?: CardContainer,
  ) {
    return card.originCards?.some(
      (originCard) =>
        originCard.cardIndex === hoveredCard?.cardIndex &&
        originCard.columnIndex === hoveredCard?.columnIndex,
    );
  }

  public isLineHovered(line: any, hoveredCard?: CardContainer) {
    return line.indexes.some(
      (index: any) =>
        index.cardIndex === hoveredCard?.cardIndex &&
        index.columnIndex === hoveredCard?.columnIndex,
    );
  }

  public onOpenTagEditorAtIndex(openAtIndex: number) {
    const node = this.builderStore.nodesPerColumns[openAtIndex]?.[0];

    if (!node || node.node.question.type === "survey") {
      this.onOpenTagEditor.emit({
        type: "editor",
        url: this.messageURL,
        parameters: {},
      });
      return;
    }

    this.closeLateralPanel();

    this.onOpenTagEditor.emit({
      type: "editor",
      url: node.node.question.url,
      parameters: { openAtIndex },
    });
  }

  public getSurveyLanguageWithEmoji(language: SurveyLanguages) {
    return this.builderStore.availableLanguages.find(
      (lang) => lang.value === language,
    );
  }

  public onSurveyLanguageChange(lang: SurveyLanguages) {
    this.builderStore.currentLanguage = lang;
    this.computeLines();
  }
}
