import twemoji from "@twemoji/api";
import { isDarkColor } from "utils/color";

const emojisOverride = [
  "30-20e3",
  "31-20e3",
  "32-20e3",
  "33-20e3",
  "34-20e3",
  "35-20e3",
  "36-20e3",
  "37-20e3",
  "38-20e3",
  "39-20e3",
  "1f51f",
];

// const base = (twemoji as any).base.replace(
//   "https://twemoji.maxcdn.com",
//   "https://twemoji.screeb.app"
// );

// The Twemoji version is hardcoded:
// - api/third-party/emoji.go
// - tag/src/widget/services/survey/emoji.ts
// - web/src/app/components/builder/components/Cards/sanitized-message/emoji.ts
export function replaceTwemojiBaseURL(baseURL: string) {
  return baseURL.replace(
    /https\:\/\/cdn\.jsdelivr\.net\/gh\/.*\/assets\//gi,
    "https://emoji.screeb.app/15.0.2/",
    // "https://cdnjs.cloudflare.com/ajax/libs/twemoji"
  );
}

const base = replaceTwemojiBaseURL((twemoji as any).base);

// const base = (twemoji as any).base.replace(
//   "https://cdn.jsdelivr.net/gh/jdecked/twemoji@15.0.2/assets",
//   "https://emoji.screeb.app/15.0.2",
//   // "https://cdnjs.cloudflare.com/ajax/libs/twemoji"
// );

export function formatEmojiCodePoint(codePoint: string) {
  return codePoint
    .toLowerCase()
    .replace(/\-fe0f/g, "")
    .replace(/^0+/, "");
}

export function shouldOverrideEmojiCodePoint(codePoint: string) {
  return emojisOverride.includes(formatEmojiCodePoint(codePoint));
}

export function shouldOverrideEmoji(text: string) {
  return shouldOverrideEmojiCodePoint(
    (twemoji as any).convert.toCodePoint(text),
  );
}

/***************************************************
 *          DUPLICATE FROM ADMIN SOURCE CODE
 ***************************************************/

export function emojiTranscoder(
  text: string,
  useDarkAndLightMode = false,
  primaryTextColor = "#000000",
): string {
  if (shouldOverrideEmoji(text)) {
    return (twemoji as any).parse(text, {
      className: "screeb-emoji",
      folder:
        "/assets/emojis/" +
        (!useDarkAndLightMode || isDarkColor(primaryTextColor)
          ? "dark"
          : "light"),
      ext: ".svg",
      base: ".",
    });
  }

  return (twemoji as any).parse(text, {
    className: "screeb-emoji",
    folder: "svg",
    ext: ".svg",
    base: base,
  });
}

export function emojiTranscoderURLOnly(text: string): string {
  // @ts-ignore this because typings on twemoji are fucked.
  return `${base}svg/${convertEmoji(text)}.svg`;
}

export function convertEmoji(text: string): string {
  return (twemoji as any).convert.toCodePoint(text);
}

export function removeEmojis(text: string): string {
  // @ts-ignore this because typings on twemoji are fucked.
  return (twemoji as any).replace(text, () => "");
}
