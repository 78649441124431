import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from "@angular/core";

@Component({
  selector: "card",
  template: `
    <div
      *ngIf="!cardPath"
      class="card"
      [ngClass]="{ clickable: !!cardClick, 'is-unvailable': !quotaOk }"
      (click)="cardClick.emit($event)"
    >
      <ng-container [ngTemplateOutlet]="inner"></ng-container>
    </div>

    <a
      *ngIf="!!cardPath && cardPathIsInternal"
      class="card"
      [routerLink]="cardPath"
      [ngClass]="{ clickable: !!cardClick }"
      (click)="cardClick.emit($event)"
    >
      <ng-container [ngTemplateOutlet]="inner"></ng-container>
    </a>

    <a
      *ngIf="!!cardPath && !cardPathIsInternal"
      class="card"
      [href]="cardPath"
      [ngClass]="{ clickable: !!cardClick }"
      (click)="cardClick.emit($event)"
    >
      <ng-container [ngTemplateOutlet]="inner"></ng-container>
    </a>

    <ng-template #inner>
      <div *ngIf="!headerIcon" class="header" [style]="headerCss"></div>
      <div *ngIf="!!headerIcon" class="header icon">
        <screeb-icon
          class="icon"
          [size]="headerIconSize || 'sm'"
          [icon]="headerIcon"
        ></screeb-icon>
      </div>

      <div class="content">
        <div class="top">
          <h3 *ngIf="!!title">{{ title }}</h3>
          <h3 *ngIf="!!titleTpl">
            <ng-container [ngTemplateOutlet]="titleTpl"></ng-container>
          </h3>

          <ng-container
            *ngIf="!!extra"
            [ngTemplateOutlet]="extra"
          ></ng-container>
        </div>

        <ng-container
          *ngIf="!!contentTpl"
          [ngTemplateOutlet]="contentTpl"
        ></ng-container>
        <ng-container *ngIf="!!content">
          <p *ngFor="let c of content">{{ c }}</p>
        </ng-container>
      </div>

      <footer>
        <div class="left" *ngIf="actionLeft">
          <ng-container *ngTemplateOutlet="actionLeft"></ng-container>
        </div>
        <div class="right" *ngIf="actionRight">
          <ng-container *ngTemplateOutlet="actionRight"></ng-container>
        </div>
      </footer>
    </ng-template>
  `,
  styles: [
    `
      :host {
        width: 100%;
        padding: 1px;
        background: var(--screeb-color-background);
        border: 1px solid var(--screeb-color-border-secondary);
        box-sizing: border-box;
        border-radius: 16px;
      }

      .card {
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      .card.is-unvailable {
        filter: saturate(0%);
      }

      .card.clickable {
        cursor: pointer;

        &:hover {
          screeb-icon {
            transform: scale(1.1) rotate(10deg);
          }
        }
      }

      .card .header {
        background-repeat: no-repeat;
        background-color: var(--screeb-color-background-secondary);
        background-position: center center;
        background-size: cover;
        height: 184px;
        border-top-left-radius: 14px;
        border-top-right-radius: 14px;
      }

      .card .header.icon {
        display: flex;
        align-items: center;
        justify-content: center;

        screeb-icon {
          transition: transform 0.2s ease-in-out;
        }

        screeb-icon,
        ::ng-deep screeb-icon svg {
          height: 50px !important;
          width: 50px !important;
        }
      }

      .card .content {
        flex: 1;
        padding: 24px;
        padding-bottom: 0px;
        color: var(--screeb-color-body-text);
        font-size: 16px;
        line-height: 24px;
      }

      .card .content .top {
        display: flex;
        justify-content: space-between;
        font-weight: 500;
      }

      .card .content .top h3 {
        margin: 0px;
        margin-bottom: 4px;
      }

      .card .content p {
        margin: 0px;
        margin-bottom: 4px;
      }

      .card footer {
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 24px;
        margin-top: 0.5rem;
      }

      .card footer ::ng-deep .left a,
      .card footer ::ng-deep .left button {
        margin-right: 10px;
      }

      .card footer ::ng-deep .right a,
      .card footer ::ng-deep .right button {
        margin-left: 10px;
      }

      .card footer ::ng-deep a,
      .card footer ::ng-deep button {
        font-weight: 500;
        font-size: 16px;
      }

      .card footer ::ng-deep a:not(.ant-btn-primary):not(.ant-btn-default),
      .card
        footer
        ::ng-deep
        button:not(.ant-btn-primary):not(.ant-btn-default) {
        height: auto;
        padding: 0px;
        line-height: 24px;
      }
    `,
  ],
})
export class CardComponent implements OnInit {
  @Input() cardPath: string;
  @Input() headerIcon?: string | null;
  @Input() headerIconSize?: string | null;
  @Input() headerCss: object;
  @Input() title: string;
  @Input() titleTpl: string;
  @Input() content: string[];
  @Input() contentTpl: TemplateRef<any>;
  @Input() extra: TemplateRef<any>;
  @Input() actionLeft: TemplateRef<any>;
  @Input() actionRight: TemplateRef<any>;
  @Input() quotaOk: boolean = true;

  @Output() cardClick = new EventEmitter();

  public cardPathIsInternal: boolean;

  constructor() {
    this.cardPathIsInternal = false;
  }

  ngOnInit() {
    this.cardPathIsInternal =
      this.cardPath &&
      !this.cardPath.startsWith("http://") &&
      !this.cardPath.startsWith("https://") &&
      !this.cardPath.startsWith("mailto:");
  }
}
