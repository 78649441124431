import { Post101 } from "components/utils/card-101-link/card-101-link.model";
import { Serializable } from "models/serializable.model";
import { uuidv4 } from "utils/uuid";
import { HookType } from "./hook.model";

export type IntegrationType =
  // forward
  | "harvestr"
  | "productboard"
  | "intercom"
  | "zendesk"
  | "trello"
  | "cycle"
  | "monday"
  | "asana"
  // cdp
  | "amplitude-source"
  | "segment-source"
  | "rudderstack-source"
  | "contentsquare-source"
  | "typeform"
  // hook
  | "stitch"
  | "webhook"
  | "zapier"
  | "cobbai"
  | "notion"
  | "atlassian"
  | "email"
  | "amplitude-destination"
  | "segment-destination"
  | "rudderstack-destination"
  // reporting
  | "contentsquare-destination"
  | "slack";

export type surveyLevelIntegrationType = "webhook" | "cobbai" | "zapier";
export type scenarioNodeLevelIntegrationType = "webhook" | "zapier";

export type IntegrationCategory =
  | "source"
  | "destination"
  | "analytics"
  | "cdp"
  | "roadmapping"
  | "etl"
  | "project_management"
  | "customer_service"
  | "survey"
  | "no_code"
  | "communication";

export type IntegrationCategorySpec = {
  category: IntegrationCategory;
  name: string;
  color: string;
};

export const integrationCategorySpec: {
  [key in IntegrationCategory]: IntegrationCategorySpec;
} = {
  source: {
    category: "source",
    name: "Source",
    color: "#E2D6FF",
  },
  destination: {
    category: "destination",
    name: "Destination",
    color: "#ABF3E0",
  },
  analytics: {
    category: "analytics",
    name: "Analytics",
    color: "#DED583",
  },
  cdp: {
    category: "cdp",
    name: "CDP",
    color: "#D0E4FD",
  },
  roadmapping: {
    category: "roadmapping",
    name: "Roadmapping",
    color: "#FFF2D6",
  },
  etl: {
    category: "etl",
    name: "ETL",
    color: "#FFD6DD",
  },
  project_management: {
    category: "project_management",
    name: "Project Management",
    color: "#E6B1C8",
  },
  customer_service: {
    category: "customer_service",
    name: "Customer Service",
    color: "#F2C594",
  },
  survey: {
    category: "survey",
    name: "Survey",
    color: "#E8C5B7",
  },
  no_code: {
    category: "no_code",
    name: "No Code",
    color: "#F9FFC9",
  },
  communication: {
    category: "communication",
    name: "Communication",
    color: "#C9CEFF",
  },
};

export type IntegrationSpec = {
  type: IntegrationType;
  name: string;
  url?: string;
  shortDescription: string;
  description: string;
  imageSrc: string;
  installable: boolean; // hidden in marketplace, because installed by default for all accounts (eg: email)
  createOnInstall: boolean;
  autoSave: boolean;
  tokenModifiable: boolean;
  authCanBreak: boolean;
  posts101: Post101[];
  videoUrl: string;
  visibleInMarketplace: boolean;
  haveNoGlobalSettings?: boolean;
  categories: IntegrationCategory[];
  // features
  features: {
    dataSource: boolean; // eg: cdp, tracking...
    reporting: boolean; // eg; send weekly reports into slack
    hooks: boolean; // eg: webhook, zapier, ETLs...
    forwarding: boolean; // manual forwarding button
  };
};

export const integrationSpecs: { [key in IntegrationType]: IntegrationSpec } = {
  "amplitude-source": {
    type: "amplitude-source",
    name: "Amplitude Source",
    url: "https://amplitude.com/",
    shortDescription:
      "Use Amplitude events and properties to display your Screeb surveys in your app.",
    description:
      "Use Amplitude events and properties to display your Screeb surveys in your app.",
    imageSrc: "/assets/icons/integrations/amplitude.png",
    installable: true,
    createOnInstall: true,
    visibleInMarketplace: true,
    autoSave: false,
    tokenModifiable: false,
    authCanBreak: false,
    posts101: [
      {
        title: "How to create an Event Tracking Plan for your SaaS.",
        url: "https://screeb.app/blog/how-to-create-an-event-tracking-plan-for-your-saas/",
        color: "#FFF2D6",
        readTime: 3,
      },
    ],
    videoUrl: "",

    categories: ["source", "cdp", "analytics"],
    features: {
      dataSource: true,
      reporting: false,
      hooks: false,
      forwarding: false,
    },
  },
  "segment-source": {
    type: "segment-source",
    name: "Segment Source",
    url: "https://segment.com/",
    shortDescription:
      "Use Segment events and properties to display your Screeb surveys in your app.",
    description:
      "Use Segment events and properties to display your Screeb surveys in your app.",
    imageSrc: "/assets/icons/integrations/segment.png",
    installable: true,
    createOnInstall: true,
    visibleInMarketplace: true,
    autoSave: false,
    tokenModifiable: false,
    authCanBreak: false,
    posts101: [
      {
        title: "How to create an Event Tracking Plan for your SaaS.",
        url: "https://screeb.app/blog/how-to-create-an-event-tracking-plan-for-your-saas",
        color: "#FFF2D6",
        readTime: 3,
      },
      {
        title: "Screeb's Segment Integration: the complete guide",
        url: "https://help.screeb.app/en/articles/5299671-segment-integration-the-complete-guide",
        color: "#D0E4FD",
        readTime: 3,
      },
    ],
    videoUrl: "",

    categories: ["source", "cdp"],
    features: {
      dataSource: true,
      reporting: false,
      hooks: false,
      forwarding: false,
    },
  },
  "rudderstack-source": {
    type: "rudderstack-source",
    name: "RudderStack Source",
    url: "https://rudderstack.com/",
    shortDescription:
      "Use RudderStack events and properties to display your Screeb surveys in your app.",
    description:
      "Use RudderStack events and properties to display your Screeb surveys in your app.",
    imageSrc: "/assets/icons/integrations/rudderstack.png",
    installable: true,
    createOnInstall: true,
    visibleInMarketplace: true,
    autoSave: false,
    tokenModifiable: false,
    authCanBreak: false,
    posts101: [
      {
        title: "How to create an Event Tracking Plan for your SaaS.",
        url: "https://screeb.app/blog/how-to-create-an-event-tracking-plan-for-your-saas/",
        color: "#FFF2D6",
        readTime: 3,
      },
    ],
    videoUrl: "",

    categories: ["source", "cdp"],
    features: {
      dataSource: true,
      reporting: false,
      hooks: false,
      forwarding: false,
    },
  },
  "contentsquare-source": {
    type: "contentsquare-source",
    name: "ContentSquare Source",
    url: "https://contentsquare.com/",
    shortDescription:
      "Use ContentSquare events and properties to display Screeb surveys in your app.",
    description:
      "Use ContentSquare events and properties to display Screeb surveys in your app.",
    imageSrc: "/assets/icons/integrations/contentsquare.png",
    installable: true,
    createOnInstall: true,
    visibleInMarketplace: false,
    autoSave: true, // auto save enabled but trigger nevver called (result in no save and hidden save button)
    tokenModifiable: false,
    authCanBreak: false,
    posts101: [],
    videoUrl: "",

    categories: ["source", "cdp"],
    features: {
      dataSource: true,
      reporting: false,
      hooks: false,
      forwarding: false,
    },
  },
  "amplitude-destination": {
    type: "amplitude-destination",
    name: "Amplitude Destination",
    url: "https://amplitude.com/",
    shortDescription:
      "Send Screeb responses and properties to Amplitude to use them in your dashboards.",
    description:
      "Send Screeb responses and properties to Amplitude to use them in your dashboards.",
    imageSrc: "/assets/icons/integrations/amplitude.png",
    installable: true,
    createOnInstall: false,
    visibleInMarketplace: true,
    autoSave: false,
    tokenModifiable: true,
    authCanBreak: false,
    posts101: [
      {
        title: "How to create an Event Tracking Plan for your SaaS.",
        url: "https://screeb.app/blog/how-to-create-an-event-tracking-plan-for-your-saas/",
        color: "#FFF2D6",
        readTime: 3,
      },
    ],
    videoUrl: "",

    categories: ["destination", "analytics"],
    features: {
      dataSource: false,
      reporting: false,
      hooks: true,
      forwarding: false,
    },
  },
  "segment-destination": {
    type: "segment-destination",
    name: "Segment Destination",
    url: "https://segment.com/",
    shortDescription:
      "Send Screeb responses and properties to Segment to use them in your other tools.",
    description:
      "Send Screeb responses and properties to Segment to use them in your other tools.",
    imageSrc: "/assets/icons/integrations/segment.png",
    installable: true,
    createOnInstall: false,
    visibleInMarketplace: true,
    autoSave: false,
    tokenModifiable: true,
    authCanBreak: false,
    posts101: [
      {
        title: "How to create an Event Tracking Plan for your SaaS.",
        url: "https://screeb.app/blog/how-to-create-an-event-tracking-plan-for-your-saas/",
        color: "#FFF2D6",
        readTime: 3,
      },
      {
        title: "Screeb's Segment Integration: the complete guide",
        url: "https://help.screeb.app/en/articles/5299671-segment-integration-the-complete-guide",
        color: "var(--screeb-color-blue-200)",
        readTime: 3,
      },
    ],
    videoUrl: "",

    categories: ["destination", "cdp"],
    features: {
      dataSource: false,
      reporting: false,
      hooks: true,
      forwarding: false,
    },
  },
  "rudderstack-destination": {
    type: "rudderstack-destination",
    name: "RudderStack Destination",
    url: "https://rudderstack.com/",
    shortDescription:
      "Send Screeb responses and properties to RudderStack to use them in your other tools.",
    description:
      "Send Screeb responses and properties to RudderStack to use them in your other tools.",
    imageSrc: "/assets/icons/integrations/rudderstack.png",
    installable: true,
    createOnInstall: false,
    visibleInMarketplace: true,
    autoSave: false,
    tokenModifiable: true,
    authCanBreak: false,
    posts101: [
      {
        title: "How to create an Event Tracking Plan for your SaaS.",
        url: "https://screeb.app/blog/how-to-create-an-event-tracking-plan-for-your-saas/",
        color: "#FFF2D6",
        readTime: 3,
      },
    ],
    videoUrl: "",

    categories: ["destination", "cdp"],
    features: {
      dataSource: false,
      reporting: false,
      hooks: true,
      forwarding: false,
    },
  },
  zendesk: {
    type: "zendesk",
    name: "Zendesk",
    url: "https://zendesk.com/",
    shortDescription:
      "Create tickets in Zendesk from Screeb responses and user feedback.",
    description:
      "Create tickets in Zendesk from Screeb responses and user feedback.",
    imageSrc: "/assets/icons/integrations/zendesk.png",
    installable: true,
    createOnInstall: false,
    visibleInMarketplace: true,
    autoSave: false,
    tokenModifiable: false,
    authCanBreak: false,
    posts101: [],
    videoUrl: "",

    categories: ["destination", "customer_service"],
    features: {
      dataSource: false,
      reporting: false,
      hooks: false,
      forwarding: true,
    },
  },
  intercom: {
    type: "intercom",
    name: "Intercom",
    url: "https://intercom.com/",
    shortDescription:
      "Create conversations in Intercom from Screeb responses and user feedback.",
    description:
      "Create conversations in Intercom from Screeb responses and user feedback.",
    imageSrc: "/assets/icons/integrations/intercom.png",
    installable: true,
    createOnInstall: false,
    visibleInMarketplace: true,
    autoSave: false,
    tokenModifiable: false,
    authCanBreak: false,
    posts101: [
      {
        title: "How to connect Screeb to Intercom",
        url: "https://help.screeb.app/en/articles/5342069-how-to-connect-screeb-to-intercom",
        color: "#CDE0FDFF",
        readTime: 3,
      },
    ],
    videoUrl: "",

    categories: ["destination", "customer_service"],
    features: {
      dataSource: false,
      reporting: false,
      hooks: false,
      forwarding: true,
    },
  },
  productboard: {
    type: "productboard",
    name: "Productboard",
    url: "https://productboard.com/",
    shortDescription:
      "Create new insights in Productboard from Screeb responses and user feedback.",
    description:
      "Create new insights in Productboard from Screeb responses and user feedback.",
    imageSrc: "/assets/icons/integrations/productboard.png",
    installable: true,
    createOnInstall: false,
    visibleInMarketplace: true,
    autoSave: false,
    tokenModifiable: false,
    authCanBreak: false,
    posts101: [
      {
        title: "How to connect Screeb to Productboard.",
        url: "https://help.screeb.app/en/articles/5341915-how-to-connect-screeb-to-productboard",
        color: "#CDE0FDFF",
        readTime: 3,
      },
      {
        title: "Share In-App Surveys Responses to Productboard with Screeb",
        url: "https://screeb.app/blog/in-app-surveys-for-productboard/",
        color: "#CDE0FDFF",
        readTime: 3,
      },
    ],
    videoUrl: "",

    categories: ["destination", "roadmapping"],
    features: {
      dataSource: false,
      reporting: false,
      hooks: false,
      forwarding: true,
    },
  },
  harvestr: {
    type: "harvestr",
    name: "Harvestr",
    url: "https://harvestr.io/",
    shortDescription:
      "Create new feedback in Harvestr from Screeb responses and user feedback.",
    description:
      "Create new feedback in Harvestr from Screeb responses and user feedback.",
    imageSrc: "/assets/icons/integrations/harvestr.png",
    installable: true,
    createOnInstall: false,
    visibleInMarketplace: true,
    autoSave: false,
    tokenModifiable: false,
    authCanBreak: false,
    posts101: [
      {
        title: "How to connect Screeb to Harvestr",
        url: "https://help.screeb.app/en/articles/5341788-how-to-connect-screeb-to-harvestr",
        color: "#CDE0FDFF",
        readTime: 3,
      },
    ],
    videoUrl: "",

    categories: ["destination", "roadmapping"],
    features: {
      dataSource: false,
      reporting: false,
      hooks: false,
      forwarding: true,
    },
  },
  trello: {
    type: "trello",
    name: "Trello",
    url: "https://trello.com/",
    shortDescription:
      "Create new feedback in Trello from Screeb responses and user feedback.",
    description:
      "Create new feedback in Trello from Screeb responses and user feedback.",
    imageSrc: "/assets/icons/integrations/trello.png",
    installable: true,
    createOnInstall: false,
    visibleInMarketplace: true,
    autoSave: false,
    tokenModifiable: false,
    authCanBreak: false,
    posts101: [],
    videoUrl: "",

    categories: ["destination", "project_management"],
    features: {
      dataSource: false,
      reporting: false,
      hooks: false,
      forwarding: true,
    },
  },
  cycle: {
    type: "cycle",
    name: "Cycle",
    url: "https://cycle.io/",
    shortDescription:
      "Create new feedback in Cycle from Screeb responses and user feedback.",
    description:
      "Create new feedback in Cycle from Screeb responses and user feedback.",
    imageSrc: "/assets/icons/integrations/cycle.png",
    installable: true,
    createOnInstall: false,
    visibleInMarketplace: true,
    autoSave: false,
    tokenModifiable: false,
    authCanBreak: false,
    posts101: [],
    videoUrl: "",

    categories: ["destination", "roadmapping"],
    features: {
      dataSource: false,
      reporting: false,
      hooks: false,
      forwarding: true,
    },
  },
  monday: {
    type: "monday",
    name: "Monday",
    url: "https://monday.com/",
    shortDescription:
      "Create new feedback in Monday from Screeb responses and user feedback.",
    description:
      "Create new feedback in Monday from Screeb responses and user feedback.",
    imageSrc: "/assets/icons/integrations/monday.png",
    installable: true,
    createOnInstall: false,
    visibleInMarketplace: true,
    autoSave: false,
    tokenModifiable: false,
    authCanBreak: false,
    posts101: [],
    videoUrl: "",

    categories: ["destination", "project_management"],
    features: {
      dataSource: false,
      reporting: false,
      hooks: false,
      forwarding: true,
    },
  },
  asana: {
    type: "asana",
    name: "Asana",
    url: "https://asana.com/",
    shortDescription:
      "Create new feedback in Asana from Screeb responses and user feedback.",
    description:
      "Create new feedback in Asana from Screeb responses and user feedback.",
    imageSrc: "/assets/icons/integrations/asana.png",
    installable: true,
    createOnInstall: false,
    visibleInMarketplace: false,
    autoSave: false,
    tokenModifiable: false,
    authCanBreak: false,
    posts101: [],
    videoUrl: "",

    categories: ["destination", "project_management"],
    features: {
      dataSource: false,
      reporting: false,
      hooks: false,
      forwarding: true,
    },
  },
  typeform: {
    type: "typeform",
    name: "Typeform",
    url: "https://typeform.com/",
    shortDescription:
      "Centralize every responses made by your users in typeforms and display them in their Screeb profile. Get a unified view of what your users tell you, wherever they say it.",
    description:
      "Centralize every responses made by your users in typeforms and display them in their Screeb profile. Get a unified view of what your users tell you, wherever they say it.",
    imageSrc: "/assets/icons/integrations/typeform.png",
    installable: true,
    createOnInstall: true,
    visibleInMarketplace: false,
    autoSave: true,
    tokenModifiable: false,
    authCanBreak: false,
    posts101: [],
    videoUrl: "",

    categories: ["source", "survey"],
    features: {
      dataSource: true,
      reporting: false,
      hooks: false,
      forwarding: false,
    },
  },
  notion: {
    type: "notion",
    name: "Notion",
    url: "https://notion.so/",
    shortDescription: "Push your Screeb data to Notion.",
    description:
      "Connect your Notion account to Screeb to automatically share your users feedback to a database.",
    imageSrc: "/assets/icons/integrations/notion.png",
    installable: true,
    createOnInstall: true,
    visibleInMarketplace: true,
    autoSave: true,
    tokenModifiable: false,
    authCanBreak: true,
    posts101: [],
    videoUrl: "",

    categories: ["destination", "project_management"],
    features: {
      dataSource: false,
      reporting: false,
      hooks: true,
      forwarding: false,
    },
  },
  atlassian: {
    type: "atlassian",
    name: "Jira Software",
    url: "https://www.atlassian.com/",
    shortDescription: "Push your Screeb data to Jira.",
    description:
      "Connect your Atlassian account to Screeb to automatically share your users feedback to a Jira project.",
    imageSrc: "/assets/icons/integrations/jira-software.png",
    installable: true,
    createOnInstall: true,
    visibleInMarketplace: true,
    autoSave: true,
    tokenModifiable: false,
    authCanBreak: true,
    posts101: [],
    videoUrl: "",

    categories: ["destination", "project_management"],
    features: {
      dataSource: false,
      reporting: false,
      hooks: true,
      forwarding: true,
    },
  },
  webhook: {
    type: "webhook",
    name: "Webhook",
    shortDescription:
      "Push your Screeb data to third party solutions via Webhooks.",
    description:
      "Push your Screeb data to third party solutions via Webhooks.<br>This will <b>automatically</b> push data from all of your surveys.",
    imageSrc: "/assets/icons/integrations/webhook.png",
    installable: true,
    createOnInstall: true,
    visibleInMarketplace: true,
    autoSave: true,
    tokenModifiable: false,
    authCanBreak: false,
    posts101: [
      // { title: 'What about partial responses?<br>You can set up an automatic closing delay for your surveys, after which partial responses will be pushed via webhooks. Set it up here.', url: (currentOrgId: string) => `/org/${currentOrgId}/settings/customize/survey/in_app`, color: '#CDE0FDFF', readTime: 1 },
      {
        title:
          "Learn which data are pushed through webhooks and the content of our payload.",
        url: "https://developers.screeb.app/api-and-connectors/webhook-v2.1.0",
        color: "#CDE0FDFF",
        readTime: 6,
      },
    ],
    videoUrl: "",

    categories: ["destination"],
    features: {
      dataSource: false,
      reporting: false,
      hooks: true,
      forwarding: false,
    },
  },
  stitch: {
    type: "stitch",
    name: "Stitch",
    url: "https://stitchdata.com/",
    shortDescription: "Push your Screeb data to the Stitch ETL.",
    description:
      "Push your Screeb data to the Stitch ETL.<br>This will <b>automatically</b> push data from all of your surveys to Stitch.",
    imageSrc: "/assets/icons/integrations/stitch.png",
    installable: true,
    createOnInstall: true,
    visibleInMarketplace: true,
    autoSave: true,
    tokenModifiable: false,
    authCanBreak: false,
    posts101: [
      // { title: 'What about partial responses?<br>You can set up an automatic closing delay for your surveys, after which partial responses will be pushed via webhooks to Stitch. Set it up here.', url: (currentOrgId: string) => `/org/${currentOrgId}/settings/customize/survey/in_app`, color: '#CDE0FDFF', readTime: 1 },
      {
        title:
          "Learn which data are pushed through Stitch and the schema of our datawarehouse.",
        url: "https://developers.screeb.app/api-and-connectors/stitch-v2.0.0",
        color: "#CDE0FDFF",
        readTime: 6,
      },
      {
        title: "⚠️ You must set “event_id” as Primary Key in Stitch.",
        url: "",
        color: "#CDE0FDFF",
      },
    ],
    videoUrl: "",

    categories: ["destination", "etl"],
    features: {
      dataSource: false,
      reporting: false,
      hooks: true,
      forwarding: false,
    },
  },
  zapier: {
    type: "zapier",
    name: "Zapier",
    url: "https://zapier.com/",
    shortDescription:
      "Push your Screeb data to third party solutions via Zapier.",
    description: "Push your Screeb data to third party solutions via Zapier.",
    imageSrc: "/assets/icons/integrations/zapier.png",
    installable: true,
    createOnInstall: true,
    visibleInMarketplace: false,
    autoSave: true,
    tokenModifiable: true,
    authCanBreak: false,
    posts101: [],
    videoUrl: "",

    categories: ["destination", "no_code"],
    features: {
      dataSource: false,
      reporting: false,
      hooks: true,
      forwarding: false,
    },
  },
  cobbai: {
    type: "cobbai",
    name: "Cobbai",
    url: "https://cobbai.com/",
    shortDescription:
      "Push your Screeb data to the Cobbai feedback analysis tool.",
    description: "Push your Screeb data to the Cobbai feedback analysis tool.",
    imageSrc: "/assets/icons/integrations/cobbai.png",
    installable: true,
    createOnInstall: true,
    visibleInMarketplace: true,
    autoSave: true,
    tokenModifiable: false,
    authCanBreak: false,
    posts101: [],
    videoUrl: "",
    haveNoGlobalSettings: true,
    categories: ["destination", "analytics"],
    features: {
      dataSource: false,
      reporting: false,
      hooks: true,
      forwarding: false,
    },
  },
  slack: {
    type: "slack",
    name: "Slack",
    url: "https://slack.com/",
    shortDescription:
      "Automatically share key metrics from Screeb's reports to any channel your want in Slack.",
    description:
      "Automatically share key metrics from Screeb's reports to any channel your want in Slack.",
    imageSrc: "/assets/icons/integrations/slack.png",
    installable: true,
    createOnInstall: true,
    visibleInMarketplace: true,
    autoSave: true,
    tokenModifiable: false,
    authCanBreak: true,
    posts101: [],
    videoUrl: "",

    categories: ["destination", "communication"],
    features: {
      dataSource: false,
      reporting: true,
      hooks: false,
      forwarding: false,
    },
  },
  "contentsquare-destination": {
    type: "contentsquare-destination",
    name: "Contentsquare Destination",
    url: "https://contentsquare.com/",
    shortDescription:
      "Automatically share key metrics from Screeb's reports to Contentsquare.",
    description:
      "Automatically share key metrics from Screeb's reports to Contentsquare.",
    imageSrc: "/assets/icons/integrations/contentsquare.png",
    installable: true,
    createOnInstall: true,
    visibleInMarketplace: false,
    autoSave: true,
    tokenModifiable: false,
    authCanBreak: true,
    posts101: [],
    videoUrl: "",

    categories: ["destination"],
    features: {
      dataSource: false,
      reporting: true,
      hooks: false,
      forwarding: false,
    },
  },
  email: {
    type: "email",
    name: "Email",
    shortDescription: "",
    description: "",
    imageSrc: "/assets/icons/integrations/.png",
    installable: false,
    createOnInstall: false,
    visibleInMarketplace: false,
    autoSave: false,
    tokenModifiable: false,
    authCanBreak: false,
    posts101: [],
    videoUrl: "",

    categories: ["destination", "communication"],
    features: {
      dataSource: false,
      reporting: false,
      hooks: true,
      forwarding: false,
    },
  },
};

// used for catalog
export const orderedIntegrationsTypes: IntegrationType[] = [
  "segment-source",
  "productboard",
  "slack",
  "notion",
  "atlassian",
  "amplitude-source",
  "rudderstack-source",
  "cobbai",
  "harvestr",
  "webhook",
  "amplitude-destination",
  "segment-destination",
  "rudderstack-destination",
  "contentsquare-source",
  "cycle",
  "stitch",
  "asana",
  "monday",
  "trello",
  "zendesk",
  "intercom",

  // not visible
  "typeform",
  "zapier",
  "email",
];

export class IntegrationSettingsHarvestr extends Serializable {
  constructor(public email?: string) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);
    return this;
  }

  static New(): IntegrationSettingsHarvestr {
    return { email: "" } as IntegrationSettingsHarvestr;
  }
}

export class IntegrationSettingsProductboard extends Serializable {
  constructor(public email?: string) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);
    return this;
  }

  static New(): IntegrationSettingsProductboard {
    return { email: "" } as IntegrationSettingsZendesk;
  }
}

export class IntegrationSettingsZendesk extends Serializable {
  constructor(public email?: string) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);
    return this;
  }

  static New(): IntegrationSettingsZendesk {
    return { email: "" } as IntegrationSettingsZendesk;
  }
}

export class IntegrationSettingsIntercom extends Serializable {
  constructor(public email?: string) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);
    return this;
  }

  static New(): IntegrationSettingsIntercom {
    return { email: "" } as IntegrationSettingsIntercom;
  }
}

export class IntegrationSettingsTrello extends Serializable {
  constructor(public email?: string) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);
    return this;
  }

  static New(): IntegrationSettingsTrello {
    return { email: "" } as IntegrationSettingsTrello;
  }
}

export class IntegrationSettingsCycle extends Serializable {
  constructor(public email?: string) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);
    return this;
  }

  static New(): IntegrationSettingsCycle {
    return { email: "" } as IntegrationSettingsCycle;
  }
}

export class IntegrationSettingsMonday extends Serializable {
  constructor(public email?: string) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);
    return this;
  }

  static New(): IntegrationSettingsMonday {
    return { email: "" } as IntegrationSettingsMonday;
  }
}

export class IntegrationSettingsAsana extends Serializable {
  constructor(public email?: string) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);
    return this;
  }

  static New(): IntegrationSettingsAsana {
    return { email: "" } as IntegrationSettingsAsana;
  }
}

export class IntegrationSettingsAmplitudeSource extends Serializable {
  constructor(public ignore_anonymous?: boolean) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);
    return this;
  }

  static New(): IntegrationSettingsAmplitudeSource {
    return { ignore_anonymous: false } as IntegrationSettingsAmplitudeSource;
  }
}

export class IntegrationSettingsSegmentSource extends Serializable {
  constructor(public ignore_anonymous?: boolean) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);
    return this;
  }

  static New(): IntegrationSettingsSegmentSource {
    return { ignore_anonymous: false } as IntegrationSettingsSegmentSource;
  }
}

export class IntegrationSettingsRudderstackSource extends Serializable {
  constructor(public ignore_anonymous?: boolean) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);
    return this;
  }

  static New(): IntegrationSettingsRudderstackSource {
    return { ignore_anonymous: false } as IntegrationSettingsRudderstackSource;
  }
}

export class IntegrationSettingsContentSquareSource extends Serializable {
  constructor(
    public ignore_anonymous?: boolean,
    public client_id?: string,
    public client_secret?: string,
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);
    return this;
  }

  static New(): IntegrationSettingsContentSquareSource {
    return {
      ignore_anonymous: false,
    } as IntegrationSettingsContentSquareSource;
  }
}

export class IntegrationSettingsTypeform extends Serializable {
  constructor(public items?: IntegrationSettingsTypeformItem[]) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);

    if (json["items"])
      this.items = json["items"].map((item) =>
        new IntegrationSettingsStitchItem().fromJson(item),
      );

    return this;
  }

  static New(): IntegrationSettingsTypeform {
    return { items: [] } as IntegrationSettingsTypeform;
  }
}

export class IntegrationSettingsTypeformItem extends Serializable {
  constructor(
    public id: string,
    public name: string,
    public typeform?: IntegrationSettingsTypeformMatchingTypeform,
    public screeb?: IntegrationSettingsTypeformMatchingScreeb,
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);

    if (json["typeform"])
      this.typeform =
        new IntegrationSettingsTypeformMatchingTypeform().fromJson(
          json["typeform"],
        );
    if (json["screeb"])
      this.screeb = new IntegrationSettingsTypeformMatchingScreeb().fromJson(
        json["screeb"],
      );

    return this;
  }

  static New(): IntegrationSettingsTypeformItem {
    return {
      id: uuidv4(),
      name: "Name of the typeform",
      typeform: IntegrationSettingsTypeformMatchingTypeform.New(),
      screeb: IntegrationSettingsTypeformMatchingScreeb.New(),
    } as IntegrationSettingsTypeformItem;
  }
}

export class IntegrationSettingsTypeformMatchingTypeform extends Serializable {
  constructor(
    public mode?: "hidden_field" | "ref",
    public hidden_field?: string,
    public ref?: string,
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);
    return this;
  }

  static New(): IntegrationSettingsTypeformMatchingTypeform {
    return {
      mode: "hidden_field",
      hidden_field: "",
      ref: null,
    } as IntegrationSettingsTypeformMatchingTypeform;
  }
}

export class IntegrationSettingsTypeformMatchingScreeb extends Serializable {
  constructor(
    public mode?: "alias" | "property",
    public property_key?: string,
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);
    return this;
  }

  static New(): IntegrationSettingsTypeformMatchingScreeb {
    return {
      mode: "alias",
      property_key: null,
    } as IntegrationSettingsTypeformMatchingScreeb;
  }
}

export class IntegrationSettingsWebhook extends Serializable {
  constructor(public items?: IntegrationSettingsWebhookItem[]) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);

    if (json["items"])
      this.items = json["items"].map((item) =>
        new IntegrationSettingsWebhookItem().fromJson(item),
      );

    return this;
  }

  static New(): IntegrationSettingsWebhook {
    return { items: [] } as IntegrationSettingsWebhook;
  }
}

export class IntegrationSettingsWebhookItem extends Serializable {
  constructor(
    public id?: string,
    public version?: string,
    public enabled?: boolean,
    public name?: string,
    public path?: string,
    public hooks?: HookType[],
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);

    if (json["hooks"]) this.hooks = json["hooks"].map((t) => t as HookType);

    return this;
  }

  static New(version: string): IntegrationSettingsWebhookItem {
    return {
      id: uuidv4(),
      enabled: true,
      name: "New webhook",
      path: "",
      hooks: [],
      version: version,
    } as IntegrationSettingsWebhookItem;
  }
}

export class IntegrationSettingsStitch extends Serializable {
  constructor(public items?: IntegrationSettingsStitchItem[]) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);

    if (json["items"])
      this.items = json["items"].map((item) =>
        new IntegrationSettingsStitchItem().fromJson(item),
      );

    return this;
  }

  static New(): IntegrationSettingsStitch {
    return { items: [] } as IntegrationSettingsStitch;
  }
}

export class IntegrationSettingsStitchItem extends Serializable {
  constructor(
    public id?: string,
    public version?: string,
    public enabled?: boolean,
    public name?: string,
    public path?: string,
    public hook?: HookType,
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);

    this.hook = json["hook"] as HookType;

    return this;
  }

  static New(version: string): IntegrationSettingsStitchItem {
    return {
      id: uuidv4(),
      version: version,
      enabled: true,
      name: "New Stitch Integration",
      path: "",
      hook: null,
    } as IntegrationSettingsStitchItem;
  }
}

export class IntegrationSettingsZapier extends Serializable {
  constructor(public items?: IntegrationSettingsZapierItem[]) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);

    if (json["items"])
      this.items = json["items"].map((item) =>
        new IntegrationSettingsZapierItem().fromJson(item),
      );

    return this;
  }

  static New(): IntegrationSettingsZapier {
    return { items: [] } as IntegrationSettingsZapier;
  }
}

export class IntegrationSettingsZapierItem extends Serializable {
  constructor(
    public id?: string,
    public version?: string,
    public enabled?: boolean,
    public name?: string,
    public path?: string,
    public hook?: HookType,
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);

    this.hook = json["hook"] as HookType;

    return this;
  }

  static New(version: string): IntegrationSettingsZapierItem {
    return {
      id: uuidv4(),
      version: version,
      enabled: true,
      name: "New Zap",
      path: "",
      hook: null,
    } as IntegrationSettingsZapierItem;
  }
}

export class IntegrationSettingsCobbai extends Serializable {
  constructor(public items?: IntegrationSettingsCobbaiItem[]) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);

    if (json["items"])
      this.items = json["items"].map((item) =>
        new IntegrationSettingsCobbaiItem().fromJson(item),
      );

    return this;
  }

  static New(): IntegrationSettingsCobbai {
    return { items: [] } as IntegrationSettingsCobbai;
  }
}

export class IntegrationSettingsCobbaiItem extends Serializable {
  constructor(
    public id?: string,
    public version?: string,
    public enabled?: boolean,
    public name?: string,
    public path?: string,
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);
    return this;
  }

  static New(version: string): IntegrationSettingsCobbaiItem {
    return {
      id: uuidv4(),
      version: version,
      enabled: true,
      name: "New project",
      path: "",
    } as IntegrationSettingsCobbaiItem;
  }
}

export class IntegrationSettingsAmplitudeDestination extends Serializable {
  constructor() {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);
    return this;
  }

  static New(): IntegrationSettingsAmplitudeDestination {
    return {} as IntegrationSettingsAmplitudeDestination;
  }
}

export class IntegrationSettingsSegmentDestination extends Serializable {
  constructor(public server_url?: string) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);
    return this;
  }

  static New(): IntegrationSettingsSegmentDestination {
    return { server_url: "" } as IntegrationSettingsSegmentDestination;
  }
}

export class IntegrationSettingsRudderstackDestination extends Serializable {
  constructor(public server_url?: string) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);
    return this;
  }

  static New(): IntegrationSettingsRudderstackDestination {
    return { server_url: "" } as IntegrationSettingsRudderstackDestination;
  }
}

export class IntegrationSettingsSlack extends Serializable {
  constructor() {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);
    return this;
  }

  static New(): IntegrationSettingsSlack {
    return {} as IntegrationSettingsSlack;
  }
}

export class IntegrationSettingsAtlassian extends Serializable {
  constructor(public cloud_id?: string) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);
    return this;
  }

  static New(): IntegrationSettingsAtlassian {
    return {} as IntegrationSettingsAtlassian;
  }
}

export class IntegrationSettings extends Serializable {
  constructor(
    // forward
    public harvestr?: IntegrationSettingsHarvestr,
    public productboard?: IntegrationSettingsProductboard,
    public intercom?: IntegrationSettingsIntercom,
    public zendesk?: IntegrationSettingsZendesk,
    public trello?: IntegrationSettingsTrello,
    public cycle?: IntegrationSettingsCycle,
    public monday?: IntegrationSettingsMonday,
    public asana?: IntegrationSettingsAsana,

    // cdp
    public amplitude_source?: IntegrationSettingsAmplitudeSource,
    public segment_source?: IntegrationSettingsSegmentSource,
    public rudderstack_source?: IntegrationSettingsRudderstackSource,
    public contentsquare_source?: IntegrationSettingsContentSquareSource,
    public typeform?: IntegrationSettingsTypeform,

    // hook
    public stitch?: IntegrationSettingsStitch,
    public webhook?: IntegrationSettingsWebhook,
    public zapier?: IntegrationSettingsZapier,
    public cobbai?: IntegrationSettingsCobbai,
    public atlassian?: IntegrationSettingsAtlassian,
    // public notion?: IntegrationSettingsNotion,
    public amplitude_destination?: IntegrationSettingsAmplitudeDestination,
    public segment_destination?: IntegrationSettingsSegmentDestination,
    public rudderstack_destination?: IntegrationSettingsRudderstackDestination,

    // reporting
    public slack?: IntegrationSettingsSlack,
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);

    // forward
    if (json["harvestr"])
      this.harvestr = new IntegrationSettingsHarvestr().fromJson(
        json["harvestr"],
      );
    if (json["productboard"])
      this.productboard = new IntegrationSettingsProductboard().fromJson(
        json["productboard"],
      );
    if (json["intercom"])
      this.intercom = new IntegrationSettingsIntercom().fromJson(
        json["intercom"],
      );
    if (json["zendesk"])
      this.zendesk = new IntegrationSettingsZendesk().fromJson(json["zendesk"]);
    if (json["trello"])
      this.trello = new IntegrationSettingsTrello().fromJson(json["trello"]);
    if (json["cycle"])
      this.cycle = new IntegrationSettingsCycle().fromJson(json["cycle"]);
    if (json["monday"])
      this.monday = new IntegrationSettingsMonday().fromJson(json["monday"]);
    if (json["asana"])
      this.asana = new IntegrationSettingsAsana().fromJson(json["asana"]);

    // cdp
    if (json["amplitude_source"])
      this.amplitude_source = new IntegrationSettingsAmplitudeSource().fromJson(
        json["amplitude_source"],
      );
    if (json["segment_source"])
      this.segment_source = new IntegrationSettingsSegmentSource().fromJson(
        json["segment_source"],
      );
    if (json["rudderstack_source"])
      this.rudderstack_source =
        new IntegrationSettingsRudderstackSource().fromJson(
          json["rudderstack_source"],
        );
    if (json["typeform"])
      this.typeform = new IntegrationSettingsTypeform().fromJson(
        json["typeform"],
      );

    // hook
    if (json["stitch"])
      this.stitch = new IntegrationSettingsStitch().fromJson(json["stitch"]);
    if (json["webhook"])
      this.webhook = new IntegrationSettingsWebhook().fromJson(json["webhook"]);
    if (json["zapier"])
      this.zapier = new IntegrationSettingsZapier().fromJson(json["zapier"]);
    if (json["cobbai"])
      this.cobbai = new IntegrationSettingsCobbai().fromJson(json["cobbai"]);
    if (json["atlassian"])
      this.atlassian = new IntegrationSettingsAtlassian().fromJson(
        json["atlassian"],
      );
    if (json["amplitude_destination"])
      this.amplitude_destination =
        new IntegrationSettingsAmplitudeDestination().fromJson(
          json["amplitude_destination"],
        );
    if (json["segment_destination"])
      this.segment_destination =
        new IntegrationSettingsSegmentDestination().fromJson(
          json["segment_destination"],
        );
    if (json["rudderstack_destination"])
      this.rudderstack_destination =
        new IntegrationSettingsRudderstackDestination().fromJson(
          json["rudderstack_destination"],
        );

    // reporting
    if (json["slack"])
      this.slack = new IntegrationSettingsSlack().fromJson(json["slack"]);

    return this;
  }

  static New(type: IntegrationType): IntegrationSettings {
    switch (type) {
      // forward
      case "harvestr":
        return {
          harvestr: IntegrationSettingsHarvestr.New(),
        } as IntegrationSettings;
      case "productboard":
        return {
          productboard: IntegrationSettingsProductboard.New(),
        } as IntegrationSettings;
      case "intercom":
        return {
          intercom: IntegrationSettingsIntercom.New(),
        } as IntegrationSettings;
      case "zendesk":
        return {
          zendesk: IntegrationSettingsZendesk.New(),
        } as IntegrationSettings;
      case "trello":
        return {
          trello: IntegrationSettingsTrello.New(),
        } as IntegrationSettings;
      case "cycle":
        return {
          cycle: IntegrationSettingsCycle.New(),
        } as IntegrationSettings;
      case "monday":
        return {
          monday: IntegrationSettingsMonday.New(),
        } as IntegrationSettings;
      case "asana":
        return {
          asana: IntegrationSettingsAsana.New(),
        } as IntegrationSettings;

      // cdp
      case "amplitude-source":
        return {
          amplitude_source: IntegrationSettingsAmplitudeSource.New(),
        } as IntegrationSettings;
      case "segment-source":
        return {
          segment_source: IntegrationSettingsSegmentSource.New(),
        } as IntegrationSettings;
      case "rudderstack-source":
        return {
          rudderstack_source: IntegrationSettingsRudderstackSource.New(),
        } as IntegrationSettings;
      case "contentsquare-source":
        return {
          contentsquare_source: IntegrationSettingsContentSquareSource.New(),
        } as IntegrationSettings;
      case "typeform":
        return {
          typeform: IntegrationSettingsTypeform.New(),
        } as IntegrationSettings;

      // hook
      case "webhook":
        return {
          webhook: IntegrationSettingsWebhook.New(),
        } as IntegrationSettings;
      case "stitch":
        return {
          stitch: IntegrationSettingsStitch.New(),
        } as IntegrationSettings;
      case "zapier":
        return {
          zapier: IntegrationSettingsZapier.New(),
        } as IntegrationSettings;
      case "cobbai":
        return {
          cobbai: IntegrationSettingsCobbai.New(),
        } as IntegrationSettings;
      case "notion":
        return {} as IntegrationSettings;
      case "atlassian":
        return {
          atlassian: IntegrationSettingsAtlassian.New(),
        } as IntegrationSettings;
      case "amplitude-destination":
        return {
          amplitude_destination: IntegrationSettingsAmplitudeDestination.New(),
        } as IntegrationSettings;
      case "segment-destination":
        return {
          segment_destination: IntegrationSettingsSegmentDestination.New(),
        } as IntegrationSettings;
      case "rudderstack-destination":
        return {
          rudderstack_destination:
            IntegrationSettingsRudderstackDestination.New(),
        } as IntegrationSettings;

      // reporting
      case "slack":
        return { slack: IntegrationSettingsSlack.New() } as IntegrationSettings;
    }

    throw Error("unexpected integration type");
  }
}

export class Integration extends Serializable {
  constructor(
    public id?: string,
    public org_id?: string,
    public type?: IntegrationType,
    public token?: string,
    public client_id?: string,
    public client_secret?: string,
    public settings?: IntegrationSettings,
    public auth_ok?: boolean,
    public auth_user_id?: string,
    public scopes?: string[],

    public created_at?: Date,
    public updated_at?: Date,
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);

    this.type = json["type"] as IntegrationType;
    if (json["settings"])
      this.settings = new IntegrationSettings().fromJson(json["settings"]);

    return this;
  }

  static New(org_id: string, type: IntegrationType): Integration {
    return {
      org_id,
      type,
      token: null,
      settings: IntegrationSettings.New(type),
    } as Integration;
  }
}
