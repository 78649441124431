<section>
  <div *ngIf="integration">
    <h2>API Key</h2>
    <text-snippet-copy
      [reset]="true"
      [copyText]="integration.token"
      (onReset)="onReset()"
      (onCopy)="onCopy()"
    ></text-snippet-copy>
  </div>
</section>
