/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/no-output-rename */

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { integrationUpdate } from "components/integration/settings/integration-settings.component";

import { autoTips } from "helpers/form-errors.helper";
import { NotificationHelper } from "helpers/notification.helper";
import { IntegrationDao } from "models/integration.dao";
import { Integration, IntegrationSettings } from "models/integrations.model";
import { Org } from "models/org.model";
import { TrackingEventName } from "services/trackers.events";
import { TrackersService } from "services/trackers.service";

@Component({
  selector: "integration-settings-segment-destination",
  templateUrl: "./segment-destination-settings.component.html",
  styleUrls: ["./segment-destination-settings.component.scss"],
})
export class IntegrationSettingsSegmentDestinationComponent implements OnInit {
  @Input() public org: Org = null;
  @Input() public integration: Integration = null;

  @Output() public valid = new EventEmitter<boolean>();
  @Output("onSettingsChange") public settings =
    new EventEmitter<integrationUpdate>();

  public validateForm: FormGroup = null;
  public autoTips = autoTips;

  constructor(
    public trackersService: TrackersService,
    public integrationDao: IntegrationDao,
    public notificationHelper: NotificationHelper,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit() {
    this.validateForm = this.formBuilder.group({
      server: [
        this.integration?.settings?.segment_destination?.server_url ??
          "https://api.segment.io/v1",
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(100),
        ],
      ],
      token: [
        this.integration?.token,
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(100),
        ],
      ],
    });
    this.validateSettings();

    this.validateForm.valueChanges.subscribe(() => {
      this.validateSettings();
    });
  }

  public validateSettings() {
    this.valid.emit(this.validateForm.valid);
    this.settings.emit({
      token: this.validateForm.value["token"],
      settings: new IntegrationSettings().fromJson({
        segment_destination: {
          server_url: this.validateForm.value["server"],
        },
      }),
    });
  }

  private segmentEventTrack(eventName: TrackingEventName) {
    this.trackersService
      .newEventTrackingBuilder(eventName)
      .withOrg(this.org)
      .withIntegration(this.integration)
      .build();
  }
}
