<header>
  <div class="header-title">
    <h1>
      {{ currentUserGroup.favorite ? "⭐️" : "" }}
      {{ groupName }}
    </h1>

    <div class="header-right">
      <a
        *ngIf="featureFlaggingService.canBulkImportGroupAssignation()"
        nz-button
        nzSize="large"
        routerLink="/org/{{ org.id }}/people/segment/{{
          currentUserGroup.id
        }}/bulk-import"
      >
        Import
      </a>
      <filters-csv-export
        forceMode="filters"
        [org]="org"
        type="respondent"
      ></filters-csv-export>
      <a
        *ngIf="'survey.create' | hasPermission"
        class="ask-a-question-button"
        routerLink="/org/{{ org.id }}/survey/create"
        [queryParams]="{ segmentId: currentUserGroup.id }"
        title="Ask a Question to this Segment"
        nz-button
        nzType="default"
        nzSize="large"
        nzGhost
      >
        <img
          alt="Ask a Question"
          class="icon"
          src="/assets/icons/common/ask-a-question.svg"
        />
        Ask a Question
      </a>

      <div class="analytics-filters">
        <survey-stats-filter-date
          hidden
          reporting="segment"
          theme="dark"
          [org]="org"
        ></survey-stats-filter-date>
      </div>
    </div>
  </div>
  <section class="header-indicators" nz-row [nzGutter]="[56, 0]">
    <div nz-col nzSpan="24" nzXl="8">
      <survey-stats-indicators-single-indicator
        title="Total number of User in Segment"
        [value]="totalNumberOfUserInSegment ?? 0"
        [loading]="loadings.getUserCount && initialFetch"
        format="number"
        theme="dark"
      ></survey-stats-indicators-single-indicator>
    </div>
    <div nz-col nzSpan="24" nzXl="8">
      <survey-stats-indicators-single-indicator
        title="NPS for this segment"
        [value]="npsAggregation?.score ?? 0"
        [loading]="loadings.getNps && initialFetch"
        format="number"
        theme="dark"
      ></survey-stats-indicators-single-indicator>
    </div>
    <div nz-col nzSpan="24" nzXl="8">
      <survey-stats-indicators-single-indicator
        title="Number of Survey Responses for this Segment"
        [value]="numberOfSurveyResponsesForThisSegment"
        [loading]="loadings.getAnwersCount && initialFetch"
        format="number"
        theme="dark"
      ></survey-stats-indicators-single-indicator>
    </div>
  </section>
</header>

<div class="content">
  <error-message
    *ngIf="error"
    message="An error occured when fetching data."
    [details]="error.message"
    (reload)="analyticsFilterService.triggerChange()"
  ></error-message>

  <ng-container *ngIf="!error">
    <section nz-row [nzGutter]="[32, 16]" class="middle-indicators">
      <div nz-col class="fullsize-indicator" nzSpan="24" nzXl="8">
        <h2>Top 10 Events Triggered</h2>

        <div class="indicator-with-border indicator-scrollable">
          <nz-spin
            nzSimple
            nzSize="large"
            *ngIf="loadings.getMostTrackedEvents && initialFetch"
          ></nz-spin>
          <user-events
            *ngIf="!loadings.getMostTrackedEvents || !initialFetch"
            [userEvents]="mostTriggeredEvents"
          ></user-events>
        </div>
      </div>
      <div nz-col class="fullsize-indicator" nzSpan="24" nzXl="8">
        <h2>Average Number of Triggers per User</h2>

        <div class="indicator-with-border indicator-scrollable">
          <nz-spin
            nzSimple
            nzSize="large"
            *ngIf="loadings.getMostTrackedEvents && initialFetch"
          ></nz-spin>
          <user-events
            *ngIf="!loadings.getMostTrackedEvents || !initialFetch"
            [userEvents]="mostTriggeredEventsAveragePerUser"
          ></user-events>
        </div>
      </div>
      <div nz-col class="fullsize-indicator" nzSpan="24" nzXl="8">
        <h2>Segment / Event Correlation Score</h2>

        <div class="indicator-with-border height-auto">
          <div class="correlation-label">Select an event</div>
          <nz-select
            class="correlation-picker"
            nzSize="large"
            [nzShowSearch]="true"
            [(ngModel)]="selectedCorrelationEvent"
            (ngModelChange)="selectedCorrelationEventChange($event)"
          >
            <nz-option
              *ngFor="let event of registryEntriesEvent; let eventIndex = index"
              [nzValue]="event.id"
              [nzLabel]="event.slug"
            ></nz-option>
          </nz-select>
          <correlation-indicator
            class="correlation-indicator"
            label="Correlation Score with this Segment:"
            [value]="selectedCorrelationEventValue"
            [loading]="loadings.getEventCorrelation"
          ></correlation-indicator>
        </div>
        <card-101-link
          color="var(--screeb-color-success-outline)"
          title="What is the Correlation Score?"
          description="<p>The Correlation Score is a score from -1 to 1 showing how much an event is triggered by a segment of users, <b>compared to other segments.</b></p>
        If an event is <b>triggered by every member</b> of a segment, and by no other user in another segment, then the event is <b>highly correlated</b> with this segment, and the score will be 1. If the event is <b>not triggered</b> in this segment but triggered in other segments, then it will have a <b>-1 score</b>."
        ></card-101-link>
      </div>
    </section>

    <section nz-row [nzGutter]="[32, 16]">
      <div nz-col nzSpan="16">
        <h2>Activity Trend for this Segment</h2>

        <div class="indicator-with-border trend-indicator">
          <nz-spin
            nzSimple
            nzSize="large"
            *ngIf="loadings.getActivityTrendEvents && initialFetch"
          ></nz-spin>
          <survey-stats-indicators-trend-indicator
            *ngIf="!loadings.getActivityTrendEvents || !initialFetch"
            theme="segment"
            [datasets]="trendChartDataset"
            [legend]="trendChartLegend"
            [hideLegend]="true"
            [releases]="releases"
            [orgAccounts]="orgAccounts"
            [orgId]="org.id"
          ></survey-stats-indicators-trend-indicator>
        </div>
      </div>
      <div nz-col nzSpan="8">
        <h2>Responses Emotions Analysis</h2>
        <div class="indicator-with-border with-padding">
          <survey-stats-indicators-radar-indicator
            [labels]="[
              '😡 Anger',
              '&nbsp;😄 Joy&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;',
              '😱 Fear',
              '😢 Sadness',
            ]"
            [legend]="emotionsChartLegend"
            [datasets]="emotionsChartDatasets"
            [loading]="loadingEmotions"
          />
        </div>
      </div>
    </section>

    <section nz-row [nzGutter]="[32, 16]">
      <div nz-col nzSpan="24" nzXl="8">
        <h2>List of Users in this Segment</h2>

        <div
          class="indicator-with-border trend-indicator indicator-scrollable users-list"
        >
          <nz-spin
            nzSimple
            nzSize="large"
            *ngIf="loadings.getUserCount && initialFetch"
          ></nz-spin>
          <user-simple-list
            *ngIf="!loadings.getUserCount || !initialFetch"
            [users]="users"
          ></user-simple-list>
          <button
            class="load-more-button"
            nz-button
            nzType="link"
            (click)="loadMoreUsers()"
            [nzLoading]="fetchingMoreUsers"
            *ngIf="
              (!loadings.getUserCount || !initialFetch) &&
              users.length < totalNumberOfUserInSegment
            "
          >
            Load more
          </button>
        </div>
      </div>
      <div nz-col nzSpan="24" nzXl="16">
        <section nz-row [nzGutter]="[32, 16]">
          <div nz-col nzSpan="24" nzXl="12">
            <div>
              <h2>Most active user</h2>

              <div class="indicator-with-border users-list">
                <nz-spin
                  nzSimple
                  nzSize="large"
                  *ngIf="loadings.getSpecificUsersMostActive && initialFetch"
                ></nz-spin>
                <user-simple-list
                  *ngIf="!loadings.getSpecificUsersMostActive || !initialFetch"
                  [users]="mostActiveUsers"
                ></user-simple-list>
              </div>
            </div>

            <div class="margin-top-indicator">
              <h2>Least active user</h2>

              <div class="indicator-with-border users-list">
                <nz-spin
                  nzSimple
                  nzSize="large"
                  *ngIf="loadings.getSpecificUsersLeastActive && initialFetch"
                ></nz-spin>
                <user-simple-list
                  *ngIf="!loadings.getSpecificUsersLeastActive || !initialFetch"
                  [users]="leastActiveUsers"
                ></user-simple-list>
              </div>
            </div>

            <div class="margin-top-indicator">
              <h2>Top MRR in this segment</h2>

              <div class="indicator-with-border users-list">
                <nz-spin
                  nzSimple
                  nzSize="large"
                  *ngIf="loadings.getSpecificUsersTopMrr && initialFetch"
                ></nz-spin>
                <user-simple-list
                  *ngIf="!loadings.getSpecificUsersTopMrr || !initialFetch"
                  [users]="topMRRUsers"
                ></user-simple-list>
              </div>
            </div>

            <div class="margin-top-indicator">
              <h2>Top NPS in this segment</h2>

              <div class="indicator-with-border users-list">
                <user-simple-list [users]="topNPSUsers"></user-simple-list>
              </div>
            </div>
          </div>
          <div nz-col nzSpan="24" nzXl="12">
            <div>
              <h2>Most common properties</h2>

              <div class="indicator-with-border">
                <nz-spin
                  nzSimple
                  nzSize="large"
                  *ngIf="loadings.getMostTrackedProperties && initialFetch"
                ></nz-spin>
                <user-properties
                  *ngIf="!loadings.getMostTrackedProperties || !initialFetch"
                  [userProperties]="mostTrackedProperties"
                ></user-properties>
              </div>
            </div>
            <div class="margin-top-indicator">
              <card-101-link
                color="var(--screeb-color-info-outline)"
                preTitle="Know who's using your product and spot common attributes among your users by sharing Properties with Screeb."
                title="Learn how to set up User Properties here."
                url="https://help.screeb.app/en/articles/8611571-all-about-users-properties"
                imageLink="/assets/illustrations/user-qualification-2.png"
                contentAlign="flex-start"
                backgroundAlign="center"
                backgroundSize="cover"
                linearGradient="0deg, rgba(208, 228, 253, 0) 0%, rgba(208, 228, 253, 1) 100%"
              ></card-101-link>
            </div>
          </div>
        </section>
      </div>
    </section>
  </ng-container>
</div>
