import { Component, Input, OnInit } from "@angular/core";
import { TargetingRule } from "models/targeting-rule.model";
import { ChannelClientScreen } from "models/channel-screen.model";
import {
  onOrRuleChange,
  onOrRuleChangeEnsureEmptyEndingField,
} from "../utils/rule-change";
import { RegistryEntrySource } from "models/registry.model";

@Component({
  selector: "rule-screen",
  templateUrl: "./rule-screen.component.html",
  styleUrls: ["./rule-screen.component.scss"],
})
export class TargetingRuleScreenComponent implements OnInit {
  @Input() public rule: TargetingRule = null;
  @Input() public source: RegistryEntrySource = null; // @TODO: remove at the same time than legacy distribs
  @Input() public channelClientScreens: ChannelClientScreen[] = [];

  private screens: ChannelClientScreen[] = [];
  public androidScreens: ChannelClientScreen[] = [];
  public iosScreens: ChannelClientScreen[] = [];

  public onOrRuleChange = onOrRuleChange;

  constructor() {}

  ngOnInit() {
    onOrRuleChangeEnsureEmptyEndingField(this.rule, false);

    if (this.source) {
      this.screens = this.channelClientScreens.filter(
        (v) => v.source === this.source,
      );
    } else {
      this.screens = this.channelClientScreens;
    }

    this.androidScreens = this.screens.filter(
      (v) => v.source === "sdk-android",
    );
    this.iosScreens = this.screens.filter((v) => v.source === "sdk-ios");
  }

  public trackByFn(index: any) {
    return index;
  }

  public checkValidValue(r: TargetingRule, index: number): boolean {
    if (index === 0 && r.value.v_s_arr[index].trim().length === 0) {
      return false;
    }

    return true;
  }
}
