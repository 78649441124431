import { Component, Input } from "@angular/core";
import { ScreebIconSize } from "components/utils/screeb-icon/screeb-icon.types";

@Component({
  selector: "brand-card",
  template: `
    <div class="brand-card" [class]="{ active: active }">
      <div class="card-header" [style.background-color]="color">
        <div class="circle">
          <div
            class="circle-active"
            [style]="{
              opacity: active ? 1 : 0,
            }"
          ></div>
        </div>
        <screeb-icon
          *ngIf="!!icon"
          class="logo"
          [size]="folder"
          [icon]="icon"
        />
      </div>
      <div class="card-content">
        <p>{{ title }}</p>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        min-width: 100px;
        min-height: 50px;
      }

      .brand-card {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        border-radius: 16px;
        border: 1px solid var(--screeb-color-border-secondary);

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        cursor: pointer;

        /*transition: border ease-in-out 150ms;*/
      }

      .brand-card.active {
        outline: 3px solid var(--screeb-color-purple-500);
        outline-offset: -1px;
      }

      .brand-card.active .card-header {
        width: 100%;
        height: 66%;
        border-top-left-radius: 14px;
        border-top-right-radius: 14px;
      }

      .brand-card.active .card-header .circle {
        border: 2px solid var(--screeb-color-purple-500);
      }

      .card-header {
        display: flex;
        width: 100%;
        height: 66%;
        min-height: 66%;
        overflow: hidden;
        position: relative;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      .card-header .circle {
        position: absolute;
        left: 16px;
        top: 16px;
        width: 24px;
        height: 24px;
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;

        background-color: var(--screeb-color-background);
        border: 1px solid var(--screeb-color-border-secondary);

        transition: border ease-in-out 150ms;
      }

      .card-header .circle .circle-active {
        width: 16px;
        height: 16px;
        border-radius: 50%;

        background-color: var(--screeb-color-purple-500);
        transition: opacity ease-in-out 150ms;
      }

      .card-header .logo {
        height: 110% !important;
        width: inherit !important;
      }

      .card-header .logo ::ng-deep svg {
        width: inherit;
        height: inherit;
        fill: var(--screeb-color-white);
      }

      .card-content {
        width: 100%;
        height: 33%;
        display: flex;
        align-items: center;
      }

      .card-content p {
        padding-left: 24px;
        margin: 0;
        width: 100%;
        font-family: "Rubik";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
      }
    `,
  ],
})
export class BrandCardComponent {
  @Input() title: string = null;
  @Input() folder: ScreebIconSize = null;
  @Input() icon: string = null;
  @Input() color: string = null;
  @Input() active: boolean = false;
  constructor() {}
}
