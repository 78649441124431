import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { PageComponentInterface } from "components/PageComponentInterface";

import { Org } from "models/org.model";

import { AnalyticsFilterService } from "services/analytics-filters.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { PermissionsService } from "services/permissions.service";
import { RoutingService } from "services/routing.service";
import { UIService } from "services/ui.service";
import { RegistryEntry } from "models/registry.model";
import { LanguageWithEmoji } from "resolvers/asset-languages-countries";

@Component({
  selector: "user-record-list-page",
  templateUrl: "./user-record-list.component.html",
  styleUrls: ["./user-record-list.component.scss"],
})
export class UserRecordListPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Sessions replay";
  public name = "Sessions replay";

  private obs: any = null;
  public org: Org;
  public languages: LanguageWithEmoji[] = [];
  public registryEntriesIdentityProperty: RegistryEntry[] = [];
  public registryEntriesGroup: RegistryEntry[] = [];
  public registryEntriesEvent: RegistryEntry[] = [];

  public keyword = "";
  public searching = false;
  public loading = true;
  public isUsersLoading = true;

  public isSummaryLoading = true;
  public summaryTrimLength = 450;
  public summary: string | null = null;
  public summaryError: Error;
  public viewMoreSummary = false;

  public sessionReplayEnabled: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private routingService: RoutingService,
    public analyticsFilterService: AnalyticsFilterService,
    public featureFlaggingService: FeatureFlaggingService,
    public permissionsService: PermissionsService,
    public uiService: UIService,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.obs = this.route.data.subscribe((data) => {
      this.org = data.org;
      this.registryEntriesGroup = data.registryEntriesGroup.groups;
      this.languages = data.languages_and_countries.surveyLanguagesWithEmojis;
      this.registryEntriesIdentityProperty =
        data.registryEntriesIdentityProperty.filter(
          (entry: RegistryEntry) => entry.type !== "object",
        );
      this.registryEntriesEvent = data.registryEntriesEvent;

      this.sessionReplayEnabled =
        this.org.settings?.session_replay_enabled &&
        this.uiService.currentSuperOrg.entitlements.session_recording_enabled;
    });
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  public joinWaitingList() {
    // @ts-ignore
    window.$screeb("survey.start", "d5f95caa-9099-4a58-a997-ebd11e23b28a");
  }
}
