<div *ngIf="rule">
  <nz-select
    nzMode="multiple"
    class="rule-type-survey-values"
    [nzDisabled]="!('survey_edition' | hasEntitlement)"
    [ngClass]="{ invalid: rule.value.v_s_arr.length === 0 }"
    [(ngModel)]="rule.value.v_s_arr"
    [nzDropdownMatchSelectWidth]="false"
    nzPlaceHolder="Select survey(s)"
    nzShowSearch
  >
    <nz-option
      *ngFor="let survey of surveys"
      [nzValue]="survey.id"
      [nzLabel]="survey.title"
    ></nz-option>
  </nz-select>
</div>
