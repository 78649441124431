import {
  BillingSettings,
  notFreeSubscriptionOptions,
  OrgBillingCoupon,
  PlanBillingSettings,
  PlanType,
  scalePlanDefaultMAU,
  subscriptionOptions,
  subscriptionOptionsAddon,
} from "models/org_billing.model";
import { addonInfos } from "./components/billing-plan/billing-plan.data";

export const defaultScaleSubscriptionOptions: notFreeSubscriptionOptions = {
  billingCycle: "year",
  planType: "scale",
  addons: {
    whiteLabel: false,
    additionalWorkspaces: 0,
    responseTranslation: false,
    audioVideoSurvey: false,
    // aiContentAnalysis: false,
    // messageInApp: false,
    surveyInAppMobile: false,
    graphicalSurveyCustomization: false,
    iamMFA: false,
    // sessionRecording: false,
  },
  mtu: scalePlanDefaultMAU,
};

export type lateralPanelMode =
  | "address"
  | "payment_methods_list"
  | "payment_method_edit"
  | "change_billing_cycle"
  | "cancel"
  | "activate_addon"
  | "subscription"
  | "confirm_subscription"
  | "invoices";

export const YearlyDiscount = 0.1;

export function couponToString(coupon: OrgBillingCoupon): string {
  if (coupon.type === "amount") {
    return "€" + coupon.amount_off + " off";
  } else if (coupon.type === "percentage") {
    return "" + coupon.percent_off + "% off";
  }

  throw Error("unexpected coupon type");
}

export function mtuToPlanId(
  planType: "scale" | "advanced",
  billingSettings: BillingSettings,
  mtu: number,
): PlanBillingSettings {
  switch (planType) {
    case "scale":
      return billingSettings.plans.scale.products.find((p) => p.mtu === mtu);
    case "advanced":
      return billingSettings.plans.advanced.products.find((p) => p.mtu === mtu);
  }
}

export function subscriptionOptionsToAddonArray(
  subscriptionOptions: subscriptionOptions,
) {
  if (subscriptionOptions.planType === "free") {
    return [];
  }

  const addons: subscriptionOptionsAddon[] = [];

  if (subscriptionOptions.addons.whiteLabel) {
    addons.push({
      addon_key: "white-label",
      quantity: 1,
    });
  }

  if (subscriptionOptions.addons.additionalWorkspaces > 0) {
    addons.push({
      addon_key: "additional-workspaces",
      quantity: subscriptionOptions.addons.additionalWorkspaces,
    });
  }

  if (subscriptionOptions.addons.responseTranslation) {
    addons.push({
      addon_key: "response-translation",
      quantity: 1,
    });
  }

  if (subscriptionOptions.addons.graphicalSurveyCustomization) {
    addons.push({
      addon_key: "graphical-survey-customization",
      quantity: 1,
    });
  }

  if (subscriptionOptions.addons.audioVideoSurvey) {
    addons.push({
      addon_key: "audio-video-survey",
      quantity: 1,
    });
  }

  // if (subscriptionOptions.addons.aiContentAnalysis) {
  //   addons.push({
  //     addon_key: "ai-content-analysis",
  //     quantity: 1,
  //   });
  // }

  // if (subscriptionOptions.addons.messageInApp) {
  //   addons.push({
  //     addon_key: "message-inapp",
  //     quantity: 1,
  //   });
  // }

  if (subscriptionOptions.addons.surveyInAppMobile) {
    addons.push({
      addon_key: "survey-inapp-mobile",
      quantity: 1,
    });
  }

  if (subscriptionOptions.addons.iamMFA) {
    addons.push({
      addon_key: "iam-mfa",
      quantity: 1,
    });
  }

  // exclude addons included in the plan
  return addons.filter(
    (addon) =>
      !addonInfos[addon.addon_key].includedInPlans.includes(
        subscriptionOptions.planType,
      ),
  );
}

export function planIdToPlanType(
  planId: string,
  billingSettings: BillingSettings,
): PlanType {
  if (!planId) {
    return "free";
  }

  const scaleProduct = billingSettings.plans.scale.products.find(
    (p) => p.product === planId,
  );
  if (scaleProduct) {
    return "scale";
  }

  const advancedProduct = billingSettings.plans.advanced.products.find(
    (p) => p.product === planId,
  );
  if (advancedProduct) {
    return "advanced";
  }

  if (billingSettings.plans.scale.old_products.includes(planId)) {
    return "scale";
  }

  if (billingSettings.plans.advanced.old_products.includes(planId)) {
    return "advanced";
  }

  return "custom";
}
