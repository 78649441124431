import { inject } from "@angular/core";
import { CanActivateFn } from "@angular/router";
import { ApplicationLoadService } from "services/app.service";
import { SettingsService } from "services/settings.service";
import { UpgradeAdminService } from "services/upgrade.service";

export function appGuard(): CanActivateFn {
  return async () => {
    const settingsService = inject(SettingsService);
    const applicationLoadService = inject(ApplicationLoadService);
    const upgradeAdminService = inject(UpgradeAdminService);

    return settingsService
      .load()
      .then(() => applicationLoadService.loader())
      .then(() => upgradeAdminService.initialize())
      .then(() => {
        applicationLoadService.isMaintenance = false;
        return true;
      })
      .catch((err) => {
        console.error(err);
        applicationLoadService.isMaintenance = true;
        return false;
      });
  };
}
