import { FormControl } from "@angular/forms";

import { ENV } from "environment";

export function isPasswordStrengthValid(password: string) {
  // if (ENV["FAST_AND_FURIOUS_MODE"] === true) return null;

  if (!password) return null;

  const minLength = password.length >= 8;
  const hasDigit = /\d/.test(password);
  const hasAlpaLowercase = /[a-z]/.test(password);
  const hasAlpaUppercase = /[A-Z]/.test(password);
  const hasSpecialChar =
    password.replace(/\d/g, "").replace(/[A-Z]/g, "").replace(/[a-z]/g, "")
      .length > 0;

  const errors = {};
  if (!minLength) Object.assign(errors, { password_minlength: true });
  if (!hasDigit) Object.assign(errors, { password_strength_digit: true });
  if (!hasAlpaLowercase)
    Object.assign(errors, { password_strength_alpha_lowercase: true });
  if (!hasAlpaUppercase)
    Object.assign(errors, { password_strength_alpha_uppercase: true });
  if (!hasSpecialChar)
    Object.assign(errors, { password_strength_special_char: true });

  if (!Object.keys(errors).length) return null;
  return errors;
}

export function passwordStrengthValidator(control: FormControl) {
  if (ENV["FAST_AND_FURIOUS_MODE"] === true) return null;

  return isPasswordStrengthValid(control.value);
}
