<header>
  <h1>All responses</h1>
  <div class="analytics-filters">
    <survey-stats-filters
      reportType="all-responses"
      [canShare]="true"
      [org]="org"
      [survey]="survey"
      [reporting]="'Individual Responses'"
      [languages]="languages"
      [registryEntriesIdentityProperty]="registryEntriesIdentityProperty"
      [registryEntriesGroup]="registryEntriesGroup"
      [registryEntriesEvent]="registryEntriesEvent"
      type="response"
    ></survey-stats-filters>
  </div>
</header>

<div class="settings">
  <div class="setting">
    <h2 class="title">
      <screeb-icon size="md" icon="response" />
      Translation
    </h2>
    <p class="desc">
      Handle whether you want to translate the responses of your
      {{ uiService.isMessagePage ? "message" : "survey" }}
    </p>
    <tag-settings-translation
      [translationEnabled]="translationEnabled"
      [disabled]="!org.translation_enabled"
      (specificTranslationEnabledSwitchChange)="toggleTranslations($event)"
    ></tag-settings-translation>
  </div>
</div>

<survey-stats-individual-responses-questions-table
  [nodes]="nodes"
  [responsesCount]="responsesCount"
  [displaySurveyTitle]="false"
  [questionBodyView]="questionBodyView"
></survey-stats-individual-responses-questions-table>

<ng-template #questionBodyView let-item="item">
  <ng-container *ngIf="!item._node.isOpenEndedQuestion">
    <!-- questions ouvertes -->
    <survey-stats-individual-responses-bar-chart-single-question
      #barChart
      [org]="org"
      [survey]="survey"
      [node]="item._node"
    ></survey-stats-individual-responses-bar-chart-single-question>
    <ng-container *ngIf="barChart.buckets.length > 0">
      <div
        class="switch"
        (click)="
          expandedResponses[item.node.correlationId] =
            !expandedResponses[item.node.correlationId]
        "
      >
        <button
          *ngIf="expandedResponses[item.node.correlationId]"
          nz-button
          nzType="link"
        >
          Hide all results
          <i nz-icon nzType="up"></i>
        </button>
        <button
          *ngIf="!expandedResponses[item.node.correlationId]"
          nz-button
          nzType="link"
        >
          Show all results
          <i nz-icon nzType="down"></i>
        </button>
      </div>
      <survey-stats-individual-responses-answers-table-single-question
        *ngIf="expandedResponses[item.node.correlationId]"
        [org]="org"
        [survey]="survey"
        [getQuery]="getQuery"
        [nodeCorrelationId]="item.node.correlationId"
        [integrations]="integrations"
        [removable]="true"
      ></survey-stats-individual-responses-answers-table-single-question>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="item._node.isOpenEndedQuestion">
    <survey-stats-individual-responses-range-single-question
      *ngIf="item._node.questionType === 'range'"
      [org]="org"
      [survey]="survey"
      [node]="item._node"
    ></survey-stats-individual-responses-range-single-question>

    <survey-stats-individual-responses-answers-table-single-question
      [org]="org"
      [survey]="survey"
      [getQuery]="getQuery"
      [nodeCorrelationId]="item.node.correlationId"
      [integrations]="integrations"
      [removable]="true"
    ></survey-stats-individual-responses-answers-table-single-question>
  </ng-container>
</ng-template>
