<form
  nz-form
  [nzAutoTips]="autoTips"
  [formGroup]="validateForm"
  (ngSubmit)="save()"
>
  <nz-form-item>
    <nz-form-label>Name</nz-form-label>
    <nz-form-control>
      <input
        type="text"
        nz-input
        nzHasFeedback
        formControlName="name"
        placeholder="Name of the typeform"
      />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label>URL</nz-form-label>
    <nz-form-control>
      <text-snippet-copy
        [copyText]="url"
        [url]="url"
        [canOpen]="false"
        [withPadding]="false"
        (onCopy)="onWebhookCopy.emit(item)"
      ></text-snippet-copy>
    </nz-form-control>
  </nz-form-item>

  <h4>Link Typeform respondents to users in Screeb.</h4>
  <p>
    Select the hidden field or the question in which you identify your
    respondents and link it to the same property in Screeb. That’s needed to
    link Typeform responses in the right Screeb profiles!
  </p>

  <section nz-row [nzGutter]="[32, 16]">
    <div nz-col nzSpan="12">
      <nz-form-item>
        <nz-form-label>Match Typeform...</nz-form-label>
        <nz-radio-group
          formControlName="typeform_mode"
          (ngModelChange)="onTypeformModeChange()"
        >
          <div class="choice">
            <label nz-radio [nzValue]="'hidden_field'">Hidden field</label>
            <nz-form-control>
              <input
                type="text"
                nz-input
                nzHasFeedback
                formControlName="typeform_hidden_field"
                placeholder="eg: user_id"
                [disabled]="validateForm.get('typeform_hidden_field').disabled"
              />
            </nz-form-control>
          </div>

          <div class="choice">
            <label nz-radio [nzValue]="'ref'">Question</label>
            <nz-form-control>
              <input
                type="text"
                nz-input
                nzHasFeedback
                formControlName="typeform_ref"
                placeholder="Question reference"
                [disabled]="validateForm.get('typeform_ref').disabled"
              />
            </nz-form-control>
          </div>
        </nz-radio-group>
      </nz-form-item>
    </div>

    <div nz-col nzSpan="12">
      <nz-form-item>
        <nz-form-label>...with Screeb</nz-form-label>
        <nz-radio-group
          formControlName="screeb_mode"
          (ngModelChange)="onScreebModeChange()"
        >
          <div class="choice">
            <label nz-radio [nzValue]="'alias'">User identifier</label>
          </div>

          <br />

          <div class="choice">
            <label nz-radio [nzValue]="'property'">User property</label>
            <nz-form-control>
              <selector-user-properties
                [registryEntriesIdentityProperty]="
                  registryEntriesIdentityProperty
                "
                [ngModel]="validateForm.get('screeb_property_key').value"
                (ngModelChange)="
                  validateForm.get('screeb_property_key').setValue($event)
                "
                [ngModelOptions]="{ standalone: true }"
                ngDefaultControl
                [disabled]="validateForm.get('screeb_property_key').disabled"
              ></selector-user-properties>
              <!-- controlName="screeb_property_key" -->
            </nz-form-control>
          </div>
        </nz-radio-group>
      </nz-form-item>
    </div>
  </section>

  <div class="actions">
    <span class="push"></span>
    <button
      nz-button
      nzType="primary"
      (click)="save()"
      [disabled]="!validateForm.valid"
    >
      Save
    </button>
    <button nz-button nzType="default" (click)="cancel()">Cancel</button>
    <button nz-button nzType="dashed" nzDanger (click)="remove()">
      Remove
    </button>
  </div>
</form>
