import { inject, Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from "@angular/router";

import { ApplicationLoadService } from "services/app.service";
import { AuthService, SessionService } from "services/auth.service";

export function authGuard(): CanActivateFn {
  return (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const withRedirect = !window.location.pathname.includes("/auth/authorize");

    const applicationLoadService = inject(ApplicationLoadService);
    const authService = inject(AuthService);

    return applicationLoadService.loader().then((isAuth) => {
      if (isAuth === true) {
        return true;
      }

      if (withRedirect) {
        // not logged-in so, we redirect to login page
        authService.redirectOnLogin({ target: state.url });
      }

      return false;
    });
  };
}

@Injectable()
export class IsNotAuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private applicationLoadService: ApplicationLoadService,
    private sessionService: SessionService,
  ) {}

  canActivate(): Promise<boolean> {
    return this.applicationLoadService.loader().then((isAuth) => {
      if (isAuth === false) return true;
      this.router.navigate(["/"]);
      return false;
    });
  }

  canActivateChild(): Promise<boolean> {
    return this.canActivate();
  }
}

@Injectable()
export class EnsureNotAuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private applicationLoadService: ApplicationLoadService,
    private authService: AuthService,
  ) {}

  canActivate(): Promise<boolean> {
    return this.applicationLoadService.loader().then(async (isAuth) => {
      if (isAuth === true) await this.authService.logout(false);
      return true;
    });
  }

  canActivateChild(): Promise<boolean> {
    return this.canActivate();
  }
}
