import { Injectable } from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";
import { Router } from "@angular/router";

import { ConfigService } from "./config.service";

interface ISeoDataOg {
  url?: string;
  type?: string;
}

interface ISeoDataTwitter {
  type?: string;
  // site?: string
}

interface ISeoData {
  title?: string;
  title_suffix?: string;
  description?: string;
  keywords?: string;
  image?: string;
  og?: ISeoDataOg;
  twitter?: ISeoDataTwitter;
}

@Injectable()
class SeoService {
  private loaderPromise: Promise<any> = null;

  constructor(
    private title: Title,
    private meta: Meta,
    private router: Router,
    private configService: ConfigService,
  ) {}

  public set(data: ISeoData = {}) {
    const d = {
      title: "Continuous Product Discovery",
      title_suffix: " | Screeb",
      description: "Continuous Product Discovery Without the Time Sink",
      keywords:
        "Screeb, analytics, user, research, survey, question, response, aquisition, retention, ux, funnel",
      image:
        this.configService.config.selfEndpoint +
        "/assets/preview/screenshot3.png",
      og: {
        type: "website",
        url: this.configService.config.selfEndpoint + this.router.url,
      },
      twitter: {
        type: "summary",
        // site: null,
      },
      ...data,
    };

    this.title.setTitle(d.title + d.title_suffix);
    this.meta.updateTag({ name: "description", content: d.description });
    this.meta.updateTag({ name: "keywords", content: d.keywords });

    this.meta.updateTag({ property: "og:type", content: d.og.type });
    this.meta.updateTag({
      property: "og:title",
      content: d.title + d.title_suffix,
    });
    this.meta.updateTag({ property: "og:description", content: d.description });
    this.meta.updateTag({ property: "og:image", content: d.image });
    this.meta.updateTag({ property: "og:image:secure_url", content: d.image });
    this.meta.updateTag({ property: "og:url", content: d.og.url });
    this.meta.updateTag({ property: "og:site_name", content: "Screeb" });

    this.meta.updateTag({ name: "twitter:card", content: d.twitter.type });
    // this.meta.updateTag({ name: 'twitter:site', content: d.twitter.site });
    this.meta.updateTag({
      name: "twitter:title",
      content: d.title + d.title_suffix,
    });
    this.meta.updateTag({
      name: "twitter:description",
      content: d.description,
    });
    this.meta.updateTag({ name: "twitter:image", content: d.image });

    this.meta.removeTag("name=twitter\\:creator");
    this.meta.addTag({ name: "twitter:creator", content: "@ScreebApp" });
  }
}

export { SeoService };
