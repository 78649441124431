import { Injectable } from "@angular/core";

import { ScreebApiHelper } from "helpers/screeb-api.helper";

@Injectable()
export class DebugDao {
  constructor(private screebApiHelper: ScreebApiHelper) {}

  public askPrompt(
    workspaceId: string,
    surveyId: string,
    systemPrompt: string,
    userPrompt: string,
    size: number,
  ): Promise<object> {
    const body = {
      org_id: workspaceId,
      survey_id: surveyId,
      system_prompt: systemPrompt,
      user_prompt: userPrompt,
      size: size,
    };
    return this.screebApiHelper
      .post<object>(`/prettygift`, body)
      .toPromise()
      .then((data: object) => {
        return {
          result: data["result"][0],
          total: data["total_anwsers"],
        };
      });
  }
}
