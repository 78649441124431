<div
  class="preview-container"
  [style]="getCssVars()"
  [ngClass]="
    mode +
    ' ' +
    scope +
    ' ' +
    'preview-' +
    format +
    ' ' +
    'preview-link-' +
    tagSettings.link_position
  "
  [class.preview-email-center-left]="
    emailPreview === 'out' && tagSettings.emails_position === 'center-left'
  "
  [class.preview-email-center-right]="
    emailPreview === 'out' && tagSettings.emails_position === 'center-right'
  "
>
  @let currentFormat = getFormat(format, tagSettings.format);
  <div class="mode" *ngIf="!forceMobile">
    <nz-radio-group [(ngModel)]="mode">
      <label class="mode-button" nz-radio nzValue="desktop">
        <i nz-icon nzType="desktop" nzTheme="outline"></i>
      </label>
      <label class="mode-button" nz-radio nzValue="mobile">
        <i nz-icon nzType="mobile" nzTheme="outline"></i>
      </label>
    </nz-radio-group>
  </div>
  <div *ngIf="format === 'email'" class="email-preview">
    <nz-radio-group
      [(ngModel)]="emailPreview"
      nzButtonStyle="solid"
      nzSize="large"
    >
      <label nz-radio-button nzValue="in">
        <span class="email-preview-label">
          <screeb-icon size="md" icon="email"></screeb-icon>
          Embedded
        </span>
      </label>
      <label nz-radio-button nzValue="out">
        <span class="email-preview-label">
          <screeb-icon size="md" icon="desktop"></screeb-icon>
          Browser
        </span>
      </label>
    </nz-radio-group>
    <div class="help-icon">
      <screeb-icon
        size="sm"
        icon="info-circle"
        nz-popover
        nzType="primary"
        nzPopoverPlacement="topLeft"
        [nzPopoverContent]="contentTemplate"
      ></screeb-icon>
    </div>
    <ng-template #contentTemplate>
      <span>
        <strong>Embedded:</strong>
        Preview the survey directly within your email.
      </span>
      <br />
      <span>
        <strong>Browser:</strong>
        Preview the survey in the respondent's web browser.
      </span>
    </ng-template>
  </div>

  <div
    *ngIf="
      (format === 'link' &&
        tagSettings.link_position !== 'center-center' &&
        mode !== 'mobile') ||
      (format === 'email' &&
        emailPreview === 'out' &&
        tagSettings.emails_position !== 'center-center' &&
        mode !== 'mobile')
    "
    class="link-side-container email-side-container"
    [ngStyle]="{
      'background-image': 'url(\'' + getBackgroundImage() + '\')',
      'background-color': getBackgroundColor(),
    }"
  ></div>

  <div
    class="messages-wrapper"
    [ngClass]="{
      linkSharing:
        format === 'link' ||
        format === 'hosted-page' ||
        (format === 'email' && emailPreview === 'out'),
      emailWrapper: format === 'email' && emailPreview === 'in',
      inPageBackground: format === 'in_page',
      linkNotCentered:
        format === 'link' && tagSettings.link_position !== 'center-center',
      emailNotCentered:
        format === 'email' && tagSettings.emails_position !== 'center-center',
    }"
    [ngStyle]="{
      'background-image':
        (format === 'link' && tagSettings.link_position === 'center-center') ||
        (format === 'email' &&
          (tagSettings.emails_position === 'center-center' ||
            emailPreview === 'in'))
          ? 'url(\'' + getBackgroundImage() + '\')'
          : '',
      'background-color':
        (format === 'link' && tagSettings.link_position === 'center-center') ||
        (format === 'email' &&
          (tagSettings.emails_position === 'center-center' ||
            emailPreview === 'in'))
          ? getBackgroundColor()
          : '',
    }"
    [style.align-items]="getVerticalAlign()"
    [style.justify-content]="getHorizontalAlign()"
  >
    <div
      class="background"
      [ngStyle]="{
        'background-image': 'url(\'' + getInnerBackgroundImage() + '\')',
        'background-color': getInnerBackgroundColor(),
      }"
    >
      <div
        class="screeb-overlay"
        *ngIf="
          currentFormat === 'conversationnal' &&
          tagSettings.conversational_overlay_visibility !== 'hidden'
        "
      ></div>
    </div>

    <div
      *ngIf="format === 'link' && tagSettings.page_background_icon"
      class="link-icon"
    >
      <img [src]="tagSettings.page_background_icon" alt="link icon" />
    </div>
    <div
      *ngIf="
        format === 'email' &&
        emailPreview === 'out' &&
        tagSettings.emails_background_icon
      "
      class="email-icon"
    >
      <img [src]="tagSettings.emails_background_icon" alt="email icon" />
    </div>

    <div *ngIf="currentFormat === 'in_page'" class="in-page-image-container">
      <img
        class="in-page-image"
        src="/assets/preview/preview-background-in-page-up.png"
        alt="preview-background-in-page-up"
      />
    </div>

    @let prefix =
      currentFormat === "in_page"
        ? "in_page"
        : currentFormat === "email" && emailPreview === "out"
          ? "emails"
          : "link";
    @let classPrefix =
      currentFormat === "in_page"
        ? "in-page"
        : currentFormat === "email" && emailPreview === "out"
          ? "emails-out"
          : "link";
    @let isEmbeddedFormat =
      ["in_page", "link"].includes(currentFormat) ||
      (currentFormat === "email" && emailPreview === "out");
    <div *ngIf="isEmbeddedFormat" class="{{ classPrefix }}">
      <div
        class="{{ classPrefix }}-identity"
        *ngIf="
          tagSettings[prefix + '_identity_type'] === 'fixed' ||
          tagSettings[prefix + '_identity_type'] === 'screeby'
        "
      >
        <div
          *ngIf="tagSettings[prefix + '_identity_type'] === 'fixed'"
          class="{{ classPrefix }}-identity-avatar"
        ></div>
        <img
          *ngIf="tagSettings[prefix + '_identity_type'] === 'screeby'"
          src="{{
            isDarkColor(tagSettings[prefix + '_color_secondary'])
              ? '/assets/screeby-white.png'
              : '/assets/screeby.png'
          }}"
          (error)="onImgError($event, prefix)"
          alt="Screeby"
        />
        <div class="{{ classPrefix }}-identity-details">
          <div class="{{ classPrefix }}-identity-name">
            {{ tagSettings[prefix + "_identity_fixed_name"] }}
          </div>
          <div
            *ngIf="tagSettings[prefix + '_identity_fixed_title']?.length"
            class="{{ classPrefix }}-identity-title"
          >
            {{ tagSettings[prefix + "_identity_fixed_title"] }}
          </div>
        </div>
      </div>
      <div class="{{ classPrefix }}-body">
        <div>
          How would you rate your
          <strong>overall satisfaction with our product</strong>
          ?
        </div>
        <div class="{{ classPrefix }}-rating">
          <div class="{{ classPrefix }}-rating-button">
            <sanitized-message-component
              content="🤩"
              [options]="{
                links: true,
                styling: true,
                emojiTranscoder: true,
                useDarkAndLightMode: true,
                primaryTextColor: tagSettings.in_page_color_primary_text,
              }"
            ></sanitized-message-component>
          </div>
          <div class="{{ classPrefix }}-rating-button">
            <sanitized-message-component
              content="🙂"
              [options]="{
                links: true,
                styling: true,
                emojiTranscoder: true,
                useDarkAndLightMode: true,
                primaryTextColor: tagSettings.in_page_color_primary_text,
              }"
            ></sanitized-message-component>
          </div>
          <div class="{{ classPrefix }}-rating-button">
            <sanitized-message-component
              content="😐"
              [options]="{
                links: true,
                styling: true,
                emojiTranscoder: true,
                useDarkAndLightMode: true,
                primaryTextColor: tagSettings.in_page_color_primary_text,
              }"
            ></sanitized-message-component>
          </div>
          <div class="{{ classPrefix }}-rating-button">
            <sanitized-message-component
              content="🙁"
              [options]="{
                links: true,
                styling: true,
                emojiTranscoder: true,
                useDarkAndLightMode: true,
                primaryTextColor: tagSettings.in_page_color_primary_text,
              }"
            ></sanitized-message-component>
          </div>
          <div class="{{ classPrefix }}-rating-button">
            <sanitized-message-component
              content="😡"
              [options]="{
                links: true,
                styling: true,
                emojiTranscoder: true,
                useDarkAndLightMode: true,
                primaryTextColor: tagSettings.in_page_color_primary_text,
              }"
            ></sanitized-message-component>
          </div>
        </div>
        <div class="{{ classPrefix }}-footer">
          <div
            class="{{ classPrefix }}-powered-by"
            [ngClass]="
              currentFormat === 'in_page'
                ? shouldInvertWhiteLabelInPage()
                  ? 'inverted'
                  : ''
                : shouldInvertWhiteLabelLink()
                  ? 'inverted'
                  : ''
            "
          >
            <svg
              width="18"
              height="14"
              viewBox="0 0 18 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6 0C2.68629 0 0 2.68629 0 6V14H12C15.3137 14 18 11.3137 18 8V6C18 2.68629 15.3137 0 12 0H6ZM5 8C5.55229 8 6 7.55229 6 7C6 6.44771 5.55229 6 5 6C4.44772 6 4 6.44771 4 7C4 7.55229 4.44772 8 5 8ZM9 8C9.55229 8 10 7.55229 10 7C10 6.44771 9.55229 6 9 6C8.44771 6 8 6.44771 8 7C8 7.55229 8.44771 8 9 8ZM14 7C14 7.55229 13.5523 8 13 8C12.4477 8 12 7.55229 12 7C12 6.44771 12.4477 6 13 6C13.5523 6 14 6.44771 14 7Z"
                [ngStyle]="{
                  fill:
                    currentFormat === 'in_page'
                      ? shouldInvertWhiteLabelInPage()
                        ? 'var(--screeb-color-white)'
                        : '#727387'
                      : shouldInvertWhiteLabelLink()
                        ? 'var(--screeb-color-white)'
                        : '#727387',
                }"
              />
            </svg>
            <span>Powered by Screeb</span>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="currentFormat === 'cards'" class="cards">
      <div class="cards-buttons">
        <screeb-icon
          size="sm"
          icon="bottom-arrow"
          *ngIf="tagSettings.cards_display_reduce_button"
        ></screeb-icon>
        <screeb-icon size="sm" icon="cross"></screeb-icon>
      </div>
      <div
        class="cards-identity"
        *ngIf="
          tagSettings.cards_identity_type === 'fixed' ||
          tagSettings.cards_identity_type === 'screeby'
        "
      >
        @let isDarkColorCards = isDarkColor(tagSettings.cards_color_secondary);
        <img
          *ngIf="tagSettings.cards_identity_type === 'screeby'"
          class="cards-identity-screeby"
          src="{{
            isDarkColorCards
              ? '/assets/screeby-white.png'
              : '/assets/screeby.png'
          }}"
          (error)="onImgError($event, 'cards')"
          alt="Screeby"
        />
        <div
          *ngIf="tagSettings.cards_identity_type === 'fixed'"
          class="cards-identity-avatar"
        ></div>
        <div class="cards-identity-details">
          <div class="cards-identity-name">
            {{ tagSettings.cards_identity_fixed_name }}
          </div>
          <div
            *ngIf="tagSettings.cards_identity_fixed_title?.length"
            class="cards-identity-title"
          >
            {{ tagSettings.cards_identity_fixed_title }}
          </div>
        </div>
      </div>
      <div class="cards-body">
        <div>
          How would you rate your
          <strong>overall satisfaction with our product</strong>
          ?
        </div>
        <div class="cards-rating">
          <div class="cards-rating-button">
            <sanitized-message-component
              content="🤩"
              [options]="{
                links: true,
                styling: true,
                emojiTranscoder: true,
                useDarkAndLightMode: true,
                primaryTextColor: tagSettings.cards_color_primary_text,
              }"
            ></sanitized-message-component>
          </div>
          <div class="cards-rating-button">
            <sanitized-message-component
              content="🙂"
              [options]="{
                links: true,
                styling: true,
                emojiTranscoder: true,
                useDarkAndLightMode: true,
                primaryTextColor: tagSettings.cards_color_primary_text,
              }"
            ></sanitized-message-component>
          </div>
          <div class="cards-rating-button">
            <sanitized-message-component
              content="😐"
              [options]="{
                links: true,
                styling: true,
                emojiTranscoder: true,
                useDarkAndLightMode: true,
                primaryTextColor: tagSettings.cards_color_primary_text,
              }"
            ></sanitized-message-component>
          </div>
          <div class="cards-rating-button">
            <sanitized-message-component
              content="🙁"
              [options]="{
                links: true,
                styling: true,
                emojiTranscoder: true,
                useDarkAndLightMode: true,
                primaryTextColor: tagSettings.cards_color_primary_text,
              }"
            ></sanitized-message-component>
          </div>
          <div class="cards-rating-button">
            <sanitized-message-component
              content="😡"
              [options]="{
                links: true,
                styling: true,
                emojiTranscoder: true,
                useDarkAndLightMode: true,
                primaryTextColor: tagSettings.cards_color_primary_text,
              }"
            ></sanitized-message-component>
          </div>
        </div>
        <div class="cards-footer">
          <div
            class="cards-powered-by"
            [ngClass]="shouldInvertWhiteLabel() ? 'inverted' : ''"
          >
            <svg
              width="18"
              height="14"
              viewBox="0 0 18 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6 0C2.68629 0 0 2.68629 0 6V14H12C15.3137 14 18 11.3137 18 8V6C18 2.68629 15.3137 0 12 0H6ZM5 8C5.55229 8 6 7.55229 6 7C6 6.44771 5.55229 6 5 6C4.44772 6 4 6.44771 4 7C4 7.55229 4.44772 8 5 8ZM9 8C9.55229 8 10 7.55229 10 7C10 6.44771 9.55229 6 9 6C8.44771 6 8 6.44771 8 7C8 7.55229 8.44771 8 9 8ZM14 7C14 7.55229 13.5523 8 13 8C12.4477 8 12 7.55229 12 7C12 6.44771 12.4477 6 13 6C13.5523 6 14 6.44771 14 7Z"
                [ngStyle]="{
                  fill: shouldInvertWhiteLabel()
                    ? 'var(--screeb-color-white)'
                    : '#727387',
                }"
              />
            </svg>
            <span>Powered by Screeb</span>
          </div>
        </div>
      </div>
    </div>

    <div
      *ngIf="currentFormat === 'feedback_button'"
      class="feedback_button-container"
      [ngClass]="[
        tagSettings.feedback_button_position === 'center-left'
          ? 'feedback_button-container-left'
          : 'feedback_button-container-right',
        mode === 'mobile' ? 'mobile' : '',
        feedbackButtonPreview,
      ]"
      #feedbackContainer
    >
      <div
        *ngIf="feedbackButtonPreview === 'opened'"
        [ngClass]="[
          tagSettings.feedback_button_position === 'center-left'
            ? 'feedback-button-container-left'
            : 'feedback-button-container-right',
        ]"
      >
        <div class="feedback_button">
          <div
            class="cards-buttons"
            (click)="handleFeedbackButtonChange('closed')"
          >
            <screeb-icon
              size="sm"
              icon="bottom-arrow"
              *ngIf="mode === 'mobile'"
            ></screeb-icon>
            <screeb-icon size="sm" icon="cross"></screeb-icon>
          </div>
          <div
            class="feedback_button-identity"
            *ngIf="
              tagSettings.feedback_button_identity_type === 'fixed' ||
              tagSettings.feedback_button_identity_type === 'screeby'
            "
          >
            <div class="cards-identity-details">
              <div class="cards-identity-name">
                {{ tagSettings.feedback_button_identity_fixed_name }}
              </div>
              <div class="cards-identity-title">
                {{ tagSettings.feedback_button_identity_fixed_title }}
              </div>
            </div>
          </div>
          <div class="feedback_button-body">
            <div>
              How would you rate your
              <strong>overall satisfaction with our product</strong>
              ?
            </div>
            <div class="cards-rating">
              <div class="cards-rating-button">
                <sanitized-message-component
                  content="🤩"
                  [options]="{
                    links: true,
                    styling: true,
                    emojiTranscoder: true,
                    useDarkAndLightMode: true,
                    primaryTextColor:
                      tagSettings.feedback_button_color_primary_text,
                  }"
                ></sanitized-message-component>
              </div>
              <div class="cards-rating-button">
                <sanitized-message-component
                  content="🙂"
                  [options]="{
                    links: true,
                    styling: true,
                    emojiTranscoder: true,
                    useDarkAndLightMode: true,
                    primaryTextColor:
                      tagSettings.feedback_button_color_primary_text,
                  }"
                ></sanitized-message-component>
              </div>
              <div class="cards-rating-button">
                <sanitized-message-component
                  content="😐"
                  [options]="{
                    links: true,
                    styling: true,
                    emojiTranscoder: true,
                    useDarkAndLightMode: true,
                    primaryTextColor:
                      tagSettings.feedback_button_color_primary_text,
                  }"
                ></sanitized-message-component>
              </div>
              <div class="cards-rating-button">
                <sanitized-message-component
                  content="🙁"
                  [options]="{
                    links: true,
                    styling: true,
                    emojiTranscoder: true,
                    useDarkAndLightMode: true,
                    primaryTextColor:
                      tagSettings.feedback_button_color_primary_text,
                  }"
                ></sanitized-message-component>
              </div>
              <div class="cards-rating-button">
                <sanitized-message-component
                  content="😡"
                  [options]="{
                    links: true,
                    styling: true,
                    emojiTranscoder: true,
                    useDarkAndLightMode: true,
                    primaryTextColor:
                      tagSettings.feedback_button_color_primary_text,
                  }"
                ></sanitized-message-component>
              </div>
            </div>
            <div class="cards-footer">
              <div
                class="cards-powered-by"
                [ngClass]="shouldInvertWhiteLabel() ? 'inverted' : ''"
              >
                <svg
                  width="18"
                  height="14"
                  viewBox="0 0 18 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6 0C2.68629 0 0 2.68629 0 6V14H12C15.3137 14 18 11.3137 18 8V6C18 2.68629 15.3137 0 12 0H6ZM5 8C5.55229 8 6 7.55229 6 7C6 6.44771 5.55229 6 5 6C4.44772 6 4 6.44771 4 7C4 7.55229 4.44772 8 5 8ZM9 8C9.55229 8 10 7.55229 10 7C10 6.44771 9.55229 6 9 6C8.44771 6 8 6.44771 8 7C8 7.55229 8.44771 8 9 8ZM14 7C14 7.55229 13.5523 8 13 8C12.4477 8 12 7.55229 12 7C12 6.44771 12.4477 6 13 6C13.5523 6 14 6.44771 14 7Z"
                    [ngStyle]="{
                      fill: shouldInvertWhiteLabel()
                        ? 'var(--screeb-color-white)'
                        : '#727387',
                    }"
                  />
                </svg>
                <span>Powered by Screeb</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <feedback-button-preview
        *ngIf="
          mode === 'desktop' ||
          (mode === 'mobile' && feedbackButtonPreview === 'closed')
        "
        [tagSettings]="tagSettings"
        [feedbackButtonPreview]="feedbackButtonPreview"
        (feedbackButtonPreviewChange)="handleFeedbackButtonChange($event)"
      ></feedback-button-preview>
    </div>

    <div
      *ngIf="currentFormat === 'email' && emailPreview === 'in'"
      class="emails"
      [ngClass]="{
        mobile: mode === 'mobile',
      }"
    >
      <div class="emails-body">
        <div>
          How would you rate your
          <strong>overall satisfaction with our product</strong>
          ?
        </div>
        <div class="cards-rating">
          <div class="cards-rating-button">
            <sanitized-message-component
              content="🤩"
              [options]="{
                links: true,
                styling: true,
                emojiTranscoder: true,
                useDarkAndLightMode: true,
                primaryTextColor: tagSettings.emails_color_primary_text,
              }"
            ></sanitized-message-component>
          </div>
          <div class="cards-rating-button">
            <sanitized-message-component
              content="🙂"
              [options]="{
                links: true,
                styling: true,
                emojiTranscoder: true,
                useDarkAndLightMode: true,
                primaryTextColor: tagSettings.emails_color_primary_text,
              }"
            ></sanitized-message-component>
          </div>
          <div class="cards-rating-button">
            <sanitized-message-component
              content="😐"
              [options]="{
                links: true,
                styling: true,
                emojiTranscoder: true,
                useDarkAndLightMode: true,
                primaryTextColor: tagSettings.emails_color_primary_text,
              }"
            ></sanitized-message-component>
          </div>
          <div class="cards-rating-button">
            <sanitized-message-component
              content="🙁"
              [options]="{
                links: true,
                styling: true,
                emojiTranscoder: true,
                useDarkAndLightMode: true,
                primaryTextColor: tagSettings.emails_color_primary_text,
              }"
            ></sanitized-message-component>
          </div>
          <div class="cards-rating-button">
            <sanitized-message-component
              content="😡"
              [options]="{
                links: true,
                styling: true,
                emojiTranscoder: true,
                useDarkAndLightMode: true,
                primaryTextColor: tagSettings.emails_color_primary_text,
              }"
            ></sanitized-message-component>
          </div>
        </div>
        <div class="cards-footer">
          <div
            class="emails-powered-by"
            [ngClass]="
              shouldInvertWhiteLabel(tagSettings.emails_color_primary)
                ? 'inverted'
                : ''
            "
          >
            <svg
              width="18"
              height="14"
              viewBox="0 0 18 14"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6 0C2.68629 0 0 2.68629 0 6V14H12C15.3137 14 18 11.3137 18 8V6C18 2.68629 15.3137 0 12 0H6ZM5 8C5.55229 8 6 7.55229 6 7C6 6.44771 5.55229 6 5 6C4.44772 6 4 6.44771 4 7C4 7.55229 4.44772 8 5 8ZM9 8C9.55229 8 10 7.55229 10 7C10 6.44771 9.55229 6 9 6C8.44771 6 8 6.44771 8 7C8 7.55229 8.44771 8 9 8ZM14 7C14 7.55229 13.5523 8 13 8C12.4477 8 12 7.55229 12 7C12 6.44771 12.4477 6 13 6C13.5523 6 14 6.44771 14 7Z"
              />
            </svg>
            <span>Powered by Screeb</span>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="currentFormat === 'in_page'" class="in-page-image-container">
      <img
        class="in-page-image"
        src="/assets/preview/preview-background-in-page-down.png"
        alt="preview-background-in-page-down"
      />
    </div>

    <ul *ngIf="currentFormat === 'conversationnal'" class="messages">
      <div
        class="messages-buttons"
        [style.justify-content]="getHorizontalAlign(true)"
      >
        <button
          class="messages-button"
          *ngIf="!tagSettings.conversational_display_close_reduce"
        >
          <screeb-icon size="sm" icon="ellipsis-horizontal"></screeb-icon>
        </button>
        <button
          class="messages-button"
          *ngIf="tagSettings.conversational_display_close_reduce"
        >
          <screeb-icon size="sm" icon="cross"></screeb-icon>
        </button>
        <button
          class="messages-button"
          *ngIf="tagSettings.conversational_display_close_reduce"
        >
          <screeb-icon size="sm" icon="neutral"></screeb-icon>
        </button>
      </div>
      <li class="message incoming">
        <div class="message-inner">
          <div
            class="intro"
            *ngIf="
              tagSettings.conversational_identity_type === 'fixed' ||
              tagSettings.conversational_identity_type === 'screeby'
            "
          >
            <div
              *ngIf="tagSettings.conversational_identity_type === 'fixed'"
              class="channel-picture"
            ></div>
            <img
              *ngIf="tagSettings.conversational_identity_type === 'screeby'"
              class="screeby"
              src="{{
                isDarkColor(tagSettings.conversational_color_secondary)
                  ? '/assets/screeby-white.png'
                  : '/assets/screeby.png'
              }}"
              (error)="onImgError($event, 'conversational')"
              alt="Screeby"
            />
            <div class="display-name">
              <strong>
                {{ tagSettings.conversational_identity_fixed_name }}
              </strong>
              <ng-container
                *ngIf="tagSettings.conversational_identity_fixed_title?.length"
              >
                <br />
                <span>
                  {{ tagSettings.conversational_identity_fixed_title }}
                </span>
              </ng-container>
            </div>
          </div>
          <div class="text">
            <p class="content">
              How likely are you to recommend our app to a friend or colleague?
            </p>
          </div>
        </div>
      </li>

      <li class="message outgoing">
        <div class="message-inner is-score-response">
          <div class="text">
            <p class="content" style="font-size: 32px">
              <sanitized-message-component
                content="9️⃣"
                [options]="{
                  links: true,
                  styling: true,
                  emojiTranscoder: true,
                  useDarkAndLightMode: true,
                  primaryTextColor:
                    tagSettings.conversational_color_primary_text,
                }"
              ></sanitized-message-component>
            </p>
          </div>
        </div>
      </li>

      <li class="message incoming">
        <div class="message-inner">
          <div class="text">
            <div
              *ngIf="tagSettings.conversational_identity_type !== 'screeby'"
              class="channel-picture"
            ></div>
            <img
              *ngIf="tagSettings.conversational_identity_type === 'screeby'"
              class="screeby"
              src="{{
                isDarkColor(tagSettings.conversational_color_secondary)
                  ? '/assets/screeby-white-fallback.png'
                  : '/assets/screeby-fallback.png'
              }}"
              (error)="onImgError($event, 'conversational')"
              alt="Screeby"
            />
            <p class="content">How can we improve your experience?</p>
          </div>
        </div>
      </li>

      <li class="message outgoing">
        <div class="message-inner">
          <div class="text">
            <p class="content">
              I use the app on a daily basis, sometimes with my phone. A mobile
              app would be awesome!
            </p>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
