import { Injectable } from "@angular/core";

import { ScreebApiHelper } from "helpers/screeb-api.helper";
import { WeeklyBriefing } from "./weekly-briefing.model";

@Injectable()
export class WeeklyBriefingDao {
  constructor(private screebApiHelper: ScreebApiHelper) {}

  public getAllByOrgId(orgId: string): Promise<WeeklyBriefing[]> {
    return this.screebApiHelper
      .get<WeeklyBriefing[]>(`/org/${orgId}/weekly-briefing`)
      .toPromise()
      .then((data: object[]) => {
        return data.map((c: object) => new WeeklyBriefing().fromJson(c));
      });
  }

  public deleteById(orgId: string, id: string): Promise<void> {
    return this.screebApiHelper
      .delete<void>(`/org/${orgId}/weekly-briefing/${id}`)
      .toPromise();
  }

  public create(
    orgId: string,
    firstname: string,
    lastname: string,
    email: string,
    role: string,
  ): Promise<WeeklyBriefing> {
    const body = {
      firstname,
      lastname,
      email,
      role,
    };
    return this.screebApiHelper
      .post<WeeklyBriefing>(`/org/${orgId}/weekly-briefing`, body)
      .toPromise()
      .then((data: object) => {
        return new WeeklyBriefing().fromJson(data);
      });
  }

  public update(
    orgId: string,
    briefingId: string,
    firstname: string,
    lastname: string,
    email: string,
    role: string,
  ): Promise<WeeklyBriefing> {
    const body = {
      firstname,
      lastname,
      email,
      role,
    };
    return this.screebApiHelper
      .put<WeeklyBriefing>(`/org/${orgId}/weekly-briefing/${briefingId}`, body)
      .toPromise()
      .then((data: object) => {
        return new WeeklyBriefing().fromJson(data);
      });
  }
}
