import { Component, Input, OnInit } from "@angular/core";
import { TargetingRule } from "models/targeting-rule.model";

@Component({
  selector: "rule-language",
  templateUrl: "./rule-language.component.html",
  styleUrls: ["./rule-language.component.scss"],
})
export class TargetingRuleLanguageComponent implements OnInit {
  @Input() public rule: TargetingRule = null;
  @Input() public languages: string[][] = [];

  public mainLanguages: string[][] = [];

  constructor() {}

  ngOnInit() {
    this.mainLanguages = this.languages.filter((lang: string[]) => {
      return ["en", "fr", "es", "de", "zh", "ar"].includes(lang[0]);
    });
  }
}
