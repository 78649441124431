import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { GraphNode } from "components/builder/flow";
import { decode } from "html-entities";
import {
  MessageText,
  SurveyLanguages,
  getI18nTextLabelTranslation,
  setI18nTextLabelTranslation,
} from "models/survey.dao.types";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { BuilderStore } from "stores/builder.store";
import { randomString } from "utils/random";
import { BigCardValidatorFactory } from "../../BuilderLayout/Validators/CardValidator";
import {
  htmlSanitizer,
  stripHtml,
} from "../../Cards/sanitized-message/sanitizer";

@Component({
  selector: "lateral-panel-edit-text",
  templateUrl: "./edit-text.component.html",
  styleUrls: ["./edit-text.component.scss"],
})
export class LateralPanelEditTextComponent implements OnInit {
  @Input() node: GraphNode = null;
  @Input() message: MessageText = null;
  @Input() nextQuestionPickerEnabled: boolean = false;
  @Input() language: SurveyLanguages = "en";

  @Output() suggest = new EventEmitter<unknown>();
  @Output() languageChange = new EventEmitter<SurveyLanguages>();
  @Output() errorChange = new EventEmitter<boolean>();

  public uniqHash: string;

  public textErrors: string[] = [];

  constructor(
    public builderStore: BuilderStore,
    public featureFlaggingService: FeatureFlaggingService,
  ) {}

  ngOnInit() {
    this.uniqHash = randomString(5);
    if (this.message.type !== "text") throw Error("unexpected message type");
    this.validateData();
  }

  public get text(): string {
    return decode(
      getI18nTextLabelTranslation(
        this.message.text,
        this.language,
        this.language,
      ) || "",
    );
  }

  public set text(text: string) {
    setI18nTextLabelTranslation(this.message.text, text, this.language);
  }

  public getMessagePlaceholder(): string | null {
    if (
      this.getLength(this.text) < 1 &&
      this.language !== this.builderStore.survey.scenario.default_language
    ) {
      return "Missing translation";
    }
    return "";
  }

  /**
   * Data validation
   */
  public validateData() {
    const { errors } = BigCardValidatorFactory.getValidatorFromNode(this.node)(
      this.node,
      this.language,
      this.builderStore.survey.scenario.default_language,
    );

    [this.textErrors] = errors;

    this.errorChange.emit(this.textErrors.length > 0);
  }

  getLength(text: string) {
    return stripHtml(
      htmlSanitizer(text, {
        styling: true,
        CR: false,
        nativeCR: true,
        links: true,
      }),
    ).length;
  }

  onTextChange(event: CustomEvent<string>) {
    this.text = event.detail;
    this.validateData();
  }
}
