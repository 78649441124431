/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/no-output-rename */

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { integrationUpdate } from "components/integration/settings/integration-settings.component";

import { autoTips } from "helpers/form-errors.helper";
import { NotificationHelper } from "helpers/notification.helper";
import { IntegrationDao } from "models/integration.dao";
import { Integration, IntegrationSettings } from "models/integrations.model";
import { Org } from "models/org.model";
import { ConfigService } from "services/config.service";
import { TrackingEventName } from "services/trackers.events";
import { TrackersService } from "services/trackers.service";
import { UIService } from "services/ui.service";

@Component({
  selector: "integration-settings-segment-source",
  templateUrl: "./segment-source-settings.component.html",
  styleUrls: ["./segment-source-settings.component.scss"],
})
export class IntegrationSettingsSegmentSourceComponent implements OnInit {
  @Input() public org: Org = null;
  @Input() public integration: Integration = null;

  @Output() public valid = new EventEmitter<boolean>();
  @Output("onSettingsChange") public settings =
    new EventEmitter<integrationUpdate>();

  public validateForm: FormGroup = null;
  public autoTips = autoTips;

  public contactUs: boolean = false;
  public isOverrided: boolean = false;

  constructor(
    public trackersService: TrackersService,
    public integrationDao: IntegrationDao,
    public notificationHelper: NotificationHelper,
    private formBuilder: FormBuilder,
    private configService: ConfigService,
    private uiService: UIService,
  ) {}

  ngOnInit() {
    this.isOverrided = !!this.uiService.currentOrg.settings.skip_anonymous;
    this.validateForm = this.formBuilder.group({
      ignore_anonymous: [
        this.isOverrided
          ? true
          : this.integration?.settings?.segment_source?.ignore_anonymous,
        [Validators.required],
      ],
    });
    this.validateSettings();

    this.validateForm.valueChanges.subscribe(() => {
      this.validateSettings();
    });

    this.contactUs = !["global", "local"].includes(
      this.configService.config.platform,
    );
  }

  public validateSettings() {
    this.valid.emit(this.validateForm.valid);
    this.settings.emit({
      settings: {
        segment_source: {
          ignore_anonymous: this.validateForm.value["ignore_anonymous"],
        },
      } as IntegrationSettings,
    });
  }

  public onCopy() {
    this.segmentEventTrack("Integration token copied");
  }

  public onReset() {
    this.integrationDao
      .resetIntegrationToken(this.org.id, this.integration.type)
      .then((r: Integration) => {
        this.segmentEventTrack("Integration token reset");
        this.integration.token = r.token;
        this.notificationHelper.trigger("Token Reset!", null, "success");
      })
      .catch((err) => {
        console.error(err);
        this.notificationHelper.trigger(
          "Error While resetting Token",
          null,
          "error",
        );
      });
  }

  private segmentEventTrack(eventName: TrackingEventName) {
    this.trackersService
      .newEventTrackingBuilder(eventName)
      .withOrg(this.org)
      .withIntegration(this.integration)
      .build();
  }
}
