<svg
  class="chart"
  xmlns="http://www.w3.org/2000/svg"
  [attr.width]="pieWidth"
  [attr.height]="pieHeight"
  [attr.viewBox]="'0 0 ' + pieWidth + ' ' + pieHeight"
>
  <mask id="mask">
    <rect
      x="0"
      y="0"
      [attr.width]="pieWidth"
      [attr.height]="pieHeight"
      fill="white"
    />
    <circle
      [attr.cx]="pieWidth / 2"
      [attr.cy]="pieHeight"
      [attr.r]="pieHeight - pieLineWidth"
      fill="black"
    />
    <circle
      class="valuePoint"
      [attr.cx]="getPointX()"
      [attr.cy]="getPointY()"
      r="10"
      fill="white"
      stroke="black"
      stroke-width="4"
    />
  </mask>
  <g mask="url(#mask)">
    <path
      [attr.d]="getLowRangePiePart()"
      [attr.fill]="getRangeColor(0)"
      [attr.opacity]="getOpacity(0)"
    />
    <path
      [attr.d]="getMediumRangePiePart()"
      [attr.fill]="getRangeColor(1)"
      [attr.opacity]="getOpacity(1)"
    />
    <path
      [attr.d]="getHighRangePiePart()"
      [attr.fill]="getRangeColor(2)"
      [attr.opacity]="getOpacity(2)"
    />
    <path
      class="valuePath"
      [attr.d]="getValuePath()"
      fill="transparent"
      stroke="#5E21F1"
      stroke-width="4"
    />
    <circle
      class="valuePoint"
      [attr.cx]="getPointX()"
      [attr.cy]="getPointY()"
      r="9"
      fill="#5E21F1"
    />
  </g>
  <g>
    <text
      [attr.x]="pieLineWidth + 8"
      [attr.y]="pieHeight"
      text-anchor="start"
      font-weight="normal"
      font-family="Rubik"
      font-size="14"
      fill="#727387"
    >
      {{ min }}
    </text>
    <text
      [attr.x]="pieWidth - pieLineWidth - 8"
      [attr.y]="pieHeight"
      text-anchor="end"
      font-weight="normal"
      font-family="Rubik"
      font-size="14"
      fill="#727387"
    >
      {{ max }}
    </text>
  </g>
  <g>
    <text
      [attr.x]="pieWidth / 2"
      [attr.y]="pieHeight / 2 + (ideal ? 18 : 30)"
      text-anchor="middle"
      font-weight="700"
      font-family="Rubik"
      [attr.font-size]="ideal ? 32 : 38"
      fill="#303140"
    >
      {{ getFormattedValue() }}
    </text>
    <text
      *ngIf="ideal"
      [attr.x]="pieWidth / 2"
      [attr.y]="pieHeight / 2 + 50"
      text-anchor="middle"
      font-weight="normal"
      font-family="Rubik"
      font-size="14"
      fill="#727387"
    >
      <tspan [attr.fill]="getIdealValueDiffColor()">
        {{ getIdealValueDiffText() }}
      </tspan>
      <tspan>compared to your industry</tspan>
    </text>
  </g>
</svg>
