<div>
  <section id="parameters">
    <url-builder
      [url]="url"
      [baseUrl]="baseUrl"
      (urlChange)="onUrlChange($event)"
    ></url-builder>
  </section>

  <nz-card class="email-template-card">
    <div class="card-header">
      <p class="card-title">
        <strong>Paste the code</strong>
        below in your email to display the first question of your survey to your
        readers
      </p>
      <button
        class="preview-button"
        nz-button
        nzType="primary"
        nzSize="large"
        (click)="onPreviewModalOpenChange(true)"
      >
        <i nz-icon nzType="caret-right"></i>
        Preview
      </button>
    </div>
    <p class="error" *ngIf="error">{{ error }}</p>

    <div class="installation-code-container" *ngIf="!error">
      <code
        class="installation-code"
        [highlight]="emailTemplate"
        [languages]="undefined"
      ></code>
      <button
        class="copy-button"
        nz-button
        nzSize="medium"
        nzType="primary"
        (click)="copy()"
      >
        Copy
      </button>
    </div>
  </nz-card>

  <section id="actions-preview"></section>
</div>

<email-preview-modal
  [isOpen]="previewModalOpened"
  [template]="emailTemplate"
  (modalOpenChange)="onPreviewModalOpenChange($event)"
></email-preview-modal>

<ng-container>
  <hr />
  <h2 class="title">
    <screeb-icon size="md" icon="button" />
    Behaviour
  </h2>
  <p class="desc">Let's customize the behaviour for this channel</p>
</ng-container>
<section id="switch-multiple">
  <div class="recurring-survey-title">
    <p class="label">Multiple response</p>
    <nz-switch
      [ngModel]="multiple"
      (ngModelChange)="multipleChange.emit($event)"
      [nzCheckedChildren]="checkedTemplate"
      [nzUnCheckedChildren]="unCheckedTemplate"
    ></nz-switch>
    <ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
    <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>
  </div>
  <p>
    By default, a user can reply to a survey only once.
    <br />
    Activate the multiple responses, to allow a user to reply many times.
  </p>
</section>
