<nz-list nzItemLayout="horizontal">
  <nz-list-item *ngFor="let invoice of orgBillingInvoices.slice().reverse()">
    <nz-list-item-meta
      nzDescription="{{ invoice.created_at | dfnsFormat: 'PPPP' }}"
    >
      <nz-list-item-meta-title>
        <a
          *ngIf="!invoice.paid"
          href="{{ invoice.payment_url }}"
          target="_blank"
        >
          <nz-tag class="warning">
            <i nz-icon nzType="clock-circle"></i>
            <span>Pay</span>
          </nz-tag>
        </a>
        <nz-tag *ngIf="invoice.paid" class="success">
          <i nz-icon nzType="check-circle"></i>
          <span>Paid</span>
        </nz-tag>

        {{ invoice.amount }}€ -
        <a [href]="getInvoiceURL(invoice)" target="_blank">Download</a>
      </nz-list-item-meta-title>
    </nz-list-item-meta>
  </nz-list-item>
  <nz-list-empty *ngIf="orgBillingInvoices.length === 0"></nz-list-empty>
</nz-list>
