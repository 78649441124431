import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { Survey } from "models/survey.model";
import { PageComponentInterface } from "components/PageComponentInterface";
import { UIService } from "services/ui.service";

@Component({
  selector: "page-survey",
  template: "",
  // templateUrl: './survey.component.html',
  // styleUrls: ['./survey.component.scss']
})
export class SurveyPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Survey"; // @TODO: rename title (survey vs message)
  public name = "survey"; // @TODO: rename title (survey vs message)

  private obs: any = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public uiService: UIService,
  ) {}

  ngOnInit() {
    this.obs = this.route.data.subscribe((data) => {
      const survey: Survey = data["survey"];
      if (this.uiService.isMessagePage) {
        this.title = "Message";
        this.name = "message";
      }

      if (survey.stats.response_total > 0)
        this.router.navigate(
          [
            "org",
            survey.org_id,
            this.uiService.surveyTypePath,
            survey.id,
            "stats",
          ],
          { replaceUrl: true },
        );
      else
        this.router.navigate(
          [
            "org",
            survey.org_id,
            this.uiService.surveyTypePath,
            survey.id,
            "share",
          ],
          { replaceUrl: true },
        );
    });
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }
}
