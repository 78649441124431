<section>
  <div *ngIf="integration">
    <form
      *ngIf="!integration.auth_ok"
      nz-form
      [nzAutoTips]="autoTips"
      [formGroup]="validateForm"
    >
      <h2>Client ID</h2>
      <nz-form-item>
        <nz-form-control>
          <nz-input-group>
            <input
              type="url"
              nz-input
              nzHasFeedback
              formControlName="client_id"
              placeholder="Client ID"
            />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <h2>Client Secret</h2>
      <nz-form-item>
        <nz-form-control>
          <nz-input-group>
            <input
              type="password"
              nz-input
              nzHasFeedback
              formControlName="client_secret"
              placeholder="Client Secret"
            />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </form>
    <integration-settings-oauth
      [org]="org"
      [integration]="integration"
      [confirmMessage]=""
      [client_id]="validateForm.value.client_id"
      [client_secret]="validateForm.value.client_secret"
      mode="2LO"
      iconName="contentsquare"
    ></integration-settings-oauth>
  </div>
</section>
