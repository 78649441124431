import { Component, Input } from "@angular/core";

@Component({
  selector: "utils-form-error",
  templateUrl: "./form-error.component.html",
  styleUrls: ["./form-error.component.scss"],
})
export class FormErrorComponent {
  @Input() public errors: string[] = null;

  constructor() {}
}
