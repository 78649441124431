import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NotificationHelper } from "helpers/notification.helper";
import { Account } from "models/account.model";
import { SurveyDao } from "models/survey.dao";
import { SurveyScenario } from "models/survey.dao.types";
import { Survey } from "models/survey.model";
import { UIService } from "services/ui.service";
import { BuilderStore } from "stores/builder.store";
import { formatDateDistanceFromNow } from "utils/date";

@Component({
  selector: "scenario-history",
  templateUrl: "./scenario-history.component.html",
  styleUrls: ["./scenario-history.component.scss"],
})
export class ScenarioHistoryComponent implements OnInit, OnChanges, OnDestroy {
  constructor(
    public uiService: UIService,
    public builderStore: BuilderStore,
    private route: ActivatedRoute,
    private surveyDao: SurveyDao,
    private notificationHelper: NotificationHelper,
  ) {}

  @Input() survey: Survey = null;
  @Output() public scenarioHistoryChange: EventEmitter<SurveyScenario> =
    new EventEmitter();

  private obs: any = null;

  public orgAccounts: Account[] = [];

  public relativeDate: string | null = null;
  public prefix: string = "Created";
  public updatedBy: Account | null = null;
  public fetched: boolean = false;
  public scenarios: SurveyScenario[] = [];

  ngOnInit(): void {
    this.obs = this.route.data.subscribe((data) => {
      this.orgAccounts = data.orgAccounts;
    });

    this.setup();
  }

  ngOnChanges(): void {
    this.setup();
  }

  ngOnDestroy(): void {
    this.obs?.unsubscribe();
  }

  private setup() {
    if (this.survey?.scenario?.created_at) {
      this.relativeDate = formatDateDistanceFromNow(
        new Date(this.survey?.scenario?.created_at),
      );
      this.prefix =
        this.survey?.scenario?.version === 0 ? "Created" : "Updated";
    } else {
      this.relativeDate = null;
      this.prefix = "Created";
    }

    this.updatedBy = this.orgAccounts?.find(
      ({ id }) => id === this.survey?.scenario?.author_id,
    );
  }

  public onScenarioClick(scenario: SurveyScenario): void {
    this.scenarioHistoryChange.emit(scenario);
  }

  public onVisibleChange(visible: boolean): void {
    if (this.fetched || !visible) {
      return;
    }

    this.surveyDao
      .getAllScenariosByWorkspaceIdAndSurveyId(
        this.survey.org_id,
        this.survey.id,
      )
      .then((scenarios) => {
        this.scenarios = scenarios.map((scenario) => {
          return {
            ...scenario,
            datePrefix: scenario.version === 0 ? "Created" : "Updated",
            relativeDate: formatDateDistanceFromNow(
              new Date(scenario.created_at),
            ),
            author: this.orgAccounts.find(
              ({ id }) => id === scenario.author_id,
            ),
          };
        });
        this.scenarios.sort((a, b) => {
          return (
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          );
        });
      })
      .catch((error) => {
        console.error(error);
        this.notificationHelper.trigger(
          "Failed to fetch scenarios",
          "Please try again later",
          "error",
        );
      })
      .finally(() => {
        this.fetched = true;
      });
  }
}
