import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import {
  TargetingRule,
  TargetingRuleOperator,
  TargetingRuleType,
  targetingRulesTypesAndLabels,
  targetingRulesTypesToOperator,
} from "models/targeting-rule.model";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { ChannelClientScreen } from "models/channel-screen.model";
import { UIService } from "services/ui.service";
import { SurveyDistribution } from "models/survey-distribution.model";

@Component({
  selector: "survey-share-targeting-wizard",
  templateUrl: "./wizard.component.html",
  styleUrls: ["./wizard.component.scss"],
})
export class WizardTargetingShareSurveyComponent implements OnInit, OnChanges {
  public name = "Sharing - Wizard mode";

  @Input() public distribution: SurveyDistribution = null;
  @Input() public surveyId: string = null;
  @Input() public orgId: string = null;
  @Input() public rules: TargetingRule[] = [];
  @Input() public channelClientScreens: ChannelClientScreen[] = [];
  @Input() public languages: string[][] = [];
  @Input() public countries: string[][] = [];
  @Input() public selectorCSS: string = null;
  @Output() public selectorCSSChange = new EventEmitter<string>();

  @Output() public rulesChange = new EventEmitter<TargetingRule[]>();

  public targetingRulesTypesToOperator = targetingRulesTypesToOperator;
  public targetingRulesTypesAndLabels = Object.values(
    targetingRulesTypesAndLabels,
  );

  public mainLanguages: string[][] = [];
  public mainCountries: string[][] = [];

  public deviceRule: TargetingRule = null;
  public delayRule: TargetingRule = null;
  public sessionDurationRule: TargetingRule = null;
  public recurrenceRule: TargetingRule = null;
  public recurrenceRuleIfNotAnswered: TargetingRule = null;
  public multipleRule: TargetingRule = null;
  public screenRule: TargetingRule = null;
  public urlRules: TargetingRule[] = [];

  public ready = false;

  constructor(
    public featureFlaggingService: FeatureFlaggingService,
    public uiService: UIService,
  ) {}

  ngOnInit() {
    this.setupRules();
  }

  ngOnChanges() {
    this.setupRules();
  }

  private setupRules() {
    this.ready = false;
    this.mainLanguages = this.languages.filter((lang: string[]) => {
      return ["en", "fr", "es", "de", "zh", "ar"].includes(lang[0]);
    });
    this.mainCountries = this.countries.filter((country: string[]) => {
      return ["US", "GB", "FR", "ES", "DE", "CN"].includes(country[0]);
    });

    this.urlRules = this.findRulesByType("url");
    this.screenRule = this.findRuleByType("screen");
    if (this.screenRule && this.screenRule.operator !== "equal") {
      this.screenRule.operator = "equal";
      this.screenRule.value.v_s = null;
      this.screenRule.value.v_s_arr = [];
    }

    this.delayRule = this.findRuleByType("delay");
    this.sessionDurationRule = this.findRuleByType("session_duration");
    this.recurrenceRule = this.findRuleByType("recurrence");
    // this.recurrenceRuleIfNotAnswered = this.findRuleByType(
    //   "recurrence_not_answered",
    // );

    //this.completeCappingRuleIfOneOfThemExists();

    const created1 = this.createDeviceRuleIfNotExist();
    const created2 = this.createMultipleRuleIfNotExist();

    if (created1 || created2) {
      this.rules = Array.from(this.rules);
      this.rulesChange.emit(this.rules);
    }

    this.ready = true;
  }

  // duplicated code
  private addRule(
    type: TargetingRuleType | undefined,
    operator?: TargetingRuleOperator,
  ) {
    const r = new TargetingRule();
    r.survey_distribution_id = this.distribution?.id; // can be null
    r.org_id = this.orgId; // can be null
    r.type = type || "url";
    r.operator = operator || r.getAvailableOperators()?.[0]?.type;
    r.value = r.getDefaultValue();
    this.rules.push(r);
  }

  private findRuleByType(type: TargetingRuleType): TargetingRule {
    const rule = this.rules.find((r: TargetingRule) => r.type === type);

    return rule ? rule : null;
  }

  private findRulesByType(type: TargetingRuleType): TargetingRule[] {
    return this.rules.filter((r: TargetingRule) => r.type === type);
  }

  private createDeviceRuleIfNotExist(): boolean {
    this.deviceRule = this.rules.find(
      (r: TargetingRule) => r.type === "device",
    );
    if (!this.deviceRule) {
      this.addRule("device");
      this.createDeviceRuleIfNotExist();
      return true;
    }
    return false;
  }

  private createMultipleRuleIfNotExist(): boolean {
    this.multipleRule = this.rules.find(
      (r: TargetingRule) => r.type === "multiple",
    );
    if (!this.multipleRule) {
      this.addRule("multiple");
      this.createMultipleRuleIfNotExist();
      return true;
    }
    return false;
  }

  public isRecurrenceRuleExisting(): boolean {
    return !!this.recurrenceRule;
  }

  public isRecurrenceIfNotAnsweredRuleExisting(): boolean {
    return !!this.recurrenceRuleIfNotAnswered;
  }

  /**
   * URL rule changes
   */
  // public onUrlRuleAdded() {
  //   this.addRule('url', 'contains');
  //   this.urlRules = this.rules.filter((r: TargetingRule) => r.type === 'url');

  //   this.rules = Array.from(this.rules);
  //   this.rulesChange.emit(this.rules);
  // }

  public onUrlRuleRemoved(index: number) {
    this.rules.splice(index, 1);
    this.urlRules = this.rules.filter((r: TargetingRule) => r.type === "url");

    this.rules = Array.from(this.rules);
    this.rulesChange.emit(this.rules);
  }

  public onUrlTargetingChange(checked: boolean) {
    if (checked === false) {
      this.rules = this.rules.filter((r: TargetingRule) => r.type !== "url");
      this.urlRules = [];
    } else {
      if (this.urlRules.length === 0) {
        this.addRule("url", "contains");
        this.urlRules = this.rules.filter(
          (r: TargetingRule) => r.type === "url",
        );
      }
    }

    this.rules = Array.from(this.rules);
    this.rulesChange.emit(this.rules);
  }

  public onScreenTargetingChange(checked: boolean) {
    if (checked === false) {
      this.rules = this.rules.filter((r: TargetingRule) => r.type !== "screen");
      this.screenRule = null;
    } else {
      if (!this.screenRule) {
        this.addRule("screen", "equal");
        this.screenRule = this.findRuleByType("screen");
        this.screenRule.value.v_s = null;
        this.screenRule.value.v_s_arr = [];
      }
    }

    this.rules = Array.from(this.rules);
    this.rulesChange.emit(this.rules);
  }

  /**
   * Delay + session duration rules
   */
  public onDelayOrSessionDurationTargetingChange(checked: boolean) {
    if (checked === false) {
      this.rules = this.rules.filter(
        (r: TargetingRule) =>
          r.type !== "delay" && r.type !== "session_duration",
      );
      this.delayRule = null;
    } else {
      this.addRule("delay");
    }

    this.delayRule = this.rules.find((r: TargetingRule) => r.type === "delay");
    this.sessionDurationRule = this.rules.find(
      (r: TargetingRule) => r.type === "session_duration",
    );

    this.rules = Array.from(this.rules);
    this.rulesChange.emit(this.rules);
  }
  public getPlaceholderDelayOrSessionDuration() {
    if (this.delayRule) {
      return this.delayRule.getValuePlaceholder();
    } else if (this.sessionDurationRule) {
      return this.sessionDurationRule.getValuePlaceholder();
    }
    return "5";
  }
  public isDelayOrSessionDurationDisabled() {
    return this.delayRule === null && this.sessionDurationRule === null;
  }
  public isDelayOrSessionDurationInvalid() {
    if (this.delayRule)
      return (
        this.delayRule.value.v_n === null ||
        this.delayRule.value.v_n === undefined
      );
    else if (this.sessionDurationRule)
      return (
        this.sessionDurationRule.value.v_n === null ||
        this.sessionDurationRule.value.v_n === undefined
      );
    return false;
  }
  public getDelayOrSessionDurationMode(): "delay" | "session_duration" {
    if (this.delayRule) {
      return "delay";
    } else if (this.sessionDurationRule) {
      return "session_duration";
    }

    return "delay"; // default
  }
  public switchDelayOrSessionDurationMode(ruleType: TargetingRuleType) {
    const valueSnapshot = this.delayRule
      ? this.delayRule.value.v_n
      : this.sessionDurationRule.value.v_n;

    // remove both types
    this.rules = this.rules.filter(
      (r: TargetingRule) => r.type !== "delay" && r.type !== "session_duration",
    );

    // add the selected rule type
    this.addRule(ruleType);

    // refresh rules
    this.delayRule = this.rules.find((r: TargetingRule) => r.type === "delay");
    this.sessionDurationRule = this.rules.find(
      (r: TargetingRule) => r.type === "session_duration",
    );

    // set the delay value to the previous value
    (this.delayRule ? this.delayRule : this.sessionDurationRule).value.v_n =
      valueSnapshot;

    // pass new rules to parent component
    this.rules = Array.from(this.rules);
    this.rulesChange.emit(this.rules);
  }

  /**
   * Recurrence rule changes
   */
  public onRecurrenceTargetingChange(checked: boolean) {
    this.createMultipleRuleIfNotExist();

    this.multipleRule.value.v_b = checked;
    if (!checked) {
      this.rules = this.rules.filter(
        (r: TargetingRule) => r.type !== "recurrence",
      );
      this.recurrenceRule = null;
    } else {
      if (!this.findRuleByType("recurrence")) {
        this.addRule("recurrence");
        this.recurrenceRule = this.rules.find(
          (r: TargetingRule) => r.type === "recurrence",
        );
      }
    }

    this.rules = Array.from(this.rules);
    this.rulesChange.emit(this.rules);
  }

  /**
   * Recurrence if not answered rule changes
   */
  // public onRecurrenceIfNotAnsweredTargetingChange(checked: boolean) {
  //   this.createMultipleRuleIfNotExist();
  //   this.multipleRule.value.v_b =
  //     checked ||
  //     !!this.rules.find((r: TargetingRule) => r.type === "recurrence");
  //   if (!checked) {
  //     this.rules = this.rules.filter(
  //       (r: TargetingRule) => r.type !== "recurrence_not_answered",
  //     );
  //     this.recurrenceRuleIfNotAnswered = null;
  //   } else {
  //     if (!this.findRuleByType("recurrence_not_answered")) {
  //       this.addRule("recurrence_not_answered");
  //       this.recurrenceRuleIfNotAnswered = this.rules.find(
  //         (r: TargetingRule) => r.type === "recurrence_not_answered",
  //       );
  //     }
  //   }

  //   this.rules = Array.from(this.rules);
  //   this.rulesChange.emit(this.rules);
  // }

  /**
   * Device rule changes
   */
  public onDeviceChange(device: string, checked: boolean) {
    if (checked) {
      this.deviceRule.value.v_s_arr?.push(device);
    } else {
      this.deviceRule.value.v_s_arr = this.deviceRule.value.v_s_arr?.filter(
        (d) => d !== device,
      );
    }
  }
}
