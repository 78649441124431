<h2>URL Builder</h2>
<p>
  You can add parameters to your URL to link data to the responses you’ll
  collect. They’ll be visible in our reports as properties and you’ll be able to
  use them as filters.
  <br />
  You can use variables from your emailing provider, social sharing tool or any
  other solution you may use to share this link.
</p>
<nz-table
  #table
  [nzData]="parameters"
  nzNoResult="No parameters defined"
  [nzHideOnSinglePage]="true"
  [nzPageSize]="100"
>
  <thead>
    <tr>
      <th>Parameter</th>
      <th>Value</th>
      <th [nzWidth]="'140px'" nzRight>
        <button
          class="ant-btn-secondary"
          nz-button
          nzType="default"
          (click)="onAddParameter()"
        >
          Add a parameter
        </button>
      </th>
    </tr>
  </thead>

  <tbody>
    <tr *ngFor="let parameter of table.data; let i = index">
      <td nzAlign="center">
        <input
          nz-input
          type="text"
          [(ngModel)]="parameter.key"
          (ngModelChange)="
            ensureValidParameterKey(parameter); onUpdateParameter()
          "
        />
      </td>
      <td nzAlign="center">
        <input
          *ngIf="parameter.key.length > 0"
          nz-input
          type="text"
          [(ngModel)]="parameter.value"
          (ngModelChange)="onUpdateParameter()"
        />
      </td>
      <td zAlign="right" nzRight class="column-actions">
        <a (click)="removeParameter(i)">
          <i nz-icon nzType="close"></i>
        </a>
      </td>
    </tr>
  </tbody>
</nz-table>
