<div
  nz-col
  [nzSpan]="24"
  class="targeting-embed"
  [ngClass]="{
    'targeting-embed-error': !selectorCSS,
  }"
>
  <h3>Embed the survey in</h3>
  <div
    class="targeting-embed-input-container"
    nz-tooltip
    [nzTooltipTitle]="!selectorCSS ? 'Please enter a valid CSS selector' : null"
    [nzTooltipColor]="'red'"
    [nzTooltipTrigger]="!selectorCSS ? 'hover' : 'none'"
  >
    <div>
      <span>Element</span>
    </div>
    <input
      type="text"
      nz-input
      placeholder="Type element name for example: #elementId, .elementClass, input#login..."
      [ngModel]="selectorCSS"
      (ngModelChange)="selectorCSSChange.emit($event)"
    />
    <button
      nz-button
      nzType="primary"
      nzSize="large"
      (click)="isPickVisible = true"
    >
      Pick an element
    </button>
  </div>
</div>

<nz-modal
  [(nzVisible)]="isPickVisible"
  (nzOnCancel)="isPickVisible = false"
  nzTitle="Choose a url"
  [nzFooter]="null"
  [nzCancelText]="null"
  [nzOkText]="null"
>
  <ng-container *nzModalContent>
    <div class="modal-container">
      <div class="is-flex">
        <input
          type="text"
          placeholder="Enter website url"
          [ngClass]="{
            invalid: false,
          }"
          [(ngModel)]="currentUrl"
          (keyup.enter)="onURLChosen(currentUrl)"
        />
        <button
          nz-button
          nzType="primary"
          nzSize="large"
          [disabled]="!currentUrl?.length"
          (click)="onURLChosen(currentUrl)"
        >
          Choose
        </button>
      </div>
    </div>
    <ng-container *ngIf="currentURLs?.length">
      <div class="recently-used-pages">
        <h4>Your recently used pages</h4>
        <div
          class="recently-used-page"
          *ngFor="let url of currentURLs"
          (click)="onURLChosen(url)"
        >
          <div class="url" [title]="url">{{ url }}</div>
          <screeb-icon size="sm" icon="bottom-arrow"></screeb-icon>
        </div>
      </div>
    </ng-container>
  </ng-container>
</nz-modal>
