import { Pipe, PipeTransform } from "@angular/core";
import { WorkspaceAccountPermission } from "models/account-permissions";
import { PermissionsService } from "services/permissions.service";

@Pipe({
  name: "hasPermission",
})
export class PermissionPipe implements PipeTransform {
  constructor(private permissionsService: PermissionsService) {}

  transform(value: WorkspaceAccountPermission): boolean {
    return this.permissionsService.isAllowed(value);
  }
}
