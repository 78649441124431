<settings-header
  title="Tagging"
  description="Configure tags to automatically add them to answers of your surveys or sessions replay summary"
  (save)="save()"
  [isLoading]="loading"
  [isDisabled]="hasNotChanged()"
></settings-header>

<h3>
  <screeb-icon size="sm" icon="save" />
  Automatic tagging
</h3>
<div class="tags-section">
  <div class="tags" *ngFor="let groupTag of orgAnswerTags">
    <page-settings-tagging-edit
      [groupTag]="groupTag"
      [allOrgTags]="allOrgTags"
      (removeGroupTag)="remove(groupTag)"
    />
  </div>
  <div *ngIf="orgAnswerTags.length === 0">
    <p class="empty">
      No automatic tagging configured yet. Add a new one by clicking on the
      "Add" button below.
    </p>
  </div>
  <div
    class="save"
    [ngClass]="{ invisible: !('tagging.update' | hasPermission) }"
  >
    <button nz-button nzType="primary" (click)="add()">Add</button>
  </div>
</div>
<hr />
<h3>
  <screeb-icon size="sm" icon="click" />
  Smart tagging
</h3>
<div class="smart-tag-container">
  <p>
    Our AI will analyse the actual content of answers and sessions replay
    summary and apply tags based on meaning and not only keywords.
  </p>
  <tag-input
    placeHolder="e.g. customer-support, feature-request"
    size="large"
    [options]="allOrgTagsOptions"
    [ngModel]="orgSmartTags"
    [disabled]="!('tagging.update' | hasPermission)"
    (ngModelChange)="orgSmartTags = $event"
  ></tag-input>
</div>
