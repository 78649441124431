import { Component, OnDestroy, OnInit } from "@angular/core";

import { ActivatedRoute, Router } from "@angular/router";
import { PageComponentInterface } from "components/PageComponentInterface";
import {
  defaultScaleSubscriptionOptions,
  lateralPanelMode,
  planIdToPlanType,
} from "components/super-org/billing/products.data";
import { Org } from "models/org.model";
import {
  BillingSettings,
  notFreeSubscriptionOptions,
  OrgBilling,
  OrgBillingInvoice,
  OrgBillingPaymentMethods,
  OrgBillingSubscription,
  PlanType,
  scalePlanDefaultMAU,
  subscriptionOptions,
} from "models/org_billing.model";
import { SuperOrgDao } from "models/super-org.dao";
import { SuperOrg } from "models/super-org.model";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { RoutingService } from "services/routing.service";
import { SettingsService } from "services/settings.service";
import { TrackersService } from "services/trackers.service";
import { deepCopy } from "utils/object";
import { recommendedPlanTypeUpgrade } from "./components/billing-plan/billing-plan.data";

@Component({
  selector: "page-settings-billing",
  templateUrl: "./billing.component.html",
  styleUrls: ["./billing.component.scss"],
})
export class SettingsBillingPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Billing";
  public name = "Settings billing";

  private obs: any = null;

  public superOrg: SuperOrg = null;
  public workspaces: Org[] = null;
  public orgBilling: OrgBilling = null;
  public orgBillingSubscription: OrgBillingSubscription = null;
  public orgBillingPaymentMethods: OrgBillingPaymentMethods = null;
  public orgBillingInvoices: OrgBillingInvoice[] = null;
  public countries: string[][] = null;
  public currentPlanType: PlanType;

  public hasInvoices: boolean = false;
  public tabIndex: number = 0;
  public lateralPanelOpened: boolean = false;
  public lateralPanelMode: lateralPanelMode = null;
  public lateralPanelCurrentSubscription: subscriptionOptions = null;
  public lateralPanelTargetedSubscription: subscriptionOptions = null;

  public recommendedPlanType: PlanType | null;
  public currentSubscriptionOptions: subscriptionOptions;

  private invoiceRefreshJob: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private routingService: RoutingService,
    public featureFlaggingService: FeatureFlaggingService,
    private superOrgDao: SuperOrgDao,
    private trackersService: TrackersService,
    private settingsService: SettingsService,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.obs = this.route.data.subscribe((data) => {
      this.superOrg = data.superOrg;
      this.workspaces = data.workspaces;
      this.orgBilling = data.orgBilling;
      this.orgBillingSubscription = data.orgBillingSubscription;
      this.orgBillingPaymentMethods = data.orgBillingPaymentMethods;
      this.orgBillingInvoices = data.orgBillingInvoices;
      this.countries = data["languages_and_countries"]["countries"];

      this.hasInvoices = this.orgBillingInvoices?.length > 0;

      this.refreshInvoicePeriodically();
      this.refresh();
    });
  }

  refresh(): void {
    this.currentPlanType = planIdToPlanType(
      this.orgBilling.stripe_product_id,
      this.settingsService.billing,
    );
    this.recommendedPlanType =
      recommendedPlanTypeUpgrade[
        planIdToPlanType(
          this.orgBilling.stripe_product_id,
          this.settingsService.billing,
        )
      ];

    this.currentSubscriptionOptions = this.orgBillingToSubscriptionOptions(
      this.settingsService.billing,
      this.orgBilling,
      this.orgBillingSubscription,
    );
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }

    clearTimeout(this.invoiceRefreshJob);
  }

  public openPanel(
    mode: lateralPanelMode,
    currentSubscription: subscriptionOptions | null,
    targetedSubscription: subscriptionOptions | null,
  ) {
    this.lateralPanelMode = mode;
    this.lateralPanelCurrentSubscription = currentSubscription;
    this.lateralPanelTargetedSubscription = targetedSubscription;
    this.lateralPanelOpened = true;
  }

  public closePanel(newSubscriptionOptions: subscriptionOptions | null) {
    this.lateralPanelOpened = false;

    if (newSubscriptionOptions) {
      this.currentSubscriptionOptions = newSubscriptionOptions;
    }
  }

  public refreshInvoice() {
    this.superOrgDao
      .getInvoicesBySuperOrgId(this.superOrg.id)
      .then((invoices: OrgBillingInvoice[]) => {
        this.orgBillingInvoices = invoices;

        this.hasInvoices = this.orgBillingInvoices?.length > 0;
      })
      .catch((err) => {
        // fails silently
        console.error(err);
      });
  }

  // dirty polling
  private refreshInvoicePeriodically() {
    this.invoiceRefreshJob = setInterval(() => {
      if (this.tabIndex === 2) {
        this.refreshInvoice();
      }
    }, 2000);
  }

  public upgradeAnnualBilling() {
    const event = this.trackersService
      .newEventTrackingBuilder(
        "Organization billing addon additional MTU requested",
      )
      .withSuperOrg(this.superOrg)
      .withProps({
        current_subscription_options: this.currentSubscriptionOptions,
      });

    if (
      this.currentSubscriptionOptions.planType === "scale" ||
      this.currentSubscriptionOptions.planType === "advanced"
    ) {
      if (this.currentSubscriptionOptions.mtu < scalePlanDefaultMAU) {
        // when a customer is on a very old account with less than 10k mtu or on a current plan with less than 10k mtu
        // since there is no addon price for such plans, we prefer just talk to a sales rep
        event.withProps({ action_result: "contact_us" }).build();
        this.askUsAQuestion();
        return;
      }
    }

    if (this.currentPlanType === "custom") {
      event.withProps({ action_result: "contact_us" }).build();
      this.askUsAQuestion();
      return;
    } else if (this.currentPlanType === "free") {
      event.withProps({ action_result: "unexpected" }).build();
      return;
    }

    const targetedSubscriptionOptions = deepCopy(
      this.currentSubscriptionOptions,
    ) as notFreeSubscriptionOptions;

    targetedSubscriptionOptions.billingCycle = "year";

    event
      .withProps({
        action_result: "open_panel",
        upgrade_to: targetedSubscriptionOptions.planType,
      })
      .withProps({ targeted_subscription_options: targetedSubscriptionOptions })
      .build();

    this.openPanel(
      "change_billing_cycle",
      this.currentSubscriptionOptions,
      targetedSubscriptionOptions,
    );
  }

  public onSubscribe(event: any) {
    if (
      this.currentSubscriptionOptions.planType === "scale" ||
      this.currentSubscriptionOptions.planType === "advanced"
    ) {
      if (this.currentSubscriptionOptions.mtu < scalePlanDefaultMAU) {
        // when a customer is on a very old account with less than 10k mtu or on a current plan with less than 10k mtu
        // since there is no addon price for such plans, we prefer just talk to a sales rep
        this.askUsAQuestion();
        return;
      }
    }

    this.openPanel(
      event.mode,
      event.currentSubscription,
      event.targetedSubscription,
    );
  }

  public upgradePlan(planType: "scale" | "advanced" | "custom") {
    const event = this.trackersService
      .newEventTrackingBuilder("Organization billing plan upgrade requested")
      .withSuperOrg(this.superOrg)
      .withProps({
        current_subscription_options: this.currentSubscriptionOptions,
        targeted_subscription_options: deepCopy(
          defaultScaleSubscriptionOptions,
        ),
      });

    if (
      this.currentSubscriptionOptions.planType === "scale" ||
      this.currentSubscriptionOptions.planType === "advanced"
    ) {
      if (this.currentSubscriptionOptions.mtu < scalePlanDefaultMAU) {
        // when a customer is on a very old account with less than 10k mtu or on a current plan with less than 10k mtu
        // since there is no addon price for such plans, we prefer just talk to a sales rep
        event.withProps({ action_result: "contact_us" }).build();
        this.askUsAQuestion();
        return;
      }
    }

    if (planType === "custom") {
      event.withProps({ action_result: "contact_us" }).build();
      this.askUsAQuestion();
      return;
    }

    switch (this.currentSubscriptionOptions.planType) {
      case "free":
        event
          .withProps({
            action_result: "open_panel",
            upgrade_to: planType,
          })
          .build();
        this.openPanel(
          "subscription",
          this.currentSubscriptionOptions,
          deepCopy({
            ...defaultScaleSubscriptionOptions,
            planType,
          }),
        );
        break;
      case "scale":
      case "advanced":
        event
          .withProps({
            action_result: "open_panel",
            upgrade_to: planType,
          })
          .build();
        this.openPanel(
          "subscription",
          this.currentSubscriptionOptions,
          deepCopy({
            ...this.currentSubscriptionOptions,
            planType,
          }),
        );
        break;
      case "custom":
        event.withProps({ action_result: "unexpected" }).build();
        this.askUsAQuestion();
        break;
    }
  }

  public downgradePlan() {
    this.trackersService
      .newEventTrackingBuilder("Organization billing plan downgrade requested")
      .withSuperOrg(this.superOrg)
      .withProps({
        current_subscription_options: this.currentSubscriptionOptions,
        action_result: "contact_us",
      })
      .build();

    this.askUsAQuestion();
  }

  public cancelPlan() {
    const event = this.trackersService
      .newEventTrackingBuilder("Organization billing plan downgrade requested")
      .withSuperOrg(this.superOrg)
      .withProps({
        current_subscription_options: this.currentSubscriptionOptions,
      });

    if (this.currentPlanType === "custom") {
      event.withProps({ action_result: "contact_us" }).build();
      this.askUsAQuestion();
      return;
    }

    event
      .withProps({
        action_result: "open_panel",
        downgrade_to: "free",
        targeted_subscription_options: {
          planType: "free",
        },
      })
      .build();

    this.openPanel("cancel", this.currentSubscriptionOptions, {
      planType: "free",
    });
  }

  private orgBillingToSubscriptionOptions(
    billingSettings: BillingSettings,
    orgBilling: OrgBilling,
    /* eslint-disable */
    orgBillingSubscription: OrgBillingSubscription,
  ): subscriptionOptions {
    if (!orgBilling.stripe_product_id) {
      return { planType: "free" };
    }

    const workspacesIncludedInPlan = orgBilling.stripe_product_id.indexOf("advanced") !== -1 ? 2 : 1;

    return {
      billingCycle: orgBilling.billing_cycle_duration ?? "month",
      planType: planIdToPlanType(orgBilling.stripe_product_id, billingSettings),
      addons: {
        whiteLabel: orgBilling.expected_metadata.white_label,
        additionalWorkspaces:
          orgBilling.expected_metadata.max_workspaces -
          workspacesIncludedInPlan,
        responseTranslation: orgBilling.expected_metadata.response_translation,
        audioVideoSurvey: orgBilling.expected_metadata.audio_video_surveys,
        // aiContentAnalysis: orgBilling.expected_metadata.content_analysis,
        surveyInAppMobile: orgBilling.expected_metadata.survey_inapp_mobile,
        // messageInApp: orgBilling.expected_metadata.message_inapp,
        graphicalSurveyCustomization: orgBilling.expected_metadata.advanced_survey_customization,
        iamMFA: orgBilling.expected_metadata.iam_mfa,
        // sessionRecording: orgBilling.expected_metadata.session_recording,
      },
      mtu: orgBilling.expected_metadata.max_mtu ?? scalePlanDefaultMAU,
      coupon: null, // @TODO: support the case where we would reuse the same coupon for a different subscription.
      // coupon: orgBillingSubscription.discount,
    };
  }

  private askUsAQuestion() {
    if (this.trackersService.intercomIsBooted()) {
      this.trackersService.intercomOpenConversation();
    } else {
      window.location.assign("mailto:michael@screeb.app");
    }
  }

  public goToTab(name: "subscription" | "plans") {
    switch (name) {
      case "subscription":
        this.tabIndex = 0;
        break;
      case "plans":
          this.tabIndex = 1;
          break;
    }
  }
}
