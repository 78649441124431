<section nz-row [nzGutter]="[32, 16]">
  <div *ngIf="!passwordEdit" nz-col nzXs="24" nzXl="12">
    <button
      (click)="passwordEdit = true"
      [nzLoading]="loading"
      nz-button
      nzType="primary"
      nzSize="large"
    >
      Change Password
    </button>
    <div *ngIf="error != null" class="save-error">{{ error }}</div>
  </div>
  <ng-container *ngIf="passwordEdit">
    <div nz-col nzXs="24" nzXl="12">
      <div class="label">Password</div>
      <input
        role="presentation"
        autocomplete="new-password"
        type="password"
        nzSize="large"
        nz-input
        [minLength]="1"
        [maxLength]="56"
        placeholder="Type a new password"
        [(ngModel)]="password"
        [ngClass]="{ invalid: !isValidPassword(password) }"
      />
      <div *ngIf="passwordErrors?.['password_minlength']" class="error">
        Min length 8
      </div>
      <div *ngIf="passwordErrors?.['password_strength_digit']" class="error">
        Missing digit
      </div>
      <div
        *ngIf="passwordErrors?.['password_strength_alpha_uppercase']"
        class="error"
      >
        Missing uppercase character
      </div>
      <div
        *ngIf="passwordErrors?.['password_strength_alpha_lowercase']"
        class="error"
      >
        Missing lowercase character
      </div>
      <div
        *ngIf="passwordErrors?.['password_strength_special_char']"
        class="error"
      >
        Missing special character
      </div>
    </div>
    <div nz-col nzXs="24" nzXl="12">
      <div class="label">Password again</div>
      <input
        type="password"
        nzSize="large"
        nz-input
        [minLength]="1"
        [maxLength]="56"
        placeholder="Type again your new password"
        [(ngModel)]="passwordAgain"
        [ngClass]="{ invalid: !isValidPasswordAgain(passwordAgain) }"
      />
      <div
        *ngIf="passwordAgain && !isValidPasswordAgain(passwordAgain)"
        class="error"
      >
        Must be same password
      </div>
    </div>
    <div class="actions" nz-col nzXs="24" nzXl="12">
      <button
        (click)="passwordEdit = false"
        [nzLoading]="loading"
        nz-button
        nzType="secondary"
        nzSize="large"
      >
        Cancel
      </button>
      <button
        (click)="changePassword()"
        [nzLoading]="loading"
        nz-button
        nzType="primary"
        nzSize="large"
        [disabled]="
          loading ||
          !password ||
          isPasswordSectionInvalid(password, passwordAgain)
        "
      >
        Save Password
      </button>
      <div *ngIf="error != null" class="save-error">{{ error }}</div>
    </div>
  </ng-container>
</section>
