import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { UserNormalizedProperty } from "models/user.model";
import { formatNumber } from "utils/number";

@Component({
  selector: "user-properties",
  templateUrl: "./user-properties.component.html",
  styleUrls: ["./user-properties.component.scss"],
})
export class UserPropertiesComponent implements OnChanges {
  @Input() public userProperties: UserNormalizedProperty[] = [];

  @Output() public eventClicked: EventEmitter<object> = new EventEmitter();

  public formatNumber = formatNumber;

  public userPropertiesWithoutObjects: UserNormalizedProperty[] = [];

  constructor() {}

  ngOnChanges(_: SimpleChanges): void {
    this.userPropertiesWithoutObjects = this.userProperties.filter(
      (p) => p.type !== "object",
    );
  }
}
