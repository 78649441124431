import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { PageComponentInterface } from "components/PageComponentInterface";
import { AuthService } from "services/auth.service";
import { RoutingService } from "services/routing.service";

@Component({
  template: `
    <div class="auth0-register-page"></div>
  `,
  selector: "page-auth0-register",
})
export class Auth0RegisterPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Signup to Screeb 🚀";
  public name = "Register";

  private sub: any = null;

  private invitationOrgToken: string = null;
  public invitationPayload: object = null;

  public isInvitationExpired: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.authService.authStatus = "authenticating";

    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.sub = this.route.queryParams.subscribe((queryParams) => {
      this.invitationOrgToken = queryParams["invitation"]
        ? queryParams["invitation"]
        : null;

      if (this.invitationOrgToken) {
        this.invitationPayload = this.getInvitationTokenPayload();
        this.isInvitationExpired = this.getInvitationIsExpired();
      }

      const email = this.invitationPayload?.["payload"]?.["email"];
      this.authService.redirectOnSignup(email, queryParams);
    });
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }

  // can throw an error in case of malformed token
  private getInvitationTokenPayload(): object {
    return JSON.parse(window.atob(this.invitationOrgToken.split(".")[1]));
  }

  public getInvitationIsExpired(): boolean {
    if (!this.invitationPayload) return false;

    return new Date(this.invitationPayload["exp"] * 1000) < new Date();
  }
}
