<div
  class="capping-banner"
  *ngIf="surveyCappingResponse?.value?.v_n || surveyCappingDisplay?.value?.v_n"
>
  <span class="capping-title">
    <screeb-icon size="sm" icon="warning"></screeb-icon>
    Capping enabled
  </span>
  <span class="capping-desc">
    <p
      *ngIf="
        !!surveyCappingDisplay?.value?.v_n && !surveyCappingResponse?.value?.v_n
      "
    >
      {{ levelToText() }} will receive up to
      <b>{{ surveyCappingDisplay.value.v_n }} displays</b>
      per
      <b>
        {{
          surveyCappingTimePeriodTypesAndLabels[
            surveyCappingDisplay.value.v_n_p
          ].per
        }}
      </b>
      {{ surveyCappingType === "per-survey" ? "until paused." : "." }}
    </p>
    <p
      *ngIf="
        !!surveyCappingResponse?.value?.v_n && !surveyCappingDisplay?.value?.v_n
      "
    >
      {{ levelToText() }} will receive up to
      <b>{{ surveyCappingResponse.value.v_n }} responses</b>
      per
      <b>
        {{
          surveyCappingTimePeriodTypesAndLabels[
            surveyCappingResponse.value.v_n_p
          ].per
        }}
      </b>
      {{ surveyCappingType === "per-survey" ? "until paused." : "." }}
    </p>
    <p
      *ngIf="
        !!surveyCappingDisplay?.value?.v_n &&
        !!surveyCappingResponse?.value?.v_n
      "
    >
      {{ levelToText() }} will receive up to
      <b>{{ surveyCappingDisplay.value.v_n }} displays</b>
      and
      <b>{{ surveyCappingResponse.value.v_n }} responses</b>
      per
      <b>
        {{
          surveyCappingTimePeriodTypesAndLabels[
            surveyCappingResponse.value.v_n_p
          ].per
        }}
      </b>
      {{ surveyCappingType === "per-survey" ? "until paused." : "." }}
    </p>
  </span>
</div>
