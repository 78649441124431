<button
  *ngIf="translationsOptions.length > 0"
  class="translate-btn"
  nz-button
  nz-dropdown
  nzPlacement="bottom"
  [nzDropdownMenu]="translateMenu"
  [(nzVisible)]="isOpen"
  (nzVisibleChange)="updateOpen($event)"
  nzTrigger="click"
  nzType="primary"
  [nzLoading]="isLoading"
  [disabled]="isLoading"
>
  <!-- (click)="scenarioTranslate.emit(builderStore.currentLanguage)" -->
  <i nz-icon nzType="translation" nzTheme="outline"></i>
  Translate
</button>

<nz-dropdown-menu #translateMenu="nzDropdownMenu">
  <div nz-menu class="translate-menu">
    <h2 class="title">Translate Survey</h2>
    <p class="description">Translate survey to the selected languages.</p>

    <section class="translate-config">
      <div
        class="translate-from"
        nz-popover
        nzPopoverTitle="Default Language"
        nzPopoverContent="The questions will be translated from the default language set for this scenario."
      >
        <h3 class="section-title">Translate from:</h3>
        <nz-input-group [nzPrefix]="defaultLanguageEmoji">
          <input nz-input [ngModel]="defaultLanguage.label" disabled />
        </nz-input-group>

        <ng-template #defaultLanguageEmoji>
          <sanitized-message-component
            [content]="defaultLanguage.emoji"
            [options]="{ emojiTranscoder: true }"
          ></sanitized-message-component>
        </ng-template>
      </div>

      <h3 class="section-title">Translate to:</h3>
      <label
        class="translate-all"
        nz-checkbox
        [(ngModel)]="allTranslationsChecked"
        (ngModelChange)="updateAllChecked()"
        [nzIndeterminate]="allTranslationsIndeterminate"
      >
        Select all
      </label>

      <nz-checkbox-wrapper
        class="translate-lang-container"
        (nzOnChange)="updateChecked($event)"
      >
        <div nz-row style="gap: 8px">
          <div
            nz-col
            nzSpan="12"
            class="translate-lang-item"
            *ngFor="let option of translationsOptions; let i = index"
          >
            <label
              nz-checkbox
              [nzChecked]="option.checked"
              [nzValue]="option.value"
              [ngClass]="{
                right: i % 2 !== 0,
              }"
            >
              <sanitized-message-component
                class="lang-option-icon"
                [content]="option.emoji"
                [options]="{ emojiTranscoder: true }"
              ></sanitized-message-component>
              <span class="lang-option-label">{{ option.label }}</span>
            </label>
          </div>
        </div>
      </nz-checkbox-wrapper>

      <nz-divider></nz-divider>

      <div class="translate-setting-option">
        <label>Override already translated questions</label>
        <nz-switch [(ngModel)]="overrideValidQuestions"></nz-switch>
      </div>
    </section>

    <div class="translate-actions">
      <button
        nz-button
        nzType="primary"
        class="translate-btn"
        [disabled]="!validForm || isLoading"
        [nzLoading]="translationLoading"
        (click)="translateScenario()"
      >
        Translate
      </button>
    </div>
  </div>
</nz-dropdown-menu>
