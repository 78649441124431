import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IntegrationAtlassianProject } from "models/integration.dao";
import { Integration } from "models/integrations.model";
import { PermissionsService } from "services/permissions.service";
import { UIService } from "services/ui.service";

@Component({
  selector: "forward-atlassian-modal",
  templateUrl: "./forward-atlassian-modal.component.html",
  styleUrls: ["./forward-atlassian-modal.component.scss"],
})
export class ForwardAtlassianModalComponent {
  @Input() isOpen: boolean = false;
  @Input() integration: Integration;
  @Input() projects: IntegrationAtlassianProject[] = [];
  @Input() projectId: string = null;
  @Output() projectChange: EventEmitter<string> = new EventEmitter();
  @Output() sendTo: EventEmitter<void> = new EventEmitter();
  @Output() modalClose: EventEmitter<void> = new EventEmitter();

  constructor(
    public permissionsService: PermissionsService,
    public uiService: UIService,
  ) {}

  handleCancel(): void {
    this.isOpen = false;
    this.modalClose.emit();
  }

  onProjectChange(projectId: string) {
    this.projectId = projectId;
    this.projectChange.emit(projectId);
  }

  public send() {
    if (!this.projectId) {
      return;
    }
    this.sendTo.emit();
    this.handleCancel();
  }
}
