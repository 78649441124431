import { Component, Input, TemplateRef } from "@angular/core";

type PopoverPlacment =
  | "top"
  | "left"
  | "right"
  | "bottom"
  | "topLeft"
  | "topRight"
  | "bottomLeft"
  | "bottomRight"
  | "leftTop"
  | "leftBottom"
  | "rightTop"
  | "rightBottom";

@Component({
  selector: "help-popover",
  templateUrl: "./help-popover.component.html",
  styleUrls: ["./help-popover.component.scss"],
})
export class HelpPopoverComponent {
  @Input() public placement: PopoverPlacment = "top";
  @Input() content: TemplateRef<any>;

  constructor() {}
}
