import { Component, Input } from "@angular/core";
import {
  surveyNodeTypesSettings,
  messageNodeTypesSettings,
  SurveyScenarioNodeTypeIcon,
} from "models/survey.dao.types";
import { CTAType } from "models/survey.dao.types";

@Component({
  selector: "card-type-icons",
  templateUrl: "./CardTypeIcons.component.html",
  styleUrls: ["./CardTypeIcons.component.scss"],
})
export class CardTypeIconsComponent {
  @Input()
  questionType: CTAType;

  public getSvgName(): SurveyScenarioNodeTypeIcon {
    return (
      surveyNodeTypesSettings[this.questionType] ||
      messageNodeTypesSettings[this.questionType]
    )?.icon;
  }
}
