/**
 * Executes a promise-based operation with automatic retries.
 *
 * @param operation The operation to execute.
 * @param maxRetries The maximum number of retries to attempt.
 * @param retryDelayMs The delay between retries, in milliseconds.
 * @returns A promise that resolves with the operation's result, or rejects if all retries fail.
 */
export async function withRetry<T>(
  operation: () => Promise<T>,
  maxRetries: number,
  retryDelayMs: number,
): Promise<T> {
  for (let i = 0; i <= maxRetries; i++) {
    try {
      const result = await operation();
      return result;
    } catch (error) {
      if (i === maxRetries) {
        throw error; // All retries failed, re-throw the last error.
      }
      await new Promise((resolve) => setTimeout(resolve, retryDelayMs));
    }
  }
}

const delayPromise = (duration: number) => {
  return (_?: any): Promise<any> => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(_);
      }, duration);
    });
  };
};

export { delayPromise };
