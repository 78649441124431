export const deepCopy = <T>(obj: T): T =>
  (Array.isArray(obj)
    ? obj.map((item) => deepCopy(item))
    : obj instanceof Date
      ? new Date(obj.getTime())
      : obj && typeof obj === "object"
        ? Object.getOwnPropertyNames(obj).reduce((o, prop) => {
            o[prop] = deepCopy(obj[prop]);
            return o;
          }, {})
        : obj) as T;

export function deepEqual(v1: any, v2: any) {
  if (typeof v1 !== typeof v2) {
    return false;
  }

  if (
    ["string", "boolean", "number", "bigint", "undefined"].includes(
      typeof v1,
    ) ||
    v1 === null ||
    v2 === null
  ) {
    return v1 === v2;
  }

  if (typeof v1 === "function") {
    return v1.toString() === v2.toString();
  }

  if (Array.isArray(v1)) {
    if (!Array.isArray(v2) || v1.length !== v2.length) {
      return false;
    }
    for (let i = 0; i < v1.length; i++) {
      if (!deepEqual(v1[i], v2[i])) {
        return false;
      }
    }
    return true;
  }

  if (v1 instanceof Date && v2 instanceof Date) {
    return v1.getTime() === v2.getTime();
  }

  const keys1 = Object.keys(v1);
  const keys2 = Object.keys(v2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    // eslint-disable-next-line no-prototype-builtins
    if (!v2.hasOwnProperty(key) || !deepEqual(v1[key], v2[key])) {
      return false;
    }
  }

  return true;
}

export function filterObject<T>(
  obj: Record<string, T>,
  predicate: (value: T, index: number) => boolean,
): Record<string, T> {
  return Object.keys(obj)
    .filter((key, index) => predicate(obj[key], index))
    .reduce((res, key) => ((res[key] = obj[key]), res), {});
}

export function mapObject<T, R>(
  obj: Record<string, T>,
  fn: (value: T, key: string, index: number) => R,
) {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value], index) => [
      key,
      fn(value, key, index),
    ]),
  );
}

export function entriesObject<K extends string | number | symbol, V>(
  obj: Record<K, V>,
): [K, V][] {
  return Object.entries(obj) as [K, V][];
}
