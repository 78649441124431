import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { HttpClient } from "@angular/common/http";

export type IndustriesScore = {
  nps: number;
  csat: number;
  ces: number;
  completionRate: number;
  emotions: any; // Yeah i know, but voilà
};

export type IndustriesScores = {
  [key: string]: {
    label: string;
    scores: IndustriesScore;
  };
};

export type IndustriesScoresType = keyof IndustriesScore;

let cache: IndustriesScores;

@Injectable()
export class AssetIndustriesScoresResolver
  implements Resolve<IndustriesScores>
{
  constructor(private httpClient: HttpClient) {}

  resolve(): Promise<IndustriesScores> {
    if (cache) {
      return Promise.resolve(cache);
    }

    return this.httpClient
      .get<IndustriesScores>(`/assets/data/industries.scores.json`)
      .toPromise()
      .then((data) => {
        cache = data;
        return data;
      });
  }
}
