<div
  class="org-settings"
  [ngClass]="{
    'survey-preview':
      (tabSelectedIndex !== 2 || interaction) && surveyType === 'survey',
    'link-sharing-preview':
      (tabSelectedIndex === 2 && !interaction) || interaction === 'link',
    'email-preview': interaction === 'email',
  }"
>
  <div class="settings">
    <section
      *ngIf="
        (scope === 'survey' &&
          (!haveNewDistributions || haveSurveyTagSettings)) ||
        scope !== 'survey'
      "
    >
      <h2 *ngIf="scope !== 'org'" class="title">
        <screeb-icon size="md" icon="desktop" />
        Design
      </h2>
      <p class="desc" *ngIf="scope === 'survey'">
        Let's customize the look and feel of your
        <b *ngIf="distributionInteractionsFormatted[interaction]">
          {{ distributionInteractionsFormatted[interaction] }}
        </b>
        {{ surveyType }}
      </p>
      <p class="desc" *ngIf="scope === 'distribution'">
        Let's customize the look and feel of your
        {{ surveyType }}
        <b *ngIf="distributionInteractionsFormatted[interaction]">
          {{ distributionInteractionsFormatted[interaction] }}
        </b>
        channel
      </p>
      <div
        class="desc"
        *ngIf="scope === 'survey'"
        style="color: var(--screeb-color-warning-active)"
      >
        This setting is deprecated and will be removed soon.
        <br />
        Please use the
        <a
          [routerLink]="[
            '/',
            'org',
            org.id,
            uiService.surveyTypePath,
            uiService.currentSurveyId,
            'share',
          ]"
        >
          channel design settings
        </a>
      </div>
      <tag-settings-design
        [scope]="scope"
        [surveyDistribution]="surveyDistribution"
        [typeSelected]="surveyType"
        [interaction]="interaction"
        [(tabSelectedIndex)]="tabSelectedIndex"
        [org]="org"
        [(tagSettings)]="tagSettings"
        (specificDesignSwitchChange)="specificDesignSwitchChange.emit($event)"
      ></tag-settings-design>
    </section>
  </div>
  <tag-settings-preview
    *ngIf="
      surveyType === 'survey' &&
      (!haveNewDistributions || haveSurveyTagSettings)
    "
    [mode]="mode"
    [scope]="scope"
    [forceMobile]="shouldForceMobile()"
    class="preview"
    [format]="getFormat()"
    [tagSettings]="tagSettings ?? defaultTagSettings"
  ></tag-settings-preview>
</div>
