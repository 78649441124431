import { IntegrationType } from "./integrations.model";

export type HookType =
  | "response.created"
  | "response.answered"
  | "response.ended";
// | 'respondent.created'
// | 'respondent.tracking.identity' | 'respondent.tracking.group' | 'respondent.tracking.event' | 'respondent.tracking.screen' | 'respondent.tracking.page';

type hookSpec = {
  label: string;
  sampleLabel: string;
  type: HookType;
  // currentVersion: string,
  // versions: string[],
};

export const hooksSpec: { [key in HookType]: hookSpec } = {
  "response.created": {
    label: "Everytime a survey is displayed",
    sampleLabel: "Survey display",
    type: "response.created",
  },
  "response.answered": {
    label: "Everytime a question is replied",
    sampleLabel: "Question answer",
    type: "response.answered",
  },
  "response.ended": {
    label: "Everytime a response is completed",
    sampleLabel: "Response completion",
    type: "response.ended",
  },
};

type hookIntegrationSpec = {
  type: IntegrationType;
  currentVersion: string | null;
  availableVersions: string[] | null;
  hooksSpec: hookSpec[];
};

export const hookIntegrationsSpec: {
  [key in IntegrationType]: hookIntegrationSpec | null;
} = {
  // some integrations are not providing hooks

  // forward
  harvestr: null,
  productboard: null,
  zendesk: null,
  intercom: null,
  trello: null,
  cycle: null,
  monday: null,
  asana: null,

  // cdp
  "amplitude-source": null,
  "segment-source": null,
  "rudderstack-source": null,
  "contentsquare-source": null,
  typeform: null,

  webhook: {
    type: "webhook",
    currentVersion: "2.1.0",
    availableVersions: ["1.3.0", "2.0.0", "2.1.0"],
    hooksSpec: [
      hooksSpec["response.created"],
      hooksSpec["response.answered"],
      hooksSpec["response.ended"],
    ],
  },
  stitch: {
    type: "stitch",
    currentVersion: "2.0.0",
    availableVersions: ["1.0.0", "2.0.0"],
    hooksSpec: [
      hooksSpec["response.created"],
      hooksSpec["response.answered"],
      hooksSpec["response.ended"],
    ],
  },
  zapier: {
    type: "zapier",
    currentVersion: "2.1.0",
    availableVersions: ["1.0.0", "2.0.0", "2.1.0"],
    hooksSpec: [
      hooksSpec["response.created"],
      hooksSpec["response.answered"],
      hooksSpec["response.ended"],
    ],
  },
  cobbai: {
    type: "cobbai",
    currentVersion: "2.0.0",
    availableVersions: ["1.0.0", "2.0.0", "2.1.0"],
    hooksSpec: [],
  },
  notion: {
    type: "notion",
    currentVersion: "2.0.0",
    availableVersions: ["1.0.0", "2.0.0"],
    hooksSpec: [
      hooksSpec["response.created"],
      hooksSpec["response.answered"],
      hooksSpec["response.ended"],
    ],
  },
  atlassian: {
    type: "atlassian",
    currentVersion: null,
    availableVersions: null,
    hooksSpec: [
      hooksSpec["response.created"],
      hooksSpec["response.answered"],
      hooksSpec["response.ended"],
    ],
  },
  email: {
    type: "email",
    currentVersion: null,
    availableVersions: null,
    hooksSpec: [
      hooksSpec["response.created"],
      hooksSpec["response.answered"],
      hooksSpec["response.ended"],
    ],
  },
  "amplitude-destination": null,
  "segment-destination": null,
  "rudderstack-destination": null,

  // reporting
  slack: {
    type: "slack",
    currentVersion: null,
    availableVersions: null,
    hooksSpec: [
      hooksSpec["response.created"],
      hooksSpec["response.answered"],
      hooksSpec["response.ended"],
    ],
  },
  "contentsquare-destination": {
    type: "contentsquare-destination",
    currentVersion: null,
    availableVersions: null,
    hooksSpec: [
      hooksSpec["response.created"],
      hooksSpec["response.answered"],
      hooksSpec["response.ended"],
    ],
  },
};

export const REGEXP_VALIDATION_WEBHOOK = `^(http(s)?:\\/\\/.)[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,10}(:\\d*)?\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)$`;
// export const REGEXP_VALIDATION_WEBHOOK_STITCH = `^(http(s)?:\\/\\/.)[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,10}(:\\d*)?\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)$`;
export const REGEXP_VALIDATION_WEBHOOK_STITCH = /stitchdata.com\/v1\/clients\//;
// export const REGEXP_VALIDATION_WEBHOOK_COBBAI = `^(http(s)?:\\/\\/.)[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,10}(:\\d*)?\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)$`;
export const REGEXP_VALIDATION_WEBHOOK_COBBAI =
  /^https:\/\/[a-zA-Z0-9-]+\.cobbai\.com\/[a-zA-Z0-9\-_]+\/connectors\/listener_screeb\/synchronize\?(import_id|token)=[a-zA-Z0-9\-_]+&(import_id|token)=[a-zA-Z0-9\-_]+$/;
