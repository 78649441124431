import { Component, Input } from "@angular/core";
import { TargetingRule } from "models/targeting-rule.model";

@Component({
  selector: "rule-dom-element",
  templateUrl: "./rule-dom-element.component.html",
  styleUrls: ["./rule-dom-element.component.scss"],
})
export class TargetingRuleDOMElementComponent {
  @Input()
  public rule: TargetingRule = null;

  constructor() {}

  public checkValidQuerySelector(r: TargetingRule): boolean {
    try {
      document.querySelector(r.value.v_s);
      return true;
    } catch (e) {
      return false;
    }
  }
}
