<div>
  <div class="billing-header">
    <div>
      <h1>Billing</h1>
      <p>
        Manage your subscription, payment methods, invoices, and billing
        details.
      </p>
    </div>

    <div style="display: flex">
      <intercom-button title="Any Question?" />

      <a
        *ngIf="'displayStripeShortcut' | hasFeatureFlag"
        nz-button
        nzType="primary"
        nzSize="large"
        class="stripe-customer-link"
        [href]="orgBilling.getStripeAdminURLForCustomer()"
        target="_blank"
      >
        Stripe
      </a>
    </div>
  </div>

  <billing-subscription-paused-subscription-alert
    [orgBilling]="orgBilling"
  ></billing-subscription-paused-subscription-alert>

  <nz-tabset
    [(nzSelectedIndex)]="tabIndex"
    [nzAnimated]="false"
    class="billing-navbar"
  >
    <nz-tab nzTitle="Your Subscription">
      <settings-billing-tab-subscription
        [superOrg]="superOrg"
        [workspaces]="workspaces"
        [orgBilling]="orgBilling"
        [countries]="countries"
        [orgBillingSubscription]="orgBillingSubscription"
        [(orgBillingPaymentMethods)]="orgBillingPaymentMethods"
        [orgBillingInvoices]="orgBillingInvoices"
        [currentSubscriptionOptions]="currentSubscriptionOptions"
        (onUpgrade)="upgradePlan($event)"
        (onDowngrade)="downgradePlan()"
        (onCancel)="cancelPlan()"
        (onSubscribe)="onSubscribe($event)"
        (openPanel)="openPanel($event, null, null)"
        (goToTab)="goToTab($event)"
      ></settings-billing-tab-subscription>
    </nz-tab>

    <!-- ************************************ -->
    <nz-tab nzTitle="Plans">
      <settings-billing-tab-plans
        [superOrg]="superOrg"
        [orgBilling]="orgBilling"
        [orgBillingSubscription]="orgBillingSubscription"
        [orgBillingPaymentMethods]="orgBillingPaymentMethods"
        [orgBillingInvoices]="orgBillingInvoices"
        (onUpgrade)="upgradePlan($event)"
        (onDowngrade)="downgradePlan()"
        (onCancel)="cancelPlan()"
      ></settings-billing-tab-plans>
    </nz-tab>
  </nz-tabset>

  <billing-lateral-panel
    *ngIf="lateralPanelOpened"
    [mode]="[lateralPanelMode]"
    [currentSubscription]="lateralPanelCurrentSubscription"
    [targetedSubscription]="lateralPanelTargetedSubscription"
    [(superOrg)]="superOrg"
    [(orgBilling)]="orgBilling"
    [orgBillingInvoices]="orgBillingInvoices"
    [(orgBillingPaymentMethods)]="orgBillingPaymentMethods"
    [(orgBillingSubscription)]="orgBillingSubscription"
    [countries]="countries"
    (refreshInvoice)="refreshInvoice()"
    (onClose)="closePanel($event)"
  ></billing-lateral-panel>
</div>
