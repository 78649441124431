<header>
  <div>
    <h1 [innerHTML]="title"></h1>
    <p [innerHTML]="description"></p>
    <div class="error-banner" *ngIf="error">
      <span class="error-title">
        <screeb-icon size="sm" icon="warning"></screeb-icon>
        Oops!
      </span>
      <span class="error-desc">
        {{
          error !== true
            ? error
            : "An error occurred while saving, please try again."
        }}
      </span>
    </div>
  </div>
  <div *ngIf="isSaveUsed">
    <button
      (click)="save.emit()"
      [nzLoading]="isLoading"
      class="ant-btn-secondary"
      nz-button
      nzType="primary"
      nzSize="large"
      [disabled]="isDisabled || isLoading"
    >
      <i nz-icon nzType="check"></i>
      {{ isLoading ? "Saving" : "Save" }}
    </button>
  </div>
</header>
