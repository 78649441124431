import { Component, OnInit } from "@angular/core";
import { Changelog, changelogs } from "./changelogs.type";

@Component({
  selector: "widget-changelog",
  templateUrl: "./changelog.component.html",
  styleUrls: ["./changelog.component.scss"],
})
export class WidgetChangelogComponent implements OnInit {
  public changelogs: Changelog[] = [];

  public currentChangelogIdx: number = 0;
  private totalChangelogsToPreview: number = 5;

  public get currentChangelog(): Changelog {
    return this.changelogs[this.currentChangelogIdx];
  }

  ngOnInit(): void {
    // take last 5 changelogs
    this.changelogs = changelogs
      .slice(-this.totalChangelogsToPreview)
      .reverse();
  }

  public nextChangelog(): void {
    this.currentChangelogIdx =
      (this.currentChangelogIdx + 1) % this.changelogs.length;
  }

  public prevChangelog(): void {
    this.currentChangelogIdx =
      (this.currentChangelogIdx - 1 + this.changelogs.length) %
      this.changelogs.length;
  }
}
