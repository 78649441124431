type ChangelogType = "update" | "fix" | "new feature";

export type Changelog = {
  title: string;
  thumbnail: string;
  type: ChangelogType;
  url?: string;
  date: Date;
};

export const changelogs: Changelog[] = [
  {
    title: "Response Expiration",
    thumbnail: "/assets/changelogs/response-expiration.png",
    type: "new feature",
    url: "",
    date: new Date("2023-01-23"),
  },
  {
    title: "New Mobile SDK",
    thumbnail: "/assets/changelogs/mobile-sdk.png",
    type: "update",
    url: "https://changelog.screeb.app/changelogs/2023-08-08-mobile-sdk",
    date: new Date("2023-08-09"),
  },
  {
    title: "AI Survey Builder",
    thumbnail: "/assets/changelogs/new-builder.png",
    type: "new feature",
    url: "https://changelog.screeb.app/changelogs/2023-08-09-ai-builder",
    date: new Date("2023-08-09"),
  },
  {
    title: "New Homepage",
    thumbnail: "/assets/changelogs/new-homepage.png",
    type: "update",
    url: "https://changelog.screeb.app/changelogs/2023-08-23-new-homepage",
    date: new Date("2023-08-23"),
  },
  {
    title: "Emotion Analysis",
    thumbnail: "/assets/changelogs/response-emotions.png",
    type: "new feature",
    url: "https://changelog.screeb.app/changelogs/2023-09-29-response-emotions",
    date: new Date("2023-09-29"),
  },
  {
    title: "Audio & Video Surveys",
    thumbnail: "/assets/changelogs/survey-audio-video.gif",
    type: "new feature",
    url: "https://changelog.screeb.app/changelogs/2023-10-24-survey-audio-video",
    date: new Date("2023-10-24"),
  },
  {
    title: "Response Tagging",
    thumbnail: "/assets/changelogs/new-homepage.png",
    type: "new feature",
    url: "https://changelog.screeb.app/changelogs/2023-10-25-response-tagging",
    date: new Date("2023-10-25"),
  },
  {
    title: "Benchmarks",
    thumbnail: "/assets/changelogs/benchmark-page.png",
    type: "new feature",
    url: "https://changelog.screeb.app/changelogs/2024-01-08-benchmark-page",
    date: new Date("2024-01-08"),
  },
  {
    title: "Recurrent Surveys",
    thumbnail: "/assets/changelogs/recurrence-if-not-answered.png",
    type: "update",
    url: "https://changelog.screeb.app/changelogs/2024-02-21-recurring-if-not-answered",
    date: new Date("2024-02-21"),
  },
  {
    title: "Integrations Filters",
    thumbnail: "/assets/changelogs/filters-integrations.png",
    type: "update",
    url: "https://changelog.screeb.app/changelogs/2024-04-17-filters-integrations",
    date: new Date("2024-04-17"),
  },
  {
    title: "Single Sign-On",
    thumbnail: "/assets/changelogs/sso.png",
    type: "new feature",
    url: "https://changelog.screeb.app/changelogs/2024-05-01-single-sign-on",
    date: new Date("2024-05-01"),
  },
  {
    title: "Ignore Anonymous Users",
    thumbnail: "/assets/changelogs/ignore-anonymous-users.png",
    type: "update",
    url: "https://changelog.screeb.app/changelogs/2024-05-07-ignore-anonymous-users",
    date: new Date("2024-05-07"),
  },
  {
    title: "Screeb Day 🎉",
    type: "update",
    thumbnail: "/assets/changelogs/365.png",
    url: "https://screeb.app/blog/new-360-customer-feedback-platform",
    date: new Date("2024-07-15"),
  },
  {
    title: "Feedback Button",
    type: "new feature",
    thumbnail: "/assets/changelogs/feedback-button-survey.png",
    url: "https://changelog.screeb.app/changelogs/2024-08-29-feedback-button",
    date: new Date("2024-08-29"),
  },
  {
    title: "Scenario Translation",
    type: "new feature",
    thumbnail: "/assets/changelogs/response-translation.png",
    url: "https://changelog.screeb.app/changelogs/2024-10-17-scenario-translation",
    date: new Date("2024-10-17"),
  },
];
