<div class="header-group">
  <div>
    <h1>Session details</h1>
    <div class="tags">
      <div *ngFor="let tag of userRecord.tags" class="no-break-line">
        <nz-tag
          class="no-copy"
          [nzColor]="'var(--screeb-color-yellow-alpha)'"
          nz-tooltip
          [nzTooltipTitle]="tag"
        >
          <p>{{ tag }}</p>
          <i
            (click)="onCopy($event, tag)"
            nz-icon
            nzType="copy"
            nzTheme="outline"
          ></i>
          <i
            (click)="handleDeleteTag(userRecord.id, tag)"
            nz-icon
            nzType="close"
            nzTheme="outline"
          ></i>
        </nz-tag>
      </div>
      <nz-tag
        *ngIf="!isAddingTag && ('tagging.update' | hasPermission)"
        class="no-copy"
        [nzColor]="'var(--screeb-color-yellow-alpha)'"
        nz-tooltip
        (click)="isAddingTag = true"
      >
        <span *ngIf="!userRecord.tags?.length">Add tags&nbsp;</span>
        +
      </nz-tag>
      <tag-input
        *ngIf="isAddingTag"
        [(ngModel)]="selectedAddedTags"
        placeHolder="Tag name"
      ></tag-input>
      <button
        *ngIf="isAddingTag"
        nz-button
        nzType="primary"
        (click)="onConfirmTag(userRecord.id)"
      >
        Add
      </button>
    </div>
  </div>
  <div>
    <a
      routerLink="/org/{{ org.id }}/survey/create"
      [queryParams]="{ respondentIds: userRecord.user_id }"
    >
      <button
        *ngIf="'survey.create' | hasPermission"
        nz-button
        nzType="primary"
        nzSize="large"
        class="ask-a-question-button"
      >
        <img
          alt="Ask a question"
          class="icon"
          src="/assets/icons/common/ask-a-question.svg"
        />
        Ask a question
      </button>
    </a>
  </div>
</div>
<error-message
  *ngIf="errorResponsesES"
  message="An error occured when fetching data."
  [details]="errorResponsesES.message"
  (reload)="refreshData()"
></error-message>

<section
  *ngIf="!errorResponsesES"
  nz-row
  [nzGutter]="[32, 16]"
  class="player-container"
>
  <div nz-col nzSpan="24" nzXl="16">
    <div
      class="rrweb-player-wrapper"
      (mouseenter)="onPlayerHover(true)"
      (mouseleave)="onPlayerHover(false)"
      (mousemove)="onPlayerHover(true)"
    >
      <canvas class="heatmap"></canvas>
      <div id="rrweb-player-target" #rrwebPlayerWrapper></div>
      <div
        class="player-skipping {{
          isSkeepingSpeed && (isRecordPlaying || isSeeking) ? 'show' : ''
        }} {{ !isControllerHidden ? 'is-open' : '' }}"
      >
        <screeb-icon size="md" icon="run"></screeb-icon>
        <div>
          <p>Fast forwarding through inactivity</p>
          <small>Fast forwarding at {{ isSkeepingSpeed }}x</small>
        </div>
      </div>
      <div
        class="player-controllers"
        [class.is-hidden]="isControllerHidden"
        (click)="onPlayerControllersClick()"
      >
        <div>
          <div
            class="player-controller-comment-modal {{
              commentModalOpen ? 'is-open' : ''
            }}"
            (click)="$event.stopPropagation()"
          >
            <nz-textarea-count [nzMaxCharacterCount]="500">
              <textarea
                #commentTextArea
                nz-input
                placeholder="Enter your comment"
                [(ngModel)]="comment"
                (keydown)="onCommentKeyDown($event)"
              ></textarea>
            </nz-textarea-count>
            <div class="player-controller-comment-modal-actions">
              <p>
                Comment at
                <span class="timecode">
                  {{ commentFormatedTimecode }}
                </span>
              </p>
              @let isCommentOk = comment?.trim()?.length;
              <button
                class="btn btn-primary"
                (click)="onCommentSubmit()"
                nz-button
                nzType="primary"
                [disabled]="!isCommentOk"
                [nzLoading]="isCommentLoading"
              >
                Comment
              </button>
            </div>
          </div>
          <div class="player-controller-comment-actions">
            <div
              class="player-controller-comment-action {{
                commentModalOpen && commentModalOpenEmoji === '⚠️'
                  ? 'active'
                  : ''
              }}"
              (click)="onCommentOpen($event, '⚠️')"
            >
              @let emojiWarn = emojiTranscoder("⚠️");
              <span [innerHtml]="emojiWarn"></span>
            </div>
            <div
              class="player-controller-comment-action {{
                commentModalOpen && commentModalOpenEmoji === '👍'
                  ? 'active'
                  : ''
              }}"
              (click)="onCommentOpen($event, '👍')"
            >
              @let emojiUp = emojiTranscoder("👍");
              <span [innerHtml]="emojiUp"></span>
            </div>
            <div
              class="player-controller-comment-action {{
                commentModalOpen && commentModalOpenEmoji === '👎'
                  ? 'active'
                  : ''
              }}"
              (click)="onCommentOpen($event, '👎')"
            >
              @let emojiDown = emojiTranscoder("👎");
              <span [innerHtml]="emojiDown"></span>
            </div>
            <div
              class="player-controller-comment-action {{
                commentModalOpen && commentModalOpenEmoji === true
                  ? 'active'
                  : ''
              }}"
              (click)="onCommentOpen($event, true)"
            >
              <screeb-icon size="sm" icon="comment"></screeb-icon>
            </div>
          </div>
          <div
            class="player-controller-progress"
            (click)="onPlayerProgressClick($event)"
          >
            <ng-container *ngFor="let index of highlightsBarOrder">
              @let startPercent = getStartPercent(highlights[index]);
              @let endPercent = getEndPercent(highlights[index]);
              <div
                class="player-controller-record player-controller-record--{{
                  highlights[index].type
                }}"
                style="left: {{ startPercent }}%;width: max(8px, {{
                  endPercent
                }}%);"
                nz-tooltip
                [nzTooltipTitle]="highlights[index].title"
                [nzTooltipPlacement]="'top'"
                [nzTooltipTitle]="titleTemplate"
                [nzTooltipTitleContext]="{
                  $implicit: highlights[index].title,
                }"
                [nzTooltipOverlayStyle]="{ top: '-24px' }"
                (nzTooltipVisibleChange)="
                  onHighlightTooltipVisibleChange($event)
                "
              >
                <ng-template #titleTemplate let-thing>
                  <span [innerHtml]="thing">T</span>
                </ng-template>
                <div
                  class="player-controller-record-icon"
                  (click)="onTimecodeClick(highlights[index])"
                >
                  <screeb-icon
                    *ngIf="highlights[index].type === 'response'"
                    size="sm"
                    icon="survey"
                  ></screeb-icon>
                  <screeb-icon
                    *ngIf="highlights[index].type === 'event'"
                    size="md"
                    icon="events"
                  ></screeb-icon>
                  <screeb-icon
                    *ngIf="highlights[index].type === 'console'"
                    size="sm"
                    style="color: {{
                      highlights[index].data === 'error'
                        ? '#F11672'
                        : highlights[index].data === 'warn'
                          ? '#faad14'
                          : undefined
                    }} !important"
                    icon="{{
                      highlights[index].data === 'error'
                        ? 'cross-circle'
                        : highlights[index].data === 'warn'
                          ? 'warning'
                          : 'info-circle'
                    }}"
                  ></screeb-icon>
                  <ng-container *ngIf="highlights[index].type === 'comment'">
                    @let comment = getCommentById(highlights[index].data);
                    <ng-container *ngIf="comment.emoji?.length">
                      @let emoji = emojiTranscoder(comment.emoji);
                      <span [innerHtml]="emoji"></span>
                    </ng-container>
                    <screeb-icon
                      *ngIf="!comment.emoji?.length"
                      size="sm"
                      icon="comment"
                    ></screeb-icon>
                  </ng-container>
                </div>
                <div
                  class="player-controller-record-bar"
                  style="background-color: {{
                    highlights[index].type === 'console'
                      ? highlights[index].data === 'error'
                        ? '#F11672'
                        : highlights[index].data === 'warn'
                          ? '#faad14'
                          : undefined
                      : undefined
                  }} !important"
                ></div>
              </div>
            </ng-container>
            <div
              class="player-controller-progress-bar {{
                !player ? 'loading' : ''
              }} {{ isSeeking ? 'seeking' : '' }}"
              style="width: {{ recordProgress }}%;"
            ></div>
          </div>

          <div class="player-controllers-inner">
            <screeb-icon
              *ngIf="!isRecordPlaying && !isSeeking"
              size="md"
              icon="play"
              (click)="onPlayerToggle()"
            />
            <screeb-icon
              *ngIf="isRecordPlaying || isSeeking"
              size="md"
              icon="pause"
              (click)="onPlayerToggle()"
            />
            <screeb-icon
              size="md"
              icon="seek-prev"
              [class.icon-paused]="!canSeekPrev"
              (click)="onPlayerSeekPrev()"
            />
            <screeb-icon
              size="md"
              icon="seek-next"
              style="position: relative; top: 1px"
              [class.icon-paused]="!canSeekNext"
              (click)="onPlayerSeekNext()"
            />
            <div class="is-spacer"></div>
            <p>{{ playerFormattedTime }} - {{ playerTotalTime }}</p>
            <div class="is-spacer"></div>
            <screeb-icon
              size="md"
              icon="fullscreen"
              (click)="onPlayerFullScreen()"
            />
            <screeb-icon
              *ngIf="playerSpeed === 1"
              size="md"
              icon="speed"
              (click)="onPlayerSpeedChange(2)"
            />
            <screeb-icon
              *ngIf="playerSpeed === 2"
              size="md"
              icon="speed-2x"
              (click)="onPlayerSpeedChange(3)"
            />
            <screeb-icon
              *ngIf="playerSpeed === 3"
              size="md"
              icon="speed-3x"
              (click)="onPlayerSpeedChange(4)"
            />
            <screeb-icon
              *ngIf="playerSpeed === 4"
              size="md"
              icon="speed-4x"
              (click)="onPlayerSpeedChange(5)"
            />
            <screeb-icon
              *ngIf="playerSpeed === 5"
              size="md"
              icon="speed-5x"
              (click)="onPlayerSpeedChange(1)"
            />
          </div>
        </div>
      </div>
    </div>
    <nz-tabset
      [nzSelectedIndex]="selectedTab"
      (nzSelectedIndexChange)="onTabChange($event)"
    >
      <ng-template #summaryTitleTemplate let-thing>
        Summary
        <span class="highlight-title-badge">AI ✨</span>
      </ng-template>
      <nz-tab [nzTitle]="summaryTitleTemplate">
        <div
          class="loading-summary"
          *ngIf="
            userRecord.completion_status < 3 && !userRecord.summary?.length
          "
        >
          <text-shimmer [lines]="10"></text-shimmer>
          <div>
            <p>Processing...</p>
            <small>This may take a few minutes</small>
            <a (click)="selectedTab = 1">View highlights</a>
          </div>
        </div>
        <div
          *ngIf="
            !userRecord.summary?.length && userRecord.completion_status >= 3
          "
        >
          <nz-empty nzNotFoundContent="No Summary"></nz-empty>
        </div>
        <div class="summary" *ngIf="userRecord.summary?.length">
          <markdown
            ngPreserveWhitespaces
            [data]="userRecord.summary"
          ></markdown>
        </div>
      </nz-tab>
      <ng-template #highlightTitleTemplate let-thing>
        Highlights
        <span *ngIf="highlights.length" class="highlight-title-badge">
          {{ highlights.length }}
        </span>
      </ng-template>
      <nz-tab [nzTitle]="highlightTitleTemplate">
        <nz-empty
          *ngIf="!highlights.length && !player"
          nzNotFoundContent="No highlights"
        ></nz-empty>
        <div class="loader" *ngIf="!player">
          <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
        </div>
        <div class="highlights" *ngIf="player">
          <nz-empty
            *ngIf="!highlights.length"
            nzNotFoundContent="No highlights"
          ></nz-empty>
          <ng-container *ngFor="let highlight of highlights; let i = index">
            @let levelClass =
              highlight.type === "console"
                ? ["info", "warn", "error"].includes(highlight.data)
                  ? highlight.data
                  : "info"
                : "";
            <div
              *ngIf="highlight.type !== 'event'"
              class="highlight {{ highlight.type }} {{
                highlight.type === 'console' ? 'level-' + levelClass : ''
              }}"
              (click)="onHighlightClick(highlight, i)"
            >
              <div class="highlight-icon">
                <screeb-icon
                  *ngIf="highlight.type === 'response'"
                  size="sm"
                  icon="survey"
                ></screeb-icon>
                <screeb-icon
                  *ngIf="highlight.type === 'console'"
                  size="sm"
                  icon="{{
                    highlight.data === 'error'
                      ? 'cross-circle'
                      : highlight.data === 'warn'
                        ? 'warning'
                        : 'info-circle'
                  }}"
                ></screeb-icon>
                <ng-container *ngIf="highlight.type === 'comment'">
                  @let comment = getCommentById(highlight.data);
                  @let account = getOrgAccount(comment.created_by);
                  <org-account-avatar
                    *ngIf="account"
                    [account]="account"
                    size="medium"
                  ></org-account-avatar>
                </ng-container>
              </div>
              <div class="highlight-content">
                <p class="highlight-title">
                  <ng-container *ngIf="highlight.type === 'comment'">
                    @let comment = getCommentById(highlight.data);
                    <ng-container *ngIf="comment.emoji">
                      @let emojiTitle = emojiTranscoder(comment.emoji);
                      <span [innerHtml]="emojiTitle"></span>
                    </ng-container>
                    <screeb-icon
                      *ngIf="!comment.emoji"
                      size="sm"
                      icon="comment"
                    ></screeb-icon>
                  </ng-container>
                  <span [innerHTML]="highlight.title"></span>
                </p>
                <hr />
                <div class="highlight-footer">
                  <p>
                    {{
                      highlight.timestamp | dfnsFormat: "yyyy-MM-dd HH:mm:ss"
                    }}
                  </p>
                  <span>·</span>
                  <ng-container *ngIf="highlight.type === 'comment'">
                    @let comment = getCommentById(highlight.data);
                    @let author = getOrgAccount(comment.created_by);
                    <p *ngIf="author">
                      <span>{{ author?.fullname }}</span>
                    </p>
                    <span>·</span>
                  </ng-container>

                  @let timecode = getTimecode(highlight.timecode_start);
                  <p
                    class="timecode"
                    (click)="
                      $event.stopPropagation(); onTimecodeClick(highlight)
                    "
                  >
                    {{ timecode }}
                  </p>
                  <div class="spacer"></div>
                  <p
                    class="highlight-action"
                    *ngIf="highlight.type === 'console'"
                  >
                    See console
                    <screeb-icon size="sm" icon="arrow-right"></screeb-icon>
                  </p>
                  <p
                    class="highlight-action"
                    *ngIf="highlight.type === 'response'"
                  >
                    Open results
                    <screeb-icon size="sm" icon="arrow-right"></screeb-icon>
                  </p>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </nz-tab>
      <nz-tab nzTitle="Events">
        <div class="indicator-with-border full-height">
          <div class="indicator-header">
            <h4>Events</h4>
          </div>
          <div class="loader" *ngIf="loadingEvents && initialFetchEvents">
            <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
          </div>
          <ng-container *ngIf="!initialFetchEvents || !loadingEvents">
            <user-events
              [userEvents]="userEvents"
              (eventClicked)="onEventClicked()"
            ></user-events>
          </ng-container>
        </div>
      </nz-tab>
      <nz-tab nzTitle="Console">
        <div
          class="disabled-tip"
          *ngIf="!org.settings.session_replay_record_console"
        >
          <screeb-icon size="sm" icon="info-circle"></screeb-icon>
          <span>Console logs are disabled for this workspace.</span>
          <a [routerLink]="['/org', org.id, 'settings', 'session-replay']">
            Go settings
          </a>
        </div>
        <div class="event-console">
          <div class="event-console__header">
            <div class="event-console__header-title">Console</div>
            <div class="event-console__header-actions">
              <!-- filter nz-select -->
              <nz-select
                nzSize="small"
                nzShowSearch
                nzMode="multiple"
                [nzShowArrow]="true"
                [nzMaxTagCount]="2"
                nzPlaceHolder="Filter by level"
                [(ngModel)]="consoleFilter"
              >
                <nz-option
                  *ngFor="let level of allFiltersLevels"
                  [nzValue]="level.value"
                  [nzLabel]="level.label"
                ></nz-option>
              </nz-select>
            </div>
          </div>
          <div class="event-console__body">
            <nz-empty
              *ngIf="!consoleEvents.length"
              nzNotFoundContent="No console logs"
            ></nz-empty>
            <ng-container *ngFor="let entry of consoleEvents; let i = index">
              @let included = consoleFilter.includes(entry.data.payload.level);
              <div
                *ngIf="included"
                class="event-console__entry level-{{
                  entry.data.payload.level
                }}"
                (click)="onConsoleEventClick(i)"
              >
                <div class="event-console__entry__icon">
                  <screeb-icon
                    size="sm"
                    icon="{{
                      entry.data.payload.level === 'error'
                        ? 'cross-circle'
                        : entry.data.payload.level === 'warn'
                          ? 'warning'
                          : 'info-circle'
                    }}"
                  ></screeb-icon>
                </div>
                <div class="event-console__entry__content">
                  <div class="event-console__entry__content__title">
                    <p *ngFor="let title of entry.data.payload.payload">
                      {{ title }}
                    </p>
                  </div>
                  <div class="event-console__entry__content__trace">
                    <u *ngIf="entry.data.payload.trace.length">
                      {{ entry.data.payload.trace[0] }}
                    </u>
                    <div
                      class="event-console__entry__action"
                      *ngIf="
                        entry.data.payload.trace.length > 1 && !entry.expanded
                      "
                    >
                      View full trace
                    </div>
                    <div
                      class="event-console__entry__action"
                      *ngIf="
                        entry.data.payload.trace.length > 1 && entry.expanded
                      "
                    >
                      Reduce trace
                    </div>
                  </div>
                  <div
                    class="event-console__entry__content__expanded"
                    *ngIf="
                      entry.data.payload.trace.length > 1 && entry.expanded
                    "
                  >
                    @let trace = entry.data.payload.trace.slice(1).join("\n");
                    <p>{{ trace }}</p>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </nz-tab>
      <nz-tab nzTitle="Details">
        <user-context
          [userRecord]="userRecord"
          [userEvents]="userEvents"
        ></user-context>
      </nz-tab>
    </nz-tabset>
  </div>
  <div nz-col nzXs="24" nzLg="24" nzXl="8">
    <nz-spin *ngIf="!user" nzSimple [nzSize]="'large'"></nz-spin>
    <section nz-row [nzGutter]="[32, 16]" class="indicators" *ngIf="user">
      <div
        nz-col
        nzSpan="24"
        class="fullsize-indicator"
        style="margin-top: 8px"
      >
        <div class="indicator-with-border user-header">
          @let userIcon =
            loadingProperties
              ? "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
              : getUserIcon(user.id, getAvatarURL(userProperties));
          <a
            class="open-link is-avatar"
            [class.has-shimmer]="loadingProperties"
            [routerLink]="
              !user ? null : ['/org', org.id, 'people', 'respondent', user.id]
            "
          >
            <img loading="lazy" alt="User's icon" nz-icon [src]="userIcon" />
          </a>
          <div class="right">
            <div class="identity">
              <p>
                @let formattedUserNameOrID =
                  !user
                    ? "Loading..."
                    : getFormattedUserNameOrIDByNormalizedProperty(
                        user.id,
                        user.aliases,
                        userProperties
                      );
                <span [class.has-shimmer]="loadingProperties">
                  {{ formattedUserNameOrID }}
                </span>
                <ng-container *ngIf="user?.is_anonymous">
                  <nz-tag
                    nz-popover
                    nzPopoverTitle="Anonymous users"
                    [nzPopoverContent]="anonymousHelp"
                  >
                    Anonymous
                  </nz-tag>

                  <ng-template #anonymousHelp>
                    This user has not been identified in Javascript tag and
                    mobile SDKs.
                    <br />
                    <a
                      rel="noopener"
                      href="https://help.screeb.app"
                      target="_blank"
                    >
                      <i nz-icon nzType="link"></i>
                      See our help section.
                    </a>
                  </ng-template>
                </ng-container>
              </p>
              <a
                class="open-link"
                [routerLink]="
                  !user
                    ? null
                    : ['/org', org.id, 'people', 'respondent', user.id]
                "
              >
                Open
              </a>
            </div>
            <div class="groups">
              @let slicedUserGroups = userGroups.slice(0, 4);
              @let remaining = userGroups.slice(4);
              <nz-tag
                *ngFor="let userGroup of slicedUserGroups"
                [nzMode]="
                  ('segment.update' | hasPermission) ? 'closeable' : 'default'
                "
                (nzOnClose)="
                  removeUserFromGroup(userGroup); $event.preventDefault()
                "
              >
                @let formatedUserGroupNameOrID =
                  getFormattedUserGroupNameOrID(userGroup);
                {{ formatedUserGroupNameOrID }}
              </nz-tag>
              @let joined = getFormattedRemainingUserGroups(remaining);
              <nz-tag
                *ngIf="remaining.length"
                nz-tooltip
                [nzTooltipTitle]="joined"
              >
                +{{ remaining.length }}
              </nz-tag>
              <user-add-to-segment
                *ngIf="'segment.update' | hasPermission"
                [org]="org"
                [user]="user"
                [isSmall]="true"
                [forceSmallTitle]="!userGroups.length"
                [orgUserGroups]="orgUserGroups"
                [userAssignedGroups]="userGroups"
                (addedToSegments)="onAddedToSegments($event)"
              ></user-add-to-segment>
            </div>
          </div>
        </div>
      </div>
      <div nz-col nzSpan="24">
        <widget-graph
          type="nps"
          [startDate]="startDate"
          [endDate]="endDate"
          [userId]="user.id"
        ></widget-graph>
      </div>
      <div nz-col nzSpan="24">
        <widget-graph
          type="ces"
          [startDate]="startDate"
          [endDate]="endDate"
          [userId]="user.id"
        ></widget-graph>
      </div>
      <div nz-col nzSpan="24">
        <widget-graph
          type="csat"
          [startDate]="startDate"
          [endDate]="endDate"
          [userId]="user.id"
        ></widget-graph>
      </div>
      <div nz-col nzSpan="24" class="fullsize-indicator">
        <user-activity [user]="user"></user-activity>
      </div>
      <div nz-col nzSpan="24" class="fullsize-indicator">
        <user-timeline [user]="user"></user-timeline>
      </div>
      <div nz-col nzSpan="24" class="fullsize-indicator">
        <div class="indicator-with-border">
          <div class="indicator-header">
            <h4>Properties</h4>
          </div>
          <div
            class="loader"
            *ngIf="loadingProperties && initialFetchProperties"
          >
            <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
          </div>
          <ng-container *ngIf="!initialFetchProperties || !loadingProperties">
            <user-properties
              [userProperties]="userProperties"
            ></user-properties>
          </ng-container>
        </div>
      </div>

      <ng-container
        *ngIf="
          (!initialFetchEventsTypeform || !loadingEventsTypeform) &&
          userEventsTypeform.length
        "
      >
        <div nz-col nzSpan="24" class="fullsize-indicator">
          <div class="indicator-with-border">
            <div class="indicator-header">
              <h4>Typeform responses</h4>
            </div>
            <ul class="stats-list">
              <li
                *ngFor="let event of userEventsTypeform"
                class="stat-item clickable"
              >
                @let typeFormEventUrl = getTypeformEventURL(event);
                <a
                  class="stat-item-header typeform"
                  [href]="typeFormEventUrl"
                  (click)="event.opened = !event.opened; onEventClicked()"
                  target="_blank"
                >
                  <square-icon icon="typeform"></square-icon>
                  <div class="stat-item-data">
                    <div class="stat-item-title">
                      <span>{{ event.slug }}</span>
                      <tag-registry-entry-source
                        [sources]="[event.source]"
                      ></tag-registry-entry-source>
                    </div>
                    <div
                      class="stat-item-value"
                      [title]="event.triggered_at | toLocaleString"
                    >
                      {{
                        event.triggered_at
                          | dfnsFormatDistanceToNow: { addSuffix: true }
                      }}
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </ng-container>
    </section>
  </div>
</section>
