import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

export const uppercaseFirst = (text: string): string => {
  if (!text) return text;

  const [first, ...rest] = text;
  return first.toLocaleUpperCase() + rest.join("");
};

@Pipe({ name: "uppercaseFirst" })
export class UppercaseFirstPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(text: string): string {
    return uppercaseFirst(text);
  }
}
