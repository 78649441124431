@let tagsSliced = tags.slice(0, maxTagsCount);
@let remaining = tags.slice(maxTagsCount);
<div class="size-{{ size }}">
  <ng-container *ngFor="let tag of tagsSliced">
    <nz-tag [style.color]="textColor" [nzColor]="color">{{ tag }}</nz-tag>
  </ng-container>
  @let joined = tagsSliced.join(", ");
  <nz-tag
    *ngIf="remaining.length"
    class="rest-tags"
    nz-tooltip
    [nzTooltipTitle]="joined"
  >
    +{{ remaining.length }}
  </nz-tag>
</div>
