import { Component } from "@angular/core";
import { DebugDao } from "models/debug.dao";

@Component({
  selector: "page-release-list",
  templateUrl: "./pretty-gift.component.html",
  styleUrls: ["./pretty-gift.component.scss"],
})
export class PrettyGiftPageComponent {
  public workspaceId: string = "";
  public surveyId: string = "";
  public answerLimit: number = 1000;
  public systemPrompt: string =
    "You are a product experience platform used by product managers at Screeb to help them improve their digital product";
  public userPrompt: string =
    "Analyze the following feedback. From these feedback, provide:\n- a comprehensive summary that must give a great sense of what users are actually saying. It must be synthetic while precise enough for the Produt Manager to know what users say in he feedback. It must also highlight key findings and learnings coming from the feedback,\n- a short description for the opportunities (coming from the feedback, it must describe opportunities of improvements or problem fixing that could improve the experience of users), the strengths (coming from the feedback, it must describe what people prefer in the product and what are considered as strengths in the product by users), the trends (topics or requests that are rising in the feedback), and the weaknesses (of the product, coming from the feedback, as seen and told by the users),\n- the 6 most recurrent keywords found in the feedback with a score from 0 to 100 associated to them (the score representing the level of revelance),\n- the 3 most relevant feedbacks not modified (those feedback must be representative of what people said and be great examples of what people said in the feedback. By reading those 3 feedback we must have a great idea of what everyone said),\n- a list of 4 Q&A (4 questions that are relevant about the topics found in the feedback that a Product Manager could have and the responses to those questions made from what is found in the feedbacks),\n- and a list of 10 recommandations based on the feedback to improve my product (make this list actionnable, as if it was next steps to follow for a Product Manager).";

  public response: string = "";
  public total: number = 0;

  public loading: boolean = false;
  public error: string | null = null;

  constructor(private debugDao: DebugDao) {}

  public chat(): void {
    this.loading = true;
    this.error = null;
    this.response = "";

    if (
      this.workspaceId.length === 0 ||
      this.surveyId.length === 0 ||
      this.systemPrompt.length === 0 ||
      this.userPrompt.length === 0
    ) {
      this.loading = false;

      this.error = "Please fill all fields";

      return;
    }

    this.debugDao
      .askPrompt(
        this.workspaceId,
        this.surveyId,
        this.systemPrompt,
        this.userPrompt,
        this.answerLimit,
      )
      .then((response: { result: string; total: number }) => {
        this.response = response.result;
        this.total = response.total;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  public onAnswerLimitChange(value: string): void {
    this.answerLimit = parseInt(value, 10);

    if (isNaN(this.answerLimit)) {
      this.answerLimit = 1000;
    }
  }
}
