import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, CanDeactivate } from "@angular/router";

import { PageComponentInterface } from "components/PageComponentInterface";
import { isInvalid } from "components/common/customize/tag-settings/component/tag-settings-design/tag-settings-design.component";
import { NotificationHelper } from "helpers/notification.helper";
import { Org } from "models/org.model";
import { adaptTagSettingsToApi } from "models/tag-settings.api";
import { TagSettingsDao } from "models/tag-settings.dao";
import { TagSettings } from "models/tag-settings.types";
import { RoutingService } from "services/routing.service";
import { deepEqual } from "utils/object";

@Component({
  selector: "page-settings-survey-behaviour",
  templateUrl: "./survey-behaviour.component.html",
  styleUrls: ["./survey-behaviour.component.scss"],
})
export class SettingsSurveyBehaviourPageComponent
  implements
    PageComponentInterface,
    OnInit,
    OnDestroy,
    CanDeactivate<SettingsSurveyBehaviourPageComponent>
{
  public title = "Default settings";
  public name = "Default settings";

  public loading = false;
  public error: string = null;

  public tagSettingsDiff: TagSettings = null;

  @Input() public org: Org = null;

  private obs: any = null;

  public isInvalid = isInvalid;

  constructor(
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private notificationHelper: NotificationHelper,
    private tagSettingsDao: TagSettingsDao,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.obs = this.route.data.subscribe((data) => {
      this.org = data["org"];

      this.tagSettingsDiff = { ...this.org?.settings };
    });
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  canDeactivate() {
    if (this.hasNotChanged()) {
      return true;
    }

    return confirm(
      "You have unsaved changes! If you leave, your changes will be lost.",
    );
  }

  public hasNotChanged() {
    return deepEqual(this.org?.settings, this.tagSettingsDiff);
  }

  save() {
    if (this.hasNotChanged() || this.isInvalid(this.org?.settings)) {
      return;
    }

    this.loading = true;
    this.error = null;
    this.tagSettingsDao
      .updateOrgTagSettings(
        this.org.id,
        adaptTagSettingsToApi(this.org.settings, "org"),
      )
      .then(() => {
        this.notificationHelper.trigger(
          "Default settings",
          "Saved successfully!",
          "success",
          undefined,
          "save",
        );
        this.tagSettingsDiff = { ...this.org?.settings };
      })
      .catch(() => {
        this.error = "Something went wrong. Please retry.";
      })
      .finally(() => {
        setTimeout(() => {
          this.loading = false;
        }, 500);
      });
  }
}
