<h1>Import surveys from GetFeedback</h1>
<p class="description">
  Let's import your surveys from GetFeedback to Screeb so you can use them right
  away.
</p>
<div class="how-to-button">
  💡&nbsp;
  <span>
    Be aware that importing surveys from GetFeedback will
    <b>not be exactly the same</b>
    as the original survey.
    <br />
    We will try to keep the same structure and questions, but some things
    <b>may change</b>
    .
  </span>
</div>
<h2>Getting Started</h2>
<p>
  We can import surveys for all customers on GetFeedback Corporate and some
  GetFeedback for Salesforce plans.
</p>
<p>
  Customers on these plans can generate API tokens at the following location:
  <b>GetFeedback > Account Settings > API</b>
</p>

<p>Please enter your API token below to continue:</p>
<form nz-form [nzAutoTips]="autoTips" [formGroup]="validateForm">
  <nz-form-item>
    <nz-form-control>
      <nz-input-group>
        <input
          type="text"
          nz-input
          nzHasFeedback
          formControlName="token"
          required
          placeholder="Bearer <your-token>"
        />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
  <button
    type="button"
    nz-button
    nzType="primary"
    [nzLoading]="isLoadSurveysLoading"
    (click)="onLoadSurveys()"
  >
    Load surveys
  </button>
</form>

<ng-container *ngIf="surveys.length">
  <h2>Surveys</h2>
  <p>
    Select the surveys you want to import. You can import as many surveys as you
    want.
  </p>
  <nz-table
    #surveysTable
    [nzData]="surveys"
    [nzFrontPagination]="false"
    [nzShowPagination]="false"
    [nzShowSizeChanger]="false"
    [nzShowQuickJumper]="false"
    [nzShowTotal]="surveys.length"
    [nzLoading]="isLoadSurveysLoading"
    [nzScroll]="{ y: 'calc(100vh - 400px)' }"
  >
    <thead>
      <tr>
        <th
          [nzChecked]="checked"
          [nzIndeterminate]="indeterminate"
          (nzCheckedChange)="onAllChecked($event)"
        ></th>
        <th>Name</th>
        <th>Created</th>
        <th>Number of questions</th>
        <th>Number of responses</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let survey of surveys">
        <td
          [nzChecked]="setOfCheckedId.has(survey.id)"
          [nzDisabled]="survey.disabled"
          (nzCheckedChange)="onItemChecked(survey.id, $event)"
        ></td>
        <td>{{ survey.name }}</td>
        <td>{{ survey.created_at | date: "medium" }}</td>
        <td>{{ survey.questions_count }}</td>
        <td>{{ survey.started_responses_count }}</td>
      </tr>
    </tbody>
  </nz-table>
  <button
    type="button"
    nz-button
    nzType="primary"
    class="import-button"
    [disabled]="!setOfCheckedId.size"
    (click)="onImportSurveys()"
  >
    Import
  </button>
  <button
    type="button"
    nz-button
    nzType="default"
    class="import-button"
    [disabled]="!setOfCheckedId.size"
    (click)="onImportSurveys(false)"
  >
    Import without responses
  </button>
</ng-container>
<nz-modal
  *ngIf="!!setOfCheckedId.size"
  [(nzVisible)]="isImportModalVisible"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  [nzClosable]="false"
  nzWidth="750px"
  nzClassName="surveys-import-getfeedback-modal"
>
  <ng-template #modalTitle>
    <h3>Importing surveys...</h3>
  </ng-template>

  <ng-template #modalContent>
    <p>{{ processingMessage }}</p>
    <nz-progress
      nzStrokeLinecap="round"
      [nzPercent]="(processedSurveys / setOfCheckedId.size) * 100"
      [nzSuccessPercent]="(importedSurveys / setOfCheckedId.size) * 100"
    ></nz-progress>
    <div class="surveys-import-close-btn">
      <p>
        <b>{{ importedSurveys }}</b>
        surveys imported
      </p>
      <p>
        <b>{{ importedResponses }}</b>
        responses imported
      </p>
      <button
        nz-button
        nzType="primary"
        [disabled]="processedSurveys !== setOfCheckedId.size"
        (click)="isImportModalVisible = false"
      >
        Done
      </button>
    </div>
  </ng-template>

  <ng-template #modalFooter></ng-template>
</nz-modal>
