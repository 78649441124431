@let backgroundColor = getBackgroundColor(icon);
<div class="square-icon" [style.backgroundColor]="backgroundColor">
  <span *ngIf="emoji && !useContent" class="icon">{{ emoji }}</span>
  <img
    *ngIf="!emoji && !useContent"
    class="icon"
    [src]="'assets/icons/common/' + icon + '.svg'"
    alt=""
  />
  <ng-content *ngIf="useContent"></ng-content>
</div>
