export type AspectRatio = {
  width: number;
  height: number;
  margin: number;
};

export function transformToAspectRatio(
  width: number,
  height: number,
  ratio: number,
) {
  const w = Math.round(height * ratio);
  return {
    width: w,
    height: height,
    margin: (width - w) / 2,
  };
}
